import "./ContactBlock.scss";

import { t } from "i18next";
import React from "react";

import { DetailTypeE, StyleTypeE } from "../../../models/ModelConfig";
import { ICombiBlock, ICombiDetail } from "../../../models/ModelContact";
import { ContactAddrBlock } from "./ContactAddrBlock";
import { ContactButtonCUrl } from "./ContactButtonCUrl";
import { ContactDetail } from "./ContactDetail";

interface IBlockProps {
	block: ICombiBlock;
	type: "cd" | "cw" | "ch"; // "cd" = Contact Details, "cw" = Conversation windows, "ch" = chat
}
export const ContactBlock = (props: IBlockProps): JSX.Element => {
	const block: ICombiBlock = props.block;
	const prefix = props.type.toString();

	const title: string =
		block && block.displayLabel && (block.displayLabel as string).startsWith("IDS_")
			? t(block.displayLabel)
			: block.displayLabel;

	const details: ICombiDetail[] = block.combiDetails;

	const highlight: boolean =
		block.style !== undefined && block.style !== null && block.style.indexOf(StyleTypeE.HGH) > -1;

	return (
		<div className={highlight ? prefix + "B_hlBlock" : undefined}>
			<div className={prefix + "B_infoBlock"}>
				{title ? <div className={prefix + "B_displayTitle"}>{title}</div> : <></>}

				<div className={block.type === DetailTypeE.CUBLK && props.type === "cd" ? "cdB_flex" : undefined}>
					{details.map((detail: ICombiDetail, index: number) => {
						return detail.type === DetailTypeE.ADR ? (
							<ContactAddrBlock key={index} detail={detail} type={props.type} />
						) : detail.type === DetailTypeE.CUDTL ? (
							<ContactButtonCUrl key={index} detail={detail} type={props.type} />
						) : (
							<ContactDetail key={index} detail={detail} type={props.type} />
						);
					})}
				</div>
			</div>
		</div>
	);
};
