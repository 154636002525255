/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-unsafe-return */

import { ILogMessage, LogLevels } from "./ILogger";

/**
 * This class contains a single log entry as beeing processed by ELogger
 */
export class LogMessage implements ILogMessage {
	public readonly time: Date = new Date();
	public readonly timestamp = this.time.getTime();
	public level: LogLevels = "debug";
	public message: string | undefined;
	public method: string | undefined;
	public meta: unknown | undefined;
	public error: unknown | undefined;
	public className = "";
	public classProps: Record<string, unknown> | undefined;
	public lokiLabelsKey?: string;
}
