import "./aboutDialog.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { faTimes } from "web-fortawesome/npm/pro-light";

import { useStore } from "../../../zustand/store";
import LicensesDialog from "../LicensesDialog/LicensesDialog";

interface IProps {
	onCLose: () => void;
}
/**
 * Renders the AboutDialog, containing Website references, client & server
 * versions and the possibility to download the logfiles and display the
 * licenses of the products used in this product
 * @param props - IProps
 * @returns - the AboutDialog itself
 */
export default function AboutDialog(props: IProps): JSX.Element {
	const { t } = useTranslation();

	const clientVersion = useStore((state) => state.clientVersion);
	const serverVersion = useStore((state) => state.serverVersion);
	const appManifest = useStore((state) => state.appManifest);
	const appName = appManifest?.appShortName;
	const appPrivacyUrl = appManifest?.appPrivacyUrl;
	const appTermsOfUseUrl = appManifest?.appTermsOfUseUrl;

	const currentYear = new Date().getFullYear();
	const [showLicensesDialog, setShowLicensesDialog] = useState<boolean>(false);

	const closeDialog = () => {
		props.onCLose();
	};

	return (
		<Dialog
			open={true}
			sx={{
				borderRadius: "5px"
			}}
			className={"about-dialog"}
			onClose={closeDialog}
		>
			<DialogContent>
				<DialogTitle
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "24px"
					}}
				>
					<img className="ad-logo" src={"./assets/images/appLogo.png?v=" + clientVersion} alt={appName + " icon"} />
					<span className={"ad-title-text"}>{appName}</span>
					<Tooltip title={t("IDS_CLOSE")} placement="bottom">
						<IconButton
							onClick={closeDialog}
							sx={{
								position: "absolute",
								right: 10,
								top: 5
							}}
						>
							<FontAwesomeIcon transform={"shrink-1"} icon={faTimes} />
						</IconButton>
					</Tooltip>
				</DialogTitle>

				<div className={"ad-sec-two"}>
					<div className={"ad-client-version"}>
						<div className={"ad-version"}>{t("IDS_ABOUT_CLIENT_VERSION")}</div>
						<div className={"ad-client-version-info"}>
							<Typography className={"ad-client-version-text"}>{clientVersion}</Typography>
						</div>
					</div>
					<div className={"ad-server-versions"}>
						<div className={"ad-version"}>{t("IDS_ABOUT_SERVER_VERSION")}</div>
						<div className={"ad-server-version-info"}>
							<Typography className={"ad-server-version-text"}>{serverVersion}</Typography>
						</div>
					</div>
				</div>

				<div className={"ad-sec-three"}>
					<div className={"ad-webrtc-block"}>
						<Typography className={"ad-sec-three-text"}></Typography>
					</div>
					<div className={"ad-display-licenses"}>
						<Typography
							className={"ad-sec-three-text"}
							onClick={() => {
								setShowLicensesDialog(true);
							}}
						>
							{t("IDS_ABOUT_LICENSES_DISPLAY")}
						</Typography>
					</div>
				</div>
				<div className={"ad-sec-four"}>
					<div className="ad-copyright">Copyright &copy; {currentYear} estos GmbH</div>
					{appPrivacyUrl ? (
						<>
							<div className="ad-linkDivider" />
							<a className="ad-privacy" href={appPrivacyUrl} target="_blank" rel="noreferrer noopener">
								{t("IDS_ABOUT_PRIVACY")}
							</a>
						</>
					) : null}
					{appTermsOfUseUrl ? (
						<>
							<div className="ad-linkDivider" />
							<a className="ad-termsofuse" href={appTermsOfUseUrl} target="_blank" rel="noreferrer noopener">
								{t("IDS_ABOUT_TERMSOFUSE")}
							</a>
						</>
					) : null}
				</div>
			</DialogContent>
			{showLicensesDialog ? <LicensesDialog onCloseLicensesDialog={setShowLicensesDialog} /> : null}
		</Dialog>
	);
}
