import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";
import { faCircleNotch, faClose, faSearch, IconDefinition } from "web-fortawesome/npm/pro-light";

import { CircleIconButton } from "../../components/common/Button/CircleIconButton";
import { TextField } from "../../components/common/TextField/TextField";
import { checkIsPhoneNumber } from "../../lib/commonHelper";

interface ISearchFormIconProps extends ComponentProps<typeof FontAwesomeIcon> {
	icon: IconDefinition;
}

const SearchFormIcon = (props: ISearchFormIconProps) => (
	<Box mr={1}>
		<FontAwesomeIcon width={16} {...props} />
	</Box>
);

interface IEndAdornmentProps {
	searchTerm: string;
	isLoading: boolean;
	setSearchTerm: (searchTerm: string) => void;
}

const EndAdornment = ({ searchTerm, isLoading, setSearchTerm }: IEndAdornmentProps) => {
	if (isLoading) {
		return <SearchFormIcon icon={faCircleNotch} className="fas fa-spinner fa-spin" />;
	}

	if (searchTerm) {
		return (
			<CircleIconButton
				icon={faClose}
				onClick={() => {
					setSearchTerm("");
				}}
			/>
		);
	}

	return <SearchFormIcon icon={faSearch} />;
};

interface IContactsSearchFormProps extends ComponentProps<typeof TextField> {
	onSearch: (searchText: string) => void;
	searchTerm: string;
	isLoading: boolean;
	setSearchTerm: (searchTerm: string) => void;
	onCallNumber: (numberToCall: string) => void;
}

export const ContactsSearchForm: FC<IContactsSearchFormProps> = ({
	onSearch,
	setSearchTerm,
	onCallNumber,
	searchTerm,
	isLoading,
	...props
}) => {
	const { t } = useTranslation();

	return (
		<TextField
			placeholder={t("IDS_CONTACT_SEARCH") ?? ""}
			InputProps={{
				sx: { pr: 1 },
				endAdornment: EndAdornment({ searchTerm, isLoading, setSearchTerm })
			}}
			InputLabelProps={{ shrink: false }}
			value={searchTerm}
			onChange={(e) => {
				if (e.target.value.length > 0) {
					onSearch(e.target.value);
				}
				setSearchTerm(e.target.value);
			}}
			onKeyDown={(e) => {
				if (e.key === "Enter" && searchTerm.length > 0 && checkIsPhoneNumber(searchTerm)) {
					onCallNumber(searchTerm);
				}
			}}
			disableHelperText
			sx={{ pr: 0 }}
			{...props}
		/>
	);
};
