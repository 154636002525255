import type { SnackbarOrigin, VariantType } from "notistack";
import { StateCreator } from "zustand";

import { StoreT } from "./store";

export type SystemMessagesSliceT = IState & IActions;

type TBannerErrorType = "noServerConnection";

export interface IUISnackBar {
	type: "standard";
	key: string;
	message: string;
	anchorOrigin: SnackbarOrigin;
	variant: VariantType;
	preventDuplicate: boolean;
	persist?: boolean;
	autoHideDuration: number;
}

interface ISystemMessages {
	snackBars: IUISnackBar[];
	banner: TBannerErrorType | undefined;
}

interface IState {
	snackBars: IUISnackBar[];
	banner: TBannerErrorType | undefined;
}

interface IActions {
	smSetBanner: (banner: TBannerErrorType | undefined) => void;
	smEnqueueSnackbar: (snackBar: IUISnackBar) => void;
	smRemoveFromQueueSnackbar: (key: string) => void;
}

export const initialState: ISystemMessages = {
	snackBars: [],
	banner: undefined
};

export const createSystemMessagesSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	SystemMessagesSliceT
> = (set) => ({
	...initialState,
	smSetBanner: (banner: TBannerErrorType | undefined) => {
		set(
			(prev) => {
				return {
					...prev,
					banner
				};
			},
			false,
			"addToSendQueue"
		);
	},
	smEnqueueSnackbar: (snackBar: IUISnackBar) => {
		set(
			(prev) => {
				return {
					...prev,
					snackBars: [...prev.snackBars, snackBar]
				};
			},
			false,
			"addToSendQueue"
		);
	},
	smRemoveFromQueueSnackbar: (key: string) => {
		set(
			(prev) => {
				return {
					...prev,
					snackBars: prev.snackBars.filter((item) => item.key !== key)
				};
			},
			false,
			"flushSendQueue"
		);
	}
});
