import { useEffect, useRef } from "react";

/**
 * Returns the previous value of a variable
 *
 * @param value - value to track
 * @returns previous value
 */
export function usePrevious<T>(value: T) {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes
	return ref.current;
}
