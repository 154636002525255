import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemText, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { ComponentProps } from "react";

import { AsnNetDatabaseJournal } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal";
import { CallOriginIcon } from "../../components/common/Icon/CallOriginIcon";
import { formatSeconds } from "../../lib/DateFormatter";
import { mapCallTypeToIcon } from "../../lib/JournalHelper";

interface IJournalEntrySummaryProps extends ComponentProps<typeof Stack> {
	journalEntry: AsnNetDatabaseJournal;
}

export const JournalEntrySummary = ({ journalEntry, sx, ...props }: IJournalEntrySummaryProps) => {
	return (
		<Stack flexDirection={"row"} alignItems="center" {...props} sx={{ overflowX: "clip", ...sx }}>
			<Stack flexDirection={"row"} gap={2}>
				<FontAwesomeIcon
					// @ts-ignore
					icon={mapCallTypeToIcon(journalEntry?.optionalParams?.CallType)}
					width={18}
				/>
				<CallOriginIcon journalEntry={journalEntry} />
			</Stack>
			<ListItemText
				primaryTypographyProps={{ sx: { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" } }}
				sx={{ ml: 2 }}
				primary={journalEntry.u8sPhoneNumber || journalEntry.u8sCtiServerUserName}
				secondary={`${DateTime.fromISO(String(journalEntry.stStartTime)).toFormat("D - t")} ${
					journalEntry.iDurationConnected > 0 ? `(${formatSeconds(journalEntry.iDurationConnected)})` : ""
				}`}
			></ListItemText>
		</Stack>
	);
};
