import { Paper, Stack } from "@mui/material";
import { ComponentProps, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Header } from "../../components/common/Header/Header";
import { theBrowser } from "../../globals";
import { IAsnNetDatabaseJournalEx } from "../../zustand/journalSlice";
import { useStore } from "../../zustand/store";
import { FilterBar } from "./FilterBar/FilterBar";
import { JournalList } from "./JournalList/JournalList";

interface IJournalProps extends Omit<ComponentProps<typeof Paper>, "onClick"> {
	selectedJournalEntryId: string | null;
	onClick: (journalEntry: IAsnNetDatabaseJournalEx) => void;
}

export const Journal: FC<IJournalProps> = ({ selectedJournalEntryId, onClick, ...props }) => {
	const readFilter = useStore((state) => state.journalReadFilter);
	const journalEntries = useStore(useCallback((state) => state.getFilteredJournalEntries(readFilter), [readFilter]));
	const { t } = useTranslation();

	return (
		<Paper
			sx={{
				height: "100%",
				overflow: "hidden",
				width: theBrowser.is.mobile ? "calc(100vw - 32px)" : "unset"
			}}
			{...props}
		>
			<Stack height={"100%"}>
				<Header title={t("IDS_JOURNAL_PAGE_TITLE")}>
					<FilterBar />
				</Header>
				<JournalList
					selectedJournalEntryId={selectedJournalEntryId}
					journalEntries={journalEntries}
					onClick={onClick}
				/>
			</Stack>
		</Paper>
	);
};
