import { IBrowser } from "../../web-shared-components/helpers/browser-detector/IBrowserDetector";
import { ILogger } from "../../web-shared-components/helpers/logger/ILogger";
import { IBaseSingletons } from "../interfaces/IBaseSingletons";

/**
 * Base class for all the singletons for an easier access to the most common singletons, needed everywhere
 * (theBrowser and theLogger) - to avoid circular dependencies.
 */
export default class BaseSingleton {
	protected browser: IBrowser;
	protected logger: ILogger;

	/**
	 * Construct the class
	 *
	 * @param args - the base singletons
	 */
	public constructor(args: IBaseSingletons) {
		this.browser = args.browser;
		this.logger = args.logger;
	}
}
