/**
 * Class for permanently listening to the microphone.
 * This is needed because we want to warn a speaking user in case
 * the microphone is muted in the conference.
 * We need an independent local audio stream for this logic.
 *
 */
class AudioMeter {
	private audioContext: AudioContext | undefined;
	private audioSource: MediaElementAudioSourceNode | undefined;
	private scriptProcessor?: ScriptProcessorNode;
	private volume: number;
	public getAudioLevel?: (level: number) => void;
	// Instance of class to use it as singleton.
	private static instance: AudioMeter;
	private audioElement: HTMLAudioElement | undefined;

	/**
	 * Define the defaults.
	 *
	 */
	private constructor() {
		this.volume = 0;
	}

	/**
	 * gets instance of miclistener as singleton
	 *
	 * @returns - an instance of MicListener
	 */
	public static getInstance(): AudioMeter {
		if (!AudioMeter.instance) {
			AudioMeter.instance = new AudioMeter();
		}
		return AudioMeter.instance;
	}

	/**
	 * Get the local audio stream passing a device id.
	 * Start listening the microphone by connecting the script processor.
	 */
	public start(): void {
		this.stop();
		this.listen();
	}

	/**
	 * Disconnect the nodes.
	 */
	public stop(): void {
		if (this.audioSource) {
			this.audioSource.disconnect();
			this.audioSource = undefined;
		}
		if (this.scriptProcessor) {
			this.scriptProcessor.disconnect();
			this.scriptProcessor = undefined;
		}
		if (this.audioContext) {
			void this.audioContext.close();
			this.audioContext = undefined;
		}
	}

	/**
	 * Create the audio nodes to get the microphone volume.
	 */
	private listen(): void {
		if (!this.audioElement) {
			return;
		}

		if (!this.audioContext) {
			// @ts-ignore
			const AudioContext = window.AudioContext || window.webkitAudioContext;
			this.audioContext = new AudioContext();
		}

		if (!this.audioContext) {
			// TODO: add error message
			return;
		}

		this.audioSource = this.audioContext.createMediaElementSource(this.audioElement);
		this.scriptProcessor = this.audioContext.createScriptProcessor(4096, 2, 2);
		this.audioSource.connect(this.scriptProcessor);
		this.scriptProcessor.connect(this.audioContext.destination);

		this.scriptProcessor.onaudioprocess = (e: AudioProcessingEvent): void => {
			let max = 0;
			const out = e.outputBuffer.getChannelData(0);
			const int = e.inputBuffer.getChannelData(0);

			for (let i = 0; i < int.length; i++) {
				const value = int[i];
				if (value) {
					out[i] = value;
					max = value > max ? value : max;
				}
			}

			this.volume = max * 100;
			this.getAudioLevel && this.getAudioLevel(this.volume);
		};
	}

	/**
	 * Something happens here for sure.
	 *
	 * @param element - the value.
	 */
	public setAudioElement(element: HTMLAudioElement): void {
		this.audioElement = element;
	}
}

export default AudioMeter;
