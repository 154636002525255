import "./presenceTooltip.scss";

import {
	faArrowLeft, faArrowRight,
	faCalendar, faCar, faCircleInfo, faCircleO,
	faClose,
	faHouse, faLocationPin, faMessage, faMobileButton,
	faMugSaucer, faPhone, faTreePalm, faVideo
} from "web-fortawesome/npm/pro-light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton/IconButton";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { formatPhoneNumberIntl } from 'react-phone-number-input'

import {
	AsnPresenceStateFlags, AsnPresenceStateEnum
} from "../../asn1/EUCSrv/stubs/ENetROSEInterface";
import { AsnAgentStateEnum } from "../../asn1/EUCSrv/stubs/ENetUC_Agent";
import { AsnClientCapabilityEnum } from "../../asn1/EUCSrv/stubs/ENetUC_ClientCapabilities";
import { AsnReachabilityEnum } from "../../asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { theSharedBrowser, theSharedTranslationManager } from "../../helpers/helpers";
import { IContactContainer } from "../../interfaces/interfaces";
import { IKeyValuePair } from "../../interfaces/IKeyValuePair";
import {
	IConfigBlock
} from "../../interfaces/interfacesConfig";
import { getIcon } from "../icons/SvgIcons";
import { iPresenceStateToString } from "../userPresenceIcon/UserPresenceHelper";
import {
	AsnAppointmentEntry,
	EnumAppointmentSensitivity,
	EnumAppointmentState
} from "../../asn1/EUCSrv/stubs/ENetUC_Common_Appointments";
import {
	AsnPresenceForward,
	AsnPresenceLine,
} from "../../asn1/EUCSrv/stubs/ENetUC_PresenceV2";

interface IProps {
	contact: IContactContainer | undefined;
	customLabels: IKeyValuePair[];
	configCD: IConfigBlock[];
	currentAppointment: string[];
	nextAppointment: string;
	close?: () => void;
}

/**
 *
 */
// eslint-disable-next-line react/display-name, @typescript-eslint/no-unnecessary-type-arguments
export const PresenceTooltip = React.forwardRef<HTMLElement, IProps>((props: IProps, ref) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [textPresence, setTextPresence] = useState<string>("");
	const [customNote, setCustomNote] = useState<string>("");
	const [statusText, setStatusText] = useState<string>("");
	const [callContactDisplayName, setCallContactDisplayName] = useState<string | undefined>("");
	const [validAppointments, setValidAppointments] = useState<AsnAppointmentEntry[]>([]);
	const [nextValidAppointment, setNextValidAppointment] = useState<AsnAppointmentEntry>();
	const [contactName, setContactName] = useState<string | undefined>("");
	const [teamsAvailable, setTeamsAvailable] = useState<boolean>(false);
	const [reachabilityType, setReachabilityType] = useState<number>(0);
	const [agentState, setAgentState] = useState<number>(0);
	const [outBound, setOutBound] = useState<number>(0);
	const [phoneLines, setPhoneLines] = useState<AsnPresenceLine[]>([]);
	const [LinesForward, setLinesForward] = useState<AsnPresenceForward[]>([]);
	const [teamsStatusText, setTeamsStatusText] = useState<string>("");

	useEffect(() => {
		if (ref) {
			const x = ref as React.MutableRefObject<HTMLDivElement>;
			setAnchorEl(x.current);
		}
	}, [ref]);

	const getNextAppointmentTime = () => {
		return props.nextAppointment;
	};

	const getNextAppointmentSubject = () => {
		let nextAppointmentSubject = nextValidAppointment?.u8sSubject;

		if (nextValidAppointment && nextValidAppointment.iSensitivity) {
			switch (nextValidAppointment.iSensitivity) {
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYNORMAL:
					break;
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYPRIVATE:
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYCONFIDENTIAL:
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYPERSONAL:
					nextAppointmentSubject = "(" + theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_SENSITIVITY_PRIVATE") + ")";
					break;
				default:
					break;
			}
		}
		return nextAppointmentSubject;
	};

	const getNextAppointmentLocation = () => {
		let location = "";
		if (nextValidAppointment?.optionalParams) {
			for (const [key, value] of Object.entries(nextValidAppointment?.optionalParams)) {
				if (key === "Location") {
					location = value as unknown as string;
					break;
				}
			}
		}
		return location;
	};

	const getCurrentAppointmentTime = (index: number) => {
		return props.currentAppointment[index];
	};

	const getCurrentAppointmentLocation = (appointment: AsnAppointmentEntry) => {
		let location = "";
		if (appointment?.optionalParams) {
			for (const [key, value] of Object.entries(appointment?.optionalParams)) {
				if (key === "Location") {
					location = value as unknown as string;
					break;
				}
			}
		}
		return location;
	};

	const getCurrentAppointmentSubject = (appointment: AsnAppointmentEntry) => {
		let appointmentSubject = appointment?.u8sSubject;

		if (appointment && appointment.iSensitivity) {
			switch (appointment.iSensitivity) {
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYNORMAL:
					break;
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYPRIVATE:
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYCONFIDENTIAL:
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYPERSONAL:
					appointmentSubject = "(" + theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_SENSITIVITY_PRIVATE") + ")";
					break;
				default:
					break;
			}
		}
		return appointmentSubject;
	};

	const getCalendarStatus = (appointment: AsnAppointmentEntry) => {
		let calendarStatus = "ptt-appointments-calendar-icon-default-state";

		if (appointment && appointment.iStatus) {
			switch (appointment.iStatus) {
				case EnumAppointmentState.eAPPOINTMENTSTATUSBUSY:
					calendarStatus = "ptt-appointments-calendar-icon-busy-state";
					break;
				case EnumAppointmentState.eAPPOINTMENTSTATUSOUTOFOFFICE:
					calendarStatus = "ptt-appointments-calendar-icon-outofoffice-state";
					break;
				default:
					calendarStatus = "ptt-appointments-calendar-icon-default-state";
					break;
			}
		}
		return calendarStatus;
	};

	const getNextAppointmentCalendarStatus = () => {
		let calendarStatus = "ptt-next-appointment-calendar-icon-default-state";

		if (nextValidAppointment && nextValidAppointment.iStatus) {
			switch (nextValidAppointment.iStatus) {
				case EnumAppointmentState.eAPPOINTMENTSTATUSBUSY:
					calendarStatus = "ptt-next-appointment-calendar-icon-busy-state";
					break;
				case EnumAppointmentState.eAPPOINTMENTSTATUSOUTOFOFFICE:
					calendarStatus = "ptt-next-appointment-calendar-icon-outofoffice-state";
					break;
				default:
					calendarStatus = "ptt-next-appointment-calendar-icon-default-state";
					break;
			}
		}
		return calendarStatus;
	};

	const userHasTeams = (): boolean => {
		if (!props.contact)
			return false;
		return !!props.contact.msTeamsEmail?.email;
	};

	const getTeamsStatus = (): string => {
		let teamsStatus = "ptt-channels-teams-svg-icon";

		if (props.contact?.msTeamsEmail) {
			switch (iPresenceStateToString(props?.contact?.msTeamsEmail?.value)) {
				case "available":
				case "unavailable":
					teamsStatus = "ptt-channels-teams-svg-icon";
					break;
				case "busy":
				case "dnd":
					teamsStatus = "ptt-channels-teams-svg-icon-busy";
					break;
				case "absent":
					teamsStatus = "ptt-channels-teams-svg-icon-absent";
					break;
				default:
					teamsStatus = "ptt-channels-teams-svg-icon";
					break;
			}
		}
		return teamsStatus;
	};

	const getTeamsStatusForOuterCircle = () => {
		let teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-black";

		if (props.contact?.msTeamsEmail) {
			switch (iPresenceStateToString(props?.contact?.msTeamsEmail?.value)) {
				case "available":
				case "unavailable":
					teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-black";
					break;
				case "busy":
				case "dnd":
					teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-red";
					break;
				case "absent":
					teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-yellow";
					break;
				default:
					teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-black";
					break;
			}
		}
		return teamsStatusForOuterCircle;
	}

	const userHasVideoChat = (): boolean => {
		if (!props.contact)
			return false;
		const eClientCapabilities = props?.contact?.clientCapabilities?.eClientCapabilities;
		if (!eClientCapabilities)
			return false;
		for (const value of eClientCapabilities) {
			if (value === AsnClientCapabilityEnum.bVideo)
				return true;
		}
		return false;
	};

	const getReachabilityTypeIcon = () => {
		let iconDefinition = faMugSaucer;

		if (reachabilityType === AsnReachabilityEnum.eREACHABILITYBUSINESSTRIP)
			iconDefinition = faCar;
		else if (reachabilityType === AsnReachabilityEnum.eREACHABILITYHOMEOFFICE)
			iconDefinition = faHouse;
		else if (reachabilityType === AsnReachabilityEnum.eREACHABILITYVACATION)
			iconDefinition = faTreePalm;

		return iconDefinition;
	};

	const getReachabilityText = () => {
		let reachabilityText = "";

		if (reachabilityType === AsnReachabilityEnum.eREACHABILITYPAUSE)
			reachabilityText = theSharedTranslationManager.getTranslation("IDS_REACHABILITY_PAUSE");
		else if (reachabilityType === AsnReachabilityEnum.eREACHABILITYBUSINESSTRIP)
			reachabilityText = theSharedTranslationManager.getTranslation("IDS_REACHABILITY_BUSINESS_TRIP");
		else if (reachabilityType === AsnReachabilityEnum.eREACHABILITYHOMEOFFICE)
			reachabilityText = theSharedTranslationManager.getTranslation("IDS_REACHABILITY_REMOTE_WORKING");
		else if (reachabilityType === AsnReachabilityEnum.eREACHABILITYVACATION)
			reachabilityText = theSharedTranslationManager.getTranslation("IDS_REACHABILITY_VACATION");

		return reachabilityText;
	};

	const getAgentStateIcon = () => {
		let iconDefinition = faCircleO;

		if (agentState === AsnAgentStateEnum.eAGENTSTATEREADY || AsnAgentStateEnum.eAGENTSTATEBUSY || AsnAgentStateEnum.eAGENTSTATENOTREADY)
			iconDefinition = faCircleO;

		return iconDefinition;
	};

	const getAgentStateText = () => {
		let agentStateText = "";

		if (agentState === AsnAgentStateEnum.eAGENTSTATEREADY)
			agentStateText = theSharedTranslationManager.getTranslation("IDS_AGENT_STATE_READY");
		else if (agentState === AsnAgentStateEnum.eAGENTSTATEBUSY)
			agentStateText = theSharedTranslationManager.getTranslation("IDS_AGENT_STATE_BUSY");
		else if (agentState === AsnAgentStateEnum.eAGENTSTATENOTREADY)
			agentStateText = theSharedTranslationManager.getTranslation("IDS_AGENT_STATE_WORKINGAFTERCALL");

		return agentStateText;
	};

	const getMobileAvailability = (): boolean => {
		if (!props?.contact?.presence)
			return false;

		if (props?.contact?.presence & AsnPresenceStateFlags.eMOBILEAVAILABLITY)
			return true;

		return false;
	};

	const getPhoneLineChannelSection = (phoneLine: AsnPresenceLine, index: number) => {
		let div = null;
		if (phoneLine && phoneLine.u8sLinePhoneNumber.search("Business") !== -1) {
			div = <div key={index} className="ptt-channels-dial-in">
				{phoneLine.iDoNotDisturb === 1 ?
					getIcon("phoneDoNotDisturb", "small", "plain", "ptt-channels-videochat-do-not-disturb-svg")
					:
					<FontAwesomeIcon
						className="ptt-channels-dial-in-icon"
						transform={"shrink-1"}
						icon={faPhone}
					/>
				}
				<Typography className={"ptt-information-text"}>
					[{phoneLine.u8sLineNumber.substring(15, 12)}] {phoneLine.iDoNotDisturb === 1 ? " " + theSharedTranslationManager.getTranslation("IDS_CHANNEL_CALL_PROTECTION") : ""}
				</Typography>
			</div>;
		}
		if (phoneLine && phoneLine.u8sLinePhoneNumber.search("phys=ClientAV") !== -1) {
			div = <div key={index} className={"ptt-channels-videochat-availability"}>
				{userHasVideoChat() ?
					<>
						{phoneLine.iDoNotDisturb === 1 ?
							getIcon("videochatDoNotDisturb", "small", "plain", "ptt-channels-videochat-do-not-disturb-svg") :
							<FontAwesomeIcon
								className="ptt-channels-videochat-icon"
								transform={"shrink-1"}
								icon={faVideo}
							/>
						}
					</>
					:
					<span>
						<IconButton
							className="ptt-channels-line-forward-icon"
							disabled={false}
						>
							{getIcon("videochatNoDevice", "small", "plain", "")}
						</IconButton>
					</span>

				}

				<Typography className={"ptt-information-text"}>{[theSharedTranslationManager.getTranslation("IDS_CHAT_MSG_VIDEOCHAT")]}</Typography>
			</div>;
		}
		if (phoneLine && phoneLine.u8sLinePhoneNumber.search("phys=Mobile") !== -1) {
			div = <div key={index} className={"ptt-channels-dial-in"}>
				{phoneLine.iState == 1 ?
					<>
						<FontAwesomeIcon
							className="ptt-channels-dial-in-icon"
							transform={"shrink-1"}
							icon={faPhone}
						/>
						<Typography className={"ptt-channels-dial-in-number"}>[{formatPhoneNumberIntl(phoneLine.u8sLineNumber)}]</Typography>
					</>
					:
					<>
						<span>
							<IconButton
								className="ptt-channels-line-forward-icon"
								disabled={false}
							>
								{getIcon("phoneOffline", "small", "plain", "ptt-channels-line-forward-svg-icon")}
							</IconButton>
						</span>
						<Typography className={"ptt-channels-dial-in-number"}>[{formatPhoneNumberIntl(phoneLine.u8sLineNumber)}] {" " + theSharedTranslationManager.getTranslation("IDS_LINE_OFFLINE")}</Typography>
					</>
				}
			</div>;
		}
		return div;
	};

	const getPresenceIcon = () => {
		return <div>
			{textPresence === theSharedTranslationManager.getTranslation("IDS_PRESENCE_AVAILABLE")
				? <div className={"ptt-heading-presence-available"}>
					<span className={"ptt-heading-white-circle"}></span>
				</div>
				: null}

			{textPresence === theSharedTranslationManager.getTranslation("IDS_PRESENCE_BUSY")
				? <div className={"ptt-heading-presence-busy"}>
					<span className={"ptt-heading-white-circle"}></span>
				</div>
				: null}

			{textPresence === theSharedTranslationManager.getTranslation("IDS_PRESENCE_DND")
				? <div className={"ptt-heading-presence-dnd"}>
					<div className="ptt-heading-rec"></div>
					<span className={"ptt-heading-white-circle"}></span>
				</div>
				: null}

			{
				textPresence === theSharedTranslationManager.getTranslation("IDS_PRESENCE_ABSENT")
					? <div className={"ptt-heading-presence-absent"}>
						<span className={"ptt-heading-white-circle"}></span>
					</div>
					: null
			}
			{
				textPresence === theSharedTranslationManager.getTranslation("IDS_PRESENCE_IDLE")
					? <div className={"ptt-heading-presence-idle"}>
						<span className={"ptt-heading-white-circle"}></span>
					</div>
					: null
			}
			{
				textPresence === theSharedTranslationManager.getTranslation("IDS_PRESENCE_OFFLINE")
					? <div className={"ptt-heading-presence-unavailable"}>
						<span className={"ptt-heading-white-circle"}></span>
					</div>
					: null
			}
			{
				textPresence === theSharedTranslationManager.getTranslation("IDS_UNKNOWN")
					? <div className={"ptt-heading-presence-unavailable"}>
						<span className={"ptt-heading-white-circle"}></span>
					</div>
					: null
			}
			{
				textPresence === theSharedTranslationManager.getTranslation("IDS_CALL_RINGING")
					? <div className={"ptt-heading-presence-ringing"}>
						<span className={"ptt-heading-white-circle"}></span>
					</div>
					: null
			}
		</div>;
	};

	useEffect(() => {
		let statusText = "";

		/* AVAILABILITY SECTION */
		if (props.contact !== undefined) {
			let contactName: string | undefined = "";
			if (props.contact?.asnNetDatabaseContact === undefined)
				contactName = theSharedTranslationManager.getTranslation("IDS_UNKNOWN_USER");
			else
				contactName = props.contact?.asnNetDatabaseContact?.u8sDisplayName;
			setContactName(contactName);
		}

		if (props.contact?.presence !== undefined) {
			let label = "";
			if (props?.contact?.presence === AsnPresenceStateEnum.eUNCONTROLLED)
				label = theSharedTranslationManager.getTranslation("IDS_UNKNOWN");
			else if (props.contact?.presence & AsnPresenceStateFlags.eRINGING)
				label = theSharedTranslationManager.getTranslation("IDS_CALL_RINGING");
			else {
				if (iPresenceStateToString(props.contact.presence) === "available")
					label = theSharedTranslationManager.getTranslation("IDS_PRESENCE_AVAILABLE");
				else if (iPresenceStateToString(props.contact.presence) === "busy")
					label = theSharedTranslationManager.getTranslation("IDS_PRESENCE_BUSY");
				else if (iPresenceStateToString(props.contact.presence) === "dnd")
					label = theSharedTranslationManager.getTranslation("IDS_PRESENCE_DND");
				else if (iPresenceStateToString(props.contact.presence) === "absent")
					label = theSharedTranslationManager.getTranslation("IDS_PRESENCE_ABSENT");
				else if (iPresenceStateToString(props.contact.presence) === "idle")
					label = theSharedTranslationManager.getTranslation("IDS_PRESENCE_IDLE");
				else if (iPresenceStateToString(props.contact.presence) === "unavailable")
					label = theSharedTranslationManager.getTranslation("IDS_PRESENCE_OFFLINE");
			}

			setTextPresence(label);
		}

		if (props.contact?.presence) {
			if (props.contact?.presence & AsnPresenceStateFlags.eCALL)
				statusText = theSharedTranslationManager.getTranslation("IDS_CALL_CONNECTED");
			else if (props.contact?.presence & AsnPresenceStateFlags.eRINGING)
				statusText = theSharedTranslationManager.getTranslation("IDS_CALL_RINGING");
			else if (props.contact?.presence & AsnPresenceStateFlags.eCONFERENCING)
				statusText = theSharedTranslationManager.getTranslation("IDS_HAS_MEETING");
			setStatusText(statusText);
		}

		if (props.contact?.seqCalls) {
			setOutBound(props.contact?.seqCalls[0]?.iOutbound);
			if (props.contact?.seqCalls[0]?.remoteContact?.u8sDisplayName !== undefined)
				setCallContactDisplayName(props.contact?.seqCalls[0]?.remoteContact?.u8sDisplayName);
			else {
				let contactDisplayName = "[" + formatPhoneNumberIntl(props.contact?.seqCalls[0]?.u8sCurrentNumberSC) + "]";
				setCallContactDisplayName(contactDisplayName);
			}

		}

		/* INFORMATION SECTION */
		if (props.contact?.customNote !== undefined)
			setCustomNote(props.contact.customNote);

		/* CHANNELS */
		if (props.contact?.msTeamsEmail !== undefined) {
			if (userHasTeams())
				setTeamsAvailable(true);
			else
				setTeamsAvailable(false);
		}

		/* CURRENT APPOINTMENT SECTION */
		if (props.contact?.currentAppointments !== undefined) {
			const currentAppointments = props.contact?.currentAppointments;
			let validAppointments: AsnAppointmentEntry[] = [];
			for (const appointment of currentAppointments) {
				const isValid = appointment?.bIsValid ? appointment?.bIsValid : false;
				if (isValid)
					validAppointments.push(appointment);
			}
			if (validAppointments.length > 3)
				validAppointments = validAppointments.slice(0, 3);
			setValidAppointments(validAppointments);
		}

		/* NEXT APPOINTMENT SECTION */
		if (props.contact?.nextAppointment !== undefined && props.contact?.nextAppointment.bIsValid) {
			const nextAppointment = props.contact?.nextAppointment;
			setNextValidAppointment(nextAppointment);
		}

		if (props.contact?.absentState !== undefined) {
			let reachability: number;
			if (props.contact?.absentState?.optionalParams) {
				for (const [key, value] of Object.entries(props.contact?.absentState?.optionalParams)) {
					if (key === "iReachability" && typeof value === "number") {
						reachability = value;
						setReachabilityType(reachability);
						break;
					}
				}
			}
		}

		if (props.contact?.agentState !== undefined)
			setAgentState(props.contact.agentState);

		/* PHONE NUMBER SECTION */
		if (props.contact?.seqPhoneLines !== undefined)
			setPhoneLines(props.contact.seqPhoneLines);

		if (props.contact?.seqLineForwards !== undefined)
			setLinesForward(props.contact.seqLineForwards);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.contact, props.nextAppointment, props.currentAppointment]);

	useEffect(() => {
		let message = "";
		if (props.contact?.presence) {
			if (props.contact?.presence & AsnPresenceStateFlags.eCALL) {
				message = theSharedTranslationManager.getTranslation("IDS_CHANNEL_CONVERSATION");
			}
			else if (props.contact?.presence & AsnPresenceStateFlags.eCONFERENCING) {
				message = theSharedTranslationManager.getTranslation("IDS_CHANNEL_MEETING");
			}
		}

		if (props.contact?.msTeamsEmail && message === "") {
			switch (iPresenceStateToString(props.contact.msTeamsEmail?.value)) {
				case "available":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_AVAILABLE");
					break;
				case "busy":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_BUSY");
					break;
				case "absent":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_ABSENT");
					break;
				case "dnd":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_DND");
					break;
				case "unavailable":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_OFFLINE");
					break;
				default:
					message = theSharedTranslationManager.getTranslation("");
					break;
			}
		}
		setTeamsStatusText(message);
	}, [props.contact?.msTeamsEmail, props.contact?.presence]);

	return (
		<Menu
			id="basic-menu"
			data-cy={"shared-presence-tooltip"}
			anchorEl={anchorEl}
			onClose={() => {
				if (props.close)
					props.close();
			}}
			open={Boolean(anchorEl)}
			MenuListProps={{
				id: "basic-button",
				"aria-labelledby": "basic-button"
			}}
			sx={{ pointerEvents: "none" }}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (props.close)
					props.close()
			}}
		>
			<div id={"ptt-main"}>
				<div className={"ptt-heading"}>

					<div className={"ptt-heading-name-section"}>
						<Typography className={"ptt-heading-username"}>{contactName}</Typography>
						{theSharedBrowser.is.touchScreen
							? <>
								<IconButton
									name="plain"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										if (props.close)
											props.close()
									}}
									sx={{
										paddingRight: "0px",
										marginRight: "0px",
										marginLeft: "32px"
									}}
								>
									<FontAwesomeIcon icon={faClose} transform={"shrink-1"} />
								</IconButton>
							</>
							: null
						}
					</div>

					<div className={"ptt-heading-status"}>
						{getPresenceIcon()}
						<Typography className={"ptt-information-head"}>{textPresence}</Typography>
					</div>

					{textPresence !== theSharedTranslationManager.getTranslation("IDS_UNKNOWN")
						? <div className={"ptt-heading-presence"}>
							<Typography className={"ptt-information-text"}> {statusText !== "" ? statusText + " " : theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_HEADER") + " " + "(" + textPresence + ")"}</Typography>
							{
								(statusText === theSharedTranslationManager.getTranslation("IDS_CALL_CONNECTED") || statusText === theSharedTranslationManager.getTranslation("IDS_CALL_RINGING"))
									? <>
										{(outBound === 0)
											? <FontAwesomeIcon className="ptt-heading-arrow-right" transform={"shrink-1"} icon={faArrowLeft} />
											: <FontAwesomeIcon className="ptt-heading-arrow-left" transform={"shrink-1"} icon={faArrowRight} />}
										<Typography className={"ptt-information-text"}> {" " + callContactDisplayName} </Typography>
									</>
									: null
							}
						</div>
						: null
					}
				</div>

				{textPresence !== theSharedTranslationManager.getTranslation("IDS_UNKNOWN")
					? <>
						<div className={"ptt-information"}>
							<Typography className={"ptt-information-head"}>{theSharedTranslationManager.getTranslation("IDS_INFORMATION_HEADER")}</Typography>
							{getMobileAvailability()
								? <div className={"ptt-information-mobile-login"}>
									<FontAwesomeIcon
										className={"ptt-information-mobile-login-icon"}
										transform={"shrink-1"}
										icon={faMobileButton}
									/>
									<Typography className={"ptt-information-text"}>{theSharedTranslationManager.getTranslation("IDS_STATE_AVAILABLE_MOBILE")}</Typography>
								</div>
								: null
							}

							{(agentState !== AsnAgentStateEnum.eAGENTSTATENOAGENT)
								? <div className={"ptt-information-sign-in"}>
									<FontAwesomeIcon
										className={"ptt-information-sign-in-icon"}
										transform={"shrink-1"}
										icon={getAgentStateIcon()}
									/>
									<Typography className={"ptt-information-text ptt-information-sign-in-text"}>{getAgentStateText()}</Typography>
								</div>
								: null
							}

							{(reachabilityType !== AsnReachabilityEnum.eREACHABILITYNORMAL)
								? <div className={"ptt-information-sign-in"}>
									<FontAwesomeIcon
										className={"ptt-information-sign-in-icon"}
										transform={"shrink-1"}
										icon={getReachabilityTypeIcon()}
									/>
									<Typography className={"ptt-information-text ptt-information-sign-in-text"}>{getReachabilityText()}</Typography>
								</div>
								: null
							}

							{(customNote !== "")
								? <div className={"ptt-information-time"}>
									<p>
										<FontAwesomeIcon
											className={"ptt-information-time-icon"}
											transform={"shrink-1"}
											icon={faCircleInfo}
										/>
										{customNote}
									</p>
								</div>
								: null
							}
						</div>
						{(reachabilityType !== AsnReachabilityEnum.eREACHABILITYVACATION) ?
							<div className={"ptt-channels"}>
								<Typography className={"ptt-information-head"}>{theSharedTranslationManager.getTranslation("IDS_CHANNELS_HEADER")}</Typography>
								<>
									{LinesForward.length > 0 ?
										LinesForward.map((LineForward: AsnPresenceForward, index: number) => (
											<div key={index} className={"ptt-channels-lineforward"}>
												<IconButton
													className="ptt-channels-line-forward-icon"
													disabled={false}
												>
													{getIcon("lineForward", "small", "plain", "ptt-channels-line-forward-svg-icon")}
												</IconButton>
												<Typography className={"ptt-information-text"}>
													[{LineForward.u8sLineNumber.substring(15, 12)}] {theSharedTranslationManager.getTranslation("IDS_CALL_FORWARD")} [{LineForward.u8sDestination.substring(15, 12)}]
												</Typography>
											</div>
										))
										: null
									}
								</>
								<>
									{phoneLines.map((phoneLine: AsnPresenceLine, index: number) => (
										getPhoneLineChannelSection(phoneLine, index)
									))}
								</>
								{statusText === theSharedTranslationManager.getTranslation("IDS_HAS_MEETING")
									? <div className={"ptt-channels-in-a-meeting"}>
										<span>
											<IconButton
												className={"ptt-channels-in-a-meeting-icon"}
												disabled={false}
											>
												{getIcon("meetings", "small", "plain", "ptt-channels-meetings-svg-icon")}
											</IconButton>
										</span>

										<Typography className={"ptt-information-text"}>{theSharedTranslationManager.getTranslation("IDS_HAS_MEETING")}</Typography>
									</div>
									: null
								}
								{(teamsAvailable)
									? <div className={"ptt-channels-availability"}>
										<IconButton
											className="ptt-channels-teams-icon"
											disabled={false}
										>
											{
												getIcon("windowsTeams", "small", "plain", getTeamsStatus() + " " + getTeamsStatusForOuterCircle())
											}
										</IconButton>
										<Typography className={"ptt-information-text ptt-channels-availability-text"}>{teamsStatusText}</Typography>
									</div>
									: null}
							</div> : null
						}
						<div className={"ptt-appointments"}>
							{validAppointments.map((appointment, index) =>
								<div key={"pt_" + index}>
									{(index === 0)
										? <Typography className={"ptt-information-head"}>{theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_HEADER")}</Typography>
										: null
									}
									<div className={"ptt-appointments-info"}>
										{(getCurrentAppointmentTime(index) !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_NEXT24HRS"))
											? <FontAwesomeIcon
												className={getCalendarStatus(appointment)}
												transform={"shrink-1"}
												icon={faCalendar}
											/>
											: null
										}
										<Typography className={"ptt-information-text"}>{getCurrentAppointmentTime(index)}</Typography>
									</div>
									{getCurrentAppointmentSubject(appointment) && getCurrentAppointmentSubject(appointment) !== ""
										? <div className={"ptt-appointment-mobile-office"}>
											<FontAwesomeIcon
												className="ptt-appointment-mobile-office-icon"
												transform={"shrink-1"}
												icon={faMessage}
											/>

											<Typography className={"ptt-information-text"}>{getCurrentAppointmentSubject(appointment)}</Typography>
										</div>
										: null
									}
									{(getCurrentAppointmentLocation(appointment) !== "")
										? <div className={"ptt-appointment-location-info"}>
											<FontAwesomeIcon
												className="ptt-appointment-location-icon"
												transform={"shrink-1"}
												icon={faLocationPin}
											/>
											<Typography className={"ptt-information-text"}>{getCurrentAppointmentLocation(appointment)}</Typography>
										</div>
										: null
									}
									<div className={"ptt-appointment-empty-section"}></div>
								</div>
							)}
						</div>
						{(nextValidAppointment?.stStart)
							? <div className={"ptt-next-appointment"}>
								<Typography className={"ptt-information-head"}>{theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NEXT")}</Typography>
								<div className={"ptt-next-appointment-calendar-info"}>
									{(getNextAppointmentTime() !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_FURTHER_NEXT24HRS") && getNextAppointmentTime() !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_NEXT24HRS"))
										? <FontAwesomeIcon
											className={getNextAppointmentCalendarStatus()}
											transform={"shrink-1"}
											icon={faCalendar}
										/>
										: null
									}
									<Typography className={"ptt-information-text"}>{getNextAppointmentTime()}</Typography>
								</div>
								{(getNextAppointmentTime() !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_FURTHER_NEXT24HRS") && getNextAppointmentTime() !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_NEXT24HRS"))
									? <>
										{getNextAppointmentSubject() && getNextAppointmentSubject() !== ""
											? <div className={"ptt-next-appointment-preparation-info"}>
												<FontAwesomeIcon
													className="ptt-next-appointment-preparation-icon"
													transform={"shrink-1"}
													icon={faMessage}
												/>
												<Typography className={"ptt-information-text"}>{getNextAppointmentSubject()}</Typography>
											</div>
											: null
										}
										{(getNextAppointmentLocation() !== "")
											? <div className={"ptt-next-appointment-location-info"}>
												<FontAwesomeIcon
													className="ptt-next-appointment-location-icon"
													transform={"shrink-1"}
													icon={faLocationPin}
												/>
												<Typography className={"ptt-information-text"}>{getNextAppointmentLocation()}</Typography>
											</div>
											: null
										}
									</>
									: null
								}
							</div>
							: null
						}
					</>
					: null
				}
			</div>
		</Menu >
	);
});
