import { AsnNetDatabaseContact } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { AsnReachabilityEnum } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import {
	AsnPresence,
	AsnPresenceLines,
	AsnPresenceSubscriptionFlags,
	AsnUpdatePresenceArgument
} from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_PresenceV2";
import { IContactContainer } from "../../../web-shared-components/interfaces/interfaces";
import { IRemoteContactCD } from "../../models/ModelContent";

/**
 *
 * @param ssPresence
 */
export function getNextAppointment(ssPresence: AsnPresence) {
	if (
		ssPresence.asnNextAppointment &&
		ssPresence.asnNextAppointment.u8sSubject &&
		ssPresence.asnNextAppointment.stEnd &&
		ssPresence.asnNextAppointment.stStart
	) {
		const appointment = {
			u8sSubject: ssPresence.asnNextAppointment.u8sSubject,
			stStart: ssPresence.asnNextAppointment.stStart,
			stEnd: ssPresence.asnNextAppointment.stEnd
		};
		return appointment;
	} else {
		return undefined;
	}
}

/**
 * Get reachability
 * @param presence
 * @returns - the reachability as defined in AsnReachabilityEnum
 */
export const getReachability = (presence: AsnPresence): AsnReachabilityEnum => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const bArgumentValid = typeof presence?.asnAbsentState?.optionalParams?.iReachability === "number";
	if (bArgumentValid) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const reachability: AsnReachabilityEnum = presence?.asnAbsentState?.optionalParams?.iReachability;
		return reachability;
	}
	return AsnReachabilityEnum.eREACHABILITYNORMAL;
};

/**
 *
 * @param asnPresence
 * @param ssPresence
 */
export function getRemoteContact(ssPresence: AsnPresence): IRemoteContactCD | undefined {
	if (
		ssPresence.seqCalls &&
		ssPresence.seqCalls.length > 0 &&
		ssPresence.seqCalls[0].remoteContact &&
		ssPresence.seqCalls[0].remoteContact.u8sDisplayName
	) {
		const remoteContact: IRemoteContactCD = {
			outbound: ssPresence.seqCalls[0].iOutbound ? ssPresence.seqCalls[0].iOutbound : 0,
			displayName: ssPresence.seqCalls[0].remoteContact.u8sDisplayName
		};
		return remoteContact;
	} else {
		return undefined;
	}
}

/**
 * Get the changed properties from the argument and return them
 * @param asnUpdatePresenceArgument
 * @returns - the changed properties
 */
export function getChangedProperties(
	asnUpdatePresenceArgument: AsnUpdatePresenceArgument
): [contactContainer: Partial<Omit<IContactContainer, "contactID">>, remoteContact: AsnNetDatabaseContact | undefined] {
	const iChangedFlags: number = asnUpdatePresenceArgument.iChangedFlags;
	const presence: AsnPresence = asnUpdatePresenceArgument.presence;

	const getPresenceUpdate = () => {
		if (
			(AsnPresenceSubscriptionFlags.ePresenceSubscriptionPresence |
				AsnPresenceSubscriptionFlags.ePresenceSubscriptionAbsentstate) &
			iChangedFlags
		) {
			const bArgumentValid = typeof asnUpdatePresenceArgument.presence.iPresenceState === "number";
			if (bArgumentValid) {
				const pres: number | undefined = presence.iPresenceState;
				return pres;
			}
		}
		return undefined;
	};

	const getReachabilityUpdate = (): AsnReachabilityEnum => {
		if (
			(AsnPresenceSubscriptionFlags.ePresenceSubscriptionPresence |
				AsnPresenceSubscriptionFlags.ePresenceSubscriptionAbsentstate) &
			iChangedFlags
		) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const bArgumentValid = typeof presence?.asnAbsentState?.optionalParams?.iReachability === "number";
			if (bArgumentValid) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const reachability: AsnReachabilityEnum = presence?.asnAbsentState?.optionalParams?.iReachability;
				return reachability;
			}
		}
		return AsnReachabilityEnum.eREACHABILITYNORMAL;
	};

	const getNoteUpdate = () => {
		if (AsnPresenceSubscriptionFlags.ePresenceSubscriptionNote & iChangedFlags) {
			const note: string | undefined =
				presence.asnCustomNote && presence.asnCustomNote.u8sNote ? presence.asnCustomNote.u8sNote : undefined;

			return note;
		}
		return undefined;
	};
	const getAppointmentUpdate = () => {
		if (AsnPresenceSubscriptionFlags.ePresenceSubscriptionAppointment & iChangedFlags) {
			const appointment = {
				u8sSubject:
					presence.asnNextAppointment && presence.asnNextAppointment.u8sSubject
						? presence.asnNextAppointment.u8sSubject
						: "",
				stStart:
					presence.asnNextAppointment && presence.asnNextAppointment.stStart
						? presence.asnNextAppointment.stStart.toString()
						: "",
				stEnd:
					presence.asnNextAppointment && presence.asnNextAppointment.stEnd
						? presence.asnNextAppointment.stEnd.toString()
						: ""
			};
			return appointment;
		}
		return undefined;
	};
	const getPhoneLinesUpdate = () => {
		if (AsnPresenceSubscriptionFlags.ePresenceSubscriptionPhoneLines & iChangedFlags) {
			const seqPhoneLines: AsnPresenceLines | undefined = presence.seqPhoneLines;

			return seqPhoneLines;
		}
		return undefined;
	};

	const getRemoteContactUpdate = () => {
		if (AsnPresenceSubscriptionFlags.ePresenceSubscriptionPhoneCalls & iChangedFlags) {
			return presence.asnRemoteContact;
		}

		return undefined;
	};
	const getSeqCallsUpdate = () => {
		if (AsnPresenceSubscriptionFlags.ePresenceSubscriptionPhoneCalls & iChangedFlags) {
			return presence.seqCalls;
		}

		return undefined;
	};

	const updatedPresence = {
		...(getPresenceUpdate() && { presence: getPresenceUpdate() }),
		...(getNoteUpdate() && { customNote: getNoteUpdate() }),
		...(getAppointmentUpdate() && { asnNextAppointment: getAppointmentUpdate() }),
		...(getReachabilityUpdate() && { reachability: getReachabilityUpdate() }),
		...(getPhoneLinesUpdate() && { seqPhoneLines: getPhoneLinesUpdate() }),
		...(getSeqCallsUpdate() && { seqCalls: getSeqCallsUpdate() })
	};

	return [updatedPresence, getRemoteContactUpdate()];
}
