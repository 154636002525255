import "./ContactButtonCUrl.scss";

import React from "react";

import { SubTypeE } from "../../../models/ModelConfig";
import { ICombiDetail } from "../../../models/ModelContact";
// import * as APIcc from "../../apis/APIConnectCommon";

/**
 * Contact URLS: Application-Anbindung von Peter Simon
 *
 * Entscheidungs-matrix:  Browser-Consent   Schema-registrierung   Portabel
 * goToHttp(s)Link        JA                Registry               JA          (URL wird im Default Browser aufgerufen)
 * goToExternalApp        NEIN              ActionManager          NEIN        (API wird aufgerufen)
 *
 * [React Event Handlers with TypeScript](https://www.carlrippon.com/React-event-handlers-with-typescript/)
 *
 * @param props - the props passed from the parent
 * @returns the element itself
 */

interface IButtonCUProps {
	detail: ICombiDetail;
	type: "cd" | "cw" | "ch";
}
export const ContactButtonCUrl = (props: IButtonCUProps): JSX.Element => {
	const detail: ICombiDetail = props.detail;
	const prefix = props.type.toString();

	const label: string = detail && detail.displayLabel ? detail.displayLabel.slice(0, 80) : "";

	// if Contact URL is declared as goToExternalApp (Call custom API):
	if (detail.subtype === SubTypeE.APP) {
		const fetchData = (event: React.SyntheticEvent): void => {
			// APIcc.OpenExternalAppPerApi(detail.content);
			event.preventDefault();
		};

		return (
			<div className={prefix === "cd" ? "cdBCu_buttonWrap" : undefined}>
				<button className={prefix + "BCu_button"} onClick={fetchData}>
					{label}
				</button>
			</div>
		);
	} else if (detail.subtype === SubTypeE.LNK) {
		// if Contact URL is declared as goToHttp(s)Link (Call HTTP / HTTPS URL):
		const clickable: string =
			detail.content.indexOf("http://") < 0 && detail.content.indexOf("https://") < 0
				? "https://" + detail.content
				: detail.content;

		return (
			<div className={prefix + "BCu_buttonWrap"}>
				<a href={clickable} target="_blank" rel="noreferrer">
					<button className={prefix + "BCu_button"}>{label}</button>
				</a>
			</div>
		);
	} else {
		return <></>;
	}
};
