// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_Transport.ts
 * "UC-Server-Access-Protocol-Transport" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.23, 20.10.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * Transport layer interface
 * ## Module description
 * The module defines operations on the transport layer of the business logic.
 * This includes asnStartTLS which activates TLS on a already existing TCP connection and additional operations for
 * running multiplexed connections to the UCServer (several sessions of different users on one TCP connection; creating, deleting them and user authentication).
 *
 * @private
 */
// [PrintTSImports]
import { TSDeprecatedCallback } from "./TSDeprecatedCallback";
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Auth from "./ENetUC_Auth";
// [PrintTSRootTypes]
export const moduleName = "ENetUC_Transport";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnRegisterServerErrorEnum {
	general = 0,
	invalidProtocolVersion = 1,
	invalidServerID = 2,
	invalidLogin = 3,
	/** @deprecated 09.06.2023 redirect is no longer an error but a regular response with asnRegisterServerEx */
	redirect = 4,
	/** UCSID is already connected */
	duplicateLogon = 5
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnWebServiceLogonErrorEnum {
	general = 0,
	invalidProtocolVersion = 1,
	invalidUser = 2,
	invalidPassword = 3
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnStartTLSArgument {
	public constructor(that?: AsnStartTLSArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnStartTLSArgument {
		return new AsnStartTLSArgument();
	}

	public static type = "AsnStartTLSArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnStartTLSArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnStartTLSResult {
	public constructor(that?: AsnStartTLSResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnStartTLSResult {
		return new AsnStartTLSResult();
	}

	public static type = "AsnStartTLSResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnStartTLSResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnStartMultiUserModeArgument {
	public constructor(that?: AsnStartMultiUserModeArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnStartMultiUserModeArgument {
		return new AsnStartMultiUserModeArgument();
	}

	public static type = "AsnStartMultiUserModeArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnStartMultiUserModeArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnStartMultiUserModeResult {
	public constructor(that?: AsnStartMultiUserModeResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnStartMultiUserModeResult {
		return new AsnStartMultiUserModeResult();
	}

	public static type = "AsnStartMultiUserModeResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnStartMultiUserModeResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnCreateUserSessionArgument {
	public constructor(that: AsnCreateUserSessionArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnCreateUserSessionArgument {
		return new AsnCreateUserSessionArgument({
			negotiate: ENetUC_Auth.AsnNegotiateServerConnectionArgument["initEmpty"].call(0)
		});
	}

	public static type = "AsnCreateUserSessionArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCreateUserSessionArgument",
			...params,
			value: [
				ENetUC_Auth.AsnNegotiateServerConnectionArgument.getASN1Schema({ name: "negotiate" })
			]
		});
	}

	public negotiate!: ENetUC_Auth.AsnNegotiateServerConnectionArgument;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnCreateUserSessionResult {
	public constructor(that: AsnCreateUserSessionResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnCreateUserSessionResult {
		return new AsnCreateUserSessionResult({
			u8sSessionID: "",
			negotiateresult: ENetUC_Auth.AsnNegotiateServerConnectionResult["initEmpty"].call(0)
		});
	}

	public static type = "AsnCreateUserSessionResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCreateUserSessionResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sSessionID" }),
				ENetUC_Auth.AsnNegotiateServerConnectionResult.getASN1Schema({ name: "negotiateresult" })
			]
		});
	}

	/** SessionID of the created user session. All further invokes for this user session must include this SessionID. */
	public u8sSessionID!: string;
	public negotiateresult!: ENetUC_Auth.AsnNegotiateServerConnectionResult;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnDestroyUserSessionArgument {
	public constructor(that: AsnDestroyUserSessionArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnDestroyUserSessionArgument {
		return new AsnDestroyUserSessionArgument({
			u8sSessionID: ""
		});
	}

	public static type = "AsnDestroyUserSessionArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDestroyUserSessionArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sSessionID" })
			]
		});
	}

	public u8sSessionID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnDestroyUserSessionResult {
	public constructor(that?: AsnDestroyUserSessionResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnDestroyUserSessionResult {
		return new AsnDestroyUserSessionResult();
	}

	public static type = "AsnDestroyUserSessionResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDestroyUserSessionResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUserSessionDestroyedArgument {
	public constructor(that: AsnUserSessionDestroyedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUserSessionDestroyedArgument {
		return new AsnUserSessionDestroyedArgument({
			u8sSessionID: ""
		});
	}

	public static type = "AsnUserSessionDestroyedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUserSessionDestroyedArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sSessionID" })
			]
		});
	}

	public u8sSessionID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRegisterExternalServiceResult {
	public constructor(that?: AsnRegisterExternalServiceResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRegisterExternalServiceResult {
		return new AsnRegisterExternalServiceResult();
	}

	public static type = "AsnRegisterExternalServiceResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRegisterExternalServiceResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetExternalServiceProfileArgument {
	public constructor(that: AsnGetExternalServiceProfileArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetExternalServiceProfileArgument {
		return new AsnGetExternalServiceProfileArgument({
			iVersion: 0,
			u8sToken: ""
		});
	}

	public static type = "AsnGetExternalServiceProfileArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetExternalServiceProfileArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iVersion" }),
				new asn1ts.Utf8String({ name: "u8sToken" }),
				new asn1ts.Integer({ name: "iFlags", idBlock: { optionalID: 0 } })
			]
		});
	}

	/** Version of the protocol */
	public iVersion!: number;
	/** Client token */
	public u8sToken!: string;
	/** for future use */
	public iFlags?: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetExternalServiceProfileResult {
	public constructor(that?: AsnGetExternalServiceProfileResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetExternalServiceProfileResult {
		return new AsnGetExternalServiceProfileResult();
	}

	public static type = "AsnGetExternalServiceProfileResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetExternalServiceProfileResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRegisterServerArgument {
	public constructor(that: AsnRegisterServerArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRegisterServerArgument {
		return new AsnRegisterServerArgument({
			iVersion: 0,
			u8sVersion: "",
			u8sServerName: "",
			u8sServerID: "",
			u8sServerPassword: "",
			presenceDomains: new ENetUC_Common.UTF8StringList(),
			u8sSystemID: "",
			iInterfaceVersion: 0
		});
	}

	public static type = "AsnRegisterServerArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRegisterServerArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iVersion" }),
				new asn1ts.Utf8String({ name: "u8sVersion" }),
				new asn1ts.Utf8String({ name: "u8sServerName" }),
				new asn1ts.Utf8String({ name: "u8sServerID" }),
				new asn1ts.Utf8String({ name: "u8sServerPassword" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "presenceDomains" }),
				new asn1ts.Utf8String({ name: "u8sSystemID" }),
				new asn1ts.Integer({ name: "iInterfaceVersion" }),
				new asn1ts.Utf8String({ name: "u8sJWTPublicKey", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sHardwareID", idBlock: { optionalID: 1 } })
			]
		});
	}

	/** version of the protocol */
	public iVersion!: number;
	/** Server Version (z.B. 5.0.0.100) */
	public u8sVersion!: string;
	/** Server Name FQDN */
	public u8sServerName!: string;
	/** Server UUID (UCSID) */
	public u8sServerID!: string;
	/** Server Password */
	public u8sServerPassword!: string;
	/** Liste der Presence Domains */
	public presenceDomains!: ENetUC_Common.UTF8StringList;
	/** Server System Identifier */
	public u8sSystemID!: string;
	/** interface version of ucserver */
	public iInterfaceVersion!: number;
	/** Public key (falls vorhanden) zum validieren des Tokens */
	public u8sJWTPublicKey?: string;
	/** HardwareID for license service */
	public u8sHardwareID?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnWebServiceCapabilities {
	public constructor(that: AsnWebServiceCapabilities) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebServiceCapabilities {
		return new AsnWebServiceCapabilities({
			u8sType: ""
		});
	}

	public static type = "AsnWebServiceCapabilities";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebServiceCapabilities",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sType" }),
				new asn1ts.Utf8String({ name: "u8sValue", optional: true }),
				new asn1ts.Integer({ name: "iValue", optional: true })
			]
		});
	}

	/** type of Capability (asnWebServiceCapabilityTypeTurn, ...) */
	public u8sType!: string;
	/** additional Information about this Capability */
	public u8sValue?: string;
	/** additional Information about this Capability */
	public iValue?: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @deprecated 09.06.2023 *
 * @private
 */
export class AsnRegisterServerError {
	public constructor(that: AsnRegisterServerError) {
		TSDeprecatedCallback.deprecatedObject(1686268800, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRegisterServerError {
		return new AsnRegisterServerError({
			iErrorDetail: 0,
			u8sErrorString: ""
		});
	}

	public static type = "AsnRegisterServerError";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRegisterServerError",
			...params,
			value: [
				new asn1ts.Integer({ name: "iErrorDetail" }),
				new asn1ts.Utf8String({ name: "u8sErrorString" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "redirectAddresses", idBlock: { optionalID: 0 } })
			]
		});
	}

	public iErrorDetail!: number;
	public u8sErrorString!: string;
	/** redirect Addresses for this UCServer - tells which servers are responsible for this ServerID (enetcti:\/\/server:port or enetctis:\/\/server:port) */
	public redirectAddresses?: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRegisterServerRedirectResult {
	public constructor(that: AsnRegisterServerRedirectResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRegisterServerRedirectResult {
		return new AsnRegisterServerRedirectResult({
			redirectAddresses: new ENetUC_Common.UTF8StringList()
		});
	}

	public static type = "AsnRegisterServerRedirectResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRegisterServerRedirectResult",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "redirectAddresses" })
			]
		});
	}

	/** Redirect Addresses for this UCServer - tells which servers are responsible for this ServerID (enetcti:\/\/server:port or enetctis:\/\/server:port) */
	public redirectAddresses!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnWebServiceLogonResult {
	public constructor(that: AsnWebServiceLogonResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebServiceLogonResult {
		return new AsnWebServiceLogonResult({
			iVersion: 0,
			u8sVersion: "",
			u8sServerName: "",
			presenceDomains: new ENetUC_Common.UTF8StringList(),
			iInterfaceVersion: 0
		});
	}

	public static type = "AsnWebServiceLogonResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebServiceLogonResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iVersion" }),
				new asn1ts.Utf8String({ name: "u8sVersion" }),
				new asn1ts.Utf8String({ name: "u8sServerName" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "presenceDomains" }),
				new asn1ts.Integer({ name: "iInterfaceVersion" })
			]
		});
	}

	/** version of the protocol */
	public iVersion!: number;
	/** Server Version (z.B. 5.0.0.100) */
	public u8sVersion!: string;
	/** Server Name FQDN */
	public u8sServerName!: string;
	/** Liste der Presence Domains */
	public presenceDomains!: ENetUC_Common.UTF8StringList;
	/** interface version of the ucserver */
	public iInterfaceVersion!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnWebListenInterfaceStatus {
	public constructor(that: AsnWebListenInterfaceStatus) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebListenInterfaceStatus {
		return new AsnWebListenInterfaceStatus({
			u8sInterfaceGUID: "",
			u8sType: "",
			listenStatus: 0
		});
	}

	public static type = "AsnWebListenInterfaceStatus";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebListenInterfaceStatus",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sInterfaceGUID" }),
				new asn1ts.Utf8String({ name: "u8sType" }),
				new asn1ts.Integer({ name: "listenStatus" })
			]
		});
	}

	/** GUID of Listen Interface */
	public u8sInterfaceGUID!: string;
	/** Type of Listen Interface (http or https) */
	public u8sType!: string;
	/** Listen Status from EDataStructuresListeningInterfaceStatus */
	public listenStatus!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnTransportKeepAliveArgument {
	public constructor(that: AsnTransportKeepAliveArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnTransportKeepAliveArgument {
		return new AsnTransportKeepAliveArgument({
			dummy: null
		});
	}

	public static type = "AsnTransportKeepAliveArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnTransportKeepAliveArgument",
			...params,
			value: [
				new asn1ts.Null({ name: "dummy" })
			]
		});
	}

	public dummy!: null;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @deprecated 20.06.2023 -&gt; AsnWebGetIceServersExArgument
 * @private
 */
export class AsnWebGetIceServersArgument {
	public constructor(that?: AsnWebGetIceServersArgument) {
		TSDeprecatedCallback.deprecatedObject(1687219200, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebGetIceServersArgument {
		return new AsnWebGetIceServersArgument();
	}

	public static type = "AsnWebGetIceServersArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebGetIceServersArgument",
			...params,
			value: [
				new asn1ts.OctetString({ name: "sTurnProviderConfig", idBlock: { optionalID: 0 } })
			]
		});
	}

	/** json config for Turn Provider */
	public sTurnProviderConfig?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * WebRtc Browser compatible iceServers
 *
 * @private
 */
export class AsnWebIceServer {
	public constructor(that: AsnWebIceServer) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebIceServer {
		return new AsnWebIceServer({
			urls: new ENetUC_Common.UTF8StringList()
		});
	}

	public static type = "AsnWebIceServer";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebIceServer",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "urls" }),
				new asn1ts.Utf8String({ name: "username", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "credential", idBlock: { optionalID: 1 } })
			]
		});
	}

	/** uri list (Peerconnection supports String or Array, we choose Array here) */
	public urls!: ENetUC_Common.UTF8StringList;
	/** turn username to use */
	public username?: string;
	/** turn password to use */
	public credential?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * This function allows to get STUN and TURN configuration
 * This function is implemented in UCWeb and called by the UCServer
 * !!!! DRAFT !!!! -&gt; not yet implemented
 * asnWebGetIceServersEx OPERATION
 * ARGUMENT	arg AsnWebGetIceServersExArgument
 * RESULT		res AsnWebGetIceServersResult
 * ERRORS		{AsnRequestError}
 * ::= 1720
 *
 * @deprecated 20.06.2023 -&gt; AsnTokenVerifyExArgument
 * @private
 */
export class AsnTokenVerifyArgument {
	public constructor(that: AsnTokenVerifyArgument) {
		TSDeprecatedCallback.deprecatedObject(1687219200, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnTokenVerifyArgument {
		return new AsnTokenVerifyArgument({
			sToken: ""
		});
	}

	public static type = "AsnTokenVerifyArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnTokenVerifyArgument",
			...params,
			value: [
				new asn1ts.OctetString({ name: "sToken" })
			]
		});
	}

	/** JWT Token */
	public sToken!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnTokenVerifyResult {
	public constructor(that: AsnTokenVerifyResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnTokenVerifyResult {
		return new AsnTokenVerifyResult({
			stValidTo: new Date(),
			iType: 0,
			u8sDNPath: "",
			u8sUserName: "",
			u8sSIPURI: "",
			u8sobjectGUID: "",
			u8sPhoneNumber: "",
			u8sPhoneSecondary: "",
			u8sCallRecordingNumber: "",
			u8sMailboxNumber: "",
			u8sHomeServer: "",
			iUserStaticRights: 0,
			asnUserContact: ENetUC_Common.AsnNetDatabaseContact["initEmpty"].call(0),
			asnUserPropertyBag: ENetUC_Common.AsnUserPropertyBag["initEmpty"].call(0)
		});
	}

	public static type = "AsnTokenVerifyResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnTokenVerifyResult",
			...params,
			value: [
				new asn1ts.Real({ name: "stValidTo" }),
				new asn1ts.Integer({ name: "iType" }),
				new asn1ts.Utf8String({ name: "u8sDNPath" }),
				new asn1ts.Utf8String({ name: "u8sUserName" }),
				new asn1ts.Utf8String({ name: "u8sSIPURI" }),
				new asn1ts.Utf8String({ name: "u8sobjectGUID" }),
				new asn1ts.Utf8String({ name: "u8sPhoneNumber" }),
				new asn1ts.Utf8String({ name: "u8sPhoneSecondary" }),
				new asn1ts.Utf8String({ name: "u8sCallRecordingNumber" }),
				new asn1ts.Utf8String({ name: "u8sMailboxNumber" }),
				new asn1ts.Utf8String({ name: "u8sHomeServer" }),
				new asn1ts.Integer({ name: "iUserStaticRights" }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnUserContact" }),
				ENetUC_Common.AsnUserPropertyBag.getASN1Schema({ name: "asnUserPropertyBag" })
			]
		});
	}

	/** valid until */
	public stValidTo!: Date;
	/** Type of token (AsnUserTokenType: eUserTokenExternal or eUserTokenLogin) */
	public iType!: number;
	public u8sDNPath!: string;
	public u8sUserName!: string;
	public u8sSIPURI!: string;
	public u8sobjectGUID!: string;
	public u8sPhoneNumber!: string;
	public u8sPhoneSecondary!: string;
	public u8sCallRecordingNumber!: string;
	public u8sMailboxNumber!: string;
	public u8sHomeServer!: string;
	public iUserStaticRights!: number;
	public asnUserContact!: ENetUC_Common.AsnNetDatabaseContact;
	public asnUserPropertyBag!: ENetUC_Common.AsnUserPropertyBag;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnCheckConnectionArgument {
	public constructor(that?: AsnCheckConnectionArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnCheckConnectionArgument {
		return new AsnCheckConnectionArgument();
	}

	public static type = "AsnCheckConnectionArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCheckConnectionArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnCheckConnectionResult {
	public constructor(that: AsnCheckConnectionResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnCheckConnectionResult {
		return new AsnCheckConnectionResult({
			u8sServerVersion: "",
			u8sProductVersion: "",
			iProtocolVersion: 0,
			iOEMID: 0,
			u8sCookie: ""
		});
	}

	public static type = "AsnCheckConnectionResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCheckConnectionResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sServerVersion" }),
				new asn1ts.Utf8String({ name: "u8sProductVersion" }),
				new asn1ts.Integer({ name: "iProtocolVersion" }),
				new asn1ts.Integer({ name: "iOEMID" }),
				new asn1ts.Utf8String({ name: "u8sCookie" })
			]
		});
	}

	/** Server Version \"5.1.30.35169\" */
	public u8sServerVersion!: string;
	/** Product Version \"5.1.30.35169\" */
	public u8sProductVersion!: string;
	/** Protocol Version (60) */
	public iProtocolVersion!: number;
	/** estos : 0 */
	public iOEMID!: number;
	/** Cookie, das der Server vom Admin bekommt, um zu schauen, ob er das ist. */
	public u8sCookie!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRequestCERTokenArgument {
	public constructor(that?: AsnRequestCERTokenArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRequestCERTokenArgument {
		return new AsnRequestCERTokenArgument();
	}

	public static type = "AsnRequestCERTokenArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestCERTokenArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRequestCERTokenResult {
	public constructor(that: AsnRequestCERTokenResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRequestCERTokenResult {
		return new AsnRequestCERTokenResult({
			u8sToken: ""
		});
	}

	public static type = "AsnRequestCERTokenResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestCERTokenResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sToken" })
			]
		});
	}

	/** Token zum upload eines ErrorReports */
	public u8sToken!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetTimeArgument {
	public constructor(that?: AsnGetTimeArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetTimeArgument {
		return new AsnGetTimeArgument();
	}

	public static type = "AsnGetTimeArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetTimeArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetTimeResult {
	public constructor(that: AsnGetTimeResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetTimeResult {
		return new AsnGetTimeResult({
			stSystemtime: new Date()
		});
	}

	public static type = "AsnGetTimeResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetTimeResult",
			...params,
			value: [
				new asn1ts.Real({ name: "stSystemtime" })
			]
		});
	}

	/** Systemzeit auf dem Server */
	public stSystemtime!: Date;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnWebServiceCapabilitiesList extends Array<AsnWebServiceCapabilities> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnWebServiceCapabilities.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRegisterServerResult {
	public constructor(that?: AsnRegisterServerResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRegisterServerResult {
		return new AsnRegisterServerResult();
	}

	public static type = "AsnRegisterServerResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRegisterServerResult",
			...params,
			value: [
				AsnWebServiceCapabilitiesList.getASN1Schema({ name: "capabilities", optional: true })
			]
		});
	}

	public capabilities?: AsnWebServiceCapabilitiesList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnWebServiceLogonArgument {
	public constructor(that: AsnWebServiceLogonArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebServiceLogonArgument {
		return new AsnWebServiceLogonArgument({
			iVersion: 0,
			u8sVersion: "",
			u8sUser: "",
			u8sPassword: "",
			capabilities: new AsnWebServiceCapabilitiesList()
		});
	}

	public static type = "AsnWebServiceLogonArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebServiceLogonArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iVersion" }),
				new asn1ts.Utf8String({ name: "u8sVersion" }),
				new asn1ts.Utf8String({ name: "u8sUser" }),
				new asn1ts.Utf8String({ name: "u8sPassword" }),
				AsnWebServiceCapabilitiesList.getASN1Schema({ name: "capabilities" })
			]
		});
	}

	/** version of the protocol */
	public iVersion!: number;
	/** WebService Version (z.B. 5.0.0.100) */
	public u8sVersion!: string;
	/** User */
	public u8sUser!: string;
	/** Password */
	public u8sPassword!: string;
	/** Capabilities */
	public capabilities!: AsnWebServiceCapabilitiesList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnWebServiceCapabilitiesChangedArgument {
	public constructor(that?: AsnWebServiceCapabilitiesChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebServiceCapabilitiesChangedArgument {
		return new AsnWebServiceCapabilitiesChangedArgument();
	}

	public static type = "AsnWebServiceCapabilitiesChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebServiceCapabilitiesChangedArgument",
			...params,
			value: [
				AsnWebServiceCapabilitiesList.getASN1Schema({ name: "capabilities", optional: true })
			]
		});
	}

	public capabilities?: AsnWebServiceCapabilitiesList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnWebListenInterfaceStatusList extends Array<AsnWebListenInterfaceStatus> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnWebListenInterfaceStatus.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnWebServiceStatusArgument {
	public constructor(that: AsnWebServiceStatusArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebServiceStatusArgument {
		return new AsnWebServiceStatusArgument({
			interfaceList: new AsnWebListenInterfaceStatusList()
		});
	}

	public static type = "AsnWebServiceStatusArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebServiceStatusArgument",
			...params,
			value: [
				AsnWebListenInterfaceStatusList.getASN1Schema({ name: "interfaceList" })
			]
		});
	}

	public interfaceList!: AsnWebListenInterfaceStatusList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnWebIceServerList extends Array<AsnWebIceServer> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnWebIceServer.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnWebGetIceServersResult {
	public constructor(that: AsnWebGetIceServersResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebGetIceServersResult {
		return new AsnWebGetIceServersResult({
			iceServers: new AsnWebIceServerList(),
			stValidTo: new Date(),
			iTTL: 0
		});
	}

	public static type = "AsnWebGetIceServersResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebGetIceServersResult",
			...params,
			value: [
				AsnWebIceServerList.getASN1Schema({ name: "iceServers" }),
				new asn1ts.Real({ name: "stValidTo" }),
				new asn1ts.Integer({ name: "iTTL" })
			]
		});
	}

	/** the list of servers */
	public iceServers!: AsnWebIceServerList;
	/** valid until */
	public stValidTo!: Date;
	/** TTL in Seconds */
	public iTTL!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUCSCapabilitiesChangedArgument {
	public constructor(that?: AsnUCSCapabilitiesChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUCSCapabilitiesChangedArgument {
		return new AsnUCSCapabilitiesChangedArgument();
	}

	public static type = "AsnUCSCapabilitiesChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUCSCapabilitiesChangedArgument",
			...params,
			value: [
				AsnWebServiceCapabilitiesList.getASN1Schema({ name: "capabilities", optional: true })
			]
		});
	}

	public capabilities?: AsnWebServiceCapabilitiesList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnExternalServiceProperty {
	public constructor(that: AsnExternalServiceProperty) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnExternalServiceProperty {
		return new AsnExternalServiceProperty({
			u8sName: "",
			u8sType: ""
		});
	}

	public static type = "AsnExternalServiceProperty";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnExternalServiceProperty",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sName" }),
				new asn1ts.Utf8String({ name: "u8sType" }),
				AsnExternalServicePropertyList.getASN1Schema({ name: "listExternalServiceProperty", idBlock: { optionalID: 0 } })
			]
		});
	}

	/** External service property name */
	public u8sName!: string;
	/** External service property type */
	public u8sType!: string;
	public listExternalServiceProperty?: AsnExternalServicePropertyList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnExternalServicePropertyList extends Array<AsnExternalServiceProperty> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnExternalServiceProperty.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRegisterExternalServiceArgument {
	public constructor(that: AsnRegisterExternalServiceArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRegisterExternalServiceArgument {
		return new AsnRegisterExternalServiceArgument({
			iVersion: 0,
			u8sServiceType: "",
			u8sServiceId: "",
			u8sServiceUrl: "",
			u8sToken: ""
		});
	}

	public static type = "AsnRegisterExternalServiceArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRegisterExternalServiceArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iVersion" }),
				new asn1ts.Utf8String({ name: "u8sServiceType" }),
				new asn1ts.Utf8String({ name: "u8sServiceId" }),
				new asn1ts.Utf8String({ name: "u8sServiceUrl" }),
				new asn1ts.Utf8String({ name: "u8sToken" }),
				AsnExternalServicePropertyList.getASN1Schema({ name: "listExternalServiceProperty", idBlock: { optionalID: 0 } })
			]
		});
	}

	/** Version of the protocol */
	public iVersion!: number;
	/** Service Type name e.g. FAQ, RANKING */
	public u8sServiceType!: string;
	/** UUID generated by the Service */
	public u8sServiceId!: string;
	/** Service URL Path */
	public u8sServiceUrl!: string;
	/** Service Token */
	public u8sToken!: string;
	public listExternalServiceProperty?: AsnExternalServicePropertyList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnNegotiateInterfaceVersionArgument {
	public constructor(that: AsnNegotiateInterfaceVersionArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnNegotiateInterfaceVersionArgument {
		return new AsnNegotiateInterfaceVersionArgument({
			u8sMinInterfaceVer: "",
			u8sMaxInterfaceVer: ""
		});
	}

	public static type = "AsnNegotiateInterfaceVersionArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNegotiateInterfaceVersionArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sMinInterfaceVer" }),
				new asn1ts.Utf8String({ name: "u8sMaxInterfaceVer" })
			]
		});
	}

	/** Requested minimum interface version, usually this should be a &lt;major&gt;.0.0 */
	public u8sMinInterfaceVer!: string;
	/** Requested maximum interface version, usually this will be the interface version the client has been built with */
	public u8sMaxInterfaceVer!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnNegotiateInterfaceVersionResult {
	public constructor(that: AsnNegotiateInterfaceVersionResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnNegotiateInterfaceVersionResult {
		return new AsnNegotiateInterfaceVersionResult({
			bSuccess: false,
			u8sServerInterfaceVer: ""
		});
	}

	public static type = "AsnNegotiateInterfaceVersionResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNegotiateInterfaceVersionResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" }),
				new asn1ts.Utf8String({ name: "u8sServerInterfaceVer" })
			]
		});
	}

	/** true if the requested version is considered compatible, false otherwise */
	public bSuccess!: boolean;
	/** The interface version the server has been built with */
	public u8sServerInterfaceVer!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @deprecated 09.06.2023 The custom error object is now replaced with an AsnRequestError to ease generalized error handling.
 * @private
 */
export class AsnCreateUserSessionError {
	public constructor(that: AsnCreateUserSessionError) {
		TSDeprecatedCallback.deprecatedObject(1686268800, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnCreateUserSessionError {
		return new AsnCreateUserSessionError({
			iErrorDetail: 0,
			u8sErrorString: "",
			negotiateerror: ENetUC_Common.AsnRequestError["initEmpty"].call(0)
		});
	}

	public static type = "AsnCreateUserSessionError";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCreateUserSessionError",
			...params,
			value: [
				new asn1ts.Integer({ name: "iErrorDetail" }),
				new asn1ts.Utf8String({ name: "u8sErrorString" }),
				ENetUC_Common.AsnRequestError.getASN1Schema({ name: "negotiateerror" })
			]
		});
	}

	public iErrorDetail!: number;
	public u8sErrorString!: string;
	public negotiateerror!: ENetUC_Common.AsnRequestError;
}

// [PrintTSTypeDefCode]
// [PrintTSChoiceDefCode]
export class AsnRegisterServerExResultChoice {
	public constructor(that?: AsnRegisterServerExResultChoice) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnRegisterServerExResultChoice {
		return new AsnRegisterServerExResultChoice();
	}

	public static type = "AsnRegisterServerExResultChoice";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Choice {
		return new asn1ts.Choice({
			name: "AsnRegisterServerExResultChoice",
			...params,
			value: [
				AsnRegisterServerResult.getASN1Schema({ name: "result", idBlock: { optionalID: 0 } }),
				AsnRegisterServerRedirectResult.getASN1Schema({ name: "redirect", idBlock: { optionalID: 1 } })
			]
		});
	}

	public result?: AsnRegisterServerResult;
	public redirect?: AsnRegisterServerRedirectResult;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnWebGetIceServersExArgument {
	public constructor(that?: AsnWebGetIceServersExArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnWebGetIceServersExArgument {
		return new AsnWebGetIceServersExArgument();
	}

	public static type = "AsnWebGetIceServersExArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnWebGetIceServersExArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "sTurnProviderConfig", idBlock: { optionalID: 0 } })
			]
		});
	}

	/** json config for Turn Provider */
	public sTurnProviderConfig?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnTokenVerifyExArgument {
	public constructor(that: AsnTokenVerifyExArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnTokenVerifyExArgument {
		return new AsnTokenVerifyExArgument({
			u8sToken: ""
		});
	}

	public static type = "AsnTokenVerifyExArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnTokenVerifyExArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sToken" })
			]
		});
	}

	/** JWT Token */
	public u8sToken!: string;
}
