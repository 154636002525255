import { useCallback, useEffect, useState } from "react";

export const useRightPanel = () => {
	const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
	const [selectedJournalEntryId, setSelectedJournalEntryId] = useState<string | null>(null);
	const [visible, setVisible] = useState(false);

	const onCancel = useCallback(() => {
		setVisible(false);
	}, []);

	useEffect(() => {
		if (selectedContactId || selectedJournalEntryId) {
			setVisible(true);
		}
	}, [selectedContactId, selectedJournalEntryId, setVisible]);

	return {
		visible,
		onCancel,
		selectedJournalEntryId,
		selectedContactId,
		setSelectedJournalEntryId,
		setSelectedContactId
	};
};
