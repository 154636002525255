/*
* If ever a new detail is added, this is the list that makes it possible.
*
* The new detail needs a name, a localisation variable, vis = true and
* the indication in which block in the initial data it should be placed (localization name).
* A new block for sorting in is also possible, just add some new initialBlockLocal.
* This is necessary so that the customer can see the detail - provided it has been filled with content -
* and can edit it in its configuration via the editor if necessary.
*
* In addition, the detail should be included in the initial data:
* - ConfigCDIntitalData.ts
* - ConfigCWIntitalData.ts
*/

export interface IPermittedDetail {
	name: string;
	local: string; // variable name for localisation
	type?: string; // possible types are: addr, app, block, city, cfblk, cfdtl, cublk, cudtl, ctry, mail, fone, pict, plz, sip, stt, str, text, url
	subtype?: string; // possible subtypes are: app, link, mail, fone, text, url (=> only necessary for type "cudtl" and "cfdtl)
	initialBlockLocal?: string | null; // initial block variable name for localisation
	vis: boolean; // visible or not
}

export const U8sAllPermittedDetails: IPermittedDetail[] = [
	{
		name: "iCtiServerUser",
		local: "",
		vis: false
	},
	{
		name: "iPrivateContact",
		local: "",
		vis: false
	},
	{
		name: "u8sAssistantTelephoneNumber",
		local: "IDS_CD_PHONE_ASSISTANT",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sBody",
		local: "IDS_CD_BODY",
		initialBlockLocal: "IDS_CD_OTHER",
		vis: true
	},
	{
		name: "u8sBusinessAddressCity",
		local: "IDS_CD_CITY",
		type: "city",
		initialBlockLocal: "IDS_CD_ADDRESS_BUSINESS",
		vis: true
	},
	{
		name: "u8sBusinessAddressCountry",
		local: "IDS_CD_COUNTRY",
		type: "ctry",
		initialBlockLocal: "IDS_CD_ADDRESS_BUSINESS",
		vis: true
	},
	{
		name: "u8sBusinessAddressPostalCode",
		local: "IDS_CD_POSTALCODE",
		type: "plz",
		initialBlockLocal: "IDS_CD_ADDRESS_BUSINESS",
		vis: true
	},
	{
		name: "u8sBusinessAddressState",
		local: "IDS_CD_STATE",
		type: "stt",
		initialBlockLocal: "IDS_CD_ADDRESS_BUSINESS",
		vis: true
	},
	{
		name: "u8sBusinessAddressStreet",
		local: "IDS_CD_STREET",
		type: "str",
		initialBlockLocal: "IDS_CD_ADDRESS_BUSINESS",
		vis: true
	},
	{
		name: "u8sCallbackTelephoneNumber",
		local: "IDS_CD_PHONE_CALLBACK",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sCarTelephoneNumber",
		local: "IDS_CD_PHONE_CAR",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sCompany",
		local: "IDS_CD_COMPANY",
		initialBlockLocal: "IDS_CD_PERSON",
		vis: true
	},
	{
		name: "u8sCompanyMainTelephoneNumber",
		local: "IDS_CD_PHONE_COMPANYMAIN",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sCtiServerUserName",
		local: "",
		vis: false
	},
	{
		name: "u8sCustomerID",
		local: "IDS_CD_CUSTOMERID",
		initialBlockLocal: "IDS_CD_PERSON",
		vis: true
	},
	{
		name: "u8sDatabaseName",
		local: "IDS_CD_DATABASENAME",
		initialBlockLocal: "IDS_CD_TECHNICALDETAILS",
		vis: true
	},
	{
		name: "u8sDepartment",
		local: "IDS_CD_DEPARTMENT",
		initialBlockLocal: "IDS_CD_PERSON",
		vis: true
	},
	{
		name: "u8sDirectWebLink",
		local: "",
		vis: false
	},
	{
		name: "u8sDisplayName",
		local: "IDS_CD_DISPLAYNAME",
		initialBlockLocal: null,
		vis: true
	},
	{
		name: "u8sEMail",
		local: "IDS_CD_EMAIL",
		type: "mail",
		initialBlockLocal: "IDS_CD_EMAILS",
		vis: true
	},
	{
		name: "u8sEMail2",
		local: "IDS_CD_EMAIL",
		type: "mail",
		initialBlockLocal: "IDS_CD_EMAILS",
		vis: true
	},
	{
		name: "u8sEMail3",
		local: "IDS_CD_EMAIL",
		type: "mail",
		initialBlockLocal: "IDS_CD_EMAILS",
		vis: true
	},
	{
		name: "u8sEntryID",
		local: "",
		vis: false
	},
	{
		name: "u8sEntryIDDB",
		local: "",
		vis: false
	},
	{
		name: "u8sEntryIDStore",
		local: "",
		vis: false
	},
	{
		name: "u8sFaxBusiness",
		local: "IDS_CD_FAX_BUSINESS",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sFaxHome",
		local: "IDS_CD_FAX_HOME",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sFirstName",
		local: "IDS_CD_FIRSTNAME",
		initialBlockLocal: null,
		vis: true
	},
	{
		name: "u8sFound",
		local: "",
		vis: false
	},
	{
		name: "u8sISDNTelephoneNumber",
		local: "IDS_CD_PHONE_ISDN",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sJobTitle",
		local: "IDS_CD_JOBTITLE",
		initialBlockLocal: "IDS_CD_PERSON",
		vis: true
	},
	{
		name: "u8sLastName",
		local: "IDS_CD_LASTNAME",
		initialBlockLocal: null,
		vis: true
	},
	{
		name: "u8sOfficeRoomNumber",
		local: "IDS_CD_OFFICEROOMNUMBER",
		initialBlockLocal: "IDS_CD_PERSON",
		vis: true
	},
	{
		name: "u8sOtherAddressCity",
		local: "IDS_CD_CITY",
		type: "city",
		initialBlockLocal: "IDS_CD_ADDRESS_OTHER",
		vis: true
	},
	{
		name: "u8sOtherAddressCountry",
		local: "IDS_CD_COUNTRY",
		type: "ctry",
		initialBlockLocal: "IDS_CD_ADDRESS_OTHER",
		vis: true
	},
	{
		name: "u8sOtherAddressPostalCode",
		local: "IDS_CD_POSTALCODE",
		type: "plz",
		initialBlockLocal: "IDS_CD_ADDRESS_OTHER",
		vis: true
	},
	{
		name: "u8sOtherAddressState",
		local: "IDS_CD_STATE",
		type: "stt",
		initialBlockLocal: "IDS_CD_ADDRESS_OTHER",
		vis: true
	},
	{
		name: "u8sOtherAddressStreet",
		local: "IDS_CD_STREET",
		type: "str",
		initialBlockLocal: "IDS_CD_ADDRESS_OTHER",
		vis: true
	},
	{
		name: "u8sOtherTelephoneNumber",
		local: "IDS_CD_PHONE_OTHER",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sPagerTelephoneNumber",
		local: "IDS_CD_PHONE_PAGER",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sPhoneBusiness",
		local: "IDS_CD_PHONE_BUSINESS",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sPhoneBusiness2",
		local: "IDS_CD_PHONE_BUSINESS2",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sPhoneHome",
		local: "IDS_CD_PHONE_HOME",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sPhoneHome2",
		local: "IDS_CD_PHONE_HOME2",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sPhoneMobile",
		local: "IDS_CD_PHONE_MOBILE",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sPrimaryTelephoneNumber",
		local: "IDS_CD_PHONE_PRIMARY",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sPrivateAddressCity",
		local: "IDS_CD_CITY",
		type: "city",
		initialBlockLocal: "IDS_CD_ADDRESS_PRIVATE",
		vis: true
	},
	{
		name: "u8sPrivateAddressCountry",
		local: "IDS_CD_COUNTRY",
		type: "ctry",
		initialBlockLocal: "IDS_CD_ADDRESS_PRIVATE",
		vis: true
	},
	{
		name: "u8sPrivateAddressPostalCode",
		local: "IDS_CD_POSTALCODE",
		type: "plz",
		initialBlockLocal: "IDS_CD_ADDRESS_PRIVATE",
		vis: true
	},
	{
		name: "u8sPrivateAddressState",
		local: "IDS_CD_STATE",
		type: "stt",
		initialBlockLocal: "IDS_CD_ADDRESS_PRIVATE",
		vis: true
	},
	{
		name: "u8sPrivateAddressStreet",
		local: "IDS_CD_STREET",
		type: "str",
		initialBlockLocal: "IDS_CD_ADDRESS_PRIVATE",
		vis: true
	},
	{
		name: "u8sRadioTelephoneNumber",
		local: "IDS_CD_PHONE_RADIO",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sSIPAddress",
		local: "IDS_CD_SIP",
		type: "mail",
		initialBlockLocal: "IDS_CD_EMAILS",
		vis: true
	},
	{
		name: "u8sTTYTTDTelephoneNumber",
		local: "IDS_CD_PHONE_TTYTTD",
		type: "fone",
		initialBlockLocal: "IDS_CD_PHONE_NUMBERS",
		vis: true
	},
	{
		name: "u8sWebPageURL",
		local: "IDS_CD_WEBPAGEURL",
		type: "mail",
		initialBlockLocal: "IDS_CD_EMAILS",
		vis: true
	}
];

export const PermittedDetailsMap = (): Map<string, IPermittedDetail> => {
	const allTrue: IPermittedDetail[] = U8sAllPermittedDetails.filter(detail => detail.vis === true);
	const resultMap: Map<string, IPermittedDetail> = new Map<string, IPermittedDetail>();

	allTrue.map(elem => {
		resultMap.set(elem.name, elem);
		return true;
	});
	return resultMap;
};

export const PermittedDetailNamesMap = (): Map<string, boolean> => {
	const allTrue: IPermittedDetail[] = U8sAllPermittedDetails.filter(detail => detail.vis === true);
	const resultMap: Map<string, boolean> = new Map<string, boolean>();

	allTrue.map(elem => {
		resultMap.set(elem.name, elem.vis);
		return true;
	});

	for (let i = 0; i < 20; i++)
		resultMap.set("Custom" + i, true);

	for (let i = 0; i < 3; i++)
		resultMap.set("ContactURL" + i, true);

	resultMap.set("Appointment", true);
	resultMap.set("addr_business", true);
	resultMap.set("addr_privat", true);
	resultMap.set("addr_other", true);

	return resultMap;
};

export const translationVariables: string[] = [
	"IDS_CALL_CONNECTED",
	"IDS_CALL_RINGING",
	"IDS_CD_ADDRESS_BUSINESS",
	"IDS_CD_ADDRESS_OTHER",
	"IDS_CD_ADDRESS_PRIVATE",
	"IDS_CD_ADDRESSES",
	"IDS_CD_APPOINTMENT_NEXT",
	"IDS_CD_APPOINTMENT",
	"IDS_CD_BODY",
	"IDS_CD_CITY",
	"IDS_CD_COMPANY",
	"IDS_CD_COUNTRY",
	"IDS_CD_CUSTOMERID",
	"IDS_CD_DATABASENAME",
	"IDS_CD_DEPARTMENT",
	"IDS_CD_DISPLAYNAME",
	"IDS_CD_EMAIL",
	"IDS_CD_EMAILS",
	"IDS_CD_FAX_BUSINESS",
	"IDS_CD_FAX_HOME",
	"IDS_CD_FIRSTNAME",
	"IDS_CD_JOBTITLE",
	"IDS_CD_LASTNAME",
	"IDS_CD_OFFICEROOMNUMBER",
	"IDS_CD_OTHER",
	"IDS_CD_PERSON",
	"IDS_CD_PHONE_ASSISTANT",
	"IDS_CD_PHONE_BUSINESS",
	"IDS_CD_PHONE_BUSINESS2",
	"IDS_CD_PHONE_CALLBACK",
	"IDS_CD_PHONE_CAR",
	"IDS_CD_PHONE_COMPANYMAIN",
	"IDS_CD_PHONE_HOME",
	"IDS_CD_PHONE_HOME2",
	"IDS_CD_PHONE_ISDN",
	"IDS_CD_PHONE_MOBILE",
	"IDS_CD_PHONE_NUMBERS",
	"IDS_CD_PHONE_OTHER",
	"IDS_CD_PHONE_PAGER",
	"IDS_CD_PHONE_PRIMARY",
	"IDS_CD_PHONE_RADIO",
	"IDS_CD_PHONE_TTYTTD",
	"IDS_CD_POSTALCODE",
	"IDS_CD_SIP",
	"IDS_CD_STATE",
	"IDS_CD_STREET",
	"IDS_CD_TECHNICALDETAILS",
	"IDS_CD_WEBPAGEURL",
	"IDS_HAS_MEETING"
];
