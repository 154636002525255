import { StateCreator } from "zustand";

import { Eav_CallType, ECallState } from "../interfaces/IAVCall";
import { StoreT } from "./store";

export type AvCallSliceT = IState & IActions;

export interface IAvCall {
	// AVCall id
	id: string;
	// type of call (audio / video)
	callType: Eav_CallType;
	// state of the call
	callState: ECallState;
	// generator of the call
	from: string;
	// destination of the call
	to: string;
	// conversation id
	conversationID: string;
	// the layout is minimized
	isMinimized?: true;
	// the video is in fullscreen
	isFullscreen?: true;
	// show the bottombar in fullscreen AV Call
	showBottomBarInFullscreen?: true;
	// is the initiator of the call
	isInitiator: boolean;
	// the remote video track id
	remoteVideoTrackID?: string;
	// the remote audio track id
	remoteAudioTrackID?: string;
	// the local video track id
	localVideoTrackID?: string;
	// the local audio track id
	localAudioTrackID?: string;
	// remote audio is muted
	isRemoteAudioMuted: boolean;
	// remote video is muted
	isRemoteVideoMuted: boolean;
}

interface IState {
	avCalls: IAvCall[];
	// the current selected cam
	selectedCam?: string;
	// the current selected mic
	selectedMic?: string;
	// the current selected speaker
	selectedSpeaker?: string;
}

interface IActions {
	avCallAdd: (avCall: IAvCall) => void;
	avCallRemove: (callId: string) => void;
	avCallSetCallState: ({ id, callState }: { id: string; callState: ECallState }) => void;
	avCallSetLocalAudioTrack: ({ id, trackID }: { id: string; trackID: string | undefined }) => void;
	avCallSetLocalVideoTrack: ({ id, trackID }: { id: string; trackID: string | undefined }) => void;
	avCallSetRemoteAudioTrack: ({ id, trackID }: { id: string; trackID: string | undefined }) => void;
	avCallSetRemoteVideoTrack: ({ id, trackID }: { id: string; trackID: string | undefined }) => void;
	avCallSetSelectedCam: (camId: string | undefined) => void;
	avCallSetSelectedMic: (micId: string | undefined) => void;
	avCallSetSelectedSpeaker: (selectedSpeaker: string | undefined) => void;
	avCallSetIsMinimized: ({ id, isMinimized }: { id: string; isMinimized: true | undefined }) => void;
	avCallSetIsFullscreen: ({ id, isFullscreen }: { id: string; isFullscreen: true | undefined }) => void;
	avCallToggleBottomBar: ({
		id,
		showBottomBarInFullscreen
	}: {
		id: string;
		showBottomBarInFullscreen: true | undefined;
	}) => void;
	avCallSetRemoteAudioMuted: ({ id, isRemoteAudioMuted }: { id: string; isRemoteAudioMuted: boolean }) => void;
	avCallSetRemoteVideoMuted: ({ id, isRemoteVideoMuted }: { id: string; isRemoteVideoMuted: boolean }) => void;
}

export const initialState: IState = {
	avCalls: []
};

export const createAvCallSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	AvCallSliceT
> = (set) => ({
	...initialState,
	avCallAdd: (avCall: IAvCall) => {
		set(
			(prev) => {
				const existing = prev.avCalls.find((item) => item.id === avCall.id);
				if (!existing) {
					return {
						...prev,
						avCalls: [...prev.avCalls, avCall]
					};
				}
				return prev;
			},
			false,
			"avCallAdd"
		);
	},
	avCallRemove: (callId: string) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.filter((item) => item.id !== callId)
				};
			},
			false,
			"avCallRemove"
		);
	},
	avCallSetCallState: ({ id, callState }: { id: string; callState: ECallState }) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.map((item) => {
						if (item.id === id) {
							return {
								...item,
								callState
							};
						}
						return item;
					})
				};
			},
			false,
			"avCallSetCallState"
		);
	},
	avCallSetLocalAudioTrack: ({ id, trackID }: { id: string; trackID: string | undefined }) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.map((item) => {
						if (item.id === id) {
							return {
								...item,
								trackID
							};
						}
						return item;
					})
				};
			},
			false,
			"avCallSetLocalAudioTrack"
		);
	},
	avCallSetLocalVideoTrack: ({ id, trackID }: { id: string; trackID: string | undefined }) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.map((item) => {
						if (item.id === id) {
							return {
								...item,
								localVideoTrackID: trackID
							};
						}
						return item;
					})
				};
			},
			false,
			"avCallSetLocalVideoTrack"
		);
	},
	avCallSetRemoteAudioTrack: ({ id, trackID }: { id: string; trackID: string | undefined }) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.map((item) => {
						if (item.id === id) {
							return {
								...item,
								remoteAudioTrackID: trackID
							};
						}
						return item;
					})
				};
			},
			false,
			"avCallSetRemoteAudioTrack"
		);
	},
	avCallSetRemoteVideoTrack: ({ id, trackID }: { id: string; trackID: string | undefined }) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.map((item) => {
						if (item.id === id) {
							return {
								...item,
								remoteVideoTrackID: trackID
							};
						}
						return item;
					})
				};
			},
			false,
			"avCallSetRemoteVideoTrack"
		);
	},
	avCallSetSelectedCam: (selectedCam: string | undefined) => {
		set(
			(prev) => {
				return {
					...prev,
					selectedCam
				};
			},
			false,
			"avCallSetSelectedCam"
		);
	},
	avCallSetSelectedMic: (selectedMic: string | undefined) => {
		set(
			(prev) => {
				return {
					...prev,
					selectedMic
				};
			},
			false,
			"avCallSetSelectedMic"
		);
	},
	avCallSetSelectedSpeaker: (selectedSpeaker: string | undefined) => {
		set(
			(prev) => {
				return {
					...prev,
					selectedSpeaker
				};
			},
			false,
			"avCallSetSelectedSpeaker"
		);
	},
	avCallSetIsMinimized: ({ id, isMinimized }: { id: string; isMinimized: true | undefined }) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.map((item) => {
						if (item.id === id) {
							return {
								...item,
								isMinimized
							};
						}
						return item;
					})
				};
			},
			false,
			"avCallSetIsMinimized"
		);
	},
	avCallSetIsFullscreen: ({ id, isFullscreen }: { id: string; isFullscreen: true | undefined }) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.map((item) => {
						if (item.id === id) {
							return {
								...item,
								isFullscreen
							};
						}
						return item;
					})
				};
			},
			false,
			"avCallSetIsFullscreen"
		);
	},
	avCallToggleBottomBar: ({
		id,
		showBottomBarInFullscreen
	}: {
		id: string;
		showBottomBarInFullscreen: true | undefined;
	}) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.map((item) => {
						if (item.id === id) {
							return {
								...item,
								showBottomBarInFullscreen
							};
						}
						return item;
					})
				};
			},
			false,
			"avCallToggleBottomBar"
		);
	},
	avCallSetRemoteAudioMuted: ({ id, isRemoteAudioMuted }: { id: string; isRemoteAudioMuted: boolean }) => {
		set(
			(prev) => {
				return {
					...prev,
					isRemoteAudioMuted
				};
			},
			false,
			"avCallSetRemoteAudioMuted"
		);
	},
	avCallSetRemoteVideoMuted: ({ id, isRemoteVideoMuted }: { id: string; isRemoteVideoMuted: boolean }) => {
		set(
			(prev) => {
				return {
					...prev,
					avCalls: prev.avCalls.map((item) => {
						if (item.id === id) {
							return {
								...item,
								isRemoteVideoMuted
							};
						}
						return item;
					})
				};
			},
			false,
			"avCallSetRemoteVideoMuted"
		);
	}
});
