// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_Common_Converter.ts
 * "UC-Server-Access-Protocol-Common" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.23, 20.10.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_Common from "./ENetUC_Common";
import { EAsnOptionalParametersConverter } from "./TSOptionalParamConverter";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
// [PrintTSRootTypes]
export const moduleName = "ENetUC_Common_Converter";

// [PrintTSEncoderDecoderCode]
export class AsnOptionalParamChoice_Converter {
	public static toJSON(s: ENetUC_Common.AsnOptionalParamChoice, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnOptionalParamChoice & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOptionalParamChoice");

		const t = {} as ENetUC_Common.AsnOptionalParamChoice & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnOptionalParamChoice";
		if (s.stringdata != null)
			TSConverter.fillJSONParam(s, t, "stringdata", "string", errors, newContext);
		else if (s.binarydata != null)
			TSConverter.fillJSONParam(s, t, "binarydata", "Uint8Array", errors, newContext);
		else if (s.integerdata != null)
			TSConverter.fillJSONParam(s, t, "integerdata", "number", errors, newContext);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnOptionalParamChoice"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnOptionalParamChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOptionalParamChoice");

		let t: ENetUC_Common.AsnOptionalParamChoice | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnOptionalParamChoice>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnOptionalParamChoice["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.stringdata !== undefined) {
				if (TSConverter.validateParam(s, "stringdata", "string", errors, newContext))
					t.stringdata = s.stringdata;
			} else if (s.binarydata !== undefined) {
				if (TSConverter.validateParam(s, "binarydata", "string", errors, newContext))
					t.binarydata = TSConverter.decode64(s.binarydata as unknown as string);
			} else if (s.integerdata !== undefined) {
				if (TSConverter.validateParam(s, "integerdata", "number", errors, newContext))
					t.integerdata = s.integerdata;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}
		if (errors.validateResult(newContext, "AsnOptionalParamChoice"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnOptionalParamChoice | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "AsnOptionalParamChoice";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOptionalParamChoice");

		// [Print_BER_EncoderChoiceDefCode]
		if (TSConverter.validateParam(s, "stringdata", "string", errors, newContext, true))
			t = new asn1ts.Utf8String({ value: s.stringdata, name: "stringdata" });
		else if (TSConverter.validateParam(s, "binarydata", "Uint8Array", errors, newContext, true))
			t = new asn1ts.OctetString({ valueHex: s.binarydata, name: "binarydata" });
		else if (TSConverter.validateParam(s, "integerdata", "number", errors, newContext, true))
			t = new asn1ts.Integer({ value: s.integerdata, name: "integerdata" });
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
		if (errors.validateResult(newContext, "AsnOptionalParamChoice"))
			return t;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnOptionalParamChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOptionalParamChoice");

		let t: ENetUC_Common.AsnOptionalParamChoice | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnOptionalParamChoice.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnOptionalParamChoice["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "stringdata" && asn1ts.Utf8String.typeGuard(s)) {
				const _stringdata = s.getValue();
				if (TSConverter.validateParamType(_stringdata, "stringdata", "string", errors, newContext, false))
					t.stringdata = _stringdata;
			} else if (s.choiceName === "binarydata" && asn1ts.OctetString.typeGuard(s)) {
				const _binarydata = s.getValue();
				if (TSConverter.validateParamType(_binarydata, "binarydata", "Uint8Array", errors, newContext, false))
					t.binarydata = new Uint8Array(_binarydata);
			} else if (s.choiceName === "integerdata" && asn1ts.Integer.typeGuard(s)) {
				const _integerdata = s.getValue();
				if (TSConverter.validateParamType(_integerdata, "integerdata", "number", errors, newContext, false))
					t.integerdata = _integerdata;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnOptionalParamChoice"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnStringPair_Converter {
	public static toJSON(s: ENetUC_Common.AsnStringPair, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnStringPair & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStringPair");

		const t = {} as ENetUC_Common.AsnStringPair & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnStringPair";
		TSConverter.fillJSONParam(s, t, "key", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "value", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnStringPair"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnStringPair | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStringPair");

		let t: ENetUC_Common.AsnStringPair | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnStringPair>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnStringPair["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "key", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "value", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnStringPair"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnStringPair | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnStringPair";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStringPair");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "key", "string", errors, newContext);
		TSConverter.validateParam(s, "value", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnStringPair")) {
			t.push(new asn1ts.Utf8String({ value: s.key, name: "key" }));
			t.push(new asn1ts.Utf8String({ value: s.value, name: "value" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnStringPair | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStringPair");

		let t: ENetUC_Common.AsnStringPair | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnStringPair.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnStringPair["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "key", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "value", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnStringPair"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnIntegerPair_Converter {
	public static toJSON(s: ENetUC_Common.AsnIntegerPair, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnIntegerPair & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnIntegerPair");

		const t = {} as ENetUC_Common.AsnIntegerPair & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnIntegerPair";
		TSConverter.fillJSONParam(s, t, "key", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "value", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnIntegerPair"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnIntegerPair | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnIntegerPair");

		let t: ENetUC_Common.AsnIntegerPair | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnIntegerPair>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnIntegerPair["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "key", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "value", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnIntegerPair"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnIntegerPair | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnIntegerPair";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnIntegerPair");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "key", "number", errors, newContext);
		TSConverter.validateParam(s, "value", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnIntegerPair")) {
			t.push(new asn1ts.Integer({ value: s.key, name: "key" }));
			t.push(new asn1ts.Integer({ value: s.value, name: "value" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnIntegerPair | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnIntegerPair");

		let t: ENetUC_Common.AsnIntegerPair | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnIntegerPair.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnIntegerPair["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "key", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "value", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnIntegerPair"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnStringIntegerPair_Converter {
	public static toJSON(s: ENetUC_Common.AsnStringIntegerPair, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnStringIntegerPair & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStringIntegerPair");

		const t = {} as ENetUC_Common.AsnStringIntegerPair & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnStringIntegerPair";
		TSConverter.fillJSONParam(s, t, "u8sStr", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iInt", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnStringIntegerPair"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnStringIntegerPair | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStringIntegerPair");

		let t: ENetUC_Common.AsnStringIntegerPair | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnStringIntegerPair>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnStringIntegerPair["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sStr", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iInt", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnStringIntegerPair"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnStringIntegerPair | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnStringIntegerPair";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStringIntegerPair");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sStr", "string", errors, newContext);
		TSConverter.validateParam(s, "iInt", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnStringIntegerPair")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sStr, name: "u8sStr" }));
			t.push(new asn1ts.Integer({ value: s.iInt, name: "iInt" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnStringIntegerPair | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStringIntegerPair");

		let t: ENetUC_Common.AsnStringIntegerPair | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnStringIntegerPair.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnStringIntegerPair["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sStr", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iInt", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnStringIntegerPair"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestError_Converter {
	public static toJSON(s: ENetUC_Common.AsnRequestError, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnRequestError & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestError");

		const t = {} as ENetUC_Common.AsnRequestError & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestError";
		TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnRequestError"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnRequestError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestError");

		let t: ENetUC_Common.AsnRequestError | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnRequestError>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnRequestError["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnRequestError"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnRequestError | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestError";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestError");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iErrorDetail", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sErrorString", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnRequestError")) {
			t.push(new asn1ts.Integer({ value: s.iErrorDetail, name: "iErrorDetail" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sErrorString, name: "u8sErrorString" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnRequestError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestError");

		let t: ENetUC_Common.AsnRequestError | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnRequestError.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnRequestError["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iErrorDetail", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sErrorString", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRequestError"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNetDatabaseContactID_Converter {
	public static toJSON(s: ENetUC_Common.AsnNetDatabaseContactID, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnNetDatabaseContactID & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseContactID");

		const t = {} as ENetUC_Common.AsnNetDatabaseContactID & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNetDatabaseContactID";
		TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEntryIDDB", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEntryIDStore", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnNetDatabaseContactID"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnNetDatabaseContactID | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseContactID");

		let t: ENetUC_Common.AsnNetDatabaseContactID | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnNetDatabaseContactID>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnNetDatabaseContactID["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEntryIDDB", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEntryIDStore", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnNetDatabaseContactID"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnNetDatabaseContactID | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNetDatabaseContactID";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseContactID");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sEntryID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEntryIDDB", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEntryIDStore", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnNetDatabaseContactID")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryID, name: "u8sEntryID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryIDDB, name: "u8sEntryIDDB" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryIDStore, name: "u8sEntryIDStore" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnNetDatabaseContactID | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseContactID");

		let t: ENetUC_Common.AsnNetDatabaseContactID | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnNetDatabaseContactID.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnNetDatabaseContactID["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sEntryID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEntryIDDB", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEntryIDStore", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseContactID"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnOptionalParam_Converter {
	public static toJSON(s: ENetUC_Common.AsnOptionalParam, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnOptionalParam & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOptionalParam");

		const t = {} as ENetUC_Common.AsnOptionalParam & INamedType;

		// It is not possible to encode a single AsnOptionalParam into the ucserver notation. Needs the AsnOptionalParameters envelop!
		if (newContext?.bUCServerOptionalParams)
			debugger;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnOptionalParam";
		TSConverter.fillJSONParam(s, t, "key", "string", errors, newContext);
		const _value = AsnOptionalParamChoice_Converter.toJSON(s.value, errors, newContext, "value");
		if (_value)
			t.value = _value;

		if (errors.validateResult(newContext, "AsnOptionalParam"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnOptionalParam | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOptionalParam");

		let t: ENetUC_Common.AsnOptionalParam | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnOptionalParam>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnOptionalParam["initEmpty"].call(0);
			// It is not possible to decode a single AsnOptionalParam from the ucserver notation. Needs the AsnOptionalParameters envelop!
			if (s.key === undefined)
				debugger;
			else {
				// [Print_JSON_DecoderSeqDefCode]
				TSConverter.fillJSONParam(s, t, "key", "string", errors, context, false);
				const _value = AsnOptionalParamChoice_Converter.fromJSON(s.value, errors, newContext, "value", false);
				if (_value)
					t.value = _value;
			}
		}
		if (errors.validateResult(newContext, "AsnOptionalParam"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnOptionalParam | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnOptionalParam";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOptionalParam");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "key", "string", errors, newContext);
		const _value = AsnOptionalParamChoice_Converter.toBER(s.value, errors, newContext, "value");
		if (errors.validateResult(newContext, "AsnOptionalParam")) {
			t.push(new asn1ts.Utf8String({ value: s.key, name: "key" }));
			if (_value)
				t.push(_value);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnOptionalParam | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOptionalParam");

		let t: ENetUC_Common.AsnOptionalParam | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnOptionalParam.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnOptionalParam["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "key", "Utf8String", errors, newContext);
			const _value = AsnOptionalParamChoice_Converter.fromBER(s.getValueByName("value"), errors, newContext, "value");
			if (_value)
				t.value = _value;
		}

		if (errors.validateResult(newContext, "AsnOptionalParam"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnStringPairList_Converter {
	public static toJSON(s: ENetUC_Common.AsnStringPairList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnStringPairList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStringPairList");

		const t = [] as ENetUC_Common.AsnStringPairList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnStringPair_Converter.toJSON(se, errors, newContext, "AsnStringPair");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnStringPairList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnStringPairList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStringPairList");

		let t: ENetUC_Common.AsnStringPairList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnStringPairList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Common.AsnStringPairList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnStringPair_Converter.fromJSON(se, errors, newContext, "AsnStringPair", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnStringPairList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnStringPairList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnStringPairList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStringPairList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnStringPair_Converter.toBER(s[id], errors, newContext, "AsnStringPair");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnStringPair"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnStringPairList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStringPairList");

		let t: ENetUC_Common.AsnStringPairList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnStringPairList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Common.AsnStringPairList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnStringPair_Converter.fromBER(se, errors, newContext, "AsnStringPair", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnStringPairList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJSONWebTokenHeader_Converter {
	public static toJSON(s: ENetUC_Common.AsnJSONWebTokenHeader, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnJSONWebTokenHeader & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJSONWebTokenHeader");

		const t = {} as ENetUC_Common.AsnJSONWebTokenHeader & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJSONWebTokenHeader";
		TSConverter.fillJSONParam(s, t, "u8sTYP", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sALG", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCTY", "string", errors, newContext, true);
		if (s.optionals) {
			const _optionals = AsnStringPairList_Converter.toJSON(s.optionals, errors, newContext, "optionals");
			if (_optionals)
				t.optionals = _optionals;
		}

		if (errors.validateResult(newContext, "AsnJSONWebTokenHeader"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnJSONWebTokenHeader | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJSONWebTokenHeader");

		let t: ENetUC_Common.AsnJSONWebTokenHeader | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnJSONWebTokenHeader>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnJSONWebTokenHeader["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sTYP", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sALG", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sCTY", "string", errors, context, true);
			t.optionals = AsnStringPairList_Converter.fromJSON(s.optionals, errors, newContext, "optionals", true);
		}
		if (errors.validateResult(newContext, "AsnJSONWebTokenHeader"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnJSONWebTokenHeader | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJSONWebTokenHeader";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJSONWebTokenHeader");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sTYP", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sALG", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCTY", "string", errors, newContext, true);
		const _optionals = AsnStringPairList_Converter.toBER(s.optionals, errors, newContext, "optionals", 1);
		if (errors.validateResult(newContext, "AsnJSONWebTokenHeader")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sTYP, name: "u8sTYP" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sALG, name: "u8sALG" }));
			if (s.u8sCTY !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCTY, name: "u8sCTY", idBlock: { optionalID: 0 } }));
			if (_optionals)
				t.push(_optionals);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnJSONWebTokenHeader | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJSONWebTokenHeader");

		let t: ENetUC_Common.AsnJSONWebTokenHeader | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnJSONWebTokenHeader.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnJSONWebTokenHeader["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sTYP", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sALG", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCTY", "Utf8String", errors, newContext, true);
			t.optionals = AsnStringPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionals"), errors, newContext, "optionals", true);
		}

		if (errors.validateResult(newContext, "AsnJSONWebTokenHeader"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJSONWebTokenPayLoad_Converter {
	public static toJSON(s: ENetUC_Common.AsnJSONWebTokenPayLoad, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnJSONWebTokenPayLoad & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJSONWebTokenPayLoad");

		const t = {} as ENetUC_Common.AsnJSONWebTokenPayLoad & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJSONWebTokenPayLoad";
		TSConverter.fillJSONParam(s, t, "u8sISS", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sSUB", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sAUD", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "utcEXP", "Date", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "utcNBF", "Date", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "utcIAT", "Date", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sJTI", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iUserType", "number", errors, newContext, true);
		if (s.optionals) {
			const _optionals = AsnStringPairList_Converter.toJSON(s.optionals, errors, newContext, "optionals");
			if (_optionals)
				t.optionals = _optionals;
		}

		if (errors.validateResult(newContext, "AsnJSONWebTokenPayLoad"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnJSONWebTokenPayLoad | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJSONWebTokenPayLoad");

		let t: ENetUC_Common.AsnJSONWebTokenPayLoad | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnJSONWebTokenPayLoad>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnJSONWebTokenPayLoad["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sISS", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sSUB", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sAUD", "string", errors, context, true);
			if (TSConverter.validateParam(s, "utcEXP", "string", errors, context, true) && s.utcEXP)
				t.utcEXP = new Date(s.utcEXP);
			if (TSConverter.validateParam(s, "utcNBF", "string", errors, context, true) && s.utcNBF)
				t.utcNBF = new Date(s.utcNBF);
			if (TSConverter.validateParam(s, "utcIAT", "string", errors, context, true) && s.utcIAT)
				t.utcIAT = new Date(s.utcIAT);
			TSConverter.fillJSONParam(s, t, "u8sJTI", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iUserType", "number", errors, context, true);
			t.optionals = AsnStringPairList_Converter.fromJSON(s.optionals, errors, newContext, "optionals", true);
		}
		if (errors.validateResult(newContext, "AsnJSONWebTokenPayLoad"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnJSONWebTokenPayLoad | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJSONWebTokenPayLoad";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJSONWebTokenPayLoad");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sISS", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sSUB", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sAUD", "string", errors, newContext, true);
		TSConverter.validateParam(s, "utcEXP", "Date", errors, newContext, true);
		TSConverter.validateParam(s, "utcNBF", "Date", errors, newContext, true);
		TSConverter.validateParam(s, "utcIAT", "Date", errors, newContext, true);
		TSConverter.validateParam(s, "u8sJTI", "string", errors, newContext);
		TSConverter.validateParam(s, "iUserType", "number", errors, newContext, true);
		const _optionals = AsnStringPairList_Converter.toBER(s.optionals, errors, newContext, "optionals", 7);
		if (errors.validateResult(newContext, "AsnJSONWebTokenPayLoad")) {
			if (s.u8sISS !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sISS, name: "u8sISS", idBlock: { optionalID: 0 } }));
			if (s.u8sSUB !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sSUB, name: "u8sSUB", idBlock: { optionalID: 1 } }));
			if (s.u8sAUD !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAUD, name: "u8sAUD", idBlock: { optionalID: 2 } }));
			if (s.utcEXP !== undefined)
				t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcEXP), name: "utcEXP", idBlock: { optionalID: 3 } }));
			if (s.utcNBF !== undefined)
				t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcNBF), name: "utcNBF", idBlock: { optionalID: 4 } }));
			if (s.utcIAT !== undefined)
				t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcIAT), name: "utcIAT", idBlock: { optionalID: 5 } }));
			t.push(new asn1ts.Utf8String({ value: s.u8sJTI, name: "u8sJTI" }));
			if (s.iUserType !== undefined)
				t.push(new asn1ts.Integer({ value: s.iUserType, name: "iUserType", idBlock: { optionalID: 6 } }));
			if (_optionals)
				t.push(_optionals);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnJSONWebTokenPayLoad | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJSONWebTokenPayLoad");

		let t: ENetUC_Common.AsnJSONWebTokenPayLoad | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnJSONWebTokenPayLoad.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnJSONWebTokenPayLoad["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sISS", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sSUB", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sAUD", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "utcEXP", "AsnSystemTime", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "utcNBF", "AsnSystemTime", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "utcIAT", "AsnSystemTime", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sJTI", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iUserType", "Integer", errors, newContext, true);
			t.optionals = AsnStringPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionals"), errors, newContext, "optionals", true);
		}

		if (errors.validateResult(newContext, "AsnJSONWebTokenPayLoad"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnOptionalParameters_Converter {
	public static toJSON(s: ENetUC_Common.AsnOptionalParameters, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnOptionalParameters | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOptionalParameters");

		const t = [] as ENetUC_Common.AsnOptionalParameters;

		if (newContext?.bUCServerOptionalParams) {
			const params = EAsnOptionalParametersConverter.toJSON(s, errors, newContext, name);
			if (errors.validateResult(newContext, "EAsnOptionalParameters"))
				return params;
			return undefined;
		}
		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnOptionalParam_Converter.toJSON(se, errors, newContext, "AsnOptionalParam");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnOptionalParameters"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnOptionalParameters | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOptionalParameters");

		let t: ENetUC_Common.AsnOptionalParameters | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnOptionalParameters>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Common.AsnOptionalParameters();
			if (s.length === undefined) {
				// Proprietary UCServer AsnOptionalParameters decoding
				EAsnOptionalParametersConverter.fromJSON(s, t, errors, context, name, optional);
			} else {
				// [Print_JSON_DecoderSetOfDefCode]
				for (const id in s) {
					const se = s[id];
					if (se === undefined)
						continue;
					const val = AsnOptionalParam_Converter.fromJSON(se, errors, newContext, "AsnOptionalParam", false);
					if (val)
						t.push(val);
				}
			}
		}
		if (errors.validateResult(newContext, "AsnOptionalParameters"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnOptionalParameters | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnOptionalParameters";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOptionalParameters");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnOptionalParam_Converter.toBER(s[id], errors, newContext, "AsnOptionalParam");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnOptionalParam"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnOptionalParameters | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOptionalParameters");

		let t: ENetUC_Common.AsnOptionalParameters | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnOptionalParameters.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Common.AsnOptionalParameters();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnOptionalParam_Converter.fromBER(se, errors, newContext, "AsnOptionalParam", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnOptionalParameters"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNetDatabaseContact_Converter {
	public static toJSON(s: ENetUC_Common.AsnNetDatabaseContact, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnNetDatabaseContact & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseContact");

		const t = {} as ENetUC_Common.AsnNetDatabaseContact & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNetDatabaseContact";
		TSConverter.fillJSONParam(s, t, "u8sFound", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sDatabaseName", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sEntryIDDB", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sEntryIDStore", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCtiServerUserName", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sDisplayName", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sFirstName", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sLastName", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sJobTitle", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCompany", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sDepartment", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sOfficeRoomNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCustomerID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sBusinessAddressStreet", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sBusinessAddressPostalCode", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sBusinessAddressCity", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sBusinessAddressState", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sBusinessAddressCountry", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPrivateAddressStreet", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPrivateAddressPostalCode", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPrivateAddressCity", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPrivateAddressState", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPrivateAddressCountry", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sOtherAddressStreet", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sOtherAddressPostalCode", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sOtherAddressCity", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sOtherAddressState", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sOtherAddressCountry", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sEMail", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sEMail2", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sEMail3", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sSIPAddress", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sWebPageURL", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPhoneBusiness", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPhoneBusiness2", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCompanyMainTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sAssistantTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPhoneHome", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPhoneHome2", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPrimaryTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPhoneMobile", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCarTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sRadioTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPagerTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sOtherTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sCallbackTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sISDNTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sTTYTTDTelephoneNumber", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sFaxBusiness", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sFaxHome", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sDirectWebLink", "string", errors, newContext, true);
		if (s.customFields) {
			const _customFields = AsnStringPairList_Converter.toJSON(s.customFields, errors, newContext, "customFields");
			if (_customFields)
				t.customFields = _customFields;
		}
		TSConverter.fillJSONParam(s, t, "bValid", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iPrivateContact", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iCtiServerUser", "number", errors, newContext, true);
		if (s.optionalParams) {
			const _optionalParams = AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseContact"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnNetDatabaseContact | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseContact");

		let t: ENetUC_Common.AsnNetDatabaseContact | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnNetDatabaseContact>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnNetDatabaseContact["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sFound", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sDatabaseName", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sEntryIDDB", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sEntryIDStore", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sCtiServerUserName", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sDisplayName", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sFirstName", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sLastName", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sJobTitle", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sCompany", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sDepartment", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sOfficeRoomNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sCustomerID", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sBusinessAddressStreet", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sBusinessAddressPostalCode", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sBusinessAddressCity", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sBusinessAddressState", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sBusinessAddressCountry", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPrivateAddressStreet", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPrivateAddressPostalCode", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPrivateAddressCity", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPrivateAddressState", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPrivateAddressCountry", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sOtherAddressStreet", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sOtherAddressPostalCode", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sOtherAddressCity", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sOtherAddressState", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sOtherAddressCountry", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sEMail", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sEMail2", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sEMail3", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sSIPAddress", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sWebPageURL", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPhoneBusiness", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPhoneBusiness2", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sCompanyMainTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sAssistantTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPhoneHome", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPhoneHome2", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPrimaryTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPhoneMobile", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sCarTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sRadioTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPagerTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sOtherTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sCallbackTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sISDNTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sTTYTTDTelephoneNumber", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sFaxBusiness", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sFaxHome", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sBody", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sDirectWebLink", "string", errors, context, true);
			t.customFields = AsnStringPairList_Converter.fromJSON(s.customFields, errors, newContext, "customFields", true);
			TSConverter.fillJSONParam(s, t, "bValid", "boolean", errors, context, true);
			TSConverter.fillJSONParam(s, t, "iPrivateContact", "number", errors, context, true);
			TSConverter.fillJSONParam(s, t, "iCtiServerUser", "number", errors, context, true);
			t.optionalParams = AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnNetDatabaseContact"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnNetDatabaseContact | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNetDatabaseContact";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseContact");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sFound", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sDatabaseName", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sEntryIDDB", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sEntryIDStore", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sEntryID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCtiServerUserName", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sDisplayName", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sFirstName", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sLastName", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sJobTitle", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCompany", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sDepartment", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sOfficeRoomNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCustomerID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sBusinessAddressStreet", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sBusinessAddressPostalCode", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sBusinessAddressCity", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sBusinessAddressState", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sBusinessAddressCountry", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPrivateAddressStreet", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPrivateAddressPostalCode", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPrivateAddressCity", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPrivateAddressState", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPrivateAddressCountry", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sOtherAddressStreet", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sOtherAddressPostalCode", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sOtherAddressCity", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sOtherAddressState", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sOtherAddressCountry", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sEMail", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sEMail2", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sEMail3", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sSIPAddress", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sWebPageURL", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPhoneBusiness", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPhoneBusiness2", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCompanyMainTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sAssistantTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPhoneHome", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPhoneHome2", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPrimaryTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPhoneMobile", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCarTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sRadioTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPagerTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sOtherTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sCallbackTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sISDNTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sTTYTTDTelephoneNumber", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sFaxBusiness", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sFaxHome", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sBody", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sDirectWebLink", "string", errors, newContext, true);
		const _customFields = AsnStringPairList_Converter.toBER(s.customFields, errors, newContext, "customFields", 53);
		TSConverter.validateParam(s, "bValid", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "iPrivateContact", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iCtiServerUser", "number", errors, newContext, true);
		const _optionalParams = AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 57);
		if (errors.validateResult(newContext, "AsnNetDatabaseContact")) {
			if (s.u8sFound !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sFound, name: "u8sFound", idBlock: { optionalID: 0 } }));
			if (s.u8sDatabaseName !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sDatabaseName, name: "u8sDatabaseName", idBlock: { optionalID: 1 } }));
			if (s.u8sEntryIDDB !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sEntryIDDB, name: "u8sEntryIDDB", idBlock: { optionalID: 2 } }));
			if (s.u8sEntryIDStore !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sEntryIDStore, name: "u8sEntryIDStore", idBlock: { optionalID: 3 } }));
			if (s.u8sEntryID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sEntryID, name: "u8sEntryID", idBlock: { optionalID: 4 } }));
			if (s.u8sCtiServerUserName !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCtiServerUserName, name: "u8sCtiServerUserName", idBlock: { optionalID: 5 } }));
			if (s.u8sDisplayName !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sDisplayName, name: "u8sDisplayName", idBlock: { optionalID: 6 } }));
			if (s.u8sFirstName !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sFirstName, name: "u8sFirstName", idBlock: { optionalID: 7 } }));
			if (s.u8sLastName !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sLastName, name: "u8sLastName", idBlock: { optionalID: 8 } }));
			if (s.u8sJobTitle !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sJobTitle, name: "u8sJobTitle", idBlock: { optionalID: 9 } }));
			if (s.u8sCompany !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCompany, name: "u8sCompany", idBlock: { optionalID: 10 } }));
			if (s.u8sDepartment !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sDepartment, name: "u8sDepartment", idBlock: { optionalID: 11 } }));
			if (s.u8sOfficeRoomNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sOfficeRoomNumber, name: "u8sOfficeRoomNumber", idBlock: { optionalID: 12 } }));
			if (s.u8sCustomerID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCustomerID, name: "u8sCustomerID", idBlock: { optionalID: 13 } }));
			if (s.u8sBusinessAddressStreet !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sBusinessAddressStreet, name: "u8sBusinessAddressStreet", idBlock: { optionalID: 14 } }));
			if (s.u8sBusinessAddressPostalCode !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sBusinessAddressPostalCode, name: "u8sBusinessAddressPostalCode", idBlock: { optionalID: 15 } }));
			if (s.u8sBusinessAddressCity !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sBusinessAddressCity, name: "u8sBusinessAddressCity", idBlock: { optionalID: 16 } }));
			if (s.u8sBusinessAddressState !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sBusinessAddressState, name: "u8sBusinessAddressState", idBlock: { optionalID: 17 } }));
			if (s.u8sBusinessAddressCountry !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sBusinessAddressCountry, name: "u8sBusinessAddressCountry", idBlock: { optionalID: 18 } }));
			if (s.u8sPrivateAddressStreet !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPrivateAddressStreet, name: "u8sPrivateAddressStreet", idBlock: { optionalID: 19 } }));
			if (s.u8sPrivateAddressPostalCode !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPrivateAddressPostalCode, name: "u8sPrivateAddressPostalCode", idBlock: { optionalID: 20 } }));
			if (s.u8sPrivateAddressCity !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPrivateAddressCity, name: "u8sPrivateAddressCity", idBlock: { optionalID: 21 } }));
			if (s.u8sPrivateAddressState !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPrivateAddressState, name: "u8sPrivateAddressState", idBlock: { optionalID: 22 } }));
			if (s.u8sPrivateAddressCountry !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPrivateAddressCountry, name: "u8sPrivateAddressCountry", idBlock: { optionalID: 23 } }));
			if (s.u8sOtherAddressStreet !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sOtherAddressStreet, name: "u8sOtherAddressStreet", idBlock: { optionalID: 24 } }));
			if (s.u8sOtherAddressPostalCode !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sOtherAddressPostalCode, name: "u8sOtherAddressPostalCode", idBlock: { optionalID: 25 } }));
			if (s.u8sOtherAddressCity !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sOtherAddressCity, name: "u8sOtherAddressCity", idBlock: { optionalID: 26 } }));
			if (s.u8sOtherAddressState !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sOtherAddressState, name: "u8sOtherAddressState", idBlock: { optionalID: 27 } }));
			if (s.u8sOtherAddressCountry !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sOtherAddressCountry, name: "u8sOtherAddressCountry", idBlock: { optionalID: 28 } }));
			if (s.u8sEMail !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sEMail, name: "u8sEMail", idBlock: { optionalID: 29 } }));
			if (s.u8sEMail2 !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sEMail2, name: "u8sEMail2", idBlock: { optionalID: 30 } }));
			if (s.u8sEMail3 !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sEMail3, name: "u8sEMail3", idBlock: { optionalID: 31 } }));
			if (s.u8sSIPAddress !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sSIPAddress, name: "u8sSIPAddress", idBlock: { optionalID: 32 } }));
			if (s.u8sWebPageURL !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sWebPageURL, name: "u8sWebPageURL", idBlock: { optionalID: 33 } }));
			if (s.u8sPhoneBusiness !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPhoneBusiness, name: "u8sPhoneBusiness", idBlock: { optionalID: 34 } }));
			if (s.u8sPhoneBusiness2 !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPhoneBusiness2, name: "u8sPhoneBusiness2", idBlock: { optionalID: 35 } }));
			if (s.u8sCompanyMainTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCompanyMainTelephoneNumber, name: "u8sCompanyMainTelephoneNumber", idBlock: { optionalID: 36 } }));
			if (s.u8sAssistantTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAssistantTelephoneNumber, name: "u8sAssistantTelephoneNumber", idBlock: { optionalID: 37 } }));
			if (s.u8sPhoneHome !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPhoneHome, name: "u8sPhoneHome", idBlock: { optionalID: 38 } }));
			if (s.u8sPhoneHome2 !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPhoneHome2, name: "u8sPhoneHome2", idBlock: { optionalID: 39 } }));
			if (s.u8sPrimaryTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPrimaryTelephoneNumber, name: "u8sPrimaryTelephoneNumber", idBlock: { optionalID: 40 } }));
			if (s.u8sPhoneMobile !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPhoneMobile, name: "u8sPhoneMobile", idBlock: { optionalID: 41 } }));
			if (s.u8sCarTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCarTelephoneNumber, name: "u8sCarTelephoneNumber", idBlock: { optionalID: 42 } }));
			if (s.u8sRadioTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sRadioTelephoneNumber, name: "u8sRadioTelephoneNumber", idBlock: { optionalID: 43 } }));
			if (s.u8sPagerTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPagerTelephoneNumber, name: "u8sPagerTelephoneNumber", idBlock: { optionalID: 44 } }));
			if (s.u8sOtherTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sOtherTelephoneNumber, name: "u8sOtherTelephoneNumber", idBlock: { optionalID: 45 } }));
			if (s.u8sCallbackTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sCallbackTelephoneNumber, name: "u8sCallbackTelephoneNumber", idBlock: { optionalID: 46 } }));
			if (s.u8sISDNTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sISDNTelephoneNumber, name: "u8sISDNTelephoneNumber", idBlock: { optionalID: 47 } }));
			if (s.u8sTTYTTDTelephoneNumber !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sTTYTTDTelephoneNumber, name: "u8sTTYTTDTelephoneNumber", idBlock: { optionalID: 48 } }));
			if (s.u8sFaxBusiness !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sFaxBusiness, name: "u8sFaxBusiness", idBlock: { optionalID: 49 } }));
			if (s.u8sFaxHome !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sFaxHome, name: "u8sFaxHome", idBlock: { optionalID: 50 } }));
			if (s.u8sBody !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sBody, name: "u8sBody", idBlock: { optionalID: 51 } }));
			if (s.u8sDirectWebLink !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sDirectWebLink, name: "u8sDirectWebLink", idBlock: { optionalID: 52 } }));
			if (_customFields)
				t.push(_customFields);
			if (s.bValid !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bValid, name: "bValid", idBlock: { optionalID: 54 } }));
			if (s.iPrivateContact !== undefined)
				t.push(new asn1ts.Integer({ value: s.iPrivateContact, name: "iPrivateContact", idBlock: { optionalID: 55 } }));
			if (s.iCtiServerUser !== undefined)
				t.push(new asn1ts.Integer({ value: s.iCtiServerUser, name: "iCtiServerUser", idBlock: { optionalID: 56 } }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnNetDatabaseContact | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseContact");

		let t: ENetUC_Common.AsnNetDatabaseContact | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnNetDatabaseContact.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnNetDatabaseContact["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sFound", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sDatabaseName", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sEntryIDDB", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sEntryIDStore", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sEntryID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCtiServerUserName", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sDisplayName", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sFirstName", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sLastName", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sJobTitle", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCompany", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sDepartment", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sOfficeRoomNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCustomerID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sBusinessAddressStreet", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sBusinessAddressPostalCode", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sBusinessAddressCity", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sBusinessAddressState", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sBusinessAddressCountry", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPrivateAddressStreet", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPrivateAddressPostalCode", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPrivateAddressCity", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPrivateAddressState", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPrivateAddressCountry", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sOtherAddressStreet", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sOtherAddressPostalCode", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sOtherAddressCity", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sOtherAddressState", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sOtherAddressCountry", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sEMail", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sEMail2", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sEMail3", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sSIPAddress", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sWebPageURL", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPhoneBusiness", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPhoneBusiness2", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCompanyMainTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sAssistantTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPhoneHome", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPhoneHome2", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPrimaryTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPhoneMobile", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCarTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sRadioTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPagerTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sOtherTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sCallbackTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sISDNTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sTTYTTDTelephoneNumber", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sFaxBusiness", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sFaxHome", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sBody", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sDirectWebLink", "Utf8String", errors, newContext, true);
			t.customFields = AsnStringPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "customFields"), errors, newContext, "customFields", true);
			TSConverter.fillASN1Param(s, t, "bValid", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iPrivateContact", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iCtiServerUser", "Integer", errors, newContext, true);
			t.optionalParams = AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseContact"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnIntegerPairList_Converter {
	public static toJSON(s: ENetUC_Common.AsnIntegerPairList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnIntegerPairList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnIntegerPairList");

		const t = [] as ENetUC_Common.AsnIntegerPairList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnIntegerPair_Converter.toJSON(se, errors, newContext, "AsnIntegerPair");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnIntegerPairList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnIntegerPairList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnIntegerPairList");

		let t: ENetUC_Common.AsnIntegerPairList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnIntegerPairList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Common.AsnIntegerPairList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnIntegerPair_Converter.fromJSON(se, errors, newContext, "AsnIntegerPair", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnIntegerPairList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnIntegerPairList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnIntegerPairList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnIntegerPairList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnIntegerPair_Converter.toBER(s[id], errors, newContext, "AsnIntegerPair");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnIntegerPair"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnIntegerPairList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnIntegerPairList");

		let t: ENetUC_Common.AsnIntegerPairList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnIntegerPairList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Common.AsnIntegerPairList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnIntegerPair_Converter.fromBER(se, errors, newContext, "AsnIntegerPair", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnIntegerPairList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnStringIntegerPairList_Converter {
	public static toJSON(s: ENetUC_Common.AsnStringIntegerPairList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnStringIntegerPairList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStringIntegerPairList");

		const t = [] as ENetUC_Common.AsnStringIntegerPairList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnStringIntegerPair_Converter.toJSON(se, errors, newContext, "AsnStringIntegerPair");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnStringIntegerPairList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnStringIntegerPairList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStringIntegerPairList");

		let t: ENetUC_Common.AsnStringIntegerPairList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnStringIntegerPairList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Common.AsnStringIntegerPairList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnStringIntegerPair_Converter.fromJSON(se, errors, newContext, "AsnStringIntegerPair", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnStringIntegerPairList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnStringIntegerPairList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnStringIntegerPairList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStringIntegerPairList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnStringIntegerPair_Converter.toBER(s[id], errors, newContext, "AsnStringIntegerPair");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnStringIntegerPair"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnStringIntegerPairList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStringIntegerPairList");

		let t: ENetUC_Common.AsnStringIntegerPairList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnStringIntegerPairList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Common.AsnStringIntegerPairList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnStringIntegerPair_Converter.fromBER(se, errors, newContext, "AsnStringIntegerPair", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnStringIntegerPairList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestErrorList_Converter {
	public static toJSON(s: ENetUC_Common.AsnRequestErrorList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnRequestErrorList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestErrorList");

		const t = [] as ENetUC_Common.AsnRequestErrorList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnRequestError_Converter.toJSON(se, errors, newContext, "AsnRequestError");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnRequestErrorList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnRequestErrorList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestErrorList");

		let t: ENetUC_Common.AsnRequestErrorList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnRequestErrorList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Common.AsnRequestErrorList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnRequestError_Converter.fromJSON(se, errors, newContext, "AsnRequestError", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnRequestErrorList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnRequestErrorList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestErrorList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestErrorList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnRequestError_Converter.toBER(s[id], errors, newContext, "AsnRequestError");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnRequestError"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnRequestErrorList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestErrorList");

		let t: ENetUC_Common.AsnRequestErrorList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnRequestErrorList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Common.AsnRequestErrorList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnRequestError_Converter.fromBER(se, errors, newContext, "AsnRequestError", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnRequestErrorList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class SEQInteger_Converter {
	public static toJSON(s: ENetUC_Common.SEQInteger, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.SEQInteger | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "SEQInteger");

		const t = [] as ENetUC_Common.SEQInteger;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const se of s) {
			if (TSConverter.validateParamType(se, "SEQInteger", "number", errors, newContext, false))
				t.push(se);
		}

		if (errors.validateResult(newContext, "SEQInteger"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.SEQInteger | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "SEQInteger");

		let t: ENetUC_Common.SEQInteger | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.SEQInteger>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Common.SEQInteger();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const se of s) {
				if (TSConverter.validateParamType(se, "SEQInteger", "number", errors, newContext, false))
					t.push(se);
			}
		}
		if (errors.validateResult(newContext, "SEQInteger"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.SEQInteger | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "SEQInteger";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "SEQInteger");

		// [Print_BER_EncoderSetOfDefCode]
		for (const se of s) {
			if (TSConverter.validateParamType(se, "SEQInteger", "number", errors, newContext, false))
				t.push(new asn1ts.Integer({ value: se }));
		}
		if (errors.validateResult(newContext, "(null)"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.SEQInteger | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "SEQInteger");

		let t: ENetUC_Common.SEQInteger | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.SEQInteger.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Common.SEQInteger();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Integer.typeGuard(se)) {
					const value = se.getValue();
					if (TSConverter.validateParamType(value, "SEQInteger", "number", errors, newContext, false))
						t.push(value);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "SEQInteger"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class UTF8StringList_Converter {
	public static toJSON(s: ENetUC_Common.UTF8StringList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.UTF8StringList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "UTF8StringList");

		const t = [] as ENetUC_Common.UTF8StringList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const se of s) {
			if (TSConverter.validateParamType(se, "UTF8StringList", "string", errors, newContext, false))
				t.push(se);
		}

		if (errors.validateResult(newContext, "UTF8StringList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.UTF8StringList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "UTF8StringList");

		let t: ENetUC_Common.UTF8StringList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.UTF8StringList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Common.UTF8StringList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const se of s) {
				if (TSConverter.validateParamType(se, "UTF8StringList", "string", errors, newContext, false))
					t.push(se);
			}
		}
		if (errors.validateResult(newContext, "UTF8StringList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.UTF8StringList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "UTF8StringList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "UTF8StringList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const se of s) {
			if (TSConverter.validateParamType(se, "UTF8StringList", "string", errors, newContext, false))
				t.push(new asn1ts.Utf8String({ value: se }));
		}
		if (errors.validateResult(newContext, "(null)"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.UTF8StringList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "UTF8StringList");

		let t: ENetUC_Common.UTF8StringList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.UTF8StringList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Common.UTF8StringList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Utf8String.typeGuard(se)) {
					const value = se.getValue();
					if (TSConverter.validateParamType(value, "UTF8StringList", "string", errors, newContext, false))
						t.push(value);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "UTF8StringList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNetDatabaseContactList_Converter {
	public static toJSON(s: ENetUC_Common.AsnNetDatabaseContactList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnNetDatabaseContactList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseContactList");

		const t = [] as ENetUC_Common.AsnNetDatabaseContactList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnNetDatabaseContact_Converter.toJSON(se, errors, newContext, "AsnNetDatabaseContact");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseContactList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnNetDatabaseContactList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseContactList");

		let t: ENetUC_Common.AsnNetDatabaseContactList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnNetDatabaseContactList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Common.AsnNetDatabaseContactList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnNetDatabaseContact_Converter.fromJSON(se, errors, newContext, "AsnNetDatabaseContact", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnNetDatabaseContactList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnNetDatabaseContactList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNetDatabaseContactList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseContactList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnNetDatabaseContact_Converter.toBER(s[id], errors, newContext, "AsnNetDatabaseContact");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseContact"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnNetDatabaseContactList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseContactList");

		let t: ENetUC_Common.AsnNetDatabaseContactList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnNetDatabaseContactList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Common.AsnNetDatabaseContactList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnNetDatabaseContact_Converter.fromBER(se, errors, newContext, "AsnNetDatabaseContact", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseContactList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNetDatabaseContactIDList_Converter {
	public static toJSON(s: ENetUC_Common.AsnNetDatabaseContactIDList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnNetDatabaseContactIDList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseContactIDList");

		const t = [] as ENetUC_Common.AsnNetDatabaseContactIDList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnNetDatabaseContactID_Converter.toJSON(se, errors, newContext, "AsnNetDatabaseContactID");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseContactIDList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnNetDatabaseContactIDList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseContactIDList");

		let t: ENetUC_Common.AsnNetDatabaseContactIDList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnNetDatabaseContactIDList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Common.AsnNetDatabaseContactIDList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnNetDatabaseContactID_Converter.fromJSON(se, errors, newContext, "AsnNetDatabaseContactID", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnNetDatabaseContactIDList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnNetDatabaseContactIDList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNetDatabaseContactIDList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseContactIDList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnNetDatabaseContactID_Converter.toBER(s[id], errors, newContext, "AsnNetDatabaseContactID");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseContactID"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnNetDatabaseContactIDList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseContactIDList");

		let t: ENetUC_Common.AsnNetDatabaseContactIDList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnNetDatabaseContactIDList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Common.AsnNetDatabaseContactIDList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnNetDatabaseContactID_Converter.fromBER(se, errors, newContext, "AsnNetDatabaseContactID", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseContactIDList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUserPropertyBag_Converter {
	public static toJSON(s: ENetUC_Common.AsnUserPropertyBag, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnUserPropertyBag & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserPropertyBag");

		const t = {} as ENetUC_Common.AsnUserPropertyBag & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUserPropertyBag";
		const _asnProperties = AsnStringPairList_Converter.toJSON(s.asnProperties, errors, newContext, "asnProperties");
		if (_asnProperties)
			t.asnProperties = _asnProperties;

		if (errors.validateResult(newContext, "AsnUserPropertyBag"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnUserPropertyBag | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserPropertyBag");

		let t: ENetUC_Common.AsnUserPropertyBag | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnUserPropertyBag>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnUserPropertyBag["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _asnproperties = AsnStringPairList_Converter.fromJSON(s.asnProperties, errors, newContext, "asnProperties", false);
			if (_asnproperties)
				t.asnProperties = _asnproperties;
		}
		if (errors.validateResult(newContext, "AsnUserPropertyBag"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnUserPropertyBag | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUserPropertyBag";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserPropertyBag");

		// [Print_BER_EncoderSeqDefCode]
		const _asnProperties = AsnStringPairList_Converter.toBER(s.asnProperties, errors, newContext, "asnProperties");
		if (errors.validateResult(newContext, "AsnUserPropertyBag")) {
			if (_asnProperties)
				t.push(_asnProperties);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnUserPropertyBag | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserPropertyBag");

		let t: ENetUC_Common.AsnUserPropertyBag | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnUserPropertyBag.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnUserPropertyBag["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _asnproperties = AsnStringPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnProperties"), errors, newContext, "asnProperties");
			if (_asnproperties)
				t.asnProperties = _asnproperties;
		}

		if (errors.validateResult(newContext, "AsnUserPropertyBag"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJSONWebToken_Converter {
	public static toJSON(s: ENetUC_Common.AsnJSONWebToken, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Common.AsnJSONWebToken & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJSONWebToken");

		const t = {} as ENetUC_Common.AsnJSONWebToken & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJSONWebToken";
		const _header = AsnJSONWebTokenHeader_Converter.toJSON(s.header, errors, newContext, "header");
		if (_header)
			t.header = _header;
		const _payload = AsnJSONWebTokenPayLoad_Converter.toJSON(s.payload, errors, newContext, "payload");
		if (_payload)
			t.payload = _payload;
		TSConverter.fillJSONParam(s, t, "signature", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnJSONWebToken"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnJSONWebToken | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJSONWebToken");

		let t: ENetUC_Common.AsnJSONWebToken | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Common.AsnJSONWebToken>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Common.AsnJSONWebToken["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _header = AsnJSONWebTokenHeader_Converter.fromJSON(s.header, errors, newContext, "header", false);
			if (_header)
				t.header = _header;
			const _payload = AsnJSONWebTokenPayLoad_Converter.fromJSON(s.payload, errors, newContext, "payload", false);
			if (_payload)
				t.payload = _payload;
			TSConverter.fillJSONParam(s, t, "signature", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnJSONWebToken"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Common.AsnJSONWebToken | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJSONWebToken";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJSONWebToken");

		// [Print_BER_EncoderSeqDefCode]
		const _header = AsnJSONWebTokenHeader_Converter.toBER(s.header, errors, newContext, "header");
		const _payload = AsnJSONWebTokenPayLoad_Converter.toBER(s.payload, errors, newContext, "payload");
		TSConverter.validateParam(s, "signature", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnJSONWebToken")) {
			if (_header)
				t.push(_header);
			if (_payload)
				t.push(_payload);
			t.push(new asn1ts.Utf8String({ value: s.signature, name: "signature" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Common.AsnJSONWebToken | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJSONWebToken");

		let t: ENetUC_Common.AsnJSONWebToken | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Common.AsnJSONWebToken.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Common.AsnJSONWebToken["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _header = AsnJSONWebTokenHeader_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "header"), errors, newContext, "header");
			if (_header)
				t.header = _header;
			const _payload = AsnJSONWebTokenPayLoad_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "payload"), errors, newContext, "payload");
			if (_payload)
				t.payload = _payload;
			TSConverter.fillASN1Param(s, t, "signature", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnJSONWebToken"))
			return t;

		return undefined;
	}
}
