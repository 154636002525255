import { Navigate, useLocation } from "react-router-dom";

import { useStore } from "../zustand/store";

interface IProtectedRouteProps {
	children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
	const loggedIn = useStore((state) => state.loggedIn);
	const location = useLocation();

	if (!loggedIn) {
		return <Navigate to="/login" replace state={{ from: location }} />;
	}

	return <>{children}</>;
};
