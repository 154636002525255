// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_ClientContent.ts
 * "UC-Server-Access-Protocol-ClientContent" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.23, 20.10.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * Client content interface (Favorite\/Monitor contacts) aka \"ClientContent\"
 * ## Module description
 * This module describes the interface for the client content, which are the contacts in the \"Favorites\" tab in the main window and the contacts in the contact monitor.
 *
 *
 * ## Client content data model
 * The client content consists of contact data (the data shown to the user, mainly AsnNetDatabaseContact objects) and the arragment of the contacts in the favorites\/monitor, the data structure.
 *
 * The data is devided in a list of contacts and a tree structure of groupclusters, groups and items.&lt;br \/&gt;
 * The root is the \"contentstruture\" which contains a list of groupclusters (AsnClientContentGroupCluster).&lt;br \/&gt;
 * Each groupcluster contains groups (AsnClientContentGroup) and each group contains items (AsnClientContentItem).&lt;br \/&gt;
 * Finally the item contains the reference to the contact data in the contactlist.
 *
 * &lt;pre&gt;
 * contactlist
 * contentstructure
 * |+- listGroupClusters
 * |   +- listGroups
 * |   |   +- listItems
 * |   |   |   +- item (== only a reference to the contact in the contactlist; normally its ENetDatabaseContactID.
 * |   |   |   +- item
 * |   |   |   +- item
 * |   |   .
 * |   .
 * .
 * &lt;\/pre&gt;
 *
 * ## How to use the API
 * This API is highly flexible - it can be used to get client content with contact data and\/or content structure and with\/without eventing for updates.
 * &lt;br \/&gt;
 * ### Synchronoously without any events:
 * Example for a new client, getting all the content (incl. photos) for the first time without any eventing. It is using asnGetClientContent, which is always sending
 * back all the contacts und structure. Onyl the pictures can be left out to save bandwidth.&lt;br \/&gt;
 *
 * UML section is not exported to this file!
 *
 * ### Asynchronously and with eventing:
 * Example for a client, getting all the content and subscribe for events using asnSubscribeClientContent.&lt;br \/&gt;
 * This Call subscribes to all Events and is asynchronous. The client content will be sent from the server by the change event asnClientContentChanged.
 * The event will then always occure if there are changes to the client content data or structure. What has changed is described in asnClientContentChanged.
 *
 * UML section is not exported to this file!
 *
 * ### Subscibe for events with differential update:
 * If a client has already a cached version of the client content, it can request a differential update from the server by sending his last known revision.
 * The resulting event contains only the changed entries (see AsnClientContentChangedArgument for Details) and the client has to update its state\/cache accordingly.&lt;br \/&gt;
 *
 * UML section is not exported to this file!
 *
 * &lt;br \/&gt;
 * The synchronous and asynchronous can be used together, e.g. if it makes sense to fetch the complete content at first and then subscribe to the events later on.&lt;br \/&gt;
 *
 * #### Remarks:
 * \"Changecounter\" or \"Revision\" of the client content, a 64 Bit Integer.
 *
 * ** For historical reasons the same data ist stored sometimes in strings \"u8sContentRevision\" or \"u8sRevision\" and mostly as a 64 bit integer in \"iRevision\".
 * It is the same value, just transported with a different representation.
 * ** The revision is a timestamp and will be defined by the server; the client cannot alter this value on the server directly, only by changing parts of the client content.
 * ** A new revision from the server is alwyays higher than an old one.
 *
 * Setting the iEventFlagsToSubscribe parameter to
 *
 * ** eCONTACTS will only result in contact data events
 * ** eSTRUCTURE will only result in structure events
 * ** eJPEGPHOTO will include the jpeg photos directly in the events; if not set, the contact data will only contain hash values kor the pictures and the client gan request the pictures via asnRequestContactImage if needed.
 *
 * ## Details on GroupCluster- and Group-IDs
 *
 * - GroupCluster IDs
 * - The GroupCluster ID is a normal number, starting with '100' and counting up.
 * - Reserved numbers are between 0-199:
 * - \"0\" to \"99\" -&gt; Reserved
 * - \"100\" -&gt; The favorites tab in the Windows client main window.
 * - \"101\" -&gt; First group cluster of the Windoes client which will be shown in the monitor window.
 * - \"150\" to \"199\" -&gt; Reserved for possible adminstrative purposes.
 * - \"200\" -&gt; First group the user can define by himself, so make sure new groupclusters has an id greater or euqals than that.
 * - Group IDs
 * - A Group ID is formatted like this \"g:G[number]\", but with the exception of the following reserved IDs they can be any unique string inside a group cluster.
 * It is recommended to follow the naming convention and make the IDs unique over the whole structure.
 * - Following the standard formatting of the Group ids, new ones should start at \"g:G800\" and upwards.
 * - GroupID \"g:G770\" - \"g:G778\": reserved for special groups.
 * - \"g:G770\" - Group with additional phone line items of the user; only visisble in the windows client if enabled in the client settings.
 * - \"g:G771\" - Group for the administrative \"Jeder sieht jeden im Monitor\" setting in the windows client monitor window.
 * - \"g:G772\" - Reserved.
 * - GroupID \"g:G779\" is the first freely usable group ID inside a group cluster.
 * For historical reasons the Windows client is always starting with \"g:G779\" in every group cluster, which is possible, but makes it a bit harder to distinct between the groups over the whole structure.
 *
 * ## Client Content Tagging
 * A client can \"tag\" contacts if changes on these contacts (presence, data etc.) are of special interest for the user.
 * Technically the \"tags\" are just the contact IDs in a list. The API provided here makes it possible to change the list on the UCServer and get events, if another client of the same user makes changes to the list.
 * In the Windows client it is used to inform the user of specific presence state changes of the contacts the user tagged in the UI (e.g. available again, has a call to pickup etc.).
 */
// [PrintTSImports]
import { TSDeprecatedCallback } from "./TSDeprecatedCallback";
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
// [PrintTSRootTypes]
export const moduleName = "ENetUC_ClientContent";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnClientContentContactFilter {
	/** No content filter, just send all data. */
	eNOFILTER = 0,
	/** Set this if the client has an own picture chache and don't want the pictures ang get hashes instead. */
	eNOPICTURES = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnSubscribeClientContentFlags {
	/** eALL = eCONTACTS | eSTRUCTURE | eJPEGPHOTO */
	eALL = 0,
	/** Get events for changes in the contacts. */
	eCONTACTS = 1,
	/** Get events for changes in the structure. */
	eSTRUCTURE = 2,
	/** If set, the result includes all existing contact photos. If not set the result contains only the hash values of the photos, and the client can decide if it wants to fetch the photos with asnRequestContactImage. */
	eJPEGPHOTO = 4
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnChangeClientContentFlags {
	/** reserved */
	eUPDATEORIGINATOR = 0,
	/** Instead of merging the data, the server should replace the all existing data with the new ones. */
	eFULLUPDATE = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnClientContentChangedFlags {
	/** If nothing is set it is a full update of the given elements.. */
	eFULLUPDATE = 0
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAdministrativeFunctionKeyTypes {
	/** functionkey is a dialing button, the value is simply a number to dial */
	eAdministrativeFunctionKeyDial = 0
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAdministrativeMonitorContentSubscriptionFlags {
	/** Set value of 0 will prevent the server of sending events to the client. (See AsnGetAndSubscribeAdministrativeMonitorContentArgument for details). */
	eAdministrativeMonitorContentGroupNoEvents = 0,
	/** If groups are changing on the server, an event will be send from the server to the client with the group(s) with the related groups. */
	eAdministrativeMonitorContentGroupChanges = 1
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnGetClientContent.
 */
export class AsnGetClientContentArgument {
	public constructor(that: AsnGetClientContentArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetClientContentArgument {
		return new AsnGetClientContentArgument({
			iContactFilter: 0
		});
	}

	public static type = "AsnGetClientContentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetClientContentArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iContactFilter" })
			]
		});
	}

	/** Filter the results - mainly for suppressing the piuctures in the result. */
	public iContactFilter!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnSubscribeClientContent.
 */
export class AsnSubscribeClientContentArgument {
	public constructor(that?: AsnSubscribeClientContentArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnSubscribeClientContentArgument {
		return new AsnSubscribeClientContentArgument();
	}

	public static type = "AsnSubscribeClientContentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSubscribeClientContentArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iRevision", idBlock: { optionalID: 0 } }),
				new asn1ts.Integer({ name: "iEventFlagsToSubscribe", idBlock: { optionalID: 1 } }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sGroupClusterIDRestriction", idBlock: { optionalID: 2 } })
			]
		});
	}

	/**
	 * Transaction counter known by the client, the server can return just the changes since last request.&lt;br \/&gt;
	 * Send 0 here to get the complete client content known by the server.
	 */
	public iRevision?: number;
	/** Subscribe to specific or all events and define the behaviour according the jpeg photos in the contact datasets. See AsnSubscribeClientContentFlags for details. */
	public iEventFlagsToSubscribe?: number;
	/** If set the server will only send back the given groupclusters. The field contains a list of groupcluster IDs. */
	public u8sGroupClusterIDRestriction?: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnSubscribeClientContent
 */
export class AsnSubscribeClientContentResult {
	public constructor(that: AsnSubscribeClientContentResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnSubscribeClientContentResult {
		return new AsnSubscribeClientContentResult({
			iRevision: 0
		});
	}

	public static type = "AsnSubscribeClientContentResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSubscribeClientContentResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iRevision" })
			]
		});
	}

	/** The actual transaction counter known by the server. */
	public iRevision!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnUnsubscribeClientContent.
 */
export class AsnUnsubscribeClientContentArgument {
	public constructor(that?: AsnUnsubscribeClientContentArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUnsubscribeClientContentArgument {
		return new AsnUnsubscribeClientContentArgument();
	}

	public static type = "AsnUnsubscribeClientContentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUnsubscribeClientContentArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnUnsubscribeClientContent.
 */
export class AsnUnsubscribeClientContentResult {
	public constructor(that?: AsnUnsubscribeClientContentResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUnsubscribeClientContentResult {
		return new AsnUnsubscribeClientContentResult();
	}

	public static type = "AsnUnsubscribeClientContentResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUnsubscribeClientContentResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnChangeClientContent.
 * Event vom Client zum Server, dass sich die ClientDaten geaendert haben
 */
export class AsnChangeClientContentResult {
	public constructor(that: AsnChangeClientContentResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnChangeClientContentResult {
		return new AsnChangeClientContentResult({
			iRevision: 0
		});
	}

	public static type = "AsnChangeClientContentResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnChangeClientContentResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iRevision" })
			]
		});
	}

	/** The resulting, new revision of the client content. */
	public iRevision!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * AsnClientContentItem describes the item which will be displayed in the UI.
 * These items are the structural placeholder for the contact data which should be displayed.
 * It contains a 'pointer' to the data in form of the presence ID which should match a contact entry in the contacts list of the client content.
 *
 * Noteable is, that the AsnNetDatabaseContactIDList can contain more than one reference to a contact (AsnNetDatabaseContactID)
 * - this makes it possible in the future, that contacts with more than one dataset from different databases can be \"merged\" in the UI.
 * This feature ist not implemented yet.
 */
export class AsnClientContentItem {
	public constructor(that: AsnClientContentItem) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentItem {
		return new AsnClientContentItem({
			u8sID: "",
			u8sPresenceID: "",
			iIsEditable: 0,
			listNetDatabaseContactIDs: new ENetUC_Common.AsnNetDatabaseContactIDList(),
			selectedNetDatabaseContactID: ENetUC_Common.AsnNetDatabaseContactID["initEmpty"].call(0)
		});
	}

	public static type = "AsnClientContentItem";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentItem",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sID" }),
				new asn1ts.Utf8String({ name: "u8sPresenceID" }),
				new asn1ts.Integer({ name: "iIsEditable" }),
				ENetUC_Common.AsnNetDatabaseContactIDList.getASN1Schema({ name: "listNetDatabaseContactIDs" }),
				ENetUC_Common.AsnNetDatabaseContactID.getASN1Schema({ name: "selectedNetDatabaseContactID" })
			]
		});
	}

	/** Unique ID of the item in the client content, not of the contact it represents. */
	public u8sID!: string;
	/** Presence ID (aka sip:-address) of the contact, which presence state should be displayed on this item. This makes it possible, that contacts from external databases can have presence information without copying the internal dataset to the contact. */
	public u8sPresenceID!: string;
	/** Ignore for now, may be removed in the future. */
	public iIsEditable!: number;
	/** List of AsnNetDatabaseContactID s which can be merged in the UI. As for now, there will be only one supported. */
	public listNetDatabaseContactIDs!: ENetUC_Common.AsnNetDatabaseContactIDList;
	/** Last selected contact in the UI. This makes it possible, that this item could be displayed on every client. Makes only sense in desktop clients. */
	public selectedNetDatabaseContactID!: ENetUC_Common.AsnNetDatabaseContactID;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnClientContentSubscribeToTags
 */
export class AsnClientContentSubscribeToTagsArgument {
	public constructor(that: AsnClientContentSubscribeToTagsArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentSubscribeToTagsArgument {
		return new AsnClientContentSubscribeToTagsArgument({
			iSubscribe: 0
		});
	}

	public static type = "AsnClientContentSubscribeToTagsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentSubscribeToTagsArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iSubscribe" })
			]
		});
	}

	/** always 1 */
	public iSubscribe!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Rsult of asnClientContentSubscribeToTags
 */
export class AsnClientContentSubscribeToTagsResult {
	public constructor(that?: AsnClientContentSubscribeToTagsResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentSubscribeToTagsResult {
		return new AsnClientContentSubscribeToTagsResult();
	}

	public static type = "AsnClientContentSubscribeToTagsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentSubscribeToTagsResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for event asnClientContentTaggedIDsChanged.
 */
export class AsnClientContentTaggedIDsChangedArgument {
	public constructor(that: AsnClientContentTaggedIDsChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentTaggedIDsChangedArgument {
		return new AsnClientContentTaggedIDsChangedArgument({
			u8sTaggedIDs: new ENetUC_Common.UTF8StringList(),
			iRevision: 0
		});
	}

	public static type = "AsnClientContentTaggedIDsChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentTaggedIDsChangedArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sTaggedIDs" }),
				new asn1ts.Integer({ name: "iRevision" })
			]
		});
	}

	/** New contact ID list of the contacts to be tagged. */
	public u8sTaggedIDs!: ENetUC_Common.UTF8StringList;
	/** Reserved */
	public iRevision!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnClientContentSaveTags.
 */
export class AsnClientContentSaveTagsArgument {
	public constructor(that: AsnClientContentSaveTagsArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentSaveTagsArgument {
		return new AsnClientContentSaveTagsArgument({
			u8sTaggedIDs: new ENetUC_Common.UTF8StringList(),
			iRevision: 0
		});
	}

	public static type = "AsnClientContentSaveTagsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentSaveTagsArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sTaggedIDs" }),
				new asn1ts.Integer({ name: "iRevision" })
			]
		});
	}

	/** List of contacts to toggle tag state - new contactIDs get tagged, missing contact IDs will be untagged. */
	public u8sTaggedIDs!: ENetUC_Common.UTF8StringList;
	/** Reserved */
	public iRevision!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnClientContentSaveTags.
 */
export class AsnClientContentSaveTagsResult {
	public constructor(that: AsnClientContentSaveTagsResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentSaveTagsResult {
		return new AsnClientContentSaveTagsResult({
			iRevision: 0
		});
	}

	public static type = "AsnClientContentSaveTagsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentSaveTagsResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iRevision" })
			]
		});
	}

	/** Reserved */
	public iRevision!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Defines a function key: type (e.g. dialing) and type-dependant value
 */
export class AsnAdministrativeFunctionKey {
	public constructor(that: AsnAdministrativeFunctionKey) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAdministrativeFunctionKey {
		return new AsnAdministrativeFunctionKey({
			iType: 0,
			u8sValue: "",
			u8sName: ""
		});
	}

	public static type = "AsnAdministrativeFunctionKey";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAdministrativeFunctionKey",
			...params,
			value: [
				new asn1ts.Integer({ name: "iType" }),
				new asn1ts.Utf8String({ name: "u8sValue" }),
				new asn1ts.Utf8String({ name: "u8sName" })
			]
		});
	}

	/** AsnAdministrativeFunctionKeyTypes */
	public iType!: number;
	/** what to do, depends on type. usually a number to dial */
	public u8sValue!: string;
	public u8sName!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Arguments for asnGetAndSubscribeAdministrativeMonitorContent. The content will be generated by the UCServer using the logged in user.
 */
export class AsnGetAndSubscribeAdministrativeMonitorContentArgument {
	public constructor(that?: AsnGetAndSubscribeAdministrativeMonitorContentArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetAndSubscribeAdministrativeMonitorContentArgument {
		return new AsnGetAndSubscribeAdministrativeMonitorContentArgument();
	}

	public static type = "AsnGetAndSubscribeAdministrativeMonitorContentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetAndSubscribeAdministrativeMonitorContentArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iEventFlagsToSubscribe", idBlock: { optionalID: 0 } })
			]
		});
	}

	/**
	 * Optional parameter. If left out the UCServer assume eAdministrativeMonitorContentGroupChanges (1) as default.
	 * This parmameter can be set to eAdministrativeMonitorContentGroupNoEvents (0) for requesting the actual content list but not to subscribe to events.
	 */
	public iEventFlagsToSubscribe?: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argumment for asnUnsubscribeAdministrativeMonitorContent
 */
export class AsnUnsubscribeAdministrativeMonitorContentArgument {
	public constructor(that?: AsnUnsubscribeAdministrativeMonitorContentArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUnsubscribeAdministrativeMonitorContentArgument {
		return new AsnUnsubscribeAdministrativeMonitorContentArgument();
	}

	public static type = "AsnUnsubscribeAdministrativeMonitorContentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUnsubscribeAdministrativeMonitorContentArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnUnsubscribeAdministrativeMonitorContent
 */
export class AsnUnsubscribeAdministrativeMonitorContentResult {
	public constructor(that: AsnUnsubscribeAdministrativeMonitorContentResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnUnsubscribeAdministrativeMonitorContentResult {
		return new AsnUnsubscribeAdministrativeMonitorContentResult({
			bSuccess: false
		});
	}

	public static type = "AsnUnsubscribeAdministrativeMonitorContentResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUnsubscribeAdministrativeMonitorContentResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" })
			]
		});
	}

	/** true if successful (if some error happens, AsnRequestError will contain the reason) */
	public bSuccess!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnClientContentItemList extends Array<AsnClientContentItem> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnClientContentItem.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnAdministrativeFunctionKeyList extends Array<AsnAdministrativeFunctionKey> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnAdministrativeFunctionKey.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * AsnClientContentGroup object contains the list of AsnClientContentItem objects and some meta data for displaying groups in the UI.
 * Groups structure contains a list of AsnClientContentItem objects so they can be managed\/displayed in the UI.
 * A group contains also an unique id (unique in the client content of the user), a display name, a sort order,
 * if the group is expanded or collapsed in the UI, editable or persistet on the server.
 */
export class AsnClientContentGroup {
	public constructor(that: AsnClientContentGroup) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentGroup {
		return new AsnClientContentGroup({
			u8sID: "",
			u8sName: "",
			u8sOrderBy: "",
			iAutorefresh: 0,
			iExpanded: 0,
			iIsEditable: 0,
			iIsPersistent: 0,
			listItems: new AsnClientContentItemList()
		});
	}

	public static type = "AsnClientContentGroup";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentGroup",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sID" }),
				new asn1ts.Utf8String({ name: "u8sName" }),
				new asn1ts.Utf8String({ name: "u8sOrderBy" }),
				new asn1ts.Integer({ name: "iAutorefresh" }),
				new asn1ts.Integer({ name: "iExpanded" }),
				new asn1ts.Integer({ name: "iIsEditable" }),
				new asn1ts.Integer({ name: "iIsPersistent" }),
				AsnClientContentItemList.getASN1Schema({ name: "listItems" })
			]
		});
	}

	/** Unique ID of this group in the client content. */
	public u8sID!: string;
	/** Name to display in an UI */
	public u8sName!: string;
	/** Sortmechanism to use */
	public u8sOrderBy!: string;
	/** ignore for now, may be removed in the future */
	public iAutorefresh!: number;
	/** the client should display this group 'open' (=1, items visible) or 'closed' (=0, only group header visible) */
	public iExpanded!: number;
	/** contents of this group can be changed by the user */
	public iIsEditable!: number;
	/** contents of this group will be saved by the server; if not, contents ist mostly autmatically generated by the server and will be saved in XML\/database */
	public iIsPersistent!: number;
	/** list of items (=contact IDs) in this group */
	public listItems!: AsnClientContentItemList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnClientContentGroupList extends Array<AsnClientContentGroup> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnClientContentGroup.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * A group of contact IDs to display as a contact group of a administrative Monitor
 * This structure describes a group of UCServer users (identified by SIP-Adresses) and the group itself.
 */
export class AsnAdministrativeMonitorGroup {
	public constructor(that: AsnAdministrativeMonitorGroup) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAdministrativeMonitorGroup {
		return new AsnAdministrativeMonitorGroup({
			u8sGroupURI: "",
			u8sGroupName: "",
			listContactIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	public static type = "AsnAdministrativeMonitorGroup";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAdministrativeMonitorGroup",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sGroupURI" }),
				new asn1ts.Utf8String({ name: "u8sGroupName" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listContactIDs" }),
				AsnAdministrativeFunctionKeyList.getASN1Schema({ name: "listFunctionKeys", idBlock: { optionalID: 0 } }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 10 } })
			]
		});
	}

	/** ID of the Group in the usermanagement */
	public u8sGroupURI!: string;
	/** Name of the Group (for display) */
	public u8sGroupName!: string;
	/** List of ContactIDs (SIP-URIs) of the users in the Group */
	public listContactIDs!: ENetUC_Common.UTF8StringList;
	/** List of administrative defined function keys for every user in the group */
	public listFunctionKeys?: AsnAdministrativeFunctionKeyList;
	/** optionalParams Name \"flags\" Value number */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnAdministrativeMonitorGroupList extends Array<AsnAdministrativeMonitorGroup> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnAdministrativeMonitorGroup.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Results of asnGetAndSubscribeAdministrativeMonitorContent
 */
export class AsnGetAndSubscribeAdministrativeMonitorContentResult {
	public constructor(that: AsnGetAndSubscribeAdministrativeMonitorContentResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetAndSubscribeAdministrativeMonitorContentResult {
		return new AsnGetAndSubscribeAdministrativeMonitorContentResult({
			listAdministrativeMonitorGroups: new AsnAdministrativeMonitorGroupList()
		});
	}

	public static type = "AsnGetAndSubscribeAdministrativeMonitorContentResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetAndSubscribeAdministrativeMonitorContentResult",
			...params,
			value: [
				AsnAdministrativeMonitorGroupList.getASN1Schema({ name: "listAdministrativeMonitorGroups" })
			]
		});
	}

	/** List of AsnAdministrativeMonitorGroup objects the clients user is configured for on the UCServer. */
	public listAdministrativeMonitorGroups!: AsnAdministrativeMonitorGroupList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for event asnAdministrativeMonitorContentGroupAdded.
 */
export class AsnAdministrativeMonitorContentGroupAddedArgument {
	public constructor(that: AsnAdministrativeMonitorContentGroupAddedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAdministrativeMonitorContentGroupAddedArgument {
		return new AsnAdministrativeMonitorContentGroupAddedArgument({
			adminMonitorGroupAdded: AsnAdministrativeMonitorGroup["initEmpty"].call(0)
		});
	}

	public static type = "AsnAdministrativeMonitorContentGroupAddedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAdministrativeMonitorContentGroupAddedArgument",
			...params,
			value: [
				AsnAdministrativeMonitorGroup.getASN1Schema({ name: "adminMonitorGroupAdded" })
			]
		});
	}

	/** Group which was added */
	public adminMonitorGroupAdded!: AsnAdministrativeMonitorGroup;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for event asnAdministrativeMonitorContentGroupChanged.
 */
export class AsnAdministrativeMonitorContentGroupChangedArgument {
	public constructor(that: AsnAdministrativeMonitorContentGroupChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAdministrativeMonitorContentGroupChangedArgument {
		return new AsnAdministrativeMonitorContentGroupChangedArgument({
			adminMonitorGroupChanged: AsnAdministrativeMonitorGroup["initEmpty"].call(0)
		});
	}

	public static type = "AsnAdministrativeMonitorContentGroupChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAdministrativeMonitorContentGroupChangedArgument",
			...params,
			value: [
				AsnAdministrativeMonitorGroup.getASN1Schema({ name: "adminMonitorGroupChanged" })
			]
		});
	}

	/** Groups which was changed */
	public adminMonitorGroupChanged!: AsnAdministrativeMonitorGroup;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for event asnAdministrativeMonitorContentGroupRemoved.
 */
export class AsnAdministrativeMonitorContentGroupRemovedArgument {
	public constructor(that: AsnAdministrativeMonitorContentGroupRemovedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAdministrativeMonitorContentGroupRemovedArgument {
		return new AsnAdministrativeMonitorContentGroupRemovedArgument({
			adminMonitorGroupRemoved: AsnAdministrativeMonitorGroup["initEmpty"].call(0)
		});
	}

	public static type = "AsnAdministrativeMonitorContentGroupRemovedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAdministrativeMonitorContentGroupRemovedArgument",
			...params,
			value: [
				AsnAdministrativeMonitorGroup.getASN1Schema({ name: "adminMonitorGroupRemoved" })
			]
		});
	}

	/** Groups which was deleted */
	public adminMonitorGroupRemoved!: AsnAdministrativeMonitorGroup;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * AsnClientContentGroupCluster object contains a list of AsnClientContentGroup objects and some meta data for displaying groups in the UI.
 * GroupCluster structure contains the list of groups so they can be managed\/displayed in the UI.
 * The original design needed this, to give the user the ability to arrange their contacts in groups AND in \"groups of groups\" (= clusters).
 * In the desktop clients main window the favorites tab is a group cluster with visible groups named \"Favorites\".
 * In the Monitor window the user can per default manage only groupclusters with exact one group inside.
 * It is possible to activate the groups in the monitor too, but this feature is very seldom used by users.&lt;br \/&gt;
 * GroupClusters contains also an unique id (unique in the client content of the user), a display name and some flags.
 */
export class AsnClientContentGroupCluster {
	public constructor(that: AsnClientContentGroupCluster) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentGroupCluster {
		return new AsnClientContentGroupCluster({
			u8sID: "",
			u8sName: "",
			iIsEditable: 0,
			iIsPersistent: 0,
			iIsTabbed: 0,
			u8sNeighbourClusterID: "",
			listGroups: new AsnClientContentGroupList()
		});
	}

	public static type = "AsnClientContentGroupCluster";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentGroupCluster",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sID" }),
				new asn1ts.Utf8String({ name: "u8sName" }),
				new asn1ts.Integer({ name: "iIsEditable" }),
				new asn1ts.Integer({ name: "iIsPersistent" }),
				new asn1ts.Integer({ name: "iIsTabbed" }),
				new asn1ts.Utf8String({ name: "u8sNeighbourClusterID" }),
				AsnClientContentGroupList.getASN1Schema({ name: "listGroups" })
			]
		});
	}

	/** Unique ID of the group cluster inside the whole client content. */
	public u8sID!: string;
	/** Display name of the gorup cluster. */
	public u8sName!: string;
	/** The user may change the contents of this group cluster or not. Used mostly for administrative content, which the user should not manipulate. */
	public iIsEditable!: number;
	/** The content of the group cluster will be saved or not. The non persistent content ist also mostly used for administratve content, which is dynamically created by the server and therefore it makes no sense to save it in the database\/file. */
	public iIsPersistent!: number;
	/** ignore for now, may be removed in the future. */
	public iIsTabbed!: number;
	/** ignore for now, may be removed in the future. */
	public u8sNeighbourClusterID!: string;
	/** list of AsnClientContentGroups in this cluster. */
	public listGroups!: AsnClientContentGroupList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnClientContentGroupClusterList extends Array<AsnClientContentGroupCluster> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnClientContentGroupCluster.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * AsnClientContentStructure is the root of the client content structure object.
 * When calling asnGetClientContent or asnSubscribeClientContent, the result contains an instance of this object.
 * It is the entry point to the structure of the client content which describes which item is in which and which group is in which groupcluster.
 */
export class AsnClientContentStructure {
	public constructor(that: AsnClientContentStructure) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentStructure {
		return new AsnClientContentStructure({
			iIsEditable: 0,
			u8sVersion: "",
			u8sRevision: "",
			u8sLastWriter: "",
			listGroupClusters: new AsnClientContentGroupClusterList()
		});
	}

	public static type = "AsnClientContentStructure";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentStructure",
			...params,
			value: [
				new asn1ts.Integer({ name: "iIsEditable" }),
				new asn1ts.Utf8String({ name: "u8sVersion" }),
				new asn1ts.Utf8String({ name: "u8sRevision" }),
				new asn1ts.Utf8String({ name: "u8sLastWriter" }),
				AsnClientContentGroupClusterList.getASN1Schema({ name: "listGroupClusters" })
			]
		});
	}

	/** Indication for the client, that changes may be ignored by the server. Nomrally it is 1 because we want the user to manage his favorites for himself. */
	public iIsEditable!: number;
	/** Version of Content-Structure API, should be at least 2. */
	public u8sVersion!: string;
	/** Revision of user's content structure; will be set by the server every time the struture\/contacts will be changed. */
	public u8sRevision!: string;
	/** ignore for now, may be removed. */
	public u8sLastWriter!: string;
	/** list of groupclusters in this structure. */
	public listGroupClusters!: AsnClientContentGroupClusterList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetClientContent.
 */
export class AsnGetClientContentResult {
	public constructor(that: AsnGetClientContentResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetClientContentResult {
		return new AsnGetClientContentResult({
			u8sContentRevision: "",
			contactList: new ENetUC_Common.AsnNetDatabaseContactList(),
			contentstructure: AsnClientContentStructure["initEmpty"].call(0)
		});
	}

	public static type = "AsnGetClientContentResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetClientContentResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContentRevision" }),
				ENetUC_Common.AsnNetDatabaseContactList.getASN1Schema({ name: "contactList" }),
				AsnClientContentStructure.getASN1Schema({ name: "contentstructure" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** Actual revision of the client content on the server. */
	public u8sContentRevision!: string;
	/** List of the contacts in this client content. */
	public contactList!: ENetUC_Common.AsnNetDatabaseContactList;
	/** Tree structure of the items in this client content; the leave items of the tree are pointing to the contact objects in the contacts-list. */
	public contentstructure!: AsnClientContentStructure;
	/**
	 * If the server cannot open the client content for this user in the optional parameters:&lt;br \/&gt;
	 * ** key \"ClientContentXMLError\", value error number 0xffffff01 - The XML file cannot be read on the server side.
	 * ** key \"FileErrorCode\", value is the file access error code
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnChangeClientContent.
 */
export class AsnChangeClientContentArgument {
	public constructor(that: AsnChangeClientContentArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnChangeClientContentArgument {
		return new AsnChangeClientContentArgument({
			iRevision: 0,
			u8sVersion: "",
			iIsEditable: 0,
			iFlags: 0
		});
	}

	public static type = "AsnChangeClientContentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnChangeClientContentArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iRevision" }),
				new asn1ts.Utf8String({ name: "u8sVersion" }),
				new asn1ts.Integer({ name: "iIsEditable" }),
				ENetUC_Common.AsnNetDatabaseContactList.getASN1Schema({ name: "addedOrChangedContacts", idBlock: { optionalID: 0 } }),
				ENetUC_Common.AsnNetDatabaseContactIDList.getASN1Schema({ name: "removedContacts", idBlock: { optionalID: 1 } }),
				AsnClientContentGroupClusterList.getASN1Schema({ name: "listContentstructure", idBlock: { optionalID: 2 } }),
				new asn1ts.Integer({ name: "iFlags" })
			]
		});
	}

	/** The revision the changes are based on. */
	public iRevision!: number;
	/** Version of the content structure */
	public u8sVersion!: string;
	/** Defines if the user may change the contents or not (usually 1 - change is allowed) */
	public iIsEditable!: number;
	/** List of contacts (AsnNetDatabaseContact) to add or change. In case of an initial update as a result of asnSubscribeClientContent this list conatins all contacts. */
	public addedOrChangedContacts?: ENetUC_Common.AsnNetDatabaseContactList;
	/** List of contacts, specified by their IDs, to remove from the content. */
	public removedContacts?: ENetUC_Common.AsnNetDatabaseContactIDList;
	/** New layout of the client content tree. */
	public listContentstructure?: AsnClientContentGroupClusterList;
	/** If this flag is set to 1 the UCServer should remove all existing data and set the new ones instead. */
	public iFlags!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnClientContentChanged event.
 */
export class AsnClientContentChangedArgument {
	public constructor(that: AsnClientContentChangedArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnClientContentChangedArgument {
		return new AsnClientContentChangedArgument({
			iRevision: 0,
			iFlags: 0
		});
	}

	public static type = "AsnClientContentChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientContentChangedArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iRevision" }),
				ENetUC_Common.AsnNetDatabaseContactList.getASN1Schema({ name: "addedOrChangedContacts", idBlock: { optionalID: 0 } }),
				ENetUC_Common.AsnNetDatabaseContactIDList.getASN1Schema({ name: "removedContacts", idBlock: { optionalID: 1 } }),
				AsnClientContentGroupClusterList.getASN1Schema({ name: "listContentstructure", idBlock: { optionalID: 2 } }),
				new asn1ts.Integer({ name: "iFlags" })
			]
		});
	}

	/** Actual revision of the client content. */
	public iRevision!: number;
	/** List of added or changed contacts. */
	public addedOrChangedContacts?: ENetUC_Common.AsnNetDatabaseContactList;
	/** List of contact IDs of removed contacts. */
	public removedContacts?: ENetUC_Common.AsnNetDatabaseContactIDList;
	/** New layout of the client content tree. */
	public listContentstructure?: AsnClientContentGroupClusterList;
	/** If 0 replace the transmitted data with the current one in the client. The contacts will change independently, the structure always as a whole. In the future other values will be possible. */
	public iFlags!: number;
}
