// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_Auth_Converter.ts
 * "UC-Server-Access-Protocol-Auth" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.23, 20.10.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_Auth from "./ENetUC_Auth";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";
import * as ENetUC_Common_AsnContact_Converter from "./ENetUC_Common_AsnContact_Converter";
// [PrintTSRootTypes]
export const moduleName = "ENetUC_Auth_Converter";

// [PrintTSEncoderDecoderCode]
export class AsnAdminLoginChoice_Converter {
	public static toJSON(s: ENetUC_Auth.AsnAdminLoginChoice, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnAdminLoginChoice & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdminLoginChoice");

		const t = {} as ENetUC_Auth.AsnAdminLoginChoice & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAdminLoginChoice";
		if (s.u8sAdminPassword != null)
			TSConverter.fillJSONParam(s, t, "u8sAdminPassword", "string", errors, newContext);
		else if (s.u8sAdminPasswordCrypted != null)
			TSConverter.fillJSONParam(s, t, "u8sAdminPasswordCrypted", "string", errors, newContext);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnAdminLoginChoice"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAdminLoginChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdminLoginChoice");

		let t: ENetUC_Auth.AsnAdminLoginChoice | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnAdminLoginChoice>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnAdminLoginChoice["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.u8sAdminPassword !== undefined) {
				if (TSConverter.validateParam(s, "u8sAdminPassword", "string", errors, newContext))
					t.u8sAdminPassword = s.u8sAdminPassword;
			} else if (s.u8sAdminPasswordCrypted !== undefined) {
				if (TSConverter.validateParam(s, "u8sAdminPasswordCrypted", "string", errors, newContext))
					t.u8sAdminPasswordCrypted = s.u8sAdminPasswordCrypted;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}
		if (errors.validateResult(newContext, "AsnAdminLoginChoice"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnAdminLoginChoice | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "AsnAdminLoginChoice";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdminLoginChoice");

		// [Print_BER_EncoderChoiceDefCode]
		if (TSConverter.validateParam(s, "u8sAdminPassword", "string", errors, newContext, true))
			t = new asn1ts.Utf8String({ value: s.u8sAdminPassword, name: "u8sAdminPassword", idBlock: { optionalID: 0 } });
		else if (TSConverter.validateParam(s, "u8sAdminPasswordCrypted", "string", errors, newContext, true))
			t = new asn1ts.Utf8String({ value: s.u8sAdminPasswordCrypted, name: "u8sAdminPasswordCrypted", idBlock: { optionalID: 1 } });
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
		if (errors.validateResult(newContext, "AsnAdminLoginChoice"))
			return t;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAdminLoginChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdminLoginChoice");

		let t: ENetUC_Auth.AsnAdminLoginChoice | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnAdminLoginChoice.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnAdminLoginChoice["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "u8sAdminPassword" && asn1ts.Utf8String.typeGuard(s)) {
				const _u8sAdminPassword = s.getValue();
				if (TSConverter.validateParamType(_u8sAdminPassword, "u8sAdminPassword", "string", errors, newContext, false))
					t.u8sAdminPassword = _u8sAdminPassword;
			} else if (s.choiceName === "u8sAdminPasswordCrypted" && asn1ts.Utf8String.typeGuard(s)) {
				const _u8sAdminPasswordCrypted = s.getValue();
				if (TSConverter.validateParamType(_u8sAdminPasswordCrypted, "u8sAdminPasswordCrypted", "string", errors, newContext, false))
					t.u8sAdminPasswordCrypted = _u8sAdminPasswordCrypted;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnAdminLoginChoice"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNegotiateServerConnectionArgument_Converter {
	public static toJSON(s: ENetUC_Auth.AsnNegotiateServerConnectionArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnNegotiateServerConnectionArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionArgument");

		const t = {} as ENetUC_Auth.AsnNegotiateServerConnectionArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNegotiateServerConnectionArgument";
		TSConverter.fillJSONParam(s, t, "iClientProtocolVersion", "number", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionArgument");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnNegotiateServerConnectionArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iClientProtocolVersion", "number", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnNegotiateServerConnectionArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNegotiateServerConnectionArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iClientProtocolVersion", "number", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionArgument")) {
			t.push(new asn1ts.Integer({ value: s.iClientProtocolVersion, name: "iClientProtocolVersion" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionArgument");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnNegotiateServerConnectionArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iClientProtocolVersion", "Integer", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNegotiateServerConnectionResult_Converter {
	public static toJSON(s: ENetUC_Auth.AsnNegotiateServerConnectionResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnNegotiateServerConnectionResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionResult");

		const t = {} as ENetUC_Auth.AsnNegotiateServerConnectionResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNegotiateServerConnectionResult";
		TSConverter.fillJSONParam(s, t, "iServerProtocolVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iServerUMSecurityOption", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iServerTLSSecurityOption", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sServerAuthentication", "Uint8Array", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}
		TSConverter.fillJSONParam(s, t, "stServerTime", "Date", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionResult");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnNegotiateServerConnectionResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iServerProtocolVersion", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iServerUMSecurityOption", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iServerTLSSecurityOption", "number", errors, context, false);
			if (TSConverter.validateParam(s, "sServerAuthentication", "string", errors, context, false))
				t.sServerAuthentication = TSConverter.decode64(s.sServerAuthentication as unknown as string);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			if (TSConverter.validateParam(s, "stServerTime", "string", errors, context, true) && s.stServerTime)
				t.stServerTime = new Date(s.stServerTime);
		}
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnNegotiateServerConnectionResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNegotiateServerConnectionResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iServerProtocolVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "iServerUMSecurityOption", "number", errors, newContext);
		TSConverter.validateParam(s, "iServerTLSSecurityOption", "number", errors, newContext);
		TSConverter.validateParam(s, "sServerAuthentication", "Uint8Array", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		TSConverter.validateParam(s, "stServerTime", "Date", errors, newContext, true);
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionResult")) {
			t.push(new asn1ts.Integer({ value: s.iServerProtocolVersion, name: "iServerProtocolVersion" }));
			t.push(new asn1ts.Integer({ value: s.iServerUMSecurityOption, name: "iServerUMSecurityOption" }));
			t.push(new asn1ts.Integer({ value: s.iServerTLSSecurityOption, name: "iServerTLSSecurityOption" }));
			t.push(new asn1ts.OctetString({ valueHex: s.sServerAuthentication, name: "sServerAuthentication" }));
			if (_optionalParams)
				t.push(_optionalParams);
			if (s.stServerTime !== undefined)
				t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stServerTime), name: "stServerTime" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionResult");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnNegotiateServerConnectionResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iServerProtocolVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iServerUMSecurityOption", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iServerTLSSecurityOption", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sServerAuthentication", "OctetString", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
			TSConverter.fillASN1Param(s, t, "stServerTime", "AsnSystemTime", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNegotiateServerConnectionError_Converter {
	public static toJSON(s: ENetUC_Auth.AsnNegotiateServerConnectionError, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnNegotiateServerConnectionError & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionError");

		const t = {} as ENetUC_Auth.AsnNegotiateServerConnectionError & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNegotiateServerConnectionError";
		TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionError"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionError");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionError | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnNegotiateServerConnectionError>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionError["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionError"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnNegotiateServerConnectionError | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNegotiateServerConnectionError";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionError");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iErrorDetail", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sErrorString", "string", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionError")) {
			t.push(new asn1ts.Integer({ value: s.iErrorDetail, name: "iErrorDetail" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sErrorString, name: "u8sErrorString" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionError");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionError | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnNegotiateServerConnectionError.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionError["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iErrorDetail", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sErrorString", "Utf8String", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionError"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAuthenticationSimple_Converter {
	public static toJSON(s: ENetUC_Auth.AsnAuthenticationSimple, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnAuthenticationSimple & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationSimple");

		const t = {} as ENetUC_Auth.AsnAuthenticationSimple & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAuthenticationSimple";
		TSConverter.fillJSONParam(s, t, "u8sClientUsername", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sClientUserPassword", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnAuthenticationSimple"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationSimple | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationSimple");

		let t: ENetUC_Auth.AsnAuthenticationSimple | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnAuthenticationSimple>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnAuthenticationSimple["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sClientUsername", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sClientUserPassword", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnAuthenticationSimple"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnAuthenticationSimple | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAuthenticationSimple";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationSimple");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sClientUsername", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sClientUserPassword", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnAuthenticationSimple")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sClientUsername, name: "u8sClientUsername" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sClientUserPassword, name: "u8sClientUserPassword" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationSimple | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationSimple");

		let t: ENetUC_Auth.AsnAuthenticationSimple | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnAuthenticationSimple.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnAuthenticationSimple["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sClientUsername", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sClientUserPassword", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAuthenticationSimple"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAuthenticationSimpleCrypt_Converter {
	public static toJSON(s: ENetUC_Auth.AsnAuthenticationSimpleCrypt, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnAuthenticationSimpleCrypt & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationSimpleCrypt");

		const t = {} as ENetUC_Auth.AsnAuthenticationSimpleCrypt & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAuthenticationSimpleCrypt";
		TSConverter.fillJSONParam(s, t, "u8sClientUsername", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sClientUserPasswordCrypted", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnAuthenticationSimpleCrypt"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationSimpleCrypt | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationSimpleCrypt");

		let t: ENetUC_Auth.AsnAuthenticationSimpleCrypt | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnAuthenticationSimpleCrypt>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnAuthenticationSimpleCrypt["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sClientUsername", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sClientUserPasswordCrypted", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnAuthenticationSimpleCrypt"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnAuthenticationSimpleCrypt | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAuthenticationSimpleCrypt";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationSimpleCrypt");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sClientUsername", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sClientUserPasswordCrypted", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnAuthenticationSimpleCrypt")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sClientUsername, name: "u8sClientUsername" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sClientUserPasswordCrypted, name: "u8sClientUserPasswordCrypted" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationSimpleCrypt | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationSimpleCrypt");

		let t: ENetUC_Auth.AsnAuthenticationSimpleCrypt | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnAuthenticationSimpleCrypt.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnAuthenticationSimpleCrypt["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sClientUsername", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sClientUserPasswordCrypted", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAuthenticationSimpleCrypt"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAuthenticationWinuser_Converter {
	public static toJSON(s: ENetUC_Auth.AsnAuthenticationWinuser, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnAuthenticationWinuser & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationWinuser");

		const t = {} as ENetUC_Auth.AsnAuthenticationWinuser & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAuthenticationWinuser";
		TSConverter.fillJSONParam(s, t, "u8sClientUsername", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sClientUsernameAuthorisation", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnAuthenticationWinuser"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationWinuser | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationWinuser");

		let t: ENetUC_Auth.AsnAuthenticationWinuser | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnAuthenticationWinuser>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnAuthenticationWinuser["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sClientUsername", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sClientUsernameAuthorisation", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnAuthenticationWinuser"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnAuthenticationWinuser | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAuthenticationWinuser";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationWinuser");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sClientUsername", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sClientUsernameAuthorisation", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnAuthenticationWinuser")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sClientUsername, name: "u8sClientUsername" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sClientUsernameAuthorisation, name: "u8sClientUsernameAuthorisation" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationWinuser | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationWinuser");

		let t: ENetUC_Auth.AsnAuthenticationWinuser | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnAuthenticationWinuser.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnAuthenticationWinuser["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sClientUsername", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sClientUsernameAuthorisation", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAuthenticationWinuser"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAuthenticationEnhanced_Converter {
	public static toJSON(s: ENetUC_Auth.AsnAuthenticationEnhanced, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnAuthenticationEnhanced & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationEnhanced");

		const t = {} as ENetUC_Auth.AsnAuthenticationEnhanced & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAuthenticationEnhanced";
		TSConverter.fillJSONParam(s, t, "u8sClientUsername", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sClientUsernameAuthorisation", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAuthenticationMethod", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sAuthCredetials", "Uint8Array", errors, newContext);

		if (errors.validateResult(newContext, "AsnAuthenticationEnhanced"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationEnhanced | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationEnhanced");

		let t: ENetUC_Auth.AsnAuthenticationEnhanced | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnAuthenticationEnhanced>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnAuthenticationEnhanced["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sClientUsername", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sClientUsernameAuthorisation", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sAuthenticationMethod", "string", errors, context, false);
			if (TSConverter.validateParam(s, "sAuthCredetials", "string", errors, context, false))
				t.sAuthCredetials = TSConverter.decode64(s.sAuthCredetials as unknown as string);
		}
		if (errors.validateResult(newContext, "AsnAuthenticationEnhanced"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnAuthenticationEnhanced | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAuthenticationEnhanced";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationEnhanced");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sClientUsername", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sClientUsernameAuthorisation", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sAuthenticationMethod", "string", errors, newContext);
		TSConverter.validateParam(s, "sAuthCredetials", "Uint8Array", errors, newContext);
		if (errors.validateResult(newContext, "AsnAuthenticationEnhanced")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sClientUsername, name: "u8sClientUsername" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sClientUsernameAuthorisation, name: "u8sClientUsernameAuthorisation" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAuthenticationMethod, name: "u8sAuthenticationMethod" }));
			t.push(new asn1ts.OctetString({ valueHex: s.sAuthCredetials, name: "sAuthCredetials" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationEnhanced | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationEnhanced");

		let t: ENetUC_Auth.AsnAuthenticationEnhanced | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnAuthenticationEnhanced.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnAuthenticationEnhanced["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sClientUsername", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sClientUsernameAuthorisation", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAuthenticationMethod", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sAuthCredetials", "OctetString", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAuthenticationEnhanced"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLogonResult_Converter {
	public static toJSON(s: ENetUC_Auth.AsnLogonResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnLogonResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonResult");

		const t = {} as ENetUC_Auth.AsnLogonResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLogonResult";
		TSConverter.fillJSONParam(s, t, "u8sLoggedInUser", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iValidContact", "number", errors, newContext);
		const _ownContact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toJSON(s.ownContact, errors, newContext, "ownContact");
		if (_ownContact)
			t.ownContact = _ownContact;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}
		if (s.listConfigSTUNandTURN) {
			const _listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toJSON(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN");
			if (_listConfigSTUNandTURN)
				t.listConfigSTUNandTURN = _listConfigSTUNandTURN;
		}
		if (s.listDataCenterServers) {
			const _listDataCenterServers = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.listDataCenterServers, errors, newContext, "listDataCenterServers");
			if (_listDataCenterServers)
				t.listDataCenterServers = _listDataCenterServers;
		}

		if (errors.validateResult(newContext, "AsnLogonResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonResult");

		let t: ENetUC_Auth.AsnLogonResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnLogonResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnLogonResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLoggedInUser", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iValidContact", "number", errors, context, false);
			const _owncontact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromJSON(s.ownContact, errors, newContext, "ownContact", false);
			if (_owncontact)
				t.ownContact = _owncontact;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
			t.listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromJSON(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN", true);
			t.listDataCenterServers = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.listDataCenterServers, errors, newContext, "listDataCenterServers", true);
		}
		if (errors.validateResult(newContext, "AsnLogonResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnLogonResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnLogonResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLoggedInUser", "string", errors, newContext);
		TSConverter.validateParam(s, "iValidContact", "number", errors, newContext);
		const _ownContact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toBER(s.ownContact, errors, newContext, "ownContact");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 0);
		const _listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toBER(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN", 1);
		const _listDataCenterServers = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.listDataCenterServers, errors, newContext, "listDataCenterServers", 2);
		if (errors.validateResult(newContext, "AsnLogonResult")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLoggedInUser, name: "u8sLoggedInUser" }));
			t.push(new asn1ts.Integer({ value: s.iValidContact, name: "iValidContact" }));
			if (_ownContact)
				t.push(_ownContact);
			if (_optionalParams)
				t.push(_optionalParams);
			if (_listConfigSTUNandTURN)
				t.push(_listConfigSTUNandTURN);
			if (_listDataCenterServers)
				t.push(_listDataCenterServers);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonResult");

		let t: ENetUC_Auth.AsnLogonResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnLogonResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnLogonResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLoggedInUser", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iValidContact", "Integer", errors, newContext);
			const _owncontact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "ownContact"), errors, newContext, "ownContact");
			if (_owncontact)
				t.ownContact = _owncontact;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
			t.listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listConfigSTUNandTURN"), errors, newContext, "listConfigSTUNandTURN", true);
			t.listDataCenterServers = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listDataCenterServers"), errors, newContext, "listDataCenterServers", true);
		}

		if (errors.validateResult(newContext, "AsnLogonResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLogonResultSuccess_Converter {
	public static toJSON(s: ENetUC_Auth.AsnLogonResultSuccess, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnLogonResultSuccess & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonResultSuccess");

		const t = {} as ENetUC_Auth.AsnLogonResultSuccess & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLogonResultSuccess";
		TSConverter.fillJSONParam(s, t, "u8sLoggedInUser", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iValidContact", "number", errors, newContext);
		const _ownContact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toJSON(s.ownContact, errors, newContext, "ownContact");
		if (_ownContact)
			t.ownContact = _ownContact;
		if (s.listConfigSTUNandTURN) {
			const _listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toJSON(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN");
			if (_listConfigSTUNandTURN)
				t.listConfigSTUNandTURN = _listConfigSTUNandTURN;
		}
		if (s.listDataCenterServers) {
			const _listDataCenterServers = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.listDataCenterServers, errors, newContext, "listDataCenterServers");
			if (_listDataCenterServers)
				t.listDataCenterServers = _listDataCenterServers;
		}

		if (errors.validateResult(newContext, "AsnLogonResultSuccess"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonResultSuccess | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonResultSuccess");

		let t: ENetUC_Auth.AsnLogonResultSuccess | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnLogonResultSuccess>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnLogonResultSuccess["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLoggedInUser", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iValidContact", "number", errors, context, false);
			const _owncontact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromJSON(s.ownContact, errors, newContext, "ownContact", false);
			if (_owncontact)
				t.ownContact = _owncontact;
			t.listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromJSON(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN", true);
			t.listDataCenterServers = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.listDataCenterServers, errors, newContext, "listDataCenterServers", true);
		}
		if (errors.validateResult(newContext, "AsnLogonResultSuccess"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnLogonResultSuccess | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnLogonResultSuccess";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonResultSuccess");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLoggedInUser", "string", errors, newContext);
		TSConverter.validateParam(s, "iValidContact", "number", errors, newContext);
		const _ownContact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toBER(s.ownContact, errors, newContext, "ownContact");
		const _listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toBER(s.listConfigSTUNandTURN, errors, newContext, "listConfigSTUNandTURN", 1);
		const _listDataCenterServers = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.listDataCenterServers, errors, newContext, "listDataCenterServers", 2);
		if (errors.validateResult(newContext, "AsnLogonResultSuccess")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLoggedInUser, name: "u8sLoggedInUser" }));
			t.push(new asn1ts.Integer({ value: s.iValidContact, name: "iValidContact" }));
			if (_ownContact)
				t.push(_ownContact);
			if (_listConfigSTUNandTURN)
				t.push(_listConfigSTUNandTURN);
			if (_listDataCenterServers)
				t.push(_listDataCenterServers);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonResultSuccess | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonResultSuccess");

		let t: ENetUC_Auth.AsnLogonResultSuccess | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnLogonResultSuccess.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnLogonResultSuccess["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLoggedInUser", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iValidContact", "Integer", errors, newContext);
			const _owncontact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "ownContact"), errors, newContext, "ownContact");
			if (_owncontact)
				t.ownContact = _owncontact;
			t.listConfigSTUNandTURN = ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listConfigSTUNandTURN"), errors, newContext, "listConfigSTUNandTURN", true);
			t.listDataCenterServers = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listDataCenterServers"), errors, newContext, "listDataCenterServers", true);
		}

		if (errors.validateResult(newContext, "AsnLogonResultSuccess"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLogonError_Converter {
	public static toJSON(s: ENetUC_Auth.AsnLogonError, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnLogonError & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonError");

		const t = {} as ENetUC_Auth.AsnLogonError & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLogonError";
		TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sAuthCredetials", "Uint8Array", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sFailedUser", "string", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnLogonError"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonError");

		let t: ENetUC_Auth.AsnLogonError | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnLogonError>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnLogonError["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, context, false);
			if (TSConverter.validateParam(s, "sAuthCredetials", "string", errors, context, false))
				t.sAuthCredetials = TSConverter.decode64(s.sAuthCredetials as unknown as string);
			TSConverter.fillJSONParam(s, t, "u8sFailedUser", "string", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnLogonError"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnLogonError | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnLogonError";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonError");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iErrorDetail", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sErrorString", "string", errors, newContext);
		TSConverter.validateParam(s, "sAuthCredetials", "Uint8Array", errors, newContext);
		TSConverter.validateParam(s, "u8sFailedUser", "string", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnLogonError")) {
			t.push(new asn1ts.Integer({ value: s.iErrorDetail, name: "iErrorDetail" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sErrorString, name: "u8sErrorString" }));
			t.push(new asn1ts.OctetString({ valueHex: s.sAuthCredetials, name: "sAuthCredetials" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sFailedUser, name: "u8sFailedUser" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonError");

		let t: ENetUC_Auth.AsnLogonError | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnLogonError.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnLogonError["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iErrorDetail", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sErrorString", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sAuthCredetials", "OctetString", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sFailedUser", "Utf8String", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnLogonError"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLogoffArgument_Converter {
	public static toJSON(s: ENetUC_Auth.AsnLogoffArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnLogoffArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogoffArgument");

		const t = {} as ENetUC_Auth.AsnLogoffArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLogoffArgument";

		if (errors.validateResult(newContext, "AsnLogoffArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogoffArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogoffArgument");

		let t: ENetUC_Auth.AsnLogoffArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnLogoffArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnLogoffArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnLogoffArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnLogoffArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnLogoffArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogoffArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogoffArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogoffArgument");

		let t: ENetUC_Auth.AsnLogoffArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnLogoffArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnLogoffArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnLogoffArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetUserTokenResult_Converter {
	public static toJSON(s: ENetUC_Auth.AsnGetUserTokenResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnGetUserTokenResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserTokenResult");

		const t = {} as ENetUC_Auth.AsnGetUserTokenResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetUserTokenResult";
		TSConverter.fillJSONParam(s, t, "sToken", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetUserTokenResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetUserTokenResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserTokenResult");

		let t: ENetUC_Auth.AsnGetUserTokenResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnGetUserTokenResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnGetUserTokenResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "sToken", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetUserTokenResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnGetUserTokenResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetUserTokenResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserTokenResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sToken", "Uint8Array", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetUserTokenResult")) {
			t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.sToken), name: "sToken" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetUserTokenResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserTokenResult");

		let t: ENetUC_Auth.AsnGetUserTokenResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnGetUserTokenResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnGetUserTokenResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sToken", "OctetString", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetUserTokenResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetClientAuthenticationArgument_Converter {
	public static toJSON(s: ENetUC_Auth.AsnSetClientAuthenticationArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnSetClientAuthenticationArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientAuthenticationArgument");

		const t = {} as ENetUC_Auth.AsnSetClientAuthenticationArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetClientAuthenticationArgument";
		TSConverter.fillJSONParam(s, t, "iCtiClientAppID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sClientAuthentication", "Uint8Array", errors, newContext);

		if (errors.validateResult(newContext, "AsnSetClientAuthenticationArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnSetClientAuthenticationArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientAuthenticationArgument");

		let t: ENetUC_Auth.AsnSetClientAuthenticationArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnSetClientAuthenticationArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnSetClientAuthenticationArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iCtiClientAppID", "number", errors, context, false);
			if (TSConverter.validateParam(s, "sClientAuthentication", "string", errors, context, false))
				t.sClientAuthentication = TSConverter.decode64(s.sClientAuthentication as unknown as string);
		}
		if (errors.validateResult(newContext, "AsnSetClientAuthenticationArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnSetClientAuthenticationArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetClientAuthenticationArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientAuthenticationArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iCtiClientAppID", "number", errors, newContext);
		TSConverter.validateParam(s, "sClientAuthentication", "Uint8Array", errors, newContext);
		if (errors.validateResult(newContext, "AsnSetClientAuthenticationArgument")) {
			t.push(new asn1ts.Integer({ value: s.iCtiClientAppID, name: "iCtiClientAppID" }));
			t.push(new asn1ts.OctetString({ valueHex: s.sClientAuthentication, name: "sClientAuthentication" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnSetClientAuthenticationArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientAuthenticationArgument");

		let t: ENetUC_Auth.AsnSetClientAuthenticationArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnSetClientAuthenticationArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnSetClientAuthenticationArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iCtiClientAppID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sClientAuthentication", "OctetString", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSetClientAuthenticationArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetClientAuthenticationResult_Converter {
	public static toJSON(s: ENetUC_Auth.AsnSetClientAuthenticationResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnSetClientAuthenticationResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientAuthenticationResult");

		const t = {} as ENetUC_Auth.AsnSetClientAuthenticationResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetClientAuthenticationResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnSetClientAuthenticationResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnSetClientAuthenticationResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientAuthenticationResult");

		let t: ENetUC_Auth.AsnSetClientAuthenticationResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnSetClientAuthenticationResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnSetClientAuthenticationResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnSetClientAuthenticationResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnSetClientAuthenticationResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetClientAuthenticationResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientAuthenticationResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnSetClientAuthenticationResult")) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnSetClientAuthenticationResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientAuthenticationResult");

		let t: ENetUC_Auth.AsnSetClientAuthenticationResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnSetClientAuthenticationResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnSetClientAuthenticationResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSetClientAuthenticationResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnProfileAttribute_Converter {
	public static toJSON(s: ENetUC_Auth.AsnProfileAttribute, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnProfileAttribute & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnProfileAttribute");

		const t = {} as ENetUC_Auth.AsnProfileAttribute & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnProfileAttribute";
		TSConverter.fillJSONParam(s, t, "u8sServiceType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8seName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sValue", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnProfileAttribute"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnProfileAttribute | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnProfileAttribute");

		let t: ENetUC_Auth.AsnProfileAttribute | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnProfileAttribute>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnProfileAttribute["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sServiceType", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8seName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sValue", "string", errors, context, true);
		}
		if (errors.validateResult(newContext, "AsnProfileAttribute"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnProfileAttribute | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnProfileAttribute";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnProfileAttribute");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sServiceType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8seName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sValue", "string", errors, newContext, true);
		if (errors.validateResult(newContext, "AsnProfileAttribute")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sServiceType, name: "u8sServiceType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8seName, name: "u8seName" }));
			if (s.u8sValue !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sValue, name: "u8sValue", idBlock: { optionalID: 0 } }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnProfileAttribute | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnProfileAttribute");

		let t: ENetUC_Auth.AsnProfileAttribute | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnProfileAttribute.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnProfileAttribute["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sServiceType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8seName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sValue", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnProfileAttribute"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetLoggedInContactArgument_Converter {
	public static toJSON(s: ENetUC_Auth.AsnGetLoggedInContactArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnGetLoggedInContactArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLoggedInContactArgument");

		const t = {} as ENetUC_Auth.AsnGetLoggedInContactArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetLoggedInContactArgument";

		if (errors.validateResult(newContext, "AsnGetLoggedInContactArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetLoggedInContactArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLoggedInContactArgument");

		let t: ENetUC_Auth.AsnGetLoggedInContactArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnGetLoggedInContactArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnGetLoggedInContactArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnGetLoggedInContactArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnGetLoggedInContactArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetLoggedInContactArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLoggedInContactArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetLoggedInContactArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLoggedInContactArgument");

		let t: ENetUC_Auth.AsnGetLoggedInContactArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnGetLoggedInContactArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnGetLoggedInContactArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetLoggedInContactArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetLoggedInContactResult_Converter {
	public static toJSON(s: ENetUC_Auth.AsnGetLoggedInContactResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnGetLoggedInContactResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLoggedInContactResult");

		const t = {} as ENetUC_Auth.AsnGetLoggedInContactResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetLoggedInContactResult";
		TSConverter.fillJSONParam(s, t, "u8sLoggedInUser", "string", errors, newContext);
		const _ownContact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toJSON(s.ownContact, errors, newContext, "ownContact");
		if (_ownContact)
			t.ownContact = _ownContact;

		if (errors.validateResult(newContext, "AsnGetLoggedInContactResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetLoggedInContactResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLoggedInContactResult");

		let t: ENetUC_Auth.AsnGetLoggedInContactResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnGetLoggedInContactResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnGetLoggedInContactResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLoggedInUser", "string", errors, context, false);
			const _owncontact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromJSON(s.ownContact, errors, newContext, "ownContact", false);
			if (_owncontact)
				t.ownContact = _owncontact;
		}
		if (errors.validateResult(newContext, "AsnGetLoggedInContactResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnGetLoggedInContactResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetLoggedInContactResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLoggedInContactResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLoggedInUser", "string", errors, newContext);
		const _ownContact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toBER(s.ownContact, errors, newContext, "ownContact");
		if (errors.validateResult(newContext, "AsnGetLoggedInContactResult")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLoggedInUser, name: "u8sLoggedInUser" }));
			if (_ownContact)
				t.push(_ownContact);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetLoggedInContactResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLoggedInContactResult");

		let t: ENetUC_Auth.AsnGetLoggedInContactResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnGetLoggedInContactResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnGetLoggedInContactResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLoggedInUser", "Utf8String", errors, newContext);
			const _owncontact = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "ownContact"), errors, newContext, "ownContact");
			if (_owncontact)
				t.ownContact = _owncontact;
		}

		if (errors.validateResult(newContext, "AsnGetLoggedInContactResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAuthenticationChoice_Converter {
	public static toJSON(s: ENetUC_Auth.AsnAuthenticationChoice, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnAuthenticationChoice & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationChoice");

		const t = {} as ENetUC_Auth.AsnAuthenticationChoice & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAuthenticationChoice";
		if (s.simple != null) {
			const _simple = AsnAuthenticationSimple_Converter.toJSON(s.simple, errors, newContext, "simple");
			if (_simple)
				t.simple = _simple;
		} else if (s.simplecrypt != null) {
			const _simplecrypt = AsnAuthenticationSimpleCrypt_Converter.toJSON(s.simplecrypt, errors, newContext, "simplecrypt");
			if (_simplecrypt)
				t.simplecrypt = _simplecrypt;
		} else if (s.winuser != null) {
			const _winuser = AsnAuthenticationWinuser_Converter.toJSON(s.winuser, errors, newContext, "winuser");
			if (_winuser)
				t.winuser = _winuser;
		} else if (s.enhanced != null) {
			const _enhanced = AsnAuthenticationEnhanced_Converter.toJSON(s.enhanced, errors, newContext, "enhanced");
			if (_enhanced)
				t.enhanced = _enhanced;
		} else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnAuthenticationChoice"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationChoice");

		let t: ENetUC_Auth.AsnAuthenticationChoice | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnAuthenticationChoice>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnAuthenticationChoice["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.simple !== undefined)
				t.simple = AsnAuthenticationSimple_Converter.fromJSON(s.simple, errors, newContext, "simple", false);
			else if (s.simplecrypt !== undefined)
				t.simplecrypt = AsnAuthenticationSimpleCrypt_Converter.fromJSON(s.simplecrypt, errors, newContext, "simplecrypt", false);
			else if (s.winuser !== undefined)
				t.winuser = AsnAuthenticationWinuser_Converter.fromJSON(s.winuser, errors, newContext, "winuser", false);
			else if (s.enhanced !== undefined)
				t.enhanced = AsnAuthenticationEnhanced_Converter.fromJSON(s.enhanced, errors, newContext, "enhanced", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}
		if (errors.validateResult(newContext, "AsnAuthenticationChoice"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnAuthenticationChoice | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "AsnAuthenticationChoice";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAuthenticationChoice");

		// [Print_BER_EncoderChoiceDefCode]
		if (s.simple)
			t = AsnAuthenticationSimple_Converter.toBER(s.simple, errors, newContext, "simple", 0);
		else if (s.simplecrypt)
			t = AsnAuthenticationSimpleCrypt_Converter.toBER(s.simplecrypt, errors, newContext, "simplecrypt", 1);
		else if (s.winuser)
			t = AsnAuthenticationWinuser_Converter.toBER(s.winuser, errors, newContext, "winuser", 2);
		else if (s.enhanced)
			t = AsnAuthenticationEnhanced_Converter.toBER(s.enhanced, errors, newContext, "enhanced", 3);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
		if (errors.validateResult(newContext, "AsnAuthenticationChoice"))
			return t;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAuthenticationChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAuthenticationChoice");

		let t: ENetUC_Auth.AsnAuthenticationChoice | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnAuthenticationChoice.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnAuthenticationChoice["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "simple" && asn1ts.Sequence.typeGuard(s))
				t.simple = AsnAuthenticationSimple_Converter.fromBER(s, undefined, newContext, "simple", false);
			else if (s.choiceName === "simplecrypt" && asn1ts.Sequence.typeGuard(s))
				t.simplecrypt = AsnAuthenticationSimpleCrypt_Converter.fromBER(s, undefined, newContext, "simplecrypt", false);
			else if (s.choiceName === "winuser" && asn1ts.Sequence.typeGuard(s))
				t.winuser = AsnAuthenticationWinuser_Converter.fromBER(s, undefined, newContext, "winuser", false);
			else if (s.choiceName === "enhanced" && asn1ts.Sequence.typeGuard(s))
				t.enhanced = AsnAuthenticationEnhanced_Converter.fromBER(s, undefined, newContext, "enhanced", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnAuthenticationChoice"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAdminLogin_Converter {
	public static toJSON(s: ENetUC_Auth.AsnAdminLogin, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnAdminLogin & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdminLogin");

		const t = {} as ENetUC_Auth.AsnAdminLogin & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAdminLogin";
		TSConverter.fillJSONParam(s, t, "u8sAdminUsername", "string", errors, newContext);
		const _adminPassword = AsnAdminLoginChoice_Converter.toJSON(s.adminPassword, errors, newContext, "adminPassword");
		if (_adminPassword)
			t.adminPassword = _adminPassword;

		if (errors.validateResult(newContext, "AsnAdminLogin"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAdminLogin | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdminLogin");

		let t: ENetUC_Auth.AsnAdminLogin | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnAdminLogin>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnAdminLogin["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sAdminUsername", "string", errors, context, false);
			const _adminpassword = AsnAdminLoginChoice_Converter.fromJSON(s.adminPassword, errors, newContext, "adminPassword", false);
			if (_adminpassword)
				t.adminPassword = _adminpassword;
		}
		if (errors.validateResult(newContext, "AsnAdminLogin"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnAdminLogin | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAdminLogin";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAdminLogin");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sAdminUsername", "string", errors, newContext);
		const _adminPassword = AsnAdminLoginChoice_Converter.toBER(s.adminPassword, errors, newContext, "adminPassword");
		if (errors.validateResult(newContext, "AsnAdminLogin")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sAdminUsername, name: "u8sAdminUsername" }));
			if (_adminPassword)
				t.push(_adminPassword);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnAdminLogin | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAdminLogin");

		let t: ENetUC_Auth.AsnAdminLogin | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnAdminLogin.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnAdminLogin["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sAdminUsername", "Utf8String", errors, newContext);
			const _adminpassword = AsnAdminLoginChoice_Converter.fromBER(s.getValueByName("adminPassword"), errors, newContext, "adminPassword");
			if (_adminpassword)
				t.adminPassword = _adminpassword;
		}

		if (errors.validateResult(newContext, "AsnAdminLogin"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnProfileAttributeList_Converter {
	public static toJSON(s: ENetUC_Auth.AsnProfileAttributeList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnProfileAttributeList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnProfileAttributeList");

		const t = [] as ENetUC_Auth.AsnProfileAttributeList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnProfileAttribute_Converter.toJSON(se, errors, newContext, "AsnProfileAttribute");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnProfileAttributeList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnProfileAttributeList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnProfileAttributeList");

		let t: ENetUC_Auth.AsnProfileAttributeList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnProfileAttributeList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Auth.AsnProfileAttributeList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnProfileAttribute_Converter.fromJSON(se, errors, newContext, "AsnProfileAttribute", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnProfileAttributeList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnProfileAttributeList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnProfileAttributeList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnProfileAttributeList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnProfileAttribute_Converter.toBER(s[id], errors, newContext, "AsnProfileAttribute");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnProfileAttribute"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnProfileAttributeList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnProfileAttributeList");

		let t: ENetUC_Auth.AsnProfileAttributeList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnProfileAttributeList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Auth.AsnProfileAttributeList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnProfileAttribute_Converter.fromBER(se, errors, newContext, "AsnProfileAttribute", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnProfileAttributeList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLogonArgument_Converter {
	public static toJSON(s: ENetUC_Auth.AsnLogonArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnLogonArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonArgument");

		const t = {} as ENetUC_Auth.AsnLogonArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLogonArgument";
		TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sVersion", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iAttachToContact", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sClientComputerName", "string", errors, newContext);
		const _authentication = AsnAuthenticationChoice_Converter.toJSON(s.authentication, errors, newContext, "authentication");
		if (_authentication)
			t.authentication = _authentication;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnLogonArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonArgument");

		let t: ENetUC_Auth.AsnLogonArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnLogonArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnLogonArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sVersion", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iAttachToContact", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sClientComputerName", "string", errors, context, false);
			const _authentication = AsnAuthenticationChoice_Converter.fromJSON(s.authentication, errors, newContext, "authentication", false);
			if (_authentication)
				t.authentication = _authentication;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnLogonArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnLogonArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnLogonArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sVersion", "string", errors, newContext);
		TSConverter.validateParam(s, "iAttachToContact", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sClientComputerName", "string", errors, newContext);
		const _authentication = AsnAuthenticationChoice_Converter.toBER(s.authentication, errors, newContext, "authentication");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnLogonArgument")) {
			t.push(new asn1ts.Integer({ value: s.iVersion, name: "iVersion" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sVersion, name: "u8sVersion" }));
			t.push(new asn1ts.Integer({ value: s.iAttachToContact, name: "iAttachToContact" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sClientComputerName, name: "u8sClientComputerName" }));
			if (_authentication)
				t.push(_authentication);
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonArgument");

		let t: ENetUC_Auth.AsnLogonArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnLogonArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnLogonArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sVersion", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iAttachToContact", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sClientComputerName", "Utf8String", errors, newContext);
			const _authentication = AsnAuthenticationChoice_Converter.fromBER(s.getValueByName("authentication"), errors, newContext, "authentication");
			if (_authentication)
				t.authentication = _authentication;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnLogonArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetUserTokenArgument_Converter {
	public static toJSON(s: ENetUC_Auth.AsnGetUserTokenArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnGetUserTokenArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserTokenArgument");

		const t = {} as ENetUC_Auth.AsnGetUserTokenArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetUserTokenArgument";
		TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iTTL", "number", errors, newContext, true);
		if (s.lstServiceType) {
			const _lstServiceType = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.lstServiceType, errors, newContext, "lstServiceType");
			if (_lstServiceType)
				t.lstServiceType = _lstServiceType;
		}
		if (s.lstProfileAttributes) {
			const _lstProfileAttributes = AsnProfileAttributeList_Converter.toJSON(s.lstProfileAttributes, errors, newContext, "lstProfileAttributes");
			if (_lstProfileAttributes)
				t.lstProfileAttributes = _lstProfileAttributes;
		}
		if (s.adminLogin) {
			const _adminLogin = AsnAdminLogin_Converter.toJSON(s.adminLogin, errors, newContext, "adminLogin");
			if (_adminLogin)
				t.adminLogin = _adminLogin;
		}

		if (errors.validateResult(newContext, "AsnGetUserTokenArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetUserTokenArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserTokenArgument");

		let t: ENetUC_Auth.AsnGetUserTokenArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnGetUserTokenArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnGetUserTokenArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iType", "number", errors, context, true);
			TSConverter.fillJSONParam(s, t, "iTTL", "number", errors, context, true);
			t.lstServiceType = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.lstServiceType, errors, newContext, "lstServiceType", true);
			t.lstProfileAttributes = AsnProfileAttributeList_Converter.fromJSON(s.lstProfileAttributes, errors, newContext, "lstProfileAttributes", true);
			t.adminLogin = AsnAdminLogin_Converter.fromJSON(s.adminLogin, errors, newContext, "adminLogin", true);
		}
		if (errors.validateResult(newContext, "AsnGetUserTokenArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnGetUserTokenArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetUserTokenArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserTokenArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iType", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iTTL", "number", errors, newContext, true);
		const _lstServiceType = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.lstServiceType, errors, newContext, "lstServiceType", 2);
		const _lstProfileAttributes = AsnProfileAttributeList_Converter.toBER(s.lstProfileAttributes, errors, newContext, "lstProfileAttributes", 3);
		const _adminLogin = AsnAdminLogin_Converter.toBER(s.adminLogin, errors, newContext, "adminLogin", 4);
		if (errors.validateResult(newContext, "AsnGetUserTokenArgument")) {
			if (s.iType !== undefined)
				t.push(new asn1ts.Integer({ value: s.iType, name: "iType", idBlock: { optionalID: 0 } }));
			if (s.iTTL !== undefined)
				t.push(new asn1ts.Integer({ value: s.iTTL, name: "iTTL", idBlock: { optionalID: 1 } }));
			if (_lstServiceType)
				t.push(_lstServiceType);
			if (_lstProfileAttributes)
				t.push(_lstProfileAttributes);
			if (_adminLogin)
				t.push(_adminLogin);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetUserTokenArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserTokenArgument");

		let t: ENetUC_Auth.AsnGetUserTokenArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnGetUserTokenArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnGetUserTokenArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iType", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iTTL", "Integer", errors, newContext, true);
			t.lstServiceType = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "lstServiceType"), errors, newContext, "lstServiceType", true);
			t.lstProfileAttributes = AsnProfileAttributeList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "lstProfileAttributes"), errors, newContext, "lstProfileAttributes", true);
			t.adminLogin = AsnAdminLogin_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "adminLogin"), errors, newContext, "adminLogin", true);
		}

		if (errors.validateResult(newContext, "AsnGetUserTokenArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNegotiateServerConnectionExArgument_Converter {
	public static toJSON(s: ENetUC_Auth.AsnNegotiateServerConnectionExArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnNegotiateServerConnectionExArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionExArgument");

		const t = {} as ENetUC_Auth.AsnNegotiateServerConnectionExArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNegotiateServerConnectionExArgument";
		TSConverter.fillJSONParam(s, t, "iClientProtocolVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sClientDeviceId", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sClientDeviceName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bSoftphoneClient", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bProvideAVLine", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionGUID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bAnonymousLogin", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sAnonymousHintData", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionExArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionExArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionExArgument");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionExArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnNegotiateServerConnectionExArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionExArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iClientProtocolVersion", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sClientDeviceId", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sClientDeviceName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bSoftphoneClient", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bProvideAVLine", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionGUID", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "bAnonymousLogin", "boolean", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sAnonymousHintData", "string", errors, context, true);
		}
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionExArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnNegotiateServerConnectionExArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNegotiateServerConnectionExArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionExArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iClientProtocolVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sClientDeviceId", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sClientDeviceName", "string", errors, newContext);
		TSConverter.validateParam(s, "bSoftphoneClient", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bProvideAVLine", "boolean", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionGUID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "bAnonymousLogin", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "u8sAnonymousHintData", "string", errors, newContext, true);
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionExArgument")) {
			t.push(new asn1ts.Integer({ value: s.iClientProtocolVersion, name: "iClientProtocolVersion" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sClientDeviceId, name: "u8sClientDeviceId" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sClientDeviceName, name: "u8sClientDeviceName" }));
			t.push(new asn1ts.Boolean({ value: s.bSoftphoneClient, name: "bSoftphoneClient" }));
			t.push(new asn1ts.Boolean({ value: s.bProvideAVLine, name: "bProvideAVLine" }));
			if (s.u8sConnectionGUID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sConnectionGUID, name: "u8sConnectionGUID", idBlock: { optionalID: 0 } }));
			if (s.bAnonymousLogin !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bAnonymousLogin, name: "bAnonymousLogin", idBlock: { optionalID: 1 } }));
			if (s.u8sAnonymousHintData !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAnonymousHintData, name: "u8sAnonymousHintData", idBlock: { optionalID: 2 } }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionExArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionExArgument");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionExArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnNegotiateServerConnectionExArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionExArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iClientProtocolVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sClientDeviceId", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sClientDeviceName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bSoftphoneClient", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bProvideAVLine", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionGUID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bAnonymousLogin", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sAnonymousHintData", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionExArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNegotiateServerConnectionExResult_Converter {
	public static toJSON(s: ENetUC_Auth.AsnNegotiateServerConnectionExResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnNegotiateServerConnectionExResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionExResult");

		const t = {} as ENetUC_Auth.AsnNegotiateServerConnectionExResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNegotiateServerConnectionExResult";
		TSConverter.fillJSONParam(s, t, "iServerType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iServerProtocolVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iServerUMSecurityOption", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iServerTLSSecurityOption", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "sServerAuthentication", "Uint8Array", errors, newContext);
		TSConverter.fillJSONParam(s, t, "stServerTime", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sProductVersion", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sConnectionGUID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iOEM", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iServerBusyTimeout", "number", errors, newContext, true);
		if (s.u8sPresenceDomains) {
			const _u8sPresenceDomains = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sPresenceDomains, errors, newContext, "u8sPresenceDomains");
			if (_u8sPresenceDomains)
				t.u8sPresenceDomains = _u8sPresenceDomains;
		}
		TSConverter.fillJSONParam(s, t, "bPresenceServicesDisabled", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bUserUserRightsDisabled", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sAnonymousUsername", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionExResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionExResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionExResult");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionExResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnNegotiateServerConnectionExResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionExResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iServerType", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iServerProtocolVersion", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iServerUMSecurityOption", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iServerTLSSecurityOption", "number", errors, context, false);
			if (TSConverter.validateParam(s, "sServerAuthentication", "string", errors, context, false))
				t.sServerAuthentication = TSConverter.decode64(s.sServerAuthentication as unknown as string);
			if (TSConverter.validateParam(s, "stServerTime", "string", errors, context, false))
				t.stServerTime = new Date(s.stServerTime);
			TSConverter.fillJSONParam(s, t, "u8sProductVersion", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sConnectionGUID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iOEM", "number", errors, context, true);
			TSConverter.fillJSONParam(s, t, "iServerBusyTimeout", "number", errors, context, true);
			t.u8sPresenceDomains = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sPresenceDomains, errors, newContext, "u8sPresenceDomains", true);
			TSConverter.fillJSONParam(s, t, "bPresenceServicesDisabled", "boolean", errors, context, true);
			TSConverter.fillJSONParam(s, t, "bUserUserRightsDisabled", "boolean", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sAnonymousUsername", "string", errors, context, true);
		}
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionExResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnNegotiateServerConnectionExResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNegotiateServerConnectionExResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateServerConnectionExResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iServerType", "number", errors, newContext);
		TSConverter.validateParam(s, "iServerProtocolVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "iServerUMSecurityOption", "number", errors, newContext);
		TSConverter.validateParam(s, "iServerTLSSecurityOption", "number", errors, newContext);
		TSConverter.validateParam(s, "sServerAuthentication", "Uint8Array", errors, newContext);
		TSConverter.validateParam(s, "stServerTime", "Date", errors, newContext);
		TSConverter.validateParam(s, "u8sProductVersion", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sConnectionGUID", "string", errors, newContext);
		TSConverter.validateParam(s, "iOEM", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iServerBusyTimeout", "number", errors, newContext, true);
		const _u8sPresenceDomains = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sPresenceDomains, errors, newContext, "u8sPresenceDomains", 2);
		TSConverter.validateParam(s, "bPresenceServicesDisabled", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "bUserUserRightsDisabled", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "u8sAnonymousUsername", "string", errors, newContext, true);
		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionExResult")) {
			t.push(new asn1ts.Integer({ value: s.iServerType, name: "iServerType" }));
			t.push(new asn1ts.Integer({ value: s.iServerProtocolVersion, name: "iServerProtocolVersion" }));
			t.push(new asn1ts.Integer({ value: s.iServerUMSecurityOption, name: "iServerUMSecurityOption" }));
			t.push(new asn1ts.Integer({ value: s.iServerTLSSecurityOption, name: "iServerTLSSecurityOption" }));
			t.push(new asn1ts.OctetString({ valueHex: s.sServerAuthentication, name: "sServerAuthentication" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stServerTime), name: "stServerTime" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sProductVersion, name: "u8sProductVersion" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionGUID, name: "u8sConnectionGUID" }));
			if (s.iOEM !== undefined)
				t.push(new asn1ts.Integer({ value: s.iOEM, name: "iOEM", idBlock: { optionalID: 0 } }));
			if (s.iServerBusyTimeout !== undefined)
				t.push(new asn1ts.Integer({ value: s.iServerBusyTimeout, name: "iServerBusyTimeout", idBlock: { optionalID: 1 } }));
			if (_u8sPresenceDomains)
				t.push(_u8sPresenceDomains);
			if (s.bPresenceServicesDisabled !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bPresenceServicesDisabled, name: "bPresenceServicesDisabled", idBlock: { optionalID: 3 } }));
			if (s.bUserUserRightsDisabled !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bUserUserRightsDisabled, name: "bUserUserRightsDisabled", idBlock: { optionalID: 4 } }));
			if (s.u8sAnonymousUsername !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAnonymousUsername, name: "u8sAnonymousUsername", idBlock: { optionalID: 5 } }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnNegotiateServerConnectionExResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateServerConnectionExResult");

		let t: ENetUC_Auth.AsnNegotiateServerConnectionExResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnNegotiateServerConnectionExResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnNegotiateServerConnectionExResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iServerType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iServerProtocolVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iServerUMSecurityOption", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iServerTLSSecurityOption", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "sServerAuthentication", "OctetString", errors, newContext);
			TSConverter.fillASN1Param(s, t, "stServerTime", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sProductVersion", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sConnectionGUID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iOEM", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iServerBusyTimeout", "Integer", errors, newContext, true);
			t.u8sPresenceDomains = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sPresenceDomains"), errors, newContext, "u8sPresenceDomains", true);
			TSConverter.fillASN1Param(s, t, "bPresenceServicesDisabled", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bUserUserRightsDisabled", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sAnonymousUsername", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnNegotiateServerConnectionExResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLogonResultChoice_Converter {
	public static toJSON(s: ENetUC_Auth.AsnLogonResultChoice, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnLogonResultChoice & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonResultChoice");

		const t = {} as ENetUC_Auth.AsnLogonResultChoice & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLogonResultChoice";
		if (s.success != null) {
			const _success = AsnLogonResultSuccess_Converter.toJSON(s.success, errors, newContext, "success");
			if (_success)
				t.success = _success;
		} else if (s.renegotiation != null) {
			const _renegotiation = AsnAuthenticationSimpleCrypt_Converter.toJSON(s.renegotiation, errors, newContext, "renegotiation");
			if (_renegotiation)
				t.renegotiation = _renegotiation;
		} else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnLogonResultChoice"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonResultChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonResultChoice");

		let t: ENetUC_Auth.AsnLogonResultChoice | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnLogonResultChoice>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnLogonResultChoice["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.success !== undefined)
				t.success = AsnLogonResultSuccess_Converter.fromJSON(s.success, errors, newContext, "success", false);
			else if (s.renegotiation !== undefined)
				t.renegotiation = AsnAuthenticationSimpleCrypt_Converter.fromJSON(s.renegotiation, errors, newContext, "renegotiation", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}
		if (errors.validateResult(newContext, "AsnLogonResultChoice"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnLogonResultChoice | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "AsnLogonResultChoice";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonResultChoice");

		// [Print_BER_EncoderChoiceDefCode]
		if (s.success)
			t = AsnLogonResultSuccess_Converter.toBER(s.success, errors, newContext, "success", 0);
		else if (s.renegotiation)
			t = AsnAuthenticationSimpleCrypt_Converter.toBER(s.renegotiation, errors, newContext, "renegotiation", 1);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
		if (errors.validateResult(newContext, "AsnLogonResultChoice"))
			return t;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonResultChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonResultChoice");

		let t: ENetUC_Auth.AsnLogonResultChoice | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnLogonResultChoice.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnLogonResultChoice["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "success" && asn1ts.Sequence.typeGuard(s))
				t.success = AsnLogonResultSuccess_Converter.fromBER(s, undefined, newContext, "success", false);
			else if (s.choiceName === "renegotiation" && asn1ts.Sequence.typeGuard(s))
				t.renegotiation = AsnAuthenticationSimpleCrypt_Converter.fromBER(s, undefined, newContext, "renegotiation", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnLogonResultChoice"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLogonResultRenegotiation_Converter {
	public static toJSON(s: ENetUC_Auth.AsnLogonResultRenegotiation, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnLogonResultRenegotiation & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonResultRenegotiation");

		const t = {} as ENetUC_Auth.AsnLogonResultRenegotiation & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLogonResultRenegotiation";
		TSConverter.fillJSONParam(s, t, "sAuthCredetials", "Uint8Array", errors, newContext);

		if (errors.validateResult(newContext, "AsnLogonResultRenegotiation"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonResultRenegotiation | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonResultRenegotiation");

		let t: ENetUC_Auth.AsnLogonResultRenegotiation | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnLogonResultRenegotiation>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnLogonResultRenegotiation["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			if (TSConverter.validateParam(s, "sAuthCredetials", "string", errors, context, false))
				t.sAuthCredetials = TSConverter.decode64(s.sAuthCredetials as unknown as string);
		}
		if (errors.validateResult(newContext, "AsnLogonResultRenegotiation"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnLogonResultRenegotiation | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnLogonResultRenegotiation";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLogonResultRenegotiation");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sAuthCredetials", "Uint8Array", errors, newContext);
		if (errors.validateResult(newContext, "AsnLogonResultRenegotiation")) {
			t.push(new asn1ts.OctetString({ valueHex: s.sAuthCredetials, name: "sAuthCredetials" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnLogonResultRenegotiation | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLogonResultRenegotiation");

		let t: ENetUC_Auth.AsnLogonResultRenegotiation | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnLogonResultRenegotiation.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnLogonResultRenegotiation["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sAuthCredetials", "OctetString", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnLogonResultRenegotiation"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetUserTokenExResult_Converter {
	public static toJSON(s: ENetUC_Auth.AsnGetUserTokenExResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Auth.AsnGetUserTokenExResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserTokenExResult");

		const t = {} as ENetUC_Auth.AsnGetUserTokenExResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetUserTokenExResult";
		TSConverter.fillJSONParam(s, t, "sToken", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetUserTokenExResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetUserTokenExResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserTokenExResult");

		let t: ENetUC_Auth.AsnGetUserTokenExResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Auth.AsnGetUserTokenExResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Auth.AsnGetUserTokenExResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "sToken", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetUserTokenExResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Auth.AsnGetUserTokenExResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetUserTokenExResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserTokenExResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sToken", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetUserTokenExResult")) {
			t.push(new asn1ts.Utf8String({ value: s.sToken, name: "sToken" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Auth.AsnGetUserTokenExResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserTokenExResult");

		let t: ENetUC_Auth.AsnGetUserTokenExResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Auth.AsnGetUserTokenExResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Auth.AsnGetUserTokenExResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sToken", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetUserTokenExResult"))
			return t;

		return undefined;
	}
}
