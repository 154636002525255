// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_ClientCapabilities_Converter.ts
 * "UC-Server-Access-Protocol-ClientCapabilities" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.23, 20.10.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_ClientCapabilities from "./ENetUC_ClientCapabilities";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
// [PrintTSRootTypes]
export const moduleName = "ENetUC_ClientCapabilities_Converter";

// [PrintTSEncoderDecoderCode]
export class AsnClientCapabilitiesV2_Converter {
	public static toJSON(s: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2 & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientCapabilitiesV2");

		const t = {} as ENetUC_ClientCapabilities.AsnClientCapabilitiesV2 & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientCapabilitiesV2";
		const _eClientCapabilities = ENetUC_Common_Converter.SEQInteger_Converter.toJSON(s.eClientCapabilities, errors, newContext, "eClientCapabilities");
		if (_eClientCapabilities)
			t.eClientCapabilities = _eClientCapabilities;
		const _customCapabilities = ENetUC_Common_Converter.AsnStringPairList_Converter.toJSON(s.customCapabilities, errors, newContext, "customCapabilities");
		if (_customCapabilities)
			t.customCapabilities = _customCapabilities;

		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientCapabilitiesV2");

		let t: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2 | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientCapabilities.AsnClientCapabilitiesV2>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _eclientcapabilities = ENetUC_Common_Converter.SEQInteger_Converter.fromJSON(s.eClientCapabilities, errors, newContext, "eClientCapabilities", false);
			if (_eclientcapabilities)
				t.eClientCapabilities = _eclientcapabilities;
			const _customcapabilities = ENetUC_Common_Converter.AsnStringPairList_Converter.fromJSON(s.customCapabilities, errors, newContext, "customCapabilities", false);
			if (_customcapabilities)
				t.customCapabilities = _customcapabilities;
		}
		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2 | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientCapabilitiesV2";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientCapabilitiesV2");

		// [Print_BER_EncoderSeqDefCode]
		const _eClientCapabilities = ENetUC_Common_Converter.SEQInteger_Converter.toBER(s.eClientCapabilities, errors, newContext, "eClientCapabilities");
		const _customCapabilities = ENetUC_Common_Converter.AsnStringPairList_Converter.toBER(s.customCapabilities, errors, newContext, "customCapabilities");
		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2")) {
			if (_eClientCapabilities)
				t.push(_eClientCapabilities);
			if (_customCapabilities)
				t.push(_customCapabilities);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientCapabilitiesV2");

		let t: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2 | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientCapabilities.AsnClientCapabilitiesV2.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _eclientcapabilities = ENetUC_Common_Converter.SEQInteger_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "eClientCapabilities"), errors, newContext, "eClientCapabilities");
			if (_eclientcapabilities)
				t.eClientCapabilities = _eclientcapabilities;
			const _customcapabilities = ENetUC_Common_Converter.AsnStringPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "customCapabilities"), errors, newContext, "customCapabilities");
			if (_customcapabilities)
				t.customCapabilities = _customcapabilities;
		}

		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetClientCapabilitiesV2Result_Converter {
	public static toJSON(s: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientCapabilitiesV2Result");

		const t = {} as ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetClientCapabilitiesV2Result";

		if (errors.validateResult(newContext, "AsnSetClientCapabilitiesV2Result"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientCapabilitiesV2Result");

		let t: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnSetClientCapabilitiesV2Result"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetClientCapabilitiesV2Result";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientCapabilitiesV2Result");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientCapabilitiesV2Result");

		let t: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnSetClientCapabilitiesV2Result"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetClientCapabilitiesV2Argument_Converter {
	public static toJSON(s: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetClientCapabilitiesV2Argument");

		const t = {} as ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetClientCapabilitiesV2Argument";
		const _u8slistContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8slistContactIDs, errors, newContext, "u8slistContactIDs");
		if (_u8slistContactIDs)
			t.u8slistContactIDs = _u8slistContactIDs;

		if (errors.validateResult(newContext, "AsnGetClientCapabilitiesV2Argument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetClientCapabilitiesV2Argument");

		let t: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _u8slistcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8slistContactIDs, errors, newContext, "u8slistContactIDs", false);
			if (_u8slistcontactids)
				t.u8slistContactIDs = _u8slistcontactids;
		}
		if (errors.validateResult(newContext, "AsnGetClientCapabilitiesV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetClientCapabilitiesV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetClientCapabilitiesV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		const _u8slistContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8slistContactIDs, errors, newContext, "u8slistContactIDs");
		if (errors.validateResult(newContext, "AsnGetClientCapabilitiesV2Argument")) {
			if (_u8slistContactIDs)
				t.push(_u8slistContactIDs);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetClientCapabilitiesV2Argument");

		let t: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _u8slistcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8slistContactIDs"), errors, newContext, "u8slistContactIDs");
			if (_u8slistcontactids)
				t.u8slistContactIDs = _u8slistcontactids;
		}

		if (errors.validateResult(newContext, "AsnGetClientCapabilitiesV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientCapabilitiesV2ByID_Converter {
	public static toJSON(s: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientCapabilitiesV2ByID");

		const t = {} as ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientCapabilitiesV2ByID";
		TSConverter.fillJSONParam(s, t, "u8sID", "string", errors, newContext);
		const _capabilities = AsnClientCapabilitiesV2_Converter.toJSON(s.capabilities, errors, newContext, "capabilities");
		if (_capabilities)
			t.capabilities = _capabilities;

		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ByID"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientCapabilitiesV2ByID");

		let t: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sID", "string", errors, context, false);
			const _capabilities = AsnClientCapabilitiesV2_Converter.fromJSON(s.capabilities, errors, newContext, "capabilities", false);
			if (_capabilities)
				t.capabilities = _capabilities;
		}
		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ByID"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientCapabilitiesV2ByID";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientCapabilitiesV2ByID");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sID", "string", errors, newContext);
		const _capabilities = AsnClientCapabilitiesV2_Converter.toBER(s.capabilities, errors, newContext, "capabilities");
		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ByID")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sID, name: "u8sID" }));
			if (_capabilities)
				t.push(_capabilities);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientCapabilitiesV2ByID");

		let t: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sID", "Utf8String", errors, newContext);
			const _capabilities = AsnClientCapabilitiesV2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "capabilities"), errors, newContext, "capabilities");
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ByID"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientCapabilitiesV2ByIDList_Converter {
	public static toJSON(s: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientCapabilitiesV2ByIDList");

		const t = [] as ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnClientCapabilitiesV2ByID_Converter.toJSON(se, errors, newContext, "AsnClientCapabilitiesV2ByID");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ByIDList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientCapabilitiesV2ByIDList");

		let t: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnClientCapabilitiesV2ByID_Converter.fromJSON(se, errors, newContext, "AsnClientCapabilitiesV2ByID", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ByIDList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientCapabilitiesV2ByIDList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientCapabilitiesV2ByIDList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnClientCapabilitiesV2ByID_Converter.toBER(s[id], errors, newContext, "AsnClientCapabilitiesV2ByID");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ByID"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientCapabilitiesV2ByIDList");

		let t: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnClientCapabilitiesV2ByID_Converter.fromBER(se, errors, newContext, "AsnClientCapabilitiesV2ByID", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ByIDList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetClientCapabilitiesV2Argument_Converter {
	public static toJSON(s: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientCapabilitiesV2Argument");

		const t = {} as ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetClientCapabilitiesV2Argument";
		const _capabilities = AsnClientCapabilitiesV2_Converter.toJSON(s.capabilities, errors, newContext, "capabilities");
		if (_capabilities)
			t.capabilities = _capabilities;

		if (errors.validateResult(newContext, "AsnSetClientCapabilitiesV2Argument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientCapabilitiesV2Argument");

		let t: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _capabilities = AsnClientCapabilitiesV2_Converter.fromJSON(s.capabilities, errors, newContext, "capabilities", false);
			if (_capabilities)
				t.capabilities = _capabilities;
		}
		if (errors.validateResult(newContext, "AsnSetClientCapabilitiesV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetClientCapabilitiesV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientCapabilitiesV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		const _capabilities = AsnClientCapabilitiesV2_Converter.toBER(s.capabilities, errors, newContext, "capabilities");
		if (errors.validateResult(newContext, "AsnSetClientCapabilitiesV2Argument")) {
			if (_capabilities)
				t.push(_capabilities);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientCapabilitiesV2Argument");

		let t: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _capabilities = AsnClientCapabilitiesV2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "capabilities"), errors, newContext, "capabilities");
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnSetClientCapabilitiesV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetClientCapabilitiesV2Result_Converter {
	public static toJSON(s: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetClientCapabilitiesV2Result");

		const t = {} as ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetClientCapabilitiesV2Result";
		const _capabilities = AsnClientCapabilitiesV2ByIDList_Converter.toJSON(s.capabilities, errors, newContext, "capabilities");
		if (_capabilities)
			t.capabilities = _capabilities;

		if (errors.validateResult(newContext, "AsnGetClientCapabilitiesV2Result"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetClientCapabilitiesV2Result");

		let t: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _capabilities = AsnClientCapabilitiesV2ByIDList_Converter.fromJSON(s.capabilities, errors, newContext, "capabilities", false);
			if (_capabilities)
				t.capabilities = _capabilities;
		}
		if (errors.validateResult(newContext, "AsnGetClientCapabilitiesV2Result"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetClientCapabilitiesV2Result";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetClientCapabilitiesV2Result");

		// [Print_BER_EncoderSeqDefCode]
		const _capabilities = AsnClientCapabilitiesV2ByIDList_Converter.toBER(s.capabilities, errors, newContext, "capabilities");
		if (errors.validateResult(newContext, "AsnGetClientCapabilitiesV2Result")) {
			if (_capabilities)
				t.push(_capabilities);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetClientCapabilitiesV2Result");

		let t: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _capabilities = AsnClientCapabilitiesV2ByIDList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "capabilities"), errors, newContext, "capabilities");
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnGetClientCapabilitiesV2Result"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientCapabilitiesV2ChangedArgument_Converter {
	public static toJSON(s: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientCapabilitiesV2ChangedArgument");

		const t = {} as ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientCapabilitiesV2ChangedArgument";
		const _capabilities = AsnClientCapabilitiesV2ByIDList_Converter.toJSON(s.capabilities, errors, newContext, "capabilities");
		if (_capabilities)
			t.capabilities = _capabilities;

		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ChangedArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientCapabilitiesV2ChangedArgument");

		let t: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _capabilities = AsnClientCapabilitiesV2ByIDList_Converter.fromJSON(s.capabilities, errors, newContext, "capabilities", false);
			if (_capabilities)
				t.capabilities = _capabilities;
		}
		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientCapabilitiesV2ChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientCapabilitiesV2ChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _capabilities = AsnClientCapabilitiesV2ByIDList_Converter.toBER(s.capabilities, errors, newContext, "capabilities");
		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ChangedArgument")) {
			if (_capabilities)
				t.push(_capabilities);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientCapabilitiesV2ChangedArgument");

		let t: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _capabilities = AsnClientCapabilitiesV2ByIDList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "capabilities"), errors, newContext, "capabilities");
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnClientCapabilitiesV2ChangedArgument"))
			return t;

		return undefined;
	}
}
