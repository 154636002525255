import "./ContactDetail.scss";

import { t } from "i18next";
import React from "react";

import { DetailTypeE, IFontColor, StyleTypeE } from "../../../models/ModelConfig";
import { ICombiDetail } from "../../../models/ModelContact";
import { DetailTypeToHTML } from "./DetailTypeToHTML";

interface IDetailProps {
	detail: ICombiDetail;
	type: "cd" | "cw" | "ch";
}
export const ContactDetail = (props: IDetailProps): JSX.Element => {
	const detail: ICombiDetail = props.detail;
	const prefix = props.type.toString();

	const title: string =
		detail.displayLabel && (detail.displayLabel as string).startsWith("IDS_")
			? t(detail.displayLabel)
			: detail.displayLabel;

	const highlight: boolean =
		detail.style !== undefined && detail.style !== null && detail.style.indexOf(StyleTypeE.HGH) > -1 ? true : false;

	const bold: boolean =
		detail.style !== undefined && detail.style !== null && detail.style.indexOf(StyleTypeE.BLD) > -1 ? true : false;

	const color: IFontColor = {
		color:
			detail.style && detail.style.indexOf(StyleTypeE.RED) > -1
				? StyleTypeE.RED
				: detail.style !== undefined && detail.style !== null && detail.style.indexOf(StyleTypeE.GRN) > -1
				? StyleTypeE.GRN
				: null
	};

	const content: JSX.Element = DetailTypeToHTML(detail);
	const labelNotEmpty: boolean =
		detail.displayLabel !== "" && detail.displayLabel !== " " && detail.displayLabel !== null;
	const showLabel: boolean = props.type === "cw" && detail.type !== DetailTypeE.CFDTL;

	return (
		<>
			{highlight ? <div className={prefix + "D_hlLine"} /> : <></>}
			<div className={prefix + "D_infoLine"}>
				{/* // TODO LABEL AN FÜR CL  */}
				<div
					className={
						labelNotEmpty
							? prefix + "D_infoLabelDp" + (!showLabel ? "" : " cwD_dpnone")
							: prefix + "D_infoLabel" + (!showLabel ? "" : " cwD_dpnone")
					}
				>
					{title}
				</div>

				<div
					className={
						(prefix === "ch" ? "chD_" : "cdcwD_") +
						"infoContent" +
						(bold ? " cV_bold" : "") +
						(color.color === StyleTypeE.RED ? " cV_cR" : "") +
						(color.color === StyleTypeE.GRN ? " cV_cG" : "")
					}
				>
					{content}
				</div>
			</div>
		</>
	);
};
