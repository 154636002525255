import { t } from "i18next";
import type { VariantType } from "notistack";

import {
	EError,
	ILogEntryCallback,
	ILogMessage,
	IProcessLogMessage
} from "../../web-shared-components/helpers/logger/ILogger";
import { getState } from "../zustand/store";
import { IUISnackBar } from "../zustand/systemMessagesSlice";
import { generateGUID } from "./commonHelper";

interface IReadableError {
	message: string;
	banner?: true;
	snackbar?: true;
}
/**
 *
 */
export class LoggerCallback implements ILogEntryCallback {
	/**
	 * On log message callback
	 *
	 * @param logmessage - the log message
	 * @returns - the event to handle or undefined
	 */
	public onLogMessage(logmessage: ILogMessage): IProcessLogMessage | undefined {
		const readable = LoggerCallback.getHumanReadableError(logmessage.error, logmessage.message);
		// Debug level never shows a snackbar
		if (readable.snackbar && logmessage.level !== "debug") {
			let variant: VariantType = "default";
			switch (logmessage.level) {
				case "error":
					variant = "error";
					break;
				case "warn":
					variant = "warning";
					break;
				case "info":
					variant = "info";
					break;
				default:
					break;
			}
			const snackBar: IUISnackBar = {
				type: "standard",
				key: generateGUID(),
				message: readable.message,
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "left"
				},
				variant,
				preventDuplicate: true,
				persist: false,
				autoHideDuration: 3000
			};
			getState().smEnqueueSnackbar(snackBar);
		}
		const logForDB = logmessage;
		logForDB.message = readable.message;

		return undefined;
	}

	/**
	 * Get a readable error to display
	 *
	 * @param error - the error enum to convert
	 * @param message - additional custom message we may want to display
	 * @returns - the readable error or undefined
	 */
	public static getHumanReadableError(error?: EError | unknown, message?: string): IReadableError {
		if (error === undefined) {
			return { message: message ?? "Unknown error", snackbar: true };
		} else {
			const err: EError = error as EError;
			// TODO: add i18n labels
			switch (err) {
				case EError.AuthenticationErrorUCServer:
					return { message: "Error authenticating: please check the username and password", snackbar: true };
				case EError.AuthenticationErrorMeetings:
					return { message: "Error authenticating in meetings server", snackbar: true };
				case EError.InvalidUCSID:
					return { message: "Invalid ucsid", snackbar: true };
				case EError.InvalidUCServerURI:
					return { message: "Invalid UCServer URI", snackbar: true };
				case EError.FetchFailed:
					return { message: "Error fetching" + (message ? " - " + message : ""), snackbar: true };
				case EError.InvalidServerInterface:
					return { message: t("IDS_ERROR_WRONG_SERVER_VERSION"), snackbar: true };
				case EError.InvalidUCControllerURL:
					return { message: "Invalid UCController URI", snackbar: true };
				case EError.InvalidProtocolVersion:
					return { message: "Invalid protocol version", snackbar: true };
				case EError.SessionNoID:
					return { message: t("IDS_ERROR_NO_LICENSE"), snackbar: true };
				case EError.SessionCreateGeneric:
					return { message: "Error creating session", snackbar: true };
				case EError.SessionDestroyGeneric:
					return { message: "Error destroying session", snackbar: true };
				case EError.FileTransferError:
					return { message: "File transfer error" + (message ? " - " + message : ""), snackbar: true };
				case EError.PersistenceStorageReadError:
					return { message: "Error reading persistence storage" + (message ? " - " + message : ""), snackbar: true };
				case EError.MediaDeviceError:
					return { message: "Error acquiring media stream", snackbar: true };
				case EError.AsnErrorRequestReject:
					return { message: "Asn request rejected" + (message ? " - " + message : "") };
				case EError.AsnErrorRequestResult:
					return { message: "Asn request result error" + (message ? " - " + message : "") };
				default:
					return { message: message ?? "Unknown error", snackbar: true };
			}
		}
	}
}
