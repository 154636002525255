import styled from "@mui/material/styles/styled";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";

import { useStore } from "../../../zustand/store";

interface ISnackBarRendererProps {
	children: React.ReactElement;
}

const SnackBarRenderer = ({ children }: ISnackBarRendererProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const smRemoveFromQueueSnackbar = useStore((state) => state.smRemoveFromQueueSnackbar);
	const snackBars = useStore((state) => state.snackBars);

	/**
	 * Reacts on snackBars Redux queue.
	 * Iterate the Redux queue and add to the SnackBar library queue, then remove from Redux queue.
	 * The in case the document is not visible this is not running.
	 * When is visible again, it shows the queued snackbars.
	 */
	useEffect(() => {
		for (const snackBar of snackBars) {
			switch (snackBar.type) {
				case "standard":
					enqueueSnackbar(snackBar.message, {
						anchorOrigin: snackBar.anchorOrigin,
						variant: snackBar.variant,
						preventDuplicate: snackBar.preventDuplicate,
						persist: snackBar.persist,
						autoHideDuration: snackBar.autoHideDuration
					});
					smRemoveFromQueueSnackbar(snackBar.key);
					break;
				default:
					break;
			}
		}
	}, [snackBars, smRemoveFromQueueSnackbar, enqueueSnackbar]);

	return <>{children}</>;
};

export const WarningsSnackbarProvider = ({ children }: ISnackBarRendererProps) => {
	const notistackRef = React.createRef<SnackbarProvider>();

	return (
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		<StyledWarningsSnackbarProvider ref={notistackRef} maxSnack={5}>
			<SnackBarRenderer>{children}</SnackBarRenderer>
		</StyledWarningsSnackbarProvider>
	);
};

const StyledWarningsSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
	"&.SnackbarItem-contentRoot": {
		position: "relative",
		transform: "translate(-50%, 0)",
		backgroundColor: "white",
		color: theme.palette.error.main,
		font: "caption",
		fontSize: "14px",
		fontWeight: 600,
		letterSpacing: "1.23px",
		borderRadius: "5px"
	},
	"&.SnackbarItem-contentRoot .SnackbarItem-message": {
		width: "90%",
		hyphens: "auto"
	},
	"&.SnackbarItem-contentRoot .SnackbarItem-action": {
		position: "absolute",
		right: "10px"
	}
}));
