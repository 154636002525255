interface IAVCallAsnConfig {
	isConnected: boolean;
	mySipUri?: string;
	targetSipUri: string;
	myEndpointID?: string;
	targetEndpointID?: string;
	associatedTextChatConversationId: string;
	isPublicCall?: boolean;
	u8sCallID?: string;
}

// AV Call Types
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum Eav_CallType {
	// Audio und Video
	EAV_CALLTYPE_AUDIOVIDEO = 0,
	// Audio
	EAV_CALLTYPE_AUDIO = 1,
	// Desktop und Windows
	EAV_CALLTYPE_DESKTOPSHARING = 2,
	// Desktop und Windows anfordern
	EAV_CALLTYPE_REQUESTDESKTOPSHARING = 3,
	// SIP AV Call
	EAV_CALLTYPE_SIP = 0xffff
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum EAvMessageType {
	ICE_CANDIDATE = 1,
	ICE_CANDIDATE_SDP = 2,
	CAM_CONTROL = 3,
	MY_DEVICE_STATE = 4,
	ICE_CANDIDATE_END = 5,
	RENEGOTIATE_OFFER = 6,
	RENEGOTIATE_ANSWER = 7,
	RENEGOTIATE_DECLINE = 8,
	REINVITE_OFFER = 9,
	REINVITE_ANSWER = 10
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum EAvCallState {
	CALLSTATE_IDLE = 0,
	CALLSTATE_CALL_PROC = 1,
	CALLSTATE_ALERT = 2,
	CALLSTATE_CONNECT = 10
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type TAVCallType =
	| "offer"
	| "answer"
	| "candidate"
	| "end-of-candidates"
	| "bye"
	| "callstatechange"
	| "devicestate"
	| "dummy";

export interface IAVCallMessageDeviceState {
	asnType: EAvMessageType;
	body: string;
}

export interface IRTCIceCandidateSimplified {
	sdpMLineIndex: number | null;
	sdpMid: string | null;
	candidate: string;
}

export interface IAVCallMessage {
	type: TAVCallType;
	content?:
		| RTCSessionDescriptionInit
		| IRTCIceCandidateSimplified
		| EAvDropReason
		| IAVCallMessageDeviceState
		| EAvCallState;
}

export interface IAVCallParams {
	isInitiator: boolean;
	asnCallType: Eav_CallType;
	asnConfig: IAVCallAsnConfig;
	mediaConstraints: MediaStreamConstraints;
	peerConnectionConfig: RTCConfiguration;
	messages?: IAVCallMessage[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ECallState {
	IDLE = 0,
	INCOMING = 1,
	MEDIA_REQUEST = 2,
	MEDIA_REQUEST_DONE = 3,
	RINGING = 4,
	CONNECTIONPENDING = 6,
	CONNECTED = 10,
	HANGUP = 20,
	REMOTEHANGUP = 21,
	MEDIA_FAILURE = 31,
	CONNECTION_LOST = 32,
	TIMEOUT = 40,
	TIMEOUT_NO_LOCAL_CANDIDATES = 41,
	TIMEOUT_NO_REMOTE_CANDIDATES = 42,
	TIMEOUT_NO_TURN_CONFIG = 43,
	TIMEOUT_NO_REMOTE_RELAY_CANDIDATES = 44,
	TIMEOUT_NO_LOCAL_RELAY_CANDIDATES = 45
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum EAvDropReason {
	NORMAL_CALL_CLEARING = 0,
	BUSY = 1,
	CANCEL = 2,
	OTHER_PARTY_GOT_THE_CALL = 3,
	ENDPOINT_REMOVED = 4,
	UNKNOWN = 5,
	GENERAL_ERROR = 6,
	CLIENT_REMOVED_ALL_CALLS = 7,
	ONLY_ONE_CALL_ALLOWED = 8,
	CLIENT_CALL_NOT_POSSIBLE = 9,
	UNSUPPORTED_CALLTYPE = 10,
	INIT_FAILED = 11,
	INIT_DEV_FAILED = 12,
	INIT_MIC_FAILED = 13,
	INIT_SPEAK_FAILED = 14,
	INIT_CAM_FAILED = 15,
	REJECT = 16,
	WEBRTC_CALL_MESS_ERROR = 17,
	WEBRTC_ANSWER_ERROR = 18,
	CONNECT_TIMEOUT = 19,
	MEDIA_CONN_TIMEOUT = 20,
	AVSERVICE_DISABLED = 21,
	MCU_ERROR = 22
}
