import React from "react";
import commonStyles from "../common/SharedButtonsCommon.module.scss";
import styles from "./SharedCallHangUpButton.module.scss";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "web-fortawesome/npm/pro-light";
import { IButtonCommonProps } from "../../interfaces/IButtonCommonProps";
import { setFAIconShrinkSize, theSharedTranslationManager } from "../../../../helpers/helpers";

interface IProps extends IButtonCommonProps {
	onClick?: () => void;
}

/**
 * Hang Up Button
 *
 * @param props - props
 * @returns - the component
 */
export default function SharedCallHangUpButton(props: IProps): JSX.Element {
	const width: string = props.width + "px";
	const height: string = props.height + "px";

	return (
		<div className={styles.container}>
			<Tooltip title={theSharedTranslationManager.getTranslation("IDS_AV_HANGUP")} placement="bottom">
				<IconButton
					data-cy="webrtc-hang-up-call"
					className={`${styles.icon} ${props.isDisabled ? styles.disabled : styles.default}`}
					{...(!props.isDraggable ? { onClick: () => { props.onClick && props.onClick(); } } : {})}
					{...(props.isDraggable ? { onTouchEnd: () => { props.onClick && props.onClick(); } } : {})}
					{...(props.isDraggable ? { onClick: () => { props.onClick && props.onClick(); } } : {})}
					disabled={props.isDisabled}
					sx={{
						padding: 0,
						margin: 0,
						width: width,
						height: height
					}}
				>
					<FontAwesomeIcon  transform={setFAIconShrinkSize(5)} icon={faPhone} className={commonStyles.rotatedPhone} />
				</IconButton>
			</Tooltip>
		</div>
	);
}
