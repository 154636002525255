import { ClickAwayListener, Fade, Paper, Popper as MuiPopper } from "@mui/material";
import { ComponentProps } from "react";

interface IPopperProps extends ComponentProps<typeof MuiPopper> {
	children: React.ReactNode;
	onClickAway: ComponentProps<typeof ClickAwayListener>["onClickAway"];
}

export const Popper = ({ open, children, onClickAway, anchorEl, sx, ...props }: IPopperProps) => {
	return (
		<MuiPopper open={open} anchorEl={anchorEl} placement={"top-start"} transition {...props}>
			{({ TransitionProps }) => (
				<ClickAwayListener onClickAway={onClickAway}>
					<Fade {...TransitionProps}>
						<Paper elevation={6} sx={sx}>
							{children}
						</Paper>
					</Fade>
				</ClickAwayListener>
			)}
		</MuiPopper>
	);
};
