import { ENetUC_Common } from "../../asn1/EUCSrv/stubs/types";

/**
 * This file contains generic type guards for all differnt sorts of elements we hold
 */

/**
 * Validates wether error is an Error object
 *
 * @param error - the object to check wether it is of the requested type
 * @returns true if the object is of the requested type
 */
export function isError(error: unknown): error is Error {
	const asError = (error as Error);
	return (asError && asError.message && asError.name) ? true : false;
}

/**
 * Validates wether error is an AsnRequestError object
 *
 * @param error - the object to check wether it is of the requested type
 * @returns true if the object is of the requested type
 */
export function isAsnRequestError(error: unknown): error is ENetUC_Common.AsnRequestError {
	const asAsnRequestError = (error as ENetUC_Common.AsnRequestError);
	return (asAsnRequestError && asAsnRequestError.iErrorDetail && typeof asAsnRequestError.u8sErrorString === "string") ? true : false;
}

/**
 * Interface for an object that has a toJSON method...
 */
interface IObjWithtoJSON {
	toJSON(): string;
}

/**
 * Validates wether obj has a toJSON method
 *
 * @param obj - the object to check wether it is of the requested type
 * @returns true if the object is of the requested type
 */
export function hasToJSON(obj: unknown): obj is IObjWithtoJSON {
	const asiObjToJSON = (obj as IObjWithtoJSON);
	return (obj && typeof asiObjToJSON.toJSON === "function") ? true : false;
}
