import { StateCreator } from "zustand";

import { AsnLineForwards } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_SIPCTI";
import { AsnCtiLineInfo, AsnRemoteOfficeState } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_CTI";
import { StoreT } from "./store";

export type CtiSliceT = IState & IActions;

export interface ILineInfoContainer {
	lineInfo: AsnCtiLineInfo;
	isSoftPhone?: boolean;
	bDoNotDisturb?: boolean;
	remoteOfficeState?: AsnRemoteOfficeState;
	seqLineForwards?: AsnLineForwards;
}

export interface ILocalPhoneLineSettingsContainer {
	lineForwardNumber?: string;
}

interface IState {
	myPhoneLines: Map<string, ILineInfoContainer>;
	/**
	 * This map is used to store the forwarding lines locally,
	 * because the forwarding lines are not stored in the UCServer,
	 * if the forwarding is not active.
	 * The key is the u8sLinePhoneNumber of the line.
	 */
	localPhoneLines: Map<string, ILocalPhoneLineSettingsContainer>;
}

interface IActions {
	setPhoneLines: (lines: ILineInfoContainer[]) => void;
	removePhoneLines: (u8sLinePhoneNumbers: string[]) => void;
	setDoNotDisturb: (u8sLinePhoneNumber: string, iDoNotDisturb: number) => void;
	setRemoteOffice: (u8sLinePhoneNumber: string, remoteOfficeState: AsnRemoteOfficeState) => void;
	setLineForwards: (u8sLinePhoneNumber: string, seqLineForwards: AsnLineForwards) => void;
	setLocalPhoneLine: (u8sLinePhoneNumber: string, phoneLineNumber: string) => void;
}

export const initialState = {
	myPhoneLines: new Map(),
	localPhoneLines: new Map()
};

export const createCtiSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	CtiSliceT
> = (set) => ({
	...initialState,
	setPhoneLines: (lines: ILineInfoContainer[]) => {
		set(
			(prev) => {
				return {
					...prev,
					myPhoneLines: new Map([
						...prev.myPhoneLines,
						...new Map(
							lines.map((line) => [
								line.lineInfo.u8sLinePhoneNumber,
								{ ...prev.myPhoneLines.get(line.lineInfo.u8sLinePhoneNumber), ...line }
							])
						)
					])
				};
			},
			false,
			"setPhoneLines"
		);
	},
	removePhoneLines: (u8sLinePhoneNumbers: string[]) => {
		set(
			(prev) => {
				return {
					...prev,
					myPhoneLines: new Map([...prev.myPhoneLines].filter(([key]) => !u8sLinePhoneNumbers.includes(key)))
				};
			},
			false,
			"removePhoneLines"
		);
	},
	setDoNotDisturb: (u8sLinePhoneNumber: string, iDoNotDisturb: number) => {
		set(
			(prev) => {
				return {
					...prev,
					myPhoneLines: new Map(
						[...prev.myPhoneLines].map(([key, value]) => {
							if (key === u8sLinePhoneNumber) {
								return [key, { ...value, bDoNotDisturb: iDoNotDisturb === 1 ? true : false }];
							}
							return [key, value];
						})
					)
				};
			},
			false,
			"setDoNotDisturb"
		);
	},
	setRemoteOffice: (u8sLinePhoneNumber: string, remoteOfficeState: AsnRemoteOfficeState) => {
		set(
			(prev) => {
				return {
					...prev,
					myPhoneLines: new Map(
						[...prev.myPhoneLines].map(([key, value]) => {
							if (key === u8sLinePhoneNumber) {
								return [key, { ...value, remoteOfficeState }];
							}
							return [key, value];
						})
					)
				};
			},
			false,
			"setRemoteOffice"
		);
	},
	setLineForwards: (u8sLinePhoneNumber: string, seqLineForwards: AsnLineForwards) => {
		set(
			(prev) => {
				return {
					...prev,
					myPhoneLines: new Map(
						[...prev.myPhoneLines].map(([key, value]) => {
							if (key === u8sLinePhoneNumber) {
								return [key, { ...value, seqLineForwards }];
							}
							return [key, value];
						})
					)
				};
			},
			false,
			"setLineForwards"
		);
	},
	setLocalPhoneLine: (u8sLinePhoneNumber: string, phoneLineNumber: string) => {
		set(
			(prev) => {
				return {
					...prev,
					localPhoneLines: new Map(prev.localPhoneLines).set(u8sLinePhoneNumber, {
						lineForwardNumber: phoneLineNumber
					})
				};
			},
			false,
			"setLocalPhoneLine"
		);
	}
});
