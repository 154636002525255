import { Stack, Switch } from "@mui/material";

import { Typography } from "../../components/common/Typography/Typography";

interface ISettingsItem {
	title: string;
	subTitle?: string;
	value?: boolean;
	disabled?: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	bottomComponent?: () => JSX.Element;
}

export const SettingsItem = ({ title, value, subTitle, onChange, bottomComponent, disabled }: ISettingsItem) => {
	return (
		<Stack>
			<Stack>
				<Typography variant={"h3"}>{title}</Typography>
			</Stack>
			<Stack direction={"row"} alignItems={"flex-start"} mb={0.5} mt={1}>
				<Stack flexGrow={1}>
					{bottomComponent ? (
						bottomComponent()
					) : (
						<Stack justifyContent={"center"}>
							<Typography variant={"body1"}>{subTitle}</Typography>
						</Stack>
					)}
				</Stack>
				<Switch onChange={onChange} checked={value} disabled={disabled} />
			</Stack>
		</Stack>
	);
};
