import React, { useState, useRef, useMemo } from "react";
import styles from "./sharedSpeakerSelectorButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Tooltip } from "@mui/material";
import { IMediaDevice } from "../../interfaces/IMediaDevice";
import { IButtonCommonProps } from "../../interfaces/IButtonCommonProps";
import { setFAIconShrinkSize, theSharedTranslationManager } from "../../../../helpers/helpers";
import { faVolume, faAngleDown } from "web-fortawesome/npm/pro-light";

interface IProps extends IButtonCommonProps {
	onSpeakerSelected?: (speaker: string) => void;
	selectedSpeakerID: string | undefined;
	speakerList: IMediaDevice[];
}

/**
 * Speaker Selector Button
 *
 * @param props - props
 * @returns - the component
 */
export default function SharedSpeakerSelectorButton(props: IProps): JSX.Element {
	const anchorRef = useRef<HTMLButtonElement>(null);
	const [listOpen, setListOpen] = useState<boolean>(false);
	const width: string = props.width + "px";
	const height: string = props.height + "px";

	/**
	 * Render the speaker list in the dropdown menu
	 * @returns - the elements or null
	 */
	const speakerList: React.ReactElement[] | null = useMemo(() => {
		if (props.speakerList.length === 0)
			return null;
		const speakers: React.ReactElement[] = [];
		props.speakerList.forEach((item, idx) => (
			speakers.push(
				<MenuItem
					key={item.deviceId}
					selected={item.deviceId === props.selectedSpeakerID}
					onClick={() => {
						setListOpen(false);
						props.onSpeakerSelected && props.onSpeakerSelected(item.deviceId);
					}}
				>
					{(item.label || `Speaker ${idx + 1}`) + (item.deviceId === props.selectedSpeakerID ? " ✓" : "")}
				</MenuItem>
			)
		));
		return speakers;
	}, [props.speakerList, props.selectedSpeakerID]);

	return (
		<div className={styles.container}>
			<IconButton
				ref={anchorRef}
				{...(!props.isDraggable ? { onClick: () => { setListOpen(!listOpen); } } : {})}
				{...(props.isDraggable ? { onTouchEnd: () => { setListOpen(!listOpen); } } : {})}
				{...(props.isDraggable ? { onClick: () => { setListOpen(!listOpen); } } : {})}
				sx={{
					padding: 0,
					margin: 0,
					width: width,
					height: height
				}}
				className={styles.active}
				disabled={props.isDisabled}
			>
				<FontAwesomeIcon icon={faVolume} transform={setFAIconShrinkSize(5)}/>
			</IconButton>

			<div className={styles.arrow}>
				<Tooltip title={theSharedTranslationManager.getTranslation("IDS_AV_CHOOSE_SPEAKER")} placement="bottom">
					<IconButton
						data-cy="webrtc-speaker-list-toggle"
						ref={anchorRef}
						{...(!props.isDraggable ? { onClick: () => { setListOpen(!listOpen); } } : {})}
						{...(props.isDraggable ? { onTouchEnd: () => { setListOpen(!listOpen); } } : {})}
						{...(props.isDraggable ? { onClick: () => { setListOpen(!listOpen); } } : {})}
						sx={{
							padding: 0,
							margin: 0,
							width: "unset",
							height: "unset"
						}}
					>
						<FontAwesomeIcon transform={setFAIconShrinkSize(5)} icon={faAngleDown}/>
					</IconButton>
				</Tooltip>
				<Popper
					transition
					placement="bottom"
					open={listOpen}
					anchorEl={anchorRef.current}
					sx={{
						zIndex: 101
					}}
				>
					{({ TransitionProps: props, placement }) => (
						<Grow {...props} style={{ transformOrigin: "right top" }}>
							<Paper>
								<ClickAwayListener onClickAway={() => { setListOpen(false); }}>
									<MenuList data-cy="webrtc-speaker-list" autoFocusItem={listOpen}>
										{speakerList}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		</div>
	);
}
