import { createTheme } from "@mui/material/styles";
// eslint-disable-next-line import/named
import { Palette as MuiPallete } from "@mui/material/styles/createPalette";

import fonts from "./fonts.css?inline";

const defaultFont = "Source Sans Pro";

const primColor100 = "rgba(83, 123, 151, 1)";
const primColor50 = "rgba(83, 123, 151, 0.5)";
export const black87 = "rgba(0, 0, 0, 0.87)";
const black56 = "rgba(0, 0, 0, 0.56)";
const black24 = "rgba(0, 0, 0, 0.24)";
const listHover = "rgba(83, 123, 151, 0.1)";
const listSelected = "rgba(83, 123, 151, 0.2)";
export const grayBackground = "rgba(0, 0, 0, 0.06)";

const inputActive = primColor100;

declare module "@mui/material/styles" {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface TypographyVariants {
		body3: React.CSSProperties;
	}
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface TypographyVariantsOptions {
		body3?: React.CSSProperties;
	}
}

declare module "@mui/material/Typography" {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface TypographyPropsVariantOverrides {
		body3: true;
	}
}

declare module "@mui/material/styles/createPalette" {
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Palette extends MuiPallete {
		black56: { main: string };
		black24: { main: string };
	}
}

const defaultThemeOptions = {
	palette: {
		primary: {
			main: primColor100,
			contrastText: "#fff"
		},
		text: {
			primary: black87
		},
		background: {
			default: "#f2f2f2"
		},
		success: {
			main: "#29c55c"
		},
		error: {
			main: "#c80331"
		},
		black56: {
			main: black56
		},
		black24: {
			main: black24
		}
	},
	typography: {
		fontFamily: defaultFont,
		htmlFontSize: 16,
		h1: {
			fontSize: "32px",
			fontWeight: 600,
			color: black87
		},
		h2: {
			fontSize: "24px",
			fontWeight: 600,
			color: black87
		},
		h3: {
			fontSize: "20px",
			fontWeight: 400,
			color: black87
		},
		h4: {
			fontSize: "20px",
			fontWeight: 300,
			color: black87
		},
		body1: {
			fontSize: "16px",
			fontWeight: 400,
			color: black87
		},
		body2: {
			fontSize: "14px",
			fontWeight: 400,
			color: black56
		},
		body3: {
			fontSize: "11px",
			fontWeight: 500,
			color: black56
		},
		button: {
			fontSize: "16px",
			fontWeight: 600,
			color: black87
		}
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: fonts
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					"& .MuiSwitch-switchBase": {
						color: "grey",
						height: "inherit"
					},
					"& .MuiSwitch-switchBase.Mui-checked": {
						color: primColor100
					},
					"& .MuiSwitch-track": {
						backgroundColor: black24
					}
				}
			}
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0
			},
			styleOverrides: {
				root: {
					borderRadius: "5px",
					"&.MuiAccordion-root.Mui-disabled": {
						backgroundColor: "transparent"
					}
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: inputActive
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: inputActive,
						borderWidth: "1px"
					}
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					"&.Mui-focused": {
						color: inputActive
					}
				}
			}
		},
		MuiToggleButtonGroup: {
			styleOverrides: {
				root: {
					"& .MuiToggleButton-root": {
						height: "32px"
					}
				}
			}
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					borderRadius: "5px",
					height: "34px"
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					boxShadow: "none"
				}
			}
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					color: "white",
					backgroundColor: primColor50,
					"&.Mui-selected": {
						backgroundColor: primColor100,
						color: "white",
						"&:hover": {
							opacity: 0.8,
							backgroundColor: primColor100
						}
					},
					"&:hover": {
						opacity: 0.8,
						backgroundColor: primColor50
					}
				}
			}
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					"&.Mui-selected": {
						backgroundColor: listSelected
					},
					"&.Mui-focusVisible": {
						backgroundColor: "transparent"
					},
					"&:hover": {
						backgroundColor: listHover
					}
				}
			}
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					"&.Mui-disabled": {
						opacity: 1
					},
					"&:before": {
						display: "none"
					}
				}
			}
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					"&:before": {
						display: "none"
					}
				}
			}
		}
	}
};

export const MuiTheme = createTheme(defaultThemeOptions);
