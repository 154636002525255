import { DateTime } from "luxon";
import { useCallback } from "react";

export const useDateTimeFormatter = () => {
	const formatTime = useCallback((date: Date) => {
		if (!date) {
			return "";
		}
		const dateObject = new Date(date);
		return dateObject.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });
	}, []);

	const formatDateShort = useCallback((date: Date) => {
		if (!date) {
			return "";
		}
		const dateObject = new Date(date);
		return dateObject.toLocaleDateString([], { year: "numeric", month: "short", day: "numeric" });
	}, []);
	const formatDate = useCallback((date: Date) => {
		if (!date) {
			return "";
		}
		const dateObject = new Date(date);
		return DateTime.fromJSDate(dateObject).toFormat("D");
	}, []);

	const formatRelativeToday = useCallback(
		(date: Date) => {
			if (!date) {
				return "";
			}
			const dateObject = new Date(date);

			// if date is today, return time
			if (dateObject.toDateString() === new Date().toDateString()) {
				return formatTime(dateObject);
			}

			// if date is yesterday, return "Yesterday"
			// const yesterday = new Date();
			// yesterday.setDate(yesterday.getDate() - 1);
			// if (dateObject.toDateString() === yesterday.toDateString())
			// 	return "Yesterday";

			// else return date
			return formatDate(dateObject);
		},
		[formatTime, formatDate]
	);

	return { formatTime, formatDateShort, formatRelativeToday };
};
