
export interface IFilterConfig {
	// The two filters depend on another
	// If you set includes the name must match -> default changes to DO NOT WRITE entry
	// If you set excludes the name is not allowd to match -> default changes to WRITE entry
	// If you set includes and excludes the classname is first filtered for includes and then for excludes
	includeClassNames?: string[];
	excludeClassNames?: string[];

	includeMethodNames?: string[];
	excludeMethodNames?: string[];

	includeMessages?: string[];
	excludeMessages?: string[];
}

// If you do not specify anything, everything is logged -> defaults to true for all of them
// If you:
// - do not want certain levels to be logged, specify false
// - only want certain levels to be logged, specify the regular expression matching classnames and or methodnames
//   if any of the conditions matches the entry will be logged
export interface IFilterConfigs {
	error: IFilterConfig | boolean | undefined;
	warn: IFilterConfig | boolean | undefined;
	info: IFilterConfig | boolean | undefined;
	debug: IFilterConfig | boolean | undefined;
}

/**
 * Error values, highest to lowest
 */
export type LogLevels = "error" | "warn" | "info" | "debug";

/**
 * The loglevels from highes to lowest
 */
export enum ELogLevel {
	ERROR = 1,
	WARN = 2,
	INFO = 3,
	DEBUG = 4 // Default
}

// The log data blob whichn is provided through the ILogCallback
export interface ILogData {
	className: string;
	classProps?: Record<string, unknown>;
}

// The callback that is used to query log data for a log invoke
export interface ILogCallback {
	getLogData(): ILogData;
}

// For Loki we can specify alternate labels for certain log entry calls
// In order to specify that those alternate labels shall be used the meta needs to contain the lokiLabelsKey key and the ILokiConfig the matching entry in the map
interface ILokiAlternateLabelsMeta {
	lokiLabelsKey: string;
}

export enum EError {
	NoError,
	AsnErrorRequestReject, // ASN error when a request is rejected
	AsnErrorRequestResult, // ASN error in the request result
	AuthenticationErrorUCServer,
	AuthenticationErrorMeetings,
	FetchFailed,
	FileTransferError,
	Generic,
	InvalidUCControllerURL,
	InvalidUCSID,
	InvalidUCServerURI,
	InvalidProtocolVersion,
	InvalidServerInterface,
	MediaDeviceError,
	PersistenceStorageReadError,
	SessionCreateGeneric,
	SessionDestroyGeneric,
	SessionNoID,
	SubscribeError,
	UCServerConnectionError,
	WebsocketError
}

/**
 * This interface contains a single log entry as being processed by ELogger
 */
export interface ILogMessage extends ILogData {
	readonly time: Date;
	readonly timestamp: number;
	level: LogLevels;
	message: string | undefined;
	method: string | undefined;
	meta: unknown | ILokiAlternateLabelsMeta;
	error: EError | unknown | undefined;
	// The loki labels key is taken from the metadata as the logger processes the log message
	// After having it copied here it is removed from the metadata
	lokiLabelsKey?: string;
}

/**
 * If the IHandleLogEntryCallback wants the library to handle the event as sentry event it can simply return this object
 */
export interface IProcessLogMessage {
	// Flag to process the message
	bProcess: boolean;
	// User entered description about the error
	description: string;
}

/**
 * This callback is called whenever a log message is handed over to the logger
 * The frontend can handle decide based on the log message if a user shall add additional contextual data to the logentry
 * If the frontend sets bProcess the entry is automatically processed through sentry
 */
export interface ILogEntryCallback {
	onLogMessage(logmessage: ILogMessage): IProcessLogMessage | undefined;
}

/**
 * A registered callback together with an optional filter element
 * The filter allows to define for which entries the callback wants to get informed
 */
export interface ILogEntryWithFilter {
	callback: ILogEntryCallback;
	filter?: IFilterConfigs;
}

/**
 * Interface to access the Logger
 */
export interface ILogger {
	error(msg: string, calling_method: string, logdata_or_callback?: ILogData | ILogCallback, meta?: Record<string, unknown>, exception?: unknown): void;
	warn(msg: string, calling_method: string, logdata_or_callback?: ILogData | ILogCallback, meta?: Record<string, unknown>, exception?: unknown): void;
	info(msg: string, calling_method: string, logdata_or_callback?: ILogData | ILogCallback, meta?: Record<string, unknown>, exception?: unknown): void;
	debug(msg: string, calling_method: string, logdata_or_callback?: ILogData | ILogCallback, meta?: Record<string, unknown>, exception?: unknown): void;

	console_log(data: unknown, ...optionalParams: any[]): void;
	console_warn(data: unknown, ...optionalParams: any[]): void;
	console_error(data: unknown, ...optionalParams: any[]): void;

	storeLogs(store: boolean): void;
	setLogLevel(logLevel: ELogLevel): void;
	log: ILogMessage[];
	clearLog(): void;
	addLogEntryCallback(callback: ILogEntryCallback, filter: IFilterConfigs | undefined): void;
	removeLogEntryCallback(callback: ILogEntryCallback): void;
}
