import { FC } from "react";
import { useTranslation } from "react-i18next";

import {
	AsnNetDatabaseContact,
	AsnNetDatabaseContactList
} from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { Typography } from "../../components/common/Typography/Typography";
import { ContactList } from "../../components/ContactList/ContactList";

interface ISearchResultContacts {
	searchedContacts: AsnNetDatabaseContactList;
	selectedContactId: string | null;
	isLoading: boolean;
	searchTerm?: string;
	endReached: (index: number) => void;
	onClick: (contact: AsnNetDatabaseContact) => void;
}

export const SearchResultContacts: FC<ISearchResultContacts> = ({
	selectedContactId,
	searchedContacts,
	isLoading,
	searchTerm,
	onClick,
	endReached
}) => {
	const { t } = useTranslation();

	return (
		<>
			<Typography variant="body2" sx={{ pl: 2 }}>
				{t("IDS_CONTACT_SEARCH_RESULTS")}
			</Typography>
			<ContactList
				contacts={searchedContacts}
				endReached={endReached}
				onClick={onClick}
				selectedContactId={selectedContactId}
				isLoading={isLoading}
				searchTerm={searchTerm}
			/>
		</>
	);
};
