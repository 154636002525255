import { Box, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TextField } from "../../../components/common/TextField/TextField";
import { ReadStatusE } from "../../../zustand/configSlice";
import { useStore } from "../../../zustand/store";

export interface IFormInputs {
	readStatus: ReadStatusE;
	searchTerm: string;
}

export const FilterBar = () => {
	const { t } = useTranslation();
	const setReadFilter = useStore((state) => state.setReadFilter);
	const defaultReadFilter = useStore((state) => state.journalReadFilter);
	const defaultValues = useMemo(
		() => ({
			readStatus: defaultReadFilter,
			searchTerm: ""
		}),
		[defaultReadFilter]
	);

	const { handleSubmit, control, watch } = useForm<IFormInputs>({
		defaultValues
	});

	useEffect(() => {
		const onSubmit = (data: IFormInputs) => {
			setReadFilter(data.readStatus);
		};

		const { unsubscribe } = watch(() => handleSubmit(onSubmit)());
		return () => {
			unsubscribe();
		};
	}, [handleSubmit, watch, setReadFilter]);

	return (
		<form>
			<Stack direction="row" spacing={1}>
				<Box>
					<Controller
						render={({ field, fieldState }) => (
							<ToggleButtonGroup exclusive {...field}>
								<ToggleButton color="primary" value={ReadStatusE.All} sx={{ minWidth: "100px" }}>
									{t("IDS_JOURNAL_FILTER_ALL")}
								</ToggleButton>
								<ToggleButton value={ReadStatusE.Unread} sx={{ minWidth: "100px" }}>
									{t("IDS_JOURNAL_FILTER_UNREAD")}
								</ToggleButton>
							</ToggleButtonGroup>
						)}
						name="readStatus"
						control={control}
					/>
				</Box>
				<Box>
					{/* <Controller
						render={({ field, fieldState }) => (
							<TextField
								placeholder={t("IDS_JOURNAL_SEARCH_PLACEHOLDER") || ""}
								fieldError={fieldState.error}
								{...field}
							/>
						)}
						name="searchTerm"
						control={control}
					/> */}
				</Box>
			</Stack>
		</form>
	);
};
