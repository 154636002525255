import { theAudioPlayer, theAVManager } from "../globals";
import {
	Eav_CallType,
	EAvDropReason,
	EAvMessageType,
	ECallState,
	IAVCallMessage,
	IAVCallParams
} from "../interfaces/IAVCall";
import { IHTMLAudioWithSinkID } from "../interfaces/IBrowserDetector";
import { navigator_media } from "../lib/BrowserDetector";
import { getState } from "../zustand/store";
import PeerConnectionClient, { IPeerConnectionStates } from "./PeerConnectionClient";
import WebRTCHelper from "./WebRTCHelper";

interface IRTCLogs {
	iceCandidatesLocal: string[];
	iceCandidatesRemote: string[];
	iceServers: RTCIceServer[];
}

/**
 *
 */
export default class AVCall {
	public static CONNECTION_TIMEOUT = 20000;
	public id: string | undefined;
	public callState: ECallState;
	public pcClient: PeerConnectionClient | null;
	public localStream: MediaStream;
	public remoteStream: MediaStream;
	public localAudioTrack: MediaStreamTrack | undefined;
	public localVideoTrack: MediaStreamTrack | undefined;
	public audio: IHTMLAudioWithSinkID;
	public params: IAVCallParams;
	public isScreenSharing: boolean;
	public isOtherAudioMuted: boolean;
	public isIceDisconnected: boolean;
	public isCheckIceStarted: boolean;
	public asnCallType: Eav_CallType;
	private signalingQueue: IAVCallMessage[];
	private recvSignalingQueue: IAVCallMessage[];
	private isPcClientInitializing: boolean;
	private timeout: NodeJS.Timeout | null;
	private durationInterval: NodeJS.Timer | null;
	public duration: number;
	private logs: IRTCLogs;

	/**
	 * Constructor
	 *
	 * @param params - IAVCallParams
	 */
	public constructor(params: IAVCallParams) {
		this.params = params;
		this.id = undefined;
		this.pcClient = null;
		this.localStream = new MediaStream();
		this.remoteStream = new MediaStream();
		this.isScreenSharing = false;
		this.isOtherAudioMuted = false;
		this.timeout = null;
		this.durationInterval = null;
		this.duration = 0;
		this.audio = new Audio() as IHTMLAudioWithSinkID;

		this.logs = {
			iceCandidatesLocal: [],
			iceCandidatesRemote: [],
			iceServers: []
		};

		this.isIceDisconnected = false;
		this.isCheckIceStarted = false;

		this.callState = ECallState.IDLE;
		this.setAndDispatchCallState(ECallState.IDLE);
		this.asnCallType = params.asnCallType;
		// Signaling Nachrichten können erst dann über den UCServer gesendet werden, wenn die Connection steht.
		// Das ist dann der Fall, sobald ein asnAVConnect eingetroffen ist.
		this.signalingQueue = [];
		// Bevor eine PeerConnection erstellt werden kann, muss die STUN/TURN Konfiguration asynchron geholt werden.
		// In der Zeit empfangene Signalisierungs Nachrichten (bspw. Remote Kandidaten) landen in dieser Queue.
		this.recvSignalingQueue = [];
		// Flag ob gerade eine Anfrage für die STUN/TURN Konfiguration läuft.
		this.isPcClientInitializing = false;
	}

	/**
	 * Sets the new call state and dispatch it to Redux as soon as it changes
	 *
	 * @param state - the new state
	 */
	public setAndDispatchCallState(state: ECallState) {
		this.callState = state;
		if (!this.id) {
			return;
		}
		getState().avCallSetCallState({ id: this.id, callState: this.callState });
		if (state === ECallState.CONNECTED) {
			this.durationInterval = setInterval(() => {
				this.duration++;
			}, 1000);
			void theAudioPlayer.play("callAccepted");
		}
	}

	/**
	 * On new ice candidate
	 *
	 * @param location - local or remote
	 * @param candidate - the candidate
	 */
	private onNewIceCandidate(location: "Local" | "Remote", candidate: RTCIceCandidate) {
		if (location === "Local") {
			this.logs.iceCandidatesLocal.push(candidate.candidate);
		} else if (location === "Remote") {
			this.logs.iceCandidatesRemote.push(candidate.candidate);
		}
	}

	/**
	 * ontrack event of Peer Connection
	 *
	 * @param track - the track to add
	 */
	private onTrack(track: MediaStreamTrack) {
		console.log("onTrack", track);
		if (!this.id) {
			return;
		}
		if (track.kind === "video") {
			const existingVideoTrack = this.remoteStream.getVideoTracks()[0];
			if (existingVideoTrack) {
				this.remoteStream.removeTrack(existingVideoTrack);
			}
			getState().avCallSetRemoteVideoTrack({ id: this.id, trackID: track.id });
			getState().avCallSetRemoteVideoMuted({ id: this.id, isRemoteVideoMuted: false });
		} else if (track.kind === "audio") {
			const existingAudioTrack = this.remoteStream.getAudioTracks()[0];
			if (existingAudioTrack) {
				this.remoteStream.removeTrack(existingAudioTrack);
			}

			getState().avCallSetRemoteAudioTrack({ id: this.id, trackID: track.id });
			getState().avCallSetRemoteAudioMuted({ id: this.id, isRemoteAudioMuted: false });
		}
		this.remoteStream.addTrack(track);
		if (track.kind === "audio" && !this.audio.srcObject) {
			this.audio.srcObject = this.remoteStream;
			void this.audio.play();
		}
	}

	/**
	 * On onIceConnectionStateChanged peer connection event
	 *
	 * @param state - the state of the peer connection
	 */
	private onIceConnectionStateChanged(state: string) {
		console.log("onIceConnectionStateChanged", state);
		if (!this.pcClient) {
			return;
		}
		const pc = this.pcClient.pc;
		if (!pc) {
			return;
		}
		this.isIceDisconnected = false;
		if (state === "connected") {
			this.setAndDispatchCallState(ECallState.CONNECTED);
		} else if (state === "failed") {
			// TODO WEBRTCTODO
			console.error("AvCall: iceconnection failed");
		} else if (state === "disconnected") {
			this.isIceDisconnected = true;
			if (!this.isCheckIceStarted) {
				this.checkIceDisconnect();
			}
		}
	}

	/**
	 * Check ICE disconnect
	 */
	private checkIceDisconnect() {
		this.isCheckIceStarted = true;

		setTimeout(() => {
			if (this.isIceDisconnected) {
				this.setAndDispatchCallState(ECallState.CONNECTION_LOST);
				this.hangup(false, EAvDropReason.MEDIA_CONN_TIMEOUT);
			}
			this.isCheckIceStarted = false;
		}, 5000);
	}

	/**
	 * Get this avCall.params.isInitiator
	 *
	 * @returns - true if is initiator of the call, false otherwise
	 */
	public isInitiator(): boolean {
		return this.params.isInitiator;
	}

	/**
	 * Starts the connection timeout
	 */
	public startConnectionTimeout() {
		if (!this.timeout) {
			this.timeout = setTimeout(this.timeoutAvCall.bind(this), AVCall.CONNECTION_TIMEOUT);
		}
	}

	/**
	 * Handler the connection timeout
	 */
	private timeoutAvCall() {
		this.timeout = null;

		if (this.callState >= ECallState.CONNECTED && this.callState < ECallState.TIMEOUT) {
			console.error("AvCall: Timeout but already connected or hung up");
			return;
		}

		const states = this.pcClient && this.pcClient.getPeerConnectionStates();

		console.log("AvCall: Reached AV-Connection-Timeout. PeerConnection states: ", states);
		console.log("AvCall: Reached AV-Connection-Timeout. Ice candidates and STUN/TURN config: ", this.logs);

		if (this.logs.iceCandidatesLocal.length === 0) {
			console.error("AvCall: Reached AV-Connection-Timeout. Hint: Not one single local candidate could be gathered.");
			this.setAndDispatchCallState(ECallState.TIMEOUT_NO_LOCAL_CANDIDATES);
		} else if (this.logs.iceCandidatesRemote.length === 0) {
			console.error("AvCall: Reached AV-Connection-Timeout. Hint: The remote party did not send one single candidate.");
			this.setAndDispatchCallState(ECallState.TIMEOUT_NO_REMOTE_CANDIDATES);
		} else if (!this.logs.iceServers || !(this.logs.iceServers.length && this.logs.iceServers.length > 0)) {
			console.error("AvCall: Reached AV-Connection-Timeout. Hint: No TURN server config available.");
			this.setAndDispatchCallState(ECallState.TIMEOUT_NO_TURN_CONFIG);
		} else if (!this.hasRelayCandidates("Local")) {
			console.error(
				"AvCall: Reached AV-Connection-Timeout. Hint: A TURN Server config was avaliable, but it was not possible to gather one single TURN candidate. The TURN config seems to be invalid."
			);
			this.setAndDispatchCallState(ECallState.TIMEOUT_NO_LOCAL_RELAY_CANDIDATES);
		} else if (!this.hasRelayCandidates("Remote")) {
			console.error(
				"AvCall: Reached AV-Connection-Timeout. Hint: The remote party did not send one single TURN candidate."
			);
			this.setAndDispatchCallState(ECallState.TIMEOUT_NO_REMOTE_RELAY_CANDIDATES);
		} else {
			console.error("AvCall: Timeout error.");
			this.setAndDispatchCallState(ECallState.TIMEOUT);
		}

		this.hangup(false, EAvDropReason.MEDIA_CONN_TIMEOUT);
	}

	/**
	 * Check if there are relay candidates
	 *
	 * @param location - Local or Remote
	 * @returns - true on success
	 */
	private hasRelayCandidates(location: "Local" | "Remote") {
		let candidates;
		if (location === "Local") {
			candidates = this.logs.iceCandidatesLocal;
		} else {
			candidates = this.logs.iceCandidatesRemote;
		}

		for (let i = 0; i < candidates.length; i++) {
			if (theAVManager.getIceCandidateType(candidates[i]) === "relay") {
				return true;
			}
		}

		return false;
	}

	/**
	 * Start the call.
	 */
	public async start() {
		if (this.callState > ECallState.INCOMING && this.callState < ECallState.CONNECTED) {
			console.log("AvCall: call already started");
			return;
		}
		// When we start a new call, always hang up potentially existing connected calls
		const avCalls = theAVManager.getAVCalls();
		if (avCalls) {
			for (const call of avCalls) {
				if (call.callState === ECallState.CONNECTED) {
					call.hangup(false, EAvDropReason.ONLY_ONE_CALL_ALLOWED);
				}
			}
		}
		this.setAndDispatchCallState(ECallState.MEDIA_REQUEST);

		console.log("AvCall: get user media with constraints: ", this.params.mediaConstraints);

		const constraints = this.params.mediaConstraints;

		// I'm receiving a Screesharing-only call, I don't need to get my user media devices
		if (!this.params.isInitiator && this.asnCallType === Eav_CallType.EAV_CALLTYPE_DESKTOPSHARING) {
			this.setAndDispatchCallState(ECallState.MEDIA_REQUEST_DONE);
			await this.startSignaling();
		} else {
			try {
				const stream = await navigator_media.mediaDevices.getUserMedia(constraints);
				const audioTrack = stream.getAudioTracks()[0];
				const videoTrack = stream.getVideoTracks()[0];
				if (audioTrack) {
					this.localAudioTrack = audioTrack;
					this.localStream.addTrack(audioTrack);
					// In case the constraints didn't contain a pre-selected mic, get the id from the track settings
					if (!(constraints.audio as MediaTrackConstraints)?.deviceId) {
						const micList = getState().mics;
						const groupId = audioTrack.getSettings().groupId;
						const deviceID = WebRTCHelper.getDeviceIdFromSameGroup(groupId, micList);
						getState().avCallSetSelectedMic(deviceID);
					}
				}
				if (videoTrack) {
					this.localVideoTrack = videoTrack;
					this.localStream.addTrack(videoTrack);
					// In case the constraints didn't contain a pre-selected cam, get the id from the track settings
					if (!(constraints.video as MediaTrackConstraints)?.deviceId) {
						const camList = getState().cams;
						const groupId = videoTrack.getSettings().groupId;
						const deviceID = WebRTCHelper.getDeviceIdFromSameGroup(groupId, camList);
						getState().avCallSetSelectedCam(deviceID);
					}
				}
				// Signaling starten
				this.setAndDispatchCallState(ECallState.MEDIA_REQUEST_DONE);
				await this.startSignaling();
			} catch (error) {
				console.error("AvCall: Error getting user media", error);
				this.setAndDispatchCallState(ECallState.MEDIA_FAILURE);
				this.hangup();
			}
		}
	}

	/**
	 * Beendet den Call.
	 *
	 * @param withoutSignaling - Boolean. True falls das Beenden dem Server nicht mitgeteilt werden soll. Standardmäßg false
	 * @param dropReason - Grund des Beendens (siehe AvEnums.AVDropReason), Standardmäßig 0.
	 */
	public hangup(withoutSignaling?: boolean, dropReason?: EAvDropReason) {
		if (!withoutSignaling) {
			this.sendSignalingMessageToAvManager({ type: "bye", content: dropReason });
		}

		if (this.callState < 20) {
			this.setAndDispatchCallState(ECallState.HANGUP);
		}

		const tracks = this.localStream.getTracks();
		if (tracks) {
			for (const track of tracks) {
				track.stop();
			}
		}

		if (this.id) {
			getState().avCallRemove(this.id);
		}

		if (this.pcClient) {
			this.pcClient.close();
			this.pcClient = null;
		}
		void theAudioPlayer.play("callEnded");
	}

	/**
	 * Remote hang up
	 *
	 * @param remoteDropReason - EAvDropReason
	 */
	public onRemoteHangup(remoteDropReason: EAvDropReason) {
		if (this.callState < 20 && remoteDropReason === EAvDropReason.MEDIA_CONN_TIMEOUT) {
			this.setAndDispatchCallState(ECallState.TIMEOUT);
		} else if (this.callState < 20) {
			this.setAndDispatchCallState(ECallState.REMOTEHANGUP);
		}
		this.hangup(true);
	}

	/**
	 * Set local stream audio track
	 *
	 * @param track - the new track to set
	 */
	public setAudioTrack(track?: MediaStreamTrack) {
		if (!this.id || !this.pcClient) {
			return;
		}
		const pc = this.pcClient.pc;
		if (!pc) {
			return;
		}
		const existingAudioTrack = this.localStream.getAudioTracks()[0];
		const signalOtherParty =
			(track === undefined && !!existingAudioTrack) || (!!track && existingAudioTrack === undefined);
		if (existingAudioTrack) {
			existingAudioTrack.stop();
			this.localStream.removeTrack(existingAudioTrack);
		}
		this.localAudioTrack = track;
		if (track) {
			this.localStream.addTrack(track);
			// replace the current audio track in the peer connection sender
			const senders = pc.getSenders();
			for (const sender of senders) {
				if (sender.track?.kind === "audio") {
					void sender.replaceTrack(track);
				}
			}
		}
		getState().avCallSetLocalAudioTrack({ id: this.id, trackID: track?.id });
		// Signal the new mic state to the other party
		if (signalOtherParty) {
			const msg: IAVCallMessage = {
				type: "devicestate",
				content: {
					asnType: EAvMessageType.MY_DEVICE_STATE,
					body: track === undefined ? "MIC_MUTE" : "MIC_UNMUTE"
				}
			};
			this.sendSignalingMessageToAvManager(msg);
		}
	}

	/**
	 * Set local stream video track
	 *
	 * @param track - the new track to set
	 */
	public setVideoTrack(track?: MediaStreamTrack) {
		if (!this.id || !this.pcClient) {
			return;
		}
		const pc = this.pcClient.pc;
		if (!pc) {
			return;
		}
		const existingVideoTrack = this.localStream.getVideoTracks()[0];
		const signalOtherParty =
			(track === undefined && !!existingVideoTrack) || (!!track && existingVideoTrack === undefined);
		if (existingVideoTrack) {
			existingVideoTrack.stop();
			this.localStream.removeTrack(existingVideoTrack);
		}
		this.localVideoTrack = track;
		if (track) {
			this.localStream.addTrack(track);
			// replace the current video track in the peer connection sender
			const senders = pc.getSenders();
			for (const sender of senders) {
				if (sender.track?.kind === "video") {
					void sender.replaceTrack(track);
				}
			}
		}
		getState().avCallSetLocalVideoTrack({ id: this.id, trackID: track?.id });
		// Signal the new cam state to the other party
		if (signalOtherParty) {
			const msg: IAVCallMessage = {
				type: "devicestate",
				content: {
					asnType: EAvMessageType.MY_DEVICE_STATE,
					body: track === undefined ? "CAM_MUTE" : "CAM_UNMUTE"
				}
			};
			this.sendSignalingMessageToAvManager(msg);
		}
	}

	/**
	 * Try to create a PeerConnection Client
	 *
	 * @returns - true if it's created (or already there), undefined otherwise
	 */
	private async maybeCreatePCClient(): Promise<true | undefined> {
		if (this.pcClient) {
			return true;
		} else {
			this.isPcClientInitializing = true;

			let iceServers = await theAVManager.getIceServers();
			if (iceServers instanceof Error) {
				console.warn("AcCall: error while getting stun/turn configuration", iceServers);
				// Try to create a PC with empty iceServers, this can work in localhost only
				iceServers = [];
			}

			/* this.pcConstraints = {
				optional: [
					{ googCpuOveruseDetection: true } // Used by whereby
				],
				rtcStatsPeerId: undefined,
				rtcStatsClientId: undefined,
				rtcStatsConferenceId: undefined
			};

			// sdpSemantics: this.browser.supports.unifiedPlan ? "unified-plan" : "plan-b"
			this.pcConfig = {
				iceServers: params.iceServers,
				sdpSemantics: "unified-plan"
			}; */

			this.logs.iceServers = iceServers;
			this.isPcClientInitializing = false;
			this.params.peerConnectionConfig.iceServers = iceServers;

			try {
				this.pcClient = new PeerConnectionClient(this.params);
				this.pcClient.onsignalingmessage = this.sendSignalingMessage.bind(this);
				this.pcClient.oniceconnectionstatechange = this.onIceConnectionStateChanged.bind(this);
				this.pcClient.ontrack = this.onTrack.bind(this);
				this.pcClient.onnewicecandidate = this.onNewIceCandidate.bind(this);
				this.pcClient.onremotehangup = this.onRemoteHangup.bind(this);
				this.pcClient.onerror = this.onError.bind(this);
				return true;
			} catch (e) {
				console.error("AvCall: Create PeerConnection exception", e);
				return undefined;
			}
		}
	}

	/**
	 * Start signaling
	 */
	private async startSignaling() {
		if (!this.params.isInitiator) {
			this.setAndDispatchCallState(ECallState.CONNECTIONPENDING);
		}

		await this.maybeCreatePCClient();
		if (!this.pcClient) {
			return;
		}
		if (this.localStream) {
			this.pcClient.addStream(this.localStream);
		}

		if (this.params.isInitiator) {
			this.pcClient.startAsCaller();
		} else {
			this.pcClient.startAsCallee(this.params.messages);
		}
	}

	/**
	 * Handle on receive signaling channel message
	 *
	 * @param msg - the message
	 */
	public async onRecvSignalingChannelMessage(msg: IAVCallMessage) {
		this.recvSignalingQueue.push(msg);
		await this.maybeCreatePCClient();
		this.drainRecvSignalingQueue();
	}

	/**
	 * Drain the received signaling message queue
	 */
	private drainRecvSignalingQueue() {
		if (this.pcClient) {
			for (const msg of this.recvSignalingQueue) {
				this.pcClient.receiveSignalingMessage(msg);
			}

			// Workaround: for some reason resetting this array cleans the queue in the pcClient
			setTimeout(() => {
				this.recvSignalingQueue = [];
			}, 100);
		}
	}

	/**
	 * Send signaling message
	 *
	 * @param message - the message to send
	 */
	public sendSignalingMessage(message: IAVCallMessage) {
		const canBeQueued = message.type === "candidate" || message.type === "end-of-candidates";

		// Falls noch keine ID bekannt ist, können die Kandidaten nicht an den UCServer geschickt werden.
		const isConnected = this.id;

		if (canBeQueued && !isConnected) {
			console.log("AvCall: Adding " + message.type + " signaling message to queue.");
			this.signalingQueue.push(message);
		} else {
			if (isConnected) {
				for (let i = 0; i < this.signalingQueue.length; i++) {
					this.sendSignalingMessageToAvManager(this.signalingQueue[i]);
				}

				this.signalingQueue = [];
			}

			this.sendSignalingMessageToAvManager(message);
		}
	}

	/**
	 * Send the signaling message to AVManager
	 *
	 * @param message - the message to send
	 */
	private sendSignalingMessageToAvManager(message: IAVCallMessage) {
		theAVManager.sendSignalingMessage(this, message).catch((e) => {
			console.error(e);
		});
	}

	/**
	 * On error
	 *
	 * @param message - error message
	 */
	private onError(message: string) {
		console.error("PeerConnectionClient:", message);
	}

	/**
	 * Get the Peer Connection states
	 *
	 * @returns - the IPeerConnectionStates or undefined
	 */
	private getPeerConnectionStates(): IPeerConnectionStates | undefined {
		if (!this.pcClient) {
			return undefined;
		}

		return this.pcClient.getPeerConnectionStates();
	}

	/**
	 * Get the Peer Connection stats
	 */
	private async getPeerConnectionStats(): Promise<RTCStatsReport | undefined> {
		if (!this.pcClient) {
			return;
		}
		return await this.pcClient.getPeerConnectionStats();
	}
}
