import { Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AsnNetDatabaseJournal } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal";
import { theJournalManager } from "../../globals";
import { usePrevious } from "../../hooks/usePrevious";
import { SavingStateIndicator, useSavingState } from "./SavingStateIndicator";

interface ICurrentJournalEntryProps {
	journalEntry: AsnNetDatabaseJournal;
}

export const CurrentJournalEntry = ({ journalEntry }: ICurrentJournalEntryProps) => {
	const [memo, setMemo] = useState(journalEntry.u8sMemo);
	const { t } = useTranslation();
	const prevJournalEntryId = usePrevious(journalEntry.u8sConnectionID);

	const { savingState, debouncedUpdate, cancelUpdate } = useSavingState((memoText: string) =>
		theJournalManager.updateJournalMemo([journalEntry.u8sConnectionID], memoText)
	);

	useEffect(() => {
		if (!!prevJournalEntryId && prevJournalEntryId !== journalEntry.u8sConnectionID) {
			cancelUpdate();
		}
		setMemo(journalEntry.u8sMemo);
	}, [journalEntry, cancelUpdate, prevJournalEntryId, debouncedUpdate]);

	return (
		<Stack>
			<TextField
				sx={{ mx: 2 }}
				label={t("IDS_CONTACT_DETAILS_MEMO")}
				placeholder={t("IDS_CONTACT_DETAILS_MEMO") || ""}
				value={memo}
				size="small"
				multiline
				maxRows={10}
				onChange={(e) => {
					setMemo(e.target.value);
					void debouncedUpdate(e.target.value);
				}}
			/>
			<SavingStateIndicator savingState={savingState} />
		</Stack>
	);
};
