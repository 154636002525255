import { StateCreator } from "zustand";

import { StoreT } from "./store";

export type SettingsSliceT = IState & IActions;

interface IState {
	showCallConfirmation: boolean;
}

interface IActions {
	setShowCallConfirmation: (showCallConfirmation: boolean) => void;
}

export const initialState = {
	showCallConfirmation: true
};

export const createSettingsSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	SettingsSliceT
> = (set) => ({
	...initialState,
	setShowCallConfirmation: (showCallConfirmation: boolean) => {
		set(
			(prev) => {
				return {
					...prev,
					showCallConfirmation
				};
			},
			false,
			"setShowCallConfirmation"
		);
	}
});
