import { Divider, Stack } from "@mui/material";
import { ComponentProps } from "react";
import { faArrowLeft, faTimes } from "web-fortawesome/npm/pro-light";

import { CircleIconButton } from "../Button/CircleIconButton";
import { Typography } from "../Typography/Typography";

interface IHeaderProps extends ComponentProps<typeof Stack> {
	title: string;
	onBack?: () => void;
	onClose?: () => void;
	children?: React.ReactNode;
	hideDivider?: boolean;
}

export const Header = ({ title, children, onBack, onClose, hideDivider, ...props }: IHeaderProps) => {
	return (
		<>
			<Stack flexDirection="row" justifyContent="space-between" p={2} {...props}>
				<Stack flexDirection="row" overflow={"hidden"}>
					{onBack && <CircleIconButton icon={faArrowLeft} onClick={onBack} sx={{ mr: 1 }} />}
					<Typography variant="h2" noWrap flex={1}>
						{title}
					</Typography>
				</Stack>
				{children}
				{onClose && <CircleIconButton icon={faTimes} onClick={onClose} />}
			</Stack>
			{!hideDivider && <Divider sx={{ mx: 2, mb: 2 }} />}
		</>
	);
};
