import { v4 as uuidv4 } from "uuid";

import { AsnClientCapabilityEnum } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_ClientCapabilities";
import { IContactContainer } from "../../web-shared-components/interfaces/interfaces";

/**
 * Generates a uuidv4
 * @returns - the uuidv4
 */
export function generateGUID(): string {
	return uuidv4().split("-").join("");
}

/**
 * function to get the initials from a username. e.g. MA from Matthias Abele
 * if ucserverDisplayName exists (logged in user) use this for the initals otherwise (guest)
 * use the userEnteredDisplayName to create the initals
 * @param ucserverDisplayName - the username in the ucserver
 * @param userEnteredDisplayName - the username in meetings
 * @returns the string with the initials
 */
export function getInitials(ucserverDisplayName: string, userEnteredDisplayName: string): string {
	const regexpEmoji = /\p{Emoji}/gu;
	let initials: string;
	const name = userEnteredDisplayName.length > 0 ? userEnteredDisplayName : ucserverDisplayName;
	if (name === "") {
		return "";
	}

	const bits: Array<string> = name.split(" ");

	if (bits.length > 1) {
		const bit1 = bits[0]?.replace(regexpEmoji, "") || "";
		const bit2 = bits[bits.length - 1]?.replace(regexpEmoji, "") || "";
		let initial1: string;
		let initial2: string;

		if (bit1 === "") {
			initial1 = ":)";
		} else {
			initial1 = bit1[0] || "";
		}
		if (bit2 === "") {
			initial2 = ":)";
		} else {
			initial2 = bit2[0] || "";
		}

		initials = (initial1 + initial2).toUpperCase();
	} else {
		const initial = name.replace(regexpEmoji, "");

		if (initial && initial.length > 0) {
			if (initial.length === 1) {
				initials = initial[0]?.toUpperCase() || "";
			} else {
				initials = initial.substring(0, 2).toUpperCase();
			}
		} else {
			initials = ":)";
		}
	}

	return initials;
}

/**
 * Add the sip prefix if it does not exist in the contact id
 * @param contactID - the contact ID to check
 * @returns - the correct sip
 */
export function addSipPrefixIfNotExists(contactID?: string): string {
	if (!contactID) {
		return "";
	}
	const sip = contactID.substring(0, 4);
	if (sip === "sip:") {
		return contactID;
	}
	return "sip:" + contactID;
}

/**
 * Remove sip prefix if it does exist in the contact id
 * @param contactID - the contact ID to check
 * @returns - the contact id without the sip prefix
 */
export function removeSipPrefixIfExists(contactID: string | undefined): string {
	if (!contactID) {
		return "";
	}
	const sip = contactID.substring(0, 4);
	if (sip === "sip:") {
		return contactID.substring(4, contactID.length);
	}
	return contactID;
}

/**
 * Get the phone number from a full string sip uri
 * @param sipUri - the uri to check
 * @returns - the phone number only
 */
export function getPhoneNumberFromSIPURI(sipUri?: string): string {
	if (sipUri === undefined) {
		return "";
	}
	let phone = removeSipPrefixIfExists(sipUri);
	const split = phone.split("@");
	if (split.length) {
		phone = split[0];
	}
	return phone;
}

/**
 * Convert seconds in readable format
 * @param secs - the seconds to convert
 * @returns - a readable format like 00:00:00
 */
export function secondsToReadable(secs?: number): string {
	if (secs === undefined) {
		return "";
	}
	const date = new Date(0);
	date.setSeconds(secs);
	return date.toISOString().substring(11, 19);
}

/**
 * Check if the text is a phone number
 * @param numberToCheck - The number to check
 * @returns boolean
 */
export function checkIsPhoneNumber(numberToCheck: string) {
	if (!numberToCheck?.length) {
		return false;
	}

	// TODO: the check is very basic and should be improved
	return numberToCheck.match(/^[+]?(\d+(\d-\s*\d+)*)?$/g) !== null;
}

/**
 *	Start an audio or video call by opening the installed app
 * @param u8sSIPAddress - the sip address of the contact
 * @param type - "audio" | "video"
 */
export function startCall(u8sSIPAddress: string, type: "audio" | "video") {
	if (!u8sSIPAddress || ["audio", "video"].indexOf(type) === -1) {
		return;
	}

	if (type === "audio") {
		return window.open(`eucc-audio:${u8sSIPAddress}`, "_self");
	}

	if (type === "video") {
		return window.open(`eucc-video:${u8sSIPAddress}`, "_self");
	}
}

const checkUserHasCapability =
	(capability: AsnClientCapabilityEnum) =>
	(contact?: IContactContainer): boolean => {
		if (!contact) {
			return false;
		}
		const eClientCapabilities = contact?.clientCapabilities?.eClientCapabilities;
		if (!eClientCapabilities) {
			return false;
		}
		for (const value of eClientCapabilities) {
			if (value === capability) {
				return true;
			}
		}

		return false;
	};

/**
 * Check if the user has video chat capabilities
 * @param contact - the contact to check
 * @returns boolean
 */
export function checkUserHasVideoChat(contact?: IContactContainer): boolean {
	return checkUserHasCapability(AsnClientCapabilityEnum.bVideo)(contact);
}

/**
 * Check if the user has audio chat capabilities
 * @param contact - the contact to check
 * @returns boolean
 */
export function checkUserHasAudioChat(contact?: IContactContainer): boolean {
	return checkUserHasCapability(AsnClientCapabilityEnum.bAudio)(contact);
}

/**
 * Replace a substring inside a string with the same string but in bold and blue
 * (for the results in a search, according to the design)
 * @param str -  the total string
 * @param substr - the substring in bold
 * @returns the modified string
 */
export function boldAndBlueString(str: string, substr: string) {
	// In order to keep the original case (upper/lower), find the string, add the style, replace the original with it.
	let newString = "";
	const foundString = str.match(RegExp(substr, "i"));
	if (foundString && foundString.length) {
		newString = `<span style="color:#537b97;font-weight:bold">${foundString[0]}</span>`;
	}
	return str.replace(RegExp(substr, "gi"), newString);
}
