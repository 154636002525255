import { FC } from "react";
import { useTranslation } from "react-i18next";

import {
	AsnNetDatabaseContact,
	AsnNetDatabaseContactList
} from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { Typography } from "../../components/common/Typography/Typography";
import { ContactList } from "../../components/ContactList/ContactList";

interface IContactListProps {
	favoriteContacts: AsnNetDatabaseContactList;
	selectedContactId: string | null;
	onClick: (contact: AsnNetDatabaseContact) => void;
}

export const FavoriteContacts: FC<IContactListProps> = ({ selectedContactId, favoriteContacts, onClick }) => {
	const { t } = useTranslation();

	return (
		<>
			<Typography variant="body2" sx={{ pl: 2 }}>
				{t("IDS_CONTACT_VIP_CONTACTS")}
			</Typography>
			<ContactList contacts={favoriteContacts} onClick={onClick} selectedContactId={selectedContactId} />
		</>
	);
};
