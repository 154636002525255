import create from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";
import createVanilla from "zustand/vanilla";

import { AsnNetDatabaseJournal } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal";
import { IContactContainer } from "../../web-shared-components/interfaces/interfaces";
import { AvCallSliceT, createAvCallSlice, initialState as initialStateAvCallSlice } from "./avCallSlice";
import { ConfigSliceT, createConfigSlice, initialState as initialStateConfigSlice } from "./configSlice";
import {
	ContactDetailsSliceT,
	createContactDetailsSlice,
	initialState as initialStateContactDetailsSlice
} from "./contactDetailsSlice";
import {
	ContactSliceT,
	createContactSlice,
	IClientContent,
	initialState as initialStateContactSlice
} from "./contactSlice";
import {
	createCtiSlice,
	CtiSliceT,
	ILineInfoContainer,
	ILocalPhoneLineSettingsContainer,
	initialState as initialStateCtiSlice
} from "./ctiSlice";
import {
	createCustomLabelsSlice,
	CustomLabelsSliceT,
	initialState as initialStateCustomLabelsSlice
} from "./customLabelsSlice";
import { createJournalSlice, initialState as initialStateJournalSlice, JournalSliceT } from "./journalSlice";
import {
	createMediaDevicesSlice,
	initialState as initialStateMediaDevicesSlice,
	MediaDevicesSliceT
} from "./mediaDevicesSlice";
import { createMyselfSlice, initialState as initialStateMyselfSlice, MyselfSliceT } from "./myselfSlice";
import { createSendQueueSlice, initialState as initialStateSendQueueSlice, SendQueueSliceT } from "./sendQueueSlice";
import { createSettingsSlice, initialState as initialStateSettingsSlice, SettingsSliceT } from "./settingsSlice";
import {
	createSystemMessagesSlice,
	initialState as initialStateSystemMessagesSlice,
	SystemMessagesSliceT
} from "./systemMessagesSlice";
import {
	createTeamsAppInformationSlice,
	initialState as initialStateTeamsAppInformationSlice,
	TeamsAppInformationSliceT
} from "./teamsAppInformationSlice";

export type StoreT = ContactSliceT &
	JournalSliceT &
	ConfigSliceT &
	CustomLabelsSliceT &
	CtiSliceT &
	AvCallSliceT &
	MediaDevicesSliceT &
	ContactDetailsSliceT &
	SettingsSliceT &
	SendQueueSliceT &
	SystemMessagesSliceT &
	MyselfSliceT &
	TeamsAppInformationSliceT & {
		reset: () => void;
	};

const store = createVanilla<StoreT>()(
	devtools(
		persist(
			subscribeWithSelector((...a) => {
				return {
					...createJournalSlice(...a),
					...createContactSlice(...a),
					...createCustomLabelsSlice(...a),
					...createContactDetailsSlice(...a),
					...createConfigSlice(...a),
					...createMyselfSlice(...a),
					...createCtiSlice(...a),
					...createAvCallSlice(...a),
					...createMediaDevicesSlice(...a),
					...createSettingsSlice(...a),
					...createSendQueueSlice(...a),
					...createSystemMessagesSlice(...a),
					...createTeamsAppInformationSlice(...a),
					reset: () => {
						a[0](
							(prev) => {
								return {
									...prev,
									...initialStateJournalSlice,
									...initialStateContactSlice,
									...initialStateCustomLabelsSlice,
									...initialStateContactDetailsSlice,
									...initialStateConfigSlice,
									...initialStateMyselfSlice,
									...initialStateCtiSlice,
									...initialStateAvCallSlice,
									...initialStateMediaDevicesSlice,
									...initialStateSettingsSlice,
									...initialStateSendQueueSlice,
									...initialStateSystemMessagesSlice,
									...initialStateTeamsAppInformationSlice
								};
							},
							true,
							"reset"
						);
					}
				};
			}),
			{
				name: "estos-app",
				// Maps and Sets are not supported by localStorage
				serialize: (data) => {
					return JSON.stringify({
						...data,
						state: {
							...data.state,
							contactsPresence: Array.from(data.state.contactsPresence as Map<string, IContactContainer>),
							contactsDetails: Array.from(data.state.contactsDetails as Map<string, IClientContent>),
							myPhoneLines: Array.from(data.state.myPhoneLines as Map<string, ILineInfoContainer>),
							localPhoneLines: Array.from(data.state.localPhoneLines as Map<string, ILocalPhoneLineSettingsContainer>),
							journalEntries: Array.from(data.state.journalEntries as Map<string, AsnNetDatabaseJournal>),
							journalContactIds: Array.from(data.state.journalContactIds as Set<string>)
						}
					});
				},
				deserialize: (value) => {
					const data = JSON.parse(value);
					data.state.contactsPresence = new Map(data.state.contactsPresence);
					data.state.contactsDetails = new Map(data.state.contactsDetails);
					data.state.myPhoneLines = new Map(data.state.myPhoneLines);
					data.state.localPhoneLines = new Map(data.state.localPhoneLines);
					data.state.journalEntries = new Map(data.state.journalEntries);
					data.state.journalContactIds = new Set(data.state.journalContactIds);
					return data;
				},
				partialize: (store) => {
					const { localPhoneLines, ...restInitialStateCtiSlice } = initialStateCtiSlice;
					// we don't want to persist the whole state
					return {
						...store,
						...initialStateAvCallSlice,
						...initialStateContactSlice,
						...initialStateJournalSlice,
						...restInitialStateCtiSlice,
						...initialStateSendQueueSlice,
						...initialStateSystemMessagesSlice
					};
				}
			}
		),
		{
			enabled: process.env.NODE_ENV === "development",
			serialize: {
				options: {
					map: true
				}
			}
		}
	)
);

export const useStore = create(store);
export const { getState, setState, subscribe } = store;
