import "./UserPresenceIcon.scss";
import React, { useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "web-fortawesome/npm/pro-light";
import { ICombiPic } from "../contactDetailsComponent/models/ModelCombinedContact";
import { iPresenceStateToString } from "./UserPresenceHelper";

interface IProps {
	pict: ICombiPic | null | undefined;
	pres: number;
	size?: number;
	unknownPresence?: boolean;
	noPresenceBorder?: boolean;
	isGroup?: boolean;
}

/**
 * The Icon showing the userImage with the presence.
 * In case it's a group it shows a generic icon.
 *
 * @param props - the props passed from the parent
 * @returns the Icon itself
 */
export default function UserPresenceIcon(props: IProps): JSX.Element {
	const textPresence = useMemo(() => {
		return iPresenceStateToString(props.pres);
	}, [props.pres]);
	const iconSize = useMemo(() => {
		return props.size || 40;
	}, [props.size]);
	const sizeInitials= useMemo(() => {
		let size = 20;
		if (props.size)
			size = props.size / 2.5;
		return size + "px";
	}, [props.size]);

	const getCssAddClass = useCallback(() => {
		if (props.unknownPresence && !props.noPresenceBorder)
			return "upi-unknown-presence";
		else if (props.noPresenceBorder)
			return "upi-no-presence-border";
		else
			if (props.size === 120) {
				return "upi-presence-big-size-" + textPresence;
			} else
				return "upi-presence-" + textPresence;
	}, [props.unknownPresence, props.noPresenceBorder, textPresence, props.size]);

	return (
		<>
			{!props.isGroup
				? <div className={"upi-main-container"}>
					<div className={"upi-container " + getCssAddClass() + " upi-container-normal"}
						style={{
							width: iconSize + "px",
							height: iconSize + "px"
						}}
					>
						<span
							// user shown with picture
							className={"upi-content" + (props.noPresenceBorder ? " upi-content-bg" : "")}
							style={{
								width: iconSize + "px",
								height: iconSize + "px",
								backgroundImage: props.pict?.visible
									? "url(data:image/jpeg;base64," + props.pict.jpegPhoto + ")"
									: "none"
							}}>

							{/* user shown with initials */}
							{props.pict?.visible
								? ""
								: <span className="uib-initals" style={{ fontSize: sizeInitials }}>{props.pict?.initials}</span>}

							{/* unknown user, no pict, no initals: */}
							{!props.pict || (props.pict?.visible === false && (!props.pict?.initials || props.pict?.initials === ""))
								? <span className="uib-unknown">
									<FontAwesomeIcon
										size={"xs"}
										icon={faUser}
										style={{
											width: (iconSize / 2.5) + "px",
											height: (iconSize / 2.5) + "px"
										}} />
								</span>
								: ""}
						</span>
					</div>
					{
						textPresence === "dnd" && !props.noPresenceBorder 
							? <span className="upi-presence-whitebar"></span>
							: null
					}
				</div>
				: <div
					className="upi-group-container-external"
					style={{
						width: (iconSize + 6) + "px",
						height: (iconSize + 6) + "px"
					}}
				>
					{props.pict?.jpegPhoto && props.pict?.jpegPhoto !== ""
						? <span
							style={{
								width: iconSize + "px",
								height: iconSize + "px",
								borderRadius: "50%",
								backgroundImage: "url(data:image/jpeg;base64," + props.pict.jpegPhoto + ")",
								backgroundSize: "contain"
							}}
							data-cy="upi-pict-container-span"
						/>
						: <FontAwesomeIcon
							className="upi-group-icon"
							transform={"shrink-8"}
							icon={faUsers}
							style={{
								width: (iconSize + 2) + "px",
								height: (iconSize + 2) + "px"
							}}
							data-cy="upi-group-icon-container-span"
						/>
					}
				</div>
			}
		</>
	);
}
