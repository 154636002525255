import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Typography } from "../../components/common/Typography/Typography";
import { grayBackground } from "../../theme/theme";

interface IJournalEntryMemoProps {
	memo: string;
}

export const JournalEntryMemo = ({ memo }: IJournalEntryMemoProps) => {
	const { t } = useTranslation();
	return (
		<Stack sx={{ borderRadius: "5px", backgroundColor: grayBackground, p: 1, whiteSpace: "pre-line" }}>
			<Typography variant="body2" sx={{ fontWeight: 600 }}>
				{t("IDS_CONTACT_DETAILS_MEMO")}
			</Typography>
			<Typography variant="body2">{memo}</Typography>
		</Stack>
	);
};
