import { BrowserDetector } from "../web-shared-components/helpers/browser-detector/BrowserDetector";
import { ELogLevel, IFilterConfigs } from "../web-shared-components/helpers/logger/ILogger";
import { Logger } from "../web-shared-components/helpers/logger/Logger";
import { TranslationManager } from "../web-shared-components/managers/translationManager";
import { IBaseSingletons } from "./interfaces/IBaseSingletons";
import AudioPlayer from "./lib/AudioPlayer";
import { LoggerCallback } from "./lib/LoggerCallback";
import { AVManager } from "./managers/AVManager";
import { ClientPersistenceManager } from "./managers/ClientPersistenceManager";
import { ContactManager } from "./managers/ContactManager/ContactManager";
import { CtiManager } from "./managers/CtiManager";
import { JournalManager } from "./managers/JournalManager";
import { OemManager } from "./managers/OemManager";
import { LoginController } from "./session/LoginController";
import { UCClient } from "./session/UCClient";

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		chrome: {
			webview: unknown;
		};
		webkitAudioContext: typeof AudioContext;
	}
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const IS_WEB_VIEW = false; // (window as Window).chrome.webview !== undefined;

// TODO: we may want to distinguish the log level in console, depending on the environment
// dev - stg - prod
const consoleLoggingFilter: IFilterConfigs = {
	warn: true,
	error: true,
	info: true,
	debug: true
};

export const theLogger = Logger.getInstance();
const logCallback = new LoggerCallback();
theLogger.addLogEntryCallback(logCallback, consoleLoggingFilter);
// TODO: we may also want to distinguish the general logs, depending on the environment
theLogger.init(ELogLevel.DEBUG, consoleLoggingFilter, false);
export const theBrowser = BrowserDetector.getInstance();
theBrowser.setLogger(theLogger);
// The singletons (managers, controllers) need to access to the basic singletons: theLogger, theBrowser
// To avoid circular dependencies, there's a base class that all the managers should extend.
const baseSingletons: IBaseSingletons = {
	browser: theBrowser,
	logger: theLogger
};

void theBrowser.init();
export const theLoginController = LoginController.getInstance(baseSingletons);
export const theUCClient = UCClient.getInstance(baseSingletons);
export const theJournalManager = JournalManager.getInstance(baseSingletons);
export const theContactManager = ContactManager.getInstance();
export const theCtiManager = CtiManager.getInstance(baseSingletons);
export const theAudioPlayer = AudioPlayer.getInstance(theBrowser);
export const theAVManager = AVManager.getInstance();
export const theClientPersistenceManager = ClientPersistenceManager.getInstance([
	"webview2-custom-labels",
	"webview2-contactdetails"
]);
export const theTranslationManager = TranslationManager.getInstance();
export const theOemManager = OemManager.getInstance(baseSingletons);
