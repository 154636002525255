import { useTranslation } from "react-i18next";

import { Typography } from "../common/Typography/Typography";

export const Placeholder = () => {
	const { t } = useTranslation();

	return (
		<Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
			{t("IDS_JOURNAL_LIST_EMPTY_PLACEHOLDER")}
		</Typography>
	);
};
