import { IBrowser, IHTMLAudioWithSinkID } from "../interfaces/IBrowserDetector";
import AudioMeter from "./AudioMeter";

interface ISounds {
	[key: string]: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type TSounds = "newChat" | "incomingCall" | "outgoingCall" | "callAccepted" | "callEnded";

interface IAudioPlayer {
	play(sound: TSounds, loop?: true, useAudioMeter?: true, getLevel?: () => void): Promise<void>;
	stop(): void;
	setSpkId(spkId: string): void;
}

/** Class holding the sounds to play. */
class AudioPlayer implements IAudioPlayer {
	// Key/value object for files associated to the sounds.
	private sounds: ISounds;
	// Source folder path.
	private srcFolder: string;
	// Speaker Id.
	private spkId: string;
	// Audio instance.
	private audio?: HTMLAudioElement;
	// Instance of class to use it as singleton.
	private static instance: AudioPlayer;
	// Instance of AudioMeter class in case it's used.
	public audioMeter: AudioMeter | undefined;
	// Instance of theBrowser
	private readonly browser: IBrowser;

	/**
	 * Create the audio holder defining the defaults.
	 *
	 * @param browser - instance of theBrowser
	 */
	private constructor(browser: IBrowser) {
		this.sounds = {
			newChat: "open-ended-563.mp3",
			incomingCall: "promise-616.mp3",
			outgoingCall: "serious-strike-533.mp3",
			callAccepted: "appointed-529.mp3",
			callEnded: "case-closed-531.mp3"
		};
		this.browser = browser;
		this.srcFolder = `../assets/sounds/`;
		this.spkId = "";
	}

	/**
	 * gets an instance of AudioPlayer as a singleton
	 *
	 * @param browser - instance of theBrowser
	 * @returns - an instance of the AudioPlayer
	 */
	public static getInstance(browser: IBrowser): AudioPlayer {
		if (!AudioPlayer.instance) {
			AudioPlayer.instance = new AudioPlayer(browser);
		}
		return AudioPlayer.instance;
	}

	/**
	 * AudioPlayer init method to test if the audio can be played.
	 *
	 */
	public init(): void {
		try {
			this.audio = new Audio();
			this.audio.pause();
			this.audio.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
			this.audio.currentTime = 0;
			this.audio.loop = false;
			this.audio
				.play()
				.then(() => {})
				.catch(() => {});
		} catch (ex) {}
	}

	/**
	 * Create a new audio element, set loop and sinkId, play it.
	 *
	 * @param sound - the filename of the sound to play
	 * @param loop - boolean if to be looped
	 * @param useAudioMeter - true if we want to use the audio meter
	 * @param getLevel - a function to pass to the audio meter get level
	 * and stop everything when needed.
	 */
	public async play(sound: TSounds, loop?: true, useAudioMeter?: true, getLevel?: () => void): Promise<void> {
		if (!this.audio) {
			this.audio = new Audio();
		}

		this.stop();
		this.audio.src = this.srcFolder + this.sounds[sound];

		this.audio.loop = !!loop;

		if (useAudioMeter) {
			this.audioMeter = AudioMeter.getInstance();
			this.audioMeter.setAudioElement(this.audio);
			this.audioMeter.getAudioLevel = getLevel;
		}

		const audioWithSinkId = this.audio as IHTMLAudioWithSinkID;
		if (this.browser.supports.sinkId) {
			try {
				audioWithSinkId
					.setSinkId(this.spkId)
					.then(() => {
						audioWithSinkId
							.play()
							.then(() => {})
							.catch((error) => {
								throw error;
							});
					})
					.catch(() => {});
				if (useAudioMeter && this.audioMeter) {
					this.audioMeter.start();
				}
			} catch (ex) {}
		} else {
			try {
				this.audio
					.play()
					.then(() => {})
					.catch(() => {});
				if (useAudioMeter && this.audioMeter) {
					this.audioMeter.start();
				}
			} catch (ex) {}
		}
	}

	/**
	 * Stops the audio.
	 */
	public stop(): void {
		this.audio?.pause();
	}

	/**
	 * Set the spkId (to use in setSinkId).
	 *
	 * @param spkId - the ID of the speaker as string
	 */
	public setSpkId(spkId: string): void {
		this.spkId = spkId;
	}
}

export default AudioPlayer;
