import { Helmet as ReactHelmet } from "react-helmet-async";

import { useStore } from "../../zustand/store";

export const Helmet = () => {
	const applicationName = useStore((state) => state.applicationName);

	return (
		<ReactHelmet>
			<meta charSet="utf-8" />
			<title>{applicationName}</title>
		</ReactHelmet>
	);
};
