import { Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/common/Button/Button";
import { Popper } from "../../components/common/Popper/Popper";
import { Typography } from "../../components/common/Typography/Typography";
import { theJournalManager } from "../../globals";
import { useStore } from "../../zustand/store";

interface IJournalEntryActionsProps {
	journalEntryID: string;
}

export const JournalEntryActions = ({ journalEntryID }: IJournalEntryActionsProps) => {
	const { t } = useTranslation();
	const journalEntry = useStore((state) => state.journalEntries.get(journalEntryID));
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const onOpenDeletePopper = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	if (!journalEntry) {
		return null;
	}

	const handleDelete = async () => {
		setAnchorEl(null);
		await theJournalManager.deleteJournalEntries([journalEntry?.u8sConnectionID]);
	};

	const handleUpdateReadFlags = async () => {
		await theJournalManager.updateJournalReadFlags([journalEntry?.u8sConnectionID], !journalEntry.iReadFlag);
	};

	return (
		<Stack ml={1} alignItems={"flex-start"}>
			<Button variant="text" onClick={handleUpdateReadFlags}>
				{journalEntry.iReadFlag ? t("IDS_JOURNAL_MENU_MARK_UNREAD") : t("IDS_JOURNAL_MENU_MARK_READ")}
			</Button>
			<Button variant="text" color="error" onClick={onOpenDeletePopper}>
				{t("IDS_JOURNAL_MENU_DELETE")}
			</Button>
			<Popper
				open={open}
				anchorEl={anchorEl}
				onClickAway={() => {
					setAnchorEl(null);
				}}
				sx={{ p: 2, pt: 1, mb: 1, minWidth: "180px" }}
			>
				<Stack>
					<Typography sx={{ pb: 1, textAlign: "center" }}>{t("IDS_JOURNAL_MENU_CONFIRM_TITLE")}</Typography>
					<Button color="error" onClick={handleDelete}>
						{t("IDS_DELETE")}
					</Button>
				</Stack>
			</Popper>
		</Stack>
	);
};
