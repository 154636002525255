import {
	AsnFindContactsArgument,
	AsnGetContactByEntryIDArgument
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetROSEInterface";
import {
	AsnFindContactsArgument_Converter,
	AsnGetContactByEntryIDArgument_Converter
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetROSEInterface_Converter";
import { AsnGetLoggedInContactArgument } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Auth";
import { AsnGetLoggedInContactArgument_Converter } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Auth_Converter";
import {
	AsnAVAlertArgument,
	AsnAVCallMessageArgument,
	AsnAVCallStateChangedArgument,
	AsnAVConnectArgument,
	AsnAVDropCallArgument,
	AsnAVMakeCallArgument,
	AsnAVNewCallArgument,
	AsnGetSTUNandTURNArgument
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_AV";
import {
	AsnAVCallMessageArgument_Converter,
	AsnAVCallStateChangedArgument_Converter,
	AsnAVConnectArgument_Converter,
	AsnAVDropCallArgument_Converter,
	AsnAVMakeCallArgument_Converter,
	AsnAVNewCallArgument_Converter,
	AsnGetSTUNandTURNArgument_Converter
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_AV_Converter";
import { AsnSetClientCapabilitiesV2Argument } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_ClientCapabilities";
import { AsnSetClientCapabilitiesV2Argument_Converter } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_ClientCapabilities_Converter";
import {
	AsnSubscribeClientContentArgument,
	AsnUnsubscribeClientContentArgument
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_ClientContent";
import {
	AsnSubscribeClientContentArgument_Converter,
	AsnUnsubscribeClientContentArgument_Converter
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_ClientContent_Converter";
import {
	AsnClientPersistenceEventArgument,
	AsnClientPersistenceReadArgument,
	AsnClientPersistenceSubscribeArgument
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_ClientPersistence";
import {
	AsnClientPersistenceReadArgument_Converter,
	AsnClientPersistenceSubscribeArgument_Converter
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_ClientPersistence_Converter";
import {
	AsnCtiEnumPhoneLinesArgument,
	AsnCtiEnumPhoneLinesResult,
	AsnCtiLineMonitorStartArgument,
	AsnCtiLineMonitorStartResult,
	AsnCtiLineMonitorStopArgument,
	AsnCtiLineSetDoNotDisturbArgument,
	AsnCtiLineSetRemoteOfficeArgument,
	AsnCtiNotifyLineAddRemoveArgument,
	AsnCtiNotifyLineDoNotDisturbChangedArgument,
	AsnCtiNotifyLineForwardingChangedArgument,
	AsnCtiNotifyLineInfoChangedArgument,
	AsnCtiNotifyLineRemoteOfficeChangedArgument,
	AsnCtiRemoveForwardArgument,
	AsnCtiSetForwardArgument
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_CTI";
import {
	AsnCtiEnumPhoneLinesArgument_Converter,
	AsnCtiLineMonitorStartArgument_Converter,
	AsnCtiLineMonitorStopArgument_Converter,
	AsnCtiLineSetDoNotDisturbArgument_Converter,
	AsnCtiLineSetRemoteOfficeArgument_Converter,
	AsnCtiRemoveForwardArgument_Converter,
	AsnCtiSetForwardArgument_Converter
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_CTI_Converter";
import {
	AsnDeleteJournalEntriesArgument,
	AsnGetJournalEntriesExArgument,
	AsnJournalEntryChangedListArgument,
	AsnJournalSubscribeEventsArgument,
	AsnJournalSubscribeEventsResult,
	AsnUpdateJournalMemoArgument,
	AsnUpdateJournalReadFlagArgument
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal";
import {
	AsnDeleteJournalEntriesArgument_Converter,
	AsnGetJournalEntriesExArgument_Converter,
	AsnJournalSubscribeEventsArgument_Converter,
	AsnUpdateJournalMemoArgument_Converter,
	AsnUpdateJournalReadFlagArgument_Converter
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal_Converter";
import {
	AsnGetAndSubscribePresenceArgument,
	AsnGetAndSubscribePresenceResult,
	AsnUpdatePresenceArgument
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_PresenceV2";
import { AsnGetAndSubscribePresenceArgument_Converter } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_PresenceV2_Converter";
import { ROSEError, ROSEReject } from "../../web-shared-components/asn1/EUCSrv/stubs/SNACCROSE";
import { ROSEMessage_Converter } from "../../web-shared-components/asn1/EUCSrv/stubs/SNACCROSE_Converter";
import { EError } from "../../web-shared-components/helpers/logger/ILogger";
import { theLogger } from "../globals";
import { IAVCallHandler } from "../interfaces/IHandlers";
import { IClientPersistenceHandler } from "../managers/ClientPersistenceManager";
import { IContactManagerHandler } from "../managers/ContactManager/ContactManager";
import { ICtiHandler } from "../managers/CtiManager";
import { IJournalHandler } from "../managers/JournalManager";
import { getState } from "../zustand/store";
import { IUCClientHandler } from "./UCClient";

/**
 * Socket transport manager for UCServer.
 */
export class SocketTransport {
	private ws?: WebSocket;
	private uccEndpoint?: string;
	private pingSent = 0;
	private pingPongInterval: NodeJS.Timeout | string | number | undefined;
	private static pingPongFrequency = 15000;
	private invokeId = 0;
	private static EVENT_INVOKEID = 99999;
	private static instance: SocketTransport;
	private journalHandler: IJournalHandler | undefined;
	private contactManagerHandler: IContactManagerHandler | undefined;
	private clientPersistenceHandler: IClientPersistenceHandler | undefined;
	private ctiHandler: ICtiHandler | undefined;
	private ucClientHandler: IUCClientHandler | undefined;
	private avCallHandler: IAVCallHandler | undefined;
	private responseCallBacks: Map<number, (result: unknown, invokeID?: number) => void> = new Map();

	/**
	 * Constructs a SocketTransport object
	 *
	 */
	private constructor() {}

	/**
	 * Gets instance of SocketTransport to use as singleton.
	 * @returns - an instance of this class.
	 */
	public static getInstance(): SocketTransport {
		if (!SocketTransport.instance) {
			SocketTransport.instance = new SocketTransport();
		}
		return SocketTransport.instance;
	}

	/**
	 * Set the journal handler
	 * @param journalHandler - the journal handler
	 */
	public setJournalHandler(journalHandler: IJournalHandler) {
		this.journalHandler = journalHandler;
	}

	/**
	 * Set the contact manager handler
	 * @param contactManagerHandler - the contact manager handler
	 */
	public setContactManagerHandler(contactManagerHandler: IContactManagerHandler) {
		this.contactManagerHandler = contactManagerHandler;
	}

	/**
	 * Set the UCClient handler
	 * @param ucClientHandler - the UCClient handler
	 */
	public setUCClientHandler(ucClientHandler: IUCClientHandler) {
		this.ucClientHandler = ucClientHandler;
	}

	/**
	 * Set the clientPersistence handler
	 * @param clientPersistenceHandler - the journal handler
	 */
	public setClientPersistenceHandler(clientPersistenceHandler: IClientPersistenceHandler) {
		this.clientPersistenceHandler = clientPersistenceHandler;
	}

	/**
	 * Set the clientPersistence handler
	 * @param ctiHandler - the cti handler
	 */
	public setCtiHandler(ctiHandler: ICtiHandler) {
		this.ctiHandler = ctiHandler;
	}

	/**
	 * Set the avCall handler
	 * @param avCallHandler - the avCall handler to set
	 */
	public setAVCallHandler(avCallHandler: IAVCallHandler) {
		this.avCallHandler = avCallHandler;
	}

	/**
	 * Sets the UCController endpoint.
	 * @param uri - a string with the UCController URI
	 */
	public setUCCEndpoint(uri: string): void {
		this.uccEndpoint = uri.replace("http://", "ws://").replace("https://", "wss://");
	}

	/**
	 * Opens a WebSocket with UCServer.
	 * @param sessionID - the session id returned from the create session operation.
	 * @returns - a promise with true if no errors.
	 */
	public async connect(sessionID: string): Promise<boolean | undefined | Error> {
		if (!this.uccEndpoint) {
			return;
		}
		const uri = !sessionID ? this.uccEndpoint : this.uccEndpoint + "/ws/client/websocket?x-ucsessionid=" + sessionID;
		return new Promise((resolve, reject) => {
			if (this.ws) {
				this.disconnect();
			}
			const connectSuccessHandler = () => {
				if (this.ws) {
					this.ws.removeEventListener("open", connectSuccessHandler);
					this.startPingPong();
				}
				resolve(true);
			};

			const connectFailureHandler = (e: Event) => {
				if (this.ws) {
					this.ws.removeEventListener("error", connectFailureHandler);
				}
				// this.logger.error("Error Event in UCS WebSocket", "connect connectFailureHandler", this, { errorEvent: e });
				reject(new Error("websocket connection error"));
			};

			try {
				this.ws = new WebSocket(uri);
				this.ws.addEventListener("open", connectSuccessHandler);
				this.ws.addEventListener("error", connectFailureHandler);
				this.ws.addEventListener("close", this.onCloseHandler.bind(this));
				this.ws.addEventListener("message", this.onMessageHandler.bind(this));
				this.ws.addEventListener("pong", this.onPongHandler.bind(this));
			} catch (e) {
				reject(e);
			}

			this.pingSent = 0;
		});
	}

	/**
	 * Get the socket ready state
	 * @returns - the web socket ready state
	 */
	public getSocketState(): number | undefined {
		return this.ws?.readyState;
	}

	/**
	 * Start Ping Pong
	 */
	private startPingPong() {
		if (this.pingPongInterval) {
			clearInterval(this.pingPongInterval);
		}
		this.pingPongInterval = setInterval(this.pingPong.bind(this), SocketTransport.pingPongFrequency);
	}

	/**
	 * Stop Ping Pong
	 */
	private stopPingPong() {
		clearInterval(this.pingPongInterval);
		this.pingPongInterval = undefined;
	}

	/**
	 * Ping Pong
	 */
	private pingPong() {
		if (!this.ws || this.ws.readyState !== 1 || this.pingSent > 2) {
			clearInterval(this.pingPongInterval);
			this.pingPongInterval = undefined;
			getState().smSetBanner("noServerConnection");
			// store.dispatch(contactSetAllPresencesToUnknown());
			this.ucClientHandler?.on_WebSocketClosed();
			return;
		}
		getState().smSetBanner(undefined);

		this.ws.send(JSON.stringify({ ping: "" }));

		this.pingSent++;
	}

	/**
	 * Increment invoke id, return it.
	 * It first searches if is there a sendQueue and gets the max invoke id from there.
	 * @returns - invoke id
	 */
	public getInvokeID(): number {
		const invokeIDs = getState().sendQueue.map((item) => item.invokeID);
		if (invokeIDs.length > 0) {
			this.invokeId = Math.max(...invokeIDs);
		}
		this.invokeId++;
		if (this.invokeId >= SocketTransport.EVENT_INVOKEID) {
			this.invokeId = 0;
		}
		return this.invokeId;
	}

	/**
	 * Sends an ASN command to UCServer via WebSocket.
	 * @param operationName - a string describing the asn command to send via websocket.
	 * @param argument - the argument to send to the server.
	 * @param callBack - optional callBack function for complex operations (eg: file transfer)
	 * @param queueInvokeID - option invoke ID in case it's set in the send queue
	 * @returns - true or an error
	 */
	public send(
		operationName: string,
		argument:
			| AsnSetClientCapabilitiesV2Argument
			| AsnGetAndSubscribePresenceArgument
			| AsnJournalSubscribeEventsArgument
			| AsnGetJournalEntriesExArgument
			| AsnFindContactsArgument
			| AsnUpdateJournalReadFlagArgument
			| AsnDeleteJournalEntriesArgument
			| AsnCtiEnumPhoneLinesArgument
			| AsnCtiLineMonitorStartArgument
			| AsnSubscribeClientContentArgument
			| AsnClientPersistenceReadArgument
			| AsnAVMakeCallArgument
			| AsnAVConnectArgument
			| AsnAVAlertArgument
			| AsnAVDropCallArgument
			| AsnAVCallMessageArgument
			| AsnAVNewCallArgument
			| AsnGetContactByEntryIDArgument
			| AsnAVCallStateChangedArgument
			| AsnCtiLineMonitorStopArgument
			| AsnCtiSetForwardArgument
			| AsnCtiRemoveForwardArgument,
		callBack?: (result: unknown, invokeID?: number) => void,
		queueInvokeID?: number
	): boolean | Error {
		let arg: unknown;
		let operationID;
		let noInvoke;

		// FIXME: operation id enums are not exported in the stubs
		switch (operationName) {
			case "asnSetClientCapabilitiesV2":
				arg = AsnSetClientCapabilitiesV2Argument_Converter.toJSON(argument as AsnSetClientCapabilitiesV2Argument);
				operationID = 3300; // OPID_asnSetClientCapabilitiesV2
				break;
			case "asnGetAndSubscribePresence":
				arg = AsnGetAndSubscribePresenceArgument_Converter.toJSON(argument as AsnGetAndSubscribePresenceArgument);
				operationID = 4502; // OPID_asnGetAndSubscribePresence = 4502
				break;
			case "asnUnsubscribePresence":
				arg = AsnUnsubscribeClientContentArgument_Converter.toJSON(argument as AsnUnsubscribeClientContentArgument);
				operationID = 4503; // OPID_asnUnsubscribePresence = 4503
				break;
			case "asnFindContacts":
				arg = AsnFindContactsArgument_Converter.toJSON(argument as AsnFindContactsArgument);
				operationID = 2022; // OPID_asnFindContacts = 2022
				break;
			case "asnGetContactByEntryID":
				arg = AsnGetContactByEntryIDArgument_Converter.toJSON(argument as AsnGetContactByEntryIDArgument);
				operationID = 2023; // OPID_asnFindContacts = 2022
				break;
			case "asnJournalSubscribeEvents":
				arg = AsnJournalSubscribeEventsArgument_Converter.toJSON(argument as AsnJournalSubscribeEventsArgument);
				operationID = 2171; // OPID_asnJournalSubscribeEvents
				break;
			case "asnGetJournalEntries":
				arg = AsnGetJournalEntriesExArgument_Converter.toJSON(argument as AsnGetJournalEntriesExArgument);
				operationID = 2106; // OPID_asnJournalSubscribeEvents
				break;
			case "asnSubscribeClientContent":
				arg = AsnSubscribeClientContentArgument_Converter.toJSON(argument as AsnSubscribeClientContentArgument);
				operationID = 4600; // OPID_asnSubscribeClientContent
				break;
			case "asnDeleteJournalEntries":
				arg = AsnDeleteJournalEntriesArgument_Converter.toJSON(argument as AsnDeleteJournalEntriesArgument);
				operationID = 2128; // OPID_asnDeleteJournalEntries
				break;
			case "asnUpdateJournalReadFlag":
				arg = AsnUpdateJournalReadFlagArgument_Converter.toJSON(argument as AsnUpdateJournalReadFlagArgument);
				operationID = 2114; // OPID_asnUpdateJournalReadFlag
				break;
			case "asnUpdateJournalMemo":
				arg = AsnUpdateJournalMemoArgument_Converter.toJSON(argument as AsnUpdateJournalMemoArgument);
				operationID = 2112; // OPID_asnUpdateJournalMemo
				break;
			case "asnClientPersistenceSubscribe":
				arg = AsnClientPersistenceSubscribeArgument_Converter.toJSON(argument as AsnClientPersistenceSubscribeArgument);
				operationID = 4305; // OPID_asnClientPersistenceSubscribe
				break;
			case "asnClientPersistenceRead":
				arg = AsnClientPersistenceReadArgument_Converter.toJSON(argument as AsnClientPersistenceReadArgument);
				operationID = 4302; // OPID_asnClientPersistenceRead
				break;
			case "asnGetLoggedInContact":
				arg = AsnGetLoggedInContactArgument_Converter.toJSON(argument as AsnGetLoggedInContactArgument);
				operationID = 1004; // OPID_asnGetLoggedInContact
				break;
			case "asnCtiEnumPhoneLines":
				arg = AsnCtiEnumPhoneLinesArgument_Converter.toJSON(argument as AsnCtiEnumPhoneLinesArgument);
				operationID = 1260; // OPID_asnCtiEnumPhoneLines
				break;
			case "asnCtiLineMonitorStart":
				arg = AsnCtiLineMonitorStartArgument_Converter.toJSON(argument as AsnCtiLineMonitorStartArgument);
				operationID = 1262; // OPID_asnCtiLineMonitorStart
				break;
			case "asnCtiLineMonitorStop":
				arg = AsnCtiLineMonitorStopArgument_Converter.toJSON(argument as AsnCtiLineMonitorStopArgument);
				operationID = 1263; // OPID_asnCtiLineMonitorStart
				break;
			case "asnCtiLineSetDoNotDisturb":
				arg = AsnCtiLineSetDoNotDisturbArgument_Converter.toJSON(argument as AsnCtiLineSetDoNotDisturbArgument);
				operationID = 1264; // OPID_asnCtiLineSetDoNotDisturb
				break;
			case "asnCtiLineSetRemoteOffice":
				arg = AsnCtiLineSetRemoteOfficeArgument_Converter.toJSON(argument as AsnCtiLineSetRemoteOfficeArgument);
				operationID = 1273; // OPID_asnCtiLineSetRemoteOffice
				break;
			case "asnCtiSetForward":
				arg = AsnCtiSetForwardArgument_Converter.toJSON(argument as AsnCtiSetForwardArgument);
				operationID = 1218; // OPID_asnCtiSetForward
				break;
			case "asnCtiRemoveForward":
				arg = AsnCtiRemoveForwardArgument_Converter.toJSON(argument as AsnCtiRemoveForwardArgument);
				operationID = 1219; // OPID_asnCtiRemoveForward
				break;
			case "asnGetSTUNandTURN":
				arg = AsnGetSTUNandTURNArgument_Converter.toJSON(argument as AsnGetSTUNandTURNArgument);
				operationID = 1557; // OPID_asnGetSTUNandTURN = 1557
				break;
			case "asnAVMakeCall":
				arg = AsnAVMakeCallArgument_Converter.toJSON(argument as AsnAVMakeCallArgument);
				operationID = 1500; // OPID_asnAVMakeCall = 1500
				break;
			case "asnAVConnect":
				arg = AsnAVConnectArgument_Converter.toJSON(argument as AsnAVConnectArgument);
				operationID = 1553; // OPID_asnAVConnect = 1553
				noInvoke = true;
				break;
			case "asnAVCallMessage":
				arg = AsnAVCallMessageArgument_Converter.toJSON(argument as AsnAVCallMessageArgument);
				operationID = 1552; // OPID_asnAVCallMessage = 1552
				noInvoke = true;
				break;
			case "asnAVAlert":
				arg = AsnAVNewCallArgument_Converter.toJSON(argument as AsnAVNewCallArgument);
				operationID = 1554; // OPID_asnAVAlert = 1554
				noInvoke = true;
				break;
			case "asnAVCallStateChanged":
				arg = AsnAVCallStateChangedArgument_Converter.toJSON(argument as AsnAVCallStateChangedArgument);
				operationID = 1551; // OPID_asnAVCallStateChanged = 1551
				noInvoke = true;
				break;
			case "asnAVDropCall":
				arg = AsnAVDropCallArgument_Converter.toJSON(argument as AsnAVDropCallArgument);
				operationID = 1501; // 	OPID_asnAVDropCall = 1501
				noInvoke = true;
				break;
			default:
				break;
		}

		if (arg === "" || operationID === undefined) {
			theLogger.error("Argument not supported", "send", { className: "SocketTransport" }, { arg, operationID });
			return new Error("Argument not supported");
		}

		let invokeID;
		if (noInvoke) {
			invokeID = 99999;
		} else {
			invokeID = queueInvokeID !== undefined ? queueInvokeID : this.getInvokeID();
		}

		const request = {
			invoke: {
				invokeID,
				operationID,
				operationName,
				argument
			}
		};

		const sendROSEArgument = ROSEMessage_Converter.toJSON(request);

		const sendData = JSON.stringify(sendROSEArgument);

		if (sendData === "") {
			theLogger.error(
				"Argument not well formatted",
				"send",
				{ className: "SocketTransport" },
				{ arg, operationID, sendData }
			);
			return new Error("Argument not well formatted");
		}

		if (callBack) {
			this.responseCallBacks.set(invokeID, callBack);
		}

		try {
			if (this.ws) {
				this.ws.send(sendData);
			} else {
				theLogger.error("WebSocket is undefined", "send", { className: "SocketTransport" }, { sendData });
				return new Error("WebSocket is undefined");
			}
			return true;
		} catch (e) {
			theLogger.error("Error creating WebSocket", "send", { className: "SocketTransport" }, { e });
			return e as Error;
		}
	}

	/**
	 * Handling the messages / events received from UCServer.
	 * @param msg - the message / event received from UCServer.
	 */
	private onMessageHandler(msg: MessageEvent) {
		let messages = JSON.parse(msg.data) as unknown;
		let latestEventID = 0;

		if (!(messages instanceof Array)) {
			messages = [messages];
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		for (const message of messages) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (message.pong === undefined) {
				console.log("on message: ", message);
			}
			let result: unknown;
			let callBack;

			// *** Direct result of ASN call ***

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (message.result) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				result = message.result.result ? message.result.result.result : null;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
				const invokeID = message.result.invokeID;

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const type: string | undefined = result._type;
				switch (type) {
					case "AsnJournalSubscribeEventsResult":
						if (this.journalHandler) {
							this.journalHandler.onResult_asnJournalSubscribeEvents(result as AsnJournalSubscribeEventsResult);
						}
						break;
					case "AsnCtiEnumPhoneLinesResult":
						if (this.ctiHandler) {
							this.ctiHandler.onResult_asnCtiPhoneLineSubscribeEvents(result as AsnCtiEnumPhoneLinesResult);
						}
						break;
					case "AsnCtiLineMonitorStartResult":
						if (this.ctiHandler) {
							this.ctiHandler.onResult_asnCtiLineMonitorStartResult(result as AsnCtiLineMonitorStartResult);
						}
						break;
					case "AsnGetAndSubscribePresenceResult":
						if (this.contactManagerHandler) {
							this.contactManagerHandler.onResult_asnGetAndSubscribePresence(
								result as AsnGetAndSubscribePresenceResult
							);
						}
						break;
					case "AsnGetJournalEntriesResult":
					case "AsnDeleteJournalEntriesResult":
					case "AsnUpdateJournalReadFlagResult":
					case "AsnUpdateJournalMemoResult":
					case "AsnClientPersistenceReadResult":
					case "AsnFindContactsResult":
					case "AsnGetContactByEntryIDResult":
					case "AsnUnsubscribePresenceResult":
					case "AsnGetLoggedInContactResult":
					case "AsnClientPersistenceSubscribeResult":
					case "AsnGetSTUNandTURNResult":
					case "AsnCtiLineMonitorStopResult":
					case "AsnCtiLineSetRemoteOfficeResult":
					case "AsnCtiSetForwardResult":
					case "AsnAVMakeCallResult":
						callBack = this.responseCallBacks.get(invokeID);
						if (callBack) {
							callBack(result, invokeID);
							this.responseCallBacks.delete(invokeID);
						}
						break;
					default:
						break;
				}

				let msg = "ASN request result";
				if (type !== undefined) {
					msg = type;
				}

				theLogger.debug(msg, "onMessageHandler", { className: "SocketTransport" }, message);

				// *** Call rejected ***

				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			} else if (message.reject) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				result = message.reject;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
				const invokeID = result.invokedID.invokedID;
				const error = new Error();
				if (result instanceof ROSEReject) {
					error.message = result.details || "unknown issue";
				} else {
					error.message = "unknown issue";
				}
				callBack = this.responseCallBacks.get(invokeID);
				if (callBack) {
					callBack(error, invokeID);
					this.responseCallBacks.delete(invokeID);
				} else {
					theLogger.error(
						error.message,
						"onMessageHandler",
						{ className: "SocketTransport" },
						{ result },
						EError.AsnErrorRequestReject
					);
				}
				// eslint-disable-next-line @typescript-eslint/brace-style
			}

			// *** Errors ***

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			else if (message.error) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				result = message.error;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const invokeID = result.invokedID;
				const error = new Error();
				if (result instanceof ROSEError) {
					error.message = "error code: " + result.error_value;

					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				} else if (result.error) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					error.message = result.error.u8sErrorString;
				} else {
					error.message = "unknown issue";
				}
				callBack = this.responseCallBacks.get(invokeID);
				if (callBack) {
					callBack(error, invokeID);
					this.responseCallBacks.delete(invokeID);
				} else {
					theLogger.error(
						error.message,
						"onMessageHandler",
						{ className: "SocketTransport" },
						{ result },
						EError.AsnErrorRequestResult
					);
				}
				// eslint-disable-next-line @typescript-eslint/brace-style
			}

			// *** Events received (if subscribed) ***

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			else if (message.invoke) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				result = message.invoke.argument;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				if (message.invoke.eventID > latestEventID) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access

					latestEventID = message.invoke.eventID;
				}

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				switch (result._type) {
					// Journal event
					case "AsnJournalEntryChangedListArgument":
						if (this.journalHandler) {
							this.journalHandler.onEvent_asnJournalEntryListChanged(result as AsnJournalEntryChangedListArgument);
						}
						break;
					case "AsnUpdatePresenceArgument":
						if (this.contactManagerHandler) {
							this.contactManagerHandler.onEvent_asnUpdatePresence(result as AsnUpdatePresenceArgument);
						}
						break;
					case "AsnClientPersistenceEventArgument":
						if (this.clientPersistenceHandler) {
							this.clientPersistenceHandler.onEvent_asnClientPersistenceEvent(
								result as AsnClientPersistenceEventArgument
							);
						}
						break;
					case "AsnCtiNotifyLineAddRemoveArgument":
						if (this.ctiHandler) {
							this.ctiHandler.onEvent_asnCtiNotifyLineAddRemove(result as AsnCtiNotifyLineAddRemoveArgument);
						}
						break;
					case "AsnCtiNotifyLineInfoChangedArgument":
						if (this.ctiHandler) {
							this.ctiHandler.onEvent_asnCtiNotifyLineInfoChanged(result as AsnCtiNotifyLineInfoChangedArgument);
						}
						break;
					case "AsnCtiNotifyLineDoNotDisturbChangedArgument":
						if (this.ctiHandler) {
							this.ctiHandler.onEvent_asnCtiNotifyLineDoNotDisturbChanged(
								result as AsnCtiNotifyLineDoNotDisturbChangedArgument
							);
						}
						break;
					case "AsnCtiNotifyLineRemoteOfficeChangedArgument":
						if (this.ctiHandler) {
							this.ctiHandler.onEvent_asnCtiNotifyLineRemoteOfficeChanged(
								result as AsnCtiNotifyLineRemoteOfficeChangedArgument
							);
						}
						break;
					case "AsnCtiNotifyLineForwardingChangedArgument":
						if (this.ctiHandler) {
							this.ctiHandler.onEvent_asnCtiNotifyLineForwardingChanged(
								result as AsnCtiNotifyLineForwardingChangedArgument
							);
						}
						break;
					// TODO: disabled for now, as it is not used
					// case "AsnAVNewCallArgument":
					// 	if (this.avCallHandler)
					// 		this.avCallHandler.onEvent_asnAVNewCall(result as AsnAVNewCallArgument);
					// 	break;
					// case "AsnAVConnectArgument":
					// 	if (this.avCallHandler)
					// 		this.avCallHandler.onEvent_asnAVConnect(result as AsnAVConnectArgument);
					// 	break;
					// case "AsnAVCallMessageArgument":
					// 	if (this.avCallHandler)
					// 		this.avCallHandler.onEvent_asnAVCallMessage(result as AsnAVCallMessageArgument);
					// 	break;
					// case "AsnAVCallStateChangedArgument":
					// 	if (this.avCallHandler)
					// 		this.avCallHandler.onEvent_asnAVCallStateChanged(result as AsnAVCallStateChangedArgument);
					// 	break;
					// case "AsnAVAlertArgument":
					// 	if (this.avCallHandler)
					// 		this.avCallHandler.onEvent_asnAVAlert(result as AsnAVAlertArgument);
					// 	break;
					// case "AsnAVDropCallArgument":
					// 	if (this.avCallHandler)
					// 		this.avCallHandler.onEvent_asnAVDropCall(result as AsnAVDropCallArgument);
					// 	break;
					// case "AsnSTUNandTURNConfigChangedArgument":
					// 	if (this.avCallHandler)
					// 		this.avCallHandler.onEvent_asnSTUNandTURNConfigChanged(result as AsnSTUNandTURNConfigChangedArgument);
					// 	break;
					default:
						break;
				}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			} else if (message.pong !== undefined) {
				this.pingSent = 0;
			}
		}
	}

	/**
	 * Listener for the close event of the socket.
	 */
	private onCloseHandler(): void {
		if (this.ucClientHandler) {
			this.ucClientHandler.on_WebSocketClosed();
		}
	}

	/**
	 * Listener for the error event of the socket.
	 * @param e - the error event received.
	 */
	private onErrorHandler(e: Event): void {
		// this.logger.error("WebSocket error", "onErrorHandler", this, { errorEvent: e });
	}

	/**
	 * Handles the pong event received via socket.
	 */
	private onPongHandler(): void {
		this.pingSent = 0;
	}

	/**
	 * Disconnects the WebSocket.
	 *
	 */
	private disconnect() {
		if (this.ws) {
			this.ws.removeEventListener("message", this.onMessageHandler);
			this.ws.removeEventListener("close", this.onCloseHandler);
			this.ws.removeEventListener("pong", this.onPongHandler);
			this.ws.onclose = () => {}; // disable onclose handler first
			this.ws.close();
		}
	}
}
