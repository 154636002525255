import { useScrollTrigger } from "@mui/material";
import React from "react";

interface IProps {
	children: React.ReactElement;
	target: HTMLElement | undefined;
	elevation?: number;
}

/**
 * This component is producing the elevation effect of the header when
 * scrolling in userlist and chat
 *
 * @param props - passed from the parent
 * @returns - the element itself
 */
export default function ElevationScroll(props: IProps): JSX.Element {
	const { children, target, elevation } = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: target || window
	});

	return React.cloneElement(children, {
		elevation: trigger ? elevation : 0
	});
}
