// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_Journal_Converter.ts
 * "UC-Server-Access-Protocol-Journal" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.23, 20.10.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_Journal from "./ENetUC_Journal";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";
// [PrintTSRootTypes]
export const moduleName = "ENetUC_Journal_Converter";

// [PrintTSEncoderDecoderCode]
export class AsnGetOfflineJournalArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnGetOfflineJournalArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnGetOfflineJournalArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetOfflineJournalArgument");

		const t = {} as ENetUC_Journal.AsnGetOfflineJournalArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetOfflineJournalArgument";
		TSConverter.fillJSONParam(s, t, "iMaxDays", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iMarkRead", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetOfflineJournalArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetOfflineJournalArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetOfflineJournalArgument");

		let t: ENetUC_Journal.AsnGetOfflineJournalArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnGetOfflineJournalArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnGetOfflineJournalArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iMaxDays", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iMarkRead", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetOfflineJournalArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnGetOfflineJournalArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetOfflineJournalArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetOfflineJournalArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iMaxDays", "number", errors, newContext);
		TSConverter.validateParam(s, "iMarkRead", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetOfflineJournalArgument")) {
			t.push(new asn1ts.Integer({ value: s.iMaxDays, name: "iMaxDays" }));
			t.push(new asn1ts.Integer({ value: s.iMarkRead, name: "iMarkRead" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetOfflineJournalArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetOfflineJournalArgument");

		let t: ENetUC_Journal.AsnGetOfflineJournalArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnGetOfflineJournalArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnGetOfflineJournalArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iMaxDays", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iMarkRead", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetOfflineJournalArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNetDatabaseJournal_Converter {
	public static toJSON(s: ENetUC_Journal.AsnNetDatabaseJournal, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnNetDatabaseJournal & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseJournal");

		const t = {} as ENetUC_Journal.AsnNetDatabaseJournal & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNetDatabaseJournal";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "stStartTime", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "stStopTime", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDurationTotal", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "stConnectTime", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "stDisconnectTime", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDurationConnected", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iOrigin", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iInternal", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLineNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLineName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLineUserName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iLineUserOffline", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLocalNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sRedirectingNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContactName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContactCompany", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContactEntryID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContactEntryStoreID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContactEntryDBID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCtiServerUserName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sProviderNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "rCharging", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iSucessState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sSubject", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMemo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iReadFlag", "number", errors, newContext);
		const _projectPinRule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.toJSON(s.projectPinRule, errors, newContext, "projectPinRule");
		if (_projectPinRule)
			t.projectPinRule = _projectPinRule;
		TSConverter.fillJSONParam(s, t, "u8sTrackerID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAcceptorName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAcceptorCtiServerUserName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAcceptorConnectionID", "string", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseJournal"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnNetDatabaseJournal | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseJournal");

		let t: ENetUC_Journal.AsnNetDatabaseJournal | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnNetDatabaseJournal>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnNetDatabaseJournal["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, context, false);
			if (TSConverter.validateParam(s, "stStartTime", "string", errors, context, false))
				t.stStartTime = new Date(s.stStartTime);
			if (TSConverter.validateParam(s, "stStopTime", "string", errors, context, false))
				t.stStopTime = new Date(s.stStopTime);
			TSConverter.fillJSONParam(s, t, "iDurationTotal", "number", errors, context, false);
			if (TSConverter.validateParam(s, "stConnectTime", "string", errors, context, false))
				t.stConnectTime = new Date(s.stConnectTime);
			if (TSConverter.validateParam(s, "stDisconnectTime", "string", errors, context, false))
				t.stDisconnectTime = new Date(s.stDisconnectTime);
			TSConverter.fillJSONParam(s, t, "iDurationConnected", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iOrigin", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iInternal", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLineNumber", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLineName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLineUserName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iLineUserOffline", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLocalNumber", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sRedirectingNumber", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sContactName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sContactCompany", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sContactEntryID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sContactEntryStoreID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sContactEntryDBID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sCtiServerUserName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sProviderNumber", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "rCharging", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iSucessState", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sSubject", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sMemo", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iReadFlag", "number", errors, context, false);
			const _projectpinrule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.fromJSON(s.projectPinRule, errors, newContext, "projectPinRule", false);
			if (_projectpinrule)
				t.projectPinRule = _projectpinrule;
			TSConverter.fillJSONParam(s, t, "u8sTrackerID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sAcceptorName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sAcceptorCtiServerUserName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sAcceptorConnectionID", "string", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnNetDatabaseJournal"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnNetDatabaseJournal | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNetDatabaseJournal";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseJournal");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "stStartTime", "Date", errors, newContext);
		TSConverter.validateParam(s, "stStopTime", "Date", errors, newContext);
		TSConverter.validateParam(s, "iDurationTotal", "number", errors, newContext);
		TSConverter.validateParam(s, "stConnectTime", "Date", errors, newContext);
		TSConverter.validateParam(s, "stDisconnectTime", "Date", errors, newContext);
		TSConverter.validateParam(s, "iDurationConnected", "number", errors, newContext);
		TSConverter.validateParam(s, "iOrigin", "number", errors, newContext);
		TSConverter.validateParam(s, "iInternal", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sLineNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLineName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLineUserName", "string", errors, newContext);
		TSConverter.validateParam(s, "iLineUserOffline", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sLocalNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sRedirectingNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContactName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContactCompany", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContactEntryID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContactEntryStoreID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContactEntryDBID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCtiServerUserName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sProviderNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "rCharging", "number", errors, newContext);
		TSConverter.validateParam(s, "iSucessState", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sSubject", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sMemo", "string", errors, newContext);
		TSConverter.validateParam(s, "iReadFlag", "number", errors, newContext);
		const _projectPinRule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.toBER(s.projectPinRule, errors, newContext, "projectPinRule");
		TSConverter.validateParam(s, "u8sTrackerID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sAcceptorName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sAcceptorCtiServerUserName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sAcceptorConnectionID", "string", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnNetDatabaseJournal")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stStartTime), name: "stStartTime" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stStopTime), name: "stStopTime" }));
			t.push(new asn1ts.Integer({ value: s.iDurationTotal, name: "iDurationTotal" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stConnectTime), name: "stConnectTime" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stDisconnectTime), name: "stDisconnectTime" }));
			t.push(new asn1ts.Integer({ value: s.iDurationConnected, name: "iDurationConnected" }));
			t.push(new asn1ts.Integer({ value: s.iOrigin, name: "iOrigin" }));
			t.push(new asn1ts.Integer({ value: s.iInternal, name: "iInternal" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLineNumber, name: "u8sLineNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLineName, name: "u8sLineName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLineUserName, name: "u8sLineUserName" }));
			t.push(new asn1ts.Integer({ value: s.iLineUserOffline, name: "iLineUserOffline" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLocalNumber, name: "u8sLocalNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumber, name: "u8sPhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sRedirectingNumber, name: "u8sRedirectingNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContactName, name: "u8sContactName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContactCompany, name: "u8sContactCompany" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContactEntryID, name: "u8sContactEntryID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContactEntryStoreID, name: "u8sContactEntryStoreID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContactEntryDBID, name: "u8sContactEntryDBID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCtiServerUserName, name: "u8sCtiServerUserName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sProviderNumber, name: "u8sProviderNumber" }));
			t.push(new asn1ts.Real({ value: s.rCharging, name: "rCharging" }));
			t.push(new asn1ts.Integer({ value: s.iSucessState, name: "iSucessState" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sSubject, name: "u8sSubject" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sMemo, name: "u8sMemo" }));
			t.push(new asn1ts.Integer({ value: s.iReadFlag, name: "iReadFlag" }));
			if (_projectPinRule)
				t.push(_projectPinRule);
			t.push(new asn1ts.Utf8String({ value: s.u8sTrackerID, name: "u8sTrackerID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAcceptorName, name: "u8sAcceptorName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAcceptorCtiServerUserName, name: "u8sAcceptorCtiServerUserName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAcceptorConnectionID, name: "u8sAcceptorConnectionID" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnNetDatabaseJournal | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseJournal");

		let t: ENetUC_Journal.AsnNetDatabaseJournal | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnNetDatabaseJournal.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnNetDatabaseJournal["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "stStartTime", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "stStopTime", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDurationTotal", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "stConnectTime", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "stDisconnectTime", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDurationConnected", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iOrigin", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iInternal", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLineNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLineName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLineUserName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iLineUserOffline", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLocalNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sRedirectingNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContactName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContactCompany", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContactEntryID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContactEntryStoreID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContactEntryDBID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCtiServerUserName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sProviderNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "rCharging", "Real", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iSucessState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sSubject", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMemo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iReadFlag", "Integer", errors, newContext);
			const _projectpinrule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "projectPinRule"), errors, newContext, "projectPinRule");
			if (_projectpinrule)
				t.projectPinRule = _projectpinrule;
			TSConverter.fillASN1Param(s, t, "u8sTrackerID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAcceptorName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAcceptorCtiServerUserName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAcceptorConnectionID", "Utf8String", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseJournal"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNetDatabaseJournalFindOptionsEx_Converter {
	public static toJSON(s: ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseJournalFindOptionsEx");

		const t = {} as ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNetDatabaseJournalFindOptionsEx";
		TSConverter.fillJSONParam(s, t, "stStartTimeFrom", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "stStartTimeTo", "Date", errors, newContext);
		const _u8slistLineUserNames = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8slistLineUserNames, errors, newContext, "u8slistLineUserNames");
		if (_u8slistLineUserNames)
			t.u8slistLineUserNames = _u8slistLineUserNames;
		const _u8slistPhoneNumber = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8slistPhoneNumber, errors, newContext, "u8slistPhoneNumber");
		if (_u8slistPhoneNumber)
			t.u8slistPhoneNumber = _u8slistPhoneNumber;
		TSConverter.fillJSONParam(s, t, "u8sContactName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCompanyName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sFilterText", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iMaxNumEntries", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iLastKnownGlobTransactionID", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iFilter", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnNetDatabaseJournalFindOptionsEx"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseJournalFindOptionsEx");

		let t: ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			if (TSConverter.validateParam(s, "stStartTimeFrom", "string", errors, context, false))
				t.stStartTimeFrom = new Date(s.stStartTimeFrom);
			if (TSConverter.validateParam(s, "stStartTimeTo", "string", errors, context, false))
				t.stStartTimeTo = new Date(s.stStartTimeTo);
			const _u8slistlineusernames = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8slistLineUserNames, errors, newContext, "u8slistLineUserNames", false);
			if (_u8slistlineusernames)
				t.u8slistLineUserNames = _u8slistlineusernames;
			const _u8slistphonenumber = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8slistPhoneNumber, errors, newContext, "u8slistPhoneNumber", false);
			if (_u8slistphonenumber)
				t.u8slistPhoneNumber = _u8slistphonenumber;
			TSConverter.fillJSONParam(s, t, "u8sContactName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sCompanyName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sFilterText", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iMaxNumEntries", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iLastKnownGlobTransactionID", "number", errors, context, true);
			TSConverter.fillJSONParam(s, t, "iFilter", "number", errors, context, true);
		}
		if (errors.validateResult(newContext, "AsnNetDatabaseJournalFindOptionsEx"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNetDatabaseJournalFindOptionsEx";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseJournalFindOptionsEx");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "stStartTimeFrom", "Date", errors, newContext);
		TSConverter.validateParam(s, "stStartTimeTo", "Date", errors, newContext);
		const _u8slistLineUserNames = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8slistLineUserNames, errors, newContext, "u8slistLineUserNames");
		const _u8slistPhoneNumber = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8slistPhoneNumber, errors, newContext, "u8slistPhoneNumber");
		TSConverter.validateParam(s, "u8sContactName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCompanyName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sFilterText", "string", errors, newContext);
		TSConverter.validateParam(s, "iMaxNumEntries", "number", errors, newContext);
		TSConverter.validateParam(s, "iLastKnownGlobTransactionID", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iFilter", "number", errors, newContext, true);
		if (errors.validateResult(newContext, "AsnNetDatabaseJournalFindOptionsEx")) {
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stStartTimeFrom), name: "stStartTimeFrom" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stStartTimeTo), name: "stStartTimeTo" }));
			if (_u8slistLineUserNames)
				t.push(_u8slistLineUserNames);
			if (_u8slistPhoneNumber)
				t.push(_u8slistPhoneNumber);
			t.push(new asn1ts.Utf8String({ value: s.u8sContactName, name: "u8sContactName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCompanyName, name: "u8sCompanyName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sFilterText, name: "u8sFilterText" }));
			t.push(new asn1ts.Integer({ value: s.iMaxNumEntries, name: "iMaxNumEntries" }));
			if (s.iLastKnownGlobTransactionID !== undefined)
				t.push(new asn1ts.Integer({ value: s.iLastKnownGlobTransactionID, name: "iLastKnownGlobTransactionID", idBlock: { optionalID: 0 } }));
			if (s.iFilter !== undefined)
				t.push(new asn1ts.Integer({ value: s.iFilter, name: "iFilter", idBlock: { optionalID: 1 } }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseJournalFindOptionsEx");

		let t: ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnNetDatabaseJournalFindOptionsEx["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "stStartTimeFrom", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "stStartTimeTo", "AsnSystemTime", errors, newContext);
			const _u8slistlineusernames = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8slistLineUserNames"), errors, newContext, "u8slistLineUserNames");
			if (_u8slistlineusernames)
				t.u8slistLineUserNames = _u8slistlineusernames;
			const _u8slistphonenumber = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8slistPhoneNumber"), errors, newContext, "u8slistPhoneNumber");
			if (_u8slistphonenumber)
				t.u8slistPhoneNumber = _u8slistphonenumber;
			TSConverter.fillASN1Param(s, t, "u8sContactName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCompanyName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sFilterText", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iMaxNumEntries", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iLastKnownGlobTransactionID", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iFilter", "Integer", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseJournalFindOptionsEx"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateCallAndJournalContactAndNumberArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateCallAndJournalContactAndNumberArgument");

		const t = {} as ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateCallAndJournalContactAndNumberArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sRemoteNumberSC", "string", errors, newContext);
		const _remoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.remoteContact, errors, newContext, "remoteContact");
		if (_remoteContact)
			t.remoteContact = _remoteContact;

		if (errors.validateResult(newContext, "AsnUpdateCallAndJournalContactAndNumberArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateCallAndJournalContactAndNumberArgument");

		let t: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sRemoteNumberSC", "string", errors, context, false);
			const _remotecontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.remoteContact, errors, newContext, "remoteContact", false);
			if (_remotecontact)
				t.remoteContact = _remotecontact;
		}
		if (errors.validateResult(newContext, "AsnUpdateCallAndJournalContactAndNumberArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateCallAndJournalContactAndNumberArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateCallAndJournalContactAndNumberArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sRemoteNumberSC", "string", errors, newContext);
		const _remoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.remoteContact, errors, newContext, "remoteContact");
		if (errors.validateResult(newContext, "AsnUpdateCallAndJournalContactAndNumberArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sRemoteNumberSC, name: "u8sRemoteNumberSC" }));
			if (_remoteContact)
				t.push(_remoteContact);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateCallAndJournalContactAndNumberArgument");

		let t: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sRemoteNumberSC", "Utf8String", errors, newContext);
			const _remotecontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "remoteContact"), errors, newContext, "remoteContact");
			if (_remotecontact)
				t.remoteContact = _remotecontact;
		}

		if (errors.validateResult(newContext, "AsnUpdateCallAndJournalContactAndNumberArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateCallAndJournalContactAndNumberResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateCallAndJournalContactAndNumberResult");

		const t = {} as ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateCallAndJournalContactAndNumberResult";
		TSConverter.fillJSONParam(s, t, "bResultCallInfoUpdate", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bResultJournalUpdate", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdateCallAndJournalContactAndNumberResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateCallAndJournalContactAndNumberResult");

		let t: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bResultCallInfoUpdate", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bResultJournalUpdate", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUpdateCallAndJournalContactAndNumberResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateCallAndJournalContactAndNumberResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateCallAndJournalContactAndNumberResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bResultCallInfoUpdate", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bResultJournalUpdate", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnUpdateCallAndJournalContactAndNumberResult")) {
			t.push(new asn1ts.Boolean({ value: s.bResultCallInfoUpdate, name: "bResultCallInfoUpdate" }));
			t.push(new asn1ts.Boolean({ value: s.bResultJournalUpdate, name: "bResultJournalUpdate" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateCallAndJournalContactAndNumberResult");

		let t: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bResultCallInfoUpdate", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bResultJournalUpdate", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdateCallAndJournalContactAndNumberResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateCallLocalContactArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateCallLocalContactArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateCallLocalContactArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateCallLocalContactArgument");

		const t = {} as ENetUC_Journal.AsnUpdateCallLocalContactArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateCallLocalContactArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);
		const _localContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.localContact, errors, newContext, "localContact");
		if (_localContact)
			t.localContact = _localContact;

		if (errors.validateResult(newContext, "AsnUpdateCallLocalContactArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateCallLocalContactArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateCallLocalContactArgument");

		let t: ENetUC_Journal.AsnUpdateCallLocalContactArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateCallLocalContactArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateCallLocalContactArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, context, false);
			const _localcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.localContact, errors, newContext, "localContact", false);
			if (_localcontact)
				t.localContact = _localcontact;
		}
		if (errors.validateResult(newContext, "AsnUpdateCallLocalContactArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateCallLocalContactArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateCallLocalContactArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateCallLocalContactArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		const _localContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.localContact, errors, newContext, "localContact");
		if (errors.validateResult(newContext, "AsnUpdateCallLocalContactArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			if (_localContact)
				t.push(_localContact);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateCallLocalContactArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateCallLocalContactArgument");

		let t: ENetUC_Journal.AsnUpdateCallLocalContactArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateCallLocalContactArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateCallLocalContactArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
			const _localcontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "localContact"), errors, newContext, "localContact");
			if (_localcontact)
				t.localContact = _localcontact;
		}

		if (errors.validateResult(newContext, "AsnUpdateCallLocalContactArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateJournalMemoArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateJournalMemoArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateJournalMemoArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalMemoArgument");

		const t = {} as ENetUC_Journal.AsnUpdateJournalMemoArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateJournalMemoArgument";
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		if (_u8sConnectionIDList)
			t.u8sConnectionIDList = _u8sConnectionIDList;
		TSConverter.fillJSONParam(s, t, "u8sMemo", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdateJournalMemoArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalMemoArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalMemoArgument");

		let t: ENetUC_Journal.AsnUpdateJournalMemoArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateJournalMemoArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateJournalMemoArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList", false);
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
			TSConverter.fillJSONParam(s, t, "u8sMemo", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUpdateJournalMemoArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateJournalMemoArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateJournalMemoArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalMemoArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		TSConverter.validateParam(s, "u8sMemo", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnUpdateJournalMemoArgument")) {
			if (_u8sConnectionIDList)
				t.push(_u8sConnectionIDList);
			t.push(new asn1ts.Utf8String({ value: s.u8sMemo, name: "u8sMemo" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalMemoArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalMemoArgument");

		let t: ENetUC_Journal.AsnUpdateJournalMemoArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateJournalMemoArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateJournalMemoArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sConnectionIDList"), errors, newContext, "u8sConnectionIDList");
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
			TSConverter.fillASN1Param(s, t, "u8sMemo", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalMemoArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateJournalMemoResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateJournalMemoResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateJournalMemoResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalMemoResult");

		const t = {} as ENetUC_Journal.AsnUpdateJournalMemoResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateJournalMemoResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdateJournalMemoResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalMemoResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalMemoResult");

		let t: ENetUC_Journal.AsnUpdateJournalMemoResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateJournalMemoResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateJournalMemoResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUpdateJournalMemoResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateJournalMemoResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateJournalMemoResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalMemoResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnUpdateJournalMemoResult")) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalMemoResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalMemoResult");

		let t: ENetUC_Journal.AsnUpdateJournalMemoResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateJournalMemoResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateJournalMemoResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalMemoResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateJournalProjectArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateJournalProjectArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateJournalProjectArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalProjectArgument");

		const t = {} as ENetUC_Journal.AsnUpdateJournalProjectArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateJournalProjectArgument";
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		if (_u8sConnectionIDList)
			t.u8sConnectionIDList = _u8sConnectionIDList;
		const _projectPinRule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.toJSON(s.projectPinRule, errors, newContext, "projectPinRule");
		if (_projectPinRule)
			t.projectPinRule = _projectPinRule;

		if (errors.validateResult(newContext, "AsnUpdateJournalProjectArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalProjectArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalProjectArgument");

		let t: ENetUC_Journal.AsnUpdateJournalProjectArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateJournalProjectArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateJournalProjectArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList", false);
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
			const _projectpinrule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.fromJSON(s.projectPinRule, errors, newContext, "projectPinRule", false);
			if (_projectpinrule)
				t.projectPinRule = _projectpinrule;
		}
		if (errors.validateResult(newContext, "AsnUpdateJournalProjectArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateJournalProjectArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateJournalProjectArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalProjectArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		const _projectPinRule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.toBER(s.projectPinRule, errors, newContext, "projectPinRule");
		if (errors.validateResult(newContext, "AsnUpdateJournalProjectArgument")) {
			if (_u8sConnectionIDList)
				t.push(_u8sConnectionIDList);
			if (_projectPinRule)
				t.push(_projectPinRule);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalProjectArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalProjectArgument");

		let t: ENetUC_Journal.AsnUpdateJournalProjectArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateJournalProjectArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateJournalProjectArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sConnectionIDList"), errors, newContext, "u8sConnectionIDList");
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
			const _projectpinrule = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "projectPinRule"), errors, newContext, "projectPinRule");
			if (_projectpinrule)
				t.projectPinRule = _projectpinrule;
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalProjectArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateJournalProjectResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateJournalProjectResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateJournalProjectResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalProjectResult");

		const t = {} as ENetUC_Journal.AsnUpdateJournalProjectResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateJournalProjectResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdateJournalProjectResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalProjectResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalProjectResult");

		let t: ENetUC_Journal.AsnUpdateJournalProjectResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateJournalProjectResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateJournalProjectResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUpdateJournalProjectResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateJournalProjectResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateJournalProjectResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalProjectResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnUpdateJournalProjectResult")) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalProjectResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalProjectResult");

		let t: ENetUC_Journal.AsnUpdateJournalProjectResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateJournalProjectResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateJournalProjectResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalProjectResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateJournalReadFlagArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateJournalReadFlagArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateJournalReadFlagArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalReadFlagArgument");

		const t = {} as ENetUC_Journal.AsnUpdateJournalReadFlagArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateJournalReadFlagArgument";
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		if (_u8sConnectionIDList)
			t.u8sConnectionIDList = _u8sConnectionIDList;
		TSConverter.fillJSONParam(s, t, "bReadFlag", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdateJournalReadFlagArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalReadFlagArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalReadFlagArgument");

		let t: ENetUC_Journal.AsnUpdateJournalReadFlagArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateJournalReadFlagArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateJournalReadFlagArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList", false);
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
			TSConverter.fillJSONParam(s, t, "bReadFlag", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUpdateJournalReadFlagArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateJournalReadFlagArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateJournalReadFlagArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalReadFlagArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		TSConverter.validateParam(s, "bReadFlag", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnUpdateJournalReadFlagArgument")) {
			if (_u8sConnectionIDList)
				t.push(_u8sConnectionIDList);
			t.push(new asn1ts.Boolean({ value: s.bReadFlag, name: "bReadFlag" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalReadFlagArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalReadFlagArgument");

		let t: ENetUC_Journal.AsnUpdateJournalReadFlagArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateJournalReadFlagArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateJournalReadFlagArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sConnectionIDList"), errors, newContext, "u8sConnectionIDList");
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
			TSConverter.fillASN1Param(s, t, "bReadFlag", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalReadFlagArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateJournalReadFlagResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateJournalReadFlagResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateJournalReadFlagResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalReadFlagResult");

		const t = {} as ENetUC_Journal.AsnUpdateJournalReadFlagResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateJournalReadFlagResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdateJournalReadFlagResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalReadFlagResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalReadFlagResult");

		let t: ENetUC_Journal.AsnUpdateJournalReadFlagResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateJournalReadFlagResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateJournalReadFlagResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUpdateJournalReadFlagResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateJournalReadFlagResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateJournalReadFlagResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalReadFlagResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnUpdateJournalReadFlagResult")) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalReadFlagResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalReadFlagResult");

		let t: ENetUC_Journal.AsnUpdateJournalReadFlagResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateJournalReadFlagResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateJournalReadFlagResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalReadFlagResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateJournalSubjectArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateJournalSubjectArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateJournalSubjectArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalSubjectArgument");

		const t = {} as ENetUC_Journal.AsnUpdateJournalSubjectArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateJournalSubjectArgument";
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		if (_u8sConnectionIDList)
			t.u8sConnectionIDList = _u8sConnectionIDList;
		TSConverter.fillJSONParam(s, t, "u8sSubject", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdateJournalSubjectArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalSubjectArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalSubjectArgument");

		let t: ENetUC_Journal.AsnUpdateJournalSubjectArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateJournalSubjectArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateJournalSubjectArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList", false);
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
			TSConverter.fillJSONParam(s, t, "u8sSubject", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUpdateJournalSubjectArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateJournalSubjectArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateJournalSubjectArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalSubjectArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		TSConverter.validateParam(s, "u8sSubject", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnUpdateJournalSubjectArgument")) {
			if (_u8sConnectionIDList)
				t.push(_u8sConnectionIDList);
			t.push(new asn1ts.Utf8String({ value: s.u8sSubject, name: "u8sSubject" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalSubjectArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalSubjectArgument");

		let t: ENetUC_Journal.AsnUpdateJournalSubjectArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateJournalSubjectArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateJournalSubjectArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sConnectionIDList"), errors, newContext, "u8sConnectionIDList");
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
			TSConverter.fillASN1Param(s, t, "u8sSubject", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalSubjectArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateJournalSubjectResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateJournalSubjectResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateJournalSubjectResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalSubjectResult");

		const t = {} as ENetUC_Journal.AsnUpdateJournalSubjectResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateJournalSubjectResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnUpdateJournalSubjectResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalSubjectResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalSubjectResult");

		let t: ENetUC_Journal.AsnUpdateJournalSubjectResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateJournalSubjectResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateJournalSubjectResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUpdateJournalSubjectResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateJournalSubjectResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateJournalSubjectResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalSubjectResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnUpdateJournalSubjectResult")) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalSubjectResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalSubjectResult");

		let t: ENetUC_Journal.AsnUpdateJournalSubjectResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateJournalSubjectResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateJournalSubjectResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalSubjectResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetJournalEntriesCountResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnGetJournalEntriesCountResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnGetJournalEntriesCountResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetJournalEntriesCountResult");

		const t = {} as ENetUC_Journal.AsnGetJournalEntriesCountResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetJournalEntriesCountResult";
		TSConverter.fillJSONParam(s, t, "iCount", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetJournalEntriesCountResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetJournalEntriesCountResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetJournalEntriesCountResult");

		let t: ENetUC_Journal.AsnGetJournalEntriesCountResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnGetJournalEntriesCountResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnGetJournalEntriesCountResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iCount", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetJournalEntriesCountResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnGetJournalEntriesCountResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetJournalEntriesCountResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetJournalEntriesCountResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iCount", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetJournalEntriesCountResult")) {
			t.push(new asn1ts.Integer({ value: s.iCount, name: "iCount" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetJournalEntriesCountResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetJournalEntriesCountResult");

		let t: ENetUC_Journal.AsnGetJournalEntriesCountResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnGetJournalEntriesCountResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnGetJournalEntriesCountResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iCount", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetJournalEntriesCountResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJournalEntryGetArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnJournalEntryGetArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnJournalEntryGetArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryGetArgument");

		const t = {} as ENetUC_Journal.AsnJournalEntryGetArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJournalEntryGetArgument";
		TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnJournalEntryGetArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryGetArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryGetArgument");

		let t: ENetUC_Journal.AsnJournalEntryGetArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnJournalEntryGetArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnJournalEntryGetArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sConnectionID", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnJournalEntryGetArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnJournalEntryGetArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJournalEntryGetArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryGetArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sConnectionID", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnJournalEntryGetArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sConnectionID, name: "u8sConnectionID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryGetArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryGetArgument");

		let t: ENetUC_Journal.AsnJournalEntryGetArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnJournalEntryGetArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnJournalEntryGetArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sConnectionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnJournalEntryGetArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJournalSubscribeEventsArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnJournalSubscribeEventsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnJournalSubscribeEventsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalSubscribeEventsArgument");

		const t = {} as ENetUC_Journal.AsnJournalSubscribeEventsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJournalSubscribeEventsArgument";
		TSConverter.fillJSONParam(s, t, "bReceiveJournalEvents", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iLastKnownGlobTransactionID", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iMaxEntries", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnJournalSubscribeEventsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalSubscribeEventsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalSubscribeEventsArgument");

		let t: ENetUC_Journal.AsnJournalSubscribeEventsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnJournalSubscribeEventsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnJournalSubscribeEventsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bReceiveJournalEvents", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iLastKnownGlobTransactionID", "number", errors, context, true);
			TSConverter.fillJSONParam(s, t, "iMaxEntries", "number", errors, context, true);
			TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, context, true);
		}
		if (errors.validateResult(newContext, "AsnJournalSubscribeEventsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnJournalSubscribeEventsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJournalSubscribeEventsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalSubscribeEventsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bReceiveJournalEvents", "boolean", errors, newContext);
		TSConverter.validateParam(s, "iLastKnownGlobTransactionID", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iMaxEntries", "number", errors, newContext, true);
		TSConverter.validateParam(s, "iFlags", "number", errors, newContext, true);
		if (errors.validateResult(newContext, "AsnJournalSubscribeEventsArgument")) {
			t.push(new asn1ts.Boolean({ value: s.bReceiveJournalEvents, name: "bReceiveJournalEvents" }));
			if (s.iLastKnownGlobTransactionID !== undefined)
				t.push(new asn1ts.Integer({ value: s.iLastKnownGlobTransactionID, name: "iLastKnownGlobTransactionID", idBlock: { optionalID: 0 } }));
			if (s.iMaxEntries !== undefined)
				t.push(new asn1ts.Integer({ value: s.iMaxEntries, name: "iMaxEntries", idBlock: { optionalID: 1 } }));
			if (s.iFlags !== undefined)
				t.push(new asn1ts.Integer({ value: s.iFlags, name: "iFlags", idBlock: { optionalID: 2 } }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalSubscribeEventsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalSubscribeEventsArgument");

		let t: ENetUC_Journal.AsnJournalSubscribeEventsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnJournalSubscribeEventsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnJournalSubscribeEventsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bReceiveJournalEvents", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iLastKnownGlobTransactionID", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iMaxEntries", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iFlags", "Integer", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnJournalSubscribeEventsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJournalSubscribeEventsResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnJournalSubscribeEventsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnJournalSubscribeEventsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalSubscribeEventsResult");

		const t = {} as ENetUC_Journal.AsnJournalSubscribeEventsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJournalSubscribeEventsResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnJournalSubscribeEventsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalSubscribeEventsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalSubscribeEventsResult");

		let t: ENetUC_Journal.AsnJournalSubscribeEventsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnJournalSubscribeEventsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnJournalSubscribeEventsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnJournalSubscribeEventsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnJournalSubscribeEventsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJournalSubscribeEventsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalSubscribeEventsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnJournalSubscribeEventsResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalSubscribeEventsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalSubscribeEventsResult");

		let t: ENetUC_Journal.AsnJournalSubscribeEventsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnJournalSubscribeEventsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnJournalSubscribeEventsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnJournalSubscribeEventsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJournalGetSettingsArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnJournalGetSettingsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnJournalGetSettingsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalGetSettingsArgument");

		const t = {} as ENetUC_Journal.AsnJournalGetSettingsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJournalGetSettingsArgument";

		if (errors.validateResult(newContext, "AsnJournalGetSettingsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalGetSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalGetSettingsArgument");

		let t: ENetUC_Journal.AsnJournalGetSettingsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnJournalGetSettingsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnJournalGetSettingsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnJournalGetSettingsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnJournalGetSettingsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJournalGetSettingsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalGetSettingsArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalGetSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalGetSettingsArgument");

		let t: ENetUC_Journal.AsnJournalGetSettingsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnJournalGetSettingsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnJournalGetSettingsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnJournalGetSettingsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDeleteJournalEntriesArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnDeleteJournalEntriesArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnDeleteJournalEntriesArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDeleteJournalEntriesArgument");

		const t = {} as ENetUC_Journal.AsnDeleteJournalEntriesArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDeleteJournalEntriesArgument";
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		if (_u8sConnectionIDList)
			t.u8sConnectionIDList = _u8sConnectionIDList;

		if (errors.validateResult(newContext, "AsnDeleteJournalEntriesArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnDeleteJournalEntriesArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDeleteJournalEntriesArgument");

		let t: ENetUC_Journal.AsnDeleteJournalEntriesArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnDeleteJournalEntriesArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnDeleteJournalEntriesArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList", false);
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
		}
		if (errors.validateResult(newContext, "AsnDeleteJournalEntriesArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnDeleteJournalEntriesArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDeleteJournalEntriesArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDeleteJournalEntriesArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _u8sConnectionIDList = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sConnectionIDList, errors, newContext, "u8sConnectionIDList");
		if (errors.validateResult(newContext, "AsnDeleteJournalEntriesArgument")) {
			if (_u8sConnectionIDList)
				t.push(_u8sConnectionIDList);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnDeleteJournalEntriesArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDeleteJournalEntriesArgument");

		let t: ENetUC_Journal.AsnDeleteJournalEntriesArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnDeleteJournalEntriesArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnDeleteJournalEntriesArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _u8sconnectionidlist = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sConnectionIDList"), errors, newContext, "u8sConnectionIDList");
			if (_u8sconnectionidlist)
				t.u8sConnectionIDList = _u8sconnectionidlist;
		}

		if (errors.validateResult(newContext, "AsnDeleteJournalEntriesArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDeleteJournalEntriesResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnDeleteJournalEntriesResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnDeleteJournalEntriesResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDeleteJournalEntriesResult");

		const t = {} as ENetUC_Journal.AsnDeleteJournalEntriesResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDeleteJournalEntriesResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnDeleteJournalEntriesResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnDeleteJournalEntriesResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDeleteJournalEntriesResult");

		let t: ENetUC_Journal.AsnDeleteJournalEntriesResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnDeleteJournalEntriesResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnDeleteJournalEntriesResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnDeleteJournalEntriesResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnDeleteJournalEntriesResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDeleteJournalEntriesResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDeleteJournalEntriesResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnDeleteJournalEntriesResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnDeleteJournalEntriesResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDeleteJournalEntriesResult");

		let t: ENetUC_Journal.AsnDeleteJournalEntriesResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnDeleteJournalEntriesResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnDeleteJournalEntriesResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnDeleteJournalEntriesResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMarkAllJournalEntriesAsReadArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMarkAllJournalEntriesAsReadArgument");

		const t = {} as ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMarkAllJournalEntriesAsReadArgument";

		if (errors.validateResult(newContext, "AsnMarkAllJournalEntriesAsReadArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMarkAllJournalEntriesAsReadArgument");

		let t: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnMarkAllJournalEntriesAsReadArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMarkAllJournalEntriesAsReadArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMarkAllJournalEntriesAsReadArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMarkAllJournalEntriesAsReadArgument");

		let t: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnMarkAllJournalEntriesAsReadArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMarkAllJournalEntriesAsReadResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMarkAllJournalEntriesAsReadResult");

		const t = {} as ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMarkAllJournalEntriesAsReadResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnMarkAllJournalEntriesAsReadResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMarkAllJournalEntriesAsReadResult");

		let t: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnMarkAllJournalEntriesAsReadResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMarkAllJournalEntriesAsReadResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMarkAllJournalEntriesAsReadResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnMarkAllJournalEntriesAsReadResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMarkAllJournalEntriesAsReadResult");

		let t: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnMarkAllJournalEntriesAsReadResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNetDatabaseJournalList_Converter {
	public static toJSON(s: ENetUC_Journal.AsnNetDatabaseJournalList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnNetDatabaseJournalList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseJournalList");

		const t = [] as ENetUC_Journal.AsnNetDatabaseJournalList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnNetDatabaseJournal_Converter.toJSON(se, errors, newContext, "AsnNetDatabaseJournal");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseJournalList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnNetDatabaseJournalList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseJournalList");

		let t: ENetUC_Journal.AsnNetDatabaseJournalList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnNetDatabaseJournalList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Journal.AsnNetDatabaseJournalList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnNetDatabaseJournal_Converter.fromJSON(se, errors, newContext, "AsnNetDatabaseJournal", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnNetDatabaseJournalList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnNetDatabaseJournalList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNetDatabaseJournalList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseJournalList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnNetDatabaseJournal_Converter.toBER(s[id], errors, newContext, "AsnNetDatabaseJournal");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseJournal"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnNetDatabaseJournalList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseJournalList");

		let t: ENetUC_Journal.AsnNetDatabaseJournalList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnNetDatabaseJournalList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Journal.AsnNetDatabaseJournalList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnNetDatabaseJournal_Converter.fromBER(se, errors, newContext, "AsnNetDatabaseJournal", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseJournalList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetJournalEntriesResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnGetJournalEntriesResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnGetJournalEntriesResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetJournalEntriesResult");

		const t = {} as ENetUC_Journal.AsnGetJournalEntriesResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetJournalEntriesResult";
		const _journalList = AsnNetDatabaseJournalList_Converter.toJSON(s.journalList, errors, newContext, "journalList");
		if (_journalList)
			t.journalList = _journalList;

		if (errors.validateResult(newContext, "AsnGetJournalEntriesResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetJournalEntriesResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetJournalEntriesResult");

		let t: ENetUC_Journal.AsnGetJournalEntriesResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnGetJournalEntriesResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnGetJournalEntriesResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _journallist = AsnNetDatabaseJournalList_Converter.fromJSON(s.journalList, errors, newContext, "journalList", false);
			if (_journallist)
				t.journalList = _journallist;
		}
		if (errors.validateResult(newContext, "AsnGetJournalEntriesResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnGetJournalEntriesResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetJournalEntriesResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetJournalEntriesResult");

		// [Print_BER_EncoderSeqDefCode]
		const _journalList = AsnNetDatabaseJournalList_Converter.toBER(s.journalList, errors, newContext, "journalList");
		if (errors.validateResult(newContext, "AsnGetJournalEntriesResult")) {
			if (_journalList)
				t.push(_journalList);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetJournalEntriesResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetJournalEntriesResult");

		let t: ENetUC_Journal.AsnGetJournalEntriesResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnGetJournalEntriesResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnGetJournalEntriesResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _journallist = AsnNetDatabaseJournalList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "journalList"), errors, newContext, "journalList");
			if (_journallist)
				t.journalList = _journallist;
		}

		if (errors.validateResult(newContext, "AsnGetJournalEntriesResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJournalEntryGetResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnJournalEntryGetResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnJournalEntryGetResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryGetResult");

		const t = {} as ENetUC_Journal.AsnJournalEntryGetResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJournalEntryGetResult";
		const _journalEntry = AsnNetDatabaseJournal_Converter.toJSON(s.journalEntry, errors, newContext, "journalEntry");
		if (_journalEntry)
			t.journalEntry = _journalEntry;

		if (errors.validateResult(newContext, "AsnJournalEntryGetResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryGetResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryGetResult");

		let t: ENetUC_Journal.AsnJournalEntryGetResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnJournalEntryGetResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnJournalEntryGetResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _journalentry = AsnNetDatabaseJournal_Converter.fromJSON(s.journalEntry, errors, newContext, "journalEntry", false);
			if (_journalentry)
				t.journalEntry = _journalentry;
		}
		if (errors.validateResult(newContext, "AsnJournalEntryGetResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnJournalEntryGetResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJournalEntryGetResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryGetResult");

		// [Print_BER_EncoderSeqDefCode]
		const _journalEntry = AsnNetDatabaseJournal_Converter.toBER(s.journalEntry, errors, newContext, "journalEntry");
		if (errors.validateResult(newContext, "AsnJournalEntryGetResult")) {
			if (_journalEntry)
				t.push(_journalEntry);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryGetResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryGetResult");

		let t: ENetUC_Journal.AsnJournalEntryGetResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnJournalEntryGetResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnJournalEntryGetResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _journalentry = AsnNetDatabaseJournal_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "journalEntry"), errors, newContext, "journalEntry");
			if (_journalentry)
				t.journalEntry = _journalentry;
		}

		if (errors.validateResult(newContext, "AsnJournalEntryGetResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJournalEntryChangedArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnJournalEntryChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnJournalEntryChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryChangedArgument");

		const t = {} as ENetUC_Journal.AsnJournalEntryChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJournalEntryChangedArgument";
		TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext);
		const _journalEntry = AsnNetDatabaseJournal_Converter.toJSON(s.journalEntry, errors, newContext, "journalEntry");
		if (_journalEntry)
			t.journalEntry = _journalEntry;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnJournalEntryChangedArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryChangedArgument");

		let t: ENetUC_Journal.AsnJournalEntryChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnJournalEntryChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnJournalEntryChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, context, false);
			const _journalentry = AsnNetDatabaseJournal_Converter.fromJSON(s.journalEntry, errors, newContext, "journalEntry", false);
			if (_journalentry)
				t.journalEntry = _journalentry;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnJournalEntryChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnJournalEntryChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJournalEntryChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iFlags", "number", errors, newContext);
		const _journalEntry = AsnNetDatabaseJournal_Converter.toBER(s.journalEntry, errors, newContext, "journalEntry");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnJournalEntryChangedArgument")) {
			t.push(new asn1ts.Integer({ value: s.iFlags, name: "iFlags" }));
			if (_journalEntry)
				t.push(_journalEntry);
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryChangedArgument");

		let t: ENetUC_Journal.AsnJournalEntryChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnJournalEntryChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnJournalEntryChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iFlags", "Integer", errors, newContext);
			const _journalentry = AsnNetDatabaseJournal_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "journalEntry"), errors, newContext, "journalEntry");
			if (_journalentry)
				t.journalEntry = _journalentry;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnJournalEntryChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJournalEntryChangedList_Converter {
	public static toJSON(s: ENetUC_Journal.AsnJournalEntryChangedList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnJournalEntryChangedList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryChangedList");

		const t = [] as ENetUC_Journal.AsnJournalEntryChangedList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnJournalEntryChangedArgument_Converter.toJSON(se, errors, newContext, "AsnJournalEntryChangedArgument");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnJournalEntryChangedList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryChangedList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryChangedList");

		let t: ENetUC_Journal.AsnJournalEntryChangedList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnJournalEntryChangedList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Journal.AsnJournalEntryChangedList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnJournalEntryChangedArgument_Converter.fromJSON(se, errors, newContext, "AsnJournalEntryChangedArgument", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnJournalEntryChangedList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnJournalEntryChangedList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJournalEntryChangedList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryChangedList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnJournalEntryChangedArgument_Converter.toBER(s[id], errors, newContext, "AsnJournalEntryChangedArgument");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnJournalEntryChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryChangedList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryChangedList");

		let t: ENetUC_Journal.AsnJournalEntryChangedList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnJournalEntryChangedList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Journal.AsnJournalEntryChangedList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnJournalEntryChangedArgument_Converter.fromBER(se, errors, newContext, "AsnJournalEntryChangedArgument", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnJournalEntryChangedList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJournalEntryChangedListArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnJournalEntryChangedListArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnJournalEntryChangedListArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryChangedListArgument");

		const t = {} as ENetUC_Journal.AsnJournalEntryChangedListArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJournalEntryChangedListArgument";
		TSConverter.fillJSONParam(s, t, "iLastTransactionID", "number", errors, newContext);
		const _journalEntryChangedList = AsnJournalEntryChangedList_Converter.toJSON(s.journalEntryChangedList, errors, newContext, "journalEntryChangedList");
		if (_journalEntryChangedList)
			t.journalEntryChangedList = _journalEntryChangedList;

		if (errors.validateResult(newContext, "AsnJournalEntryChangedListArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryChangedListArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryChangedListArgument");

		let t: ENetUC_Journal.AsnJournalEntryChangedListArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnJournalEntryChangedListArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnJournalEntryChangedListArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iLastTransactionID", "number", errors, context, false);
			const _journalentrychangedlist = AsnJournalEntryChangedList_Converter.fromJSON(s.journalEntryChangedList, errors, newContext, "journalEntryChangedList", false);
			if (_journalentrychangedlist)
				t.journalEntryChangedList = _journalentrychangedlist;
		}
		if (errors.validateResult(newContext, "AsnJournalEntryChangedListArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnJournalEntryChangedListArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJournalEntryChangedListArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalEntryChangedListArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iLastTransactionID", "number", errors, newContext);
		const _journalEntryChangedList = AsnJournalEntryChangedList_Converter.toBER(s.journalEntryChangedList, errors, newContext, "journalEntryChangedList");
		if (errors.validateResult(newContext, "AsnJournalEntryChangedListArgument")) {
			t.push(new asn1ts.Integer({ value: s.iLastTransactionID, name: "iLastTransactionID" }));
			if (_journalEntryChangedList)
				t.push(_journalEntryChangedList);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalEntryChangedListArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalEntryChangedListArgument");

		let t: ENetUC_Journal.AsnJournalEntryChangedListArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnJournalEntryChangedListArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnJournalEntryChangedListArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iLastTransactionID", "Integer", errors, newContext);
			const _journalentrychangedlist = AsnJournalEntryChangedList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "journalEntryChangedList"), errors, newContext, "journalEntryChangedList");
			if (_journalentrychangedlist)
				t.journalEntryChangedList = _journalentrychangedlist;
		}

		if (errors.validateResult(newContext, "AsnJournalEntryChangedListArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnJournalGetSettingsResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnJournalGetSettingsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnJournalGetSettingsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalGetSettingsResult");

		const t = {} as ENetUC_Journal.AsnJournalGetSettingsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnJournalGetSettingsResult";
		TSConverter.fillJSONParam(s, t, "ePrivacyLevel", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bUseOfflineJournal", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bOfflineDeleteOldEntries", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iOfflineDaysNoDelete", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnJournalGetSettingsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalGetSettingsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalGetSettingsResult");

		let t: ENetUC_Journal.AsnJournalGetSettingsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnJournalGetSettingsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnJournalGetSettingsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "ePrivacyLevel", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bUseOfflineJournal", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bOfflineDeleteOldEntries", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iOfflineDaysNoDelete", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnJournalGetSettingsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnJournalGetSettingsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnJournalGetSettingsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnJournalGetSettingsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "ePrivacyLevel", "number", errors, newContext);
		TSConverter.validateParam(s, "bUseOfflineJournal", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bOfflineDeleteOldEntries", "boolean", errors, newContext);
		TSConverter.validateParam(s, "iOfflineDaysNoDelete", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnJournalGetSettingsResult")) {
			t.push(new asn1ts.Enumerated({ value: s.ePrivacyLevel, name: "ePrivacyLevel" }));
			t.push(new asn1ts.Boolean({ value: s.bUseOfflineJournal, name: "bUseOfflineJournal" }));
			t.push(new asn1ts.Boolean({ value: s.bOfflineDeleteOldEntries, name: "bOfflineDeleteOldEntries" }));
			t.push(new asn1ts.Integer({ value: s.iOfflineDaysNoDelete, name: "iOfflineDaysNoDelete" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnJournalGetSettingsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnJournalGetSettingsResult");

		let t: ENetUC_Journal.AsnJournalGetSettingsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnJournalGetSettingsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnJournalGetSettingsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "ePrivacyLevel", "Enumerated", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bUseOfflineJournal", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bOfflineDeleteOldEntries", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iOfflineDaysNoDelete", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnJournalGetSettingsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateJournalArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnUpdateJournalArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnUpdateJournalArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalArgument");

		const t = {} as ENetUC_Journal.AsnUpdateJournalArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateJournalArgument";
		const _journalList = AsnNetDatabaseJournalList_Converter.toJSON(s.journalList, errors, newContext, "journalList");
		if (_journalList)
			t.journalList = _journalList;
		TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalArgument");

		let t: ENetUC_Journal.AsnUpdateJournalArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnUpdateJournalArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnUpdateJournalArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _journallist = AsnNetDatabaseJournalList_Converter.fromJSON(s.journalList, errors, newContext, "journalList", false);
			if (_journallist)
				t.journalList = _journallist;
			TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnUpdateJournalArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnUpdateJournalArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateJournalArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateJournalArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _journalList = AsnNetDatabaseJournalList_Converter.toBER(s.journalList, errors, newContext, "journalList");
		TSConverter.validateParam(s, "iFlags", "number", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnUpdateJournalArgument")) {
			if (_journalList)
				t.push(_journalList);
			t.push(new asn1ts.Integer({ value: s.iFlags, name: "iFlags" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnUpdateJournalArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateJournalArgument");

		let t: ENetUC_Journal.AsnUpdateJournalArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnUpdateJournalArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnUpdateJournalArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _journallist = AsnNetDatabaseJournalList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "journalList"), errors, newContext, "journalList");
			if (_journallist)
				t.journalList = _journallist;
			TSConverter.fillASN1Param(s, t, "iFlags", "Integer", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnUpdateJournalArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetOfflineJournalResult_Converter {
	public static toJSON(s: ENetUC_Journal.AsnGetOfflineJournalResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnGetOfflineJournalResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetOfflineJournalResult");

		const t = {} as ENetUC_Journal.AsnGetOfflineJournalResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetOfflineJournalResult";
		const _journalList = AsnNetDatabaseJournalList_Converter.toJSON(s.journalList, errors, newContext, "journalList");
		if (_journalList)
			t.journalList = _journalList;

		if (errors.validateResult(newContext, "AsnGetOfflineJournalResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetOfflineJournalResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetOfflineJournalResult");

		let t: ENetUC_Journal.AsnGetOfflineJournalResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnGetOfflineJournalResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnGetOfflineJournalResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _journallist = AsnNetDatabaseJournalList_Converter.fromJSON(s.journalList, errors, newContext, "journalList", false);
			if (_journallist)
				t.journalList = _journallist;
		}
		if (errors.validateResult(newContext, "AsnGetOfflineJournalResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnGetOfflineJournalResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetOfflineJournalResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetOfflineJournalResult");

		// [Print_BER_EncoderSeqDefCode]
		const _journalList = AsnNetDatabaseJournalList_Converter.toBER(s.journalList, errors, newContext, "journalList");
		if (errors.validateResult(newContext, "AsnGetOfflineJournalResult")) {
			if (_journalList)
				t.push(_journalList);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetOfflineJournalResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetOfflineJournalResult");

		let t: ENetUC_Journal.AsnGetOfflineJournalResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnGetOfflineJournalResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnGetOfflineJournalResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _journallist = AsnNetDatabaseJournalList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "journalList"), errors, newContext, "journalList");
			if (_journallist)
				t.journalList = _journallist;
		}

		if (errors.validateResult(newContext, "AsnGetOfflineJournalResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetJournalEntriesExArgument_Converter {
	public static toJSON(s: ENetUC_Journal.AsnGetJournalEntriesExArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnGetJournalEntriesExArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetJournalEntriesExArgument");

		const t = {} as ENetUC_Journal.AsnGetJournalEntriesExArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetJournalEntriesExArgument";
		const _findOptions = AsnNetDatabaseJournalFindOptionsEx_Converter.toJSON(s.findOptions, errors, newContext, "findOptions");
		if (_findOptions)
			t.findOptions = _findOptions;

		if (errors.validateResult(newContext, "AsnGetJournalEntriesExArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetJournalEntriesExArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetJournalEntriesExArgument");

		let t: ENetUC_Journal.AsnGetJournalEntriesExArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnGetJournalEntriesExArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnGetJournalEntriesExArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _findoptions = AsnNetDatabaseJournalFindOptionsEx_Converter.fromJSON(s.findOptions, errors, newContext, "findOptions", false);
			if (_findoptions)
				t.findOptions = _findoptions;
		}
		if (errors.validateResult(newContext, "AsnGetJournalEntriesExArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnGetJournalEntriesExArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetJournalEntriesExArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetJournalEntriesExArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _findOptions = AsnNetDatabaseJournalFindOptionsEx_Converter.toBER(s.findOptions, errors, newContext, "findOptions");
		if (errors.validateResult(newContext, "AsnGetJournalEntriesExArgument")) {
			if (_findOptions)
				t.push(_findOptions);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetJournalEntriesExArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetJournalEntriesExArgument");

		let t: ENetUC_Journal.AsnGetJournalEntriesExArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnGetJournalEntriesExArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnGetJournalEntriesExArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _findoptions = AsnNetDatabaseJournalFindOptionsEx_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "findOptions"), errors, newContext, "findOptions");
			if (_findoptions)
				t.findOptions = _findoptions;
		}

		if (errors.validateResult(newContext, "AsnGetJournalEntriesExArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetJournalEntriesCountArgumentEx_Converter {
	public static toJSON(s: ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetJournalEntriesCountArgumentEx");

		const t = {} as ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetJournalEntriesCountArgumentEx";
		const _findOptions = AsnNetDatabaseJournalFindOptionsEx_Converter.toJSON(s.findOptions, errors, newContext, "findOptions");
		if (_findOptions)
			t.findOptions = _findOptions;

		if (errors.validateResult(newContext, "AsnGetJournalEntriesCountArgumentEx"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetJournalEntriesCountArgumentEx");

		let t: ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _findoptions = AsnNetDatabaseJournalFindOptionsEx_Converter.fromJSON(s.findOptions, errors, newContext, "findOptions", false);
			if (_findoptions)
				t.findOptions = _findoptions;
		}
		if (errors.validateResult(newContext, "AsnGetJournalEntriesCountArgumentEx"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetJournalEntriesCountArgumentEx";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetJournalEntriesCountArgumentEx");

		// [Print_BER_EncoderSeqDefCode]
		const _findOptions = AsnNetDatabaseJournalFindOptionsEx_Converter.toBER(s.findOptions, errors, newContext, "findOptions");
		if (errors.validateResult(newContext, "AsnGetJournalEntriesCountArgumentEx")) {
			if (_findOptions)
				t.push(_findOptions);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetJournalEntriesCountArgumentEx");

		let t: ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Journal.AsnGetJournalEntriesCountArgumentEx["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _findoptions = AsnNetDatabaseJournalFindOptionsEx_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "findOptions"), errors, newContext, "findOptions");
			if (_findoptions)
				t.findOptions = _findoptions;
		}

		if (errors.validateResult(newContext, "AsnGetJournalEntriesCountArgumentEx"))
			return t;

		return undefined;
	}
}
