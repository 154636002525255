import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { faArrowRightFromBracket, faPhoneSquare, faSquareInfo, IconDefinition } from "web-fortawesome/npm/pro-light";

import AboutDialog from "../../components/Dialogs/AboutDialog/AboutDialog";
import { useUserMedia } from "../../hooks/useUserMedia";
import { useStore } from "../../zustand/store";
import { LeftColumnE } from "../Dashboard";

const DialogContent = () => {
	const { stream, error } = useUserMedia({ audio: true, video: true });

	return error ? (
		<p>error</p>
	) : (
		<video
			autoPlay
			ref={(video) => {
				if (video) {
					video.srcObject = stream;
				}
			}}
		/>
	);
};

interface ISettingsListItem {
	label: string;
	icon: IconDefinition;
	size: SizeProp;
	onClick?: () => void;
}

interface ISettingsItemsProps {
	setLeftCol: (col: LeftColumnE) => void;
}

export const SettingsItems = ({ setLeftCol }: ISettingsItemsProps) => {
	const { t } = useTranslation();
	const reset = useStore((state) => state.reset);
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const [showAboutDialog, setShowAboutDialog] = useState<boolean>(false);

	const settingsItems: ISettingsListItem[] = useMemo(
		() => [
			{
				label: t("IDS_CONTACT_SETTINGS_LINE"),
				icon: faPhoneSquare,
				size: "lg",
				onClick: async () => {
					setLeftCol(LeftColumnE.LINE_SETTINGS);
				}
			},
			// TODO - uncomment when we have a settings page
			// {
			// 	label: t("IDS_CONTACT_SETTINGS_GENERAL"),
			// 	icon: faCogs,
			// 	size: "sm",
			// 	onClick: async () => {
			// 		setLeftCol(LeftColumnE.APP_SETTINGS);
			// 	}
			// },
			{
				label: t("IDS_ABOUT"),
				icon: faSquareInfo,
				size: "lg",
				onClick: () => {
					setShowAboutDialog(true);
				}
			},
			{
				label: t("IDS_LOGOUT"),
				icon: faArrowRightFromBracket,
				size: "lg",
				onClick: async () => {
					reset();
					navigate("/");
				}
			}
		],
		[t, reset, navigate, setLeftCol]
	);

	return (
		<List>
			{settingsItems.map((item) => {
				return (
					<ListItem disablePadding key={item.label}>
						<ListItemButton onClick={item.onClick}>
							<ListItemIcon sx={{ minWidth: 32 }}>
								<FontAwesomeIcon icon={item.icon} size={item.size} />
							</ListItemIcon>
							<ListItemText primary={item.label} />
						</ListItemButton>
					</ListItem>
				);
			})}
			<Dialog
				open={open}
				onClose={() => {
					setOpen(false);
				}}
			>
				<DialogContent />
			</Dialog>
			{showAboutDialog ? (
				<AboutDialog
					onCLose={() => {
						setShowAboutDialog(false);
					}}
				/>
			) : null}
		</List>
	);
};
