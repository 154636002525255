import "../../components/imported/styles/avCallAudioTileRemote.scss";
import "../../components/imported/styles/avCallNotification.scss";

import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";

import { AsnPresenceStateFlags } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetROSEInterface";
import { AsnReachabilityEnum } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { TIntervalOrNull } from "../../../web-shared-components/interfaces/types";
import SharedSpectrumAnalyzer from "../../../web-shared-components/react-components/media/spectrum-analyzer/SharedSpectrumAnalyzer";
import UserPresenceIcon from "../../../web-shared-components/react-components/userPresenceIcon/UserPresenceIcon";
import { theAVManager } from "../../globals";
import { ECallState } from "../../interfaces/IAVCall";
import { addSipPrefixIfNotExists, secondsToReadable } from "../../lib/commonHelper";
import { ConstrContactFromModel } from "../../modelconstructors/ConstrModelContact";
import { ConstrContentDetailsFromJson } from "../../modelconstructors/ConstrModelContent";
import WebRTCHelper from "../../webrtc/WebRTCHelper";
import { IAvCall } from "../../zustand/avCallSlice";
import { useStore } from "../../zustand/store";
import AVCallAudioControls from "./AVCallAudioControls";

interface IProps {
	avCall: IAvCall;
}

/**
 * Video Tile remote
 *
 * @param props - the props
 * @returns - the component
 */
export default function AVCallAudioTileRemote(props: IProps): JSX.Element {
	const draggableRef = useRef<Draggable>(null);
	const partyToFind = props.avCall.isInitiator ? props.avCall.to : props.avCall.from;
	// const contactsMap = useStore((state) => state.contactsDetails);
	// const contact = contactsMap.get(addSipPrefixIfNotExists(partyToFind));
	const contactsMap = useStore((state) => state.contactsPresence);
	const clientContentsMap = useStore((state) => state.contactsDetails);
	const customLabels = useStore((state) => state.customLabels);
	const cDetails = useStore((state) => state.contactDetails);
	const [readable, setReadable] = useState<string>(
		secondsToReadable(theAVManager.getAVCall(props.avCall.id)?.duration)
	);
	const getDurationInterval = useRef<TIntervalOrNull>(null);

	const contact = contactsMap.get(addSipPrefixIfNotExists(partyToFind));
	const clientContent = clientContentsMap.get(partyToFind);
	const contentDetails = ConstrContentDetailsFromJson({
		...clientContent,
		statusInfos: {
			presence: contact?.presence || 0,
			asnCustomNote: contact?.customNote || "",
			asnNextAppointment: contact?.nextAppointment,
			reachability: contact?.reachability || AsnReachabilityEnum.eREACHABILITYNORMAL,
			agentState: contact?.agentState,
			currentAppointments: contact?.currentAppointments,
			isMobileAvailable: contact?.presence ? !!(contact?.presence & AsnPresenceStateFlags.eMOBILEAVAILABLITY) : false,
			seqCalls: contact?.seqCalls,
			seqPhoneLines: contact?.seqPhoneLines,
			seqLineForwards: contact?.seqLineForwards,
			msTeamsEmail: contact?.msTeamsEmail
		}
	});
	const constructedContact = ConstrContactFromModel(
		contentDetails,
		customLabels.CustLabels,
		cDetails.ContactDetails,
		"contactDetails"
	);

	useEffect(() => {
		return () => {
			if (getDurationInterval.current) {
				clearInterval(getDurationInterval.current);
			}
		};
	}, []);

	useEffect(() => {
		const avCall = theAVManager.getAVCall(props.avCall.id);
		if (!avCall) {
			return;
		}
		if (getDurationInterval.current) {
			clearInterval(getDurationInterval.current);
		}
		if (props.avCall.callState === ECallState.CONNECTED) {
			getDurationInterval.current = setInterval(() => {
				setReadable(secondsToReadable(avCall.duration));
			}, 1000);
		} else {
			setReadable("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.avCall.callState]);

	return (
		<Draggable ref={draggableRef} bounds={"#root"} handle=".avcatr-container">
			<div className="avcatr-container">
				<div className="avcn-user">
					<UserPresenceIcon pict={constructedContact.pict} size={48} pres={contact?.presence ?? 0} />
					<div className="avcn-name-and-type">
						<div className="avcn-name-box">
							<div className="avcn-name avcn-call-audio">{constructedContact?.displayName}</div>
							<div className="avcatr-wave">
								{props.avCall.callState === ECallState.CONNECTED ? (
									<SharedSpectrumAnalyzer
										// @ts-ignore
										width={32}
										height={16}
										audioStream={theAVManager.getAVCall(props.avCall.id)?.remoteStream}
									/>
								) : null}
							</div>
						</div>
						<div className="avcn-type-box">
							<div className="avcn-type">{WebRTCHelper.getAVCallReadableType(props.avCall.callType)}</div>
							<div className="avcn-time">{readable}</div>
						</div>
					</div>
				</div>

				<AVCallAudioControls avCall={props.avCall} />
			</div>
		</Draggable>
	);
}
