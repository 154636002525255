import "../../components/imported/styles/avCallControls.scss";

import { useEffect } from "react";

import SharedCallHangUpButton from "../../../web-shared-components/react-components/web-rtc/buttons/call-hang-up/SharedCallHangUpButton";
import SharedMicSelectorButton from "../../../web-shared-components/react-components/web-rtc/buttons/mic-selector/SharedMicSelectorButton";
import SharedSpeakerSelectorButton from "../../../web-shared-components/react-components/web-rtc/buttons/speaker-selector/SharedSpeakerSelectorButton";
import { theAVManager } from "../../globals";
import { navigator_media } from "../../lib/BrowserDetector";
import { IAvCall } from "../../zustand/avCallSlice";
import { useStore } from "../../zustand/store";

interface IProps {
	avCall: IAvCall;
}

/**
 * AVCall controls
 *
 * @param props - the props
 * @returns - the element
 */
export default function AVCallAudioControls(props: IProps): JSX.Element {
	const mics = useStore((state) => state.mics);
	const speakers = useStore((state) => state.speakers);
	const selectedMic = useStore((state) => state.selectedMic);
	const selectedSpeaker = useStore((state) => state.selectedSpeaker);
	const avCallSetSelectedMic = useStore((state) => state.avCallSetSelectedMic);
	const avCallSetSelectedSpeaker = useStore((state) => state.avCallSetSelectedSpeaker);

	const getAudioTrack = async (deviceId?: string) => {
		const constraints = {
			audio: {
				deviceId,
				echoCancellation: true
			}
		};
		const newStream = await navigator_media.mediaDevices.getUserMedia(constraints);
		if (newStream instanceof MediaStream) {
			return newStream.getAudioTracks()[0];
		}
	};

	useEffect(() => {
		if (!selectedMic) {
			return;
		}
		const avCall = theAVManager.getAVCall(props.avCall.id);
		if (!avCall) {
			return;
		}
		getAudioTrack(selectedMic)
			.then((track) => {
				if (track) {
					avCall.setAudioTrack(track);
				}
			})
			.catch((e) => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMic]);

	useEffect(() => {
		// if (!selectedSpeaker)
		// 	return;
		// const avCall = theAVManager.getAVCall(props.avCall.id);
		// if (!avCall)
		// 	return;
		// getAudioTrack(selectedSpeaker).then((track) => {
		// 	if (track)
		// 		avCall.setAudioTrack(track);
		// }).catch((e) => {});
	}, [selectedSpeaker]);

	const toggleMic = () => {
		const avCall = theAVManager.getAVCall(props.avCall.id);
		if (!avCall) {
			return;
		}
		if (!avCall.localAudioTrack) {
			getAudioTrack(selectedMic)
				.then((track) => {
					if (track) {
						avCall.setAudioTrack(track);
					}
				})
				.catch((e) => {});
		} else {
			avCall.setAudioTrack();
		}
	};

	const hungUp = () => {
		const avCall = theAVManager.getAVCall(props.avCall.id);
		if (!avCall) {
			return;
		}
		avCall.hangup();
	};

	return (
		<div className="avcac-container">
			<SharedMicSelectorButton
				width={48}
				height={48}
				selectedMicID={selectedMic}
				isMuted={!props.avCall.localAudioTrackID}
				micList={mics}
				onToggleMic={() => {
					toggleMic();
				}}
				onMicSelected={(mic) => {
					avCallSetSelectedMic(mic);
				}}
			/>

			<SharedSpeakerSelectorButton
				width={48}
				height={48}
				selectedSpeakerID={selectedSpeaker}
				speakerList={speakers}
				onSpeakerSelected={(speaker) => {
					avCallSetSelectedSpeaker(speaker);
				}}
			/>

			<SharedCallHangUpButton width={48} height={48} onClick={hungUp} />
		</div>
	);
}
