import { StateCreator } from "zustand";

import { StoreT } from "./store";

export type SendQueueSliceT = IState & IActions;

export interface ISendQueue {
	invokeID: number;
	argument: string;
	asnArgument: any;
}

interface IState {
	sendQueue: ISendQueue[];
}

interface IActions {
	addToSendQueue: (sendQueue: ISendQueue) => void;
	removeFromSendQueue: (invokeID: number) => void;
	flushSendQueue: () => void;
}

export const initialState = {
	sendQueue: []
};

export const createSendQueueSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	SendQueueSliceT
> = (set) => ({
	...initialState,
	addToSendQueue: (sendQueue: ISendQueue) => {
		set(
			(prev) => {
				return {
					...prev,
					sendQueue: [...prev.sendQueue, sendQueue]
				};
			},
			false,
			"addToSendQueue"
		);
	},
	removeFromSendQueue: (invokeID: number) => {
		set(
			(prev) => {
				return {
					...prev,
					sendQueue: prev.sendQueue.filter((item) => item.invokeID !== invokeID)
				};
			},
			false,
			"addToSendQueue"
		);
	},
	flushSendQueue: () => {
		set(
			(prev) => {
				return {
					...prev,
					sendQueue: []
				};
			},
			false,
			"flushSendQueue"
		);
	}
});
