import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { faArrowRight, faBell, faMicrophone, IconDefinition } from "web-fortawesome/npm/pro-light";

import { Header } from "../../components/common/Header/Header";
import { LeftColumnE } from "../Dashboard";

interface IAppSettingsItem {
	label: string;
	icon: IconDefinition;
	size?: SizeProp;
	onClick?: () => void;
}

interface IAppSettingsProps {
	setLeftCol: (leftCol: LeftColumnE) => void;
}

export const AppSettings = ({ setLeftCol }: IAppSettingsProps) => {
	const { t } = useTranslation();

	const appSettingsItems: IAppSettingsItem[] = useMemo(
		() => [
			{
				label: t("IDS_NOTIFICATIONS"),
				onClick: () => {
					setLeftCol(LeftColumnE.NOTIFICATION_SETTINGS);
				},
				icon: faBell
			},
			{
				label: t("IDS_DEVICES"),
				onClick: () => {},
				icon: faMicrophone
			}
		],
		[t, setLeftCol]
	);

	return (
		<Paper sx={{ height: "100%" }}>
			<Stack height={"100%"}>
				<Header
					title={t("IDS_CONTACT_SETTINGS_APP")}
					onBack={() => {
						setLeftCol(LeftColumnE.CONTACTS);
					}}
				/>
				<Stack rowGap={2}>
					{appSettingsItems.map((item) => {
						return (
							<ListItem disablePadding key={item.label}>
								<ListItemButton onClick={item.onClick}>
									<ListItemIcon sx={{ minWidth: 32 }}>
										<FontAwesomeIcon icon={item.icon} size={item.size} />
									</ListItemIcon>
									<ListItemText primary={item.label} />
									<ListItemIcon sx={{ justifyContent: "flex-end" }}>
										<FontAwesomeIcon icon={faArrowRight} />
									</ListItemIcon>
								</ListItemButton>
							</ListItem>
						);
					})}
				</Stack>
			</Stack>
		</Paper>
	);
};
