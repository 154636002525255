import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Stack, Tooltip } from "@mui/material";

import { CircleIconButton } from "../Button/CircleIconButton";

export interface IQuickActionItem {
	icon: IconProp;
	tooltip: string;
	disabled?: boolean;
	onClick: (event: React.MouseEvent<HTMLButtonElement | HTMLElement>) => void;
}

interface IQuickActionsProps {
	items: IQuickActionItem[];
}

export const QuickActions = ({ items }: IQuickActionsProps) => {
	return (
		<Stack flexDirection={"row"} gap={1}>
			{items.map((item, index) => {
				return (
					<Tooltip key={index} title={item.tooltip} color="primary" placement="bottom">
						<span>
							<CircleIconButton disabled={item.disabled} outlined onClick={item.onClick} icon={item.icon} />
						</span>
					</Tooltip>
				);
			})}
		</Stack>
	);
};
