
/**
 * Convert the presenceState into a readable string
 *
 * @param presence - the presence state to check
 * @returns - readable string
 */
export function iPresenceStateToString(presence: number): string {
	/*
		 CONTACT_STATE_UNCONTROLLED 0x00000000 // Kontakt ohne Präsenzinformation
		 CONTACT_STATE_AVAILABLE    0x00000001
		 CONTACT_STATE_BUSY         0x00000002
		 CONTACT_STATE_ABSENT       0x00000003
		 CONTACT_STATE_DONOTDISTURB 0x00000004
		 CONTACT_STATE_OUTOFORDER   0x00000007 // nur für schwarze Icons, die es für Kontakte eigentlich nicht gibt. Wird im Client benötigt aber nicht auf der Schnittstelle.
		 */
	let presenceString;
	switch (presence & 0x000000ff) {
		case 0x00000001:
			presenceString = "AVAILABLE";
			break;
		case 0x00000002:
			presenceString = "BUSY";
			break;
		case 0x00000003:
			presenceString = "ABSENT";
			break;
		case 0x00000004:
			presenceString = "DND";
			break;
		case 0x00000007:
			presenceString = "OUTOFORDER";
			break;
		default:
			presenceString = "UNAVAILABLE"; // LC was: UNCONTROLLED
			break;
	}

	const flags = presence & 0xffff0000;
	/*
	 CONTACT_STATE_RINGING              0x01000000 //!> Eine Leitung des Kontaktes klingelt
	 CONTACT_STATE_APPOINTMENT          0x02000000 //!> Der Kontakt hat einen Termin
	 CONTACT_STATE_CALL                 0x04000000 //!> Der Kontakt ist im Gespräch
	 CONTACT_STATE_OFFLINE              0x08000000 //!> Der Kontakt ist nicht am Server angemeldet
	 CONTACT_STATE_NOTESET              0x10000000 //!> Der Kontakt hat eine Notiz gesetzt
	 CONTACT_STATE_IDLE                 0x20000000 //!> Der Kontakt ist Idle
	 CONTACT_STATE_MOBILEAVAILABILITY   0x40000000 //!> FIVE-311: Der Benutzer ist wahrscheinlicher mobil erreichbar
	 */
	const additionalStates = [];
	if (flags & 0x01000000)
		additionalStates.push("RINGING");
	if (flags & 0x02000000)
		additionalStates.push("APPOINTMENT");
	if (flags & 0x04000000)
		additionalStates.push("CALL");
	if (flags & 0x08000000)
		additionalStates.push("OFFLINE");
	if (flags & 0x10000000)
		additionalStates.push("NOTESET");
	if (flags & 0x20000000)
		additionalStates.push("IDLE");
	if (flags & 0x40000000)
		additionalStates.push("MOBILEAVAILABILITY");

	// Falls Offline angegeben ist -> zeige grau an
	if (additionalStates.indexOf("OFFLINE") > -1)
		presenceString = "UNAVAILABLE"; // LC was: OFFLINE
	// Ansonsten normale Präsenz zurückgeben
	else if (additionalStates.indexOf("RINGING") > -1)
		presenceString = "RINGING";
	else if (additionalStates.indexOf("IDLE") > -1)
		presenceString = "IDLE";

	// to match with the UserPresenceIcon logic.
	// However, it would be better to keep them uppercase for the localization labels.
	return presenceString.toLowerCase();
}
