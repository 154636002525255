import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, ListItemButton, ListItemText, Stack } from "@mui/material";
import React, { FC } from "react";
import { useResizeDetector } from "react-resize-detector";

import {
	AsnNetDatabaseJournal,
	AsnNetDatabaseJournalCallType
} from "../../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal";
import { PresenceIconWithTooltip } from "../../../../web-shared-components/react-components/presenceIconWithTooltip/PresenceIconWithTooltip";
import { CircleIconButton } from "../../../components/common/Button/CircleIconButton";
import { CallOriginIcon } from "../../../components/common/Icon/CallOriginIcon";
import { Typography } from "../../../components/common/Typography/Typography";
import { ContactActions } from "../../../components/ContactList/ContactListItem";
import { useDateTimeFormatter } from "../../../hooks/useDateTimeFormatter";
import { addSipPrefixIfNotExists, startCall } from "../../../lib/commonHelper";
import ContactHelper from "../../../lib/ContactHelper";
import { mapCallTypeToIcon } from "../../../lib/JournalHelper";
import { ConstrContactFromModel } from "../../../modelconstructors/ConstrModelContact";
import { ConstrContentDetailsFromJson } from "../../../modelconstructors/ConstrModelContent";
import { IContact } from "../../../models/ModelContact";
import { IAsnNetDatabaseJournalEx } from "../../../zustand/journalSlice";
import { useStore } from "../../../zustand/store";

interface ICallInfoProps {
	journalEntry: AsnNetDatabaseJournal;
}

const CallInfo: FC<ICallInfoProps> = ({ journalEntry }) => {
	return (
		<Stack flex={1} flexDirection="row" alignItems={"center"} overflow="hidden">
			<FontAwesomeIcon
				// @ts-ignore
				icon={mapCallTypeToIcon(journalEntry?.optionalParams?.CallType)}
			/>
			<Box mx={1.5}>
				<CallOriginIcon journalEntry={journalEntry} />
			</Box>
			<Typography variant="body1" noWrap>
				{journalEntry.u8sPhoneNumber || journalEntry.u8sCtiServerUserName}
			</Typography>
		</Stack>
	);
};

interface IJournalListItemProps {
	journalEntry: IAsnNetDatabaseJournalEx;
	contact?: IContact;
	selected?: boolean;
	onClick?: (journalEntry: IAsnNetDatabaseJournalEx) => void;
}

export const JournalListItem: FC<IJournalListItemProps> = ({ journalEntry, contact, selected, onClick }) => {
	const { formatRelativeToday } = useDateTimeFormatter();
	const contactPresences = useStore((state) => state.contactsPresence);
	const contacts = useStore((state) => state.contactsDetails);
	const asnDataBaseContact = contacts.get(addSipPrefixIfNotExists(journalEntry.u8sSIPAddress));
	const statusInfos = contactPresences.get(addSipPrefixIfNotExists(journalEntry.u8sSIPAddress));

	const customLabels = useStore((state) => state.customLabels);
	const cDetails = useStore((state) => state.contactDetails);

	const { width, ref } = useResizeDetector();
	const isSmallWidth = (width || 0) < 400;

	const contentDetails = ConstrContentDetailsFromJson({
		...asnDataBaseContact,
		statusInfos: {
			presence: statusInfos?.presence || 0,
			asnCustomNote: contact?.statusInfos?.u8sNote || "",
			asnNextAppointment: statusInfos?.nextAppointment || undefined,
			currentAppointments: statusInfos?.currentAppointments || undefined,
			reachability: statusInfos?.reachability,
			agentState: statusInfos?.agentState,
			isMobileAvailable: statusInfos?.isMobileAvailable,
			seqCalls: statusInfos?.seqCalls,
			seqPhoneLines: statusInfos?.seqPhoneLines,
			seqLineForwards: statusInfos?.seqLineForwards,
			msTeamsEmail: statusInfos?.msTeamsEmail
		}
	});
	const constructedContact = ConstrContactFromModel(
		contentDetails,
		customLabels.CustLabels,
		cDetails.ContactDetails,
		"contactDetails"
	);

	const currentAppointmentTime = statusInfos ? ContactHelper.getCurrentAppointment(statusInfos) : [];
	const nextAppointmentTime = statusInfos ? ContactHelper.getNextAppointment(statusInfos) : "";

	return (
		<>
			<ListItemButton
				selected={selected}
				disableRipple
				alignItems="flex-start"
				sx={{ height: "70px", alignItems: "center", cursor: "pointer" }}
				onClick={() => onClick?.(journalEntry)}
			>
				<PresenceIconWithTooltip
					contact={{ ...statusInfos, contactID: journalEntry.u8sSIPAddress, asnNetDatabaseContact: asnDataBaseContact }}
					contactDetails={cDetails}
					customLabels={customLabels}
					combiPic={ContactHelper.getPic(asnDataBaseContact)}
					currentAppointmentTime={currentAppointmentTime}
					nextAppointmentTime={nextAppointmentTime}
				/>
				<Stack flexDirection={"row"} alignItems="center" flex={1} overflow={"hidden"}>
					<ListItemText
						primary={journalEntry.u8sContactName || journalEntry.u8sPhoneNumber}
						secondaryTypographyProps={{ component: "span" }}
						secondary={<CallInfo journalEntry={journalEntry} />}
						sx={{
							"& .MuiListItemText-primary": {
								color: journalEntry.iReadFlag ? "inherit" : "red",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden"
							}
						}}
					/>
					<Typography variant="body2">{formatRelativeToday(journalEntry.stStartTime)}</Typography>
				</Stack>
				{
					// @ts-ignore
					journalEntry?.optionalParams?.CallType !== AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOCHAT &&
					// @ts-ignore
					journalEntry?.optionalParams?.CallType !== AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOVIDEOCHAT ? (
						<CircleIconButton
							outlined
							sx={{ ml: 2 }}
							onClick={async (e) => {
								e.stopPropagation();
								// @ts-ignore
								/* if (journalEntry?.optionalParams?.CallType === AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOCHAT) {
							startCall(journalEntry.u8sPhoneNumber, "audio");
							return;
						}
						if (
							// @ts-ignore
							journalEntry?.optionalParams?.CallType === AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOVIDEOCHAT
						) {
							startCall(journalEntry.u8sPhoneNumber, "video");
							return;
						} */
								if (
									// @ts-ignore
									journalEntry?.optionalParams?.CallType !== AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOCHAT &&
									// @ts-ignore
									journalEntry?.optionalParams?.CallType !==
										AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOVIDEOCHAT
								) {
									return window.open(`tel:${journalEntry.u8sPhoneNumber}`, "_self");
								}
							}}
							// @ts-ignore
							icon={mapCallTypeToIcon(journalEntry?.optionalParams?.CallType)}
						/>
					) : asnDataBaseContact ? (
						<ContactActions contact={asnDataBaseContact} isSmallWidth={false} />
					) : null
				}
			</ListItemButton>
			<Divider variant="middle" component="li" />
		</>
	);
};
