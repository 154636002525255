import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
	AsnCtiLineSetRemoteOfficeResult,
	AsnCtiSetForwardResult
} from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_CTI";
import { Header } from "../../components/common/Header/Header";
import { TextField } from "../../components/common/TextField/TextField";
import { Typography } from "../../components/common/Typography/Typography";
import { theBrowser, theCtiManager } from "../../globals";
import { usePrevious } from "../../hooks/usePrevious";
import { useStore } from "../../zustand/store";
import { LeftColumnE } from "../Dashboard";
import { SavingStateIndicator, useSavingState } from "../JournalEntryDetails/SavingStateIndicator";
import { SettingsItem } from "./SettingsItem";

interface ILineSettingsProps {
	setLeftCol: (leftCol: LeftColumnE) => void;
}

export const LineSettings = ({ setLeftCol }: ILineSettingsProps) => {
	const { t } = useTranslation();
	const phoneLines = useStore((state) => state.myPhoneLines);
	const localPhoneLines = useStore((state) => state.localPhoneLines);
	const setShowCallConfirmation = useStore((state) => state.setShowCallConfirmation);
	const showCallConfirmation = useStore((state) => state.showCallConfirmation);
	const setLocalPhoneLine = useStore((state) => state.setLocalPhoneLine);

	const [selectedLineName, setSelectedLineName] = useState<string>([...phoneLines]?.[0]?.[0]);
	const selectedLine = useMemo(() => phoneLines.get(selectedLineName), [phoneLines, selectedLineName]);
	const selectedLocalLine = useMemo(
		() => localPhoneLines.get(selectedLineName)?.lineForwardNumber || "",
		[localPhoneLines, selectedLineName]
	);
	const [remoteLine, setRemoteLine] = useState<string>(selectedLine?.remoteOfficeState?.u8sDestination || "");
	const [forwardDestinationTextInput, setForwardDestinationTextInput] = useState<string>(selectedLocalLine);
	const prevSelectedLine = usePrevious(selectedLine);

	const onCallForwardingClick = useCallback(
		(isCallForwardingEnabled: boolean) => {
			if (!selectedLine) {
				return;
			}
			if (!isCallForwardingEnabled) {
				return theCtiManager.ctiRemoveForward(selectedLine.lineInfo.u8sLinePhoneNumber);
			}

			if (isCallForwardingEnabled) {
				return theCtiManager.ctiSetForward({
					u8sPhoneNumberFrom: selectedLine?.lineInfo.u8sLinePhoneNumber,
					u8sPhoneNumberTo: forwardDestinationTextInput
				});
			}
		},
		[selectedLine, forwardDestinationTextInput]
	);

	const onSetRemoteOfficeClick = useCallback(
		(isRemoteOfficeSet: boolean) => {
			if (!selectedLine) {
				return;
			}
			void theCtiManager.ctiLineSetRemoteOffice({
				u8sLinePhoneNumber: selectedLine?.lineInfo.u8sLinePhoneNumber,
				iEnabled: isRemoteOfficeSet ? 1 : 0,
				u8sDestination: remoteLine
			});
		},
		[selectedLine, remoteLine]
	);

	const { savingState: savingStateSetForward, debouncedUpdate: debouncedUpdateSetForward } =
		useSavingState<AsnCtiSetForwardResult | void>((destinationNumber: string) => {
			if (!selectedLine) {
				return Promise.resolve();
			}
			return theCtiManager.ctiSetForward({
				u8sPhoneNumberFrom: selectedLine?.lineInfo.u8sLinePhoneNumber,
				u8sPhoneNumberTo: destinationNumber
			});
		});
	const onChangeSetForwardDestination = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setForwardDestinationTextInput(event.target.value);
			setLocalPhoneLine(selectedLineName, event.target.value);
			if (selectedLine?.seqLineForwards?.[0]?.u8sDestination) {
				void debouncedUpdateSetForward(event.target.value);
			}
		},
		[debouncedUpdateSetForward, selectedLine]
	);

	const {
		savingState: savingStateRemoteOffice,
		debouncedUpdate: debouncedUpdateSetRemoteOffice,
		cancelUpdate
	} = useSavingState<AsnCtiLineSetRemoteOfficeResult | void>((remoteDestination: string) => {
		if (!selectedLine) {
			return Promise.resolve();
		}
		return theCtiManager.ctiLineSetRemoteOffice({
			u8sLinePhoneNumber: selectedLine?.lineInfo.u8sLinePhoneNumber,
			iEnabled: selectedLine?.remoteOfficeState?.iEnabled || 0,
			u8sDestination: remoteDestination
		});
	});
	const onChangeRemoteLine = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setRemoteLine(event.target.value);
			cancelUpdate();
			if (selectedLine?.remoteOfficeState?.iEnabled === 1) {
				void debouncedUpdateSetRemoteOffice(event.target.value);
			}
		},
		[debouncedUpdateSetRemoteOffice, cancelUpdate, selectedLine]
	);

	useEffect(() => {
		// initialize fields if selected line changes
		if (prevSelectedLine && prevSelectedLine !== selectedLine) {
			setRemoteLine(selectedLine?.remoteOfficeState?.u8sDestination || "");
			setForwardDestinationTextInput(selectedLocalLine);
			return;
		}

		if (!debouncedUpdateSetRemoteOffice.isPending()) {
			setRemoteLine(selectedLine?.remoteOfficeState?.u8sDestination || "");
		}
	}, [selectedLine]);

	useEffect(() => {
		if (!debouncedUpdateSetForward.isPending()) {
			setForwardDestinationTextInput(selectedLocalLine);
		}
	}, [debouncedUpdateSetForward, selectedLocalLine]);

	const lineSpecificSettings = useMemo(
		() => [
			{
				title: t("IDS_SETTING_CALL_FORWARDING"),
				onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
					await onCallForwardingClick(event.target.checked);
				},
				disabled: !forwardDestinationTextInput,
				value: !!selectedLine?.seqLineForwards?.[0]?.u8sDestination || false,
				bottomComponent: () => (
					<Stack>
						<TextField
							label={t("IDS_SETTING_CALL_FORWARDING_LABEL") ?? ""}
							placeholder={t("IDS_SETTING_CALL_FORWARDING_PLACEHOLDER") ?? ""}
							value={forwardDestinationTextInput}
							onChange={onChangeSetForwardDestination}
							disableHelperText
							sx={{ mb: 0 }}
						/>
						<SavingStateIndicator savingState={savingStateSetForward} />
					</Stack>
				)
			},
			{
				title: t("IDS_SETTING_REMOTE_OFFICE"),
				onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					onSetRemoteOfficeClick(event.target.checked);
				},
				disabled: !remoteLine,
				value: selectedLine?.remoteOfficeState?.iEnabled === 1,
				bottomComponent: () => (
					<Stack>
						<TextField
							label={t("IDS_SETTING_REMOTE_OFFICE_LABEL") ?? ""}
							placeholder={t("IDS_SETTING_REMOTE_OFFICE_PLACEHOLDER") ?? ""}
							value={remoteLine}
							disabled={selectedLine?.isSoftPhone}
							onChange={onChangeRemoteLine}
							disableHelperText
							sx={{ mb: 0 }}
						/>
						<SavingStateIndicator savingState={savingStateRemoteOffice} />
					</Stack>
				)
			}
		],
		[
			t,
			selectedLine,
			onCallForwardingClick,
			forwardDestinationTextInput,
			onChangeSetForwardDestination,
			remoteLine,
			onChangeRemoteLine,
			onSetRemoteOfficeClick,
			savingStateRemoteOffice,
			savingStateSetForward
		]
	);

	const commonSettings = useMemo(
		() => [
			{
				title: t("IDS_SETTING_CALL_PROTECTION"),
				subTitle: t("IDS_SETTING_CALL_PROTECTION_INFO"),
				onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					void theCtiManager.setDoNotDisturbAllLines(event.target.checked);
				},
				value: [...phoneLines].every(([, phoneline]) => phoneline.bDoNotDisturb)
			}
			// TODO: Enable when the feature is implemented
			// {
			// 	title: t("IDS_CONFIRMATION_BEFORE_CALLING"),
			// 	onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
			// 		setShowCallConfirmation(event.target.checked);
			// 	},
			// 	value: showCallConfirmation
			// }
		],
		[t, selectedLine, setShowCallConfirmation, showCallConfirmation, phoneLines]
	);

	if (phoneLines.size === 0) {
		return (
			<Paper sx={{ height: "100%" }}>
				<Stack height={"100%"}>
					<Header
						title={t("IDS_CONTACT_SETTINGS_LINE")}
						onBack={() => {
							setLeftCol(LeftColumnE.CONTACTS);
						}}
					></Header>
					<Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
						{t("IDS_SETTING_NO_LINES")}
					</Typography>
				</Stack>
			</Paper>
		);
	}

	return (
		<Paper sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<Stack height={"100%"} overflow="hidden">
				<Header
					title={t("IDS_CONTACT_SETTINGS_LINE")}
					onBack={() => {
						setLeftCol(LeftColumnE.CONTACTS);
					}}
				></Header>
				<Stack flex={1} sx={{ overflowY: "auto" }} px={2} pt={2}>
					<Typography variant="h3" sx={{ mb: 2 }}>
						{t("IDS_SETTING_LINE_SELECTION")}
					</Typography>
					<FormControl size="small" sx={{ mb: 3 }}>
						<InputLabel id="line-select">{t("IDS_SETTING_CALL_LINE")}</InputLabel>
						<Select
							labelId="line-select"
							id="line-select"
							value={selectedLineName}
							label={t("IDS_SETTING_CALL_LINE")}
							onChange={(event) => {
								setSelectedLineName(event.target.value as string);
							}}
						>
							{[...phoneLines].map(([, line]) => (
								<MenuItem key={line.lineInfo.u8sLinePhoneNumber} value={line.lineInfo.u8sLinePhoneNumber}>
									{line.lineInfo.u8sDescriptor}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Stack>
						{lineSpecificSettings.map((item) => (
							<SettingsItem key={item.title} {...item} />
						))}
					</Stack>
					<Divider sx={{ mb: 2, mt: 1 }} />
					<Stack>
						{commonSettings.map((item) => (
							<SettingsItem key={item.title} {...item} />
						))}
					</Stack>
				</Stack>
			</Stack>
		</Paper>
	);
};
