import * as msTeams from "@microsoft/teams-js";
import { app } from "@microsoft/teams-js";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useEffect, useRef } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";

import { translationVariables } from "../web-shared-components/react-components/contactDetailsComponent/models/ModelU8sPermittedDetails";
import { WarningsSnackbarProvider } from "./components/common/SnackBar/WarningsSnackbarProvider";
import { Helmet } from "./components/Helmet/Helmet";
import { theTranslationManager } from "./globals";
import { Router } from "./router/Router";
import { MuiTheme } from "./theme/theme";

const queryClient = new QueryClient();

/**
 * App
 * @returns App
 */
function App() {
	const { i18n, t } = useTranslation();
	const msTeamsInitialized = useRef(false);
	useEffect(() => {
		if (msTeamsInitialized.current) {
			return;
		}
		const getInitialTheme = async () => {
			const context = await app.getContext();
			if (context?.app?.theme) {
				console.log("theme: " + context.app.theme);
			}
			if (context?.app?.locale) {
				void i18n.changeLanguage(context.app.locale);
			}
		};

		msTeams.initialize();
		void getInitialTheme();

		// Handle theme changes
		msTeams.registerOnThemeChangeHandler(function (theme: string): void {
			console.log("theme: " + theme);
		});
		msTeamsInitialized.current = true;
	}, [i18n]);

	useEffect(() => {
		// let's pass the strings we need to the translationManager of
		// the webshared components
		const translationVars: string[] = [
			"IDS_AV_ACCEPT_KNOCKING",
			"IDS_AV_HANGUP",
			"IDS_AV_CHOOSE_SPEAKER",
			"IDS_AV_UNMUTE_MICROPHONE",
			"IDS_AV_MUTE_MICROPHONE",
			"IDS_AV_CHOOSE_MICROPHONE",
			"IDS_AV_UNMUTE_CAM",
			"IDS_AV_MUTE_CAM",
			"IDS_AV_CHOOSE_CAM",
			"IDS_REACHABILITY_PAUSE",
			"IDS_REACHABILITY_BUSINESS_TRIP",
			"IDS_REACHABILITY_HOMEOFFICE",
			"IDS_REACHABILITY_VACATION",
			"IDS_AGENT_STATE_READY",
			"IDS_AGENT_STATE_BUSY",
			"IDS_AGENT_STATE_WORKINGAFTERCALL",
			"IDS_PRESENCE_AVAILABLE",
			"IDS_PRESENCE_BUSY",
			"IDS_PRESENCE_DND",
			"IDS_PRESENCE_ABSENT",
			"IDS_APPOINTMENT_NO_NEXT24HRS",
			"IDS_APPOINTMENT_NO_FURTHER_NEXT24HRS",
			"IDS_APPOINTMENT_HEADER",
			"IDS_STATE_AVAILABLE_MOBILE",
			"IDS_STATE_AVAILABLE",
			"IDS_APPOINTMENT_HEADER",
			"IDS_APPOINTMENT_NEXT",
			"IDS_APPOINTMENT_ALLDAY",
			"IDS_UNKNOWN_USER",
			"IDS_PRESENCE_IDLE",
			"IDS_PRESENCE_OFFLINE",
			"IDS_UNKNOWN",
			"IDS_INFORMATION_HEADER",
			"IDS_CHANNELS_HEADER",
			"IDS_CALL_CONNECTED",
			"IDS_CALL_RINGING",
			"IDS_HAS_MEETING",
			"IDS_APPOINTMENT_SENSITIVITY_PRIVATE",
			"IDS_CALL_FORWARD",
			"IDS_LINE_OFFLINE",
			"IDS_CHAT_MSG_VIDEOCHAT",
			"IDS_CHANNEL_CONVERSATION",
			"IDS_CHANNEL_MEETING",
			"IDS_CHANNEL_CALL_PROTECTION"
		];

		const translations: { [key: string]: string } = {};

		for (const tvar of translationVariables) {
			translations[tvar] = t(tvar);
		}

		for (const tvar of translationVars) {
			translations[tvar] = t(tvar);
		}
		theTranslationManager.setTranslations(translations);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language]);

	return (
		<HelmetProvider>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={MuiTheme}>
					<CssBaseline />
					<WarningsSnackbarProvider>
						<LocalizationProvider dateAdapter={AdapterLuxon}>
							<Helmet />
							<Router />
						</LocalizationProvider>
					</WarningsSnackbarProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</HelmetProvider>
	);
}

export default App;
