import { groupBy, orderBy } from "lodash";
import { StateCreator } from "zustand";

import { AsnNetDatabaseJournal } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal";
import { addSipPrefixIfNotExists } from "../lib/commonHelper";
import { ReadStatusE } from "./configSlice";
import { StoreT } from "./store";

// number of journal entries to use for contact frequency calculation
const contactFrequencyJournalEntriesCount = 99;

export type JournalSliceT = IState & IActions;

export interface IAsnNetDatabaseJournalEx extends AsnNetDatabaseJournal {
	u8sSIPAddress: string;
}

/**
 * Replace existing or add new journal entries
 * @param prevJournalEntries - previous journal entries
 * @param journalEntries - new journal entries
 * @returns updated journal entries
 */
function getNewJournalEntries(
	prevJournalEntries: Map<string, IAsnNetDatabaseJournalEx>,
	journalEntries: IAsnNetDatabaseJournalEx[]
) {
	return new Map<string, IAsnNetDatabaseJournalEx>([
		...prevJournalEntries,
		...new Map(journalEntries.map((journalEntry) => [journalEntry.u8sConnectionID, journalEntry]))
	]);
}

/**
 * Get contact ids from journal entries by frequency
 * @param journalEntries - journal entries
 * @returns contact ids
 */
function getContactIdsByFrequency(journalEntries: Map<string, IAsnNetDatabaseJournalEx>): Set<string> {
	const selectedJournalEntries = [...journalEntries]
		.slice(0, contactFrequencyJournalEntriesCount)
		.map(([, journalEntry]) => journalEntry);
	const journalEntriesGroupedByContactId = groupBy(selectedJournalEntries, (journalEntry) =>
		addSipPrefixIfNotExists(journalEntry.u8sSIPAddress.toLowerCase() || journalEntry.u8sContactEntryID)
	);
	const journalEntriesOrderedByFrequency = orderBy(
		journalEntriesGroupedByContactId,
		(journalEntries) => journalEntries.length,
		["desc"]
	);
	const journalContactIds = new Set(
		journalEntriesOrderedByFrequency
			.map((journalEntries) =>
				addSipPrefixIfNotExists(journalEntries[0].u8sSIPAddress.toLowerCase() || journalEntries[0].u8sContactEntryID)
			)
			.filter((contactId) => contactId)
	);
	const otherEntries = [...journalEntries].filter(
		([, item]) => !journalContactIds.has(addSipPrefixIfNotExists(item.u8sContactEntryID.toLowerCase()))
	);

	for (const [, entry] of otherEntries) {
		journalContactIds.add(entry.u8sContactEntryID);
	}

	return journalContactIds;
}

interface IState {
	journalEntries: Map<string, IAsnNetDatabaseJournalEx>;
	journalContactIds: Set<string>;
	iLastTransactionID: number;
}

interface IActions {
	setJournals: (journalEntries: IAsnNetDatabaseJournalEx[]) => void;
	deleteJournals: (u8sConnectionIDList: string[]) => void;
	updateJournalReadFlags: (u8sConnectionIDList: string[], bReadFlag: boolean) => void;
	updateJournalMemo: (u8sConnectionIDList: string[], u8sMemo: string) => void;
	getFilteredJournalEntries: (filter: string) => IAsnNetDatabaseJournalEx[];
}

export const initialState = {
	journalEntries: new Map(),
	journalContactIds: new Set<string>(),
	iLastTransactionID: 0
};

export const createJournalSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	JournalSliceT
> = (set, get) => ({
	...initialState,
	setJournals: (journalEntries: IAsnNetDatabaseJournalEx[]) => {
		set(
			(prev) => {
				const updatedJournalEntries = getNewJournalEntries(prev.journalEntries, journalEntries);
				const sortedJournalEntries = new Map(
					orderBy([...updatedJournalEntries.entries()], ([, journalEntry]) => journalEntry.stStartTime, ["desc"])
				);

				return {
					...prev,
					journalEntries: sortedJournalEntries,
					journalContactIds: getContactIdsByFrequency(sortedJournalEntries)
				};
			},
			false,
			"setJournals"
		);
	},
	deleteJournals: (u8sConnectionIDList: string[]) => {
		set(
			(prev) => {
				const updatedJournalEntries = new Map(
					[...prev.journalEntries].filter(([key]) => !u8sConnectionIDList.includes(key))
				);
				return {
					...prev,
					journalEntries: updatedJournalEntries,
					journalContactIds: getContactIdsByFrequency(updatedJournalEntries)
				};
			},
			false,
			"deleteJournal"
		);
	},
	updateJournalReadFlags: (u8sConnectionIDList: string[], bReadFlag: boolean) => {
		set(
			(prev) => ({
				...prev,
				journalEntries: new Map(
					[...prev.journalEntries].map(([key, value]) => {
						if (u8sConnectionIDList.includes(key)) {
							return [key, { ...value, iReadFlag: bReadFlag ? 1 : 0 }];
						}
						return [key, value];
					})
				)
			}),
			false,
			"updateJournalReadFlags"
		);
	},
	updateJournalMemo: (u8sConnectionIDList: string[], u8sMemo: string) => {
		set(
			(prev) => ({
				...prev,
				journalEntries: new Map(
					[...prev.journalEntries].map(([key, value]) => {
						if (u8sConnectionIDList.includes(key)) {
							return [key, { ...value, u8sMemo }];
						}
						return [key, value];
					})
				)
			}),
			false,
			"updateJournalMemo"
		);
	},
	getFilteredJournalEntries: (filter: string) => {
		const journalEntries = get().journalEntries;
		const filteredMap = [...journalEntries].filter(([, value]) => {
			if (filter === ReadStatusE.All) {
				return true;
			}
			if (filter === ReadStatusE.Unread) {
				return !value.iReadFlag;
			}
			return false;
		});
		return [...filteredMap].map(([, value]) => value);
	}
});
