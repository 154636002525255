import { AsnAgentStateEnum } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Agent";
import { AsnClientCapabilitiesV2 } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_ClientCapabilities";
import { AsnNetDatabaseContact } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import {
	AsnAppointmentEntry,
	SEQAppointmentList
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_Appointments";
import {
	AsnAbsentState,
	AsnReachabilityEnum
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import {
	AsnPresenceCalls,
	AsnPresenceForwards,
	AsnPresenceLines
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_PresenceV2";
import { IMSTeamsEmail } from "../../web-shared-components/interfaces/interfaces";

export interface IContentDetails {
	contentPic: IContentPic;
	u8sDetails: Map<string, string>;
	statusInfos: IDynamicStatusInfosCD | null;
	customFields: IKeyValuePair[];
	contactURLs: IKeyValuePair[];
}

export interface IContentPic {
	jpegPhoto?: string;
	jpegPhotoHash?: string;
}

// used for Contact Details
export interface IDynamicStatusInfosCD {
	presence: number | null | undefined;
	u8sNote: string | null | undefined;
	remoteContact: IRemoteContactCD | null | undefined;
	nextAppointment: AsnAppointmentEntry | null | undefined;
	currentAppointments: SEQAppointmentList | null | undefined;
	reachability: AsnReachabilityEnum;
	agentState: AsnAgentStateEnum;
	absentState?: AsnAbsentState | undefined;
	isMobileAvailable: boolean;
	msTeamsEmail?: IMSTeamsEmail;
	clientCapabilities?: AsnClientCapabilitiesV2 | undefined;
	seqCalls?: AsnPresenceCalls | undefined;
	seqPhoneLines?: AsnPresenceLines | undefined;
	seqLineForwards?: AsnPresenceForwards | undefined;
}

export interface IRemoteContactCD {
	outbound: number;
	displayName: string;
}

export interface IKeyValuePair {
	key: string;
	value: string;
}

export interface ICustomerLabels {
	CustLabels: IKeyValuePair[];
}

export interface IEntryContentStatusInfoCDJSON {
	iPresenceState: number | null | undefined;
	asnCustomNote: IEntryContentCustomNoteCDJSON | null | undefined;
	nextAppointment: AsnAppointmentEntry | null | undefined;
}

export interface IEntryContentCustomNoteCDJSON {
	u8sNote: string | null | undefined;
}

export interface IEditorContentDummyData extends AsnNetDatabaseContact {
	[key: string]: string | number | boolean | {} | undefined;

	statusInfos: {
		presence: number | undefined;
		asnCustomNote: string | undefined;
		asnNextAppointment: AsnAppointmentEntry | undefined;
		currentAppointments: SEQAppointmentList | undefined;
		reachability: AsnReachabilityEnum | undefined;
		agentState: AsnAgentStateEnum | undefined;
		isMobileAvailable: boolean | undefined;
		msTeamsEmail?: IMSTeamsEmail | undefined;
		seqCalls?: AsnPresenceCalls | undefined;
		seqPhoneLines: AsnPresenceLines | undefined;
		seqLineForwards?: AsnPresenceForwards | undefined;
	};
}

export const U8sAllowedNamesMap: Map<string, boolean> = new Map<string, boolean>([
	["iCtiServerUser", false],
	["iPrivateContact", false],
	["u8sAssistantTelephoneNumber", true],
	["u8sBody", true],
	["u8sBusinessAddressCity", true],
	["u8sBusinessAddressCountry", true],
	["u8sBusinessAddressPostalCode", true],
	["u8sBusinessAddressState", true],
	["u8sBusinessAddressStreet", true],
	["u8sCallbackTelephoneNumber", true],
	["u8sCarTelephoneNumber", true],
	["u8sCompany", true],
	["u8sCompanyMainTelephoneNumber", true],
	["u8sCtiServerUserName", false],
	["u8sCustomerID", true],
	["u8sDatabaseName", true],
	["u8sDepartment", true],
	["u8sDirectWebLink", false],
	["u8sDisplayName", true],
	["u8sEMail", true],
	["u8sEMail2", true],
	["u8sEMail3", true],
	["u8sEntryID", false],
	["u8sEntryIDDB", false],
	["u8sEntryIDStore", false],
	["u8sFaxBusiness", true],
	["u8sFaxHome", true],
	["u8sFirstName", true],
	["u8sFound", false],
	["u8sISDNTelephoneNumber", true],
	["u8sJobTitle", true],
	["u8sLastName", true],
	["u8sOfficeRoomNumber", true],
	["u8sOtherAddressCity", true],
	["u8sOtherAddressCountry", true],
	["u8sOtherAddressPostalCode", true],
	["u8sOtherAddressState", true],
	["u8sOtherAddressStreet", true],
	["u8sOtherTelephoneNumber", true],
	["u8sPagerTelephoneNumber", true],
	["u8sPhoneBusiness", true],
	["u8sPhoneBusiness2", true],
	["u8sPhoneHome", true],
	["u8sPhoneHome2", true],
	["u8sPhoneMobile", true],
	["u8sPrimaryTelephoneNumber", true],
	["u8sPrivateAddressCity", true],
	["u8sPrivateAddressCountry", true],
	["u8sPrivateAddressPostalCode", true],
	["u8sPrivateAddressState", true],
	["u8sPrivateAddressStreet", true],
	["u8sRadioTelephoneNumber", true],
	["u8sSIPAddress", true],
	["u8sTTYTTDTelephoneNumber", true],
	["u8sWebPageURL", true]
]);

// ----- helper functions to create empty objects ------

/**
 * Creates an empty Status Info Object
 * @returns an IDynamicStatusInfosCD
 */
export function FnCreateEmptyStatusInfos(): IDynamicStatusInfosCD {
	return {
		presence: 0,
		u8sNote: null,
		remoteContact: { outbound: 0, displayName: "" },
		nextAppointment: undefined,
		currentAppointments: undefined,
		reachability: AsnReachabilityEnum.eREACHABILITYNORMAL,
		agentState: AsnAgentStateEnum.eAGENTSTATENOTREADY,
		isMobileAvailable: false
	};
}

/**
 * Creates an empty Content Details Object
 * @returns an IContentDetails
 */
export function FnCreateEmptyContentDetails(): IContentDetails {
	return {
		contentPic: {},
		u8sDetails: new Map<string, string>(),
		statusInfos: FnCreateEmptyStatusInfos(),
		customFields: [],
		contactURLs: []
	};
}

/**
 * Creates an empty IContentPic Object
 * @returns an IContentPic
 */
export function FnCreateEmptyContentPic(): IContentPic {
	return {
		jpegPhoto: "",
		jpegPhotoHash: ""
	};
}
