// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * SNACCROSE_Converter.ts
 * "SNACC-ROSE" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.23, 20.10.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as SNACCROSE from "./SNACCROSE";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
// [PrintTSRootTypes]
export const moduleName = "SNACCROSE_Converter";

// [PrintTSEncoderDecoderCode]
export class ROSERejectChoice_Converter {
	public static toJSON(s: SNACCROSE.ROSERejectChoice, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSERejectChoice & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSERejectChoice");

		const t = {} as SNACCROSE.ROSERejectChoice & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSERejectChoice";
		if (s.invokedID != null)
			TSConverter.fillJSONParam(s, t, "invokedID", "number", errors, newContext);
		else if (s.invokednull != null)
			TSConverter.fillJSONParam(s, t, "invokednull", "object", errors, newContext);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "ROSERejectChoice"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSERejectChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSERejectChoice");

		let t: SNACCROSE.ROSERejectChoice | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSERejectChoice>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSERejectChoice["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.invokedID !== undefined) {
				if (TSConverter.validateParam(s, "invokedID", "number", errors, newContext))
					t.invokedID = s.invokedID;
			} else if (s.invokednull !== undefined) {
				if (TSConverter.validateParam(s, "invokednull", "object", errors, newContext))
					t.invokednull = s.invokednull;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}
		if (errors.validateResult(newContext, "ROSERejectChoice"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSERejectChoice | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "ROSERejectChoice";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSERejectChoice");

		// [Print_BER_EncoderChoiceDefCode]
		if (TSConverter.validateParam(s, "invokedID", "number", errors, newContext, true))
			t = new asn1ts.Integer({ value: s.invokedID, name: "invokedID" });
		else if (TSConverter.validateParam(s, "invokednull", "object", errors, newContext, true))
			t = new asn1ts.Null({ name: "invokednull" });
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
		if (errors.validateResult(newContext, "ROSERejectChoice"))
			return t;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSERejectChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSERejectChoice");

		let t: SNACCROSE.ROSERejectChoice | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSERejectChoice.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSERejectChoice["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "invokedID" && asn1ts.Integer.typeGuard(s)) {
				const _invokedID = s.getValue();
				if (TSConverter.validateParamType(_invokedID, "invokedID", "number", errors, newContext, false))
					t.invokedID = _invokedID;
			} else if (s.choiceName === "invokednull" && asn1ts.Null.typeGuard(s)) {
				const _invokednull = s.getValue();
				if (TSConverter.validateParamType(_invokednull, "invokednull", "object", errors, newContext, false))
					t.invokednull = _invokednull;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "ROSERejectChoice"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEResultSeq_Converter {
	public static toJSON(s: SNACCROSE.ROSEResultSeq, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEResultSeq & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEResultSeq");

		const t = {} as SNACCROSE.ROSEResultSeq & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSEResultSeq";
		TSConverter.fillJSONParam(s, t, "resultValue", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "result", "object", errors, newContext);

		if (errors.validateResult(newContext, "ROSEResultSeq"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEResultSeq | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEResultSeq");

		let t: SNACCROSE.ROSEResultSeq | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEResultSeq>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEResultSeq["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "resultValue", "number", errors, context, false);
			if (TSConverter.validateAnyParam(s, "result", errors, newContext, false))
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				t.result = s.result;
		}
		if (errors.validateResult(newContext, "ROSEResultSeq"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEResultSeq | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "ROSEResultSeq";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEResultSeq");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "resultValue", "number", errors, newContext);
		TSConverter.validateParam(s, "result", "object", errors, newContext);
		if (errors.validateResult(newContext, "ROSEResultSeq")) {
			t.push(new asn1ts.Integer({ value: s.resultValue, name: "resultValue" }));
			t.push(s.result as asn1ts.Sequence);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEResultSeq | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEResultSeq");

		let t: SNACCROSE.ROSEResultSeq | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEResultSeq.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = SNACCROSE.ROSEResultSeq["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "resultValue", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "result", "Any", errors, newContext);
		}

		if (errors.validateResult(newContext, "ROSEResultSeq"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEAuth_Converter {
	public static toJSON(s: SNACCROSE.ROSEAuth, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEAuth & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEAuth");

		const t = {} as SNACCROSE.ROSEAuth & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSEAuth";
		TSConverter.fillJSONParam(s, t, "method", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "authdata", "Uint8Array", errors, newContext);

		if (errors.validateResult(newContext, "ROSEAuth"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEAuth | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEAuth");

		let t: SNACCROSE.ROSEAuth | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEAuth>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEAuth["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "method", "string", errors, context, false);
			if (TSConverter.validateParam(s, "authdata", "string", errors, context, false))
				t.authdata = TSConverter.decode64(s.authdata as unknown as string);
		}
		if (errors.validateResult(newContext, "ROSEAuth"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEAuth | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "ROSEAuth";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEAuth");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "method", "string", errors, newContext);
		TSConverter.validateParam(s, "authdata", "Uint8Array", errors, newContext);
		if (errors.validateResult(newContext, "ROSEAuth")) {
			t.push(new asn1ts.Utf8String({ value: s.method, name: "method" }));
			t.push(new asn1ts.OctetString({ valueHex: s.authdata, name: "authdata" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEAuth | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEAuth");

		let t: SNACCROSE.ROSEAuth | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEAuth.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = SNACCROSE.ROSEAuth["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "method", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "authdata", "OctetString", errors, newContext);
		}

		if (errors.validateResult(newContext, "ROSEAuth"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEError_Converter {
	public static toJSON(s: SNACCROSE.ROSEError, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEError & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEError");

		const t = {} as SNACCROSE.ROSEError & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSEError";
		TSConverter.fillJSONParam(s, t, "sessionID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "invokedID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "error_value", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "error", "object", errors, newContext, true);

		if (errors.validateResult(newContext, "ROSEError"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEError");

		let t: SNACCROSE.ROSEError | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEError>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEError["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "sessionID", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "invokedID", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "error_value", "number", errors, context, false);
			if (TSConverter.validateAnyParam(s, "error", errors, newContext, true))
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				t.error = s.error;
		}
		if (errors.validateResult(newContext, "ROSEError"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEError | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "ROSEError";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEError");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sessionID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "invokedID", "number", errors, newContext);
		TSConverter.validateParam(s, "error_value", "number", errors, newContext);
		TSConverter.validateParam(s, "error", "object", errors, newContext, true);
		if (errors.validateResult(newContext, "ROSEError")) {
			if (s.sessionID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.sessionID, name: "sessionID", idBlock: { optionalID: 1 } }));
			t.push(new asn1ts.Integer({ value: s.invokedID, name: "invokedID" }));
			t.push(new asn1ts.Integer({ value: s.error_value, name: "error_value" }));
			if (s.error !== undefined)
				t.push(s.error as asn1ts.Sequence);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEError");

		let t: SNACCROSE.ROSEError | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEError.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = SNACCROSE.ROSEError["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sessionID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "invokedID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "error_value", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "error", "Any", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "ROSEError"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEAuthList_Converter {
	public static toJSON(s: SNACCROSE.ROSEAuthList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEAuthList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEAuthList");

		const t = [] as SNACCROSE.ROSEAuthList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = ROSEAuth_Converter.toJSON(se, errors, newContext, "ROSEAuth");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "ROSEAuthList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEAuthList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEAuthList");

		let t: SNACCROSE.ROSEAuthList | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEAuthList>(data, errors, newContext, optional);
		if (s) {
			t = new SNACCROSE.ROSEAuthList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = ROSEAuth_Converter.fromJSON(se, errors, newContext, "ROSEAuth", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "ROSEAuthList"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEAuthList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "ROSEAuthList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEAuthList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = ROSEAuth_Converter.toBER(s[id], errors, newContext, "ROSEAuth");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "ROSEAuth"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEAuthList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEAuthList");

		let t: SNACCROSE.ROSEAuthList | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEAuthList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new SNACCROSE.ROSEAuthList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = ROSEAuth_Converter.fromBER(se, errors, newContext, "ROSEAuth", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "ROSEAuthList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEAuthRequest_Converter {
	public static toJSON(s: SNACCROSE.ROSEAuthRequest, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEAuthRequest & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEAuthRequest");

		const t = {} as SNACCROSE.ROSEAuthRequest & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSEAuthRequest";
		const _auth = ROSEAuth_Converter.toJSON(s.auth, errors, newContext, "auth");
		if (_auth)
			t.auth = _auth;
		TSConverter.fillJSONParam(s, t, "context", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "ROSEAuthRequest"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEAuthRequest | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEAuthRequest");

		let t: SNACCROSE.ROSEAuthRequest | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEAuthRequest>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEAuthRequest["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _auth = ROSEAuth_Converter.fromJSON(s.auth, errors, newContext, "auth", false);
			if (_auth)
				t.auth = _auth;
			TSConverter.fillJSONParam(s, t, "context", "string", errors, context, true);
		}
		if (errors.validateResult(newContext, "ROSEAuthRequest"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEAuthRequest | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "ROSEAuthRequest";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEAuthRequest");

		// [Print_BER_EncoderSeqDefCode]
		const _auth = ROSEAuth_Converter.toBER(s.auth, errors, newContext, "auth");
		TSConverter.validateParam(s, "context", "string", errors, newContext, true);
		if (errors.validateResult(newContext, "ROSEAuthRequest")) {
			if (_auth)
				t.push(_auth);
			if (s.context !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.context, name: "context" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEAuthRequest | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEAuthRequest");

		let t: SNACCROSE.ROSEAuthRequest | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEAuthRequest.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = SNACCROSE.ROSEAuthRequest["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _auth = ROSEAuth_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "auth"), errors, newContext, "auth");
			if (_auth)
				t.auth = _auth;
			TSConverter.fillASN1Param(s, t, "context", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "ROSEAuthRequest"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEAuthResult_Converter {
	public static toJSON(s: SNACCROSE.ROSEAuthResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEAuthResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEAuthResult");

		const t = {} as SNACCROSE.ROSEAuthResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSEAuthResult";
		const _authList = ROSEAuthList_Converter.toJSON(s.authList, errors, newContext, "authList");
		if (_authList)
			t.authList = _authList;
		TSConverter.fillJSONParam(s, t, "context", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "ROSEAuthResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEAuthResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEAuthResult");

		let t: SNACCROSE.ROSEAuthResult | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEAuthResult>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEAuthResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _authlist = ROSEAuthList_Converter.fromJSON(s.authList, errors, newContext, "authList", false);
			if (_authlist)
				t.authList = _authlist;
			TSConverter.fillJSONParam(s, t, "context", "string", errors, context, true);
		}
		if (errors.validateResult(newContext, "ROSEAuthResult"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEAuthResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "ROSEAuthResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEAuthResult");

		// [Print_BER_EncoderSeqDefCode]
		const _authList = ROSEAuthList_Converter.toBER(s.authList, errors, newContext, "authList");
		TSConverter.validateParam(s, "context", "string", errors, newContext, true);
		if (errors.validateResult(newContext, "ROSEAuthResult")) {
			if (_authList)
				t.push(_authList);
			if (s.context !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.context, name: "context" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEAuthResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEAuthResult");

		let t: SNACCROSE.ROSEAuthResult | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEAuthResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = SNACCROSE.ROSEAuthResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _authlist = ROSEAuthList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "authList"), errors, newContext, "authList");
			if (_authlist)
				t.authList = _authlist;
			TSConverter.fillASN1Param(s, t, "context", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "ROSEAuthResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEInvoke_Converter {
	public static toJSON(s: SNACCROSE.ROSEInvoke, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEInvoke & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEInvoke");

		const t = {} as SNACCROSE.ROSEInvoke & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSEInvoke";
		TSConverter.fillJSONParam(s, t, "sessionID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "invokeID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "linked_ID", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "operationName", "string", errors, newContext, true);
		if (s.authentication) {
			const _authentication = ROSEAuthRequest_Converter.toJSON(s.authentication, errors, newContext, "authentication");
			if (_authentication)
				t.authentication = _authentication;
		}
		TSConverter.fillJSONParam(s, t, "operationID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "argument", "object", errors, newContext, true);

		if (errors.validateResult(newContext, "ROSEInvoke"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEInvoke | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEInvoke");

		let t: SNACCROSE.ROSEInvoke | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEInvoke>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEInvoke["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "sessionID", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "invokeID", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "linked_ID", "number", errors, context, true);
			TSConverter.fillJSONParam(s, t, "operationName", "string", errors, context, true);
			t.authentication = ROSEAuthRequest_Converter.fromJSON(s.authentication, errors, newContext, "authentication", true);
			TSConverter.fillJSONParam(s, t, "operationID", "number", errors, context, false);
			if (TSConverter.validateAnyParam(s, "argument", errors, newContext, true))
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				t.argument = s.argument;
		}
		if (errors.validateResult(newContext, "ROSEInvoke"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEInvoke | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "ROSEInvoke";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEInvoke");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sessionID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "invokeID", "number", errors, newContext);
		TSConverter.validateParam(s, "linked_ID", "number", errors, newContext, true);
		TSConverter.validateParam(s, "operationName", "string", errors, newContext, true);
		const _authentication = ROSEAuthRequest_Converter.toBER(s.authentication, errors, newContext, "authentication", 3);
		TSConverter.validateParam(s, "operationID", "number", errors, newContext);
		TSConverter.validateParam(s, "argument", "object", errors, newContext, true);
		if (errors.validateResult(newContext, "ROSEInvoke")) {
			if (s.sessionID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.sessionID, name: "sessionID", idBlock: { optionalID: 1 } }));
			t.push(new asn1ts.Integer({ value: s.invokeID, name: "invokeID" }));
			if (s.linked_ID !== undefined)
				t.push(new asn1ts.Integer({ value: s.linked_ID, name: "linked_ID", idBlock: { optionalID: 0 } }));
			if (s.operationName !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.operationName, name: "operationName", idBlock: { optionalID: 2 } }));
			if (_authentication)
				t.push(_authentication);
			t.push(new asn1ts.Integer({ value: s.operationID, name: "operationID" }));
			if (s.argument !== undefined)
				t.push(s.argument as asn1ts.Sequence);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEInvoke | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEInvoke");

		let t: SNACCROSE.ROSEInvoke | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEInvoke.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = SNACCROSE.ROSEInvoke["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sessionID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "invokeID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "linked_ID", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "operationName", "Utf8String", errors, newContext, true);
			t.authentication = ROSEAuthRequest_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "authentication"), errors, newContext, "authentication", true);
			TSConverter.fillASN1Param(s, t, "operationID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "argument", "Any", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "ROSEInvoke"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEResult_Converter {
	public static toJSON(s: SNACCROSE.ROSEResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEResult");

		const t = {} as SNACCROSE.ROSEResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSEResult";
		TSConverter.fillJSONParam(s, t, "sessionID", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "invokeID", "number", errors, newContext);
		if (s.result) {
			const _result = ROSEResultSeq_Converter.toJSON(s.result, errors, newContext, "result");
			if (_result)
				t.result = _result;
		}

		if (errors.validateResult(newContext, "ROSEResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEResult");

		let t: SNACCROSE.ROSEResult | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEResult>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "sessionID", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "invokeID", "number", errors, context, false);
			t.result = ROSEResultSeq_Converter.fromJSON(s.result, errors, newContext, "result", true);
		}
		if (errors.validateResult(newContext, "ROSEResult"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "ROSEResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sessionID", "string", errors, newContext, true);
		TSConverter.validateParam(s, "invokeID", "number", errors, newContext);
		const _result = ROSEResultSeq_Converter.toBER(s.result, errors, newContext, "result", true);
		if (errors.validateResult(newContext, "ROSEResult")) {
			if (s.sessionID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.sessionID, name: "sessionID", idBlock: { optionalID: 1 } }));
			t.push(new asn1ts.Integer({ value: s.invokeID, name: "invokeID" }));
			if (_result)
				t.push(_result);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEResult");

		let t: SNACCROSE.ROSEResult | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = SNACCROSE.ROSEResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sessionID", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "invokeID", "Integer", errors, newContext);
			t.result = ROSEResultSeq_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "result"), errors, newContext, "result", true);
		}

		if (errors.validateResult(newContext, "ROSEResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class RejectProblem_Converter {
	public static toJSON(s: SNACCROSE.RejectProblem, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.RejectProblem & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "RejectProblem");

		const t = {} as SNACCROSE.RejectProblem & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "RejectProblem";
		if (s.generalProblem != null)
			TSConverter.fillJSONParam(s, t, "generalProblem", "number", errors, newContext);
		else if (s.invokeProblem != null)
			TSConverter.fillJSONParam(s, t, "invokeProblem", "number", errors, newContext);
		else if (s.returnResultProblem != null)
			TSConverter.fillJSONParam(s, t, "returnResultProblem", "number", errors, newContext);
		else if (s.returnErrorProblem != null)
			TSConverter.fillJSONParam(s, t, "returnErrorProblem", "number", errors, newContext);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "RejectProblem"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.RejectProblem | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "RejectProblem");

		let t: SNACCROSE.RejectProblem | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.RejectProblem>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.RejectProblem["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.generalProblem !== undefined) {
				if (TSConverter.validateParam(s, "generalProblem", "number", errors, newContext))
					t.generalProblem = s.generalProblem;
			} else if (s.invokeProblem !== undefined) {
				if (TSConverter.validateParam(s, "invokeProblem", "number", errors, newContext))
					t.invokeProblem = s.invokeProblem;
			} else if (s.returnResultProblem !== undefined) {
				if (TSConverter.validateParam(s, "returnResultProblem", "number", errors, newContext))
					t.returnResultProblem = s.returnResultProblem;
			} else if (s.returnErrorProblem !== undefined) {
				if (TSConverter.validateParam(s, "returnErrorProblem", "number", errors, newContext))
					t.returnErrorProblem = s.returnErrorProblem;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}
		if (errors.validateResult(newContext, "RejectProblem"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.RejectProblem | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "RejectProblem";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "RejectProblem");

		// [Print_BER_EncoderChoiceDefCode]
		if (TSConverter.validateParam(s, "generalProblem", "number", errors, newContext, true))
			t = new asn1ts.Integer({ value: s.generalProblem, name: "generalProblem", idBlock: { optionalID: 0 } });
		else if (TSConverter.validateParam(s, "invokeProblem", "number", errors, newContext, true))
			t = new asn1ts.Integer({ value: s.invokeProblem, name: "invokeProblem", idBlock: { optionalID: 1 } });
		else if (TSConverter.validateParam(s, "returnResultProblem", "number", errors, newContext, true))
			t = new asn1ts.Integer({ value: s.returnResultProblem, name: "returnResultProblem", idBlock: { optionalID: 2 } });
		else if (TSConverter.validateParam(s, "returnErrorProblem", "number", errors, newContext, true))
			t = new asn1ts.Integer({ value: s.returnErrorProblem, name: "returnErrorProblem", idBlock: { optionalID: 3 } });
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
		if (errors.validateResult(newContext, "RejectProblem"))
			return t;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.RejectProblem | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "RejectProblem");

		let t: SNACCROSE.RejectProblem | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.RejectProblem.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.RejectProblem["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "generalProblem" && asn1ts.Integer.typeGuard(s)) {
				const _generalProblem = s.getValue();
				if (TSConverter.validateParamType(_generalProblem, "generalProblem", "number", errors, newContext, false))
					t.generalProblem = _generalProblem;
			} else if (s.choiceName === "invokeProblem" && asn1ts.Integer.typeGuard(s)) {
				const _invokeProblem = s.getValue();
				if (TSConverter.validateParamType(_invokeProblem, "invokeProblem", "number", errors, newContext, false))
					t.invokeProblem = _invokeProblem;
			} else if (s.choiceName === "returnResultProblem" && asn1ts.Integer.typeGuard(s)) {
				const _returnResultProblem = s.getValue();
				if (TSConverter.validateParamType(_returnResultProblem, "returnResultProblem", "number", errors, newContext, false))
					t.returnResultProblem = _returnResultProblem;
			} else if (s.choiceName === "returnErrorProblem" && asn1ts.Integer.typeGuard(s)) {
				const _returnErrorProblem = s.getValue();
				if (TSConverter.validateParamType(_returnErrorProblem, "returnErrorProblem", "number", errors, newContext, false))
					t.returnErrorProblem = _returnErrorProblem;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "RejectProblem"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEReject_Converter {
	public static toJSON(s: SNACCROSE.ROSEReject, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEReject & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEReject");

		const t = {} as SNACCROSE.ROSEReject & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSEReject";
		TSConverter.fillJSONParam(s, t, "sessionID", "string", errors, newContext, true);
		const _invokedID = ROSERejectChoice_Converter.toJSON(s.invokedID, errors, newContext, "invokedID");
		if (_invokedID)
			t.invokedID = _invokedID;
		if (s.reject) {
			const _reject = RejectProblem_Converter.toJSON(s.reject, errors, newContext, "reject");
			if (_reject)
				t.reject = _reject;
		}
		TSConverter.fillJSONParam(s, t, "details", "string", errors, newContext, true);
		if (s.authentication) {
			const _authentication = ROSEAuthResult_Converter.toJSON(s.authentication, errors, newContext, "authentication");
			if (_authentication)
				t.authentication = _authentication;
		}

		if (errors.validateResult(newContext, "ROSEReject"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEReject | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEReject");

		let t: SNACCROSE.ROSEReject | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEReject>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEReject["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "sessionID", "string", errors, context, true);
			const _invokedid = ROSERejectChoice_Converter.fromJSON(s.invokedID, errors, newContext, "invokedID", false);
			if (_invokedid)
				t.invokedID = _invokedid;
			t.reject = RejectProblem_Converter.fromJSON(s.reject, errors, newContext, "reject", true);
			TSConverter.fillJSONParam(s, t, "details", "string", errors, context, true);
			t.authentication = ROSEAuthResult_Converter.fromJSON(s.authentication, errors, newContext, "authentication", true);
		}
		if (errors.validateResult(newContext, "ROSEReject"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEReject | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "ROSEReject";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEReject");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sessionID", "string", errors, newContext, true);
		const _invokedID = ROSERejectChoice_Converter.toBER(s.invokedID, errors, newContext, "invokedID");
		const _reject = RejectProblem_Converter.toBER(s.reject, errors, newContext, "reject", true);
		TSConverter.validateParam(s, "details", "string", errors, newContext, true);
		const _authentication = ROSEAuthResult_Converter.toBER(s.authentication, errors, newContext, "authentication", 4);
		if (errors.validateResult(newContext, "ROSEReject")) {
			if (s.sessionID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.sessionID, name: "sessionID", idBlock: { optionalID: 1 } }));
			if (_invokedID)
				t.push(_invokedID);
			if (_reject)
				t.push(_reject);
			if (s.details !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.details, name: "details" }));
			if (_authentication)
				t.push(_authentication);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEReject | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEReject");

		let t: SNACCROSE.ROSEReject | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEReject.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = SNACCROSE.ROSEReject["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sessionID", "Utf8String", errors, newContext, true);
			const _invokedid = ROSERejectChoice_Converter.fromBER(s.getValueByName("invokedID"), errors, newContext, "invokedID");
			if (_invokedid)
				t.invokedID = _invokedid;
			t.reject = RejectProblem_Converter.fromBER(s.getValueByName("reject"), errors, newContext, "reject", true);
			TSConverter.fillASN1Param(s, t, "details", "Utf8String", errors, newContext, true);
			t.authentication = ROSEAuthResult_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "authentication"), errors, newContext, "authentication", true);
		}

		if (errors.validateResult(newContext, "ROSEReject"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class ROSEMessage_Converter {
	public static toJSON(s: SNACCROSE.ROSEMessage, errors?: ConverterErrors, context?: IEncodeContext, name?: string): SNACCROSE.ROSEMessage & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEMessage");

		const t = {} as SNACCROSE.ROSEMessage & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "ROSEMessage";
		if (s.invoke != null) {
			const _invoke = ROSEInvoke_Converter.toJSON(s.invoke, errors, newContext, "invoke");
			if (_invoke)
				t.invoke = _invoke;
		} else if (s.result != null) {
			const _result = ROSEResult_Converter.toJSON(s.result, errors, newContext, "result");
			if (_result)
				t.result = _result;
		} else if (s.error != null) {
			const _error = ROSEError_Converter.toJSON(s.error, errors, newContext, "error");
			if (_error)
				t.error = _error;
		} else if (s.reject != null) {
			const _reject = ROSEReject_Converter.toJSON(s.reject, errors, newContext, "reject");
			if (_reject)
				t.reject = _reject;
		} else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "ROSEMessage"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEMessage | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEMessage");

		let t: SNACCROSE.ROSEMessage | undefined;
		const s = TSConverter.prepareJSONData<SNACCROSE.ROSEMessage>(data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEMessage["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.invoke !== undefined)
				t.invoke = ROSEInvoke_Converter.fromJSON(s.invoke, errors, newContext, "invoke", false);
			else if (s.result !== undefined)
				t.result = ROSEResult_Converter.fromJSON(s.result, errors, newContext, "result", false);
			else if (s.error !== undefined)
				t.error = ROSEError_Converter.fromJSON(s.error, errors, newContext, "error", false);
			else if (s.reject !== undefined)
				t.reject = ROSEReject_Converter.fromJSON(s.reject, errors, newContext, "reject", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}
		if (errors.validateResult(newContext, "ROSEMessage"))
			return t;

		return undefined;
	}

	public static toBER(s: SNACCROSE.ROSEMessage | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "ROSEMessage";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "ROSEMessage");

		// [Print_BER_EncoderChoiceDefCode]
		if (s.invoke)
			t = ROSEInvoke_Converter.toBER(s.invoke, errors, newContext, "invoke", 1);
		else if (s.result)
			t = ROSEResult_Converter.toBER(s.result, errors, newContext, "result", 2);
		else if (s.error)
			t = ROSEError_Converter.toBER(s.error, errors, newContext, "error", 3);
		else if (s.reject)
			t = ROSEReject_Converter.toBER(s.reject, errors, newContext, "reject", 4);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
		if (errors.validateResult(newContext, "ROSEMessage"))
			return t;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): SNACCROSE.ROSEMessage | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "ROSEMessage");

		let t: SNACCROSE.ROSEMessage | undefined;
		const s = TSConverter.prepareASN1BERData(SNACCROSE.ROSEMessage.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = SNACCROSE.ROSEMessage["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "invoke" && asn1ts.Sequence.typeGuard(s))
				t.invoke = ROSEInvoke_Converter.fromBER(s, undefined, newContext, "invoke", false);
			else if (s.choiceName === "result" && asn1ts.Sequence.typeGuard(s))
				t.result = ROSEResult_Converter.fromBER(s, undefined, newContext, "result", false);
			else if (s.choiceName === "error" && asn1ts.Sequence.typeGuard(s))
				t.error = ROSEError_Converter.fromBER(s, undefined, newContext, "error", false);
			else if (s.choiceName === "reject" && asn1ts.Sequence.typeGuard(s))
				t.reject = ROSEReject_Converter.fromBER(s, undefined, newContext, "reject", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "ROSEMessage"))
			return t;

		return undefined;
	}
}
