export default class SpectrumAnalyzer {
	private audioContext: AudioContext;
	private audioStream: MediaStream | undefined;
	private audioSource: MediaStreamAudioSourceNode | undefined;
	public analyzer: AnalyserNode | undefined;
	public dataArray: Uint8Array;
	public bufferLength: number;

	constructor(audioStream: MediaStream) {
		this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
		this.audioStream = audioStream;
		this.audioSource = this.audioContext.createMediaStreamSource(this.audioStream);
		this.analyzer = this.audioContext.createAnalyser();
		this.audioSource.connect(this.analyzer);
		// To avoid duplicated sound
		// this.analyzer.connect(this.audioContext.destination);
		this.analyzer.fftSize = 32;
		this.bufferLength = this.analyzer.frequencyBinCount;
		this.dataArray = new Uint8Array(this.bufferLength);
	}

}
