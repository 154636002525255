import { DetailTypeE, StyleTypeE, SubTypeE } from "./ModelConfig";
import { FnCreateEmptyStatusInfos, IDynamicStatusInfosCD } from "./ModelContent";

export type WindowTypeT =
	| "empty"
	| "contactDetails"
	| "remoteContact"
	| "holdContact"
	| "localContact"
	| "redirectContact"
	| "chatContactCard";

export interface IContact {
	displayName: string;
	type: WindowTypeT;
	pict: ICombiPic;
	statusInfos: IDynamicStatusInfosCD;
	combiBlocks: ICombiBlock[];
}

export interface ICombiBlock {
	displayLabel: string;
	type: DetailTypeE;
	style: StyleTypeE[] | null;
	combiDetails: ICombiDetail[];
}

export interface ICombiDetail {
	displayLabel: string;
	type: DetailTypeE;
	subtype: SubTypeE | null;
	style: StyleTypeE[];
	content: string;
	combiDetails: ICombiDetail[] | ICombiCustomField | null; // ICombiDetail[] if addressBlock || ICombiCustomField if customField
}

export interface ICombiCustomField {
	key: string;
	value: string;
}

export interface ICombiPic {
	initials: string;
	jpegPhoto: string;
	visible: boolean;
}

// ----- helper functions to create empty objects ------
/**
 * Create an empty Contact
 *
 * @returns a contactT object
 */
export function FnCreateEmptyContact(): IContact {
	const emptyContact: IContact = {
		displayName: "",
		type: "empty",
		pict: { initials: "", jpegPhoto: "", visible: false },
		statusInfos: FnCreateEmptyStatusInfos(),
		combiBlocks: []
	};

	return emptyContact;
}
