import { head } from "lodash";

import { ENetUC_ClientPersistence } from "../../web-shared-components/asn1/EUCSrv/stubs/types";
import { GetContactDetails } from "../lib/api/GetContactDetails";
import { GetCustomLabels } from "../lib/api/GetCustomLabels";
import { SocketTransport } from "../session/SocketTransport";
import { getState } from "../zustand/store";

type ItemStoreT = "webview2-custom-labels" | "webview2-contactdetails";
const crossRefDelimiter = ";";

const parseCrossRef = (cressRef: string) => {
	return head(cressRef.split(crossRefDelimiter));
};

const buildCrossRef = (itemStoreID: string) =>
	`${itemStoreID}${crossRefDelimiter}${Math.random().toString(36).substr(2, 9)}`;

// Simplified interface to handle the messages received through the websocket
export interface IClientPersistenceHandler {
	onEvent_asnClientPersistenceEvent(argument: ENetUC_ClientPersistence.AsnClientPersistenceEventArgument): void;
}

/**
 *
 */
export class ClientPersistenceManager implements IClientPersistenceHandler {
	// The singleton instance of this class
	private static instance: ClientPersistenceManager;
	private socket: SocketTransport;
	private itemStores: ItemStoreT[] = [];
	private itemStoreID = "Default";

	/**
	 * Constructs ClientPersistenceManager.
	 * Method is private as we follow the Singleton Approach using getInstance
	 * @param stores - the stores to use
	 */
	private constructor(stores: ItemStoreT[] = []) {
		this.socket = SocketTransport.getInstance();
		this.socket.setClientPersistenceHandler(this);

		this.itemStores = stores;
	}

	/**
	 * Gets instance of ClientPersistenceManager to use as singleton.
	 * @param stores - the stores to use
	 * @returns - an instance of this class.
	 */
	public static getInstance(stores: ItemStoreT[]): ClientPersistenceManager {
		if (!ClientPersistenceManager.instance) {
			ClientPersistenceManager.instance = new ClientPersistenceManager(stores);
		}
		return ClientPersistenceManager.instance;
	}

	/**
	 * Initializes the ClientPersistenceManager
	 */
	public async init() {
		const asnProperties = getState().ownAsnUserPropertyBag?.asnProperties;
		const swProfile = asnProperties?.find((item) => item.key === "swprofile");
		if (swProfile) {
			this.itemStoreID = swProfile.value;
		}

		const promises = this.itemStores.map(async (store) => {
			await this.clientPersistenceSubscribeEvents(store);
			await this.getClientPersistence(store);
		});
		try {
			await Promise.all(promises);
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * Called by the socket after reading and subscribing client persistence events
	 * @param argument - the argument
	 */
	public onEvent_asnClientPersistenceEvent(argument: ENetUC_ClientPersistence.AsnClientPersistenceEventArgument) {
		const crossRefItemStore = parseCrossRef(argument.u8sCrossRefID);
		if (crossRefItemStore === "webview2-contactdetails") {
			const configCd = GetContactDetails(argument);
			if (configCd) {
				getState().updateContactDetails(configCd);
			}
		}
		if (crossRefItemStore === "webview2-custom-labels") {
			const customLabels = GetCustomLabels(argument);
			if (customLabels) {
				getState().updateCustomLabels(customLabels);
			}
		}
	}

	/**
	 * Subscribe to client persistence events
	 * @param itemStore
	 */
	private async clientPersistenceSubscribeEvents(itemStore: string) {
		const argument = new ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument({
			u8sItemStore: itemStore,
			iSubscribeType: 1,
			u8sCrossRefID: buildCrossRef(itemStore)
		});
		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) {
					reject(result);
				} else {
					resolve(result as ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult);
				}
			};
			this.socket.send("asnClientPersistenceSubscribe", argument, callBack);
		});
	}

	/**
	 * Get client persistence entries
	 * @param itemStore
	 */
	private async getClientPersistence(
		itemStore: string
	): Promise<ENetUC_ClientPersistence.AsnClientPersistenceReadResult> {
		const argument: ENetUC_ClientPersistence.AsnClientPersistenceReadArgument = {
			u8sItemStore: itemStore,
			u8sItemStoreID: this.itemStoreID,
			u8sCrossRefID: buildCrossRef(itemStore)
		};

		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) {
					reject(result);
				} else {
					resolve(result as ENetUC_ClientPersistence.AsnClientPersistenceReadResult);
				}
			};
			this.socket.send("asnClientPersistenceRead", argument, callBack);
		});
	}
}
