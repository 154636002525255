import { StateCreator } from "zustand";

import { IManifestInfo } from "../managers/OemManager";
import { StoreT } from "./store";

export type TeamsAppInformationSliceT = IState & IActions;

interface IState {
	clientVersion: string;
	serverVersion: string;
	applicationName: string;
	appManifest: IManifestInfo;
}

interface IActions {
	setClientVersion: (clientVersion: string) => void;
	setServerVersion: (serverVersion: string) => void;
	setApplicationName: (applicationName: string) => void;
	setAppManifest: (appManifest: IManifestInfo) => void;
}

export const initialState = {
	clientVersion: "",
	serverVersion: "",
	applicationName: "",
	appManifest: {
		appShortName: "",
		appLongName: "",
		appPrivacyUrl: "",
		appTermsOfUseUrl: ""
	}
};

export const createTeamsAppInformationSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	TeamsAppInformationSliceT
> = (set) => ({
	...initialState,
	setClientVersion: (clientVersion: string) => {
		set(
			(prev) => {
				return {
					...prev,
					clientVersion
				};
			},
			true,
			"setClientVersion"
		);
	},
	setServerVersion: (serverVersion: string) => {
		set(
			(prev) => {
				return {
					...prev,
					serverVersion
				};
			},
			true,
			"setServerVersion"
		);
	},
	setAppManifest: (appManifest: IManifestInfo) => {
		set(
			(prev) => {
				return {
					...prev,
					appManifest
				};
			},
			true,
			"setAppManifest"
		);
	},
	setApplicationName: (applicationName: string) => {
		set(
			(prev) => {
				return {
					...prev,
					applicationName
				};
			},
			true,
			"setApplicationName"
		);
	}
});
