export interface IConfigCD {
	ContactDetails: IConfigBlock[];
}

export interface IConfigCW {
	RemoteContact: IConfigBlock[];
	HoldContact: IConfigBlock[];
	LocalContact: IConfigBlock[];
	RedirectContact: IConfigBlock[];
}

export interface IConfigBlock {
	id: number;
	name: string;
	type: DetailTypeE;
	local: string;
	title: string;
	visible: boolean; // controls whether the label for the blocks is displayed or not
	style: StyleTypeE[] | null;
	details: IConfigU8sDetail[];
}

export interface IConfigU8sDetail {
	id: string;
	name: string;
	type: DetailTypeE;
	subtype: SubTypeE;
	local: string;
	title: string; // is only used in exceptional cases like address title or similar
	visible: boolean; // controls in the details whether this detail is displayed or not
	style: StyleTypeE[] | null;
	details: IConfigU8sDetail[] | null; // IConfigU8sDetail[] if addressBlock else null
}

export enum DetailTypeE {
	ADR = "addr",
	BLK = "block",
	CTY = "city",
	CTR = "ctry", // country
	EMPTY = "", // helper
	URL = "url",
	EML = "mail",
	FON = "fone",
	PIC = "pict",
	PLZ = "plz",
	SIP = "sip",
	STT = "stt", // state
	STR = "str", // street
	TXT = "text",

	CFBLK = "cfblk", // (C)ustom (F)ield block
	CFDTL = "cfdtl", // (C)ustom (F)ield detail
	CUBLK = "cublk", // (C)ontact (U)RL block
	CUDTL = "cudtl" // (C)ontact (U)RL detail
}

// SubTypes are only needed/used for: Contact URLs and Custom Fields. Possible future use for formatting phone numbers.
export enum SubTypeE {
	APP = "app", // subtype contact url goToExternalApp (application manager @psi)
	LNK = "link", // subtype contact url goToHttp(s)Link (application link)
	EMPTY = "", // helper
	EML = "mail", // subtype custom fields email
	FON = "fone", // subtype custom fields phone
	TXT = "text", // subtype custom fields text
	URL = "url" // subtype custom fields url
}

export enum StyleTypeE {
	BLD = "bold",
	HGH = "highlight",
	RED = "red", // red or green is possible, not both
	GRN = "green"
	// WDT = "width",
	// HGT = "height",
}

export interface IFontColor {
	color: StyleTypeE | null;
}

// ----- helper functions to create empty objects ------

/**
 * Creates an empty detail object
 *
 * @returns an IConfigU8sDetail
 */
export function FnCreateEmptyDetail(): IConfigU8sDetail {
	return {
		id: "",
		name: "",
		type: DetailTypeE.EMPTY,
		subtype: SubTypeE.EMPTY,
		local: "",
		title: "",
		visible: false,
		style: null,
		details: []
	};
}

/**
 * Creates an empty Block
 *
 * @returns an IConfigBlock
 */
export function FnCreateEmptyBlock(): IConfigBlock {
	const emptyBlock: IConfigBlock = {
		id: 0,
		name: "",
		type: DetailTypeE.EMPTY,
		local: "",
		title: "",
		visible: false,
		style: null,
		details: []
	};
	return emptyBlock;
}

/**
 * Creates an empty "Block0" with detail (id= d000)
 *
 * @returns an IConfigBlock
 */
export function FnCreateEmptyBlockZero(): IConfigBlock {
	const emptyBlock: IConfigBlock = {
		id: 0,
		name: "block0",
		type: DetailTypeE.EMPTY,
		local: "",
		title: "< box >",
		visible: false,
		style: null,
		details: [
			{
				id: "d000",
				name: "",
				type: DetailTypeE.EMPTY,
				subtype: SubTypeE.EMPTY,
				local: "",
				title: "",
				visible: false,
				style: null,
				details: []
			}
		]
	};
	return emptyBlock;
}

/**
 * Creates an empty IConfigCW for conversation windows
 *
 * @returns an IConfigCW
 */
export function FnCreateEmptyConfigCW(): IConfigCW {
	const emptyConfigCW: IConfigCW = {
		RemoteContact: [],
		HoldContact: [],
		LocalContact: [],
		RedirectContact: []
	};

	return emptyConfigCW;
}

// [key: string]: string | ConfigU8sDetail[]
