import _ from "lodash";

import { theAVManager } from "../globals";
import { EAvDropReason, IAVCallMessage, IAVCallParams, IRTCIceCandidateSimplified } from "../interfaces/IAVCall";

/**
 * Interface according to https://developer.mozilla.org/en-US/docs/Web/API/RTCIceServer
 */
export interface IRTCIceServer {
	urls: string[];
	username: string | undefined;
	credential: string | undefined;
}

export interface IPeerConnectionStates {
	signalingState: RTCSignalingState;
	iceGatheringState: RTCIceGatheringState;
	iceConnectionState: RTCIceConnectionState;
}

/**
 *
 */
export default class PeerConnectionClient {
	public pc: RTCPeerConnection | null;
	public params: IAVCallParams;
	public static DEFAULT_SDP_OFFER_OPTIONS_ = {
		offerToReceiveAudio: 1,
		offerToReceiveVideo: 1,
		voiceActivityDetection: false
	};

	public isInitiator: boolean;
	public started: boolean;
	public hasRemoteSdp: boolean;
	public messageQueue: IAVCallMessage[];

	private localSdp?: string;
	private remoteSdp?: string;
	private dtlsRole?: "active" | "passive";

	/**
	 *
	 */
	private debouncedOffer?(): void;

	// CallBacks
	/**
	 *
	 */
	public oniceconnectionstatechange?(state: string): void;
	/**
	 *
	 */
	public ontrack?(track: MediaStreamTrack): void;
	/**
	 *
	 */
	public onsignalingmessage?(message: IAVCallMessage): void;

	/**
	 *
	 */
	public onnewicecandidate?(location: "Local" | "Remote", candidate: RTCIceCandidate): void;

	/**
	 *
	 */
	public onremotehangup?(remoteDropReason: EAvDropReason): void;

	/**
	 *
	 */
	public onerror?(error: string): void;

	/**
	 * Constructor of the class
	 *
	 * @param params - parameters from AVCall
	 */
	public constructor(params: IAVCallParams) {
		this.params = params;
		this.pc = new RTCPeerConnection(params.peerConnectionConfig);
		this.pc.onnegotiationneeded = this.onNegotiationNeeded.bind(this);
		this.pc.onicecandidate = this.onIceCandidate.bind(this);
		this.pc.ontrack = this.onTrack.bind(this);
		this.pc.onsignalingstatechange = this.onSignalingStateChanged.bind(this);
		this.pc.oniceconnectionstatechange = this.onIceConnectionStateChanged.bind(this);

		this.hasRemoteSdp = false;
		this.messageQueue = [];
		this.isInitiator = false;
		this.started = false;
	}

	/**
	 * Add stream to PC
	 *
	 * @param stream - the stream to add
	 */
	public addStream(stream: MediaStream) {
		if (!this.pc) {
			return;
		}

		const videoTracks = stream.getVideoTracks();
		const audioTracks = stream.getAudioTracks();

		for (let i = 0; i < audioTracks.length; i++) {
			this.pc.addTrack(audioTracks[i], stream);
		}

		for (let i = 0; i < videoTracks.length; i++) {
			this.pc.addTrack(videoTracks[i], stream);
		}
	}

	/**
	 * Start call as caller
	 *
	 * @returns - true on success, false otherwise
	 */
	public startAsCaller() {
		if (!this.pc) {
			return false;
		}
		if (this.started) {
			return false;
		}

		this.isInitiator = true;
		this.started = true;

		this.pc.createOffer().then(this.setLocalSdpAndNotify.bind(this)).catch(this.onError.bind(this, "createOffer"));

		return true;
	}

	/**
	 * Start a call as callee
	 *
	 * @param initialMessages - IAVCallMessage array
	 * @returns - true on success, false otherwise
	 */
	public startAsCallee(initialMessages?: IAVCallMessage[]) {
		if (!this.pc) {
			return false;
		}

		if (this.started) {
			return false;
		}

		this.isInitiator = false;
		this.started = true;

		if (initialMessages && initialMessages.length > 0) {
			// Convert received messages to JSON objects and add them to the message queue.
			for (const message of initialMessages) {
				this.receiveSignalingMessage(message);
			}
			return true;
		}

		// We may have queued messages received from the signaling channel before started.
		if (this.messageQueue.length > 0) {
			this.drainMessageQueue();
		}
		return true;
	}

	/**
	 * Receive signaling message, add to the queue, drain
	 *
	 * @param message - the IAVCallMessage
	 */
	public receiveSignalingMessage(message: IAVCallMessage) {
		if (!message) {
			return;
		}

		if (message.type === "answer" || message.type === "offer") {
			// ESTOS FIX (for renegotiate, e.g. !this.isInitiator creates a datachannel)
			this.hasRemoteSdp = true;
			// Always process offer before candidates.
			this.messageQueue.unshift(message);
		} else if (message.type === "candidate") {
			this.messageQueue.push(message);
		} else if (message.type === "end-of-candidates") {
			// ESTOS FIX
			this.messageQueue.push(message); // ESTOS FIX
		} else if (message.type === "bye") {
			if (this.onremotehangup) {
				this.onremotehangup(EAvDropReason.UNKNOWN);
			}
		}

		this.drainMessageQueue();
	}

	/**
	 * Close the peer connection
	 */
	public close() {
		if (!this.pc) {
			return;
		}
		this.pc.close();
		this.pc = null;
	}

	/**
	 * Get peer connection states
	 *
	 * @returns - the IPeerConnectionStates or undefined
	 */
	public getPeerConnectionStates(): IPeerConnectionStates | undefined {
		if (!this.pc) {
			return undefined;
		}
		return {
			signalingState: this.pc.signalingState,
			iceGatheringState: this.pc.iceGatheringState,
			iceConnectionState: this.pc.iceConnectionState
		};
	}

	/**
	 * Get peer connection stats
	 */
	public async getPeerConnectionStats(): Promise<RTCStatsReport | undefined> {
		if (!this.pc) {
			return;
		}
		return await this.pc.getStats(null);
	}

	/**
	 * Create PC answer, set sdp and notify
	 */
	public doAnswer() {
		if (!this.pc) {
			return;
		}
		this.pc.createAnswer().then(this.setLocalSdpAndNotify.bind(this)).catch(this.onError.bind(this, "createAnswer"));
	}

	/**
	 * Set local description
	 *
	 * @param sessionDescription - the sdp to set
	 */
	private setLocalSdpAndNotify(sessionDescription: RTCSessionDescriptionInit) {
		if (!this.pc || !sessionDescription.sdp) {
			return;
		}
		this.localSdp = sessionDescription.sdp;
		this.pc.setLocalDescription(sessionDescription).catch(this.onError.bind(this, "setLocalDescription"));

		if (this.onsignalingmessage && (sessionDescription.type === "answer" || sessionDescription.type === "offer")) {
			// Chrome version of RTCSessionDescription can't be serialized directly
			// because it JSON.stringify won't include attributes which are on the
			// object's prototype chain. By creating the message to serialize explicitly
			// we can avoid the issue.
			this.onsignalingmessage({
				type: sessionDescription.type,
				content: {
					sdp: sessionDescription.sdp,
					type: sessionDescription.type
				}
			});
		}
	}

	/**
	 * Set remote sdp
	 *
	 * @param message - the sdp to set
	 */
	public setRemoteSdp(message: RTCSessionDescriptionInit) {
		if (!this.pc || !message.sdp) {
			return;
		}
		this.remoteSdp = message.sdp;

		this.pc
			.setRemoteDescription(new RTCSessionDescription(message))
			.catch(this.onError.bind(this, "setRemoteDescription"));
	}

	/**
	 * Process the signaling message
	 *
	 * @param message - the message to process
	 */
	private processSignalingMessage(message: IAVCallMessage) {
		if (!this.pc) {
			return;
		}
		if (message.type === "offer" /* && !this.isInitiator_ */) {
			// ESTOS FIX (for renegotiate, e.g. !this.isInitiator creates a datachannel)
			if (this.pc.signalingState !== "stable") {
				console.error("ERROR: remote offer received in unexpected state: " + this.pc.signalingState);
				return;
			}
			const content = message.content as RTCSessionDescriptionInit;
			this.setRemoteSdp(content);
			this.doAnswer();
		} else if (message.type === "answer" /* && this.isInitiator_ */) {
			// ESTOS FIX (for renegotiate, e.g. !this.isInitiator creates a datachannel)
			if (this.pc.signalingState !== "have-local-offer") {
				console.error("ERROR: remote answer received in unexpected state: " + this.pc.signalingState);
				return;
			}
			const content = message.content as RTCSessionDescriptionInit;
			this.setRemoteSdp(content);
		} else if (message.type === "candidate") {
			const candidate = message.content as RTCIceCandidate;
			this.recordIceCandidate("Remote", candidate);
			this.pc.addIceCandidate(candidate).catch(this.onError.bind(this, "addIceCandidate"));
		} else if (message.type === "end-of-candidates") {
			// ESTOS FIX
			// void this.pc.addIceCandidate(undefined); // ESTOS FIX // This is wrong for chrome > 70 but is needed for other browsers and e.g. with federated ucservers. See https://jira.estos.de/browse/LC-625
		} else {
			console.error("WARNING: unexpected message: " + JSON.stringify(message));
		}
	}

	/**
	 * When we receive messages from GAE registration and from the WSS connection,
	 * we add them to a queue and drain it if conditions are right.
	 */
	private drainMessageQueue() {
		// It's possible that we finish registering and receiving messages from WSS
		// before our peer connection is created or started. We need to wait for the
		// peer connection to be created and started before processing messages.
		//
		// Also, the order of messages is in general not the same as the POST order
		// from the other client because the POSTs are async and the server may handle
		// some requests faster than others. We need to process offer before
		// candidates, so we wait for the offer to arrive first if we're answering.
		// Offers are added to the front of the queue.
		if (!this.pc || !this.started || !this.hasRemoteSdp) {
			return;
		}

		for (let i = 0, len = this.messageQueue.length; i < len; i++) {
			this.processSignalingMessage(this.messageQueue[i]);
		}

		this.messageQueue = [];
	}

	/**
	 * Handle on ice candidate PC event
	 *
	 * @param event - the RTCPeerConnectionIceEvent
	 */
	private onIceCandidate(event: RTCPeerConnectionIceEvent) {
		console.log("onIceCandidate", event.candidate?.sdpMid);
		if (event.candidate) {
			// Eat undesired candidates.
			if (this.filterIceCandidate(event.candidate)) {
				const simplified: IRTCIceCandidateSimplified = {
					candidate: event.candidate.candidate,
					sdpMid: event.candidate.sdpMid,
					sdpMLineIndex: event.candidate.sdpMLineIndex
				};
				const message: IAVCallMessage = {
					type: "candidate",
					content: simplified
				};
				if (this.onsignalingmessage) {
					this.onsignalingmessage(message);
				}

				this.recordIceCandidate("Local", event.candidate);
			}
		} else {
			console.log("End of candidates.");

			if (this.onsignalingmessage) {
				// ESTOS FIX
				this.onsignalingmessage({ type: "end-of-candidates" });
			}
		}
	}

	/**
	 * Handle on signaling state change PC event
	 * (currently doing nothing)
	 */
	private onSignalingStateChanged() {
		if (!this.pc) {
			return;
		}
		console.log("Signaling state changed to: " + this.pc.signalingState);
		/* if (this.onsignalingstatechange)
			this.onsignalingstatechange(this.pc.signalingState); */
	}

	/**
	 * Handle on connection state changed PC event
	 */
	private onIceConnectionStateChanged() {
		if (!this.pc) {
			return;
		}
		if (this.oniceconnectionstatechange) {
			this.oniceconnectionstatechange(this.pc.iceConnectionState);
		}
	}

	/**
	 * Filter ice candidate
	 *
	 * @param candidateObj - the candidate to filter
	 * @returns - false if the candidate should be dropped, true if not.
	 */
	private filterIceCandidate(candidateObj: RTCIceCandidateInit) {
		const candidateStr = candidateObj.candidate;
		if (!candidateStr) {
			return false;
		}
		// If we're trying to eat non-relay candidates, do that.
		return !(
			this.params.peerConnectionConfig.iceTransportPolicy === "relay" &&
			theAVManager.getIceCandidateType(candidateStr) !== "relay"
		);
	}

	/**
	 * On new ice candidate
	 *
	 * @param location - (Local or Remote)
	 * @param candidate - the candidate
	 */
	private recordIceCandidate(location: "Local" | "Remote", candidate: RTCIceCandidate) {
		if (this.onnewicecandidate) {
			this.onnewicecandidate(location, candidate);
		}
	}

	/**
	 * On track
	 *
	 * @param event - the RTCTrackEvent
	 */
	private onTrack(event: RTCTrackEvent) {
		if (this.ontrack) {
			this.ontrack(event.track);
		}
	}

	/**
	 * On Error
	 *
	 * @param error - the error string
	 */
	private onError(error: string) {
		if (this.onerror) {
			this.onerror(error);
		}
	}

	/**
	 * On negotiation needed
	 *
	 * @param event - Event
	 */
	public onNegotiationNeeded(event: Event) {
		const isNewCall = this.getPeerConnectionStates()?.iceConnectionState === "new";

		console.log("PeerConnectionClient: Negotiation needed. isNewCall: ", isNewCall);

		if (isNewCall) {
			// Tue nix, offer bzw. answer wird oben in startAsCaller bzw. in doAnswer_ verschickt.
		} else {
			// Debounce offer, so that adding and removing streams manually, will result in only one renegotation
			if (!this.debouncedOffer) {
				this.debouncedOffer = _.debounce(() => {
					if (!this.pc) {
						return;
					}
					console.log("PeerConnectionClient: Sending renegotiation.");

					this.pc
						.createOffer()
						.then(this.setLocalSdpAndNotify.bind(this))
						.catch(this.onError.bind(this, "createOffer"));
				}, 250);
			}

			this.debouncedOffer();
		}
	}
}
