import { StateCreator } from "zustand";

import { ICustomerLabels } from "../models/ModelContent";
import { StoreT } from "./store";

export type CustomLabelsSliceT = IState & IActions;

interface IState {
	customLabels: ICustomerLabels;
}

interface IActions {
	updateCustomLabels: (customLabels: ICustomerLabels) => void;
}

export const initialState = {
	customLabels: {
		CustLabels: []
	}
};

export const createCustomLabelsSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	CustomLabelsSliceT
> = (set) => ({
	...initialState,
	updateCustomLabels: (customLabels: ICustomerLabels) => {
		set(
			(prev) => ({
				...prev,
				customLabels
			}),
			false,
			"updateCustomLabels"
		);
	}
});
