import { Box, Collapse, Paper, Stack, Unstable_Grid2 as Grid, useMediaQuery } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { t } from "i18next";
import React, { FC, useEffect, useState } from "react";

import { AsnRequestError } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { AsnNetDatabaseJournal } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal";
import { Typography } from "../components/common/Typography/Typography";
import { ContactDetails } from "../components/ContactDetails/ContactDetails";
import { theBrowser, theJournalManager, theOemManager, theUCClient } from "../globals";
import { ECallState } from "../interfaces/IAVCall";
import { addSipPrefixIfNotExists } from "../lib/commonHelper";
import { IUCServerInfo } from "../session/UCClient";
import { getState, useStore } from "../zustand/store";
import { AppSettings } from "./AppSettings/AppSettings";
import AVCallAudioTileRemote from "./Call/AvCallAudioTileRemote";
import { Contacts } from "./Contacts/Contacts";
import { Journal } from "./Journal/Journal";
import { JournalEntryDetails } from "./JournalEntryDetails/JournalEntryDetails";
import { LineSettings } from "./LineSettings/LineSettings";
import { NotificationSettings } from "./NotificationSettings/NotificationSettings";
import { useRightPanel } from "./useRightPanel";

const mainColumnWidth = 320;
const sideColumnWidth = 320;
const padding = 16;
export const totalWidth = 2 * mainColumnWidth + sideColumnWidth + 3 * padding;

export enum LeftColumnE {
	CONTACTS = "contacts",
	LINE_SETTINGS = "lineSettings",
	APP_SETTINGS = "appSettings",
	NOTIFICATION_SETTINGS = "notificationSettings"
}

export enum areaToShowE {
	CONTACTS,
	JOURNAL
}

export const Dashboard: FC = () => {
	const [contactId, setContactId] = useState<string | null>(null);
	const [leftCol, setLeftCol] = useState<LeftColumnE>(LeftColumnE.CONTACTS);
	const [areaToShow, setAreaToShow] = useState<areaToShowE>(areaToShowE.CONTACTS);
	const avCalls = useStore((state) => state.avCalls);
	const activeAvCall = avCalls.find(
		(item) =>
			item.callState === ECallState.CONNECTED ||
			item.callState === ECallState.CONNECTIONPENDING ||
			(item.isInitiator && item.callState === ECallState.RINGING)
	);
	const {
		visible,
		selectedContactId = null,
		selectedJournalEntryId = null,
		onCancel,
		setSelectedContactId,
		setSelectedJournalEntryId
	} = useRightPanel();
	const isOverlapping = useMediaQuery(`(max-width:${totalWidth}px)`);

	const [showContactDetails, setShowContactDetails] = useState(false);
	const handleUpdateReadFlags = async (journalEntry: AsnNetDatabaseJournal) => {
		await theJournalManager.updateJournalReadFlags([journalEntry?.u8sConnectionID], true);
	};
	const onSelectContact = (contactId?: string) => {
		if (!contactId) {
			return;
		}
		if (contactId === selectedContactId) {
			onCancel();
			return;
		}
		setSelectedContactId(contactId);
		setSelectedJournalEntryId(null);
	};

	/**
	 * Get the server and client version
	 */
	useEffect(() => {
		theUCClient
			.getUCServerVersion()
			.then((ucserver: IUCServerInfo | AsnRequestError) => {
				if (!(ucserver instanceof AsnRequestError)) {
					getState().setServerVersion(ucserver.ucserverversion);
				}
			})
			.catch((e) => {
				console.log("Error getting Server Version", e);
			});

		const getVersionTxt = async () => {
			try {
				const timeStamp = new Date().getUTCSeconds();
				await fetch("version.txt?nocache=" + timeStamp, { cache: "no-store" })
					.then((res) => res.text())
					.then((version) => {
						getState().setClientVersion(version);
					});
			} catch (e) {
				console.log("Error fetching version.txt: ", e);
			}
		};

		void getVersionTxt();

		const appManifest = theOemManager.getManifestInformation();
		if (appManifest) {
			getState().setAppManifest(appManifest);
			const appName = appManifest?.appShortName;
			if (appName) {
				getState().setApplicationName(appName);
			}
		}
	}, []);

	return (
		<>
			{theBrowser.is.mobile ? (
				<Grid
					rowSpacing={2}
					container
					p={2}
					display={"flex"}
					flexDirection={"row"}
					alignItems={"center"}
					sx={{ width: "calc(100% - 32px)", margin: "16px 16px 0 16px", backgroundColor: "#FFF" }}
					// justifyContent={"space-around"}
				>
					<Typography
						sx={{
							width: "50%",
							textAlign: "center",
							borderBottom: areaToShow === areaToShowE.CONTACTS ? "2px solid rgba(0, 0, 0, 0.24)" : "none"
						}}
						onClick={() => {
							setSelectedContactId(null);
							setSelectedJournalEntryId(null);
							setAreaToShow(areaToShowE.CONTACTS);
						}}
					>
						{t("IDS_CONTACT_PAGE_TITLE")}
					</Typography>
					<Typography
						sx={{
							width: "50%",
							textAlign: "center",
							borderBottom: areaToShow === areaToShowE.JOURNAL ? "2px solid rgba(0, 0, 0, 0.24)" : "none"
						}}
						onClick={() => {
							setSelectedContactId(null);
							setSelectedJournalEntryId(null);
							setAreaToShow(areaToShowE.JOURNAL);
						}}
					>
						{t("IDS_JOURNAL_PAGE_TITLE")}
					</Typography>
				</Grid>
			) : null}
			<Stack direction={"row"} flex={1} height="100%" overflow={"hidden"}>
				<Grid
					container
					p={2}
					columnSpacing={2}
					flex={1}
					sx={{
						minWidth: theBrowser.is.mobile ? "unset" : `${2 * mainColumnWidth + 3 * padding}px`,
						flexFlow: theBrowser.is.mobile ? "column" : "unset"
					}}
				>
					<AnimatePresence>
						{!theBrowser.is.mobile || (theBrowser.is.mobile && areaToShow === areaToShowE.CONTACTS) ? (
							<Grid
								key={"dashboard-grid"}
								xs={6}
								sx={{
									display: "flex",
									height: "100%",
									flexDirection: "column",
									width: theBrowser.is.mobile ? "unset" : "calc(100% * 6 / var(--Grid-columns))"
								}}
							>
								{leftCol === LeftColumnE.LINE_SETTINGS ? (
									<AnimatePresence>
										<motion.div
											key={"line-settings"}
											style={{ height: "100%" }}
											initial={{ x: "100vw" }}
											animate={{ x: 0 }}
											exit={{ x: "100vw" }}
											transition={{
												ease: "linear",
												duration: 0.25
											}}
										>
											<LineSettings setLeftCol={setLeftCol} />
										</motion.div>
									</AnimatePresence>
								) : leftCol === LeftColumnE.APP_SETTINGS ? (
									<AppSettings setLeftCol={setLeftCol} />
								) : leftCol === LeftColumnE.NOTIFICATION_SETTINGS ? (
									<NotificationSettings setLeftCol={setLeftCol} />
								) : selectedContactId && theBrowser.is.mobile ? (
									<AnimatePresence>
										<motion.div
											key={"contact-details1"}
											initial={{ x: "100vw" }}
											animate={{ x: 0 }}
											exit={{ x: "100vw" }}
											transition={{
												ease: "linear",
												duration: 0.25
											}}
										>
											<Paper sx={{ height: "100%", overflow: "hidden" }} hidden={!visible || !isOverlapping}>
												<ContactDetails
													contactID={selectedContactId}
													onClose={() => {
														onCancel();
														setSelectedContactId(null);
													}}
												/>
											</Paper>
										</motion.div>
									</AnimatePresence>
								) : (
									<motion.div
										key={"contacts"}
										style={{ height: "100%" }}
										initial={{ x: "-100vw" }}
										animate={{ x: 0 }}
										exit={{ x: "-100vw" }}
										transition={{
											ease: "linear",
											duration: 0.25
										}}
									>
										<Contacts
											setLeftCol={setLeftCol}
											selectedContactId={selectedContactId}
											onSelectContact={onSelectContact}
										/>
									</motion.div>
								)}
							</Grid>
						) : null}
						<AnimatePresence>
							{!theBrowser.is.mobile || (theBrowser.is.mobile && areaToShow === areaToShowE.JOURNAL) ? (
								<Grid
									key={"journal-grid"}
									xs={6}
									sx={{
										overflow: "hidden",
										height: "100%",
										width: theBrowser.is.mobile ? "calc(100vw - 16px)" : "calc(100% * 6 / var(--Grid-columns))"
									}}
								>
									{selectedJournalEntryId && theBrowser.is.mobile ? (
										<AnimatePresence>
											<motion.div
												key={"journal-entries"}
												initial={{ x: "100vw", opacity: 0 }}
												animate={{ x: 0, opacity: 1 }}
												exit={{ x: "100vw", opacity: 0 }}
												transition={{
													ease: "linear",
													duration: 0.25
												}}
												style={{ width: "100%", height: "100%" }}
											>
												<JournalEntryDetails
													onBack={() => {
														onCancel();
														setSelectedJournalEntryId(null);
													}}
													contactID={contactId}
													journalEntryID={selectedJournalEntryId}
													showContactDetails={showContactDetails}
													setShowContactDetails={setShowContactDetails}
												/>
											</motion.div>
										</AnimatePresence>
									) : null}
									{selectedContactId && theBrowser.is.mobile ? (
										<AnimatePresence>
											<motion.div
												key={"contact-details2"}
												initial={{ x: "100vw", opacity: 0 }}
												animate={{ x: 0, opacity: 1 }}
												exit={{ x: "100vw", opacity: 0 }}
												transition={{
													ease: "linear",
													duration: 0.25
												}}
											>
												<Paper sx={{ height: "100%", overflow: "hidden" }} hidden={!visible || !isOverlapping}>
													<ContactDetails
														contactID={selectedContactId}
														onClose={() => {
															onCancel();
															setSelectedContactId(null);
														}}
													/>
												</Paper>
											</motion.div>
										</AnimatePresence>
									) : null}
									{(!selectedContactId && !selectedJournalEntryId && theBrowser.is.mobile) || !theBrowser.is.mobile ? (
										<AnimatePresence>
											<motion.div
												key={"journal"}
												initial={{ x: "100vw", opacity: 0 }}
												animate={{ x: 0, opacity: 1 }}
												exit={{ x: "100vw", opacity: 0 }}
												style={{ height: "100%" }}
												transition={{
													ease: "linear",
													duration: 0.25
												}}
											>
												<Journal
													selectedJournalEntryId={selectedJournalEntryId}
													onClick={(journalEntry) => {
														if (selectedJournalEntryId === journalEntry.u8sConnectionID) {
															onCancel();
															return;
														}
														setSelectedContactId(null);
														setSelectedJournalEntryId(journalEntry.u8sConnectionID);
														setContactId(addSipPrefixIfNotExists(journalEntry.u8sSIPAddress));
														setShowContactDetails(false);
														if (!journalEntry.iReadFlag) {
															void handleUpdateReadFlags(journalEntry);
														}
													}}
												/>
											</motion.div>
										</AnimatePresence>
									) : null}
								</Grid>
							) : null}
						</AnimatePresence>
					</AnimatePresence>
				</Grid>
				<Grid>
					<Collapse
						onExited={() => {
							if (!isOverlapping) {
								setSelectedJournalEntryId(null);
								setSelectedContactId(null);
							}
						}}
						in={visible && !isOverlapping}
						orientation="horizontal"
						sx={{ height: "100%" }}
					>
						<Box sx={{ width: `${sideColumnWidth + 2 * padding}px`, height: "100%" }} p={2} pl={0}>
							<Paper sx={{ height: "100%", overflow: "hidden" }}>
								{selectedContactId && (
									<Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
										<ContactDetails
											contactID={selectedContactId}
											onClose={() => {
												onCancel();
											}}
										/>
									</Box>
								)}
								{selectedJournalEntryId && (
									<JournalEntryDetails
										onClose={onCancel}
										contactID={contactId}
										journalEntryID={selectedJournalEntryId}
										showContactDetails={showContactDetails}
										setShowContactDetails={setShowContactDetails}
									/>
								)}
							</Paper>
						</Box>
					</Collapse>
				</Grid>
			</Stack>
			{!!activeAvCall && (
				<Box>
					<AVCallAudioTileRemote avCall={activeAvCall} />
				</Box>
			)}
		</>
	);
};
