import { StateCreator } from "zustand";

import { IConfigCD } from "../models/ModelConfig";
import { StoreT } from "./store";

export type ContactDetailsSliceT = IState & IActions;

interface IState {
	contactDetails: IConfigCD;
}

interface IActions {
	updateContactDetails: (contactDetails: IConfigCD) => void;
}

export const initialState = {
	contactDetails: {
		ContactDetails: []
	}
};

export const createContactDetailsSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	ContactDetailsSliceT
> = (set) => ({
	...initialState,
	updateContactDetails: (contactDetails: IConfigCD) => {
		set(
			(prev) => ({
				...prev,
				contactDetails
			}),
			false,
			"updateContactDetails"
		);
	}
});
