import * as manifest from "../../teams/manifest.json";
import * as oemJSON from "../../teams/oem.json";
import { ILogCallback, ILogData } from "../../web-shared-components/helpers/logger/ILogger";
import { IBaseSingletons } from "../interfaces/IBaseSingletons";
import BaseSingleton from "../lib/BaseSingleton";

// for future use we can add other needed fields
export interface IManifestInfo {
	appShortName: string | undefined;
	appLongName: string | undefined;
	appPrivacyUrl: string | undefined;
	appTermsOfUseUrl: string | undefined;
}

/**
 *
 */
export class OemManager extends BaseSingleton implements ILogCallback {
	// The singleton instance of this class
	private static instance: OemManager;

	/**
	 * Constructs OemManager.
	 * Method is private as we follow the Singleton Approach using getInstance
	 *
	 * @param attributes - the constructor attributes
	 */
	private constructor(attributes: IBaseSingletons) {
		super(attributes);
	}

	/**
	 * The Loggers getLogData callback (used in all the log methods called in this class, add the classname to every log entry)
	 *
	 * @returns - an ILogData log data object provided additional data for all the logger calls in this class
	 */
	public getLogData(): ILogData {
		return {
			className: "OemManager"
		};
	}

	/**
	 * Gets instance of AuthenticationManager to use as singleton.
	 *
	 * @param attributes - the attributes
	 * @returns - an instance of this class.
	 */
	public static getInstance(attributes: IBaseSingletons): OemManager {
		if (!OemManager.instance) {
			OemManager.instance = new OemManager(attributes);
		}
		return OemManager.instance;
	}

	/**
	 * Parse the translation json and change the OEM strings
	 *
	 * @param resources - the translation json
	 * @returns a string (JSON)
	 */
	public parseOEMStrings(resources: string): string | undefined {
		const formattedOEM = oemJSON as { [key: string]: string };
		for (const [key, value] of Object.entries(formattedOEM)) {
			resources = resources.replace(new RegExp("{{" + key + "}}", "g"), value);
		}
		return resources;
	}

	/**
	 * Get the information from the OEM Manifest
	 *
	 * @returns an IManifest object
	 */
	public getManifestInformation(): IManifestInfo | undefined {
		return {
			appShortName: manifest?.name.full,
			appLongName: manifest?.name.short,
			appPrivacyUrl: manifest?.developer?.privacyUrl,
			appTermsOfUseUrl: manifest?.developer?.termsOfUseUrl
		};
	}
}
