import { DateTime, Duration, Settings } from "luxon";

/**
 *
 * @param value
 */
export function formatDuration(value: number) {
	const duration = Duration.fromMillis(value);
	const locale = window.navigator.language;
	Settings.defaultLocale = locale;
	if (duration.as("hours") >= 1) {
		return duration.toFormat("H 'h' m 'min'");
	}

	if (duration.as("minutes") >= 1) {
		return duration.toFormat("M 'min'");
	}

	return duration.toFormat("S 'sec'");
}

/**
 * format a duration in seconds to a string
 *
 * @param seconds - the duration in seconds
 * @returns - the formatted duration (hh:mm:ss)
 */
export function formatSeconds(seconds: number) {
	const duration = Duration.fromObject({ seconds });
	return duration.as("hours") >= 1 ? duration.toFormat("hh:mm:ss") : duration.toFormat("mm:ss");
}
