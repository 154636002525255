import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, useTheme } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { faCheck, faCircleExclamation, faSpinner } from "web-fortawesome/npm/pro-light";

import { Typography } from "../../components/common/Typography/Typography";

export enum SavingStateE {
	Saving = "Saving",
	Success = "Success",
	Error = "Error"
}

/**
 * set savingState to Saving, then call updateFunction, then set savingState to Success or Error
 * @param updateFunction - function to call when saving
 * @returns \{savingState, debouncedUpdate, cancelUpdate\}
 */
export function useSavingState<T>(updateFunction: (text: string) => Promise<T>) {
	const [savingState, setSavingState] = useState<SavingStateE | null>(null);
	const savingStateCallback = useRef<SavingStateE | null>(null);

	const debouncedUpdate = useDebouncedCallback(async (text: string) => {
		savingStateCallback.current = SavingStateE.Saving;
		setSavingState(SavingStateE.Saving);
		try {
			const result = await updateFunction(text);
			if (result instanceof Error) {
				console.log("Error while updating: ", result);
				return;
			}
		} catch (e) {
			console.log(e);
			savingStateCallback.current = SavingStateE.Error;
			setSavingState(SavingStateE.Error);
			setTimeout(() => {
				!debouncedUpdate?.isPending() && setSavingState(null);
			}, 1500);
			return;
		}
		setTimeout(() => {
			if (!debouncedUpdate?.isPending() && savingStateCallback.current === SavingStateE.Saving) {
				setSavingState(SavingStateE.Success);
				setTimeout(() => {
					savingStateCallback.current = null;
					setSavingState(null);
				}, 1500);
			}
		}, 500);
	}, 300);

	const cancelUpdate = useCallback(() => {
		debouncedUpdate.cancel();
		setSavingState(null);
		savingStateCallback.current = null;
	}, [debouncedUpdate]);

	return { savingState, debouncedUpdate, cancelUpdate };
}

interface ISavingStateIndicatorProps {
	savingState: SavingStateE | null;
}

export const SavingStateIndicator = ({ savingState }: ISavingStateIndicatorProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Stack flexDirection={"row"} pl={2} pt={1} height={"32px"} alignItems="center">
			{savingState === SavingStateE.Saving ? (
				<>
					<FontAwesomeIcon icon={faSpinner} className="fa-spin" color={theme.palette.primary.main} />
					<Typography variant="body2" sx={{ ml: 1 }} color={theme.palette.primary.main}>
						{t("IDS_GENERAL_SAVING_PROGRESS")}
					</Typography>
				</>
			) : savingState === SavingStateE.Success ? (
				<>
					<FontAwesomeIcon icon={faCheck} color={theme.palette.success.main} />
					<Typography variant="body2" sx={{ ml: 1 }} color={theme.palette.success.main}>
						{t("IDS_GENERAL_SAVING_SUCCESS")}
					</Typography>
				</>
			) : savingState === SavingStateE.Error ? (
				<>
					<FontAwesomeIcon icon={faCircleExclamation} color={theme.palette.error.main} />
					<Typography variant="body2" sx={{ ml: 1 }} color={theme.palette.error.main}>
						{t("IDS_GENERAL_SAVING_ERROR")}
					</Typography>
				</>
			) : null}
		</Stack>
	);
};
