import { Box, Divider, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AsnAgentStateEnum } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Agent";
import { AsnReachabilityEnum } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { PresenceIconWithTooltip } from "../../../web-shared-components/react-components/presenceIconWithTooltip/PresenceIconWithTooltip";
import { addSipPrefixIfNotExists } from "../../lib/commonHelper";
import ContactHelper from "../../lib/ContactHelper";
import { ConstrContactFromModel } from "../../modelconstructors/ConstrModelContact";
import { ConstrContentDetailsFromJson } from "../../modelconstructors/ConstrModelContent";
import { FnCreateEmptyContact, ICombiBlock, IContact } from "../../models/ModelContact";
import { IContentDetails } from "../../models/ModelContent";
import { IClientContent } from "../../zustand/contactSlice";
import { useStore } from "../../zustand/store";
import { Header } from "../common/Header/Header";
import { StatusInfo } from "../ContactList/ContactListItem";
import { ContactBlock } from "../imported/ContactElements/ContactBlock";

interface IContactDetailsProps {
	contactID: string | null;
	onBack?: () => void;
	onClose?: () => void;
}

export const ContactDetails = ({ contactID, onBack, onClose }: IContactDetailsProps) => {
	const { t } = useTranslation();
	const customLabels = useStore((state) => state.customLabels);
	const cDetails = useStore((state) => state.contactDetails);
	const contactsDetails = useStore((state) => state.contactsDetails);
	const contacts = useStore((state) => state.contactsPresence);
	const asnDataBaseContact = contactsDetails.get(contactID!);
	const statusInfos = contacts.get(contactID!);

	const [contactDetails, setContactDetails] = useState<IContact>();
	const [currentAppointmentTime, setCurrentAppointmentTime] = useState<string[]>([]);
	const [nextAppointmentTime, setNextAppointmentTime] = useState<string>("");
	const [callNote, setCallNote] = useState<string>("");

	useEffect(() => {
		let content: IContentDetails;
		let iContact: IContact;
		let selectedContact: IClientContent | undefined;
		if (!contactID) {
			return;
		}
		selectedContact = contactsDetails.get(contactID);
		if (!selectedContact) {
			selectedContact = contactsDetails.get(addSipPrefixIfNotExists(contactID! || ""));
		}

		if (selectedContact) {
			content = ConstrContentDetailsFromJson(selectedContact);
			content.statusInfos = {
				currentAppointments: statusInfos?.currentAppointments,
				isMobileAvailable: false,
				remoteContact: undefined,
				u8sNote: statusInfos?.customNote || "",
				presence: statusInfos?.presence || 0,
				nextAppointment: statusInfos?.nextAppointment,
				reachability: statusInfos?.reachability || AsnReachabilityEnum.eREACHABILITYNORMAL,
				agentState: statusInfos?.agentState || AsnAgentStateEnum.eAGENTSTATENOAGENT,
				seqCalls: statusInfos?.seqCalls
			};

			iContact = ConstrContactFromModel(content, customLabels.CustLabels, cDetails.ContactDetails, "contactDetails");

			const callNote = iContact?.statusInfos?.u8sNote?.startsWith("IDS_") ? t(iContact.statusInfos.u8sNote) : "";
			setCallNote(callNote);
		} else {
			iContact = FnCreateEmptyContact();
		}
		setContactDetails(iContact);

		const currentAppointmentTime = statusInfos ? ContactHelper.getCurrentAppointment(statusInfos) : [];
		const nextAppointmentTime = statusInfos ? ContactHelper.getNextAppointment(statusInfos) : "";

		setCurrentAppointmentTime(currentAppointmentTime);
		setNextAppointmentTime(nextAppointmentTime);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		contactID,
		contactsDetails,
		customLabels,
		cDetails,
		statusInfos?.presence,
		statusInfos?.nextAppointment,
		statusInfos?.reachability,
		statusInfos?.agentState
	]);

	if (!contactDetails) {
		return null;
	}

	return (
		<>
			<Header title={t("IDS_CONTACT_DETAILS")} onBack={onBack} onClose={onClose} />
			<Box height={"100%"} overflow="auto">
				<Box sx={{ display: "flex", flexDirection: "row" }} px={2}>
					<PresenceIconWithTooltip
						contact={{ ...statusInfos, contactID: contactID || "", asnNetDatabaseContact: asnDataBaseContact }}
						contactDetails={cDetails}
						customLabels={customLabels}
						combiPic={ContactHelper.getPic(asnDataBaseContact)}
						currentAppointmentTime={currentAppointmentTime}
						nextAppointmentTime={nextAppointmentTime}
					/>
					<Box sx={{ flexDirection: "column" }}>
						<Typography>{contactDetails.displayName}</Typography>
						<Box>
							{StatusInfo({
								statusInfo: contactDetails.statusInfos || 0,
								note: contactDetails.statusInfos.u8sNote || "",
								nextAppointment:
									contactDetails.statusInfos.currentAppointments && contactDetails.statusInfos.currentAppointments[0]
										? t("IDS_APPOINTMENT") + " - " + contactDetails.statusInfos.currentAppointments[0].u8sSubject
										: "",
								callNote
							})}
						</Box>
					</Box>
				</Box>
				{contactDetails?.combiBlocks.map((combiBlock: ICombiBlock, index: number) => {
					return (
						<Fragment key={"rf" + index}>
							<ContactBlock key={index} block={combiBlock} type="ch" />
							<Divider sx={{ mx: 1 }} />
						</Fragment>
					);
				})}
			</Box>
		</>
	);
};
