import { useEffect, useState } from "react";

/**
 *
 * @param requestedMedia
 * @param requestedMedia.video
 * @param requestedMedia.audio
 */
export function useUserMedia(requestedMedia: { video?: boolean; audio?: boolean }) {
	const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
	const [error, setError] = useState<unknown>(null);

	useEffect(() => {
		/**
		 *
		 */
		async function enableStream() {
			try {
				const stream = await navigator.mediaDevices.getUserMedia(requestedMedia);
				setMediaStream(stream);
			} catch (err) {
				setError(err);
			}
		}

		if (!mediaStream) {
			void enableStream();
		} else {
			return function cleanup() {
				mediaStream.getTracks().forEach((track) => {
					track.stop();
				});
			};
		}
	}, [mediaStream, requestedMedia, setError]);

	return { stream: mediaStream, error };
}
