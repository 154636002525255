import { Navigate, useLocation } from "react-router-dom";

import { useStore } from "../zustand/store";

interface ILoginRouteWrapperProps {
	children: React.ReactNode;
}

export const LoginRouteWrapper = ({ children }: ILoginRouteWrapperProps) => {
	const loggedIn = useStore((state) => state.loggedIn);
	const location = useLocation();

	if (loggedIn) {
		return <Navigate to="/" replace />;
	}

	return <>{children}</>;
};
