import { StateCreator } from "zustand";

import { IMediaDevice } from "../../web-shared-components/react-components/web-rtc/interfaces/IMediaDevice";
import { StoreT } from "./store";

export type MediaDevicesSliceT = IState & IActions;

export interface IState {
	cams: IMediaDevice[];
	mics: IMediaDevice[];
	speakers: IMediaDevice[];
}

interface IActions {
	mediaDevicesSetAll: (mediaDevices: IState) => void;
	mediaDevicesSetCams: (cams: IMediaDevice[]) => void;
	mediaDevicesSetMics: (mics: IMediaDevice[]) => void;
	mediaDevicesSetSpeakers: (speakers: IMediaDevice[]) => void;
}

export const initialState = {
	cams: [],
	mics: [],
	speakers: []
};

export const createMediaDevicesSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	MediaDevicesSliceT
> = (set) => ({
	...initialState,
	mediaDevicesSetAll: (mediaDevices) => {
		set(
			(prev) => {
				return {
					...prev,
					mediaDevices
				};
			},
			false,
			"mediaDevicesSetAll"
		);
	},
	mediaDevicesSetCams: (cams: IMediaDevice[]) => {
		set(
			(prev) => {
				return {
					...prev,
					cams
				};
			},
			false,
			"mediaDevicesSetCams"
		);
	},
	mediaDevicesSetMics: (mics: IMediaDevice[]) => {
		set(
			(prev) => {
				return {
					...prev,
					mics
				};
			},
			false,
			"mediaDevicesSetMics"
		);
	},
	mediaDevicesSetSpeakers: (speakers: IMediaDevice[]) => {
		set(
			(prev) => {
				return {
					...prev,
					speakers
				};
			},
			false,
			"mediaDevicesSetSpeakers"
		);
	}
});
