import React, { useEffect, useMemo, useRef } from "react";
import "./sharedSpectrumAnalyzer.scss";
import SpectrumAnalyzer from "./SpectrumAnalyzer";

interface IProps {
	audioStream: MediaStream | undefined;
	width: number;
	height: number;
}

/**
 * Spectrum Analyzer
 *
 * @returns - the component
 */
export default function SharedSpectrumAnalyzer(props: IProps): JSX.Element {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const animationFrame = useRef<number>();
	const analyzer = useMemo(() => {
		if (!props.audioStream)
			return;
		return new SpectrumAnalyzer(props.audioStream);
	}, [props.audioStream])

	useEffect(() => {
		if (!analyzer || !canvasRef.current || !props.audioStream)
			return;
		const canvas = canvasRef.current;
		canvas.width = props.width;
		canvas.height = props.height;
		const ctx = canvas.getContext("2d");
		if (!ctx)
			return;
		const barWidth = 2; // canvas.width / analyzer.bufferLength;
		let x = 0;
		function animate(ctx: CanvasRenderingContext2D) {
			if (!analyzer?.analyzer)
				return;
			x = 0;
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			analyzer.analyzer.getByteFrequencyData(analyzer.dataArray);
			for (let i = 0; i < analyzer.bufferLength; i++) {
				if (i % 2 === 0)
					continue;
				const barHeight = analyzer.dataArray[i] / 10;
				ctx.fillStyle = "#537b97";
				ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
				x += 4;
			}

			animationFrame.current = requestAnimationFrame(() => { animate(ctx); });
		}
		animate(ctx);
	}, [analyzer]);

	useEffect(() => {
		return () => {
			if (animationFrame.current !== undefined)
				cancelAnimationFrame(animationFrame.current);
		}
	}, []);

	return (
		<canvas ref={canvasRef}></canvas>
	);
}
