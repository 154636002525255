import { Paper, Stack, Switch } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Header } from "../../components/common/Header/Header";
import { Typography } from "../../components/common/Typography/Typography";
import { LeftColumnE } from "../Dashboard";

interface INotificationSettingsProps {
	setLeftCol: (leftCol: LeftColumnE) => void;
}

export const NotificationSettings = ({ setLeftCol }: INotificationSettingsProps) => {
	const { t } = useTranslation();

	const notificationSettingsItems = useMemo(
		() => [
			{
				title: t("IDS_ALLOW_NOTIFICATIONS"),
				onClick: () => {}
			},
			{
				title: t("IDS_SHOW_PREVIEW"),
				onClick: () => {}
			}
		],
		[t]
	);

	return (
		<Paper sx={{ height: "100%" }}>
			<Stack height={"100%"}>
				<Header
					title={t("IDS_NOTIFICATIONS")}
					onBack={() => {
						setLeftCol(LeftColumnE.APP_SETTINGS);
					}}
				></Header>
				<Stack rowGap={2}>
					{notificationSettingsItems.map((item) => (
						<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} key={item.title}>
							<Stack>
								<Typography variant={"body1"}>{item.title}</Typography>
							</Stack>
							<Switch />
						</Stack>
					))}
				</Stack>
			</Stack>
		</Paper>
	);
};
