import { AsnClientPersistenceEventArgument } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_ClientPersistence";
import { AsnNetDatabaseContact } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import configDataCDInitial from "../../files/CDViewConfigInitial.json";
import { ConstrConfigCDFromJson } from "../../modelconstructors/ConstrModelConfig";
import { IConfigCD } from "../../models/ModelConfig";
import { IModelJsonConfigCD } from "../../models/ModelJson";

export const GetContactDetails = (
	asnClientPersistenceEventArgument: AsnClientPersistenceEventArgument
): IConfigCD | undefined => {
	let data;

	if (
		asnClientPersistenceEventArgument &&
		asnClientPersistenceEventArgument.changedItems &&
		asnClientPersistenceEventArgument.changedItems[0] &&
		asnClientPersistenceEventArgument.changedItems[0].data
	) {
		const requestData: IModelJsonConfigCD = JSON.parse(
			asnClientPersistenceEventArgument.changedItems[0].data
		) as IModelJsonConfigCD;

		if (
			requestData &&
			Object.prototype.hasOwnProperty.call(requestData, "u8sContactDetails") &&
			requestData.u8sContactDetails.length !== undefined &&
			requestData.u8sContactDetails.length > 0
		) {
			console.log("Downloading Contact Details Configurations.");
			data = requestData;
		}
	} else {
		console.log("No configurations available for contact details, using initial configurations.");
		data = configDataCDInitial;
	}
	return ConstrConfigCDFromJson(data as IModelJsonConfigCD);
};
