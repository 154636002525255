import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ComponentProps, ReactElement } from "react";

interface IIconCircleButtonProps extends Omit<ComponentProps<typeof IconButton>, "color"> {
	onClick: (event: React.MouseEvent<HTMLElement>) => void;
	color?: string;
	icon: IconProp;
	transparent?: boolean;
	outlined?: boolean;
}

export const CircleIconButton = ({
	icon,
	color,
	onClick,
	transparent,
	outlined,
	sx,
	disabled,
	...props
}: IIconCircleButtonProps): ReactElement => {
	const theme = useTheme();
	return (
		<Box>
			<IconButton
				onClick={onClick}
				sx={{
					height: "32px",
					width: "32px",
					justifyContent: "center",
					alignItems: "center",
					display: "flex",
					...(outlined && {
						border: `1px solid ${disabled ? theme.palette.black24.main : theme.palette.primary.main}`
					}),
					...sx
				}}
				disabled={disabled}
				size="small"
				{...props}
			>
				<FontAwesomeIcon
					color={disabled ? theme.palette.black24.main : color || theme.palette.primary.main}
					icon={icon}
					size={"sm"}
				/>
			</IconButton>
		</Box>
	);
};
