import React from "react";

import { DetailTypeE, SubTypeE } from "../../../models/ModelConfig";
import { ICombiDetail } from "../../../models/ModelContact";

export const DetailTypeToHTML = (detail: ICombiDetail): JSX.Element => {
	const content = detail.content;
	const type = detail.type;
	const subtype = detail.subtype;

	// u8sDetail is url or custom field is declared as url
	if (type === DetailTypeE.URL || (type === DetailTypeE.CFDTL && subtype === SubTypeE.URL)) {
		const clickable: string =
			content.indexOf("http://") < 0 && content.indexOf("https://") < 0 ? "https://" + content : content;
		return (
			<a href={clickable} target="_blank" rel="noreferrer">
				{content}
			</a>
		);
	} else if (type === DetailTypeE.EML || (type === DetailTypeE.CFDTL && subtype === SubTypeE.EML)) {
		const clickable: string = content.indexOf("mailto:") < 0 ? "mailto:" + content : content;
		return <a href={clickable}>{content}</a>;
	} else if (type === DetailTypeE.FON || (type === DetailTypeE.CFDTL && subtype === SubTypeE.FON)) {
		const clickable: string = content.indexOf("tel:") < 0 ? "tel:" + content : content;
		return (
			<a href={clickable} target="_blank" rel="noreferrer">
				{" "}
				{content}
			</a>
		);
	} else if (type === DetailTypeE.SIP) {
		const clickable: string = content.indexOf("sip:") < 0 ? "sip:" + content : content;
		return <a href={clickable}>{content}</a>;
	} else {
		return <React.Fragment>{content}</React.Fragment>;
	}
};
