// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_Auth.ts
 * "UC-Server-Access-Protocol-Auth" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.23, 20.10.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * Authentication interface.
 * ## Module description
 * This module defines structures and functions for negotiation and authentication on the UCServer.
 *
 * Clients connecting to the UCServer Webservices don't need to negotiate and login by themselfes, they should use \"createsession\" instead.
 * See the \"Web Service Overview\" section of this documentation for more details.
 */
// [PrintTSImports]
import { TSDeprecatedCallback } from "./TSDeprecatedCallback";
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
// [PrintTSRootTypes]
export const moduleName = "ENetUC_Auth";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnNegotiateServerConnectionServerTypeEx {
	/** A standard UCServer */
	eCTISERVERPROCALL = 0,
	/** A TAPI Server UCServer (Accepts no regular ProCall Clients, only TAPI drivers) */
	eCTISERVERTAPISERVER = 1,
	/** ProCall Business Server */
	eCTISERVERPROCALLBUSINESS = 2,
	/** ProCall DataCenter Server */
	eCTISERVERPROCALLDATACENTER = 3,
	/** ProCall UC as a Service Server */
	eCTISERVERPROCALLUCAAS = 4
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnNegotiateServerConnectionServerType {
	/** A standard UCServer; same value as for the old 2.2 ETAPIServer */
	eCTISERVERPROCALL = 0,
	/** Licenseable TAPI Server with Version 7 and later. It only accepts TAPI Clients (Remote-TSPs) and the */
	eCTISERVERTAPISERVER7 = 4,
	/**
	 * client setup tool for User Configuration while asnLogon
	 * business licensed UCServer
	 */
	eCTISERVERPROCALLBUSINESS = 5,
	/** UCServer ProCall DataCenter */
	eCTISERVERPROCALLDATACENTER = 6
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnNegotiateServerConnectionErrorEnum {
	general = 0,
	invalidProtocolVersion = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAttachToContactOptions {
	/** Commandline tools: dont attach to anything, just get rights to call something */
	eAttachToNothing = 0,
	/** Normal Client Login with attach to the 'old' Eventing Sessions, register for Journal etc. */
	eAttachToAll = 1,
	/** Normal Client Login, but don't register for events or other Services; this will be done explicitly */
	eAttachOnlyMinimum = 2
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnLogonErrorEnum {
	/** An internal Server error occured. */
	eNETLOGININTERNALSERVERERROR = 100,
	/** User name not found or password wrong (since V6.3). Description text in error message tells now, that the user cannot be authenticated. */
	eNETLOGININVALUSERNAME = 101,
	/** No license free for user. */
	eNETLOGINNOMOREUSERS = 103,
	/** UCServer and UCClient version are not compatible. */
	eNETLOGININVALIDVERSION = 104,
	/** This is not really an error, but the server requests the next step in authentication from the client with this \"error\". */
	eNETLOGINNEGOTIATIONREQUIRED = 105,
	/** The requested authntication method is not supported by the usermanager. */
	eNETLOGININVALIDAUTHENTICATIONMETHOD = 106,
	/** The user is not enabled on this ucserver. */
	eNETLOGINUSERNOTENABLED = 107,
	/** Computer login only: the computer name is not found. */
	eNETLOGININVALIDCOMPUTERNAME = 108,
	/** Domain authentication failed. */
	eNETLOGINDOMAINAUTHFAILED = 109,
	/** The user has not enough access rights to login on the server. At the moment this occures only with a mobile client without the access right for mobiles. */
	eNETLOGINMISSINGRIGHTS = 110,
	/** Reserved. */
	eNETLOGINMISSINGRIGHTSTS = 111,
	/** The requested application type (given by negotiate) is not compatible with this server. */
	eNETLOGINSERVERNOTCOMPATIBLE = 112,
	/** User is already logged in. */
	eNETLOGINALREADYAUTHENTICATED = 113,
	/** The has to use a TLS connection to this server. */
	eNETLOGINTLSREQUIRED = 114,
	/** User account has been locked due to too many failed login attempts. */
	eNETLOGINACCOUNTPASSWORDLOCKED = 129,
	/** The given token has been expired. */
	eNETLOGINTOKENEXIRED = 130,
	/** Too many failed login attempts, the user has to wait some time to try again. */
	eNETLOGINTOOMANYATTEMPTS = 131,
	/** Reserved. */
	eNETLOGINWRONGPACMODE = 133
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnUserTokenType {
	/** Token for authenticating to an external Server - does not allow to login to UCServer */
	eUserTokenExternal = 0,
	/** Token that allows Login To UCServer */
	eUserTokenLogin = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnClientAppID {
	/** Unbekannt */
	eCLIENTAPPIDUNKNOWN = 0,
	/** Der Windows Client */
	eCLIENTAPPIDPROCALL = 1,
	/** Das Arbeitsplatzsetup */
	eCLIENTAPPIDCLNINST = 2,
	/** ENetSN aus der SDK */
	eCLIENTAPPIDENETSN = 3,
	/** ENetTSC aus der SDK */
	eCLIENTAPPIDENETTSC = 4,
	/** IVR DLL */
	eCLIENTAPPIDIVRDLL = 5,
	/** Kommandozeile (aus der SDK) */
	eCLIENTAPPIDECTISRVCMD = 6,
	/** Mobile Phones */
	eCLIENTAPPIDMOBILE = 7,
	/** Mobile iPad */
	eCLIENTAPPIDMOBILEPAD = 8,
	/** Ein anderer Dienst über den WebService */
	eCLIENTAPPIDWEBSERVICESDK = 9,
	/** MAC-Client */
	eCLIENTAPPIDMAC = 10,
	/** Web Clients - FIVE-1411 */
	eCLIENTAPPIDPROCALLWEB = 15,
	/** SIP-Clients */
	eCLIENTAPPIDPROCALLSIP = 16,
	/** XMPP-Clients */
	eCLIENTAPPIDPROCALLXMPP = 17,
	/** Der ProCall One Client */
	eCLIENTAPPIDPROCALLONE = 18,
	/** der PushServiceManager im Server selbst */
	eCLIENTAPPIDPUSHSERVICE = 19,
	/** ProCall Analytics */
	eCLIENTAPPIDPROCALLANALYTICS = 20,
	/** LiveChat WebClient (=Agentenarbeitsplatz LC-288) */
	eCLIENTAPPIDWEBLIVECHAT = 21,
	/** Der EWS Calendar Replication Service (vormals enumClientAppID::MOBILE_SDK bzw. enumClientAppID::WEBSERVICE_SDK) */
	eCLIENTAPPIDEXCHANGECALENDARREPLICATOR = 22,
	/** Der PAC Client (gleiches Executable wie eCLIENTAPPIDPROCALL) */
	eCLIENTAPPIDPROCALLPAC = 23,
	/** Der PAC Client (gleiches Executable wie eCLIENTAPPIDPROCALL) */
	eCLIENTAPPIDPROCALLPCB = 24,
	/** ProCall Meetings Client */
	eCLIENTAPPIDWEBCONFERENCING = 25,
	/** Standalone-WebApp */
	eCLIENTAPPIDPROCALLWEBAPP = 26,
	/** Client-Connection via WebView2 */
	eCLIENTAPPIDWEBVIEW2CLIENT = 27,
	/** MS Teams App */
	eCLIENTAPPIDMSTEAMSAPP = 28
}

// [PrintTSTypeDefCode]
// [PrintTSChoiceDefCode]
export class AsnAdminLoginChoice {
	public constructor(that?: AsnAdminLoginChoice) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAdminLoginChoice {
		return new AsnAdminLoginChoice();
	}

	public static type = "AsnAdminLoginChoice";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Choice {
		return new asn1ts.Choice({
			name: "AsnAdminLoginChoice",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAdminPassword", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sAdminPasswordCrypted", idBlock: { optionalID: 1 } })
			]
		});
	}

	public u8sAdminPassword?: string;
	public u8sAdminPasswordCrypted?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @deprecated 20.06.2023 - see asnNegotiateServerConnectionEx
 */
export class AsnNegotiateServerConnectionArgument {
	public constructor(that: AsnNegotiateServerConnectionArgument) {
		TSDeprecatedCallback.deprecatedObject(1687219200, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnNegotiateServerConnectionArgument {
		return new AsnNegotiateServerConnectionArgument({
			iClientProtocolVersion: 0
		});
	}

	public static type = "AsnNegotiateServerConnectionArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNegotiateServerConnectionArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iClientProtocolVersion" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** Angeforderte Protokoll Version des Clients */
	public iClientProtocolVersion!: number;
	/**
	 * **Liste der optionalen Parameter:*
	 *
	 * - optionalParams Name \"ClientDeviceId\" Value String ID die der Client vergibt, die eineindeutig sein muss
	 * - optionalParams Name \"ClientDeviceName\" Value String Name des Devices
	 * - optionalParams Name \"AnonymousLogin\" Value AsnInt Client wünscht eine Anonymous Verbindung
	 * - optionalParams Name \"AnonymousHintData\" Value String Zusätzliche Information, damit der Server evtl. eine Verknüpfung mit einer älteren\/oder späteren anonymen Verbindung wieder herstellen kann.
	 * - optionalParams Name \"SoftphoneClient\" Value Int ('1' für aktiv) Der Client bietet Softphone Funktionalität
	 * - optionalParams Name \"ProvideAVLine\" Value Int ('1' für aktiv) Der Client erhält die AVLine als Leitung mit den entsprechenden Notifies
	 * - optionalParams Name \"ConnectionGUID\"  Value String ID ist ungleich null wenn der Client eine existierende ENetCtiClientConnectionGUID weiter benutzen will.
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @deprecated 20.06.2023 - see asnNegotiateServerConnectionEx
 */
export class AsnNegotiateServerConnectionResult {
	public constructor(that: AsnNegotiateServerConnectionResult) {
		TSDeprecatedCallback.deprecatedObject(1687219200, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnNegotiateServerConnectionResult {
		return new AsnNegotiateServerConnectionResult({
			iServerProtocolVersion: 0,
			iServerUMSecurityOption: 0,
			iServerTLSSecurityOption: 0,
			sServerAuthentication: new Uint8Array(0)
		});
	}

	public static type = "AsnNegotiateServerConnectionResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNegotiateServerConnectionResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iServerProtocolVersion" }),
				new asn1ts.Integer({ name: "iServerUMSecurityOption" }),
				new asn1ts.Integer({ name: "iServerTLSSecurityOption" }),
				new asn1ts.OctetString({ name: "sServerAuthentication" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true }),
				new asn1ts.Real({ name: "stServerTime", optional: true })
			]
		});
	}

	/** Protokoll Version des Servers */
	public iServerProtocolVersion!: number;
	/** Verfügbare Login Option */
	public iServerUMSecurityOption!: number;
	/** TLS nie verwenden, ermöglichen oder voraussetzen. */
	public iServerTLSSecurityOption!: number;
	/** Timestamp des Servers für Verschlüsselung am Client */
	public sServerAuthentication!: Uint8Array;
	/**
	 * **Liste der möglichen optionalen Parameter:*
	 *
	 * - optionalParams Name \"ServerBusyTimeout\" Value AsnInt Sekunden bis zum nächsten Verbindungsversuch
	 * - optionalParams Name \"serverVersion\" Value String Versionsstring des Servers
	 * - optionalParams Name \"productVersion\" Value String UCServer-ProductVersion (für ProCall identisch mit der FileVersion, ProCallGo fängt bei 1.xx an)
	 * - optionalParams Name \"presenceDomains\" Value String Liste der PrsenceDomains die der Server verwaltet duch ; getrennt.
	 * - optionalParams Name \"ServerProtocolVersion\" Value String
	 * - optionalParams Name \"PresenceServicesDisabled\" Value AsnInt
	 * - optionalParams Name \"UserUserRightsDisabled\" Value AsnInt
	 * - optionalParams Name \"AnonymousUsername\" Value String  - UserName für Anonymous login
	 * - optionalParams Name \"ServerType\" Value AsnInt AsnNegotiateServerConnectionServerType
	 * - optionalParams Name \"ServerManType\" Value AsnInt OEMId des Servers (ab Version 6.2 verfügbar)
	 * - optionalParams Name \"serverConnectionId\" Value String GUID der Verbindung, die der Server zugewiesen hat und dazu verwendet auf diese Verbindung zu verweisen bzw. zu identifizieren.
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
	/** Timestamp zum Abgleich der Uhrzeit zwischen Client und Server. Ermöglicht z.B. korrekte Anzeige von Call-Duration bei abweichender Serverzeit. */
	public stServerTime?: Date;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @deprecated 20.06.2023 - see asnNegotiateServerConnectionEx
 */
export class AsnNegotiateServerConnectionError {
	public constructor(that: AsnNegotiateServerConnectionError) {
		TSDeprecatedCallback.deprecatedObject(1687219200, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnNegotiateServerConnectionError {
		return new AsnNegotiateServerConnectionError({
			iErrorDetail: 0,
			u8sErrorString: ""
		});
	}

	public static type = "AsnNegotiateServerConnectionError";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNegotiateServerConnectionError",
			...params,
			value: [
				new asn1ts.Integer({ name: "iErrorDetail" }),
				new asn1ts.Utf8String({ name: "u8sErrorString" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** A number representing the error */
	public iErrorDetail!: number;
	/** A string representing the error */
	public u8sErrorString!: string;
	/**
	 * optionalParams Name \"serverVersion\" Value string
	 * optionalParams Name \"productVersion\" Value string
	 * optionalParams Name \"ServerProtocolVersion\" Value number
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnAuthenticationSimple {
	public constructor(that: AsnAuthenticationSimple) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAuthenticationSimple {
		return new AsnAuthenticationSimple({
			u8sClientUsername: "",
			u8sClientUserPassword: ""
		});
	}

	public static type = "AsnAuthenticationSimple";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAuthenticationSimple",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sClientUsername" }),
				new asn1ts.Utf8String({ name: "u8sClientUserPassword" })
			]
		});
	}

	/**
	 * user login daten
	 * plain text username und password
	 * diese Variante ist die einzige, die für nicht ESTOS Software unterstützt wird.
	 */
	public u8sClientUsername!: string;
	public u8sClientUserPassword!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnAuthenticationSimpleCrypt {
	public constructor(that: AsnAuthenticationSimpleCrypt) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAuthenticationSimpleCrypt {
		return new AsnAuthenticationSimpleCrypt({
			u8sClientUsername: "",
			u8sClientUserPasswordCrypted: ""
		});
	}

	public static type = "AsnAuthenticationSimpleCrypt";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAuthenticationSimpleCrypt",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sClientUsername" }),
				new asn1ts.Utf8String({ name: "u8sClientUserPasswordCrypted" })
			]
		});
	}

	/**
	 * user login daten
	 * plain text username und ESTOS encrypted password
	 */
	public u8sClientUsername!: string;
	public u8sClientUserPasswordCrypted!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnAuthenticationWinuser {
	public constructor(that: AsnAuthenticationWinuser) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAuthenticationWinuser {
		return new AsnAuthenticationWinuser({
			u8sClientUsername: "",
			u8sClientUsernameAuthorisation: ""
		});
	}

	public static type = "AsnAuthenticationWinuser";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAuthenticationWinuser",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sClientUsername" }),
				new asn1ts.Utf8String({ name: "u8sClientUsernameAuthorisation" })
			]
		});
	}

	/**
	 * windows username only
	 * plain text username und ESTOS encrypted username
	 */
	public u8sClientUsername!: string;
	public u8sClientUsernameAuthorisation!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnAuthenticationEnhanced {
	public constructor(that: AsnAuthenticationEnhanced) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAuthenticationEnhanced {
		return new AsnAuthenticationEnhanced({
			u8sClientUsername: "",
			u8sClientUsernameAuthorisation: "",
			u8sAuthenticationMethod: "",
			sAuthCredetials: new Uint8Array(0)
		});
	}

	public static type = "AsnAuthenticationEnhanced";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAuthenticationEnhanced",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sClientUsername" }),
				new asn1ts.Utf8String({ name: "u8sClientUsernameAuthorisation" }),
				new asn1ts.Utf8String({ name: "u8sAuthenticationMethod" }),
				new asn1ts.OctetString({ name: "sAuthCredetials" })
			]
		});
	}

	/**
	 * user login daten
	 * plain text username und ESTOS encrypted username
	 */
	public u8sClientUsername!: string;
	public u8sClientUsernameAuthorisation!: string;
	/** AuthenticationMethod z.B. Basic, Digest, NTLM, Negotiate, JWT */
	public u8sAuthenticationMethod!: string;
	/** Authentication credentials */
	public sAuthCredetials!: Uint8Array;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * On successfull login the user gets it´s contact details back
 * The sequence has been flagged deprecated as the former asnLogon used a special error to handle login negotiation which we now handle in the reu
 *
 * @deprecated 07.06.2023 *
 */
export class AsnLogonResult {
	public constructor(that: AsnLogonResult) {
		TSDeprecatedCallback.deprecatedObject(1686096000, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnLogonResult {
		return new AsnLogonResult({
			u8sLoggedInUser: "",
			iValidContact: 0,
			ownContact: ENetUC_Common_AsnContact.AsnContact["initEmpty"].call(0)
		});
	}

	public static type = "AsnLogonResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnLogonResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sLoggedInUser" }),
				new asn1ts.Integer({ name: "iValidContact" }),
				ENetUC_Common_AsnContact.AsnContact.getASN1Schema({ name: "ownContact" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 0 } }),
				ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList.getASN1Schema({ name: "listConfigSTUNandTURN", idBlock: { optionalID: 1 } }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listDataCenterServers", idBlock: { optionalID: 2 } })
			]
		});
	}

	/** Der CtiUserName der eingeloggt wurde. */
	public u8sLoggedInUser!: string;
	/** Wenn erfolgreich ist dieser Wert immer 1 und der ownContact gefüllt. */
	public iValidContact!: number;
	/** Die eigenen ActiveContact daten */
	public ownContact!: ENetUC_Common_AsnContact.AsnContact;
	/** optionalParams Name \"CompletedEventID\" Value string */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
	/** Die STUN\/TURN Konfiguration fuer SIP-Softphone und Audio\/Video call. */
	public listConfigSTUNandTURN?: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList;
	/** Die Server die zu dem Rechnerverbund gehören, an den man sich gerade gültg angemeldet hat. */
	public listDataCenterServers?: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Contains the success details of a logon operation
 */
export class AsnLogonResultSuccess {
	public constructor(that: AsnLogonResultSuccess) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnLogonResultSuccess {
		return new AsnLogonResultSuccess({
			u8sLoggedInUser: "",
			iValidContact: 0,
			ownContact: ENetUC_Common_AsnContact.AsnContact["initEmpty"].call(0)
		});
	}

	public static type = "AsnLogonResultSuccess";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnLogonResultSuccess",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sLoggedInUser" }),
				new asn1ts.Integer({ name: "iValidContact" }),
				ENetUC_Common_AsnContact.AsnContact.getASN1Schema({ name: "ownContact" }),
				ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList.getASN1Schema({ name: "listConfigSTUNandTURN", idBlock: { optionalID: 1 } }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listDataCenterServers", idBlock: { optionalID: 2 } })
			]
		});
	}

	/** Der CtiUserName der eingeloggt wurde. */
	public u8sLoggedInUser!: string;
	/** Wenn erfolgreich ist dieser Wert immer 1 und der ownContact gefüllt. */
	public iValidContact!: number;
	/** Die eigenen ActiveContact daten */
	public ownContact!: ENetUC_Common_AsnContact.AsnContact;
	/** Die STUN\/TURN Konfiguration fuer SIP-Softphone und Audio\/Video call. */
	public listConfigSTUNandTURN?: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList;
	/** Die Server die zu dem Rechnerverbund gehören, an den man sich gerade gültg angemeldet hat. */
	public listDataCenterServers?: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @deprecated 07.06.2023 *
 */
export class AsnLogonError {
	public constructor(that: AsnLogonError) {
		TSDeprecatedCallback.deprecatedObject(1686096000, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnLogonError {
		return new AsnLogonError({
			iErrorDetail: 0,
			u8sErrorString: "",
			sAuthCredetials: new Uint8Array(0),
			u8sFailedUser: ""
		});
	}

	public static type = "AsnLogonError";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnLogonError",
			...params,
			value: [
				new asn1ts.Integer({ name: "iErrorDetail" }),
				new asn1ts.Utf8String({ name: "u8sErrorString" }),
				new asn1ts.OctetString({ name: "sAuthCredetials" }),
				new asn1ts.Utf8String({ name: "u8sFailedUser" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** Logon error detail, see AsnLogonErrorEnum. */
	public iErrorDetail!: number;
	public u8sErrorString!: string;
	/**
	 * Server Authentication credentials for SSPI
	 * This is used if in case of eNETLOGIN_NEGOTIATIONREQUIRED
	 */
	public sAuthCredetials!: Uint8Array;
	/** This field contains the CtiUserName that was not able to log in. (This is only required for clninst to detect when a user is not enabled) */
	public u8sFailedUser!: string;
	/** optionalParams Name \"HomeServer\" Value String */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnLogoffArgument {
	public constructor(that?: AsnLogoffArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnLogoffArgument {
		return new AsnLogoffArgument();
	}

	public static type = "AsnLogoffArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnLogoffArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetUserToken.
 *
 * @deprecated 20.06.2023 -&gt; AsnGetUserTokenExResult
 */
export class AsnGetUserTokenResult {
	public constructor(that: AsnGetUserTokenResult) {
		TSDeprecatedCallback.deprecatedObject(1687219200, moduleName, this);
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetUserTokenResult {
		return new AsnGetUserTokenResult({
			sToken: ""
		});
	}

	public static type = "AsnGetUserTokenResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetUserTokenResult",
			...params,
			value: [
				new asn1ts.OctetString({ name: "sToken" })
			]
		});
	}

	/** JWT Token */
	public sToken!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetClientAuthenticationArgument {
	public constructor(that: AsnSetClientAuthenticationArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnSetClientAuthenticationArgument {
		return new AsnSetClientAuthenticationArgument({
			iCtiClientAppID: 0,
			sClientAuthentication: new Uint8Array(0)
		});
	}

	public static type = "AsnSetClientAuthenticationArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetClientAuthenticationArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iCtiClientAppID" }),
				new asn1ts.OctetString({ name: "sClientAuthentication" })
			]
		});
	}

	/** ApplicationID des Clients (enumClientAppID::ECLIENTAPPID_ Konstanten) in applicationtypes.h */
	public iCtiClientAppID!: number;
	/** sClientAuthenticate timestamp, */
	public sClientAuthentication!: Uint8Array;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetClientAuthenticationResult {
	public constructor(that: AsnSetClientAuthenticationResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnSetClientAuthenticationResult {
		return new AsnSetClientAuthenticationResult({
			bSuccess: false
		});
	}

	public static type = "AsnSetClientAuthenticationResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetClientAuthenticationResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" })
			]
		});
	}

	public bSuccess!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Profile values added as public claim as [Service_type]\/[Profile elementname] : [Profile elementvalue] used by asnGetUserToken.
 */
export class AsnProfileAttribute {
	public constructor(that: AsnProfileAttribute) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnProfileAttribute {
		return new AsnProfileAttribute({
			u8sServiceType: "",
			u8seName: ""
		});
	}

	public static type = "AsnProfileAttribute";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnProfileAttribute",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sServiceType" }),
				new asn1ts.Utf8String({ name: "u8seName" }),
				new asn1ts.Utf8String({ name: "u8sValue", idBlock: { optionalID: 0 } })
			]
		});
	}

	/** Service Type used as a namespace for a specific profile attribute */
	public u8sServiceType!: string;
	public u8seName!: string;
	/** Can be used as placeholder is the profile attribute does not exits */
	public u8sValue?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnGetLoggedInContact.
 * This opertaion does not need any parameter since the logged in contact is alread well known on the server.
 */
export class AsnGetLoggedInContactArgument {
	public constructor(that?: AsnGetLoggedInContactArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetLoggedInContactArgument {
		return new AsnGetLoggedInContactArgument();
	}

	public static type = "AsnGetLoggedInContactArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetLoggedInContactArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetLoggedInContact.
 */
export class AsnGetLoggedInContactResult {
	public constructor(that: AsnGetLoggedInContactResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetLoggedInContactResult {
		return new AsnGetLoggedInContactResult({
			u8sLoggedInUser: "",
			ownContact: ENetUC_Common_AsnContact.AsnContact["initEmpty"].call(0)
		});
	}

	public static type = "AsnGetLoggedInContactResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetLoggedInContactResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sLoggedInUser" }),
				ENetUC_Common_AsnContact.AsnContact.getASN1Schema({ name: "ownContact" })
			]
		});
	}

	/** The Login User Name */
	public u8sLoggedInUser!: string;
	/** Own Contact Data */
	public ownContact!: ENetUC_Common_AsnContact.AsnContact;
}

// [PrintTSTypeDefCode]
// [PrintTSChoiceDefCode]
export class AsnAuthenticationChoice {
	public constructor(that?: AsnAuthenticationChoice) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAuthenticationChoice {
		return new AsnAuthenticationChoice();
	}

	public static type = "AsnAuthenticationChoice";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Choice {
		return new asn1ts.Choice({
			name: "AsnAuthenticationChoice",
			...params,
			value: [
				AsnAuthenticationSimple.getASN1Schema({ name: "simple", idBlock: { optionalID: 0 } }),
				AsnAuthenticationSimpleCrypt.getASN1Schema({ name: "simplecrypt", idBlock: { optionalID: 1 } }),
				AsnAuthenticationWinuser.getASN1Schema({ name: "winuser", idBlock: { optionalID: 2 } }),
				AsnAuthenticationEnhanced.getASN1Schema({ name: "enhanced", idBlock: { optionalID: 3 } })
			]
		});
	}

	public simple?: AsnAuthenticationSimple;
	public simplecrypt?: AsnAuthenticationSimpleCrypt;
	public winuser?: AsnAuthenticationWinuser;
	public enhanced?: AsnAuthenticationEnhanced;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * UCServer administrator login credentials used as optional by AsnGetUserTokenArgument
 */
export class AsnAdminLogin {
	public constructor(that: AsnAdminLogin) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnAdminLogin {
		return new AsnAdminLogin({
			u8sAdminUsername: "",
			adminPassword: AsnAdminLoginChoice["initEmpty"].call(0)
		});
	}

	public static type = "AsnAdminLogin";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAdminLogin",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAdminUsername" }),
				AsnAdminLoginChoice.getASN1Schema({ name: "adminPassword" })
			]
		});
	}

	/** Adminstrator user name */
	public u8sAdminUsername!: string;
	/** Administrator password */
	public adminPassword!: AsnAdminLoginChoice;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnProfileAttributeList extends Array<AsnProfileAttribute> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnProfileAttribute.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Used to login to the UCServer
 * This function supersedes the former asnLogon which was using a custom Error object
 * The new implementation uses the standard AsnRequestError and puts renegotiation into the AsnLogonResultChoice object
 * !!!! DRAFT !!!! -&gt; not yet implemented
 * asnLogonEx OPERATION
 * ARGUMENT	arg AsnLogonArgument
 * RESULT		res AsnLogonResultChoice
 * ERRORS		{AsnRequestError}
 * ::= 1005
 */
export class AsnLogonArgument {
	public constructor(that: AsnLogonArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnLogonArgument {
		return new AsnLogonArgument({
			iVersion: 0,
			u8sVersion: "",
			iAttachToContact: 0,
			u8sClientComputerName: "",
			authentication: AsnAuthenticationChoice["initEmpty"].call(0)
		});
	}

	public static type = "AsnLogonArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnLogonArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iVersion" }),
				new asn1ts.Utf8String({ name: "u8sVersion" }),
				new asn1ts.Integer({ name: "iAttachToContact" }),
				new asn1ts.Utf8String({ name: "u8sClientComputerName" }),
				AsnAuthenticationChoice.getASN1Schema({ name: "authentication" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** version of the protocol */
	public iVersion!: number;
	/** Client Version (z.B. 3.0.0.100) */
	public u8sVersion!: string;
	/** Event notification Option */
	public iAttachToContact!: number;
	/** Computer Name - for Display Purposes */
	public u8sClientComputerName!: string;
	/** Authentication Options */
	public authentication!: AsnAuthenticationChoice;
	/** optionalParams Name \"CompletedEventID\" Value string */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnGetUserToken.
 */
export class AsnGetUserTokenArgument {
	public constructor(that?: AsnGetUserTokenArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetUserTokenArgument {
		return new AsnGetUserTokenArgument();
	}

	public static type = "AsnGetUserTokenArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetUserTokenArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iType", idBlock: { optionalID: 0 } }),
				new asn1ts.Integer({ name: "iTTL", idBlock: { optionalID: 1 } }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "lstServiceType", idBlock: { optionalID: 2 } }),
				AsnProfileAttributeList.getASN1Schema({ name: "lstProfileAttributes", idBlock: { optionalID: 3 } }),
				AsnAdminLogin.getASN1Schema({ name: "adminLogin", idBlock: { optionalID: 4 } })
			]
		});
	}

	/** Type of token (default eUserTokenExternal) */
	public iType?: number;
	/** Desired Time to live in seconds (default 24 hours) */
	public iTTL?: number;
	/** List of Service Types e.g. FAQ, RANKING */
	public lstServiceType?: ENetUC_Common.UTF8StringList;
	/** List from profile values added as public claim as [Service_type]\/[Profile elementname] : [Profile elementvalue] */
	public lstProfileAttributes?: AsnProfileAttributeList;
	/** Admin login credentials to create token with admin rights */
	public adminLogin?: AsnAdminLogin;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Negotiate server and client versions (current version)
 * Handshaking the core details for the client and the server
 * !!!! DRAFT !!!! -&gt; not yet implemented
 * asnNegotiateServerConnectionEx OPERATION
 * ARGUMENT	arg AsnNegotiateServerConnectionExArgument
 * RESULT		res AsnNegotiateServerConnectionExResult
 * ERRORS		{AsnRequestError}
 * ::= 1006
 * Argument for the asnNegotiateServerConnectionEx operation
 */
export class AsnNegotiateServerConnectionExArgument {
	public constructor(that: AsnNegotiateServerConnectionExArgument) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnNegotiateServerConnectionExArgument {
		return new AsnNegotiateServerConnectionExArgument({
			iClientProtocolVersion: 0,
			u8sClientDeviceId: "",
			u8sClientDeviceName: "",
			bSoftphoneClient: false,
			bProvideAVLine: false
		});
	}

	public static type = "AsnNegotiateServerConnectionExArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNegotiateServerConnectionExArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iClientProtocolVersion" }),
				new asn1ts.Utf8String({ name: "u8sClientDeviceId" }),
				new asn1ts.Utf8String({ name: "u8sClientDeviceName" }),
				new asn1ts.Boolean({ name: "bSoftphoneClient" }),
				new asn1ts.Boolean({ name: "bProvideAVLine" }),
				new asn1ts.Utf8String({ name: "u8sConnectionGUID", idBlock: { optionalID: 0 } }),
				new asn1ts.Boolean({ name: "bAnonymousLogin", idBlock: { optionalID: 1 } }),
				new asn1ts.Utf8String({ name: "u8sAnonymousHintData", idBlock: { optionalID: 2 } })
			]
		});
	}

	/** This is the client protocol version the server shall be compatible to */
	public iClientProtocolVersion!: number;
	/** A device id the client provides on his side */
	public u8sClientDeviceId!: string;
	/** A client name to show in the administrative view for this connection */
	public u8sClientDeviceName!: string;
	/** This client offers softphone functionalities */
	public bSoftphoneClient!: boolean;
	/** The client gets the AV line together with the appropriate notifies */
	public bProvideAVLine!: boolean;
	/** In case the client is reusing a connection this is the identifier of the former connection to the server */
	public u8sConnectionGUID?: string;
	/** The client wants an anonymous login */
	public bAnonymousLogin?: boolean;
	/** Additional hint data that allows the server to map older or later connections to the same client */
	public u8sAnonymousHintData?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of the asnNegotiateServerConnectionEx operation
 */
export class AsnNegotiateServerConnectionExResult {
	public constructor(that: AsnNegotiateServerConnectionExResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnNegotiateServerConnectionExResult {
		return new AsnNegotiateServerConnectionExResult({
			iServerType: 0,
			iServerProtocolVersion: 0,
			iServerUMSecurityOption: 0,
			iServerTLSSecurityOption: 0,
			sServerAuthentication: new Uint8Array(0),
			stServerTime: new Date(),
			u8sProductVersion: "",
			u8sConnectionGUID: ""
		});
	}

	public static type = "AsnNegotiateServerConnectionExResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNegotiateServerConnectionExResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iServerType" }),
				new asn1ts.Integer({ name: "iServerProtocolVersion" }),
				new asn1ts.Integer({ name: "iServerUMSecurityOption" }),
				new asn1ts.Integer({ name: "iServerTLSSecurityOption" }),
				new asn1ts.OctetString({ name: "sServerAuthentication" }),
				new asn1ts.Real({ name: "stServerTime" }),
				new asn1ts.Utf8String({ name: "u8sProductVersion" }),
				new asn1ts.Utf8String({ name: "u8sConnectionGUID" }),
				new asn1ts.Integer({ name: "iOEM", idBlock: { optionalID: 0 } }),
				new asn1ts.Integer({ name: "iServerBusyTimeout", idBlock: { optionalID: 1 } }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sPresenceDomains", idBlock: { optionalID: 2 } }),
				new asn1ts.Boolean({ name: "bPresenceServicesDisabled", idBlock: { optionalID: 3 } }),
				new asn1ts.Boolean({ name: "bUserUserRightsDisabled", idBlock: { optionalID: 4 } }),
				new asn1ts.Utf8String({ name: "u8sAnonymousUsername", idBlock: { optionalID: 5 } })
			]
		});
	}

	/** The type of server we are connecting to */
	public iServerType!: number;
	/** The protocol version of the server */
	public iServerProtocolVersion!: number;
	/** Verfügbare Login Option */
	public iServerUMSecurityOption!: number;
	/** TLS nie verwenden, ermöglichen oder voraussetzen. */
	public iServerTLSSecurityOption!: number;
	/** A realm the server wants the client to encrypt data with */
	public sServerAuthentication!: Uint8Array;
	/** The server time so that the client is able to properly display times the server is providing */
	public stServerTime!: Date;
	/** Version of the server */
	public u8sProductVersion!: string;
	/** The server side connection id for this connection */
	public u8sConnectionGUID!: string;
	/** The oem of the server (only set if not estos) */
	public iOEM?: number;
	/** In case the server is busy, this is a hint when to retry connecting to it */
	public iServerBusyTimeout?: number;
	/** The list of presence domains the server is handling */
	public u8sPresenceDomains?: ENetUC_Common.UTF8StringList;
	/** presence services are disable on the server */
	public bPresenceServicesDisabled?: boolean;
	/** user user rights are disable on the server */
	public bUserUserRightsDisabled?: boolean;
	/** In case the user logged in anonymous this holds the username the user is identified with */
	public u8sAnonymousUsername?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSChoiceDefCode]
export class AsnLogonResultChoice {
	public constructor(that?: AsnLogonResultChoice) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnLogonResultChoice {
		return new AsnLogonResultChoice();
	}

	public static type = "AsnLogonResultChoice";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Choice {
		return new asn1ts.Choice({
			name: "AsnLogonResultChoice",
			...params,
			value: [
				AsnLogonResultSuccess.getASN1Schema({ name: "success", idBlock: { optionalID: 0 } }),
				AsnAuthenticationSimpleCrypt.getASN1Schema({ name: "renegotiation", idBlock: { optionalID: 1 } })
			]
		});
	}

	public success?: AsnLogonResultSuccess;
	public renegotiation?: AsnAuthenticationSimpleCrypt;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Contains the renegotiation details of a logon operation
 */
export class AsnLogonResultRenegotiation {
	public constructor(that: AsnLogonResultRenegotiation) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnLogonResultRenegotiation {
		return new AsnLogonResultRenegotiation({
			sAuthCredetials: new Uint8Array(0)
		});
	}

	public static type = "AsnLogonResultRenegotiation";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnLogonResultRenegotiation",
			...params,
			value: [
				new asn1ts.OctetString({ name: "sAuthCredetials" })
			]
		});
	}

	/** Blob which is used in renegotiation szenarios */
	public sAuthCredetials!: Uint8Array;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetUserTokenEx.
 */
export class AsnGetUserTokenExResult {
	public constructor(that: AsnGetUserTokenExResult) {
		Object.assign(this, that);
	}

	private static initEmpty(): AsnGetUserTokenExResult {
		return new AsnGetUserTokenExResult({
			sToken: ""
		});
	}

	public static type = "AsnGetUserTokenExResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetUserTokenExResult",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "sToken" })
			]
		});
	}

	/** JWT Token */
	public sToken!: string;
}
