import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Box } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { orderBy } from "lodash";
import React, { FC, useEffect } from "react";

import { AsnPresenceStateFlags } from "../../../../web-shared-components/asn1/EUCSrv/stubs/ENetROSEInterface";
import { AsnReachabilityEnum } from "../../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { Placeholder } from "../../../components/Placeholder/Placeholder";
import { addSipPrefixIfNotExists } from "../../../lib/commonHelper";
import { ConstrContactFromModel } from "../../../modelconstructors/ConstrModelContact";
import { ConstrContentDetailsFromJson } from "../../../modelconstructors/ConstrModelContent";
import { IAsnNetDatabaseJournalEx } from "../../../zustand/journalSlice";
import { useStore } from "../../../zustand/store";
import { JournalListItem } from "./JournalListItem";

interface IJournalListProps {
	selectedJournalEntryId: string | null;
	journalEntries: IAsnNetDatabaseJournalEx[];
	onClick?: (journalEntry: IAsnNetDatabaseJournalEx) => void;
}

export const JournalList: FC<IJournalListProps> = ({ journalEntries, selectedJournalEntryId, onClick }) => {
	const parentRef = React.useRef();
	const [animationRef, enable] = useAutoAnimate();

	const contactsMap = useStore((state) => state.contactsPresence);
	const clientContentsMap = useStore((state) => state.contactsDetails);
	const customLabels = useStore((state) => state.customLabels);
	const cDetails = useStore((state) => state.contactDetails);

	const sortedJournalEntries = orderBy(journalEntries, "stStartTime", "desc");

	const rowVirtualizer = useVirtualizer({
		count: sortedJournalEntries.length,
		getScrollElement: () => parentRef.current || null,
		estimateSize: () => 70,
		overscan: 20
	});

	useEffect(() => {
		enable(!rowVirtualizer.isScrolling);
	}, [rowVirtualizer.isScrolling, enable]);

	if (!sortedJournalEntries.length) {
		return <Placeholder />;
	}

	return (
		<Box flex={1} style={{ overflowY: "auto", overflowX: "hidden" }} ref={parentRef}>
			<Box
				sx={{
					width: "100%",
					height: `${rowVirtualizer.getTotalSize()}px`,
					position: "relative",
					listStyleType: "none"
				}}
				ref={animationRef}
			>
				{rowVirtualizer.getVirtualItems().map((virtualItem) => {
					const journalEntry = sortedJournalEntries[virtualItem.index];
					const contact = contactsMap.get(
						addSipPrefixIfNotExists(journalEntry.u8sContactEntryID.toLowerCase()) ||
							addSipPrefixIfNotExists(journalEntry.u8sCtiServerUserName) ||
							journalEntry.u8sContactEntryID
					);
					const clientContent = clientContentsMap.get(
						addSipPrefixIfNotExists(journalEntry.u8sContactEntryID.toLowerCase()) ||
							addSipPrefixIfNotExists(journalEntry.u8sCtiServerUserName) ||
							journalEntry.u8sContactEntryID
					);
					const contentDetails = ConstrContentDetailsFromJson({
						...clientContent,
						statusInfos: {
							presence: contact?.presence || 0,
							asnCustomNote: contact?.customNote || "",
							asnNextAppointment: contact?.nextAppointment,
							reachability: contact?.reachability || AsnReachabilityEnum.eREACHABILITYNORMAL,
							agentState: contact?.agentState,
							currentAppointments: contact?.currentAppointments,
							isMobileAvailable: contact?.presence
								? !!(contact?.presence & AsnPresenceStateFlags.eMOBILEAVAILABLITY)
								: false,
							seqCalls: contact?.seqCalls,
							seqPhoneLines: contact?.seqPhoneLines,
							seqLineForwards: contact?.seqLineForwards,
							msTeamsEmail: contact?.msTeamsEmail
						}
					});
					const constructedContact = ConstrContactFromModel(
						contentDetails,
						customLabels.CustLabels,
						cDetails.ContactDetails,
						"contactDetails"
					);
					return (
						<Box
							key={journalEntry.u8sConnectionID}
							sx={{
								position: "absolute",
								top: virtualItem.start,
								left: 0,
								width: "100%",
								height: `${virtualItem.size}px`
							}}
						>
							<JournalListItem
								selected={selectedJournalEntryId === journalEntry.u8sConnectionID}
								journalEntry={journalEntry}
								contact={constructedContact}
								onClick={onClick}
							/>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};
