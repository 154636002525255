import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import { faChevronDown } from "web-fortawesome/npm/pro-light";

import { AsnNetDatabaseJournal } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal";
import { JournalEntryMemo } from "./JournalEntryMemo";
import { JournalEntrySummary } from "./JournalEntrySummary";

interface IRecentJournalEntriesProps {
	journalEntries: AsnNetDatabaseJournal[];
}

export const RecentJournalEntries = ({ journalEntries = [] }: IRecentJournalEntriesProps) => {
	return (
		<>
			{journalEntries.map((journalEntry) => {
				const hasMemo = !!journalEntry.u8sMemo;
				return (
					<Accordion key={journalEntry.u8sConnectionID} disableGutters disabled={!journalEntry.u8sMemo}>
						<AccordionSummary
							sx={{ flex: 1, overflow: "hidden", "& .MuiAccordionSummary-content": { overflow: "hidden" } }}
							expandIcon={hasMemo && <FontAwesomeIcon icon={faChevronDown} />}
						>
							<JournalEntrySummary journalEntry={journalEntry} />
						</AccordionSummary>
						<AccordionDetails>
							<JournalEntryMemo memo={journalEntry.u8sMemo} />
						</AccordionDetails>
					</Accordion>
				);
			})}
		</>
	);
};
