import { StateCreator } from "zustand";

import { StoreT } from "./store";

enum DateFilterE {
	MONTH = "MONTH",
	WEEK = "WEEK",
	DAY = "DAY"
}

export enum ReadStatusE {
	All = "all",
	Unread = "unread"
}

export type ConfigSliceT = IState & IActions;

interface IState {
	journalDateFilter: DateFilterE;
	journalReadFilter: ReadStatusE;
}

interface IActions {
	setReadFilter: (filter: ReadStatusE) => void;
}

export const initialState = {
	journalDateFilter: DateFilterE.MONTH,
	journalReadFilter: ReadStatusE.All
};

export const createConfigSlice: StateCreator<
	StoreT,
	[["zustand/devtools", never], ["zustand/subscribeWithSelector", never]],
	[],
	ConfigSliceT
> = (set) => ({
	...initialState,
	setReadFilter: (filter: ReadStatusE) => {
		set(
			(prev) => ({
				...prev,
				journalReadFilter: filter
			}),
			false,
			"setReadFilter"
		);
	}
});
