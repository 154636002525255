import "./ContactAddrBlock.scss";

import { t } from "i18next";
import React from "react";

import { DetailTypeE, IFontColor, StyleTypeE } from "../../../models/ModelConfig";
import { ICombiDetail } from "../../../models/ModelContact";

interface IAddrBlockProps {
	detail: ICombiDetail;
	type: "cd" | "cw" | "ch";
}
export const ContactAddrBlock = (props: IAddrBlockProps): JSX.Element => {
	const addr: ICombiDetail = props.detail;
	const prefix = props.type.toString();

	const title: string = (addr.displayLabel as string).startsWith("IDS_") ? t(addr.displayLabel) : addr.displayLabel;

	const details: ICombiDetail[] = addr.combiDetails as ICombiDetail[];

	const highlight: boolean =
		addr.style !== undefined && addr.style !== null && addr.style.indexOf(StyleTypeE.HGH) > -1 ? true : false;

	const bold: boolean =
		addr.style !== undefined && addr.style !== null && addr.style.indexOf(StyleTypeE.BLD) > -1 ? true : false;

	const color: IFontColor = {
		color:
			addr.style !== undefined && addr.style !== null && addr.style.indexOf(StyleTypeE.RED) > -1
				? StyleTypeE.RED
				: addr.style !== undefined && addr.style !== null && addr.style.indexOf(StyleTypeE.GRN) > -1
					? StyleTypeE.GRN
					: null
	};

	const height: string =
		details.length === 3
			? " " + prefix + "Ab_height3"
			: details.length === 4
				? " " + prefix + "Ab_height4"
				: details.length === 5
					? " " + prefix + "Ab_height5"
					: "";

	return (
		<>
			<div className={(highlight ? prefix + "Ab_highlight" : "") + height} />

			<div className={prefix + "Ab_addrLine"}>
				<div className={prefix + "Ab_addrLabel"}>{title}</div>

				<div className={prefix + "Ab_addrContent"}>
					{details.map((detail: ICombiDetail, index: number) => {
						return (
							<React.Fragment key={index}>
								{detail.type === DetailTypeE.PLZ || detail.type === DetailTypeE.CTY ? (
									<span
										className={
											(bold ? "cV_bold" : "") +
											(color.color === StyleTypeE.RED ? " cV_cR" : "") +
											(color.color === StyleTypeE.GRN ? " cV_cG" : "")
										}
									>
										{detail.content}&nbsp;
									</span>
								) : (
									<div
										className={
											(bold ? "cV_bold" : "") +
											(color.color === StyleTypeE.RED ? " cV_cR" : "") +
											(color.color === StyleTypeE.GRN ? " cV_cG" : "")
										}
									>
										{detail.content}
									</div>
								)}
							</React.Fragment>
						);
					})}
				</div>
			</div>
		</>
	);
};
