import {
	DetailTypeE,
	IConfigBlock,
	IConfigCD,
	IConfigCW,
	IConfigU8sDetail,
	StyleTypeE,
	SubTypeE
} from "../models/ModelConfig";
import { IModelJsonConfigCD, IModelJsonConfigCW, IModelJsonContentParts } from "../models/ModelJson";

const fnValidateStyles = (styles: StyleTypeE[]): StyleTypeE[] | null => {
	let styleTypes: StyleTypeE[] | null = [];

	if (styles.indexOf(StyleTypeE.BLD) > -1) {
		styleTypes.push(StyleTypeE.BLD);
	}
	if (styles.indexOf(StyleTypeE.HGH) > -1) {
		styleTypes.push(StyleTypeE.HGH);
	}
	if (styles.indexOf(StyleTypeE.RED) > -1) {
		styleTypes.push(StyleTypeE.RED);
	}
	if (styles.indexOf(StyleTypeE.GRN) > -1 && styleTypes.indexOf(StyleTypeE.RED) === -1) {
		styleTypes.push(StyleTypeE.GRN);
	}

	if (styleTypes && styleTypes.length === 0) {
		styleTypes = null;
	}

	return styleTypes;
};

/**
 * Converts Config JSON data (= configuration data with customer modifications) to an Array of ConfigBlocks.
 *
 * @param entryConfigJSON - JSON data to construct an configuration ( = array of IConfigBlock)
 * @returns an array of ConfigBlocks
 */
export const ConstrConfigFromJson = (entryConfigJSON: IModelJsonContentParts): IConfigBlock[] => {
	if (!entryConfigJSON) {
		return [];
	}

	const entryContactDetails = entryConfigJSON.u8sContactDetails;
	const entryCustomFields = entryConfigJSON.customFields;
	const entryContactURLs = entryConfigJSON.contactURLs;

	const configBlocksArrayResult: IConfigBlock[] = [];

	if (entryContactDetails) {
		for (let i = 0; i < entryContactDetails.length; i++) {
			const entryCDBlock = entryContactDetails[i];
			const blockDetails: IConfigU8sDetail[] = [];

			if (entryCDBlock && entryCDBlock.details && entryCDBlock.details.length) {
				for (let j = 0; j < entryCDBlock.details.length; j++) {
					const addrDetailsArray: IConfigU8sDetail[] = [];
					const entryDetail = entryCDBlock.details[j];

					if (!entryDetail) {
						return [];
					}

					if (entryDetail.type === DetailTypeE.ADR && entryDetail.details && entryDetail.details.length) {
						for (let k = 0; k < entryDetail.details.length; k++) {
							const entryAddr = entryDetail.details[k];
							const addrDetail: IConfigU8sDetail = {
								id: "",
								name: entryDetail.details[k].name,
								type: Object.values(DetailTypeE).find((type) => type === entryAddr.type)
									? entryAddr.type
									: DetailTypeE.EMPTY,
								subtype: Object.values(SubTypeE).find((type) => type === entryAddr.subtype)
									? entryAddr.subtype
									: SubTypeE.EMPTY,
								local: entryAddr.local,
								title: entryAddr.title,
								visible: entryAddr.visible === true || entryAddr.visible === false ? entryAddr.visible : true,
								style: null,
								details: entryAddr.details
							};

							addrDetailsArray.push(addrDetail);
						}
					}

					const blockDetail: IConfigU8sDetail = {
						id: "",
						name: entryDetail.name,
						type: Object.values(DetailTypeE).find((type) => type === entryDetail.type)
							? entryDetail.type
							: DetailTypeE.TXT,
						subtype: Object.values(SubTypeE).find((type) => type === entryDetail.subtype)
							? entryDetail.subtype
							: SubTypeE.EMPTY,
						local: entryDetail.local,
						title: entryDetail.title,
						visible: entryDetail.visible === true || entryDetail.visible === false ? entryDetail.visible : false,
						style: entryDetail.style !== null ? fnValidateStyles(entryDetail.style) : null,
						details: addrDetailsArray
					};

					blockDetails.push(blockDetail);
				}
			}

			const detailBlock: IConfigBlock = {
				id: !isNaN(entryCDBlock.id) ? entryCDBlock.id : 0,
				name: entryCDBlock.name,
				type: DetailTypeE.BLK,
				local: entryCDBlock.local,
				title: entryCDBlock.title,
				visible: entryCDBlock.visible === true || entryCDBlock.visible === false ? entryCDBlock.visible : false,
				style: entryCDBlock.style && entryCDBlock.style.indexOf(StyleTypeE.HGH) >= 0 ? [StyleTypeE.HGH] : null,
				details: blockDetails
			};

			configBlocksArrayResult.push(detailBlock);
		}
	}

	if (entryCustomFields) {
		const entryCFBlock: IConfigBlock = entryCustomFields[0];
		const entryCFDetails = entryCFBlock.details;
		const CFDetails: IConfigU8sDetail[] = [];

		if (entryCFDetails && entryCFDetails.length && entryCFDetails.length > 0) {
			for (let k = 0; k < entryCFDetails.length; k++) {
				const entryCFDetail = entryCFBlock.details[k];
				const cfSubType: SubTypeE =
					entryCFDetail.subtype === SubTypeE.FON ||
					entryCFDetail.subtype === SubTypeE.EML ||
					entryCFDetail.subtype === SubTypeE.URL
						? entryCFDetail.subtype
						: SubTypeE.TXT;

				const customField: IConfigU8sDetail = {
					id: "",
					name: entryCFDetail.name,
					type: DetailTypeE.CFDTL,
					subtype: cfSubType,
					local: entryCFDetail.local,
					title: entryCFDetail.title,
					visible: entryCFDetail.visible === true || entryCFDetail.visible === false ? entryCFDetail.visible : false,
					style: entryCFDetail.style !== null ? fnValidateStyles(entryCFDetail.style) : null,
					details: entryCFDetail.details
				};

				CFDetails.push(customField);
			}
		}

		const customFieldBlock: IConfigBlock = {
			id: !isNaN(entryCFBlock.id) ? entryCFBlock.id : 0,
			name: entryCFBlock.name,
			type: DetailTypeE.CFBLK,
			local: entryCFBlock.local,
			title: entryCFBlock.title,
			visible: entryCFBlock.visible === true || entryCFBlock.visible === false ? entryCFBlock.visible : false,
			style: entryCFBlock.style !== null && entryCFBlock.style.indexOf(StyleTypeE.HGH) >= 0 ? [StyleTypeE.HGH] : null,
			details: CFDetails
		};

		configBlocksArrayResult.push(customFieldBlock);
	}

	if (entryContactURLs) {
		const entryCUBlock: IConfigBlock = entryContactURLs[0];
		const entryCUDetails = entryCUBlock.details;
		const CUDetails: IConfigU8sDetail[] = [];

		if (entryCUDetails && entryCUDetails.length && entryCUDetails.length > 0) {
			for (let k = 0; k < entryCUDetails.length; k++) {
				const entryCUDetail = entryCUBlock.details[k];
				const cuSubType: SubTypeE = entryCUDetail.subtype === SubTypeE.APP ? entryCUDetail.subtype : SubTypeE.LNK;

				const contactURL: IConfigU8sDetail = {
					id: "",
					name: entryCUDetail.name,
					type: DetailTypeE.CUDTL,
					subtype: cuSubType,
					local: entryCUDetail.local,
					title: entryCUDetail.name,
					visible: entryCUDetail.visible === true || entryCUDetail.visible === false ? entryCUDetail.visible : false,
					style: null,
					details: entryCUDetail.details
				};

				CUDetails.push(contactURL);
			}
		}

		const contactURLBlock: IConfigBlock = {
			id: !isNaN(entryCUBlock.id) ? entryCUBlock.id : 0,
			name: entryCUBlock.name,
			type: DetailTypeE.CUBLK,
			local: entryCUBlock.local,
			title: entryCUBlock.title,
			visible: entryCUBlock.visible === true || entryCUBlock.visible === false ? entryCUBlock.visible : false,
			style: null,
			details: CUDetails
		};

		configBlocksArrayResult.push(contactURLBlock);
	}

	configBlocksArrayResult.sort(function (a, b) {
		return a.id - b.id;
	});

	return configBlocksArrayResult;
};

/**
 * Converts a contactdetails_config.json (= configuration file for contact details with customer modifications) to a CDConfig object.
 *
 * @param configModelCD - IModelCDConfigEditor data input
 * @returns a CDConfig object.
 */
export const ConstrConfigCDFromJson = (configModelCD: IModelJsonConfigCD): IConfigCD => {
	const cdConfig: IConfigCD = {
		ContactDetails: ConstrConfigFromJson(configModelCD)
	};

	return cdConfig;
};

/**
 * Converts a conversationwindow_config.json (= configuration file for conversation windows with customer modifications) to a CWConfig object.
 * Uses an auxiliary construction and takes a short detour via a ModelCDConfig to be able to use the function ConstrConfigFromJSON
 *
 * @param configModelCW - IModelCWConfigEditor data input
 * @returns a ConfigCW object.
 */

export const ConstrConfigCWFromJson = (configModelCW: IModelJsonConfigCW): IConfigCW => {
	const buildHelperModel = (configDetails: IModelJsonContentParts): IModelJsonConfigCD => {
		const modelCDnoComment: IModelJsonConfigCD = {
			_comment_type: "",
			_comment_subtype: "",
			_comment_style: "",
			nameInfos: [],
			u8sContactDetails: configDetails.u8sContactDetails,
			customFields: configDetails.customFields,
			contactURLs: configDetails.contactURLs
		};
		return modelCDnoComment;
	};

	const configBlocksRC: IConfigCD = Object.prototype.hasOwnProperty.call(configModelCW, "RemoteContact")
		? ConstrConfigCDFromJson(buildHelperModel(configModelCW.RemoteContact))
		: { ContactDetails: [] };
	const configBlocksHC: IConfigCD = Object.prototype.hasOwnProperty.call(configModelCW, "HoldContact")
		? ConstrConfigCDFromJson(buildHelperModel(configModelCW.HoldContact))
		: { ContactDetails: [] };
	const configBlocksLC: IConfigCD = Object.prototype.hasOwnProperty.call(configModelCW, "LocalContact")
		? ConstrConfigCDFromJson(buildHelperModel(configModelCW.LocalContact))
		: { ContactDetails: [] };
	const configBlocksRD: IConfigCD = Object.prototype.hasOwnProperty.call(configModelCW, "RedirectContact")
		? ConstrConfigCDFromJson(buildHelperModel(configModelCW.RedirectContact))
		: { ContactDetails: [] };

	const configCW: IConfigCW = {
		RemoteContact: configBlocksRC.ContactDetails,
		HoldContact: configBlocksHC.ContactDetails,
		LocalContact: configBlocksLC.ContactDetails,
		RedirectContact: configBlocksRD.ContactDetails
	};

	return configCW;
};
