// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetROSEInterface_Converter.ts
 * "UC-Server-Access-Protocol-Main" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.23, 20.10.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetROSEInterface from "./ENetROSEInterface";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
import * as ENetUC_Common_Appointments from "./ENetUC_Common_Appointments";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";
import * as ENetUC_Common_AsnContact_Converter from "./ENetUC_Common_AsnContact_Converter";
import * as ENetUC_Common_Appointments_Converter from "./ENetUC_Common_Appointments_Converter";
// [PrintTSRootTypes]
export const moduleName = "ENetROSEInterface_Converter";

// [PrintTSEncoderDecoderCode]
export class AsnLineForwardProfileChoice_Converter {
	public static toJSON(s: ENetROSEInterface.AsnLineForwardProfileChoice, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnLineForwardProfileChoice & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLineForwardProfileChoice");

		const t = {} as ENetROSEInterface.AsnLineForwardProfileChoice & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLineForwardProfileChoice";
		if (s.iAllUserLines != null)
			TSConverter.fillJSONParam(s, t, "iAllUserLines", "number", errors, newContext);
		else if (s.u8sSingleLineURI != null)
			TSConverter.fillJSONParam(s, t, "u8sSingleLineURI", "string", errors, newContext);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnLineForwardProfileChoice"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnLineForwardProfileChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLineForwardProfileChoice");

		let t: ENetROSEInterface.AsnLineForwardProfileChoice | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnLineForwardProfileChoice>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnLineForwardProfileChoice["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.iAllUserLines !== undefined) {
				if (TSConverter.validateParam(s, "iAllUserLines", "number", errors, newContext))
					t.iAllUserLines = s.iAllUserLines;
			} else if (s.u8sSingleLineURI !== undefined) {
				if (TSConverter.validateParam(s, "u8sSingleLineURI", "string", errors, newContext))
					t.u8sSingleLineURI = s.u8sSingleLineURI;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}
		if (errors.validateResult(newContext, "AsnLineForwardProfileChoice"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnLineForwardProfileChoice | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "AsnLineForwardProfileChoice";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLineForwardProfileChoice");

		// [Print_BER_EncoderChoiceDefCode]
		if (TSConverter.validateParam(s, "iAllUserLines", "number", errors, newContext, true))
			t = new asn1ts.Integer({ value: s.iAllUserLines, name: "iAllUserLines", idBlock: { optionalID: 0 } });
		else if (TSConverter.validateParam(s, "u8sSingleLineURI", "string", errors, newContext, true))
			t = new asn1ts.Utf8String({ value: s.u8sSingleLineURI, name: "u8sSingleLineURI", idBlock: { optionalID: 1 } });
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
		if (errors.validateResult(newContext, "AsnLineForwardProfileChoice"))
			return t;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnLineForwardProfileChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLineForwardProfileChoice");

		let t: ENetROSEInterface.AsnLineForwardProfileChoice | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnLineForwardProfileChoice.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnLineForwardProfileChoice["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "iAllUserLines" && asn1ts.Integer.typeGuard(s)) {
				const _iAllUserLines = s.getValue();
				if (TSConverter.validateParamType(_iAllUserLines, "iAllUserLines", "number", errors, newContext, false))
					t.iAllUserLines = _iAllUserLines;
			} else if (s.choiceName === "u8sSingleLineURI" && asn1ts.Utf8String.typeGuard(s)) {
				const _u8sSingleLineURI = s.getValue();
				if (TSConverter.validateParamType(_u8sSingleLineURI, "u8sSingleLineURI", "string", errors, newContext, false))
					t.u8sSingleLineURI = _u8sSingleLineURI;
			} else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnLineForwardProfileChoice"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNumberFormatRegExpression2_Converter {
	public static toJSON(s: ENetROSEInterface.AsnNumberFormatRegExpression2, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnNumberFormatRegExpression2 & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNumberFormatRegExpression2");

		const t = {} as ENetROSEInterface.AsnNumberFormatRegExpression2 & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNumberFormatRegExpression2";
		TSConverter.fillJSONParam(s, t, "u8sSearch", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sReplace", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bMatchReplace", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnNumberFormatRegExpression2"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNumberFormatRegExpression2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNumberFormatRegExpression2");

		let t: ENetROSEInterface.AsnNumberFormatRegExpression2 | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnNumberFormatRegExpression2>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnNumberFormatRegExpression2["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sSearch", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sReplace", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bMatchReplace", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnNumberFormatRegExpression2"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnNumberFormatRegExpression2 | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNumberFormatRegExpression2";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNumberFormatRegExpression2");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sSearch", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sReplace", "string", errors, newContext);
		TSConverter.validateParam(s, "bMatchReplace", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnNumberFormatRegExpression2")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sSearch, name: "u8sSearch" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sReplace, name: "u8sReplace" }));
			t.push(new asn1ts.Boolean({ value: s.bMatchReplace, name: "bMatchReplace" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNumberFormatRegExpression2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNumberFormatRegExpression2");

		let t: ENetROSEInterface.AsnNumberFormatRegExpression2 | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnNumberFormatRegExpression2.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnNumberFormatRegExpression2["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sSearch", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sReplace", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bMatchReplace", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnNumberFormatRegExpression2"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnKeepAliveArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnKeepAliveArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnKeepAliveArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnKeepAliveArgument");

		const t = {} as ENetROSEInterface.AsnKeepAliveArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnKeepAliveArgument";
		TSConverter.fillJSONParam(s, t, "dummy", "object", errors, newContext);

		if (errors.validateResult(newContext, "AsnKeepAliveArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnKeepAliveArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnKeepAliveArgument");

		let t: ENetROSEInterface.AsnKeepAliveArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnKeepAliveArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnKeepAliveArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnKeepAliveArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnKeepAliveArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnKeepAliveArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnKeepAliveArgument");

		// [Print_BER_EncoderSeqDefCode]
		if (errors.validateResult(newContext, "AsnKeepAliveArgument")) {
			t.push(new asn1ts.Null({ name: "dummy" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnKeepAliveArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnKeepAliveArgument");

		let t: ENetROSEInterface.AsnKeepAliveArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnKeepAliveArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnKeepAliveArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "dummy", "Null", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnKeepAliveArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetLocationInformationArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetLocationInformationArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetLocationInformationArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLocationInformationArgument");

		const t = {} as ENetROSEInterface.AsnGetLocationInformationArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetLocationInformationArgument";
		TSConverter.fillJSONParam(s, t, "u8sLocationID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetLocationInformationArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLocationInformationArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLocationInformationArgument");

		let t: ENetROSEInterface.AsnGetLocationInformationArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetLocationInformationArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetLocationInformationArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLocationID", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetLocationInformationArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetLocationInformationArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetLocationInformationArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLocationInformationArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLocationID", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetLocationInformationArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLocationID, name: "u8sLocationID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLocationInformationArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLocationInformationArgument");

		let t: ENetROSEInterface.AsnGetLocationInformationArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetLocationInformationArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetLocationInformationArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLocationID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetLocationInformationArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetLocationInformation2Result_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetLocationInformation2Result, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetLocationInformation2Result & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetLocationInformation2Result");

		const t = {} as ENetROSEInterface.AsnSetLocationInformation2Result & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetLocationInformation2Result";

		if (errors.validateResult(newContext, "AsnSetLocationInformation2Result"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetLocationInformation2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetLocationInformation2Result");

		let t: ENetROSEInterface.AsnSetLocationInformation2Result | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetLocationInformation2Result>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetLocationInformation2Result["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnSetLocationInformation2Result"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetLocationInformation2Result | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetLocationInformation2Result";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetLocationInformation2Result");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetLocationInformation2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetLocationInformation2Result");

		let t: ENetROSEInterface.AsnSetLocationInformation2Result | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetLocationInformation2Result.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetLocationInformation2Result["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnSetLocationInformation2Result"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetLocationIDsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetLocationIDsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetLocationIDsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLocationIDsArgument");

		const t = {} as ENetROSEInterface.AsnGetLocationIDsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetLocationIDsArgument";
		if (s.locationIDs) {
			const _locationIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.locationIDs, errors, newContext, "locationIDs");
			if (_locationIDs)
				t.locationIDs = _locationIDs;
		}

		if (errors.validateResult(newContext, "AsnGetLocationIDsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLocationIDsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLocationIDsArgument");

		let t: ENetROSEInterface.AsnGetLocationIDsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetLocationIDsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetLocationIDsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			t.locationIDs = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.locationIDs, errors, newContext, "locationIDs", true);
		}
		if (errors.validateResult(newContext, "AsnGetLocationIDsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetLocationIDsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetLocationIDsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLocationIDsArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _locationIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.locationIDs, errors, newContext, "locationIDs", 0);
		if (errors.validateResult(newContext, "AsnGetLocationIDsArgument")) {
			if (_locationIDs)
				t.push(_locationIDs);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLocationIDsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLocationIDsArgument");

		let t: ENetROSEInterface.AsnGetLocationIDsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetLocationIDsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetLocationIDsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			t.locationIDs = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "locationIDs"), errors, newContext, "locationIDs", true);
		}

		if (errors.validateResult(newContext, "AsnGetLocationIDsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetLocationIDsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetLocationIDsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetLocationIDsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLocationIDsResult");

		const t = {} as ENetROSEInterface.AsnGetLocationIDsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetLocationIDsResult";
		const _locationIDMD5ChecksumList = ENetUC_Common_Converter.AsnStringPairList_Converter.toJSON(s.locationIDMD5ChecksumList, errors, newContext, "locationIDMD5ChecksumList");
		if (_locationIDMD5ChecksumList)
			t.locationIDMD5ChecksumList = _locationIDMD5ChecksumList;

		if (errors.validateResult(newContext, "AsnGetLocationIDsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLocationIDsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLocationIDsResult");

		let t: ENetROSEInterface.AsnGetLocationIDsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetLocationIDsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetLocationIDsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _locationidmd5checksumlist = ENetUC_Common_Converter.AsnStringPairList_Converter.fromJSON(s.locationIDMD5ChecksumList, errors, newContext, "locationIDMD5ChecksumList", false);
			if (_locationidmd5checksumlist)
				t.locationIDMD5ChecksumList = _locationidmd5checksumlist;
		}
		if (errors.validateResult(newContext, "AsnGetLocationIDsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetLocationIDsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetLocationIDsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLocationIDsResult");

		// [Print_BER_EncoderSeqDefCode]
		const _locationIDMD5ChecksumList = ENetUC_Common_Converter.AsnStringPairList_Converter.toBER(s.locationIDMD5ChecksumList, errors, newContext, "locationIDMD5ChecksumList");
		if (errors.validateResult(newContext, "AsnGetLocationIDsResult")) {
			if (_locationIDMD5ChecksumList)
				t.push(_locationIDMD5ChecksumList);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLocationIDsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLocationIDsResult");

		let t: ENetROSEInterface.AsnGetLocationIDsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetLocationIDsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetLocationIDsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _locationidmd5checksumlist = ENetUC_Common_Converter.AsnStringPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "locationIDMD5ChecksumList"), errors, newContext, "locationIDMD5ChecksumList");
			if (_locationidmd5checksumlist)
				t.locationIDMD5ChecksumList = _locationidmd5checksumlist;
		}

		if (errors.validateResult(newContext, "AsnGetLocationIDsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLocationInformationChangedArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnLocationInformationChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnLocationInformationChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLocationInformationChangedArgument");

		const t = {} as ENetROSEInterface.AsnLocationInformationChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLocationInformationChangedArgument";
		TSConverter.fillJSONParam(s, t, "u8sLocationID", "string", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnLocationInformationChangedArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnLocationInformationChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLocationInformationChangedArgument");

		let t: ENetROSEInterface.AsnLocationInformationChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnLocationInformationChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnLocationInformationChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLocationID", "string", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnLocationInformationChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnLocationInformationChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnLocationInformationChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLocationInformationChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLocationID", "string", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnLocationInformationChangedArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLocationID, name: "u8sLocationID" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnLocationInformationChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLocationInformationChangedArgument");

		let t: ENetROSEInterface.AsnLocationInformationChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnLocationInformationChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnLocationInformationChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLocationID", "Utf8String", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnLocationInformationChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetLicensedFeatureCountArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetLicensedFeatureCountArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetLicensedFeatureCountArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLicensedFeatureCountArgument");

		const t = {} as ENetROSEInterface.AsnGetLicensedFeatureCountArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetLicensedFeatureCountArgument";

		if (errors.validateResult(newContext, "AsnGetLicensedFeatureCountArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLicensedFeatureCountArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLicensedFeatureCountArgument");

		let t: ENetROSEInterface.AsnGetLicensedFeatureCountArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetLicensedFeatureCountArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetLicensedFeatureCountArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnGetLicensedFeatureCountArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetLicensedFeatureCountArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetLicensedFeatureCountArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLicensedFeatureCountArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLicensedFeatureCountArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLicensedFeatureCountArgument");

		let t: ENetROSEInterface.AsnGetLicensedFeatureCountArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetLicensedFeatureCountArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetLicensedFeatureCountArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetLicensedFeatureCountArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetLicensedFeatureCountResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetLicensedFeatureCountResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetLicensedFeatureCountResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLicensedFeatureCountResult");

		const t = {} as ENetROSEInterface.AsnGetLicensedFeatureCountResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetLicensedFeatureCountResult";
		const _licensedFeatures = ENetUC_Common_Converter.AsnIntegerPairList_Converter.toJSON(s.licensedFeatures, errors, newContext, "licensedFeatures");
		if (_licensedFeatures)
			t.licensedFeatures = _licensedFeatures;

		if (errors.validateResult(newContext, "AsnGetLicensedFeatureCountResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLicensedFeatureCountResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLicensedFeatureCountResult");

		let t: ENetROSEInterface.AsnGetLicensedFeatureCountResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetLicensedFeatureCountResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetLicensedFeatureCountResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _licensedfeatures = ENetUC_Common_Converter.AsnIntegerPairList_Converter.fromJSON(s.licensedFeatures, errors, newContext, "licensedFeatures", false);
			if (_licensedfeatures)
				t.licensedFeatures = _licensedfeatures;
		}
		if (errors.validateResult(newContext, "AsnGetLicensedFeatureCountResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetLicensedFeatureCountResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetLicensedFeatureCountResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLicensedFeatureCountResult");

		// [Print_BER_EncoderSeqDefCode]
		const _licensedFeatures = ENetUC_Common_Converter.AsnIntegerPairList_Converter.toBER(s.licensedFeatures, errors, newContext, "licensedFeatures");
		if (errors.validateResult(newContext, "AsnGetLicensedFeatureCountResult")) {
			if (_licensedFeatures)
				t.push(_licensedFeatures);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLicensedFeatureCountResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLicensedFeatureCountResult");

		let t: ENetROSEInterface.AsnGetLicensedFeatureCountResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetLicensedFeatureCountResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetLicensedFeatureCountResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _licensedfeatures = ENetUC_Common_Converter.AsnIntegerPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "licensedFeatures"), errors, newContext, "licensedFeatures");
			if (_licensedfeatures)
				t.licensedFeatures = _licensedfeatures;
		}

		if (errors.validateResult(newContext, "AsnGetLicensedFeatureCountResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetAdministrativeMonitorContentArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAdministrativeMonitorContentArgument");

		const t = {} as ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetAdministrativeMonitorContentArgument";

		if (errors.validateResult(newContext, "AsnGetAdministrativeMonitorContentArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAdministrativeMonitorContentArgument");

		let t: ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnGetAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetAdministrativeMonitorContentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAdministrativeMonitorContentArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAdministrativeMonitorContentArgument");

		let t: ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetAdministrativeMonitorContentResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetAdministrativeMonitorContentResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetAdministrativeMonitorContentResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAdministrativeMonitorContentResult");

		const t = {} as ENetROSEInterface.AsnGetAdministrativeMonitorContentResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetAdministrativeMonitorContentResult";
		TSConverter.fillJSONParam(s, t, "iDisableAllMonitorConfiguration", "number", errors, newContext);
		const _listContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.listContactIDs, errors, newContext, "listContactIDs");
		if (_listContactIDs)
			t.listContactIDs = _listContactIDs;

		if (errors.validateResult(newContext, "AsnGetAdministrativeMonitorContentResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetAdministrativeMonitorContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAdministrativeMonitorContentResult");

		let t: ENetROSEInterface.AsnGetAdministrativeMonitorContentResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetAdministrativeMonitorContentResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetAdministrativeMonitorContentResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iDisableAllMonitorConfiguration", "number", errors, context, false);
			const _listcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.listContactIDs, errors, newContext, "listContactIDs", false);
			if (_listcontactids)
				t.listContactIDs = _listcontactids;
		}
		if (errors.validateResult(newContext, "AsnGetAdministrativeMonitorContentResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetAdministrativeMonitorContentResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetAdministrativeMonitorContentResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAdministrativeMonitorContentResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iDisableAllMonitorConfiguration", "number", errors, newContext);
		const _listContactIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.listContactIDs, errors, newContext, "listContactIDs");
		if (errors.validateResult(newContext, "AsnGetAdministrativeMonitorContentResult")) {
			t.push(new asn1ts.Integer({ value: s.iDisableAllMonitorConfiguration, name: "iDisableAllMonitorConfiguration" }));
			if (_listContactIDs)
				t.push(_listContactIDs);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetAdministrativeMonitorContentResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAdministrativeMonitorContentResult");

		let t: ENetROSEInterface.AsnGetAdministrativeMonitorContentResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetAdministrativeMonitorContentResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetAdministrativeMonitorContentResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iDisableAllMonitorConfiguration", "Integer", errors, newContext);
			const _listcontactids = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listContactIDs"), errors, newContext, "listContactIDs");
			if (_listcontactids)
				t.listContactIDs = _listcontactids;
		}

		if (errors.validateResult(newContext, "AsnGetAdministrativeMonitorContentResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnReloadAdministrativeMonitorContentArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnReloadAdministrativeMonitorContentArgument");

		const t = {} as ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnReloadAdministrativeMonitorContentArgument";

		if (errors.validateResult(newContext, "AsnReloadAdministrativeMonitorContentArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnReloadAdministrativeMonitorContentArgument");

		let t: ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnReloadAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnReloadAdministrativeMonitorContentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnReloadAdministrativeMonitorContentArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnReloadAdministrativeMonitorContentArgument");

		let t: ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnReloadAdministrativeMonitorContentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRetriggerFederationConnectionArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnRetriggerFederationConnectionArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnRetriggerFederationConnectionArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRetriggerFederationConnectionArgument");

		const t = {} as ENetROSEInterface.AsnRetriggerFederationConnectionArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRetriggerFederationConnectionArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnRetriggerFederationConnectionArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRetriggerFederationConnectionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRetriggerFederationConnectionArgument");

		let t: ENetROSEInterface.AsnRetriggerFederationConnectionArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnRetriggerFederationConnectionArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnRetriggerFederationConnectionArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnRetriggerFederationConnectionArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnRetriggerFederationConnectionArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRetriggerFederationConnectionArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRetriggerFederationConnectionArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnRetriggerFederationConnectionArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRetriggerFederationConnectionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRetriggerFederationConnectionArgument");

		let t: ENetROSEInterface.AsnRetriggerFederationConnectionArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnRetriggerFederationConnectionArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnRetriggerFederationConnectionArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRetriggerFederationConnectionArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnActionFilterEntry_Converter {
	public static toJSON(s: ENetROSEInterface.AsnActionFilterEntry, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnActionFilterEntry & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnActionFilterEntry");

		const t = {} as ENetROSEInterface.AsnActionFilterEntry & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnActionFilterEntry";
		TSConverter.fillJSONParam(s, t, "u8sFilterFieldName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sFilterFieldExpression", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnActionFilterEntry"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnActionFilterEntry | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnActionFilterEntry");

		let t: ENetROSEInterface.AsnActionFilterEntry | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnActionFilterEntry>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnActionFilterEntry["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sFilterFieldName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sFilterFieldExpression", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnActionFilterEntry"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnActionFilterEntry | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnActionFilterEntry";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnActionFilterEntry");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sFilterFieldName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sFilterFieldExpression", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnActionFilterEntry")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sFilterFieldName, name: "u8sFilterFieldName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sFilterFieldExpression, name: "u8sFilterFieldExpression" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnActionFilterEntry | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnActionFilterEntry");

		let t: ENetROSEInterface.AsnActionFilterEntry | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnActionFilterEntry.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnActionFilterEntry["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sFilterFieldName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sFilterFieldExpression", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnActionFilterEntry"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetAppointmentForwardsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetAppointmentForwardsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetAppointmentForwardsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetAppointmentForwardsResult");

		const t = {} as ENetROSEInterface.AsnSetAppointmentForwardsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetAppointmentForwardsResult";

		if (errors.validateResult(newContext, "AsnSetAppointmentForwardsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetAppointmentForwardsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetAppointmentForwardsResult");

		let t: ENetROSEInterface.AsnSetAppointmentForwardsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetAppointmentForwardsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetAppointmentForwardsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnSetAppointmentForwardsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetAppointmentForwardsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetAppointmentForwardsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetAppointmentForwardsResult");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetAppointmentForwardsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetAppointmentForwardsResult");

		let t: ENetROSEInterface.AsnSetAppointmentForwardsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetAppointmentForwardsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetAppointmentForwardsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnSetAppointmentForwardsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetAppointmentForwardsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetAppointmentForwardsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetAppointmentForwardsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAppointmentForwardsArgument");

		const t = {} as ENetROSEInterface.AsnGetAppointmentForwardsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetAppointmentForwardsArgument";

		if (errors.validateResult(newContext, "AsnGetAppointmentForwardsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetAppointmentForwardsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAppointmentForwardsArgument");

		let t: ENetROSEInterface.AsnGetAppointmentForwardsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetAppointmentForwardsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetAppointmentForwardsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnGetAppointmentForwardsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetAppointmentForwardsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetAppointmentForwardsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAppointmentForwardsArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetAppointmentForwardsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAppointmentForwardsArgument");

		let t: ENetROSEInterface.AsnGetAppointmentForwardsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetAppointmentForwardsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetAppointmentForwardsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetAppointmentForwardsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnOperationCompleteArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnOperationCompleteArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnOperationCompleteArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOperationCompleteArgument");

		const t = {} as ENetROSEInterface.AsnOperationCompleteArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnOperationCompleteArgument";
		TSConverter.fillJSONParam(s, t, "u8sCompletionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnOperationCompleteArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnOperationCompleteArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOperationCompleteArgument");

		let t: ENetROSEInterface.AsnOperationCompleteArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnOperationCompleteArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnOperationCompleteArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sCompletionID", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnOperationCompleteArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnOperationCompleteArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnOperationCompleteArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOperationCompleteArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sCompletionID", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnOperationCompleteArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sCompletionID, name: "u8sCompletionID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnOperationCompleteArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOperationCompleteArgument");

		let t: ENetROSEInterface.AsnOperationCompleteArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnOperationCompleteArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnOperationCompleteArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sCompletionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnOperationCompleteArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnOpenContactsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnOpenContactsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnOpenContactsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOpenContactsResult");

		const t = {} as ENetROSEInterface.AsnOpenContactsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnOpenContactsResult";
		const _contacts = ENetUC_Common_AsnContact_Converter.AsnContacts_Converter.toJSON(s.contacts, errors, newContext, "contacts");
		if (_contacts)
			t.contacts = _contacts;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnOpenContactsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnOpenContactsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOpenContactsResult");

		let t: ENetROSEInterface.AsnOpenContactsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnOpenContactsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnOpenContactsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _contacts = ENetUC_Common_AsnContact_Converter.AsnContacts_Converter.fromJSON(s.contacts, errors, newContext, "contacts", false);
			if (_contacts)
				t.contacts = _contacts;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnOpenContactsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnOpenContactsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnOpenContactsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOpenContactsResult");

		// [Print_BER_EncoderSeqDefCode]
		const _contacts = ENetUC_Common_AsnContact_Converter.AsnContacts_Converter.toBER(s.contacts, errors, newContext, "contacts");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnOpenContactsResult")) {
			if (_contacts)
				t.push(_contacts);
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnOpenContactsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOpenContactsResult");

		let t: ENetROSEInterface.AsnOpenContactsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnOpenContactsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnOpenContactsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _contacts = ENetUC_Common_AsnContact_Converter.AsnContacts_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contacts"), errors, newContext, "contacts");
			if (_contacts)
				t.contacts = _contacts;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnOpenContactsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCloseContactsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnCloseContactsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnCloseContactsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCloseContactsResult");

		const t = {} as ENetROSEInterface.AsnCloseContactsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCloseContactsResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sComment", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCloseContactsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnCloseContactsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCloseContactsResult");

		let t: ENetROSEInterface.AsnCloseContactsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnCloseContactsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnCloseContactsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sComment", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnCloseContactsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnCloseContactsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCloseContactsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCloseContactsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sComment", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnCloseContactsResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sComment, name: "u8sComment" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnCloseContactsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCloseContactsResult");

		let t: ENetROSEInterface.AsnCloseContactsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnCloseContactsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnCloseContactsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sComment", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCloseContactsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetSettingsDynamicRightsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetSettingsDynamicRightsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetSettingsDynamicRightsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetSettingsDynamicRightsArgument");

		const t = {} as ENetROSEInterface.AsnSetSettingsDynamicRightsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetSettingsDynamicRightsArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);
		const _seqDynamicRightsList = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.toJSON(s.seqDynamicRightsList, errors, newContext, "seqDynamicRightsList");
		if (_seqDynamicRightsList)
			t.seqDynamicRightsList = _seqDynamicRightsList;

		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetSettingsDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetSettingsDynamicRightsArgument");

		let t: ENetROSEInterface.AsnSetSettingsDynamicRightsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetSettingsDynamicRightsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetSettingsDynamicRightsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, context, false);
			const _seqdynamicrightslist = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.fromJSON(s.seqDynamicRightsList, errors, newContext, "seqDynamicRightsList", false);
			if (_seqdynamicrightslist)
				t.seqDynamicRightsList = _seqdynamicrightslist;
		}
		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetSettingsDynamicRightsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetSettingsDynamicRightsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetSettingsDynamicRightsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		const _seqDynamicRightsList = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.toBER(s.seqDynamicRightsList, errors, newContext, "seqDynamicRightsList");
		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			if (_seqDynamicRightsList)
				t.push(_seqDynamicRightsList);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetSettingsDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetSettingsDynamicRightsArgument");

		let t: ENetROSEInterface.AsnSetSettingsDynamicRightsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetSettingsDynamicRightsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetSettingsDynamicRightsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
			const _seqdynamicrightslist = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqDynamicRightsList"), errors, newContext, "seqDynamicRightsList");
			if (_seqdynamicrightslist)
				t.seqDynamicRightsList = _seqdynamicrightslist;
		}

		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetSettingsDynamicRightsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetSettingsDynamicRightsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetSettingsDynamicRightsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetSettingsDynamicRightsResult");

		const t = {} as ENetROSEInterface.AsnSetSettingsDynamicRightsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetSettingsDynamicRightsResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetSettingsDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetSettingsDynamicRightsResult");

		let t: ENetROSEInterface.AsnSetSettingsDynamicRightsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetSettingsDynamicRightsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetSettingsDynamicRightsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetSettingsDynamicRightsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetSettingsDynamicRightsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetSettingsDynamicRightsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetSettingsDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetSettingsDynamicRightsResult");

		let t: ENetROSEInterface.AsnSetSettingsDynamicRightsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetSettingsDynamicRightsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetSettingsDynamicRightsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetSettingsDynamicRightsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetSettingsDynamicRightsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetSettingsDynamicRightsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsDynamicRightsArgument");

		const t = {} as ENetROSEInterface.AsnGetSettingsDynamicRightsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetSettingsDynamicRightsArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsDynamicRightsArgument");

		let t: ENetROSEInterface.AsnGetSettingsDynamicRightsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetSettingsDynamicRightsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetSettingsDynamicRightsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetSettingsDynamicRightsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetSettingsDynamicRightsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsDynamicRightsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsDynamicRightsArgument");

		let t: ENetROSEInterface.AsnGetSettingsDynamicRightsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetSettingsDynamicRightsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetSettingsDynamicRightsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetSettingsDynamicRightsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetSettingsDynamicRightsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetSettingsDynamicRightsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsDynamicRightsResult");

		const t = {} as ENetROSEInterface.AsnGetSettingsDynamicRightsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetSettingsDynamicRightsResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		const _seqDynamicRightsList = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.toJSON(s.seqDynamicRightsList, errors, newContext, "seqDynamicRightsList");
		if (_seqDynamicRightsList)
			t.seqDynamicRightsList = _seqDynamicRightsList;

		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsDynamicRightsResult");

		let t: ENetROSEInterface.AsnGetSettingsDynamicRightsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetSettingsDynamicRightsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetSettingsDynamicRightsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
			const _seqdynamicrightslist = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.fromJSON(s.seqDynamicRightsList, errors, newContext, "seqDynamicRightsList", false);
			if (_seqdynamicrightslist)
				t.seqDynamicRightsList = _seqdynamicrightslist;
		}
		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetSettingsDynamicRightsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetSettingsDynamicRightsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsDynamicRightsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		const _seqDynamicRightsList = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.toBER(s.seqDynamicRightsList, errors, newContext, "seqDynamicRightsList");
		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			if (_seqDynamicRightsList)
				t.push(_seqDynamicRightsList);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsDynamicRightsResult");

		let t: ENetROSEInterface.AsnGetSettingsDynamicRightsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetSettingsDynamicRightsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetSettingsDynamicRightsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			const _seqdynamicrightslist = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqDynamicRightsList"), errors, newContext, "seqDynamicRightsList");
			if (_seqdynamicrightslist)
				t.seqDynamicRightsList = _seqdynamicrightslist;
		}

		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSettingsGlobalDynamicRightsChangedArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSettingsGlobalDynamicRightsChangedArgument");

		const t = {} as ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSettingsGlobalDynamicRightsChangedArgument";
		const _globalDynamicRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(s.globalDynamicRights, errors, newContext, "globalDynamicRights");
		if (_globalDynamicRights)
			t.globalDynamicRights = _globalDynamicRights;

		if (errors.validateResult(newContext, "AsnSettingsGlobalDynamicRightsChangedArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSettingsGlobalDynamicRightsChangedArgument");

		let t: ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _globaldynamicrights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(s.globalDynamicRights, errors, newContext, "globalDynamicRights", false);
			if (_globaldynamicrights)
				t.globalDynamicRights = _globaldynamicrights;
		}
		if (errors.validateResult(newContext, "AsnSettingsGlobalDynamicRightsChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSettingsGlobalDynamicRightsChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSettingsGlobalDynamicRightsChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _globalDynamicRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toBER(s.globalDynamicRights, errors, newContext, "globalDynamicRights");
		if (errors.validateResult(newContext, "AsnSettingsGlobalDynamicRightsChangedArgument")) {
			if (_globalDynamicRights)
				t.push(_globalDynamicRights);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSettingsGlobalDynamicRightsChangedArgument");

		let t: ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _globaldynamicrights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "globalDynamicRights"), errors, newContext, "globalDynamicRights");
			if (_globaldynamicrights)
				t.globalDynamicRights = _globaldynamicrights;
		}

		if (errors.validateResult(newContext, "AsnSettingsGlobalDynamicRightsChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetSettingsGlobalDynamicRightsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsGlobalDynamicRightsResult");

		const t = {} as ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetSettingsGlobalDynamicRightsResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		const _globalDynamicRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(s.globalDynamicRights, errors, newContext, "globalDynamicRights");
		if (_globalDynamicRights)
			t.globalDynamicRights = _globalDynamicRights;

		if (errors.validateResult(newContext, "AsnGetSettingsGlobalDynamicRightsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsGlobalDynamicRightsResult");

		let t: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
			const _globaldynamicrights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(s.globalDynamicRights, errors, newContext, "globalDynamicRights", false);
			if (_globaldynamicrights)
				t.globalDynamicRights = _globaldynamicrights;
		}
		if (errors.validateResult(newContext, "AsnGetSettingsGlobalDynamicRightsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetSettingsGlobalDynamicRightsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsGlobalDynamicRightsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		const _globalDynamicRights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toBER(s.globalDynamicRights, errors, newContext, "globalDynamicRights");
		if (errors.validateResult(newContext, "AsnGetSettingsGlobalDynamicRightsResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			if (_globalDynamicRights)
				t.push(_globalDynamicRights);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsGlobalDynamicRightsResult");

		let t: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			const _globaldynamicrights = ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "globalDynamicRights"), errors, newContext, "globalDynamicRights");
			if (_globaldynamicrights)
				t.globalDynamicRights = _globaldynamicrights;
		}

		if (errors.validateResult(newContext, "AsnGetSettingsGlobalDynamicRightsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateMyAppointmentArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUpdateMyAppointmentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUpdateMyAppointmentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyAppointmentArgument");

		const t = {} as ENetROSEInterface.AsnUpdateMyAppointmentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateMyAppointmentArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);
		const _seqActualAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(s.seqActualAppointments, errors, newContext, "seqActualAppointments");
		if (_seqActualAppointments)
			t.seqActualAppointments = _seqActualAppointments;
		const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment");
		if (_asnNextAppointment)
			t.asnNextAppointment = _asnNextAppointment;
		TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnUpdateMyAppointmentArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyAppointmentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyAppointmentArgument");

		let t: ENetROSEInterface.AsnUpdateMyAppointmentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUpdateMyAppointmentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnUpdateMyAppointmentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, context, false);
			const _seqactualappointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(s.seqActualAppointments, errors, newContext, "seqActualAppointments", false);
			if (_seqactualappointments)
				t.seqActualAppointments = _seqactualappointments;
			const _asnnextappointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment", false);
			if (_asnnextappointment)
				t.asnNextAppointment = _asnnextappointment;
			TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnUpdateMyAppointmentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUpdateMyAppointmentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateMyAppointmentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyAppointmentArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		const _seqActualAppointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toBER(s.seqActualAppointments, errors, newContext, "seqActualAppointments");
		const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toBER(s.asnNextAppointment, errors, newContext, "asnNextAppointment");
		TSConverter.validateParam(s, "iPresenceState", "number", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnUpdateMyAppointmentArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			if (_seqActualAppointments)
				t.push(_seqActualAppointments);
			if (_asnNextAppointment)
				t.push(_asnNextAppointment);
			t.push(new asn1ts.Integer({ value: s.iPresenceState, name: "iPresenceState" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyAppointmentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyAppointmentArgument");

		let t: ENetROSEInterface.AsnUpdateMyAppointmentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUpdateMyAppointmentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnUpdateMyAppointmentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
			const _seqactualappointments = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqActualAppointments"), errors, newContext, "seqActualAppointments");
			if (_seqactualappointments)
				t.seqActualAppointments = _seqactualappointments;
			const _asnnextappointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnNextAppointment"), errors, newContext, "asnNextAppointment");
			if (_asnnextappointment)
				t.asnNextAppointment = _asnnextappointment;
			TSConverter.fillASN1Param(s, t, "iPresenceState", "Integer", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnUpdateMyAppointmentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateMyCallInformationArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUpdateMyCallInformationArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUpdateMyCallInformationArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyCallInformationArgument");

		// [Print_JSON_EncoderImportTypeRef]
		const t = ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.toJSON(s, errors, newContext, name);

		if (errors.validateResult(newContext, "AsnUpdateMyCallInformationArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyCallInformationArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyCallInformationArgument");

		let t: ENetROSEInterface.AsnUpdateMyCallInformationArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUpdateMyCallInformationArgument>(data, errors, newContext, optional);
		if (s) {
			// [Print_JSON_DecoderImportTypeRef]
			t = ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.fromJSON(s, errors, newContext, undefined, optional);
		}
		if (errors.validateResult(newContext, "AsnUpdateMyCallInformationArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUpdateMyCallInformationArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateMyCallInformationArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyCallInformationArgument");

		// [Print_BER_EncoderImportTypeRef]
		const v = ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.toBER(s, errors, newContext, name, optional);
		if (errors.validateResult(newContext, "AsnContactCallInformation")) {
			if (v)
				t.push(v);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyCallInformationArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyCallInformationArgument");

		let t: ENetROSEInterface.AsnUpdateMyCallInformationArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUpdateMyCallInformationArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {			// [Print_BER_DecoderImportTypeRef]
			const t = ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.fromBER(s, errors, newContext, name, optional);
		}

		if (errors.validateResult(newContext, "AsnUpdateMyCallInformationArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNotifyLineForwardStateArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnNotifyLineForwardStateArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnNotifyLineForwardStateArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNotifyLineForwardStateArgument");

		const t = {} as ENetROSEInterface.AsnNotifyLineForwardStateArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNotifyLineForwardStateArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iContactFeatureSet", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLinenumber", "string", errors, newContext);
		const _seqLineForwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(s.seqLineForwards, errors, newContext, "seqLineForwards");
		if (_seqLineForwards)
			t.seqLineForwards = _seqLineForwards;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnNotifyLineForwardStateArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNotifyLineForwardStateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNotifyLineForwardStateArgument");

		let t: ENetROSEInterface.AsnNotifyLineForwardStateArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnNotifyLineForwardStateArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnNotifyLineForwardStateArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iContactFeatureSet", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLinenumber", "string", errors, context, false);
			const _seqlineforwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(s.seqLineForwards, errors, newContext, "seqLineForwards", false);
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnNotifyLineForwardStateArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnNotifyLineForwardStateArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNotifyLineForwardStateArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNotifyLineForwardStateArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		TSConverter.validateParam(s, "iContactFeatureSet", "number", errors, newContext);
		TSConverter.validateParam(s, "iPresenceState", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sLinenumber", "string", errors, newContext);
		const _seqLineForwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toBER(s.seqLineForwards, errors, newContext, "seqLineForwards");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnNotifyLineForwardStateArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			t.push(new asn1ts.Integer({ value: s.iContactFeatureSet, name: "iContactFeatureSet" }));
			t.push(new asn1ts.Integer({ value: s.iPresenceState, name: "iPresenceState" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLinenumber, name: "u8sLinenumber" }));
			if (_seqLineForwards)
				t.push(_seqLineForwards);
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNotifyLineForwardStateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNotifyLineForwardStateArgument");

		let t: ENetROSEInterface.AsnNotifyLineForwardStateArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnNotifyLineForwardStateArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnNotifyLineForwardStateArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iContactFeatureSet", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iPresenceState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLinenumber", "Utf8String", errors, newContext);
			const _seqlineforwards = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqLineForwards"), errors, newContext, "seqLineForwards");
			if (_seqlineforwards)
				t.seqLineForwards = _seqlineforwards;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnNotifyLineForwardStateArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateMyContactConfigurationArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUpdateMyContactConfigurationArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUpdateMyContactConfigurationArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyContactConfigurationArgument");

		const t = {} as ENetROSEInterface.AsnUpdateMyContactConfigurationArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateMyContactConfigurationArgument";
		const _contactChanged = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toJSON(s.contactChanged, errors, newContext, "contactChanged");
		if (_contactChanged)
			t.contactChanged = _contactChanged;

		if (errors.validateResult(newContext, "AsnUpdateMyContactConfigurationArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyContactConfigurationArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyContactConfigurationArgument");

		let t: ENetROSEInterface.AsnUpdateMyContactConfigurationArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUpdateMyContactConfigurationArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnUpdateMyContactConfigurationArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _contactchanged = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromJSON(s.contactChanged, errors, newContext, "contactChanged", false);
			if (_contactchanged)
				t.contactChanged = _contactchanged;
		}
		if (errors.validateResult(newContext, "AsnUpdateMyContactConfigurationArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUpdateMyContactConfigurationArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateMyContactConfigurationArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyContactConfigurationArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _contactChanged = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toBER(s.contactChanged, errors, newContext, "contactChanged");
		if (errors.validateResult(newContext, "AsnUpdateMyContactConfigurationArgument")) {
			if (_contactChanged)
				t.push(_contactChanged);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyContactConfigurationArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyContactConfigurationArgument");

		let t: ENetROSEInterface.AsnUpdateMyContactConfigurationArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUpdateMyContactConfigurationArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnUpdateMyContactConfigurationArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _contactchanged = ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contactChanged"), errors, newContext, "contactChanged");
			if (_contactchanged)
				t.contactChanged = _contactchanged;
		}

		if (errors.validateResult(newContext, "AsnUpdateMyContactConfigurationArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateServerBasedClientSettingsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateServerBasedClientSettingsArgument");

		const t = {} as ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateServerBasedClientSettingsArgument";

		if (errors.validateResult(newContext, "AsnUpdateServerBasedClientSettingsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateServerBasedClientSettingsArgument");

		let t: ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnUpdateServerBasedClientSettingsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateServerBasedClientSettingsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateServerBasedClientSettingsArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateServerBasedClientSettingsArgument");

		let t: ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnUpdateServerBasedClientSettingsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMyPresenceRightsChallengeArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnMyPresenceRightsChallengeArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnMyPresenceRightsChallengeArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMyPresenceRightsChallengeArgument");

		const t = {} as ENetROSEInterface.AsnMyPresenceRightsChallengeArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMyPresenceRightsChallengeArgument";
		const _contactsChallenging = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(s.contactsChallenging, errors, newContext, "contactsChallenging");
		if (_contactsChallenging)
			t.contactsChallenging = _contactsChallenging;

		if (errors.validateResult(newContext, "AsnMyPresenceRightsChallengeArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnMyPresenceRightsChallengeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMyPresenceRightsChallengeArgument");

		let t: ENetROSEInterface.AsnMyPresenceRightsChallengeArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnMyPresenceRightsChallengeArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnMyPresenceRightsChallengeArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _contactschallenging = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(s.contactsChallenging, errors, newContext, "contactsChallenging", false);
			if (_contactschallenging)
				t.contactsChallenging = _contactschallenging;
		}
		if (errors.validateResult(newContext, "AsnMyPresenceRightsChallengeArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnMyPresenceRightsChallengeArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMyPresenceRightsChallengeArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMyPresenceRightsChallengeArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _contactsChallenging = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toBER(s.contactsChallenging, errors, newContext, "contactsChallenging");
		if (errors.validateResult(newContext, "AsnMyPresenceRightsChallengeArgument")) {
			if (_contactsChallenging)
				t.push(_contactsChallenging);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnMyPresenceRightsChallengeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMyPresenceRightsChallengeArgument");

		let t: ENetROSEInterface.AsnMyPresenceRightsChallengeArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnMyPresenceRightsChallengeArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnMyPresenceRightsChallengeArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _contactschallenging = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contactsChallenging"), errors, newContext, "contactsChallenging");
			if (_contactschallenging)
				t.contactsChallenging = _contactschallenging;
		}

		if (errors.validateResult(newContext, "AsnMyPresenceRightsChallengeArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestMyPresenceRightsChallengeArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestMyPresenceRightsChallengeArgument");

		const t = {} as ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestMyPresenceRightsChallengeArgument";

		if (errors.validateResult(newContext, "AsnRequestMyPresenceRightsChallengeArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestMyPresenceRightsChallengeArgument");

		let t: ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnRequestMyPresenceRightsChallengeArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestMyPresenceRightsChallengeArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestMyPresenceRightsChallengeArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestMyPresenceRightsChallengeArgument");

		let t: ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnRequestMyPresenceRightsChallengeArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnResetMyPresenceRightsChallengesArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnResetMyPresenceRightsChallengesArgument");

		const t = {} as ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnResetMyPresenceRightsChallengesArgument";

		if (errors.validateResult(newContext, "AsnResetMyPresenceRightsChallengesArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnResetMyPresenceRightsChallengesArgument");

		let t: ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnResetMyPresenceRightsChallengesArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnResetMyPresenceRightsChallengesArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnResetMyPresenceRightsChallengesArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnResetMyPresenceRightsChallengesArgument");

		let t: ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnResetMyPresenceRightsChallengesArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientInfo_Converter {
	public static toJSON(s: ENetROSEInterface.AsnClientInfo, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnClientInfo & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientInfo");

		const t = {} as ENetROSEInterface.AsnClientInfo & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnClientInfo";
		TSConverter.fillJSONParam(s, t, "iConnectionType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bMyConnectionInfo", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "stUTCLoginTime", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sComputername", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAdditionalInfo", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnClientInfo"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnClientInfo | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientInfo");

		let t: ENetROSEInterface.AsnClientInfo | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnClientInfo>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnClientInfo["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iConnectionType", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bMyConnectionInfo", "boolean", errors, context, false);
			if (TSConverter.validateParam(s, "stUTCLoginTime", "string", errors, context, false))
				t.stUTCLoginTime = new Date(s.stUTCLoginTime);
			TSConverter.fillJSONParam(s, t, "u8sComputername", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sAdditionalInfo", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnClientInfo"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnClientInfo | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientInfo";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientInfo");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iConnectionType", "number", errors, newContext);
		TSConverter.validateParam(s, "bMyConnectionInfo", "boolean", errors, newContext);
		TSConverter.validateParam(s, "stUTCLoginTime", "Date", errors, newContext);
		TSConverter.validateParam(s, "u8sComputername", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sAdditionalInfo", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnClientInfo")) {
			t.push(new asn1ts.Integer({ value: s.iConnectionType, name: "iConnectionType" }));
			t.push(new asn1ts.Boolean({ value: s.bMyConnectionInfo, name: "bMyConnectionInfo" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stUTCLoginTime), name: "stUTCLoginTime" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sComputername, name: "u8sComputername" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAdditionalInfo, name: "u8sAdditionalInfo" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnClientInfo | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientInfo");

		let t: ENetROSEInterface.AsnClientInfo | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnClientInfo.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnClientInfo["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iConnectionType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bMyConnectionInfo", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "stUTCLoginTime", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sComputername", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAdditionalInfo", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnClientInfo"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMyUpdateClientContentArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnMyUpdateClientContentArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnMyUpdateClientContentArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMyUpdateClientContentArgument");

		const t = {} as ENetROSEInterface.AsnMyUpdateClientContentArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMyUpdateClientContentArgument";
		TSConverter.fillJSONParam(s, t, "u8sNewRevision", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnMyUpdateClientContentArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnMyUpdateClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMyUpdateClientContentArgument");

		let t: ENetROSEInterface.AsnMyUpdateClientContentArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnMyUpdateClientContentArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnMyUpdateClientContentArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sNewRevision", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnMyUpdateClientContentArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnMyUpdateClientContentArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMyUpdateClientContentArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMyUpdateClientContentArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sNewRevision", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnMyUpdateClientContentArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sNewRevision, name: "u8sNewRevision" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnMyUpdateClientContentArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMyUpdateClientContentArgument");

		let t: ENetROSEInterface.AsnMyUpdateClientContentArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnMyUpdateClientContentArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnMyUpdateClientContentArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sNewRevision", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnMyUpdateClientContentArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactRemoveContactArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactRemoveContactArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactRemoveContactArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactRemoveContactArgument");

		const t = {} as ENetROSEInterface.AsnContactRemoveContactArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactRemoveContactArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnContactRemoveContactArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactRemoveContactArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactRemoveContactArgument");

		let t: ENetROSEInterface.AsnContactRemoveContactArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactRemoveContactArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactRemoveContactArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnContactRemoveContactArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactRemoveContactArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactRemoveContactArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactRemoveContactArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnContactRemoveContactArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactRemoveContactArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactRemoveContactArgument");

		let t: ENetROSEInterface.AsnContactRemoveContactArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactRemoveContactArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactRemoveContactArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnContactRemoveContactArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAbsentStateSetUserArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAbsentStateSetUserArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAbsentStateSetUserArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateSetUserArgument");

		const t = {} as ENetROSEInterface.AsnAbsentStateSetUserArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAbsentStateSetUserArgument";
		const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(s.absentstate, errors, newContext, "absentstate");
		if (_absentstate)
			t.absentstate = _absentstate;

		if (errors.validateResult(newContext, "AsnAbsentStateSetUserArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateSetUserArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateSetUserArgument");

		let t: ENetROSEInterface.AsnAbsentStateSetUserArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAbsentStateSetUserArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnAbsentStateSetUserArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(s.absentstate, errors, newContext, "absentstate", false);
			if (_absentstate)
				t.absentstate = _absentstate;
		}
		if (errors.validateResult(newContext, "AsnAbsentStateSetUserArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAbsentStateSetUserArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAbsentStateSetUserArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateSetUserArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toBER(s.absentstate, errors, newContext, "absentstate");
		if (errors.validateResult(newContext, "AsnAbsentStateSetUserArgument")) {
			if (_absentstate)
				t.push(_absentstate);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateSetUserArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateSetUserArgument");

		let t: ENetROSEInterface.AsnAbsentStateSetUserArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAbsentStateSetUserArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnAbsentStateSetUserArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "absentstate"), errors, newContext, "absentstate");
			if (_absentstate)
				t.absentstate = _absentstate;
		}

		if (errors.validateResult(newContext, "AsnAbsentStateSetUserArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAbsentStateSetUserResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAbsentStateSetUserResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAbsentStateSetUserResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateSetUserResult");

		const t = {} as ENetROSEInterface.AsnAbsentStateSetUserResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAbsentStateSetUserResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnAbsentStateSetUserResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateSetUserResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateSetUserResult");

		let t: ENetROSEInterface.AsnAbsentStateSetUserResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAbsentStateSetUserResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnAbsentStateSetUserResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnAbsentStateSetUserResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAbsentStateSetUserResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAbsentStateSetUserResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateSetUserResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnAbsentStateSetUserResult")) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateSetUserResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateSetUserResult");

		let t: ENetROSEInterface.AsnAbsentStateSetUserResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAbsentStateSetUserResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnAbsentStateSetUserResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnAbsentStateSetUserResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAbsentStateResetArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAbsentStateResetArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAbsentStateResetArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateResetArgument");

		const t = {} as ENetROSEInterface.AsnAbsentStateResetArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAbsentStateResetArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactId", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnAbsentStateResetArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateResetArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateResetArgument");

		let t: ENetROSEInterface.AsnAbsentStateResetArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAbsentStateResetArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnAbsentStateResetArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactId", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnAbsentStateResetArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAbsentStateResetArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAbsentStateResetArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateResetArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactId", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnAbsentStateResetArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactId, name: "u8sContactId" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateResetArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateResetArgument");

		let t: ENetROSEInterface.AsnAbsentStateResetArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAbsentStateResetArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnAbsentStateResetArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactId", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAbsentStateResetArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAbsentStateResetResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAbsentStateResetResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAbsentStateResetResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateResetResult");

		const t = {} as ENetROSEInterface.AsnAbsentStateResetResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAbsentStateResetResult";

		if (errors.validateResult(newContext, "AsnAbsentStateResetResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateResetResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateResetResult");

		let t: ENetROSEInterface.AsnAbsentStateResetResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAbsentStateResetResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnAbsentStateResetResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnAbsentStateResetResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAbsentStateResetResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAbsentStateResetResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateResetResult");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateResetResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateResetResult");

		let t: ENetROSEInterface.AsnAbsentStateResetResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAbsentStateResetResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnAbsentStateResetResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnAbsentStateResetResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAbsentStateGetUserResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAbsentStateGetUserResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAbsentStateGetUserResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateGetUserResult");

		const t = {} as ENetROSEInterface.AsnAbsentStateGetUserResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAbsentStateGetUserResult";
		const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(s.absentstate, errors, newContext, "absentstate");
		if (_absentstate)
			t.absentstate = _absentstate;

		if (errors.validateResult(newContext, "AsnAbsentStateGetUserResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateGetUserResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateGetUserResult");

		let t: ENetROSEInterface.AsnAbsentStateGetUserResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAbsentStateGetUserResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnAbsentStateGetUserResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(s.absentstate, errors, newContext, "absentstate", false);
			if (_absentstate)
				t.absentstate = _absentstate;
		}
		if (errors.validateResult(newContext, "AsnAbsentStateGetUserResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAbsentStateGetUserResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAbsentStateGetUserResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateGetUserResult");

		// [Print_BER_EncoderSeqDefCode]
		const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toBER(s.absentstate, errors, newContext, "absentstate");
		if (errors.validateResult(newContext, "AsnAbsentStateGetUserResult")) {
			if (_absentstate)
				t.push(_absentstate);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateGetUserResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateGetUserResult");

		let t: ENetROSEInterface.AsnAbsentStateGetUserResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAbsentStateGetUserResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnAbsentStateGetUserResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "absentstate"), errors, newContext, "absentstate");
			if (_absentstate)
				t.absentstate = _absentstate;
		}

		if (errors.validateResult(newContext, "AsnAbsentStateGetUserResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetClientIdleStateArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetClientIdleStateArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetClientIdleStateArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientIdleStateArgument");

		const t = {} as ENetROSEInterface.AsnSetClientIdleStateArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetClientIdleStateArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactId", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iIdleState", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnSetClientIdleStateArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetClientIdleStateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientIdleStateArgument");

		let t: ENetROSEInterface.AsnSetClientIdleStateArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetClientIdleStateArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetClientIdleStateArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactId", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iIdleState", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnSetClientIdleStateArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetClientIdleStateArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetClientIdleStateArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientIdleStateArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactId", "string", errors, newContext);
		TSConverter.validateParam(s, "iIdleState", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnSetClientIdleStateArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactId, name: "u8sContactId" }));
			t.push(new asn1ts.Integer({ value: s.iIdleState, name: "iIdleState" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetClientIdleStateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientIdleStateArgument");

		let t: ENetROSEInterface.AsnSetClientIdleStateArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetClientIdleStateArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetClientIdleStateArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactId", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iIdleState", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSetClientIdleStateArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetClientIdleStateResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetClientIdleStateResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetClientIdleStateResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientIdleStateResult");

		const t = {} as ENetROSEInterface.AsnSetClientIdleStateResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetClientIdleStateResult";
		TSConverter.fillJSONParam(s, t, "dummy", "object", errors, newContext);

		if (errors.validateResult(newContext, "AsnSetClientIdleStateResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetClientIdleStateResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientIdleStateResult");

		let t: ENetROSEInterface.AsnSetClientIdleStateResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetClientIdleStateResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetClientIdleStateResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnSetClientIdleStateResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetClientIdleStateResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetClientIdleStateResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetClientIdleStateResult");

		// [Print_BER_EncoderSeqDefCode]
		if (errors.validateResult(newContext, "AsnSetClientIdleStateResult")) {
			t.push(new asn1ts.Null({ name: "dummy" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetClientIdleStateResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetClientIdleStateResult");

		let t: ENetROSEInterface.AsnSetClientIdleStateResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetClientIdleStateResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetClientIdleStateResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "dummy", "Null", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSetClientIdleStateResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateMyAbsentStateArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUpdateMyAbsentStateArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUpdateMyAbsentStateArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyAbsentStateArgument");

		const t = {} as ENetROSEInterface.AsnUpdateMyAbsentStateArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateMyAbsentStateArgument";
		const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(s.absentstate, errors, newContext, "absentstate");
		if (_absentstate)
			t.absentstate = _absentstate;

		if (errors.validateResult(newContext, "AsnUpdateMyAbsentStateArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyAbsentStateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyAbsentStateArgument");

		let t: ENetROSEInterface.AsnUpdateMyAbsentStateArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUpdateMyAbsentStateArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnUpdateMyAbsentStateArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(s.absentstate, errors, newContext, "absentstate", false);
			if (_absentstate)
				t.absentstate = _absentstate;
		}
		if (errors.validateResult(newContext, "AsnUpdateMyAbsentStateArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUpdateMyAbsentStateArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateMyAbsentStateArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyAbsentStateArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toBER(s.absentstate, errors, newContext, "absentstate");
		if (errors.validateResult(newContext, "AsnUpdateMyAbsentStateArgument")) {
			if (_absentstate)
				t.push(_absentstate);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyAbsentStateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyAbsentStateArgument");

		let t: ENetROSEInterface.AsnUpdateMyAbsentStateArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUpdateMyAbsentStateArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnUpdateMyAbsentStateArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _absentstate = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "absentstate"), errors, newContext, "absentstate");
			if (_absentstate)
				t.absentstate = _absentstate;
		}

		if (errors.validateResult(newContext, "AsnUpdateMyAbsentStateArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCustomNoteSetArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnCustomNoteSetArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnCustomNoteSetArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCustomNoteSetArgument");

		const t = {} as ENetROSEInterface.AsnCustomNoteSetArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCustomNoteSetArgument";
		const _customnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toJSON(s.customnote, errors, newContext, "customnote");
		if (_customnote)
			t.customnote = _customnote;

		if (errors.validateResult(newContext, "AsnCustomNoteSetArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnCustomNoteSetArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCustomNoteSetArgument");

		let t: ENetROSEInterface.AsnCustomNoteSetArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnCustomNoteSetArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnCustomNoteSetArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _customnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromJSON(s.customnote, errors, newContext, "customnote", false);
			if (_customnote)
				t.customnote = _customnote;
		}
		if (errors.validateResult(newContext, "AsnCustomNoteSetArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnCustomNoteSetArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCustomNoteSetArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCustomNoteSetArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _customnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toBER(s.customnote, errors, newContext, "customnote");
		if (errors.validateResult(newContext, "AsnCustomNoteSetArgument")) {
			if (_customnote)
				t.push(_customnote);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnCustomNoteSetArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCustomNoteSetArgument");

		let t: ENetROSEInterface.AsnCustomNoteSetArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnCustomNoteSetArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnCustomNoteSetArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _customnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "customnote"), errors, newContext, "customnote");
			if (_customnote)
				t.customnote = _customnote;
		}

		if (errors.validateResult(newContext, "AsnCustomNoteSetArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCustomNoteSetResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnCustomNoteSetResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnCustomNoteSetResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCustomNoteSetResult");

		const t = {} as ENetROSEInterface.AsnCustomNoteSetResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCustomNoteSetResult";
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnCustomNoteSetResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnCustomNoteSetResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCustomNoteSetResult");

		let t: ENetROSEInterface.AsnCustomNoteSetResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnCustomNoteSetResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnCustomNoteSetResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnCustomNoteSetResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnCustomNoteSetResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCustomNoteSetResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCustomNoteSetResult");

		// [Print_BER_EncoderSeqDefCode]
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnCustomNoteSetResult")) {
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnCustomNoteSetResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCustomNoteSetResult");

		let t: ENetROSEInterface.AsnCustomNoteSetResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnCustomNoteSetResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnCustomNoteSetResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnCustomNoteSetResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateMyCustomNoteArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUpdateMyCustomNoteArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUpdateMyCustomNoteArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyCustomNoteArgument");

		const t = {} as ENetROSEInterface.AsnUpdateMyCustomNoteArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUpdateMyCustomNoteArgument";
		const _customnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toJSON(s.customnote, errors, newContext, "customnote");
		if (_customnote)
			t.customnote = _customnote;

		if (errors.validateResult(newContext, "AsnUpdateMyCustomNoteArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyCustomNoteArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyCustomNoteArgument");

		let t: ENetROSEInterface.AsnUpdateMyCustomNoteArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUpdateMyCustomNoteArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnUpdateMyCustomNoteArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _customnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromJSON(s.customnote, errors, newContext, "customnote", false);
			if (_customnote)
				t.customnote = _customnote;
		}
		if (errors.validateResult(newContext, "AsnUpdateMyCustomNoteArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUpdateMyCustomNoteArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateMyCustomNoteArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyCustomNoteArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _customnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toBER(s.customnote, errors, newContext, "customnote");
		if (errors.validateResult(newContext, "AsnUpdateMyCustomNoteArgument")) {
			if (_customnote)
				t.push(_customnote);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyCustomNoteArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyCustomNoteArgument");

		let t: ENetROSEInterface.AsnUpdateMyCustomNoteArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUpdateMyCustomNoteArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnUpdateMyCustomNoteArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _customnote = ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "customnote"), errors, newContext, "customnote");
			if (_customnote)
				t.customnote = _customnote;
		}

		if (errors.validateResult(newContext, "AsnUpdateMyCustomNoteArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetMetaSettingsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetMetaSettingsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetMetaSettingsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetMetaSettingsResult");

		const t = {} as ENetROSEInterface.AsnSetMetaSettingsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetMetaSettingsResult";

		if (errors.validateResult(newContext, "AsnSetMetaSettingsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetMetaSettingsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetMetaSettingsResult");

		let t: ENetROSEInterface.AsnSetMetaSettingsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetMetaSettingsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetMetaSettingsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnSetMetaSettingsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetMetaSettingsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetMetaSettingsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetMetaSettingsResult");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetMetaSettingsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetMetaSettingsResult");

		let t: ENetROSEInterface.AsnSetMetaSettingsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetMetaSettingsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetMetaSettingsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnSetMetaSettingsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestFeatureArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnRequestFeatureArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnRequestFeatureArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestFeatureArgument");

		const t = {} as ENetROSEInterface.AsnRequestFeatureArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestFeatureArgument";
		TSConverter.fillJSONParam(s, t, "iFeature", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnRequestFeatureArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRequestFeatureArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestFeatureArgument");

		let t: ENetROSEInterface.AsnRequestFeatureArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnRequestFeatureArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnRequestFeatureArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iFeature", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnRequestFeatureArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnRequestFeatureArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestFeatureArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestFeatureArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iFeature", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnRequestFeatureArgument")) {
			t.push(new asn1ts.Integer({ value: s.iFeature, name: "iFeature" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRequestFeatureArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestFeatureArgument");

		let t: ENetROSEInterface.AsnRequestFeatureArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnRequestFeatureArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnRequestFeatureArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iFeature", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRequestFeatureArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestFeatureResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnRequestFeatureResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnRequestFeatureResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestFeatureResult");

		const t = {} as ENetROSEInterface.AsnRequestFeatureResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestFeatureResult";
		TSConverter.fillJSONParam(s, t, "bNotForResale", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iFeatureSet", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnRequestFeatureResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRequestFeatureResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestFeatureResult");

		let t: ENetROSEInterface.AsnRequestFeatureResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnRequestFeatureResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnRequestFeatureResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bNotForResale", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iFeatureSet", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnRequestFeatureResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnRequestFeatureResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestFeatureResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestFeatureResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bNotForResale", "boolean", errors, newContext);
		TSConverter.validateParam(s, "iFeatureSet", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnRequestFeatureResult")) {
			t.push(new asn1ts.Boolean({ value: s.bNotForResale, name: "bNotForResale" }));
			t.push(new asn1ts.Integer({ value: s.iFeatureSet, name: "iFeatureSet" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRequestFeatureResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestFeatureResult");

		let t: ENetROSEInterface.AsnRequestFeatureResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnRequestFeatureResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnRequestFeatureResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bNotForResale", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iFeatureSet", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRequestFeatureResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnEMailAttachment_Converter {
	public static toJSON(s: ENetROSEInterface.AsnEMailAttachment, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnEMailAttachment & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailAttachment");

		const t = {} as ENetROSEInterface.AsnEMailAttachment & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnEMailAttachment";
		TSConverter.fillJSONParam(s, t, "binAttachmentData", "Uint8Array", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAttachmentFileName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContentID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iAttachmentEncoding", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnEMailAttachment"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailAttachment | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailAttachment");

		let t: ENetROSEInterface.AsnEMailAttachment | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnEMailAttachment>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnEMailAttachment["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			if (TSConverter.validateParam(s, "binAttachmentData", "string", errors, context, false))
				t.binAttachmentData = TSConverter.decode64(s.binAttachmentData as unknown as string);
			TSConverter.fillJSONParam(s, t, "u8sAttachmentFileName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sContentID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iAttachmentEncoding", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnEMailAttachment"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnEMailAttachment | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnEMailAttachment";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailAttachment");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "binAttachmentData", "Uint8Array", errors, newContext);
		TSConverter.validateParam(s, "u8sAttachmentFileName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sContentID", "string", errors, newContext);
		TSConverter.validateParam(s, "iAttachmentEncoding", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnEMailAttachment")) {
			t.push(new asn1ts.OctetString({ valueHex: s.binAttachmentData, name: "binAttachmentData" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAttachmentFileName, name: "u8sAttachmentFileName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContentID, name: "u8sContentID" }));
			t.push(new asn1ts.Integer({ value: s.iAttachmentEncoding, name: "iAttachmentEncoding" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailAttachment | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailAttachment");

		let t: ENetROSEInterface.AsnEMailAttachment | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnEMailAttachment.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnEMailAttachment["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "binAttachmentData", "OctetString", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAttachmentFileName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContentID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iAttachmentEncoding", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnEMailAttachment"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnEMailParty_Converter {
	public static toJSON(s: ENetROSEInterface.AsnEMailParty, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnEMailParty & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailParty");

		const t = {} as ENetROSEInterface.AsnEMailParty & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnEMailParty";
		TSConverter.fillJSONParam(s, t, "u8sAddress", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnEMailParty"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailParty | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailParty");

		let t: ENetROSEInterface.AsnEMailParty | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnEMailParty>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnEMailParty["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sAddress", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnEMailParty"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnEMailParty | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnEMailParty";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailParty");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sAddress", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sName", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnEMailParty")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sAddress, name: "u8sAddress" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sName, name: "u8sName" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailParty | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailParty");

		let t: ENetROSEInterface.AsnEMailParty | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnEMailParty.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnEMailParty["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sAddress", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sName", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnEMailParty"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnEMailBody_Converter {
	public static toJSON(s: ENetROSEInterface.AsnEMailBody, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnEMailBody & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailBody");

		const t = {} as ENetROSEInterface.AsnEMailBody & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnEMailBody";
		TSConverter.fillJSONParam(s, t, "binBodyData", "Uint8Array", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iBodyEncoding", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnEMailBody"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailBody | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailBody");

		let t: ENetROSEInterface.AsnEMailBody | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnEMailBody>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnEMailBody["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			if (TSConverter.validateParam(s, "binBodyData", "string", errors, context, false))
				t.binBodyData = TSConverter.decode64(s.binBodyData as unknown as string);
			TSConverter.fillJSONParam(s, t, "iBodyEncoding", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnEMailBody"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnEMailBody | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnEMailBody";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailBody");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "binBodyData", "Uint8Array", errors, newContext);
		TSConverter.validateParam(s, "iBodyEncoding", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnEMailBody")) {
			t.push(new asn1ts.OctetString({ valueHex: s.binBodyData, name: "binBodyData" }));
			t.push(new asn1ts.Integer({ value: s.iBodyEncoding, name: "iBodyEncoding" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailBody | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailBody");

		let t: ENetROSEInterface.AsnEMailBody | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnEMailBody.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnEMailBody["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "binBodyData", "OctetString", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iBodyEncoding", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnEMailBody"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDatabaseEntryID_Converter {
	public static toJSON(s: ENetROSEInterface.AsnDatabaseEntryID, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnDatabaseEntryID & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseEntryID");

		const t = {} as ENetROSEInterface.AsnDatabaseEntryID & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDatabaseEntryID";
		TSConverter.fillJSONParam(s, t, "u8sEntryIDDB", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEntryIDStore", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnDatabaseEntryID"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseEntryID | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseEntryID");

		let t: ENetROSEInterface.AsnDatabaseEntryID | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnDatabaseEntryID>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnDatabaseEntryID["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sEntryIDDB", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEntryIDStore", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnDatabaseEntryID"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnDatabaseEntryID | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDatabaseEntryID";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseEntryID");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sEntryIDDB", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEntryIDStore", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEntryID", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnDatabaseEntryID")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryIDDB, name: "u8sEntryIDDB" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryIDStore, name: "u8sEntryIDStore" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryID, name: "u8sEntryID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseEntryID | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseEntryID");

		let t: ENetROSEInterface.AsnDatabaseEntryID | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnDatabaseEntryID.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnDatabaseEntryID["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sEntryIDDB", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEntryIDStore", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEntryID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnDatabaseEntryID"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDatabaseFindOption_Converter {
	public static toJSON(s: ENetROSEInterface.AsnDatabaseFindOption, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnDatabaseFindOption & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseFindOption");

		const t = {} as ENetROSEInterface.AsnDatabaseFindOption & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDatabaseFindOption";
		TSConverter.fillJSONParam(s, t, "u8sFirstName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLastName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCompanyName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCustomerID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDepartment", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sStreetAddress", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAreaCodeOrCity", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEMail", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iMaxNumEntries", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDatabaseName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUsername", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnDatabaseFindOption"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseFindOption | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseFindOption");

		let t: ENetROSEInterface.AsnDatabaseFindOption | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnDatabaseFindOption>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnDatabaseFindOption["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sFirstName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLastName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sCompanyName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sCustomerID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDepartment", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sStreetAddress", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sAreaCodeOrCity", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNo", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEMail", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iMaxNumEntries", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDatabaseName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sUsername", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnDatabaseFindOption"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnDatabaseFindOption | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDatabaseFindOption";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseFindOption");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sFirstName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLastName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCompanyName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCustomerID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDepartment", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sStreetAddress", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sAreaCodeOrCity", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEMail", "string", errors, newContext);
		TSConverter.validateParam(s, "iMaxNumEntries", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sDatabaseName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUsername", "string", errors, newContext);
		TSConverter.validateParam(s, "iFlags", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnDatabaseFindOption")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sFirstName, name: "u8sFirstName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLastName, name: "u8sLastName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCompanyName, name: "u8sCompanyName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCustomerID, name: "u8sCustomerID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDepartment, name: "u8sDepartment" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sStreetAddress, name: "u8sStreetAddress" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAreaCodeOrCity, name: "u8sAreaCodeOrCity" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNo, name: "u8sPhoneNo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEMail, name: "u8sEMail" }));
			t.push(new asn1ts.Integer({ value: s.iMaxNumEntries, name: "iMaxNumEntries" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDatabaseName, name: "u8sDatabaseName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUsername, name: "u8sUsername" }));
			t.push(new asn1ts.Integer({ value: s.iFlags, name: "iFlags" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseFindOption | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseFindOption");

		let t: ENetROSEInterface.AsnDatabaseFindOption | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnDatabaseFindOption.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnDatabaseFindOption["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sFirstName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLastName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCompanyName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCustomerID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDepartment", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sStreetAddress", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAreaCodeOrCity", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEMail", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iMaxNumEntries", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDatabaseName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUsername", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iFlags", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnDatabaseFindOption"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDBPhoneBook_Converter {
	public static toJSON(s: ENetROSEInterface.AsnDBPhoneBook, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnDBPhoneBook & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDBPhoneBook");

		const t = {} as ENetROSEInterface.AsnDBPhoneBook & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDBPhoneBook";
		TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sInfo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iCapabilities", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext);
		const _errors = ENetUC_Common_Converter.AsnRequestErrorList_Converter.toJSON(s.errors, errors, newContext, "errors");
		if (_errors)
			t.errors = _errors;

		if (errors.validateResult(newContext, "AsnDBPhoneBook"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDBPhoneBook | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDBPhoneBook");

		let t: ENetROSEInterface.AsnDBPhoneBook | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnDBPhoneBook>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnDBPhoneBook["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sInfo", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iCapabilities", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iType", "number", errors, context, false);
			const _errors = ENetUC_Common_Converter.AsnRequestErrorList_Converter.fromJSON(s.errors, errors, newContext, "errors", false);
			if (_errors)
				t.errors = _errors;
		}
		if (errors.validateResult(newContext, "AsnDBPhoneBook"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnDBPhoneBook | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDBPhoneBook";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDBPhoneBook");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sInfo", "string", errors, newContext);
		TSConverter.validateParam(s, "iCapabilities", "number", errors, newContext);
		TSConverter.validateParam(s, "iType", "number", errors, newContext);
		const _errors = ENetUC_Common_Converter.AsnRequestErrorList_Converter.toBER(s.errors, errors, newContext, "errors");
		if (errors.validateResult(newContext, "AsnDBPhoneBook")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sName, name: "u8sName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sInfo, name: "u8sInfo" }));
			t.push(new asn1ts.Integer({ value: s.iCapabilities, name: "iCapabilities" }));
			t.push(new asn1ts.Integer({ value: s.iType, name: "iType" }));
			if (_errors)
				t.push(_errors);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDBPhoneBook | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDBPhoneBook");

		let t: ENetROSEInterface.AsnDBPhoneBook | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnDBPhoneBook.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnDBPhoneBook["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sInfo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iCapabilities", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iType", "Integer", errors, newContext);
			const _errors = ENetUC_Common_Converter.AsnRequestErrorList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "errors"), errors, newContext, "errors");
			if (_errors)
				t.errors = _errors;
		}

		if (errors.validateResult(newContext, "AsnDBPhoneBook"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNetDatabaseFindOptions_Converter {
	public static toJSON(s: ENetROSEInterface.AsnNetDatabaseFindOptions, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnNetDatabaseFindOptions & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseFindOptions");

		const t = {} as ENetROSEInterface.AsnNetDatabaseFindOptions & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNetDatabaseFindOptions";
		TSConverter.fillJSONParam(s, t, "u8sFirstName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLastName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCompanyName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCustomerID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDepartment", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sStreetAddress", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAreaCodeOrCity", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNo", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEMail", "string", errors, newContext);
		const _u8spairlistCustomFieldsValues = ENetUC_Common_Converter.AsnStringPairList_Converter.toJSON(s.u8spairlistCustomFieldsValues, errors, newContext, "u8spairlistCustomFieldsValues");
		if (_u8spairlistCustomFieldsValues)
			t.u8spairlistCustomFieldsValues = _u8spairlistCustomFieldsValues;
		TSConverter.fillJSONParam(s, t, "iMaxNumEntries", "number", errors, newContext);
		const _u8slistDatabaseNames = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8slistDatabaseNames, errors, newContext, "u8slistDatabaseNames");
		if (_u8slistDatabaseNames)
			t.u8slistDatabaseNames = _u8slistDatabaseNames;
		TSConverter.fillJSONParam(s, t, "u8sUsername", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDisplayNameFormat", "number", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseFindOptions"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNetDatabaseFindOptions | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseFindOptions");

		let t: ENetROSEInterface.AsnNetDatabaseFindOptions | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnNetDatabaseFindOptions>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnNetDatabaseFindOptions["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sFirstName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLastName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sCompanyName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sCustomerID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDepartment", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sStreetAddress", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sAreaCodeOrCity", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNo", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEMail", "string", errors, context, false);
			const _u8spairlistcustomfieldsvalues = ENetUC_Common_Converter.AsnStringPairList_Converter.fromJSON(s.u8spairlistCustomFieldsValues, errors, newContext, "u8spairlistCustomFieldsValues", false);
			if (_u8spairlistcustomfieldsvalues)
				t.u8spairlistCustomFieldsValues = _u8spairlistcustomfieldsvalues;
			TSConverter.fillJSONParam(s, t, "iMaxNumEntries", "number", errors, context, false);
			const _u8slistdatabasenames = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8slistDatabaseNames, errors, newContext, "u8slistDatabaseNames", false);
			if (_u8slistdatabasenames)
				t.u8slistDatabaseNames = _u8slistdatabasenames;
			TSConverter.fillJSONParam(s, t, "u8sUsername", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iDisplayNameFormat", "number", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnNetDatabaseFindOptions"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnNetDatabaseFindOptions | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNetDatabaseFindOptions";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNetDatabaseFindOptions");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sFirstName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLastName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCompanyName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCustomerID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDepartment", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sStreetAddress", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sAreaCodeOrCity", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNo", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEMail", "string", errors, newContext);
		const _u8spairlistCustomFieldsValues = ENetUC_Common_Converter.AsnStringPairList_Converter.toBER(s.u8spairlistCustomFieldsValues, errors, newContext, "u8spairlistCustomFieldsValues");
		TSConverter.validateParam(s, "iMaxNumEntries", "number", errors, newContext);
		const _u8slistDatabaseNames = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8slistDatabaseNames, errors, newContext, "u8slistDatabaseNames");
		TSConverter.validateParam(s, "u8sUsername", "string", errors, newContext);
		TSConverter.validateParam(s, "iFlags", "number", errors, newContext);
		TSConverter.validateParam(s, "iDisplayNameFormat", "number", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnNetDatabaseFindOptions")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sFirstName, name: "u8sFirstName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLastName, name: "u8sLastName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCompanyName, name: "u8sCompanyName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCustomerID, name: "u8sCustomerID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDepartment, name: "u8sDepartment" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sStreetAddress, name: "u8sStreetAddress" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAreaCodeOrCity, name: "u8sAreaCodeOrCity" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNo, name: "u8sPhoneNo" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEMail, name: "u8sEMail" }));
			if (_u8spairlistCustomFieldsValues)
				t.push(_u8spairlistCustomFieldsValues);
			t.push(new asn1ts.Integer({ value: s.iMaxNumEntries, name: "iMaxNumEntries" }));
			if (_u8slistDatabaseNames)
				t.push(_u8slistDatabaseNames);
			t.push(new asn1ts.Utf8String({ value: s.u8sUsername, name: "u8sUsername" }));
			t.push(new asn1ts.Integer({ value: s.iFlags, name: "iFlags" }));
			t.push(new asn1ts.Integer({ value: s.iDisplayNameFormat, name: "iDisplayNameFormat" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNetDatabaseFindOptions | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNetDatabaseFindOptions");

		let t: ENetROSEInterface.AsnNetDatabaseFindOptions | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnNetDatabaseFindOptions.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnNetDatabaseFindOptions["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sFirstName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLastName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCompanyName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCustomerID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDepartment", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sStreetAddress", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAreaCodeOrCity", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNo", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEMail", "Utf8String", errors, newContext);
			const _u8spairlistcustomfieldsvalues = ENetUC_Common_Converter.AsnStringPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8spairlistCustomFieldsValues"), errors, newContext, "u8spairlistCustomFieldsValues");
			if (_u8spairlistcustomfieldsvalues)
				t.u8spairlistCustomFieldsValues = _u8spairlistcustomfieldsvalues;
			TSConverter.fillASN1Param(s, t, "iMaxNumEntries", "Integer", errors, newContext);
			const _u8slistdatabasenames = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8slistDatabaseNames"), errors, newContext, "u8slistDatabaseNames");
			if (_u8slistdatabasenames)
				t.u8slistDatabaseNames = _u8slistdatabasenames;
			TSConverter.fillASN1Param(s, t, "u8sUsername", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iFlags", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDisplayNameFormat", "Integer", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnNetDatabaseFindOptions"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetContactByEntryIDArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetContactByEntryIDArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetContactByEntryIDArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetContactByEntryIDArgument");

		const t = {} as ENetROSEInterface.AsnGetContactByEntryIDArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetContactByEntryIDArgument";
		TSConverter.fillJSONParam(s, t, "u8sEntryIDDB", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEntryIDStore", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetContactByEntryIDArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetContactByEntryIDArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetContactByEntryIDArgument");

		let t: ENetROSEInterface.AsnGetContactByEntryIDArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetContactByEntryIDArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetContactByEntryIDArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sEntryIDDB", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEntryIDStore", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetContactByEntryIDArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetContactByEntryIDArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetContactByEntryIDArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetContactByEntryIDArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sEntryIDDB", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEntryIDStore", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEntryID", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetContactByEntryIDArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryIDDB, name: "u8sEntryIDDB" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryIDStore, name: "u8sEntryIDStore" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryID, name: "u8sEntryID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetContactByEntryIDArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetContactByEntryIDArgument");

		let t: ENetROSEInterface.AsnGetContactByEntryIDArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetContactByEntryIDArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetContactByEntryIDArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sEntryIDDB", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEntryIDStore", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEntryID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetContactByEntryIDArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetContactByEntryIDResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetContactByEntryIDResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetContactByEntryIDResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetContactByEntryIDResult");

		const t = {} as ENetROSEInterface.AsnGetContactByEntryIDResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetContactByEntryIDResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);
		const _contact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.contact, errors, newContext, "contact");
		if (_contact)
			t.contact = _contact;

		if (errors.validateResult(newContext, "AsnGetContactByEntryIDResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetContactByEntryIDResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetContactByEntryIDResult");

		let t: ENetROSEInterface.AsnGetContactByEntryIDResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetContactByEntryIDResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetContactByEntryIDResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, context, false);
			const _contact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.contact, errors, newContext, "contact", false);
			if (_contact)
				t.contact = _contact;
		}
		if (errors.validateResult(newContext, "AsnGetContactByEntryIDResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetContactByEntryIDResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetContactByEntryIDResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetContactByEntryIDResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		const _contact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.contact, errors, newContext, "contact");
		if (errors.validateResult(newContext, "AsnGetContactByEntryIDResult")) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			if (_contact)
				t.push(_contact);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetContactByEntryIDResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetContactByEntryIDResult");

		let t: ENetROSEInterface.AsnGetContactByEntryIDResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetContactByEntryIDResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetContactByEntryIDResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
			const _contact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contact"), errors, newContext, "contact");
			if (_contact)
				t.contact = _contact;
		}

		if (errors.validateResult(newContext, "AsnGetContactByEntryIDResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetPreferredContactRefArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetPreferredContactRefArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetPreferredContactRefArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetPreferredContactRefArgument");

		const t = {} as ENetROSEInterface.AsnGetPreferredContactRefArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetPreferredContactRefArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetPreferredContactRefArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetPreferredContactRefArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetPreferredContactRefArgument");

		let t: ENetROSEInterface.AsnGetPreferredContactRefArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetPreferredContactRefArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetPreferredContactRefArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetPreferredContactRefArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetPreferredContactRefArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetPreferredContactRefArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetPreferredContactRefArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetPreferredContactRefArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetPreferredContactRefArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetPreferredContactRefArgument");

		let t: ENetROSEInterface.AsnGetPreferredContactRefArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetPreferredContactRefArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetPreferredContactRefArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetPreferredContactRefArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetPreferredContactRefResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetPreferredContactRefResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetPreferredContactRefResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetPreferredContactRefResult");

		const t = {} as ENetROSEInterface.AsnGetPreferredContactRefResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetPreferredContactRefResult";
		TSConverter.fillJSONParam(s, t, "bContactRefFound", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sStoreID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDBID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDisplayName", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetPreferredContactRefResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetPreferredContactRefResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetPreferredContactRefResult");

		let t: ENetROSEInterface.AsnGetPreferredContactRefResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetPreferredContactRefResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetPreferredContactRefResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bContactRefFound", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sStoreID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDBID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDisplayName", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetPreferredContactRefResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetPreferredContactRefResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetPreferredContactRefResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetPreferredContactRefResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bContactRefFound", "boolean", errors, newContext);
		TSConverter.validateParam(s, "u8sEntryID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sStoreID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDBID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDisplayName", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetPreferredContactRefResult")) {
			t.push(new asn1ts.Boolean({ value: s.bContactRefFound, name: "bContactRefFound" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryID, name: "u8sEntryID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sStoreID, name: "u8sStoreID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDBID, name: "u8sDBID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDisplayName, name: "u8sDisplayName" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetPreferredContactRefResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetPreferredContactRefResult");

		let t: ENetROSEInterface.AsnGetPreferredContactRefResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetPreferredContactRefResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetPreferredContactRefResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bContactRefFound", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEntryID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sStoreID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDBID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDisplayName", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetPreferredContactRefResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetPreferredContactRefArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetPreferredContactRefArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetPreferredContactRefArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetPreferredContactRefArgument");

		const t = {} as ENetROSEInterface.AsnSetPreferredContactRefArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetPreferredContactRefArgument";
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sStoreID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDBID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDisplayName", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnSetPreferredContactRefArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetPreferredContactRefArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetPreferredContactRefArgument");

		let t: ENetROSEInterface.AsnSetPreferredContactRefArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetPreferredContactRefArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetPreferredContactRefArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sEntryID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sStoreID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDBID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDisplayName", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnSetPreferredContactRefArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetPreferredContactRefArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetPreferredContactRefArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetPreferredContactRefArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sEntryID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sStoreID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDBID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDisplayName", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnSetPreferredContactRefArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sEntryID, name: "u8sEntryID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sStoreID, name: "u8sStoreID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDBID, name: "u8sDBID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDisplayName, name: "u8sDisplayName" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetPreferredContactRefArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetPreferredContactRefArgument");

		let t: ENetROSEInterface.AsnSetPreferredContactRefArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetPreferredContactRefArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetPreferredContactRefArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sEntryID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sStoreID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDBID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDisplayName", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSetPreferredContactRefArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDatabaseConnectorLoginArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnDatabaseConnectorLoginArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnDatabaseConnectorLoginArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseConnectorLoginArgument");

		const t = {} as ENetROSEInterface.AsnDatabaseConnectorLoginArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDatabaseConnectorLoginArgument";
		TSConverter.fillJSONParam(s, t, "u8sUserName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPassword", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDatabaseCapabilities", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDatabaseType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDatabaseName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDatabaseInfo", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnDatabaseConnectorLoginArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseConnectorLoginArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseConnectorLoginArgument");

		let t: ENetROSEInterface.AsnDatabaseConnectorLoginArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnDatabaseConnectorLoginArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnDatabaseConnectorLoginArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sUserName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sPassword", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iDatabaseCapabilities", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iDatabaseType", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDatabaseName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDatabaseInfo", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnDatabaseConnectorLoginArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnDatabaseConnectorLoginArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDatabaseConnectorLoginArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseConnectorLoginArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sUserName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPassword", "string", errors, newContext);
		TSConverter.validateParam(s, "iVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "iDatabaseCapabilities", "number", errors, newContext);
		TSConverter.validateParam(s, "iDatabaseType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sDatabaseName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDatabaseInfo", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnDatabaseConnectorLoginArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sUserName, name: "u8sUserName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPassword, name: "u8sPassword" }));
			t.push(new asn1ts.Integer({ value: s.iVersion, name: "iVersion" }));
			t.push(new asn1ts.Integer({ value: s.iDatabaseCapabilities, name: "iDatabaseCapabilities" }));
			t.push(new asn1ts.Integer({ value: s.iDatabaseType, name: "iDatabaseType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDatabaseName, name: "u8sDatabaseName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDatabaseInfo, name: "u8sDatabaseInfo" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseConnectorLoginArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseConnectorLoginArgument");

		let t: ENetROSEInterface.AsnDatabaseConnectorLoginArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnDatabaseConnectorLoginArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnDatabaseConnectorLoginArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sUserName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPassword", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDatabaseCapabilities", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDatabaseType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDatabaseName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDatabaseInfo", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnDatabaseConnectorLoginArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDatabaseConnectorLoginResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnDatabaseConnectorLoginResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnDatabaseConnectorLoginResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseConnectorLoginResult");

		const t = {} as ENetROSEInterface.AsnDatabaseConnectorLoginResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDatabaseConnectorLoginResult";
		TSConverter.fillJSONParam(s, t, "iConnectorCrossRefID", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnDatabaseConnectorLoginResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseConnectorLoginResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseConnectorLoginResult");

		let t: ENetROSEInterface.AsnDatabaseConnectorLoginResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnDatabaseConnectorLoginResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnDatabaseConnectorLoginResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iConnectorCrossRefID", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnDatabaseConnectorLoginResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnDatabaseConnectorLoginResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDatabaseConnectorLoginResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseConnectorLoginResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iConnectorCrossRefID", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnDatabaseConnectorLoginResult")) {
			t.push(new asn1ts.Integer({ value: s.iConnectorCrossRefID, name: "iConnectorCrossRefID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseConnectorLoginResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseConnectorLoginResult");

		let t: ENetROSEInterface.AsnDatabaseConnectorLoginResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnDatabaseConnectorLoginResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnDatabaseConnectorLoginResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iConnectorCrossRefID", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnDatabaseConnectorLoginResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDatabaseConnectorLogoffArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnDatabaseConnectorLogoffArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnDatabaseConnectorLogoffArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseConnectorLogoffArgument");

		const t = {} as ENetROSEInterface.AsnDatabaseConnectorLogoffArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDatabaseConnectorLogoffArgument";
		TSConverter.fillJSONParam(s, t, "iConnectorCrossRefID", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnDatabaseConnectorLogoffArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseConnectorLogoffArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseConnectorLogoffArgument");

		let t: ENetROSEInterface.AsnDatabaseConnectorLogoffArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnDatabaseConnectorLogoffArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnDatabaseConnectorLogoffArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iConnectorCrossRefID", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnDatabaseConnectorLogoffArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnDatabaseConnectorLogoffArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDatabaseConnectorLogoffArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseConnectorLogoffArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iConnectorCrossRefID", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnDatabaseConnectorLogoffArgument")) {
			t.push(new asn1ts.Integer({ value: s.iConnectorCrossRefID, name: "iConnectorCrossRefID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseConnectorLogoffArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseConnectorLogoffArgument");

		let t: ENetROSEInterface.AsnDatabaseConnectorLogoffArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnDatabaseConnectorLogoffArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnDatabaseConnectorLogoffArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iConnectorCrossRefID", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnDatabaseConnectorLogoffArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDatabaseConnectorLogoffResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnDatabaseConnectorLogoffResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnDatabaseConnectorLogoffResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseConnectorLogoffResult");

		const t = {} as ENetROSEInterface.AsnDatabaseConnectorLogoffResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDatabaseConnectorLogoffResult";

		if (errors.validateResult(newContext, "AsnDatabaseConnectorLogoffResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseConnectorLogoffResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseConnectorLogoffResult");

		let t: ENetROSEInterface.AsnDatabaseConnectorLogoffResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnDatabaseConnectorLogoffResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnDatabaseConnectorLogoffResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnDatabaseConnectorLogoffResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnDatabaseConnectorLogoffResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDatabaseConnectorLogoffResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDatabaseConnectorLogoffResult");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDatabaseConnectorLogoffResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDatabaseConnectorLogoffResult");

		let t: ENetROSEInterface.AsnDatabaseConnectorLogoffResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnDatabaseConnectorLogoffResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnDatabaseConnectorLogoffResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnDatabaseConnectorLogoffResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetPhoneBooksArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetPhoneBooksArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetPhoneBooksArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetPhoneBooksArgument");

		const t = {} as ENetROSEInterface.AsnGetPhoneBooksArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetPhoneBooksArgument";

		if (errors.validateResult(newContext, "AsnGetPhoneBooksArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetPhoneBooksArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetPhoneBooksArgument");

		let t: ENetROSEInterface.AsnGetPhoneBooksArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetPhoneBooksArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetPhoneBooksArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnGetPhoneBooksArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetPhoneBooksArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetPhoneBooksArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetPhoneBooksArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetPhoneBooksArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetPhoneBooksArgument");

		let t: ENetROSEInterface.AsnGetPhoneBooksArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetPhoneBooksArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetPhoneBooksArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetPhoneBooksArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetUserPropertyBagArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetUserPropertyBagArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetUserPropertyBagArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetUserPropertyBagArgument");

		const t = {} as ENetROSEInterface.AsnSetUserPropertyBagArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetUserPropertyBagArgument";
		const _asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toJSON(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag");
		if (_asnUserPropertyBag)
			t.asnUserPropertyBag = _asnUserPropertyBag;

		if (errors.validateResult(newContext, "AsnSetUserPropertyBagArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetUserPropertyBagArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetUserPropertyBagArgument");

		let t: ENetROSEInterface.AsnSetUserPropertyBagArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetUserPropertyBagArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetUserPropertyBagArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _asnuserpropertybag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromJSON(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag", false);
			if (_asnuserpropertybag)
				t.asnUserPropertyBag = _asnuserpropertybag;
		}
		if (errors.validateResult(newContext, "AsnSetUserPropertyBagArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetUserPropertyBagArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetUserPropertyBagArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetUserPropertyBagArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toBER(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag");
		if (errors.validateResult(newContext, "AsnSetUserPropertyBagArgument")) {
			if (_asnUserPropertyBag)
				t.push(_asnUserPropertyBag);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetUserPropertyBagArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetUserPropertyBagArgument");

		let t: ENetROSEInterface.AsnSetUserPropertyBagArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetUserPropertyBagArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetUserPropertyBagArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _asnuserpropertybag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnUserPropertyBag"), errors, newContext, "asnUserPropertyBag");
			if (_asnuserpropertybag)
				t.asnUserPropertyBag = _asnuserpropertybag;
		}

		if (errors.validateResult(newContext, "AsnSetUserPropertyBagArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetUserPropertyBagResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetUserPropertyBagResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetUserPropertyBagResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetUserPropertyBagResult");

		const t = {} as ENetROSEInterface.AsnSetUserPropertyBagResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetUserPropertyBagResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnSetUserPropertyBagResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetUserPropertyBagResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetUserPropertyBagResult");

		let t: ENetROSEInterface.AsnSetUserPropertyBagResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetUserPropertyBagResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetUserPropertyBagResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnSetUserPropertyBagResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetUserPropertyBagResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetUserPropertyBagResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetUserPropertyBagResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnSetUserPropertyBagResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetUserPropertyBagResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetUserPropertyBagResult");

		let t: ENetROSEInterface.AsnSetUserPropertyBagResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetUserPropertyBagResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetUserPropertyBagResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSetUserPropertyBagResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnServerBasedClientSettingsEx_Converter {
	public static toJSON(s: ENetROSEInterface.AsnServerBasedClientSettingsEx, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnServerBasedClientSettingsEx & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnServerBasedClientSettingsEx");

		const t = {} as ENetROSEInterface.AsnServerBasedClientSettingsEx & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnServerBasedClientSettingsEx";
		TSConverter.fillJSONParam(s, t, "u8sXMLSettingsData", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnServerBasedClientSettingsEx"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnServerBasedClientSettingsEx | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnServerBasedClientSettingsEx");

		let t: ENetROSEInterface.AsnServerBasedClientSettingsEx | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnServerBasedClientSettingsEx>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnServerBasedClientSettingsEx["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sXMLSettingsData", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnServerBasedClientSettingsEx"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnServerBasedClientSettingsEx | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnServerBasedClientSettingsEx";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnServerBasedClientSettingsEx");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sXMLSettingsData", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnServerBasedClientSettingsEx")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sXMLSettingsData, name: "u8sXMLSettingsData" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnServerBasedClientSettingsEx | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnServerBasedClientSettingsEx");

		let t: ENetROSEInterface.AsnServerBasedClientSettingsEx | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnServerBasedClientSettingsEx.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnServerBasedClientSettingsEx["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sXMLSettingsData", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnServerBasedClientSettingsEx"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactStatusResultEntry_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactStatusResultEntry, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactStatusResultEntry & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusResultEntry");

		const t = {} as ENetROSEInterface.AsnContactStatusResultEntry & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactStatusResultEntry";
		TSConverter.fillJSONParam(s, t, "iClientSearchID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iClientRowID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnContactStatusResultEntry"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusResultEntry | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusResultEntry");

		let t: ENetROSEInterface.AsnContactStatusResultEntry | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactStatusResultEntry>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactStatusResultEntry["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iClientSearchID", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iClientRowID", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sContactID", "string", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnContactStatusResultEntry"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactStatusResultEntry | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactStatusResultEntry";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusResultEntry");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iClientSearchID", "number", errors, newContext);
		TSConverter.validateParam(s, "iClientRowID", "number", errors, newContext);
		TSConverter.validateParam(s, "iPresenceState", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sContactID", "string", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnContactStatusResultEntry")) {
			t.push(new asn1ts.Integer({ value: s.iClientSearchID, name: "iClientSearchID" }));
			t.push(new asn1ts.Integer({ value: s.iClientRowID, name: "iClientRowID" }));
			t.push(new asn1ts.Integer({ value: s.iPresenceState, name: "iPresenceState" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sContactID, name: "u8sContactID" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusResultEntry | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusResultEntry");

		let t: ENetROSEInterface.AsnContactStatusResultEntry | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactStatusResultEntry.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactStatusResultEntry["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iClientSearchID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iClientRowID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iPresenceState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sContactID", "Utf8String", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnContactStatusResultEntry"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactSearchCloseArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactSearchCloseArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactSearchCloseArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactSearchCloseArgument");

		const t = {} as ENetROSEInterface.AsnContactSearchCloseArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactSearchCloseArgument";
		TSConverter.fillJSONParam(s, t, "iClientSearchID", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnContactSearchCloseArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactSearchCloseArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactSearchCloseArgument");

		let t: ENetROSEInterface.AsnContactSearchCloseArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactSearchCloseArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactSearchCloseArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iClientSearchID", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnContactSearchCloseArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactSearchCloseArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactSearchCloseArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactSearchCloseArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iClientSearchID", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnContactSearchCloseArgument")) {
			t.push(new asn1ts.Integer({ value: s.iClientSearchID, name: "iClientSearchID" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactSearchCloseArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactSearchCloseArgument");

		let t: ENetROSEInterface.AsnContactSearchCloseArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactSearchCloseArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactSearchCloseArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iClientSearchID", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnContactSearchCloseArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactSearchCloseResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactSearchCloseResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactSearchCloseResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactSearchCloseResult");

		const t = {} as ENetROSEInterface.AsnContactSearchCloseResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactSearchCloseResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnContactSearchCloseResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactSearchCloseResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactSearchCloseResult");

		let t: ENetROSEInterface.AsnContactSearchCloseResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactSearchCloseResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactSearchCloseResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnContactSearchCloseResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactSearchCloseResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactSearchCloseResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactSearchCloseResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnContactSearchCloseResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactSearchCloseResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactSearchCloseResult");

		let t: ENetROSEInterface.AsnContactSearchCloseResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactSearchCloseResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactSearchCloseResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnContactSearchCloseResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAppsRegisterPushArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAppsRegisterPushArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAppsRegisterPushArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAppsRegisterPushArgument");

		const t = {} as ENetROSEInterface.AsnAppsRegisterPushArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAppsRegisterPushArgument";
		TSConverter.fillJSONParam(s, t, "iRegisterType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDeviceID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPushID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPushChannelID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iEncryption", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPushIDVoIP", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sPushChannelIDVoIP", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnAppsRegisterPushArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAppsRegisterPushArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAppsRegisterPushArgument");

		let t: ENetROSEInterface.AsnAppsRegisterPushArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAppsRegisterPushArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnAppsRegisterPushArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iRegisterType", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDeviceID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sPushID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sPushChannelID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iEncryption", "number", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPushIDVoIP", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sPushChannelIDVoIP", "string", errors, context, true);
		}
		if (errors.validateResult(newContext, "AsnAppsRegisterPushArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAppsRegisterPushArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAppsRegisterPushArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAppsRegisterPushArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iRegisterType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sDeviceID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPushID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPushChannelID", "string", errors, newContext);
		TSConverter.validateParam(s, "iEncryption", "number", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPushIDVoIP", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sPushChannelIDVoIP", "string", errors, newContext, true);
		if (errors.validateResult(newContext, "AsnAppsRegisterPushArgument")) {
			t.push(new asn1ts.Integer({ value: s.iRegisterType, name: "iRegisterType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDeviceID, name: "u8sDeviceID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPushID, name: "u8sPushID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPushChannelID, name: "u8sPushChannelID" }));
			if (s.iEncryption !== undefined)
				t.push(new asn1ts.Integer({ value: s.iEncryption, name: "iEncryption", idBlock: { optionalID: 0 } }));
			if (s.u8sPushIDVoIP !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPushIDVoIP, name: "u8sPushIDVoIP", idBlock: { optionalID: 1 } }));
			if (s.u8sPushChannelIDVoIP !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sPushChannelIDVoIP, name: "u8sPushChannelIDVoIP", idBlock: { optionalID: 2 } }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAppsRegisterPushArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAppsRegisterPushArgument");

		let t: ENetROSEInterface.AsnAppsRegisterPushArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAppsRegisterPushArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnAppsRegisterPushArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iRegisterType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDeviceID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPushID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPushChannelID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iEncryption", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPushIDVoIP", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sPushChannelIDVoIP", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAppsRegisterPushArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAppsRegisterPushResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAppsRegisterPushResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAppsRegisterPushResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAppsRegisterPushResult");

		const t = {} as ENetROSEInterface.AsnAppsRegisterPushResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAppsRegisterPushResult";
		TSConverter.fillJSONParam(s, t, "key", "Uint8Array", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnAppsRegisterPushResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAppsRegisterPushResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAppsRegisterPushResult");

		let t: ENetROSEInterface.AsnAppsRegisterPushResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAppsRegisterPushResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnAppsRegisterPushResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			if (TSConverter.validateParam(s, "key", "string", errors, context, true) && s.key)
				t.key = TSConverter.decode64(s.key as unknown as string);
		}
		if (errors.validateResult(newContext, "AsnAppsRegisterPushResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAppsRegisterPushResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAppsRegisterPushResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAppsRegisterPushResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "key", "Uint8Array", errors, newContext, true);
		if (errors.validateResult(newContext, "AsnAppsRegisterPushResult")) {
			if (s.key !== undefined)
				t.push(new asn1ts.OctetString({ valueHex: s.key, name: "key" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAppsRegisterPushResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAppsRegisterPushResult");

		let t: ENetROSEInterface.AsnAppsRegisterPushResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAppsRegisterPushResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnAppsRegisterPushResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "key", "OctetString", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnAppsRegisterPushResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPublicUserContactData_Converter {
	public static toJSON(s: ENetROSEInterface.AsnPublicUserContactData, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnPublicUserContactData & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPublicUserContactData");

		const t = {} as ENetROSEInterface.AsnPublicUserContactData & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnPublicUserContactData";
		const _contactData = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.contactData, errors, newContext, "contactData");
		if (_contactData)
			t.contactData = _contactData;

		if (errors.validateResult(newContext, "AsnPublicUserContactData"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnPublicUserContactData | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPublicUserContactData");

		let t: ENetROSEInterface.AsnPublicUserContactData | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnPublicUserContactData>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnPublicUserContactData["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _contactdata = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.contactData, errors, newContext, "contactData", false);
			if (_contactdata)
				t.contactData = _contactdata;
		}
		if (errors.validateResult(newContext, "AsnPublicUserContactData"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnPublicUserContactData | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPublicUserContactData";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPublicUserContactData");

		// [Print_BER_EncoderSeqDefCode]
		const _contactData = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.contactData, errors, newContext, "contactData");
		if (errors.validateResult(newContext, "AsnPublicUserContactData")) {
			if (_contactData)
				t.push(_contactData);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnPublicUserContactData | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPublicUserContactData");

		let t: ENetROSEInterface.AsnPublicUserContactData | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnPublicUserContactData.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnPublicUserContactData["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _contactdata = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contactData"), errors, newContext, "contactData");
			if (_contactdata)
				t.contactData = _contactdata;
		}

		if (errors.validateResult(newContext, "AsnPublicUserContactData"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPublicUserSetContactDataResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnPublicUserSetContactDataResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnPublicUserSetContactDataResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPublicUserSetContactDataResult");

		const t = {} as ENetROSEInterface.AsnPublicUserSetContactDataResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnPublicUserSetContactDataResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnPublicUserSetContactDataResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnPublicUserSetContactDataResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPublicUserSetContactDataResult");

		let t: ENetROSEInterface.AsnPublicUserSetContactDataResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnPublicUserSetContactDataResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnPublicUserSetContactDataResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnPublicUserSetContactDataResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnPublicUserSetContactDataResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPublicUserSetContactDataResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPublicUserSetContactDataResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnPublicUserSetContactDataResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnPublicUserSetContactDataResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPublicUserSetContactDataResult");

		let t: ENetROSEInterface.AsnPublicUserSetContactDataResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnPublicUserSetContactDataResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnPublicUserSetContactDataResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnPublicUserSetContactDataResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetGoogleIntegrationSettingsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetGoogleIntegrationSettingsArgument");

		const t = {} as ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetGoogleIntegrationSettingsArgument";

		if (errors.validateResult(newContext, "AsnGetGoogleIntegrationSettingsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetGoogleIntegrationSettingsArgument");

		let t: ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnGetGoogleIntegrationSettingsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetGoogleIntegrationSettingsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetGoogleIntegrationSettingsArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetGoogleIntegrationSettingsArgument");

		let t: ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetGoogleIntegrationSettingsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetGoogleIntegrationSettingsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetGoogleIntegrationSettingsResult");

		const t = {} as ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetGoogleIntegrationSettingsResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iEnabled", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sClientID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sClientSecret", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetGoogleIntegrationSettingsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetGoogleIntegrationSettingsResult");

		let t: ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iEnabled", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sClientID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sClientSecret", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetGoogleIntegrationSettingsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetGoogleIntegrationSettingsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetGoogleIntegrationSettingsResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		TSConverter.validateParam(s, "iEnabled", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sClientID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sClientSecret", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetGoogleIntegrationSettingsResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			t.push(new asn1ts.Integer({ value: s.iEnabled, name: "iEnabled" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sClientID, name: "u8sClientID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sClientSecret, name: "u8sClientSecret" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetGoogleIntegrationSettingsResult");

		let t: ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iEnabled", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sClientID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sClientSecret", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetGoogleIntegrationSettingsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSMSMessage_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSMSMessage, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSMSMessage & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSMSMessage");

		const t = {} as ENetROSEInterface.AsnSMSMessage & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSMSMessage";
		TSConverter.fillJSONParam(s, t, "u8sRefId", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sSenderNumberSC", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sReceiverNumberSC", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMessage", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iContentIsPrivate", "number", errors, newContext);
		if (s.contactReceiver) {
			const _contactReceiver = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.contactReceiver, errors, newContext, "contactReceiver");
			if (_contactReceiver)
				t.contactReceiver = _contactReceiver;
		}

		if (errors.validateResult(newContext, "AsnSMSMessage"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSMSMessage | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSMSMessage");

		let t: ENetROSEInterface.AsnSMSMessage | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSMSMessage>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSMSMessage["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sRefId", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sSenderNumberSC", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sReceiverNumberSC", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sMessage", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iContentIsPrivate", "number", errors, context, false);
			t.contactReceiver = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.contactReceiver, errors, newContext, "contactReceiver", true);
		}
		if (errors.validateResult(newContext, "AsnSMSMessage"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSMSMessage | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSMSMessage";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSMSMessage");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sRefId", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sSenderNumberSC", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sReceiverNumberSC", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sMessage", "string", errors, newContext);
		TSConverter.validateParam(s, "iContentIsPrivate", "number", errors, newContext);
		const _contactReceiver = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.contactReceiver, errors, newContext, "contactReceiver", 0);
		if (errors.validateResult(newContext, "AsnSMSMessage")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sRefId, name: "u8sRefId" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sSenderNumberSC, name: "u8sSenderNumberSC" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sReceiverNumberSC, name: "u8sReceiverNumberSC" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sMessage, name: "u8sMessage" }));
			t.push(new asn1ts.Integer({ value: s.iContentIsPrivate, name: "iContentIsPrivate" }));
			if (_contactReceiver)
				t.push(_contactReceiver);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSMSMessage | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSMSMessage");

		let t: ENetROSEInterface.AsnSMSMessage | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSMSMessage.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSMSMessage["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sRefId", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sSenderNumberSC", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sReceiverNumberSC", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMessage", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iContentIsPrivate", "Integer", errors, newContext);
			t.contactReceiver = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contactReceiver"), errors, newContext, "contactReceiver", true);
		}

		if (errors.validateResult(newContext, "AsnSMSMessage"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSendSMSResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSendSMSResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSendSMSResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendSMSResult");

		const t = {} as ENetROSEInterface.AsnSendSMSResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSendSMSResult";
		const _u8sCrossRefIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.u8sCrossRefIDs, errors, newContext, "u8sCrossRefIDs");
		if (_u8sCrossRefIDs)
			t.u8sCrossRefIDs = _u8sCrossRefIDs;

		if (errors.validateResult(newContext, "AsnSendSMSResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendSMSResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendSMSResult");

		let t: ENetROSEInterface.AsnSendSMSResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSendSMSResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSendSMSResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _u8scrossrefids = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.u8sCrossRefIDs, errors, newContext, "u8sCrossRefIDs", false);
			if (_u8scrossrefids)
				t.u8sCrossRefIDs = _u8scrossrefids;
		}
		if (errors.validateResult(newContext, "AsnSendSMSResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSendSMSResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSendSMSResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendSMSResult");

		// [Print_BER_EncoderSeqDefCode]
		const _u8sCrossRefIDs = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.u8sCrossRefIDs, errors, newContext, "u8sCrossRefIDs");
		if (errors.validateResult(newContext, "AsnSendSMSResult")) {
			if (_u8sCrossRefIDs)
				t.push(_u8sCrossRefIDs);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendSMSResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendSMSResult");

		let t: ENetROSEInterface.AsnSendSMSResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSendSMSResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSendSMSResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _u8scrossrefids = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sCrossRefIDs"), errors, newContext, "u8sCrossRefIDs");
			if (_u8scrossrefids)
				t.u8sCrossRefIDs = _u8scrossrefids;
		}

		if (errors.validateResult(newContext, "AsnSendSMSResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUserChangePasswordArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUserChangePasswordArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUserChangePasswordArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserChangePasswordArgument");

		const t = {} as ENetROSEInterface.AsnUserChangePasswordArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUserChangePasswordArgument";
		TSConverter.fillJSONParam(s, t, "u8sOldPassword", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sNewPassword", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnUserChangePasswordArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUserChangePasswordArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserChangePasswordArgument");

		let t: ENetROSEInterface.AsnUserChangePasswordArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUserChangePasswordArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnUserChangePasswordArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sOldPassword", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sNewPassword", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUserChangePasswordArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUserChangePasswordArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUserChangePasswordArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserChangePasswordArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sOldPassword", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sNewPassword", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnUserChangePasswordArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sOldPassword, name: "u8sOldPassword" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sNewPassword, name: "u8sNewPassword" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUserChangePasswordArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserChangePasswordArgument");

		let t: ENetROSEInterface.AsnUserChangePasswordArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUserChangePasswordArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnUserChangePasswordArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sOldPassword", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sNewPassword", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUserChangePasswordArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUserChangePasswordResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUserChangePasswordResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUserChangePasswordResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserChangePasswordResult");

		const t = {} as ENetROSEInterface.AsnUserChangePasswordResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUserChangePasswordResult";
		TSConverter.fillJSONParam(s, t, "success", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnUserChangePasswordResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUserChangePasswordResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserChangePasswordResult");

		let t: ENetROSEInterface.AsnUserChangePasswordResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUserChangePasswordResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnUserChangePasswordResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "success", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUserChangePasswordResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUserChangePasswordResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUserChangePasswordResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserChangePasswordResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "success", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnUserChangePasswordResult")) {
			t.push(new asn1ts.Boolean({ value: s.success, name: "success" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUserChangePasswordResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserChangePasswordResult");

		let t: ENetROSEInterface.AsnUserChangePasswordResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUserChangePasswordResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnUserChangePasswordResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "success", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUserChangePasswordResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetUserdefinedCustomFieldsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserdefinedCustomFieldsArgument");

		const t = {} as ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetUserdefinedCustomFieldsArgument";

		if (errors.validateResult(newContext, "AsnGetUserdefinedCustomFieldsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserdefinedCustomFieldsArgument");

		let t: ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnGetUserdefinedCustomFieldsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetUserdefinedCustomFieldsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserdefinedCustomFieldsArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserdefinedCustomFieldsArgument");

		let t: ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetUserdefinedCustomFieldsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUserDefinedCustomField_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUserDefinedCustomField, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUserDefinedCustomField & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserDefinedCustomField");

		const t = {} as ENetROSEInterface.AsnUserDefinedCustomField & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUserDefinedCustomField";
		TSConverter.fillJSONParam(s, t, "u8sFieldName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sFieldValue", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnUserDefinedCustomField"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUserDefinedCustomField | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserDefinedCustomField");

		let t: ENetROSEInterface.AsnUserDefinedCustomField | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUserDefinedCustomField>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnUserDefinedCustomField["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sFieldName", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sFieldValue", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnUserDefinedCustomField"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUserDefinedCustomField | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUserDefinedCustomField";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserDefinedCustomField");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sFieldName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sFieldValue", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnUserDefinedCustomField")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sFieldName, name: "u8sFieldName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sFieldValue, name: "u8sFieldValue" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUserDefinedCustomField | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserDefinedCustomField");

		let t: ENetROSEInterface.AsnUserDefinedCustomField | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUserDefinedCustomField.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnUserDefinedCustomField["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sFieldName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sFieldValue", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUserDefinedCustomField"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSendMailArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSendMailArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSendMailArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendMailArgument");

		const t = {} as ENetROSEInterface.AsnSendMailArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSendMailArgument";
		TSConverter.fillJSONParam(s, t, "u8sReceiverSIPURI", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMessage", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sSubject", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnSendMailArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendMailArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendMailArgument");

		let t: ENetROSEInterface.AsnSendMailArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSendMailArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSendMailArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sReceiverSIPURI", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sMessage", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sSubject", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnSendMailArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSendMailArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSendMailArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendMailArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sReceiverSIPURI", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sMessage", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sSubject", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnSendMailArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sReceiverSIPURI, name: "u8sReceiverSIPURI" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sMessage, name: "u8sMessage" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sSubject, name: "u8sSubject" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendMailArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendMailArgument");

		let t: ENetROSEInterface.AsnSendMailArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSendMailArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSendMailArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sReceiverSIPURI", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMessage", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sSubject", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSendMailArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSendMailResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSendMailResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSendMailResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendMailResult");

		const t = {} as ENetROSEInterface.AsnSendMailResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSendMailResult";
		TSConverter.fillJSONParam(s, t, "success", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sErrReason", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnSendMailResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendMailResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendMailResult");

		let t: ENetROSEInterface.AsnSendMailResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSendMailResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSendMailResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "success", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sErrReason", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnSendMailResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSendMailResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSendMailResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendMailResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "success", "boolean", errors, newContext);
		TSConverter.validateParam(s, "u8sErrReason", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnSendMailResult")) {
			t.push(new asn1ts.Boolean({ value: s.success, name: "success" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sErrReason, name: "u8sErrReason" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendMailResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendMailResult");

		let t: ENetROSEInterface.AsnSendMailResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSendMailResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSendMailResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "success", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sErrReason", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSendMailResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetServerInterfaceVersionArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetServerInterfaceVersionArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetServerInterfaceVersionArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetServerInterfaceVersionArgument");

		const t = {} as ENetROSEInterface.AsnGetServerInterfaceVersionArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetServerInterfaceVersionArgument";

		if (errors.validateResult(newContext, "AsnGetServerInterfaceVersionArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetServerInterfaceVersionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetServerInterfaceVersionArgument");

		let t: ENetROSEInterface.AsnGetServerInterfaceVersionArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetServerInterfaceVersionArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetServerInterfaceVersionArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnGetServerInterfaceVersionArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetServerInterfaceVersionArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetServerInterfaceVersionArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetServerInterfaceVersionArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetServerInterfaceVersionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetServerInterfaceVersionArgument");

		let t: ENetROSEInterface.AsnGetServerInterfaceVersionArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetServerInterfaceVersionArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetServerInterfaceVersionArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetServerInterfaceVersionArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetServerInterfaceVersionResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetServerInterfaceVersionResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetServerInterfaceVersionResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetServerInterfaceVersionResult");

		const t = {} as ENetROSEInterface.AsnGetServerInterfaceVersionResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetServerInterfaceVersionResult";
		TSConverter.fillJSONParam(s, t, "iServerInterfaceVersion", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetServerInterfaceVersionResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetServerInterfaceVersionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetServerInterfaceVersionResult");

		let t: ENetROSEInterface.AsnGetServerInterfaceVersionResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetServerInterfaceVersionResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetServerInterfaceVersionResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iServerInterfaceVersion", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetServerInterfaceVersionResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetServerInterfaceVersionResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetServerInterfaceVersionResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetServerInterfaceVersionResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iServerInterfaceVersion", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetServerInterfaceVersionResult")) {
			t.push(new asn1ts.Integer({ value: s.iServerInterfaceVersion, name: "iServerInterfaceVersion" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetServerInterfaceVersionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetServerInterfaceVersionResult");

		let t: ENetROSEInterface.AsnGetServerInterfaceVersionResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetServerInterfaceVersionResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetServerInterfaceVersionResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iServerInterfaceVersion", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetServerInterfaceVersionResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRemoteInvokeCompleteArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnRemoteInvokeCompleteArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnRemoteInvokeCompleteArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemoteInvokeCompleteArgument");

		const t = {} as ENetROSEInterface.AsnRemoteInvokeCompleteArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRemoteInvokeCompleteArgument";
		TSConverter.fillJSONParam(s, t, "u8sInvokeID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sInvokingConnection", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnRemoteInvokeCompleteArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRemoteInvokeCompleteArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemoteInvokeCompleteArgument");

		let t: ENetROSEInterface.AsnRemoteInvokeCompleteArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnRemoteInvokeCompleteArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnRemoteInvokeCompleteArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sInvokeID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sInvokingConnection", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnRemoteInvokeCompleteArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnRemoteInvokeCompleteArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRemoteInvokeCompleteArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRemoteInvokeCompleteArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sInvokeID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sInvokingConnection", "string", errors, newContext);
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 0);
		if (errors.validateResult(newContext, "AsnRemoteInvokeCompleteArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sInvokeID, name: "u8sInvokeID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sInvokingConnection, name: "u8sInvokingConnection" }));
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRemoteInvokeCompleteArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRemoteInvokeCompleteArgument");

		let t: ENetROSEInterface.AsnRemoteInvokeCompleteArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnRemoteInvokeCompleteArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnRemoteInvokeCompleteArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sInvokeID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sInvokingConnection", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnRemoteInvokeCompleteArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNumberFormatRegExpressList2_Converter {
	public static toJSON(s: ENetROSEInterface.AsnNumberFormatRegExpressList2, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnNumberFormatRegExpressList2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNumberFormatRegExpressList2");

		const t = [] as ENetROSEInterface.AsnNumberFormatRegExpressList2;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnNumberFormatRegExpression2_Converter.toJSON(se, errors, newContext, "AsnNumberFormatRegExpression2");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnNumberFormatRegExpressList2"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNumberFormatRegExpressList2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNumberFormatRegExpressList2");

		let t: ENetROSEInterface.AsnNumberFormatRegExpressList2 | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnNumberFormatRegExpressList2>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnNumberFormatRegExpressList2();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnNumberFormatRegExpression2_Converter.fromJSON(se, errors, newContext, "AsnNumberFormatRegExpression2", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnNumberFormatRegExpressList2"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnNumberFormatRegExpressList2 | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNumberFormatRegExpressList2";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNumberFormatRegExpressList2");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnNumberFormatRegExpression2_Converter.toBER(s[id], errors, newContext, "AsnNumberFormatRegExpression2");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnNumberFormatRegExpression2"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNumberFormatRegExpressList2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNumberFormatRegExpressList2");

		let t: ENetROSEInterface.AsnNumberFormatRegExpressList2 | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnNumberFormatRegExpressList2.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnNumberFormatRegExpressList2();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnNumberFormatRegExpression2_Converter.fromBER(se, errors, newContext, "AsnNumberFormatRegExpression2", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnNumberFormatRegExpressList2"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNumberFormatRegExpressions2_Converter {
	public static toJSON(s: ENetROSEInterface.AsnNumberFormatRegExpressions2, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnNumberFormatRegExpressions2 & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNumberFormatRegExpressions2");

		const t = {} as ENetROSEInterface.AsnNumberFormatRegExpressions2 & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNumberFormatRegExpressions2";
		TSConverter.fillJSONParam(s, t, "bIsReadOnlyFromFile", "boolean", errors, newContext);
		const _formatRules = AsnNumberFormatRegExpressList2_Converter.toJSON(s.formatRules, errors, newContext, "formatRules");
		if (_formatRules)
			t.formatRules = _formatRules;

		if (errors.validateResult(newContext, "AsnNumberFormatRegExpressions2"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNumberFormatRegExpressions2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNumberFormatRegExpressions2");

		let t: ENetROSEInterface.AsnNumberFormatRegExpressions2 | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnNumberFormatRegExpressions2>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnNumberFormatRegExpressions2["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bIsReadOnlyFromFile", "boolean", errors, context, false);
			const _formatrules = AsnNumberFormatRegExpressList2_Converter.fromJSON(s.formatRules, errors, newContext, "formatRules", false);
			if (_formatrules)
				t.formatRules = _formatrules;
		}
		if (errors.validateResult(newContext, "AsnNumberFormatRegExpressions2"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnNumberFormatRegExpressions2 | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNumberFormatRegExpressions2";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNumberFormatRegExpressions2");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bIsReadOnlyFromFile", "boolean", errors, newContext);
		const _formatRules = AsnNumberFormatRegExpressList2_Converter.toBER(s.formatRules, errors, newContext, "formatRules");
		if (errors.validateResult(newContext, "AsnNumberFormatRegExpressions2")) {
			t.push(new asn1ts.Boolean({ value: s.bIsReadOnlyFromFile, name: "bIsReadOnlyFromFile" }));
			if (_formatRules)
				t.push(_formatRules);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnNumberFormatRegExpressions2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNumberFormatRegExpressions2");

		let t: ENetROSEInterface.AsnNumberFormatRegExpressions2 | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnNumberFormatRegExpressions2.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnNumberFormatRegExpressions2["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bIsReadOnlyFromFile", "Boolean", errors, newContext);
			const _formatrules = AsnNumberFormatRegExpressList2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "formatRules"), errors, newContext, "formatRules");
			if (_formatrules)
				t.formatRules = _formatrules;
		}

		if (errors.validateResult(newContext, "AsnNumberFormatRegExpressions2"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLocationInformation2_Converter {
	public static toJSON(s: ENetROSEInterface.AsnLocationInformation2, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnLocationInformation2 & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLocationInformation2");

		const t = {} as ENetROSEInterface.AsnLocationInformation2 & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLocationInformation2";
		TSConverter.fillJSONParam(s, t, "u8sLocationID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLocationCheckSum", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bUseVanity", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bShowExtendedSettings", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bUseTkSystem", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bUseAdvancedLCR", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bFixedNumberLenExtensionDTMFDialing", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bMultiLocationSupport", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bAutoCreateOtherLocationRules", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bAllowNumberSearchOtherLocations", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bShortenOtherLocationSCNumbersForDialing", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iCountryID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iLanguage", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCountryCode", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLocalPrefix", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sNationalPrefix", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sInternationalPrefix", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sLocalDialingRule", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sNationalDialingRule", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sInternationalDialingRule", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCityCodeNoPrefix", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sVanityNumbers", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sTKLocalAccessCode", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sTKLongDistanceAccessCode", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sTKInternationalAccessCode", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sTKPrivateAccessCode", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sTKForwardingAccessCode", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sTKAnschlussNr", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iTKMinLenInternalNumbers", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iTKMaxLenInternalNumbers", "number", errors, newContext);
		const _numberFormatInternal = AsnNumberFormatRegExpressions2_Converter.toJSON(s.numberFormatInternal, errors, newContext, "numberFormatInternal");
		if (_numberFormatInternal)
			t.numberFormatInternal = _numberFormatInternal;
		const _numberFormatExternal = AsnNumberFormatRegExpressions2_Converter.toJSON(s.numberFormatExternal, errors, newContext, "numberFormatExternal");
		if (_numberFormatExternal)
			t.numberFormatExternal = _numberFormatExternal;
		const _numberFormatAutomaticOtherLocationsDDItoSC = AsnNumberFormatRegExpressions2_Converter.toJSON(s.numberFormatAutomaticOtherLocationsDDItoSC, errors, newContext, "numberFormatAutomaticOtherLocationsDDItoSC");
		if (_numberFormatAutomaticOtherLocationsDDItoSC)
			t.numberFormatAutomaticOtherLocationsDDItoSC = _numberFormatAutomaticOtherLocationsDDItoSC;
		const _numberFormatAutomaticOtherLocationsSCtoDDI = AsnNumberFormatRegExpressions2_Converter.toJSON(s.numberFormatAutomaticOtherLocationsSCtoDDI, errors, newContext, "numberFormatAutomaticOtherLocationsSCtoDDI");
		if (_numberFormatAutomaticOtherLocationsSCtoDDI)
			t.numberFormatAutomaticOtherLocationsSCtoDDI = _numberFormatAutomaticOtherLocationsSCtoDDI;
		const _numberFormatRegExpressListIn = AsnNumberFormatRegExpressions2_Converter.toJSON(s.numberFormatRegExpressListIn, errors, newContext, "numberFormatRegExpressListIn");
		if (_numberFormatRegExpressListIn)
			t.numberFormatRegExpressListIn = _numberFormatRegExpressListIn;
		const _numberFormatRegExpressListOut = AsnNumberFormatRegExpressions2_Converter.toJSON(s.numberFormatRegExpressListOut, errors, newContext, "numberFormatRegExpressListOut");
		if (_numberFormatRegExpressListOut)
			t.numberFormatRegExpressListOut = _numberFormatRegExpressListOut;
		const _numberFormatRegExpressListDial = AsnNumberFormatRegExpressions2_Converter.toJSON(s.numberFormatRegExpressListDial, errors, newContext, "numberFormatRegExpressListDial");
		if (_numberFormatRegExpressListDial)
			t.numberFormatRegExpressListDial = _numberFormatRegExpressListDial;
		const _numberFormatRegExpressListFinalDial = AsnNumberFormatRegExpressions2_Converter.toJSON(s.numberFormatRegExpressListFinalDial, errors, newContext, "numberFormatRegExpressListFinalDial");
		if (_numberFormatRegExpressListFinalDial)
			t.numberFormatRegExpressListFinalDial = _numberFormatRegExpressListFinalDial;
		const _projectPINRules = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.toJSON(s.projectPINRules, errors, newContext, "projectPINRules");
		if (_projectPINRules)
			t.projectPINRules = _projectPINRules;
		TSConverter.fillJSONParam(s, t, "u8sDDIRangeBegin", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDDIRangeEnd", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iDialingNumberFormat", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "bNoLocalDialing", "boolean", errors, newContext);

		if (errors.validateResult(newContext, "AsnLocationInformation2"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnLocationInformation2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLocationInformation2");

		let t: ENetROSEInterface.AsnLocationInformation2 | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnLocationInformation2>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnLocationInformation2["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sLocationID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLocationCheckSum", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bUseVanity", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bShowExtendedSettings", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bUseTkSystem", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bUseAdvancedLCR", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bFixedNumberLenExtensionDTMFDialing", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bMultiLocationSupport", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bAutoCreateOtherLocationRules", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bAllowNumberSearchOtherLocations", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bShortenOtherLocationSCNumbersForDialing", "boolean", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iCountryID", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iLanguage", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sCountryCode", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLocalPrefix", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sNationalPrefix", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sInternationalPrefix", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sLocalDialingRule", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sNationalDialingRule", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sInternationalDialingRule", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sCityCodeNoPrefix", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sVanityNumbers", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sTKLocalAccessCode", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sTKLongDistanceAccessCode", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sTKInternationalAccessCode", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sTKPrivateAccessCode", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sTKForwardingAccessCode", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sTKAnschlussNr", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iTKMinLenInternalNumbers", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iTKMaxLenInternalNumbers", "number", errors, context, false);
			const _numberformatinternal = AsnNumberFormatRegExpressions2_Converter.fromJSON(s.numberFormatInternal, errors, newContext, "numberFormatInternal", false);
			if (_numberformatinternal)
				t.numberFormatInternal = _numberformatinternal;
			const _numberformatexternal = AsnNumberFormatRegExpressions2_Converter.fromJSON(s.numberFormatExternal, errors, newContext, "numberFormatExternal", false);
			if (_numberformatexternal)
				t.numberFormatExternal = _numberformatexternal;
			const _numberformatautomaticotherlocationsdditosc = AsnNumberFormatRegExpressions2_Converter.fromJSON(s.numberFormatAutomaticOtherLocationsDDItoSC, errors, newContext, "numberFormatAutomaticOtherLocationsDDItoSC", false);
			if (_numberformatautomaticotherlocationsdditosc)
				t.numberFormatAutomaticOtherLocationsDDItoSC = _numberformatautomaticotherlocationsdditosc;
			const _numberformatautomaticotherlocationssctoddi = AsnNumberFormatRegExpressions2_Converter.fromJSON(s.numberFormatAutomaticOtherLocationsSCtoDDI, errors, newContext, "numberFormatAutomaticOtherLocationsSCtoDDI", false);
			if (_numberformatautomaticotherlocationssctoddi)
				t.numberFormatAutomaticOtherLocationsSCtoDDI = _numberformatautomaticotherlocationssctoddi;
			const _numberformatregexpresslistin = AsnNumberFormatRegExpressions2_Converter.fromJSON(s.numberFormatRegExpressListIn, errors, newContext, "numberFormatRegExpressListIn", false);
			if (_numberformatregexpresslistin)
				t.numberFormatRegExpressListIn = _numberformatregexpresslistin;
			const _numberformatregexpresslistout = AsnNumberFormatRegExpressions2_Converter.fromJSON(s.numberFormatRegExpressListOut, errors, newContext, "numberFormatRegExpressListOut", false);
			if (_numberformatregexpresslistout)
				t.numberFormatRegExpressListOut = _numberformatregexpresslistout;
			const _numberformatregexpresslistdial = AsnNumberFormatRegExpressions2_Converter.fromJSON(s.numberFormatRegExpressListDial, errors, newContext, "numberFormatRegExpressListDial", false);
			if (_numberformatregexpresslistdial)
				t.numberFormatRegExpressListDial = _numberformatregexpresslistdial;
			const _numberformatregexpresslistfinaldial = AsnNumberFormatRegExpressions2_Converter.fromJSON(s.numberFormatRegExpressListFinalDial, errors, newContext, "numberFormatRegExpressListFinalDial", false);
			if (_numberformatregexpresslistfinaldial)
				t.numberFormatRegExpressListFinalDial = _numberformatregexpresslistfinaldial;
			const _projectpinrules = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.fromJSON(s.projectPINRules, errors, newContext, "projectPINRules", false);
			if (_projectpinrules)
				t.projectPINRules = _projectpinrules;
			TSConverter.fillJSONParam(s, t, "u8sDDIRangeBegin", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDDIRangeEnd", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iDialingNumberFormat", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "bNoLocalDialing", "boolean", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnLocationInformation2"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnLocationInformation2 | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnLocationInformation2";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLocationInformation2");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sLocationID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLocationCheckSum", "string", errors, newContext);
		TSConverter.validateParam(s, "bUseVanity", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bShowExtendedSettings", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bUseTkSystem", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bUseAdvancedLCR", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bFixedNumberLenExtensionDTMFDialing", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bMultiLocationSupport", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bAutoCreateOtherLocationRules", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bAllowNumberSearchOtherLocations", "boolean", errors, newContext);
		TSConverter.validateParam(s, "bShortenOtherLocationSCNumbersForDialing", "boolean", errors, newContext);
		TSConverter.validateParam(s, "iCountryID", "number", errors, newContext);
		TSConverter.validateParam(s, "iLanguage", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sCountryCode", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLocalPrefix", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sNationalPrefix", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sInternationalPrefix", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sLocalDialingRule", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sNationalDialingRule", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sInternationalDialingRule", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCityCodeNoPrefix", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sVanityNumbers", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sTKLocalAccessCode", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sTKLongDistanceAccessCode", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sTKInternationalAccessCode", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sTKPrivateAccessCode", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sTKForwardingAccessCode", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sTKAnschlussNr", "string", errors, newContext);
		TSConverter.validateParam(s, "iTKMinLenInternalNumbers", "number", errors, newContext);
		TSConverter.validateParam(s, "iTKMaxLenInternalNumbers", "number", errors, newContext);
		const _numberFormatInternal = AsnNumberFormatRegExpressions2_Converter.toBER(s.numberFormatInternal, errors, newContext, "numberFormatInternal");
		const _numberFormatExternal = AsnNumberFormatRegExpressions2_Converter.toBER(s.numberFormatExternal, errors, newContext, "numberFormatExternal");
		const _numberFormatAutomaticOtherLocationsDDItoSC = AsnNumberFormatRegExpressions2_Converter.toBER(s.numberFormatAutomaticOtherLocationsDDItoSC, errors, newContext, "numberFormatAutomaticOtherLocationsDDItoSC");
		const _numberFormatAutomaticOtherLocationsSCtoDDI = AsnNumberFormatRegExpressions2_Converter.toBER(s.numberFormatAutomaticOtherLocationsSCtoDDI, errors, newContext, "numberFormatAutomaticOtherLocationsSCtoDDI");
		const _numberFormatRegExpressListIn = AsnNumberFormatRegExpressions2_Converter.toBER(s.numberFormatRegExpressListIn, errors, newContext, "numberFormatRegExpressListIn");
		const _numberFormatRegExpressListOut = AsnNumberFormatRegExpressions2_Converter.toBER(s.numberFormatRegExpressListOut, errors, newContext, "numberFormatRegExpressListOut");
		const _numberFormatRegExpressListDial = AsnNumberFormatRegExpressions2_Converter.toBER(s.numberFormatRegExpressListDial, errors, newContext, "numberFormatRegExpressListDial");
		const _numberFormatRegExpressListFinalDial = AsnNumberFormatRegExpressions2_Converter.toBER(s.numberFormatRegExpressListFinalDial, errors, newContext, "numberFormatRegExpressListFinalDial");
		const _projectPINRules = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.toBER(s.projectPINRules, errors, newContext, "projectPINRules");
		TSConverter.validateParam(s, "u8sDDIRangeBegin", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDDIRangeEnd", "string", errors, newContext);
		TSConverter.validateParam(s, "iDialingNumberFormat", "number", errors, newContext);
		TSConverter.validateParam(s, "bNoLocalDialing", "boolean", errors, newContext);
		if (errors.validateResult(newContext, "AsnLocationInformation2")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sLocationID, name: "u8sLocationID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLocationCheckSum, name: "u8sLocationCheckSum" }));
			t.push(new asn1ts.Boolean({ value: s.bUseVanity, name: "bUseVanity" }));
			t.push(new asn1ts.Boolean({ value: s.bShowExtendedSettings, name: "bShowExtendedSettings" }));
			t.push(new asn1ts.Boolean({ value: s.bUseTkSystem, name: "bUseTkSystem" }));
			t.push(new asn1ts.Boolean({ value: s.bUseAdvancedLCR, name: "bUseAdvancedLCR" }));
			t.push(new asn1ts.Boolean({ value: s.bFixedNumberLenExtensionDTMFDialing, name: "bFixedNumberLenExtensionDTMFDialing" }));
			t.push(new asn1ts.Boolean({ value: s.bMultiLocationSupport, name: "bMultiLocationSupport" }));
			t.push(new asn1ts.Boolean({ value: s.bAutoCreateOtherLocationRules, name: "bAutoCreateOtherLocationRules" }));
			t.push(new asn1ts.Boolean({ value: s.bAllowNumberSearchOtherLocations, name: "bAllowNumberSearchOtherLocations" }));
			t.push(new asn1ts.Boolean({ value: s.bShortenOtherLocationSCNumbersForDialing, name: "bShortenOtherLocationSCNumbersForDialing" }));
			t.push(new asn1ts.Integer({ value: s.iCountryID, name: "iCountryID" }));
			t.push(new asn1ts.Integer({ value: s.iLanguage, name: "iLanguage" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCountryCode, name: "u8sCountryCode" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLocalPrefix, name: "u8sLocalPrefix" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sNationalPrefix, name: "u8sNationalPrefix" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sInternationalPrefix, name: "u8sInternationalPrefix" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sLocalDialingRule, name: "u8sLocalDialingRule" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sNationalDialingRule, name: "u8sNationalDialingRule" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sInternationalDialingRule, name: "u8sInternationalDialingRule" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCityCodeNoPrefix, name: "u8sCityCodeNoPrefix" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sVanityNumbers, name: "u8sVanityNumbers" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sTKLocalAccessCode, name: "u8sTKLocalAccessCode" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sTKLongDistanceAccessCode, name: "u8sTKLongDistanceAccessCode" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sTKInternationalAccessCode, name: "u8sTKInternationalAccessCode" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sTKPrivateAccessCode, name: "u8sTKPrivateAccessCode" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sTKForwardingAccessCode, name: "u8sTKForwardingAccessCode" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sTKAnschlussNr, name: "u8sTKAnschlussNr" }));
			t.push(new asn1ts.Integer({ value: s.iTKMinLenInternalNumbers, name: "iTKMinLenInternalNumbers" }));
			t.push(new asn1ts.Integer({ value: s.iTKMaxLenInternalNumbers, name: "iTKMaxLenInternalNumbers" }));
			if (_numberFormatInternal)
				t.push(_numberFormatInternal);
			if (_numberFormatExternal)
				t.push(_numberFormatExternal);
			if (_numberFormatAutomaticOtherLocationsDDItoSC)
				t.push(_numberFormatAutomaticOtherLocationsDDItoSC);
			if (_numberFormatAutomaticOtherLocationsSCtoDDI)
				t.push(_numberFormatAutomaticOtherLocationsSCtoDDI);
			if (_numberFormatRegExpressListIn)
				t.push(_numberFormatRegExpressListIn);
			if (_numberFormatRegExpressListOut)
				t.push(_numberFormatRegExpressListOut);
			if (_numberFormatRegExpressListDial)
				t.push(_numberFormatRegExpressListDial);
			if (_numberFormatRegExpressListFinalDial)
				t.push(_numberFormatRegExpressListFinalDial);
			if (_projectPINRules)
				t.push(_projectPINRules);
			t.push(new asn1ts.Utf8String({ value: s.u8sDDIRangeBegin, name: "u8sDDIRangeBegin" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDDIRangeEnd, name: "u8sDDIRangeEnd" }));
			t.push(new asn1ts.Integer({ value: s.iDialingNumberFormat, name: "iDialingNumberFormat" }));
			t.push(new asn1ts.Boolean({ value: s.bNoLocalDialing, name: "bNoLocalDialing" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnLocationInformation2 | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLocationInformation2");

		let t: ENetROSEInterface.AsnLocationInformation2 | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnLocationInformation2.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnLocationInformation2["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sLocationID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLocationCheckSum", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bUseVanity", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bShowExtendedSettings", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bUseTkSystem", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bUseAdvancedLCR", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bFixedNumberLenExtensionDTMFDialing", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bMultiLocationSupport", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bAutoCreateOtherLocationRules", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bAllowNumberSearchOtherLocations", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bShortenOtherLocationSCNumbersForDialing", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iCountryID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iLanguage", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCountryCode", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLocalPrefix", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sNationalPrefix", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sInternationalPrefix", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sLocalDialingRule", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sNationalDialingRule", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sInternationalDialingRule", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCityCodeNoPrefix", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sVanityNumbers", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sTKLocalAccessCode", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sTKLongDistanceAccessCode", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sTKInternationalAccessCode", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sTKPrivateAccessCode", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sTKForwardingAccessCode", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sTKAnschlussNr", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iTKMinLenInternalNumbers", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iTKMaxLenInternalNumbers", "Integer", errors, newContext);
			const _numberformatinternal = AsnNumberFormatRegExpressions2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "numberFormatInternal"), errors, newContext, "numberFormatInternal");
			if (_numberformatinternal)
				t.numberFormatInternal = _numberformatinternal;
			const _numberformatexternal = AsnNumberFormatRegExpressions2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "numberFormatExternal"), errors, newContext, "numberFormatExternal");
			if (_numberformatexternal)
				t.numberFormatExternal = _numberformatexternal;
			const _numberformatautomaticotherlocationsdditosc = AsnNumberFormatRegExpressions2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "numberFormatAutomaticOtherLocationsDDItoSC"), errors, newContext, "numberFormatAutomaticOtherLocationsDDItoSC");
			if (_numberformatautomaticotherlocationsdditosc)
				t.numberFormatAutomaticOtherLocationsDDItoSC = _numberformatautomaticotherlocationsdditosc;
			const _numberformatautomaticotherlocationssctoddi = AsnNumberFormatRegExpressions2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "numberFormatAutomaticOtherLocationsSCtoDDI"), errors, newContext, "numberFormatAutomaticOtherLocationsSCtoDDI");
			if (_numberformatautomaticotherlocationssctoddi)
				t.numberFormatAutomaticOtherLocationsSCtoDDI = _numberformatautomaticotherlocationssctoddi;
			const _numberformatregexpresslistin = AsnNumberFormatRegExpressions2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "numberFormatRegExpressListIn"), errors, newContext, "numberFormatRegExpressListIn");
			if (_numberformatregexpresslistin)
				t.numberFormatRegExpressListIn = _numberformatregexpresslistin;
			const _numberformatregexpresslistout = AsnNumberFormatRegExpressions2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "numberFormatRegExpressListOut"), errors, newContext, "numberFormatRegExpressListOut");
			if (_numberformatregexpresslistout)
				t.numberFormatRegExpressListOut = _numberformatregexpresslistout;
			const _numberformatregexpresslistdial = AsnNumberFormatRegExpressions2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "numberFormatRegExpressListDial"), errors, newContext, "numberFormatRegExpressListDial");
			if (_numberformatregexpresslistdial)
				t.numberFormatRegExpressListDial = _numberformatregexpresslistdial;
			const _numberformatregexpresslistfinaldial = AsnNumberFormatRegExpressions2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "numberFormatRegExpressListFinalDial"), errors, newContext, "numberFormatRegExpressListFinalDial");
			if (_numberformatregexpresslistfinaldial)
				t.numberFormatRegExpressListFinalDial = _numberformatregexpresslistfinaldial;
			const _projectpinrules = ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "projectPINRules"), errors, newContext, "projectPINRules");
			if (_projectpinrules)
				t.projectPINRules = _projectpinrules;
			TSConverter.fillASN1Param(s, t, "u8sDDIRangeBegin", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDDIRangeEnd", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iDialingNumberFormat", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "bNoLocalDialing", "Boolean", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnLocationInformation2"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetLocationInformation2Result_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetLocationInformation2Result, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetLocationInformation2Result & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLocationInformation2Result");

		const t = {} as ENetROSEInterface.AsnGetLocationInformation2Result & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetLocationInformation2Result";
		const _asnLocationData = AsnLocationInformation2_Converter.toJSON(s.asnLocationData, errors, newContext, "asnLocationData");
		if (_asnLocationData)
			t.asnLocationData = _asnLocationData;

		if (errors.validateResult(newContext, "AsnGetLocationInformation2Result"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLocationInformation2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLocationInformation2Result");

		let t: ENetROSEInterface.AsnGetLocationInformation2Result | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetLocationInformation2Result>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetLocationInformation2Result["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _asnlocationdata = AsnLocationInformation2_Converter.fromJSON(s.asnLocationData, errors, newContext, "asnLocationData", false);
			if (_asnlocationdata)
				t.asnLocationData = _asnlocationdata;
		}
		if (errors.validateResult(newContext, "AsnGetLocationInformation2Result"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetLocationInformation2Result | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetLocationInformation2Result";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetLocationInformation2Result");

		// [Print_BER_EncoderSeqDefCode]
		const _asnLocationData = AsnLocationInformation2_Converter.toBER(s.asnLocationData, errors, newContext, "asnLocationData");
		if (errors.validateResult(newContext, "AsnGetLocationInformation2Result")) {
			if (_asnLocationData)
				t.push(_asnLocationData);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetLocationInformation2Result | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetLocationInformation2Result");

		let t: ENetROSEInterface.AsnGetLocationInformation2Result | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetLocationInformation2Result.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetLocationInformation2Result["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _asnlocationdata = AsnLocationInformation2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnLocationData"), errors, newContext, "asnLocationData");
			if (_asnlocationdata)
				t.asnLocationData = _asnlocationdata;
		}

		if (errors.validateResult(newContext, "AsnGetLocationInformation2Result"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetLocationInformation2Argument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetLocationInformation2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetLocationInformation2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetLocationInformation2Argument");

		const t = {} as ENetROSEInterface.AsnSetLocationInformation2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetLocationInformation2Argument";
		const _asnLocationData = AsnLocationInformation2_Converter.toJSON(s.asnLocationData, errors, newContext, "asnLocationData");
		if (_asnLocationData)
			t.asnLocationData = _asnLocationData;

		if (errors.validateResult(newContext, "AsnSetLocationInformation2Argument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetLocationInformation2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetLocationInformation2Argument");

		let t: ENetROSEInterface.AsnSetLocationInformation2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetLocationInformation2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetLocationInformation2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _asnlocationdata = AsnLocationInformation2_Converter.fromJSON(s.asnLocationData, errors, newContext, "asnLocationData", false);
			if (_asnlocationdata)
				t.asnLocationData = _asnlocationdata;
		}
		if (errors.validateResult(newContext, "AsnSetLocationInformation2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetLocationInformation2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetLocationInformation2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetLocationInformation2Argument");

		// [Print_BER_EncoderSeqDefCode]
		const _asnLocationData = AsnLocationInformation2_Converter.toBER(s.asnLocationData, errors, newContext, "asnLocationData");
		if (errors.validateResult(newContext, "AsnSetLocationInformation2Argument")) {
			if (_asnLocationData)
				t.push(_asnLocationData);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetLocationInformation2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetLocationInformation2Argument");

		let t: ENetROSEInterface.AsnSetLocationInformation2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetLocationInformation2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetLocationInformation2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _asnlocationdata = AsnLocationInformation2_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnLocationData"), errors, newContext, "asnLocationData");
			if (_asnlocationdata)
				t.asnLocationData = _asnlocationdata;
		}

		if (errors.validateResult(newContext, "AsnSetLocationInformation2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnActionFilters_Converter {
	public static toJSON(s: ENetROSEInterface.AsnActionFilters, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnActionFilters | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnActionFilters");

		const t = [] as ENetROSEInterface.AsnActionFilters;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnActionFilterEntry_Converter.toJSON(se, errors, newContext, "AsnActionFilterEntry");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnActionFilters"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnActionFilters | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnActionFilters");

		let t: ENetROSEInterface.AsnActionFilters | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnActionFilters>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnActionFilters();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnActionFilterEntry_Converter.fromJSON(se, errors, newContext, "AsnActionFilterEntry", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnActionFilters"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnActionFilters | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnActionFilters";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnActionFilters");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnActionFilterEntry_Converter.toBER(s[id], errors, newContext, "AsnActionFilterEntry");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnActionFilterEntry"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnActionFilters | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnActionFilters");

		let t: ENetROSEInterface.AsnActionFilters | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnActionFilters.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnActionFilters();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnActionFilterEntry_Converter.fromBER(se, errors, newContext, "AsnActionFilterEntry", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnActionFilters"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnLineForwardProfile_Converter {
	public static toJSON(s: ENetROSEInterface.AsnLineForwardProfile, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnLineForwardProfile & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLineForwardProfile");

		const t = {} as ENetROSEInterface.AsnLineForwardProfile & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnLineForwardProfile";
		TSConverter.fillJSONParam(s, t, "u8sGUID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDisplayName", "string", errors, newContext);
		const _lineIdentification = AsnLineForwardProfileChoice_Converter.toJSON(s.lineIdentification, errors, newContext, "lineIdentification");
		if (_lineIdentification)
			t.lineIdentification = _lineIdentification;
		const _seqLineForward = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(s.seqLineForward, errors, newContext, "seqLineForward");
		if (_seqLineForward)
			t.seqLineForward = _seqLineForward;

		if (errors.validateResult(newContext, "AsnLineForwardProfile"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnLineForwardProfile | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLineForwardProfile");

		let t: ENetROSEInterface.AsnLineForwardProfile | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnLineForwardProfile>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnLineForwardProfile["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sGUID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sDisplayName", "string", errors, context, false);
			const _lineidentification = AsnLineForwardProfileChoice_Converter.fromJSON(s.lineIdentification, errors, newContext, "lineIdentification", false);
			if (_lineidentification)
				t.lineIdentification = _lineidentification;
			const _seqlineforward = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(s.seqLineForward, errors, newContext, "seqLineForward", false);
			if (_seqlineforward)
				t.seqLineForward = _seqlineforward;
		}
		if (errors.validateResult(newContext, "AsnLineForwardProfile"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnLineForwardProfile | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnLineForwardProfile";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnLineForwardProfile");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sGUID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sDisplayName", "string", errors, newContext);
		const _lineIdentification = AsnLineForwardProfileChoice_Converter.toBER(s.lineIdentification, errors, newContext, "lineIdentification");
		const _seqLineForward = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toBER(s.seqLineForward, errors, newContext, "seqLineForward");
		if (errors.validateResult(newContext, "AsnLineForwardProfile")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sGUID, name: "u8sGUID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDisplayName, name: "u8sDisplayName" }));
			if (_lineIdentification)
				t.push(_lineIdentification);
			if (_seqLineForward)
				t.push(_seqLineForward);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnLineForwardProfile | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnLineForwardProfile");

		let t: ENetROSEInterface.AsnLineForwardProfile | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnLineForwardProfile.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnLineForwardProfile["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sGUID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDisplayName", "Utf8String", errors, newContext);
			const _lineidentification = AsnLineForwardProfileChoice_Converter.fromBER(s.getValueByName("lineIdentification"), errors, newContext, "lineIdentification");
			if (_lineidentification)
				t.lineIdentification = _lineidentification;
			const _seqlineforward = ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqLineForward"), errors, newContext, "seqLineForward");
			if (_seqlineforward)
				t.seqLineForward = _seqlineforward;
		}

		if (errors.validateResult(newContext, "AsnLineForwardProfile"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAppointmentForwardEntry_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAppointmentForwardEntry, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAppointmentForwardEntry & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAppointmentForwardEntry");

		const t = {} as ENetROSEInterface.AsnAppointmentForwardEntry & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAppointmentForwardEntry";
		TSConverter.fillJSONParam(s, t, "iAppointmentContactState", "number", errors, newContext);
		const _lineForwardProfile = AsnLineForwardProfile_Converter.toJSON(s.lineForwardProfile, errors, newContext, "lineForwardProfile");
		if (_lineForwardProfile)
			t.lineForwardProfile = _lineForwardProfile;
		if (s.seqFilters) {
			const _seqFilters = AsnActionFilters_Converter.toJSON(s.seqFilters, errors, newContext, "seqFilters");
			if (_seqFilters)
				t.seqFilters = _seqFilters;
		}

		if (errors.validateResult(newContext, "AsnAppointmentForwardEntry"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAppointmentForwardEntry | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAppointmentForwardEntry");

		let t: ENetROSEInterface.AsnAppointmentForwardEntry | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAppointmentForwardEntry>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnAppointmentForwardEntry["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iAppointmentContactState", "number", errors, context, false);
			const _lineforwardprofile = AsnLineForwardProfile_Converter.fromJSON(s.lineForwardProfile, errors, newContext, "lineForwardProfile", false);
			if (_lineforwardprofile)
				t.lineForwardProfile = _lineforwardprofile;
			t.seqFilters = AsnActionFilters_Converter.fromJSON(s.seqFilters, errors, newContext, "seqFilters", true);
		}
		if (errors.validateResult(newContext, "AsnAppointmentForwardEntry"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAppointmentForwardEntry | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAppointmentForwardEntry";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAppointmentForwardEntry");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iAppointmentContactState", "number", errors, newContext);
		const _lineForwardProfile = AsnLineForwardProfile_Converter.toBER(s.lineForwardProfile, errors, newContext, "lineForwardProfile");
		const _seqFilters = AsnActionFilters_Converter.toBER(s.seqFilters, errors, newContext, "seqFilters", 0);
		if (errors.validateResult(newContext, "AsnAppointmentForwardEntry")) {
			t.push(new asn1ts.Integer({ value: s.iAppointmentContactState, name: "iAppointmentContactState" }));
			if (_lineForwardProfile)
				t.push(_lineForwardProfile);
			if (_seqFilters)
				t.push(_seqFilters);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAppointmentForwardEntry | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAppointmentForwardEntry");

		let t: ENetROSEInterface.AsnAppointmentForwardEntry | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAppointmentForwardEntry.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnAppointmentForwardEntry["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iAppointmentContactState", "Integer", errors, newContext);
			const _lineforwardprofile = AsnLineForwardProfile_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "lineForwardProfile"), errors, newContext, "lineForwardProfile");
			if (_lineforwardprofile)
				t.lineForwardProfile = _lineforwardprofile;
			t.seqFilters = AsnActionFilters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqFilters"), errors, newContext, "seqFilters", true);
		}

		if (errors.validateResult(newContext, "AsnAppointmentForwardEntry"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAppointmentForwardEntries_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAppointmentForwardEntries, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAppointmentForwardEntries | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAppointmentForwardEntries");

		const t = [] as ENetROSEInterface.AsnAppointmentForwardEntries;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnAppointmentForwardEntry_Converter.toJSON(se, errors, newContext, "AsnAppointmentForwardEntry");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnAppointmentForwardEntries"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAppointmentForwardEntries | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAppointmentForwardEntries");

		let t: ENetROSEInterface.AsnAppointmentForwardEntries | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAppointmentForwardEntries>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnAppointmentForwardEntries();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnAppointmentForwardEntry_Converter.fromJSON(se, errors, newContext, "AsnAppointmentForwardEntry", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnAppointmentForwardEntries"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAppointmentForwardEntries | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAppointmentForwardEntries";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAppointmentForwardEntries");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnAppointmentForwardEntry_Converter.toBER(s[id], errors, newContext, "AsnAppointmentForwardEntry");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnAppointmentForwardEntry"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAppointmentForwardEntries | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAppointmentForwardEntries");

		let t: ENetROSEInterface.AsnAppointmentForwardEntries | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAppointmentForwardEntries.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnAppointmentForwardEntries();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnAppointmentForwardEntry_Converter.fromBER(se, errors, newContext, "AsnAppointmentForwardEntry", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnAppointmentForwardEntries"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetAppointmentForwardsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetAppointmentForwardsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetAppointmentForwardsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetAppointmentForwardsArgument");

		const t = {} as ENetROSEInterface.AsnSetAppointmentForwardsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetAppointmentForwardsArgument";
		const _seqAppointmentForwardEntries = AsnAppointmentForwardEntries_Converter.toJSON(s.seqAppointmentForwardEntries, errors, newContext, "seqAppointmentForwardEntries");
		if (_seqAppointmentForwardEntries)
			t.seqAppointmentForwardEntries = _seqAppointmentForwardEntries;

		if (errors.validateResult(newContext, "AsnSetAppointmentForwardsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetAppointmentForwardsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetAppointmentForwardsArgument");

		let t: ENetROSEInterface.AsnSetAppointmentForwardsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetAppointmentForwardsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetAppointmentForwardsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqappointmentforwardentries = AsnAppointmentForwardEntries_Converter.fromJSON(s.seqAppointmentForwardEntries, errors, newContext, "seqAppointmentForwardEntries", false);
			if (_seqappointmentforwardentries)
				t.seqAppointmentForwardEntries = _seqappointmentforwardentries;
		}
		if (errors.validateResult(newContext, "AsnSetAppointmentForwardsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetAppointmentForwardsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetAppointmentForwardsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetAppointmentForwardsArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _seqAppointmentForwardEntries = AsnAppointmentForwardEntries_Converter.toBER(s.seqAppointmentForwardEntries, errors, newContext, "seqAppointmentForwardEntries");
		if (errors.validateResult(newContext, "AsnSetAppointmentForwardsArgument")) {
			if (_seqAppointmentForwardEntries)
				t.push(_seqAppointmentForwardEntries);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetAppointmentForwardsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetAppointmentForwardsArgument");

		let t: ENetROSEInterface.AsnSetAppointmentForwardsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetAppointmentForwardsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetAppointmentForwardsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqappointmentforwardentries = AsnAppointmentForwardEntries_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqAppointmentForwardEntries"), errors, newContext, "seqAppointmentForwardEntries");
			if (_seqappointmentforwardentries)
				t.seqAppointmentForwardEntries = _seqappointmentforwardentries;
		}

		if (errors.validateResult(newContext, "AsnSetAppointmentForwardsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetAppointmentForwardsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetAppointmentForwardsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetAppointmentForwardsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAppointmentForwardsResult");

		const t = {} as ENetROSEInterface.AsnGetAppointmentForwardsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetAppointmentForwardsResult";
		const _seqAppointmentForwardEntries = AsnAppointmentForwardEntries_Converter.toJSON(s.seqAppointmentForwardEntries, errors, newContext, "seqAppointmentForwardEntries");
		if (_seqAppointmentForwardEntries)
			t.seqAppointmentForwardEntries = _seqAppointmentForwardEntries;

		if (errors.validateResult(newContext, "AsnGetAppointmentForwardsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetAppointmentForwardsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAppointmentForwardsResult");

		let t: ENetROSEInterface.AsnGetAppointmentForwardsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetAppointmentForwardsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetAppointmentForwardsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqappointmentforwardentries = AsnAppointmentForwardEntries_Converter.fromJSON(s.seqAppointmentForwardEntries, errors, newContext, "seqAppointmentForwardEntries", false);
			if (_seqappointmentforwardentries)
				t.seqAppointmentForwardEntries = _seqappointmentforwardentries;
		}
		if (errors.validateResult(newContext, "AsnGetAppointmentForwardsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetAppointmentForwardsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetAppointmentForwardsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetAppointmentForwardsResult");

		// [Print_BER_EncoderSeqDefCode]
		const _seqAppointmentForwardEntries = AsnAppointmentForwardEntries_Converter.toBER(s.seqAppointmentForwardEntries, errors, newContext, "seqAppointmentForwardEntries");
		if (errors.validateResult(newContext, "AsnGetAppointmentForwardsResult")) {
			if (_seqAppointmentForwardEntries)
				t.push(_seqAppointmentForwardEntries);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetAppointmentForwardsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetAppointmentForwardsResult");

		let t: ENetROSEInterface.AsnGetAppointmentForwardsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetAppointmentForwardsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetAppointmentForwardsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqappointmentforwardentries = AsnAppointmentForwardEntries_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqAppointmentForwardEntries"), errors, newContext, "seqAppointmentForwardEntries");
			if (_seqappointmentforwardentries)
				t.seqAppointmentForwardEntries = _seqappointmentforwardentries;
		}

		if (errors.validateResult(newContext, "AsnGetAppointmentForwardsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactIDs_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactIDs, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactIDs | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactIDs");

		const t = [] as ENetROSEInterface.AsnContactIDs;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const se of s) {
			if (TSConverter.validateParamType(se, "AsnContactIDs", "string", errors, newContext, false))
				t.push(se);
		}

		if (errors.validateResult(newContext, "AsnContactIDs"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactIDs | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactIDs");

		let t: ENetROSEInterface.AsnContactIDs | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactIDs>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnContactIDs();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const se of s) {
				if (TSConverter.validateParamType(se, "AsnContactIDs", "string", errors, newContext, false))
					t.push(se);
			}
		}
		if (errors.validateResult(newContext, "AsnContactIDs"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactIDs | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactIDs";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactIDs");

		// [Print_BER_EncoderSetOfDefCode]
		for (const se of s) {
			if (TSConverter.validateParamType(se, "AsnContactIDs", "string", errors, newContext, false))
				t.push(new asn1ts.Utf8String({ value: se }));
		}
		if (errors.validateResult(newContext, "AsnContactID"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactIDs | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactIDs");

		let t: ENetROSEInterface.AsnContactIDs | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactIDs.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnContactIDs();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Utf8String.typeGuard(se)) {
					const value = se.getValue();
					if (TSConverter.validateParamType(value, "AsnContactIDs", "string", errors, newContext, false))
						t.push(value);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnContactIDs"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCloseContactsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnCloseContactsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnCloseContactsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCloseContactsArgument");

		const t = {} as ENetROSEInterface.AsnCloseContactsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCloseContactsArgument";
		const _seqContactIDs = AsnContactIDs_Converter.toJSON(s.seqContactIDs, errors, newContext, "seqContactIDs");
		if (_seqContactIDs)
			t.seqContactIDs = _seqContactIDs;

		if (errors.validateResult(newContext, "AsnCloseContactsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnCloseContactsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCloseContactsArgument");

		let t: ENetROSEInterface.AsnCloseContactsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnCloseContactsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnCloseContactsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqcontactids = AsnContactIDs_Converter.fromJSON(s.seqContactIDs, errors, newContext, "seqContactIDs", false);
			if (_seqcontactids)
				t.seqContactIDs = _seqcontactids;
		}
		if (errors.validateResult(newContext, "AsnCloseContactsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnCloseContactsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCloseContactsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCloseContactsArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _seqContactIDs = AsnContactIDs_Converter.toBER(s.seqContactIDs, errors, newContext, "seqContactIDs");
		if (errors.validateResult(newContext, "AsnCloseContactsArgument")) {
			if (_seqContactIDs)
				t.push(_seqContactIDs);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnCloseContactsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCloseContactsArgument");

		let t: ENetROSEInterface.AsnCloseContactsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnCloseContactsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnCloseContactsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqcontactids = AsnContactIDs_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqContactIDs"), errors, newContext, "seqContactIDs");
			if (_seqcontactids)
				t.seqContactIDs = _seqcontactids;
		}

		if (errors.validateResult(newContext, "AsnCloseContactsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSettingsDynamicRightsChangedArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSettingsDynamicRightsChangedArgument");

		// [Print_JSON_EncoderLocalTypeRef]
		const t = AsnSetSettingsDynamicRightsArgument_Converter.toJSON(s, errors, newContext, name);

		if (errors.validateResult(newContext, "AsnSettingsDynamicRightsChangedArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSettingsDynamicRightsChangedArgument");

		let t: ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument>(data, errors, newContext, optional);
		if (s) {
			// [Print_JSON_DecoderLocalTypeRef]
			t = AsnSetSettingsDynamicRightsArgument_Converter.fromJSON(s, errors, newContext, name, optional);
		}
		if (errors.validateResult(newContext, "AsnSettingsDynamicRightsChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSettingsDynamicRightsChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSettingsDynamicRightsChangedArgument");

		// [Print_BER_EncoderLocalTypeRef]
		const v = AsnSetSettingsDynamicRightsArgument_Converter.toBER(s, errors, newContext, name, optional);
		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsArgument")) {
			if (v)
				t.push(v);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSettingsDynamicRightsChangedArgument");

		let t: ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			// [Print_BER_DecoderLocalTypeRef]
			t = AsnSetSettingsDynamicRightsArgument_Converter.fromBER(s, errors, newContext, name, optional);
		}

		if (errors.validateResult(newContext, "AsnSettingsDynamicRightsChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSettingsDynamicGroupRightsChangedArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSettingsDynamicGroupRightsChangedArgument");

		// [Print_JSON_EncoderLocalTypeRef]
		const t = AsnSetSettingsDynamicRightsArgument_Converter.toJSON(s, errors, newContext, name);

		if (errors.validateResult(newContext, "AsnSettingsDynamicGroupRightsChangedArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSettingsDynamicGroupRightsChangedArgument");

		let t: ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument>(data, errors, newContext, optional);
		if (s) {
			// [Print_JSON_DecoderLocalTypeRef]
			t = AsnSetSettingsDynamicRightsArgument_Converter.fromJSON(s, errors, newContext, name, optional);
		}
		if (errors.validateResult(newContext, "AsnSettingsDynamicGroupRightsChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSettingsDynamicGroupRightsChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSettingsDynamicGroupRightsChangedArgument");

		// [Print_BER_EncoderLocalTypeRef]
		const v = AsnSetSettingsDynamicRightsArgument_Converter.toBER(s, errors, newContext, name, optional);
		if (errors.validateResult(newContext, "AsnSetSettingsDynamicRightsArgument")) {
			if (v)
				t.push(v);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSettingsDynamicGroupRightsChangedArgument");

		let t: ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			// [Print_BER_DecoderLocalTypeRef]
			t = AsnSetSettingsDynamicRightsArgument_Converter.fromBER(s, errors, newContext, name, optional);
		}

		if (errors.validateResult(newContext, "AsnSettingsDynamicGroupRightsChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetSettingsDynamicGroupRightsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsDynamicGroupRightsArgument");

		// [Print_JSON_EncoderLocalTypeRef]
		const t = AsnGetSettingsDynamicRightsArgument_Converter.toJSON(s, errors, newContext, name);

		if (errors.validateResult(newContext, "AsnGetSettingsDynamicGroupRightsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsDynamicGroupRightsArgument");

		let t: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument>(data, errors, newContext, optional);
		if (s) {
			// [Print_JSON_DecoderLocalTypeRef]
			t = AsnGetSettingsDynamicRightsArgument_Converter.fromJSON(s, errors, newContext, name, optional);
		}
		if (errors.validateResult(newContext, "AsnGetSettingsDynamicGroupRightsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetSettingsDynamicGroupRightsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsDynamicGroupRightsArgument");

		// [Print_BER_EncoderLocalTypeRef]
		const v = AsnGetSettingsDynamicRightsArgument_Converter.toBER(s, errors, newContext, name, optional);
		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsArgument")) {
			if (v)
				t.push(v);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsDynamicGroupRightsArgument");

		let t: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			// [Print_BER_DecoderLocalTypeRef]
			t = AsnGetSettingsDynamicRightsArgument_Converter.fromBER(s, errors, newContext, name, optional);
		}

		if (errors.validateResult(newContext, "AsnGetSettingsDynamicGroupRightsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetSettingsDynamicGroupRightsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsDynamicGroupRightsResult");

		// [Print_JSON_EncoderLocalTypeRef]
		const t = AsnGetSettingsDynamicRightsResult_Converter.toJSON(s, errors, newContext, name);

		if (errors.validateResult(newContext, "AsnGetSettingsDynamicGroupRightsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsDynamicGroupRightsResult");

		let t: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult>(data, errors, newContext, optional);
		if (s) {
			// [Print_JSON_DecoderLocalTypeRef]
			t = AsnGetSettingsDynamicRightsResult_Converter.fromJSON(s, errors, newContext, name, optional);
		}
		if (errors.validateResult(newContext, "AsnGetSettingsDynamicGroupRightsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetSettingsDynamicGroupRightsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsDynamicGroupRightsResult");

		// [Print_BER_EncoderLocalTypeRef]
		const v = AsnGetSettingsDynamicRightsResult_Converter.toBER(s, errors, newContext, name, optional);
		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsResult")) {
			if (v)
				t.push(v);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsDynamicGroupRightsResult");

		let t: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			// [Print_BER_DecoderLocalTypeRef]
			t = AsnGetSettingsDynamicRightsResult_Converter.fromBER(s, errors, newContext, name, optional);
		}

		if (errors.validateResult(newContext, "AsnGetSettingsDynamicGroupRightsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetSettingsGlobalDynamicRightsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsGlobalDynamicRightsArgument");

		// [Print_JSON_EncoderLocalTypeRef]
		const t = AsnGetSettingsDynamicRightsArgument_Converter.toJSON(s, errors, newContext, name);

		if (errors.validateResult(newContext, "AsnGetSettingsGlobalDynamicRightsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsGlobalDynamicRightsArgument");

		let t: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument>(data, errors, newContext, optional);
		if (s) {
			// [Print_JSON_DecoderLocalTypeRef]
			t = AsnGetSettingsDynamicRightsArgument_Converter.fromJSON(s, errors, newContext, name, optional);
		}
		if (errors.validateResult(newContext, "AsnGetSettingsGlobalDynamicRightsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetSettingsGlobalDynamicRightsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetSettingsGlobalDynamicRightsArgument");

		// [Print_BER_EncoderLocalTypeRef]
		const v = AsnGetSettingsDynamicRightsArgument_Converter.toBER(s, errors, newContext, name, optional);
		if (errors.validateResult(newContext, "AsnGetSettingsDynamicRightsArgument")) {
			if (v)
				t.push(v);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetSettingsGlobalDynamicRightsArgument");

		let t: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			// [Print_BER_DecoderLocalTypeRef]
			t = AsnGetSettingsDynamicRightsArgument_Converter.fromBER(s, errors, newContext, name, optional);
		}

		if (errors.validateResult(newContext, "AsnGetSettingsGlobalDynamicRightsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPresenceState_Converter {
	public static toJSON(s: ENetROSEInterface.AsnPresenceState, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnPresenceState & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceState");

		const t = {} as ENetROSEInterface.AsnPresenceState & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnPresenceState";
		TSConverter.fillJSONParam(s, t, "contactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iContactFeatureSet", "number", errors, newContext);
		const _asnRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.asnRemoteContact, errors, newContext, "asnRemoteContact");
		if (_asnRemoteContact)
			t.asnRemoteContact = _asnRemoteContact;
		TSConverter.fillJSONParam(s, t, "iContactConnectionState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAdditionalConnectionInfo", "string", errors, newContext);
		if (s.seqPhoneLines) {
			const _seqPhoneLines = ENetUC_Common_AsnContact_Converter.AsnContactPhoneLines_Converter.toJSON(s.seqPhoneLines, errors, newContext, "seqPhoneLines");
			if (_seqPhoneLines)
				t.seqPhoneLines = _seqPhoneLines;
		}
		TSConverter.fillJSONParam(s, t, "utcAbsentStartTime", "Date", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "utcAbsentEndTime", "Date", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sAbsentReason", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sAbsentMessage", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sNote", "string", errors, newContext, true);
		if (s.appointmentEntryList) {
			const _appointmentEntryList = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(s.appointmentEntryList, errors, newContext, "appointmentEntryList");
			if (_appointmentEntryList)
				t.appointmentEntryList = _appointmentEntryList;
		}
		if (s.asnNextAppointment) {
			const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment");
			if (_asnNextAppointment)
				t.asnNextAppointment = _asnNextAppointment;
		}

		if (errors.validateResult(newContext, "AsnPresenceState"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnPresenceState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceState");

		let t: ENetROSEInterface.AsnPresenceState | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnPresenceState>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnPresenceState["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "contactID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iContactFeatureSet", "number", errors, context, false);
			const _asnremotecontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.asnRemoteContact, errors, newContext, "asnRemoteContact", false);
			if (_asnremotecontact)
				t.asnRemoteContact = _asnremotecontact;
			TSConverter.fillJSONParam(s, t, "iContactConnectionState", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sAdditionalConnectionInfo", "string", errors, context, false);
			t.seqPhoneLines = ENetUC_Common_AsnContact_Converter.AsnContactPhoneLines_Converter.fromJSON(s.seqPhoneLines, errors, newContext, "seqPhoneLines", true);
			if (TSConverter.validateParam(s, "utcAbsentStartTime", "string", errors, context, true) && s.utcAbsentStartTime)
				t.utcAbsentStartTime = new Date(s.utcAbsentStartTime);
			if (TSConverter.validateParam(s, "utcAbsentEndTime", "string", errors, context, true) && s.utcAbsentEndTime)
				t.utcAbsentEndTime = new Date(s.utcAbsentEndTime);
			TSConverter.fillJSONParam(s, t, "u8sAbsentReason", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sAbsentMessage", "string", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sNote", "string", errors, context, true);
			t.appointmentEntryList = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(s.appointmentEntryList, errors, newContext, "appointmentEntryList", true);
			t.asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment", true);
		}
		if (errors.validateResult(newContext, "AsnPresenceState"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnPresenceState | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPresenceState";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPresenceState");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "contactID", "string", errors, newContext);
		TSConverter.validateParam(s, "iPresenceState", "number", errors, newContext);
		TSConverter.validateParam(s, "iContactFeatureSet", "number", errors, newContext);
		const _asnRemoteContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.asnRemoteContact, errors, newContext, "asnRemoteContact");
		TSConverter.validateParam(s, "iContactConnectionState", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sAdditionalConnectionInfo", "string", errors, newContext);
		const _seqPhoneLines = ENetUC_Common_AsnContact_Converter.AsnContactPhoneLines_Converter.toBER(s.seqPhoneLines, errors, newContext, "seqPhoneLines", 0);
		TSConverter.validateParam(s, "utcAbsentStartTime", "Date", errors, newContext, true);
		TSConverter.validateParam(s, "utcAbsentEndTime", "Date", errors, newContext, true);
		TSConverter.validateParam(s, "u8sAbsentReason", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sAbsentMessage", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sNote", "string", errors, newContext, true);
		const _appointmentEntryList = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toBER(s.appointmentEntryList, errors, newContext, "appointmentEntryList", 6);
		const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toBER(s.asnNextAppointment, errors, newContext, "asnNextAppointment", 7);
		if (errors.validateResult(newContext, "AsnPresenceState")) {
			t.push(new asn1ts.Utf8String({ value: s.contactID, name: "contactID" }));
			t.push(new asn1ts.Integer({ value: s.iPresenceState, name: "iPresenceState" }));
			t.push(new asn1ts.Integer({ value: s.iContactFeatureSet, name: "iContactFeatureSet" }));
			if (_asnRemoteContact)
				t.push(_asnRemoteContact);
			t.push(new asn1ts.Integer({ value: s.iContactConnectionState, name: "iContactConnectionState" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAdditionalConnectionInfo, name: "u8sAdditionalConnectionInfo" }));
			if (_seqPhoneLines)
				t.push(_seqPhoneLines);
			if (s.utcAbsentStartTime !== undefined)
				t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcAbsentStartTime), name: "utcAbsentStartTime", idBlock: { optionalID: 1 } }));
			if (s.utcAbsentEndTime !== undefined)
				t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcAbsentEndTime), name: "utcAbsentEndTime", idBlock: { optionalID: 2 } }));
			if (s.u8sAbsentReason !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAbsentReason, name: "u8sAbsentReason", idBlock: { optionalID: 3 } }));
			if (s.u8sAbsentMessage !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sAbsentMessage, name: "u8sAbsentMessage", idBlock: { optionalID: 4 } }));
			if (s.u8sNote !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sNote, name: "u8sNote", idBlock: { optionalID: 5 } }));
			if (_appointmentEntryList)
				t.push(_appointmentEntryList);
			if (_asnNextAppointment)
				t.push(_asnNextAppointment);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnPresenceState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPresenceState");

		let t: ENetROSEInterface.AsnPresenceState | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnPresenceState.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnPresenceState["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "contactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iPresenceState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iContactFeatureSet", "Integer", errors, newContext);
			const _asnremotecontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnRemoteContact"), errors, newContext, "asnRemoteContact");
			if (_asnremotecontact)
				t.asnRemoteContact = _asnremotecontact;
			TSConverter.fillASN1Param(s, t, "iContactConnectionState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAdditionalConnectionInfo", "Utf8String", errors, newContext);
			t.seqPhoneLines = ENetUC_Common_AsnContact_Converter.AsnContactPhoneLines_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqPhoneLines"), errors, newContext, "seqPhoneLines", true);
			TSConverter.fillASN1Param(s, t, "utcAbsentStartTime", "AsnSystemTime", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "utcAbsentEndTime", "AsnSystemTime", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sAbsentReason", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sAbsentMessage", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sNote", "Utf8String", errors, newContext, true);
			t.appointmentEntryList = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "appointmentEntryList"), errors, newContext, "appointmentEntryList", true);
			t.asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnNextAppointment"), errors, newContext, "asnNextAppointment", true);
		}

		if (errors.validateResult(newContext, "AsnPresenceState"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactAbsentState_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactAbsentState, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactAbsentState & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactAbsentState");

		const t = {} as ENetROSEInterface.AsnContactAbsentState & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactAbsentState";
		TSConverter.fillJSONParam(s, t, "contactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "utcAbsentStartTime", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "utcAbsentEndTime", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAbsentReason", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sAbsentMessage", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnContactAbsentState"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactAbsentState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactAbsentState");

		let t: ENetROSEInterface.AsnContactAbsentState | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactAbsentState>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactAbsentState["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "contactID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, context, false);
			if (TSConverter.validateParam(s, "utcAbsentStartTime", "string", errors, context, false))
				t.utcAbsentStartTime = new Date(s.utcAbsentStartTime);
			if (TSConverter.validateParam(s, "utcAbsentEndTime", "string", errors, context, false))
				t.utcAbsentEndTime = new Date(s.utcAbsentEndTime);
			TSConverter.fillJSONParam(s, t, "u8sAbsentReason", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sAbsentMessage", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnContactAbsentState"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactAbsentState | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactAbsentState";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactAbsentState");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "contactID", "string", errors, newContext);
		TSConverter.validateParam(s, "iPresenceState", "number", errors, newContext);
		TSConverter.validateParam(s, "utcAbsentStartTime", "Date", errors, newContext);
		TSConverter.validateParam(s, "utcAbsentEndTime", "Date", errors, newContext);
		TSConverter.validateParam(s, "u8sAbsentReason", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sAbsentMessage", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnContactAbsentState")) {
			t.push(new asn1ts.Utf8String({ value: s.contactID, name: "contactID" }));
			t.push(new asn1ts.Integer({ value: s.iPresenceState, name: "iPresenceState" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcAbsentStartTime), name: "utcAbsentStartTime" }));
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.utcAbsentEndTime), name: "utcAbsentEndTime" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAbsentReason, name: "u8sAbsentReason" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sAbsentMessage, name: "u8sAbsentMessage" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactAbsentState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactAbsentState");

		let t: ENetROSEInterface.AsnContactAbsentState | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactAbsentState.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactAbsentState["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "contactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iPresenceState", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "utcAbsentStartTime", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "utcAbsentEndTime", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAbsentReason", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sAbsentMessage", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnContactAbsentState"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactAppointmentState_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactAppointmentState, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactAppointmentState & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactAppointmentState");

		const t = {} as ENetROSEInterface.AsnContactAppointmentState & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactAppointmentState";
		TSConverter.fillJSONParam(s, t, "contactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext);
		const _appointmentEntryList = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(s.appointmentEntryList, errors, newContext, "appointmentEntryList");
		if (_appointmentEntryList)
			t.appointmentEntryList = _appointmentEntryList;
		const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment");
		if (_asnNextAppointment)
			t.asnNextAppointment = _asnNextAppointment;

		if (errors.validateResult(newContext, "AsnContactAppointmentState"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactAppointmentState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactAppointmentState");

		let t: ENetROSEInterface.AsnContactAppointmentState | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactAppointmentState>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactAppointmentState["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "contactID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, context, false);
			const _appointmententrylist = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(s.appointmentEntryList, errors, newContext, "appointmentEntryList", false);
			if (_appointmententrylist)
				t.appointmentEntryList = _appointmententrylist;
			const _asnnextappointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromJSON(s.asnNextAppointment, errors, newContext, "asnNextAppointment", false);
			if (_asnnextappointment)
				t.asnNextAppointment = _asnnextappointment;
		}
		if (errors.validateResult(newContext, "AsnContactAppointmentState"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactAppointmentState | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactAppointmentState";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactAppointmentState");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "contactID", "string", errors, newContext);
		TSConverter.validateParam(s, "iPresenceState", "number", errors, newContext);
		const _appointmentEntryList = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toBER(s.appointmentEntryList, errors, newContext, "appointmentEntryList");
		const _asnNextAppointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toBER(s.asnNextAppointment, errors, newContext, "asnNextAppointment");
		if (errors.validateResult(newContext, "AsnContactAppointmentState")) {
			t.push(new asn1ts.Utf8String({ value: s.contactID, name: "contactID" }));
			t.push(new asn1ts.Integer({ value: s.iPresenceState, name: "iPresenceState" }));
			if (_appointmentEntryList)
				t.push(_appointmentEntryList);
			if (_asnNextAppointment)
				t.push(_asnNextAppointment);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactAppointmentState | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactAppointmentState");

		let t: ENetROSEInterface.AsnContactAppointmentState | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactAppointmentState.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactAppointmentState["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "contactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iPresenceState", "Integer", errors, newContext);
			const _appointmententrylist = ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "appointmentEntryList"), errors, newContext, "appointmentEntryList");
			if (_appointmententrylist)
				t.appointmentEntryList = _appointmententrylist;
			const _asnnextappointment = ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnNextAppointment"), errors, newContext, "asnNextAppointment");
			if (_asnnextappointment)
				t.asnNextAppointment = _asnnextappointment;
		}

		if (errors.validateResult(newContext, "AsnContactAppointmentState"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactCallInformationChangedArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactCallInformationChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactCallInformationChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactCallInformationChangedArgument");

		const t = {} as ENetROSEInterface.AsnContactCallInformationChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactCallInformationChangedArgument";
		TSConverter.fillJSONParam(s, t, "contactID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, newContext);
		const _callinfo = ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.toJSON(s.callinfo, errors, newContext, "callinfo");
		if (_callinfo)
			t.callinfo = _callinfo;

		if (errors.validateResult(newContext, "AsnContactCallInformationChangedArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactCallInformationChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactCallInformationChangedArgument");

		let t: ENetROSEInterface.AsnContactCallInformationChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactCallInformationChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactCallInformationChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "contactID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iPresenceState", "number", errors, context, false);
			const _callinfo = ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.fromJSON(s.callinfo, errors, newContext, "callinfo", false);
			if (_callinfo)
				t.callinfo = _callinfo;
		}
		if (errors.validateResult(newContext, "AsnContactCallInformationChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactCallInformationChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactCallInformationChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactCallInformationChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "contactID", "string", errors, newContext);
		TSConverter.validateParam(s, "iPresenceState", "number", errors, newContext);
		const _callinfo = ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.toBER(s.callinfo, errors, newContext, "callinfo");
		if (errors.validateResult(newContext, "AsnContactCallInformationChangedArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.contactID, name: "contactID" }));
			t.push(new asn1ts.Integer({ value: s.iPresenceState, name: "iPresenceState" }));
			if (_callinfo)
				t.push(_callinfo);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactCallInformationChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactCallInformationChangedArgument");

		let t: ENetROSEInterface.AsnContactCallInformationChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactCallInformationChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactCallInformationChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "contactID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iPresenceState", "Integer", errors, newContext);
			const _callinfo = ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "callinfo"), errors, newContext, "callinfo");
			if (_callinfo)
				t.callinfo = _callinfo;
		}

		if (errors.validateResult(newContext, "AsnContactCallInformationChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUpdateMyPhoneForwardStateArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyPhoneForwardStateArgument");

		// [Print_JSON_EncoderLocalTypeRef]
		const t = AsnNotifyLineForwardStateArgument_Converter.toJSON(s, errors, newContext, name);

		if (errors.validateResult(newContext, "AsnUpdateMyPhoneForwardStateArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyPhoneForwardStateArgument");

		let t: ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument>(data, errors, newContext, optional);
		if (s) {
			// [Print_JSON_DecoderLocalTypeRef]
			t = AsnNotifyLineForwardStateArgument_Converter.fromJSON(s, errors, newContext, name, optional);
		}
		if (errors.validateResult(newContext, "AsnUpdateMyPhoneForwardStateArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUpdateMyPhoneForwardStateArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUpdateMyPhoneForwardStateArgument");

		// [Print_BER_EncoderLocalTypeRef]
		const v = AsnNotifyLineForwardStateArgument_Converter.toBER(s, errors, newContext, name, optional);
		if (errors.validateResult(newContext, "AsnNotifyLineForwardStateArgument")) {
			if (v)
				t.push(v);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUpdateMyPhoneForwardStateArgument");

		let t: ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			// [Print_BER_DecoderLocalTypeRef]
			t = AsnNotifyLineForwardStateArgument_Converter.fromBER(s, errors, newContext, name, optional);
		}

		if (errors.validateResult(newContext, "AsnUpdateMyPhoneForwardStateArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactPhoneForwardStateChangedArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactPhoneForwardStateChangedArgument");

		// [Print_JSON_EncoderLocalTypeRef]
		const t = AsnNotifyLineForwardStateArgument_Converter.toJSON(s, errors, newContext, name);

		if (errors.validateResult(newContext, "AsnContactPhoneForwardStateChangedArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactPhoneForwardStateChangedArgument");

		let t: ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument>(data, errors, newContext, optional);
		if (s) {
			// [Print_JSON_DecoderLocalTypeRef]
			t = AsnNotifyLineForwardStateArgument_Converter.fromJSON(s, errors, newContext, name, optional);
		}
		if (errors.validateResult(newContext, "AsnContactPhoneForwardStateChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactPhoneForwardStateChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactPhoneForwardStateChangedArgument");

		// [Print_BER_EncoderLocalTypeRef]
		const v = AsnNotifyLineForwardStateArgument_Converter.toBER(s, errors, newContext, name, optional);
		if (errors.validateResult(newContext, "AsnNotifyLineForwardStateArgument")) {
			if (v)
				t.push(v);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactPhoneForwardStateChangedArgument");

		let t: ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			// [Print_BER_DecoderLocalTypeRef]
			t = AsnNotifyLineForwardStateArgument_Converter.fromBER(s, errors, newContext, name, optional);
		}

		if (errors.validateResult(newContext, "AsnContactPhoneForwardStateChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnClientInfoList_Converter {
	public static toJSON(s: ENetROSEInterface.AsnClientInfoList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnClientInfoList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientInfoList");

		const t = [] as ENetROSEInterface.AsnClientInfoList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnClientInfo_Converter.toJSON(se, errors, newContext, "AsnClientInfo");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnClientInfoList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnClientInfoList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientInfoList");

		let t: ENetROSEInterface.AsnClientInfoList | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnClientInfoList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnClientInfoList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnClientInfo_Converter.fromJSON(se, errors, newContext, "AsnClientInfo", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnClientInfoList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnClientInfoList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnClientInfoList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnClientInfoList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnClientInfo_Converter.toBER(s[id], errors, newContext, "AsnClientInfo");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnClientInfo"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnClientInfoList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnClientInfoList");

		let t: ENetROSEInterface.AsnClientInfoList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnClientInfoList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnClientInfoList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnClientInfo_Converter.fromBER(se, errors, newContext, "AsnClientInfo", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnClientInfoList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMyUpdateConnectedClientListArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnMyUpdateConnectedClientListArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnMyUpdateConnectedClientListArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMyUpdateConnectedClientListArgument");

		const t = {} as ENetROSEInterface.AsnMyUpdateConnectedClientListArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMyUpdateConnectedClientListArgument";
		const _listClientInfos = AsnClientInfoList_Converter.toJSON(s.listClientInfos, errors, newContext, "listClientInfos");
		if (_listClientInfos)
			t.listClientInfos = _listClientInfos;

		if (errors.validateResult(newContext, "AsnMyUpdateConnectedClientListArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnMyUpdateConnectedClientListArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMyUpdateConnectedClientListArgument");

		let t: ENetROSEInterface.AsnMyUpdateConnectedClientListArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnMyUpdateConnectedClientListArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnMyUpdateConnectedClientListArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _listclientinfos = AsnClientInfoList_Converter.fromJSON(s.listClientInfos, errors, newContext, "listClientInfos", false);
			if (_listclientinfos)
				t.listClientInfos = _listclientinfos;
		}
		if (errors.validateResult(newContext, "AsnMyUpdateConnectedClientListArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnMyUpdateConnectedClientListArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMyUpdateConnectedClientListArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMyUpdateConnectedClientListArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _listClientInfos = AsnClientInfoList_Converter.toBER(s.listClientInfos, errors, newContext, "listClientInfos");
		if (errors.validateResult(newContext, "AsnMyUpdateConnectedClientListArgument")) {
			if (_listClientInfos)
				t.push(_listClientInfos);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnMyUpdateConnectedClientListArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMyUpdateConnectedClientListArgument");

		let t: ENetROSEInterface.AsnMyUpdateConnectedClientListArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnMyUpdateConnectedClientListArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnMyUpdateConnectedClientListArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _listclientinfos = AsnClientInfoList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listClientInfos"), errors, newContext, "listClientInfos");
			if (_listclientinfos)
				t.listClientInfos = _listclientinfos;
		}

		if (errors.validateResult(newContext, "AsnMyUpdateConnectedClientListArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAbsentStateGetUserArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAbsentStateGetUserArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAbsentStateGetUserArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateGetUserArgument");

		const t = {} as ENetROSEInterface.AsnAbsentStateGetUserArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnAbsentStateGetUserArgument";
		TSConverter.fillJSONParam(s, t, "contactId", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnAbsentStateGetUserArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateGetUserArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateGetUserArgument");

		let t: ENetROSEInterface.AsnAbsentStateGetUserArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAbsentStateGetUserArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnAbsentStateGetUserArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "contactId", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnAbsentStateGetUserArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAbsentStateGetUserArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAbsentStateGetUserArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStateGetUserArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "contactId", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnAbsentStateGetUserArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.contactId, name: "contactId" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStateGetUserArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStateGetUserArgument");

		let t: ENetROSEInterface.AsnAbsentStateGetUserArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAbsentStateGetUserArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnAbsentStateGetUserArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "contactId", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnAbsentStateGetUserArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetMetaSettingsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetMetaSettingsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetMetaSettingsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetMetaSettingsArgument");

		const t = {} as ENetROSEInterface.AsnGetMetaSettingsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetMetaSettingsArgument";
		TSConverter.fillJSONParam(s, t, "eSendPlainPassword", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetMetaSettingsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetMetaSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetMetaSettingsArgument");

		let t: ENetROSEInterface.AsnGetMetaSettingsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetMetaSettingsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetMetaSettingsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "eSendPlainPassword", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnGetMetaSettingsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetMetaSettingsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetMetaSettingsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetMetaSettingsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "eSendPlainPassword", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnGetMetaSettingsArgument")) {
			t.push(new asn1ts.Enumerated({ value: s.eSendPlainPassword, name: "eSendPlainPassword" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetMetaSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetMetaSettingsArgument");

		let t: ENetROSEInterface.AsnGetMetaSettingsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetMetaSettingsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetMetaSettingsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "eSendPlainPassword", "Enumerated", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetMetaSettingsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMetaDirectorySettings_Converter {
	public static toJSON(s: ENetROSEInterface.AsnMetaDirectorySettings, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnMetaDirectorySettings & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMetaDirectorySettings");

		const t = {} as ENetROSEInterface.AsnMetaDirectorySettings & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMetaDirectorySettings";
		TSConverter.fillJSONParam(s, t, "iMetaEnabled", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iMetaPort", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMetaServer", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sBaseDN", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iMetaForceSSL", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iMetaUseAccount", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMetaUser", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "ePasswordEncryption", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMetaPassword", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iMetaUseInServer", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iMetaUseInClient", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnMetaDirectorySettings"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnMetaDirectorySettings | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMetaDirectorySettings");

		let t: ENetROSEInterface.AsnMetaDirectorySettings | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnMetaDirectorySettings>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnMetaDirectorySettings["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iMetaEnabled", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iMetaPort", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sMetaServer", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sBaseDN", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iMetaForceSSL", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iMetaUseAccount", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sMetaUser", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "ePasswordEncryption", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sMetaPassword", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iMetaUseInServer", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iMetaUseInClient", "number", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnMetaDirectorySettings"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnMetaDirectorySettings | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMetaDirectorySettings";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMetaDirectorySettings");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iMetaEnabled", "number", errors, newContext);
		TSConverter.validateParam(s, "iMetaPort", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sMetaServer", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sBaseDN", "string", errors, newContext);
		TSConverter.validateParam(s, "iMetaForceSSL", "number", errors, newContext);
		TSConverter.validateParam(s, "iMetaUseAccount", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sMetaUser", "string", errors, newContext);
		TSConverter.validateParam(s, "ePasswordEncryption", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sMetaPassword", "string", errors, newContext);
		TSConverter.validateParam(s, "iMetaUseInServer", "number", errors, newContext);
		TSConverter.validateParam(s, "iMetaUseInClient", "number", errors, newContext);
		if (errors.validateResult(newContext, "AsnMetaDirectorySettings")) {
			t.push(new asn1ts.Integer({ value: s.iMetaEnabled, name: "iMetaEnabled" }));
			t.push(new asn1ts.Integer({ value: s.iMetaPort, name: "iMetaPort" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sMetaServer, name: "u8sMetaServer" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sBaseDN, name: "u8sBaseDN" }));
			t.push(new asn1ts.Integer({ value: s.iMetaForceSSL, name: "iMetaForceSSL" }));
			t.push(new asn1ts.Integer({ value: s.iMetaUseAccount, name: "iMetaUseAccount" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sMetaUser, name: "u8sMetaUser" }));
			t.push(new asn1ts.Enumerated({ value: s.ePasswordEncryption, name: "ePasswordEncryption" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sMetaPassword, name: "u8sMetaPassword" }));
			t.push(new asn1ts.Integer({ value: s.iMetaUseInServer, name: "iMetaUseInServer" }));
			t.push(new asn1ts.Integer({ value: s.iMetaUseInClient, name: "iMetaUseInClient" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnMetaDirectorySettings | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMetaDirectorySettings");

		let t: ENetROSEInterface.AsnMetaDirectorySettings | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnMetaDirectorySettings.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnMetaDirectorySettings["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iMetaEnabled", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iMetaPort", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMetaServer", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sBaseDN", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iMetaForceSSL", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iMetaUseAccount", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMetaUser", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "ePasswordEncryption", "Enumerated", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMetaPassword", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iMetaUseInServer", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iMetaUseInClient", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnMetaDirectorySettings"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetMetaSettingsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetMetaSettingsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetMetaSettingsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetMetaSettingsArgument");

		const t = {} as ENetROSEInterface.AsnSetMetaSettingsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetMetaSettingsArgument";
		TSConverter.fillJSONParam(s, t, "eSendPlainPassword", "number", errors, newContext);
		const _metasettings = AsnMetaDirectorySettings_Converter.toJSON(s.metasettings, errors, newContext, "metasettings");
		if (_metasettings)
			t.metasettings = _metasettings;

		if (errors.validateResult(newContext, "AsnSetMetaSettingsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetMetaSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetMetaSettingsArgument");

		let t: ENetROSEInterface.AsnSetMetaSettingsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetMetaSettingsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetMetaSettingsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "eSendPlainPassword", "number", errors, context, false);
			const _metasettings = AsnMetaDirectorySettings_Converter.fromJSON(s.metasettings, errors, newContext, "metasettings", false);
			if (_metasettings)
				t.metasettings = _metasettings;
		}
		if (errors.validateResult(newContext, "AsnSetMetaSettingsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetMetaSettingsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetMetaSettingsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetMetaSettingsArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "eSendPlainPassword", "number", errors, newContext);
		const _metasettings = AsnMetaDirectorySettings_Converter.toBER(s.metasettings, errors, newContext, "metasettings");
		if (errors.validateResult(newContext, "AsnSetMetaSettingsArgument")) {
			t.push(new asn1ts.Enumerated({ value: s.eSendPlainPassword, name: "eSendPlainPassword" }));
			if (_metasettings)
				t.push(_metasettings);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetMetaSettingsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetMetaSettingsArgument");

		let t: ENetROSEInterface.AsnSetMetaSettingsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetMetaSettingsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetMetaSettingsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "eSendPlainPassword", "Enumerated", errors, newContext);
			const _metasettings = AsnMetaDirectorySettings_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "metasettings"), errors, newContext, "metasettings");
			if (_metasettings)
				t.metasettings = _metasettings;
		}

		if (errors.validateResult(newContext, "AsnSetMetaSettingsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnEMailAttachmentList_Converter {
	public static toJSON(s: ENetROSEInterface.AsnEMailAttachmentList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnEMailAttachmentList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailAttachmentList");

		const t = [] as ENetROSEInterface.AsnEMailAttachmentList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnEMailAttachment_Converter.toJSON(se, errors, newContext, "AsnEMailAttachment");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnEMailAttachmentList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailAttachmentList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailAttachmentList");

		let t: ENetROSEInterface.AsnEMailAttachmentList | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnEMailAttachmentList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnEMailAttachmentList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnEMailAttachment_Converter.fromJSON(se, errors, newContext, "AsnEMailAttachment", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnEMailAttachmentList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnEMailAttachmentList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnEMailAttachmentList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailAttachmentList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnEMailAttachment_Converter.toBER(s[id], errors, newContext, "AsnEMailAttachment");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnEMailAttachment"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailAttachmentList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailAttachmentList");

		let t: ENetROSEInterface.AsnEMailAttachmentList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnEMailAttachmentList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnEMailAttachmentList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnEMailAttachment_Converter.fromBER(se, errors, newContext, "AsnEMailAttachment", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnEMailAttachmentList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnEMailPartyList_Converter {
	public static toJSON(s: ENetROSEInterface.AsnEMailPartyList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnEMailPartyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailPartyList");

		const t = [] as ENetROSEInterface.AsnEMailPartyList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnEMailParty_Converter.toJSON(se, errors, newContext, "AsnEMailParty");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnEMailPartyList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailPartyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailPartyList");

		let t: ENetROSEInterface.AsnEMailPartyList | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnEMailPartyList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnEMailPartyList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnEMailParty_Converter.fromJSON(se, errors, newContext, "AsnEMailParty", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnEMailPartyList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnEMailPartyList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnEMailPartyList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailPartyList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnEMailParty_Converter.toBER(s[id], errors, newContext, "AsnEMailParty");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnEMailParty"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailPartyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailPartyList");

		let t: ENetROSEInterface.AsnEMailPartyList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnEMailPartyList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnEMailPartyList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnEMailParty_Converter.fromBER(se, errors, newContext, "AsnEMailParty", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnEMailPartyList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnEMailBodyList_Converter {
	public static toJSON(s: ENetROSEInterface.AsnEMailBodyList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnEMailBodyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailBodyList");

		const t = [] as ENetROSEInterface.AsnEMailBodyList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnEMailBody_Converter.toJSON(se, errors, newContext, "AsnEMailBody");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnEMailBodyList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailBodyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailBodyList");

		let t: ENetROSEInterface.AsnEMailBodyList | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnEMailBodyList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnEMailBodyList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnEMailBody_Converter.fromJSON(se, errors, newContext, "AsnEMailBody", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnEMailBodyList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnEMailBodyList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnEMailBodyList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailBodyList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnEMailBody_Converter.toBER(s[id], errors, newContext, "AsnEMailBody");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnEMailBody"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailBodyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailBodyList");

		let t: ENetROSEInterface.AsnEMailBodyList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnEMailBodyList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnEMailBodyList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnEMailBody_Converter.fromBER(se, errors, newContext, "AsnEMailBody", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnEMailBodyList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnEMail_Converter {
	public static toJSON(s: ENetROSEInterface.AsnEMail, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnEMail & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMail");

		const t = {} as ENetROSEInterface.AsnEMail & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnEMail";
		const _originatorParty = AsnEMailParty_Converter.toJSON(s.originatorParty, errors, newContext, "originatorParty");
		if (_originatorParty)
			t.originatorParty = _originatorParty;
		const _recipientParties = AsnEMailPartyList_Converter.toJSON(s.recipientParties, errors, newContext, "recipientParties");
		if (_recipientParties)
			t.recipientParties = _recipientParties;
		TSConverter.fillJSONParam(s, t, "u8sSubject", "string", errors, newContext);
		const _bodyList = AsnEMailBodyList_Converter.toJSON(s.bodyList, errors, newContext, "bodyList");
		if (_bodyList)
			t.bodyList = _bodyList;
		if (s.attachmentList) {
			const _attachmentList = AsnEMailAttachmentList_Converter.toJSON(s.attachmentList, errors, newContext, "attachmentList");
			if (_attachmentList)
				t.attachmentList = _attachmentList;
		}
		if (s.returnParties) {
			const _returnParties = AsnEMailPartyList_Converter.toJSON(s.returnParties, errors, newContext, "returnParties");
			if (_returnParties)
				t.returnParties = _returnParties;
		}
		if (s.carbonCopyParties) {
			const _carbonCopyParties = AsnEMailPartyList_Converter.toJSON(s.carbonCopyParties, errors, newContext, "carbonCopyParties");
			if (_carbonCopyParties)
				t.carbonCopyParties = _carbonCopyParties;
		}
		if (s.blindCarbonParties) {
			const _blindCarbonParties = AsnEMailPartyList_Converter.toJSON(s.blindCarbonParties, errors, newContext, "blindCarbonParties");
			if (_blindCarbonParties)
				t.blindCarbonParties = _blindCarbonParties;
		}
		TSConverter.fillJSONParam(s, t, "bSendNotification", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bReadNotification", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iEmailImportance", "number", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sMessageID", "string", errors, newContext, true);
		if (s.u8sMapOptionalHeaderElements) {
			const _u8sMapOptionalHeaderElements = ENetUC_Common_Converter.AsnStringPairList_Converter.toJSON(s.u8sMapOptionalHeaderElements, errors, newContext, "u8sMapOptionalHeaderElements");
			if (_u8sMapOptionalHeaderElements)
				t.u8sMapOptionalHeaderElements = _u8sMapOptionalHeaderElements;
		}

		if (errors.validateResult(newContext, "AsnEMail"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMail | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMail");

		let t: ENetROSEInterface.AsnEMail | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnEMail>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnEMail["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _originatorparty = AsnEMailParty_Converter.fromJSON(s.originatorParty, errors, newContext, "originatorParty", false);
			if (_originatorparty)
				t.originatorParty = _originatorparty;
			const _recipientparties = AsnEMailPartyList_Converter.fromJSON(s.recipientParties, errors, newContext, "recipientParties", false);
			if (_recipientparties)
				t.recipientParties = _recipientparties;
			TSConverter.fillJSONParam(s, t, "u8sSubject", "string", errors, context, false);
			const _bodylist = AsnEMailBodyList_Converter.fromJSON(s.bodyList, errors, newContext, "bodyList", false);
			if (_bodylist)
				t.bodyList = _bodylist;
			t.attachmentList = AsnEMailAttachmentList_Converter.fromJSON(s.attachmentList, errors, newContext, "attachmentList", true);
			t.returnParties = AsnEMailPartyList_Converter.fromJSON(s.returnParties, errors, newContext, "returnParties", true);
			t.carbonCopyParties = AsnEMailPartyList_Converter.fromJSON(s.carbonCopyParties, errors, newContext, "carbonCopyParties", true);
			t.blindCarbonParties = AsnEMailPartyList_Converter.fromJSON(s.blindCarbonParties, errors, newContext, "blindCarbonParties", true);
			TSConverter.fillJSONParam(s, t, "bSendNotification", "boolean", errors, context, true);
			TSConverter.fillJSONParam(s, t, "bReadNotification", "boolean", errors, context, true);
			TSConverter.fillJSONParam(s, t, "iEmailImportance", "number", errors, context, true);
			TSConverter.fillJSONParam(s, t, "u8sMessageID", "string", errors, context, true);
			t.u8sMapOptionalHeaderElements = ENetUC_Common_Converter.AsnStringPairList_Converter.fromJSON(s.u8sMapOptionalHeaderElements, errors, newContext, "u8sMapOptionalHeaderElements", true);
		}
		if (errors.validateResult(newContext, "AsnEMail"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnEMail | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnEMail";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMail");

		// [Print_BER_EncoderSeqDefCode]
		const _originatorParty = AsnEMailParty_Converter.toBER(s.originatorParty, errors, newContext, "originatorParty");
		const _recipientParties = AsnEMailPartyList_Converter.toBER(s.recipientParties, errors, newContext, "recipientParties");
		TSConverter.validateParam(s, "u8sSubject", "string", errors, newContext);
		const _bodyList = AsnEMailBodyList_Converter.toBER(s.bodyList, errors, newContext, "bodyList");
		const _attachmentList = AsnEMailAttachmentList_Converter.toBER(s.attachmentList, errors, newContext, "attachmentList", 0);
		const _returnParties = AsnEMailPartyList_Converter.toBER(s.returnParties, errors, newContext, "returnParties", 1);
		const _carbonCopyParties = AsnEMailPartyList_Converter.toBER(s.carbonCopyParties, errors, newContext, "carbonCopyParties", 2);
		const _blindCarbonParties = AsnEMailPartyList_Converter.toBER(s.blindCarbonParties, errors, newContext, "blindCarbonParties", 3);
		TSConverter.validateParam(s, "bSendNotification", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "bReadNotification", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "iEmailImportance", "number", errors, newContext, true);
		TSConverter.validateParam(s, "u8sMessageID", "string", errors, newContext, true);
		const _u8sMapOptionalHeaderElements = ENetUC_Common_Converter.AsnStringPairList_Converter.toBER(s.u8sMapOptionalHeaderElements, errors, newContext, "u8sMapOptionalHeaderElements", 8);
		if (errors.validateResult(newContext, "AsnEMail")) {
			if (_originatorParty)
				t.push(_originatorParty);
			if (_recipientParties)
				t.push(_recipientParties);
			t.push(new asn1ts.Utf8String({ value: s.u8sSubject, name: "u8sSubject" }));
			if (_bodyList)
				t.push(_bodyList);
			if (_attachmentList)
				t.push(_attachmentList);
			if (_returnParties)
				t.push(_returnParties);
			if (_carbonCopyParties)
				t.push(_carbonCopyParties);
			if (_blindCarbonParties)
				t.push(_blindCarbonParties);
			if (s.bSendNotification !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bSendNotification, name: "bSendNotification", idBlock: { optionalID: 4 } }));
			if (s.bReadNotification !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bReadNotification, name: "bReadNotification", idBlock: { optionalID: 5 } }));
			if (s.iEmailImportance !== undefined)
				t.push(new asn1ts.Integer({ value: s.iEmailImportance, name: "iEmailImportance", idBlock: { optionalID: 6 } }));
			if (s.u8sMessageID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sMessageID, name: "u8sMessageID", idBlock: { optionalID: 7 } }));
			if (_u8sMapOptionalHeaderElements)
				t.push(_u8sMapOptionalHeaderElements);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMail | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMail");

		let t: ENetROSEInterface.AsnEMail | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnEMail.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnEMail["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _originatorparty = AsnEMailParty_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "originatorParty"), errors, newContext, "originatorParty");
			if (_originatorparty)
				t.originatorParty = _originatorparty;
			const _recipientparties = AsnEMailPartyList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "recipientParties"), errors, newContext, "recipientParties");
			if (_recipientparties)
				t.recipientParties = _recipientparties;
			TSConverter.fillASN1Param(s, t, "u8sSubject", "Utf8String", errors, newContext);
			const _bodylist = AsnEMailBodyList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "bodyList"), errors, newContext, "bodyList");
			if (_bodylist)
				t.bodyList = _bodylist;
			t.attachmentList = AsnEMailAttachmentList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "attachmentList"), errors, newContext, "attachmentList", true);
			t.returnParties = AsnEMailPartyList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "returnParties"), errors, newContext, "returnParties", true);
			t.carbonCopyParties = AsnEMailPartyList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "carbonCopyParties"), errors, newContext, "carbonCopyParties", true);
			t.blindCarbonParties = AsnEMailPartyList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "blindCarbonParties"), errors, newContext, "blindCarbonParties", true);
			TSConverter.fillASN1Param(s, t, "bSendNotification", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bReadNotification", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iEmailImportance", "Integer", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sMessageID", "Utf8String", errors, newContext, true);
			t.u8sMapOptionalHeaderElements = ENetUC_Common_Converter.AsnStringPairList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "u8sMapOptionalHeaderElements"), errors, newContext, "u8sMapOptionalHeaderElements", true);
		}

		if (errors.validateResult(newContext, "AsnEMail"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnEMailList_Converter {
	public static toJSON(s: ENetROSEInterface.AsnEMailList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnEMailList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailList");

		const t = [] as ENetROSEInterface.AsnEMailList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnEMail_Converter.toJSON(se, errors, newContext, "AsnEMail");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnEMailList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailList");

		let t: ENetROSEInterface.AsnEMailList | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnEMailList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnEMailList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnEMail_Converter.fromJSON(se, errors, newContext, "AsnEMail", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnEMailList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnEMailList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnEMailList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnEMailList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnEMail_Converter.toBER(s[id], errors, newContext, "AsnEMail");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnEMail"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnEMailList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnEMailList");

		let t: ENetROSEInterface.AsnEMailList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnEMailList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnEMailList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnEMail_Converter.fromBER(se, errors, newContext, "AsnEMail", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnEMailList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSendClientSideMailArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSendClientSideMailArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSendClientSideMailArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendClientSideMailArgument");

		const t = {} as ENetROSEInterface.AsnSendClientSideMailArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSendClientSideMailArgument";
		const _mails = AsnEMailList_Converter.toJSON(s.mails, errors, newContext, "mails");
		if (_mails)
			t.mails = _mails;

		if (errors.validateResult(newContext, "AsnSendClientSideMailArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendClientSideMailArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendClientSideMailArgument");

		let t: ENetROSEInterface.AsnSendClientSideMailArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSendClientSideMailArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSendClientSideMailArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _mails = AsnEMailList_Converter.fromJSON(s.mails, errors, newContext, "mails", false);
			if (_mails)
				t.mails = _mails;
		}
		if (errors.validateResult(newContext, "AsnSendClientSideMailArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSendClientSideMailArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSendClientSideMailArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendClientSideMailArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _mails = AsnEMailList_Converter.toBER(s.mails, errors, newContext, "mails");
		if (errors.validateResult(newContext, "AsnSendClientSideMailArgument")) {
			if (_mails)
				t.push(_mails);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendClientSideMailArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendClientSideMailArgument");

		let t: ENetROSEInterface.AsnSendClientSideMailArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSendClientSideMailArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSendClientSideMailArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _mails = AsnEMailList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "mails"), errors, newContext, "mails");
			if (_mails)
				t.mails = _mails;
		}

		if (errors.validateResult(newContext, "AsnSendClientSideMailArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDBPhoneBookList_Converter {
	public static toJSON(s: ENetROSEInterface.AsnDBPhoneBookList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnDBPhoneBookList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDBPhoneBookList");

		const t = [] as ENetROSEInterface.AsnDBPhoneBookList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnDBPhoneBook_Converter.toJSON(se, errors, newContext, "AsnDBPhoneBook");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnDBPhoneBookList"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDBPhoneBookList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDBPhoneBookList");

		let t: ENetROSEInterface.AsnDBPhoneBookList | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnDBPhoneBookList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnDBPhoneBookList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnDBPhoneBook_Converter.fromJSON(se, errors, newContext, "AsnDBPhoneBook", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnDBPhoneBookList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnDBPhoneBookList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDBPhoneBookList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDBPhoneBookList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnDBPhoneBook_Converter.toBER(s[id], errors, newContext, "AsnDBPhoneBook");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnDBPhoneBook"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnDBPhoneBookList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDBPhoneBookList");

		let t: ENetROSEInterface.AsnDBPhoneBookList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnDBPhoneBookList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnDBPhoneBookList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnDBPhoneBook_Converter.fromBER(se, errors, newContext, "AsnDBPhoneBook", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnDBPhoneBookList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnFindDBContactsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnFindDBContactsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnFindDBContactsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnFindDBContactsArgument");

		const t = {} as ENetROSEInterface.AsnFindDBContactsArgument & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnFindDBContactsArgument";
		if (s.findoptions != null) {
			const _findoptions = AsnDatabaseFindOption_Converter.toJSON(s.findoptions, errors, newContext, "findoptions");
			if (_findoptions)
				t.findoptions = _findoptions;
		} else if (s.entryid != null) {
			const _entryid = AsnDatabaseEntryID_Converter.toJSON(s.entryid, errors, newContext, "entryid");
			if (_entryid)
				t.entryid = _entryid;
		} else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnFindDBContactsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnFindDBContactsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnFindDBContactsArgument");

		let t: ENetROSEInterface.AsnFindDBContactsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnFindDBContactsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnFindDBContactsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.findoptions !== undefined)
				t.findoptions = AsnDatabaseFindOption_Converter.fromJSON(s.findoptions, errors, newContext, "findoptions", false);
			else if (s.entryid !== undefined)
				t.entryid = AsnDatabaseEntryID_Converter.fromJSON(s.entryid, errors, newContext, "entryid", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}
		if (errors.validateResult(newContext, "AsnFindDBContactsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnFindDBContactsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "AsnFindDBContactsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnFindDBContactsArgument");

		// [Print_BER_EncoderChoiceDefCode]
		if (s.findoptions)
			t = AsnDatabaseFindOption_Converter.toBER(s.findoptions, errors, newContext, "findoptions", 0);
		else if (s.entryid)
			t = AsnDatabaseEntryID_Converter.toBER(s.entryid, errors, newContext, "entryid", 1);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
		if (errors.validateResult(newContext, "AsnFindDBContactsArgument"))
			return t;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnFindDBContactsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnFindDBContactsArgument");

		let t: ENetROSEInterface.AsnFindDBContactsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnFindDBContactsArgument.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnFindDBContactsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "findoptions" && asn1ts.Sequence.typeGuard(s))
				t.findoptions = AsnDatabaseFindOption_Converter.fromBER(s, undefined, newContext, "findoptions", false);
			else if (s.choiceName === "entryid" && asn1ts.Sequence.typeGuard(s))
				t.entryid = AsnDatabaseEntryID_Converter.fromBER(s, undefined, newContext, "entryid", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnFindDBContactsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnFindDBContactsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnFindDBContactsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnFindDBContactsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnFindDBContactsResult");

		const t = {} as ENetROSEInterface.AsnFindDBContactsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnFindDBContactsResult";
		const _contactList = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(s.contactList, errors, newContext, "contactList");
		if (_contactList)
			t.contactList = _contactList;
		const _phonebookList = AsnDBPhoneBookList_Converter.toJSON(s.phonebookList, errors, newContext, "phonebookList");
		if (_phonebookList)
			t.phonebookList = _phonebookList;

		if (errors.validateResult(newContext, "AsnFindDBContactsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnFindDBContactsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnFindDBContactsResult");

		let t: ENetROSEInterface.AsnFindDBContactsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnFindDBContactsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnFindDBContactsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _contactlist = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(s.contactList, errors, newContext, "contactList", false);
			if (_contactlist)
				t.contactList = _contactlist;
			const _phonebooklist = AsnDBPhoneBookList_Converter.fromJSON(s.phonebookList, errors, newContext, "phonebookList", false);
			if (_phonebooklist)
				t.phonebookList = _phonebooklist;
		}
		if (errors.validateResult(newContext, "AsnFindDBContactsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnFindDBContactsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnFindDBContactsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnFindDBContactsResult");

		// [Print_BER_EncoderSeqDefCode]
		const _contactList = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toBER(s.contactList, errors, newContext, "contactList");
		const _phonebookList = AsnDBPhoneBookList_Converter.toBER(s.phonebookList, errors, newContext, "phonebookList");
		if (errors.validateResult(newContext, "AsnFindDBContactsResult")) {
			if (_contactList)
				t.push(_contactList);
			if (_phonebookList)
				t.push(_phonebookList);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnFindDBContactsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnFindDBContactsResult");

		let t: ENetROSEInterface.AsnFindDBContactsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnFindDBContactsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnFindDBContactsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _contactlist = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contactList"), errors, newContext, "contactList");
			if (_contactlist)
				t.contactList = _contactlist;
			const _phonebooklist = AsnDBPhoneBookList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "phonebookList"), errors, newContext, "phonebookList");
			if (_phonebooklist)
				t.phonebookList = _phonebooklist;
		}

		if (errors.validateResult(newContext, "AsnFindDBContactsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnFindContactsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnFindContactsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnFindContactsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnFindContactsArgument");

		const t = {} as ENetROSEInterface.AsnFindContactsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnFindContactsArgument";
		const _findOptions = AsnNetDatabaseFindOptions_Converter.toJSON(s.findOptions, errors, newContext, "findOptions");
		if (_findOptions)
			t.findOptions = _findOptions;

		if (errors.validateResult(newContext, "AsnFindContactsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnFindContactsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnFindContactsArgument");

		let t: ENetROSEInterface.AsnFindContactsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnFindContactsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnFindContactsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _findoptions = AsnNetDatabaseFindOptions_Converter.fromJSON(s.findOptions, errors, newContext, "findOptions", false);
			if (_findoptions)
				t.findOptions = _findoptions;
		}
		if (errors.validateResult(newContext, "AsnFindContactsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnFindContactsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnFindContactsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnFindContactsArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _findOptions = AsnNetDatabaseFindOptions_Converter.toBER(s.findOptions, errors, newContext, "findOptions");
		if (errors.validateResult(newContext, "AsnFindContactsArgument")) {
			if (_findOptions)
				t.push(_findOptions);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnFindContactsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnFindContactsArgument");

		let t: ENetROSEInterface.AsnFindContactsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnFindContactsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnFindContactsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _findoptions = AsnNetDatabaseFindOptions_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "findOptions"), errors, newContext, "findOptions");
			if (_findoptions)
				t.findOptions = _findoptions;
		}

		if (errors.validateResult(newContext, "AsnFindContactsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnFindContactsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnFindContactsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnFindContactsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnFindContactsResult");

		const t = {} as ENetROSEInterface.AsnFindContactsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnFindContactsResult";
		const _contactList = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(s.contactList, errors, newContext, "contactList");
		if (_contactList)
			t.contactList = _contactList;
		const _phonebookList = AsnDBPhoneBookList_Converter.toJSON(s.phonebookList, errors, newContext, "phonebookList");
		if (_phonebookList)
			t.phonebookList = _phonebookList;
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnFindContactsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnFindContactsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnFindContactsResult");

		let t: ENetROSEInterface.AsnFindContactsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnFindContactsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnFindContactsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _contactlist = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(s.contactList, errors, newContext, "contactList", false);
			if (_contactlist)
				t.contactList = _contactlist;
			const _phonebooklist = AsnDBPhoneBookList_Converter.fromJSON(s.phonebookList, errors, newContext, "phonebookList", false);
			if (_phonebooklist)
				t.phonebookList = _phonebooklist;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnFindContactsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnFindContactsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnFindContactsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnFindContactsResult");

		// [Print_BER_EncoderSeqDefCode]
		const _contactList = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toBER(s.contactList, errors, newContext, "contactList");
		const _phonebookList = AsnDBPhoneBookList_Converter.toBER(s.phonebookList, errors, newContext, "phonebookList");
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", true);
		if (errors.validateResult(newContext, "AsnFindContactsResult")) {
			if (_contactList)
				t.push(_contactList);
			if (_phonebookList)
				t.push(_phonebookList);
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnFindContactsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnFindContactsResult");

		let t: ENetROSEInterface.AsnFindContactsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnFindContactsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnFindContactsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _contactlist = ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contactList"), errors, newContext, "contactList");
			if (_contactlist)
				t.contactList = _contactlist;
			const _phonebooklist = AsnDBPhoneBookList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "phonebookList"), errors, newContext, "phonebookList");
			if (_phonebooklist)
				t.phonebookList = _phonebooklist;
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnFindContactsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetPhoneBooksResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetPhoneBooksResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetPhoneBooksResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetPhoneBooksResult");

		const t = {} as ENetROSEInterface.AsnGetPhoneBooksResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetPhoneBooksResult";
		const _phonebooks = AsnDBPhoneBookList_Converter.toJSON(s.phonebooks, errors, newContext, "phonebooks");
		if (_phonebooks)
			t.phonebooks = _phonebooks;

		if (errors.validateResult(newContext, "AsnGetPhoneBooksResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetPhoneBooksResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetPhoneBooksResult");

		let t: ENetROSEInterface.AsnGetPhoneBooksResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetPhoneBooksResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetPhoneBooksResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _phonebooks = AsnDBPhoneBookList_Converter.fromJSON(s.phonebooks, errors, newContext, "phonebooks", false);
			if (_phonebooks)
				t.phonebooks = _phonebooks;
		}
		if (errors.validateResult(newContext, "AsnGetPhoneBooksResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetPhoneBooksResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetPhoneBooksResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetPhoneBooksResult");

		// [Print_BER_EncoderSeqDefCode]
		const _phonebooks = AsnDBPhoneBookList_Converter.toBER(s.phonebooks, errors, newContext, "phonebooks");
		if (errors.validateResult(newContext, "AsnGetPhoneBooksResult")) {
			if (_phonebooks)
				t.push(_phonebooks);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetPhoneBooksResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetPhoneBooksResult");

		let t: ENetROSEInterface.AsnGetPhoneBooksResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetPhoneBooksResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetPhoneBooksResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _phonebooks = AsnDBPhoneBookList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "phonebooks"), errors, newContext, "phonebooks");
			if (_phonebooks)
				t.phonebooks = _phonebooks;
		}

		if (errors.validateResult(newContext, "AsnGetPhoneBooksResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactStatusParameter_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactStatusParameter, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactStatusParameter & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusParameter");

		const t = {} as ENetROSEInterface.AsnContactStatusParameter & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactStatusParameter";
		TSConverter.fillJSONParam(s, t, "typeOfValue", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sValue", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnContactStatusParameter"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusParameter | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusParameter");

		let t: ENetROSEInterface.AsnContactStatusParameter | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactStatusParameter>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactStatusParameter["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "typeOfValue", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sValue", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnContactStatusParameter"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactStatusParameter | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactStatusParameter";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusParameter");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "typeOfValue", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sValue", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnContactStatusParameter")) {
			t.push(new asn1ts.Enumerated({ value: s.typeOfValue, name: "typeOfValue" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sValue, name: "u8sValue" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusParameter | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusParameter");

		let t: ENetROSEInterface.AsnContactStatusParameter | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactStatusParameter.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactStatusParameter["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "typeOfValue", "Enumerated", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sValue", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnContactStatusParameter"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class SEQContactStatusParameter_Converter {
	public static toJSON(s: ENetROSEInterface.SEQContactStatusParameter, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.SEQContactStatusParameter | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "SEQContactStatusParameter");

		const t = [] as ENetROSEInterface.SEQContactStatusParameter;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnContactStatusParameter_Converter.toJSON(se, errors, newContext, "AsnContactStatusParameter");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "SEQContactStatusParameter"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.SEQContactStatusParameter | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "SEQContactStatusParameter");

		let t: ENetROSEInterface.SEQContactStatusParameter | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.SEQContactStatusParameter>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.SEQContactStatusParameter();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnContactStatusParameter_Converter.fromJSON(se, errors, newContext, "AsnContactStatusParameter", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "SEQContactStatusParameter"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.SEQContactStatusParameter | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "SEQContactStatusParameter";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "SEQContactStatusParameter");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnContactStatusParameter_Converter.toBER(s[id], errors, newContext, "AsnContactStatusParameter");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnContactStatusParameter"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.SEQContactStatusParameter | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "SEQContactStatusParameter");

		let t: ENetROSEInterface.SEQContactStatusParameter | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.SEQContactStatusParameter.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.SEQContactStatusParameter();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnContactStatusParameter_Converter.fromBER(se, errors, newContext, "AsnContactStatusParameter", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "SEQContactStatusParameter"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactStatusRequestEntry_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactStatusRequestEntry, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactStatusRequestEntry & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusRequestEntry");

		const t = {} as ENetROSEInterface.AsnContactStatusRequestEntry & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactStatusRequestEntry";
		TSConverter.fillJSONParam(s, t, "iClientSearchID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iClientRowID", "number", errors, newContext);
		const _listSearchParameter = SEQContactStatusParameter_Converter.toJSON(s.listSearchParameter, errors, newContext, "listSearchParameter");
		if (_listSearchParameter)
			t.listSearchParameter = _listSearchParameter;
		TSConverter.fillJSONParam(s, t, "bActivateNotify", "boolean", errors, newContext);
		const _listDeactivateNotify = ENetUC_Common_Converter.SEQInteger_Converter.toJSON(s.listDeactivateNotify, errors, newContext, "listDeactivateNotify");
		if (_listDeactivateNotify)
			t.listDeactivateNotify = _listDeactivateNotify;

		if (errors.validateResult(newContext, "AsnContactStatusRequestEntry"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusRequestEntry | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusRequestEntry");

		let t: ENetROSEInterface.AsnContactStatusRequestEntry | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactStatusRequestEntry>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactStatusRequestEntry["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iClientSearchID", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iClientRowID", "number", errors, context, false);
			const _listsearchparameter = SEQContactStatusParameter_Converter.fromJSON(s.listSearchParameter, errors, newContext, "listSearchParameter", false);
			if (_listsearchparameter)
				t.listSearchParameter = _listsearchparameter;
			TSConverter.fillJSONParam(s, t, "bActivateNotify", "boolean", errors, context, false);
			const _listdeactivatenotify = ENetUC_Common_Converter.SEQInteger_Converter.fromJSON(s.listDeactivateNotify, errors, newContext, "listDeactivateNotify", false);
			if (_listdeactivatenotify)
				t.listDeactivateNotify = _listdeactivatenotify;
		}
		if (errors.validateResult(newContext, "AsnContactStatusRequestEntry"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactStatusRequestEntry | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactStatusRequestEntry";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusRequestEntry");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iClientSearchID", "number", errors, newContext);
		TSConverter.validateParam(s, "iClientRowID", "number", errors, newContext);
		const _listSearchParameter = SEQContactStatusParameter_Converter.toBER(s.listSearchParameter, errors, newContext, "listSearchParameter");
		TSConverter.validateParam(s, "bActivateNotify", "boolean", errors, newContext);
		const _listDeactivateNotify = ENetUC_Common_Converter.SEQInteger_Converter.toBER(s.listDeactivateNotify, errors, newContext, "listDeactivateNotify");
		if (errors.validateResult(newContext, "AsnContactStatusRequestEntry")) {
			t.push(new asn1ts.Integer({ value: s.iClientSearchID, name: "iClientSearchID" }));
			t.push(new asn1ts.Integer({ value: s.iClientRowID, name: "iClientRowID" }));
			if (_listSearchParameter)
				t.push(_listSearchParameter);
			t.push(new asn1ts.Boolean({ value: s.bActivateNotify, name: "bActivateNotify" }));
			if (_listDeactivateNotify)
				t.push(_listDeactivateNotify);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusRequestEntry | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusRequestEntry");

		let t: ENetROSEInterface.AsnContactStatusRequestEntry | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactStatusRequestEntry.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactStatusRequestEntry["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iClientSearchID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iClientRowID", "Integer", errors, newContext);
			const _listsearchparameter = SEQContactStatusParameter_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listSearchParameter"), errors, newContext, "listSearchParameter");
			if (_listsearchparameter)
				t.listSearchParameter = _listsearchparameter;
			TSConverter.fillASN1Param(s, t, "bActivateNotify", "Boolean", errors, newContext);
			const _listdeactivatenotify = ENetUC_Common_Converter.SEQInteger_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listDeactivateNotify"), errors, newContext, "listDeactivateNotify");
			if (_listdeactivatenotify)
				t.listDeactivateNotify = _listdeactivatenotify;
		}

		if (errors.validateResult(newContext, "AsnContactStatusRequestEntry"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class SEQContactStatusRequest_Converter {
	public static toJSON(s: ENetROSEInterface.SEQContactStatusRequest, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.SEQContactStatusRequest | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "SEQContactStatusRequest");

		const t = [] as ENetROSEInterface.SEQContactStatusRequest;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnContactStatusRequestEntry_Converter.toJSON(se, errors, newContext, "AsnContactStatusRequestEntry");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "SEQContactStatusRequest"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.SEQContactStatusRequest | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "SEQContactStatusRequest");

		let t: ENetROSEInterface.SEQContactStatusRequest | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.SEQContactStatusRequest>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.SEQContactStatusRequest();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnContactStatusRequestEntry_Converter.fromJSON(se, errors, newContext, "AsnContactStatusRequestEntry", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "SEQContactStatusRequest"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.SEQContactStatusRequest | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "SEQContactStatusRequest";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "SEQContactStatusRequest");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnContactStatusRequestEntry_Converter.toBER(s[id], errors, newContext, "AsnContactStatusRequestEntry");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnContactStatusRequestEntry"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.SEQContactStatusRequest | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "SEQContactStatusRequest");

		let t: ENetROSEInterface.SEQContactStatusRequest | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.SEQContactStatusRequest.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.SEQContactStatusRequest();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnContactStatusRequestEntry_Converter.fromBER(se, errors, newContext, "AsnContactStatusRequestEntry", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "SEQContactStatusRequest"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class SEQContactStatusResult_Converter {
	public static toJSON(s: ENetROSEInterface.SEQContactStatusResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.SEQContactStatusResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "SEQContactStatusResult");

		const t = [] as ENetROSEInterface.SEQContactStatusResult;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnContactStatusResultEntry_Converter.toJSON(se, errors, newContext, "AsnContactStatusResultEntry");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "SEQContactStatusResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.SEQContactStatusResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "SEQContactStatusResult");

		let t: ENetROSEInterface.SEQContactStatusResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.SEQContactStatusResult>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.SEQContactStatusResult();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnContactStatusResultEntry_Converter.fromJSON(se, errors, newContext, "AsnContactStatusResultEntry", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "SEQContactStatusResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.SEQContactStatusResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "SEQContactStatusResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "SEQContactStatusResult");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnContactStatusResultEntry_Converter.toBER(s[id], errors, newContext, "AsnContactStatusResultEntry");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnContactStatusResultEntry"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.SEQContactStatusResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "SEQContactStatusResult");

		let t: ENetROSEInterface.SEQContactStatusResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.SEQContactStatusResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.SEQContactStatusResult();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnContactStatusResultEntry_Converter.fromBER(se, errors, newContext, "AsnContactStatusResultEntry", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "SEQContactStatusResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactStatusRequestArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactStatusRequestArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactStatusRequestArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusRequestArgument");

		const t = {} as ENetROSEInterface.AsnContactStatusRequestArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactStatusRequestArgument";
		const _request = SEQContactStatusRequest_Converter.toJSON(s.request, errors, newContext, "request");
		if (_request)
			t.request = _request;

		if (errors.validateResult(newContext, "AsnContactStatusRequestArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusRequestArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusRequestArgument");

		let t: ENetROSEInterface.AsnContactStatusRequestArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactStatusRequestArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactStatusRequestArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _request = SEQContactStatusRequest_Converter.fromJSON(s.request, errors, newContext, "request", false);
			if (_request)
				t.request = _request;
		}
		if (errors.validateResult(newContext, "AsnContactStatusRequestArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactStatusRequestArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactStatusRequestArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusRequestArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _request = SEQContactStatusRequest_Converter.toBER(s.request, errors, newContext, "request");
		if (errors.validateResult(newContext, "AsnContactStatusRequestArgument")) {
			if (_request)
				t.push(_request);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusRequestArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusRequestArgument");

		let t: ENetROSEInterface.AsnContactStatusRequestArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactStatusRequestArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactStatusRequestArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _request = SEQContactStatusRequest_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "request"), errors, newContext, "request");
			if (_request)
				t.request = _request;
		}

		if (errors.validateResult(newContext, "AsnContactStatusRequestArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnContactStatusRequestResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnContactStatusRequestResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnContactStatusRequestResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusRequestResult");

		const t = {} as ENetROSEInterface.AsnContactStatusRequestResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnContactStatusRequestResult";
		TSConverter.fillJSONParam(s, t, "iResult", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iCount", "number", errors, newContext);
		const _listStatusResult = SEQContactStatusResult_Converter.toJSON(s.listStatusResult, errors, newContext, "listStatusResult");
		if (_listStatusResult)
			t.listStatusResult = _listStatusResult;

		if (errors.validateResult(newContext, "AsnContactStatusRequestResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusRequestResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusRequestResult");

		let t: ENetROSEInterface.AsnContactStatusRequestResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnContactStatusRequestResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnContactStatusRequestResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iResult", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "iCount", "number", errors, context, false);
			const _liststatusresult = SEQContactStatusResult_Converter.fromJSON(s.listStatusResult, errors, newContext, "listStatusResult", false);
			if (_liststatusresult)
				t.listStatusResult = _liststatusresult;
		}
		if (errors.validateResult(newContext, "AsnContactStatusRequestResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnContactStatusRequestResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnContactStatusRequestResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnContactStatusRequestResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iResult", "number", errors, newContext);
		TSConverter.validateParam(s, "iCount", "number", errors, newContext);
		const _listStatusResult = SEQContactStatusResult_Converter.toBER(s.listStatusResult, errors, newContext, "listStatusResult");
		if (errors.validateResult(newContext, "AsnContactStatusRequestResult")) {
			t.push(new asn1ts.Integer({ value: s.iResult, name: "iResult" }));
			t.push(new asn1ts.Integer({ value: s.iCount, name: "iCount" }));
			if (_listStatusResult)
				t.push(_listStatusResult);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnContactStatusRequestResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnContactStatusRequestResult");

		let t: ENetROSEInterface.AsnContactStatusRequestResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnContactStatusRequestResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnContactStatusRequestResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iResult", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iCount", "Integer", errors, newContext);
			const _liststatusresult = SEQContactStatusResult_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listStatusResult"), errors, newContext, "listStatusResult");
			if (_liststatusresult)
				t.listStatusResult = _liststatusresult;
		}

		if (errors.validateResult(newContext, "AsnContactStatusRequestResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnPublicUserSetContactDataArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnPublicUserSetContactDataArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnPublicUserSetContactDataArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPublicUserSetContactDataArgument");

		const t = {} as ENetROSEInterface.AsnPublicUserSetContactDataArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnPublicUserSetContactDataArgument";
		const _contactData = AsnPublicUserContactData_Converter.toJSON(s.contactData, errors, newContext, "contactData");
		if (_contactData)
			t.contactData = _contactData;

		if (errors.validateResult(newContext, "AsnPublicUserSetContactDataArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnPublicUserSetContactDataArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPublicUserSetContactDataArgument");

		let t: ENetROSEInterface.AsnPublicUserSetContactDataArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnPublicUserSetContactDataArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnPublicUserSetContactDataArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _contactdata = AsnPublicUserContactData_Converter.fromJSON(s.contactData, errors, newContext, "contactData", false);
			if (_contactdata)
				t.contactData = _contactdata;
		}
		if (errors.validateResult(newContext, "AsnPublicUserSetContactDataArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnPublicUserSetContactDataArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnPublicUserSetContactDataArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnPublicUserSetContactDataArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _contactData = AsnPublicUserContactData_Converter.toBER(s.contactData, errors, newContext, "contactData");
		if (errors.validateResult(newContext, "AsnPublicUserSetContactDataArgument")) {
			if (_contactData)
				t.push(_contactData);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnPublicUserSetContactDataArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnPublicUserSetContactDataArgument");

		let t: ENetROSEInterface.AsnPublicUserSetContactDataArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnPublicUserSetContactDataArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnPublicUserSetContactDataArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _contactdata = AsnPublicUserContactData_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "contactData"), errors, newContext, "contactData");
			if (_contactdata)
				t.contactData = _contactdata;
		}

		if (errors.validateResult(newContext, "AsnPublicUserSetContactDataArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSMSMessages_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSMSMessages, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSMSMessages | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSMSMessages");

		const t = [] as ENetROSEInterface.AsnSMSMessages;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnSMSMessage_Converter.toJSON(se, errors, newContext, "AsnSMSMessage");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnSMSMessages"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSMSMessages | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSMSMessages");

		let t: ENetROSEInterface.AsnSMSMessages | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSMSMessages>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnSMSMessages();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnSMSMessage_Converter.fromJSON(se, errors, newContext, "AsnSMSMessage", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnSMSMessages"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSMSMessages | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSMSMessages";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSMSMessages");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnSMSMessage_Converter.toBER(s[id], errors, newContext, "AsnSMSMessage");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnSMSMessage"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSMSMessages | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSMSMessages");

		let t: ENetROSEInterface.AsnSMSMessages | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSMSMessages.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnSMSMessages();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnSMSMessage_Converter.fromBER(se, errors, newContext, "AsnSMSMessage", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnSMSMessages"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSendSMSArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSendSMSArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSendSMSArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendSMSArgument");

		const t = {} as ENetROSEInterface.AsnSendSMSArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSendSMSArgument";
		const _messages = AsnSMSMessages_Converter.toJSON(s.messages, errors, newContext, "messages");
		if (_messages)
			t.messages = _messages;

		if (errors.validateResult(newContext, "AsnSendSMSArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendSMSArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendSMSArgument");

		let t: ENetROSEInterface.AsnSendSMSArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSendSMSArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSendSMSArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _messages = AsnSMSMessages_Converter.fromJSON(s.messages, errors, newContext, "messages", false);
			if (_messages)
				t.messages = _messages;
		}
		if (errors.validateResult(newContext, "AsnSendSMSArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSendSMSArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSendSMSArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSendSMSArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _messages = AsnSMSMessages_Converter.toBER(s.messages, errors, newContext, "messages");
		if (errors.validateResult(newContext, "AsnSendSMSArgument")) {
			if (_messages)
				t.push(_messages);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSendSMSArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSendSMSArgument");

		let t: ENetROSEInterface.AsnSendSMSArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSendSMSArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSendSMSArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _messages = AsnSMSMessages_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "messages"), errors, newContext, "messages");
			if (_messages)
				t.messages = _messages;
		}

		if (errors.validateResult(newContext, "AsnSendSMSArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSMSStatusNotifyArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSMSStatusNotifyArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSMSStatusNotifyArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSMSStatusNotifyArgument");

		const t = {} as ENetROSEInterface.AsnSMSStatusNotifyArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSMSStatusNotifyArgument";
		TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "status", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sErrReason", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnSMSStatusNotifyArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSMSStatusNotifyArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSMSStatusNotifyArgument");

		let t: ENetROSEInterface.AsnSMSStatusNotifyArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSMSStatusNotifyArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSMSStatusNotifyArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMonitorCrossRefID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "status", "number", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sErrReason", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnSMSStatusNotifyArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSMSStatusNotifyArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSMSStatusNotifyArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSMSStatusNotifyArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMonitorCrossRefID", "string", errors, newContext);
		TSConverter.validateParam(s, "status", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sErrReason", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnSMSStatusNotifyArgument")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMonitorCrossRefID, name: "u8sMonitorCrossRefID" }));
			t.push(new asn1ts.Enumerated({ value: s.status, name: "status" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sErrReason, name: "u8sErrReason" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSMSStatusNotifyArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSMSStatusNotifyArgument");

		let t: ENetROSEInterface.AsnSMSStatusNotifyArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSMSStatusNotifyArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSMSStatusNotifyArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMonitorCrossRefID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "status", "Enumerated", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sErrReason", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnSMSStatusNotifyArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUserDefinedCustomFields_Converter {
	public static toJSON(s: ENetROSEInterface.AsnUserDefinedCustomFields, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnUserDefinedCustomFields | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserDefinedCustomFields");

		const t = [] as ENetROSEInterface.AsnUserDefinedCustomFields;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnUserDefinedCustomField_Converter.toJSON(se, errors, newContext, "AsnUserDefinedCustomField");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnUserDefinedCustomFields"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUserDefinedCustomFields | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserDefinedCustomFields");

		let t: ENetROSEInterface.AsnUserDefinedCustomFields | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnUserDefinedCustomFields>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnUserDefinedCustomFields();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnUserDefinedCustomField_Converter.fromJSON(se, errors, newContext, "AsnUserDefinedCustomField", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnUserDefinedCustomFields"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnUserDefinedCustomFields | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUserDefinedCustomFields";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserDefinedCustomFields");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnUserDefinedCustomField_Converter.toBER(s[id], errors, newContext, "AsnUserDefinedCustomField");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnUserDefinedCustomField"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnUserDefinedCustomFields | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserDefinedCustomFields");

		let t: ENetROSEInterface.AsnUserDefinedCustomFields | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnUserDefinedCustomFields.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnUserDefinedCustomFields();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnUserDefinedCustomField_Converter.fromBER(se, errors, newContext, "AsnUserDefinedCustomField", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnUserDefinedCustomFields"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetUserdefinedCustomFieldsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserdefinedCustomFieldsResult");

		const t = {} as ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetUserdefinedCustomFieldsResult";
		const _customfields = AsnUserDefinedCustomFields_Converter.toJSON(s.customfields, errors, newContext, "customfields");
		if (_customfields)
			t.customfields = _customfields;

		if (errors.validateResult(newContext, "AsnGetUserdefinedCustomFieldsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserdefinedCustomFieldsResult");

		let t: ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _customfields = AsnUserDefinedCustomFields_Converter.fromJSON(s.customfields, errors, newContext, "customfields", false);
			if (_customfields)
				t.customfields = _customfields;
		}
		if (errors.validateResult(newContext, "AsnGetUserdefinedCustomFieldsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetUserdefinedCustomFieldsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetUserdefinedCustomFieldsResult");

		// [Print_BER_EncoderSeqDefCode]
		const _customfields = AsnUserDefinedCustomFields_Converter.toBER(s.customfields, errors, newContext, "customfields");
		if (errors.validateResult(newContext, "AsnGetUserdefinedCustomFieldsResult")) {
			if (_customfields)
				t.push(_customfields);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetUserdefinedCustomFieldsResult");

		let t: ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _customfields = AsnUserDefinedCustomFields_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "customfields"), errors, newContext, "customfields");
			if (_customfields)
				t.customfields = _customfields;
		}

		if (errors.validateResult(newContext, "AsnGetUserdefinedCustomFieldsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnOpenContactsArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnOpenContactsArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnOpenContactsArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOpenContactsArgument");

		const t = {} as ENetROSEInterface.AsnOpenContactsArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnOpenContactsArgument";
		const _seqContactIDs = AsnContactIDs_Converter.toJSON(s.seqContactIDs, errors, newContext, "seqContactIDs");
		if (_seqContactIDs)
			t.seqContactIDs = _seqContactIDs;
		TSConverter.fillJSONParam(s, t, "bOpenOnlyInternalContacts", "boolean", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "bDontRequestPermissions", "boolean", errors, newContext, true);
		if (s.optionalParams) {
			const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(s.optionalParams, errors, newContext, "optionalParams");
			if (_optionalParams)
				t.optionalParams = _optionalParams;
		}

		if (errors.validateResult(newContext, "AsnOpenContactsArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnOpenContactsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOpenContactsArgument");

		let t: ENetROSEInterface.AsnOpenContactsArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnOpenContactsArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnOpenContactsArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _seqcontactids = AsnContactIDs_Converter.fromJSON(s.seqContactIDs, errors, newContext, "seqContactIDs", false);
			if (_seqcontactids)
				t.seqContactIDs = _seqcontactids;
			TSConverter.fillJSONParam(s, t, "bOpenOnlyInternalContacts", "boolean", errors, context, true);
			TSConverter.fillJSONParam(s, t, "bDontRequestPermissions", "boolean", errors, context, true);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(s.optionalParams, errors, newContext, "optionalParams", true);
		}
		if (errors.validateResult(newContext, "AsnOpenContactsArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnOpenContactsArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnOpenContactsArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnOpenContactsArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _seqContactIDs = AsnContactIDs_Converter.toBER(s.seqContactIDs, errors, newContext, "seqContactIDs");
		TSConverter.validateParam(s, "bOpenOnlyInternalContacts", "boolean", errors, newContext, true);
		TSConverter.validateParam(s, "bDontRequestPermissions", "boolean", errors, newContext, true);
		const _optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.toBER(s.optionalParams, errors, newContext, "optionalParams", 3);
		if (errors.validateResult(newContext, "AsnOpenContactsArgument")) {
			if (_seqContactIDs)
				t.push(_seqContactIDs);
			if (s.bOpenOnlyInternalContacts !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bOpenOnlyInternalContacts, name: "bOpenOnlyInternalContacts", idBlock: { optionalID: 0 } }));
			if (s.bDontRequestPermissions !== undefined)
				t.push(new asn1ts.Boolean({ value: s.bDontRequestPermissions, name: "bDontRequestPermissions", idBlock: { optionalID: 1 } }));
			if (_optionalParams)
				t.push(_optionalParams);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnOpenContactsArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnOpenContactsArgument");

		let t: ENetROSEInterface.AsnOpenContactsArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnOpenContactsArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnOpenContactsArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _seqcontactids = AsnContactIDs_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "seqContactIDs"), errors, newContext, "seqContactIDs");
			if (_seqcontactids)
				t.seqContactIDs = _seqcontactids;
			TSConverter.fillASN1Param(s, t, "bOpenOnlyInternalContacts", "Boolean", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "bDontRequestPermissions", "Boolean", errors, newContext, true);
			t.optionalParams = ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "optionalParams"), errors, newContext, "optionalParams", true);
		}

		if (errors.validateResult(newContext, "AsnOpenContactsArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetMetaSettingsResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetMetaSettingsResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetMetaSettingsResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetMetaSettingsResult");

		const t = {} as ENetROSEInterface.AsnGetMetaSettingsResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetMetaSettingsResult";
		const _metasettings = AsnMetaDirectorySettings_Converter.toJSON(s.metasettings, errors, newContext, "metasettings");
		if (_metasettings)
			t.metasettings = _metasettings;

		if (errors.validateResult(newContext, "AsnGetMetaSettingsResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetMetaSettingsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetMetaSettingsResult");

		let t: ENetROSEInterface.AsnGetMetaSettingsResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetMetaSettingsResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetMetaSettingsResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _metasettings = AsnMetaDirectorySettings_Converter.fromJSON(s.metasettings, errors, newContext, "metasettings", false);
			if (_metasettings)
				t.metasettings = _metasettings;
		}
		if (errors.validateResult(newContext, "AsnGetMetaSettingsResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetMetaSettingsResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetMetaSettingsResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetMetaSettingsResult");

		// [Print_BER_EncoderSeqDefCode]
		const _metasettings = AsnMetaDirectorySettings_Converter.toBER(s.metasettings, errors, newContext, "metasettings");
		if (errors.validateResult(newContext, "AsnGetMetaSettingsResult")) {
			if (_metasettings)
				t.push(_metasettings);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetMetaSettingsResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetMetaSettingsResult");

		let t: ENetROSEInterface.AsnGetMetaSettingsResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetMetaSettingsResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetMetaSettingsResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _metasettings = AsnMetaDirectorySettings_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "metasettings"), errors, newContext, "metasettings");
			if (_metasettings)
				t.metasettings = _metasettings;
		}

		if (errors.validateResult(newContext, "AsnGetMetaSettingsResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnAbsentStates_Converter {
	public static toJSON(s: ENetROSEInterface.AsnAbsentStates, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnAbsentStates | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStates");

		const t = [] as ENetROSEInterface.AsnAbsentStates;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(se, errors, newContext, "AsnAbsentState")
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnAbsentStates"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStates | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStates");

		let t: ENetROSEInterface.AsnAbsentStates | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnAbsentStates>(data, errors, newContext, optional);
		if (s) {
			t = new ENetROSEInterface.AsnAbsentStates();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(se, errors, newContext, "AsnAbsentState", false);
				if (val)
					t.push(val);
			}
		}
		if (errors.validateResult(newContext, "AsnAbsentStates"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnAbsentStates | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnAbsentStates";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnAbsentStates");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toBER(s[id], errors, newContext, "AsnAbsentState");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnAbsentState"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnAbsentStates | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnAbsentStates");

		let t: ENetROSEInterface.AsnAbsentStates | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnAbsentStates.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetROSEInterface.AsnAbsentStates();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromBER(se, errors, newContext, "AsnAbsentState", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnAbsentStates"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetPreferredContactRefResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetPreferredContactRefResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetPreferredContactRefResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetPreferredContactRefResult");

		const t = {} as ENetROSEInterface.AsnSetPreferredContactRefResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetPreferredContactRefResult";

		if (errors.validateResult(newContext, "AsnSetPreferredContactRefResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetPreferredContactRefResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetPreferredContactRefResult");

		let t: ENetROSEInterface.AsnSetPreferredContactRefResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetPreferredContactRefResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetPreferredContactRefResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnSetPreferredContactRefResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetPreferredContactRefResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetPreferredContactRefResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetPreferredContactRefResult");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetPreferredContactRefResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetPreferredContactRefResult");

		let t: ENetROSEInterface.AsnSetPreferredContactRefResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetPreferredContactRefResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetPreferredContactRefResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnSetPreferredContactRefResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetServerBasedClientSettingsExArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetServerBasedClientSettingsExArgument");

		const t = {} as ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetServerBasedClientSettingsExArgument";

		if (errors.validateResult(newContext, "AsnGetServerBasedClientSettingsExArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetServerBasedClientSettingsExArgument");

		let t: ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnGetServerBasedClientSettingsExArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetServerBasedClientSettingsExArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetServerBasedClientSettingsExArgument");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetServerBasedClientSettingsExArgument");

		let t: ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetServerBasedClientSettingsExArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetServerBasedClientSettingsExArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetServerBasedClientSettingsExResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnGetServerBasedClientSettingsExResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnGetServerBasedClientSettingsExResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetServerBasedClientSettingsExResult");

		const t = {} as ENetROSEInterface.AsnGetServerBasedClientSettingsExResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetServerBasedClientSettingsExResult";
		const _clientSettings = AsnServerBasedClientSettingsEx_Converter.toJSON(s.clientSettings, errors, newContext, "clientSettings");
		if (_clientSettings)
			t.clientSettings = _clientSettings;

		if (errors.validateResult(newContext, "AsnGetServerBasedClientSettingsExResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetServerBasedClientSettingsExResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetServerBasedClientSettingsExResult");

		let t: ENetROSEInterface.AsnGetServerBasedClientSettingsExResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnGetServerBasedClientSettingsExResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnGetServerBasedClientSettingsExResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _clientsettings = AsnServerBasedClientSettingsEx_Converter.fromJSON(s.clientSettings, errors, newContext, "clientSettings", false);
			if (_clientsettings)
				t.clientSettings = _clientsettings;
		}
		if (errors.validateResult(newContext, "AsnGetServerBasedClientSettingsExResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnGetServerBasedClientSettingsExResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetServerBasedClientSettingsExResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetServerBasedClientSettingsExResult");

		// [Print_BER_EncoderSeqDefCode]
		const _clientSettings = AsnServerBasedClientSettingsEx_Converter.toBER(s.clientSettings, errors, newContext, "clientSettings");
		if (errors.validateResult(newContext, "AsnGetServerBasedClientSettingsExResult")) {
			if (_clientSettings)
				t.push(_clientSettings);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnGetServerBasedClientSettingsExResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetServerBasedClientSettingsExResult");

		let t: ENetROSEInterface.AsnGetServerBasedClientSettingsExResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnGetServerBasedClientSettingsExResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnGetServerBasedClientSettingsExResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _clientsettings = AsnServerBasedClientSettingsEx_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "clientSettings"), errors, newContext, "clientSettings");
			if (_clientsettings)
				t.clientSettings = _clientsettings;
		}

		if (errors.validateResult(newContext, "AsnGetServerBasedClientSettingsExResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetServerBasedClientSettingsExArgument_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetServerBasedClientSettingsExArgument");

		const t = {} as ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetServerBasedClientSettingsExArgument";
		const _clientSettings = AsnServerBasedClientSettingsEx_Converter.toJSON(s.clientSettings, errors, newContext, "clientSettings");
		if (_clientSettings)
			t.clientSettings = _clientSettings;

		if (errors.validateResult(newContext, "AsnSetServerBasedClientSettingsExArgument"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetServerBasedClientSettingsExArgument");

		let t: ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _clientsettings = AsnServerBasedClientSettingsEx_Converter.fromJSON(s.clientSettings, errors, newContext, "clientSettings", false);
			if (_clientsettings)
				t.clientSettings = _clientsettings;
		}
		if (errors.validateResult(newContext, "AsnSetServerBasedClientSettingsExArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetServerBasedClientSettingsExArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetServerBasedClientSettingsExArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _clientSettings = AsnServerBasedClientSettingsEx_Converter.toBER(s.clientSettings, errors, newContext, "clientSettings");
		if (errors.validateResult(newContext, "AsnSetServerBasedClientSettingsExArgument")) {
			if (_clientSettings)
				t.push(_clientSettings);
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetServerBasedClientSettingsExArgument");

		let t: ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetServerBasedClientSettingsExArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _clientsettings = AsnServerBasedClientSettingsEx_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "clientSettings"), errors, newContext, "clientSettings");
			if (_clientsettings)
				t.clientSettings = _clientsettings;
		}

		if (errors.validateResult(newContext, "AsnSetServerBasedClientSettingsExArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnSetServerBasedClientSettingsExResult_Converter {
	public static toJSON(s: ENetROSEInterface.AsnSetServerBasedClientSettingsExResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnSetServerBasedClientSettingsExResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetServerBasedClientSettingsExResult");

		const t = {} as ENetROSEInterface.AsnSetServerBasedClientSettingsExResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnSetServerBasedClientSettingsExResult";

		if (errors.validateResult(newContext, "AsnSetServerBasedClientSettingsExResult"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetServerBasedClientSettingsExResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetServerBasedClientSettingsExResult");

		let t: ENetROSEInterface.AsnSetServerBasedClientSettingsExResult | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnSetServerBasedClientSettingsExResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnSetServerBasedClientSettingsExResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}
		if (errors.validateResult(newContext, "AsnSetServerBasedClientSettingsExResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnSetServerBasedClientSettingsExResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnSetServerBasedClientSettingsExResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnSetServerBasedClientSettingsExResult");

		// [Print_BER_EncoderSeqDefCode]

		return result;
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnSetServerBasedClientSettingsExResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnSetServerBasedClientSettingsExResult");

		let t: ENetROSEInterface.AsnSetServerBasedClientSettingsExResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnSetServerBasedClientSettingsExResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnSetServerBasedClientSettingsExResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnSetServerBasedClientSettingsExResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRedisRemoteInvoke_Converter {
	public static toJSON(s: ENetROSEInterface.AsnRedisRemoteInvoke, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetROSEInterface.AsnRedisRemoteInvoke & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRedisRemoteInvoke");

		const t = {} as ENetROSEInterface.AsnRedisRemoteInvoke & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRedisRemoteInvoke";
		TSConverter.fillJSONParam(s, t, "u8sInvokeID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sInvoker", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sInvokingServer", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sInvokingConnection", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sOp", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sArg", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnRedisRemoteInvoke"))
			return t;

		return undefined;

	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRedisRemoteInvoke | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRedisRemoteInvoke");

		let t: ENetROSEInterface.AsnRedisRemoteInvoke | undefined;
		const s = TSConverter.prepareJSONData<ENetROSEInterface.AsnRedisRemoteInvoke>(data, errors, newContext, optional);
		if (s) {
			t = ENetROSEInterface.AsnRedisRemoteInvoke["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sInvokeID", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sInvoker", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sInvokingServer", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sInvokingConnection", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sOp", "string", errors, context, false);
			TSConverter.fillJSONParam(s, t, "u8sArg", "string", errors, context, false);
		}
		if (errors.validateResult(newContext, "AsnRedisRemoteInvoke"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetROSEInterface.AsnRedisRemoteInvoke | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRedisRemoteInvoke";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRedisRemoteInvoke");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sInvokeID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sInvoker", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sInvokingServer", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sInvokingConnection", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sOp", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sArg", "string", errors, newContext);
		if (errors.validateResult(newContext, "AsnRedisRemoteInvoke")) {
			t.push(new asn1ts.Utf8String({ value: s.u8sInvokeID, name: "u8sInvokeID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sInvoker, name: "u8sInvoker" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sInvokingServer, name: "u8sInvokingServer" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sInvokingConnection, name: "u8sInvokingConnection" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sOp, name: "u8sOp" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sArg, name: "u8sArg" }));
			return result;
		}
		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetROSEInterface.AsnRedisRemoteInvoke | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRedisRemoteInvoke");

		let t: ENetROSEInterface.AsnRedisRemoteInvoke | undefined;
		const s = TSConverter.prepareASN1BERData(ENetROSEInterface.AsnRedisRemoteInvoke.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetROSEInterface.AsnRedisRemoteInvoke["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sInvokeID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sInvoker", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sInvokingServer", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sInvokingConnection", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sOp", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sArg", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRedisRemoteInvoke"))
			return t;

		return undefined;
	}
}
