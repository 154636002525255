/**
 * manages the translation for the web-shared-components
 */
export class TranslationManager {
	// The singleton instance of this class
	private static instance: TranslationManager;
	private translations: { [key: string]: string };

	/**
	 * the constructor
	 */
	private constructor() {
		this.translations = {};
	}

	/**
	 * Gets instance of TranslationManager to use as a singleton.
	 *
	 * @returns - an instance of this class.
	 */
	public static getInstance(): TranslationManager {
		if (!TranslationManager.instance)
			TranslationManager.instance = new TranslationManager();
		return TranslationManager.instance;
	}

	/**
	 * Let's pass the translations to the manager
	 *
	 * @param translations - the translations
	 */
	public setTranslations(translations: { [key: string]: string }): void {
		this.translations = translations;
	}

	/**
	 * getTranslation
	 *
	 * @param key - input variable
	 * @returns - the translation
	 */
	public getTranslation(key: string): string {
		// console.log("got translation key: ", key, " => ", this.translations[key]);
		const translation = this.translations[key];
		if (translation === undefined)
			return "";
		return translation;
	}
}
